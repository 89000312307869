import { Project } from '../../models3/Project'
import { routePrefix } from '../app/slttAvtt'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:Helpers')

export const JSON_MIME_TYPE = 'application/json'

export const groupBy = <T, K extends keyof any>(list: T[], getKey: (item: T) => K) =>
    list.reduce((previous, currentItem) => {
        const group = getKey(currentItem)
        if (!previous[group]) previous[group] = []
        previous[group].push(currentItem)
        return previous
    }, {} as Record<K, T[]>)

export const isValidIdCharacters = (id: string) => /^[a-zA-Z0-9-]+$/gi.test(id)

export const isValidIdLength = (id: string) => id.length >= 3 && id.length <= 20

export const isValidDisplayNameCharacters = (id: string) => /^[\p{L}0-9:()\- ]+$/giu.test(id)

export const isValidDisplayNameLength = (id: string) => id.length >= 3 && id.length <= 40

export const downloadWithFilename = (href: string, fileName: string) => {
    const link = document.createElement('a')
    link.setAttribute('href', href)
    link.setAttribute('download', fileName)
    link.click()
}

export const currentTimestampSafeString = () => new Date().toISOString().replace(/[/:-]/g, '_')

export const safeFileName = (fileName: string) => fileName.replace(/[\\/:"*?<>|]/g, '_')

export const exportToFile = (content: string | Blob, fileName: string, fileExtension: string) => {
    const blob = typeof content === 'string' ? new Blob([content], { type: 'text/plain' }) : content
    const url = window.URL.createObjectURL(blob)
    downloadWithFilename(url, `${safeFileName(fileName)}-${currentTimestampSafeString()}${fileExtension}`)
    window.URL.revokeObjectURL(url)
}

export const createLink = ({ project, itemId, time = 0 }: { project: Project; itemId: string; time?: number }) => {
    const linkUrl = `/index.html?project=${project.name}&id=${itemId}&time=${time}`
    return `${window.location.origin}${routePrefix}/#${linkUrl}`
}

const projectRestoreInProgress = 'projectRestoreInProgress'

export const isProjectRestoreInProgress = () => localStorage.getItem(projectRestoreInProgress) === 'true'

export const setProjectRestoreInProgress = () => localStorage.setItem(projectRestoreInProgress, 'true')

export const clearProjectRestoreInProgress = () => localStorage.setItem(projectRestoreInProgress, '')

// Make sure that we clear the project restore setting if it is still set (browser crashes,
// power outage, etc.)
if (isProjectRestoreInProgress()) {
    log(`localStorage.${projectRestoreInProgress} should be cleared before page closes. Clearing...`)
    clearProjectRestoreInProgress()
}

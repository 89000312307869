/* eslint-disable react/no-danger */
import { FC, useState, useEffect } from 'react'

import { displayError } from '../utils/Errors'
import { ApiDotBible } from '../../models3/ApiDotBible'
import { RefRange } from '../../scrRefs/RefRange'

import './ResourceViewer.css'

interface IRBody {
    resourceId: string
    refs: RefRange[]
    displayableBookNames: string[]
}

const RBody: FC<IRBody> = ({ resourceId, refs, displayableBookNames }) => {
    const [html, setHtml] = useState('')

    useEffect(() => {
        const fetchResource = async () => {
            if (!refs.length) return

            const version = await ApiDotBible.getBibleVersion(resourceId)
            const rtl = version ? version.language.scriptDirection === 'RTL' : false

            try {
                let _html = await ApiDotBible.fetchRefs(resourceId, refs, displayableBookNames)
                _html = _html.replace(/<span data-caller="\+"/g, `<input type="checkbox"/><span data-caller="+"`)

                if (rtl) {
                    _html = `<div dir="rtl">${_html}</div>`
                }
                setHtml(_html)
            } catch (error) {
                displayError(error)
            }
        }

        fetchResource()
    }, [resourceId, refs, displayableBookNames])

    return (
        <div className="resource-container">
            <div dangerouslySetInnerHTML={{ __html: html }} />
        </div>
    )
}

export default RBody

import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { observable } from 'mobx'
import { t } from 'i18next'
import { RootConsumer } from '../app/RootContext'

interface ITextInput {
    initialValue?: string
    message: string
    placeholder?: string
    type?: string
    _onEnter: (value: string) => void
    _onEscape?: () => void
    validate?: (value: string) => string // check input value returns error message or ''
    asyncValidate?: (value: string) => Promise<string> // check input value returns error message or ''
    allowEmptyValue?: boolean
}

@observer
export default class TextInput extends Component<ITextInput> {
    @observable value = ''

    @observable errorMessage = ''

    textInputRef = React.createRef<HTMLDivElement>()

    constructor(props: ITextInput) {
        super(props)
        this.clickOutside = this.clickOutside.bind(this)
    }

    componentDidMount() {
        const { initialValue } = this.props

        this.value = initialValue || ''
        document.addEventListener('mousedown', this.clickOutside)
        document.addEventListener('touchstart', this.clickOutside)
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.clickOutside)
        document.removeEventListener('touchstart', this.clickOutside)
    }

    onChange(e: any) {
        const { validate, asyncValidate } = this.props

        this.value = e.target.value
        if (validate) {
            this.errorMessage = validate(e.target.value)
        } else if (asyncValidate) {
            asyncValidate(this.value)
                .then((message: string) => {
                    this.errorMessage = message
                })
                .catch(() => {
                    this.errorMessage = t('Validation failed')
                })
        }
    }

    onKeyDown(e: React.KeyboardEvent) {
        const { _onEnter, _onEscape, allowEmptyValue } = this.props
        const { value, errorMessage } = this

        e.stopPropagation()

        // <enter> = stop editing
        if (e.keyCode === 13) {
            if ((value || allowEmptyValue) && !errorMessage) {
                if (_onEnter) {
                    _onEnter(this.value)
                }
                return
            }

            return
        }

        // <esc> = abandon edit
        if (e.keyCode === 27) {
            e.nativeEvent.stopImmediatePropagation()
            e.preventDefault()
            if (_onEscape) {
                _onEscape()
            }
            this.value = ''
        }
    }

    clickOutside(event: any) {
        const { textInputRef, errorMessage, value } = this
        const { _onEnter, _onEscape } = this.props
        if (!textInputRef.current || textInputRef.current.contains(event.target)) {
            return
        }

        if (errorMessage) {
            _onEscape?.()
        } else {
            _onEnter(value)
        }
    }

    render() {
        const { value, errorMessage, textInputRef } = this
        const { message, _onEscape, placeholder } = this.props
        let { type } = this.props

        type = type || 'text'

        const showCancel = (errorMessage || !value) && _onEscape

        return (
            <RootConsumer>
                {() => (
                    <div ref={textInputRef}>
                        <input
                            autoFocus
                            placeholder={placeholder || ''}
                            type={type}
                            value={value}
                            onChange={this.onChange.bind(this)}
                            onKeyDown={this.onKeyDown.bind(this)}
                        />

                        {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}

                        <div className="passage-info-text">
                            {value && !errorMessage && <span>{message}</span>}
                            {showCancel && <span>{t('Type Esc to cancel.')}</span>}
                        </div>
                    </div>
                )}
            </RootConsumer>
        )
    }
}

import { Passage } from './Passage'
import { PassageVideo } from './PassageVideo'
import { Portion } from './Portion'
import { Project } from './Project'
import { Root } from './Root'

import { RefRange } from '../scrRefs/RefRange'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:ProjectReferences')

// Keep track of the RefRanges for the latest videos for all passages in the project.
// Allow efficiently getting all the videos that match target references.

export const getVisiblePassageOrPortionRefRanges = ({
    passage,
    portion
}: {
    passage?: Passage | null
    portion?: Portion | null
}) => (passage?.references.length ? passage.references : portion?.references ?? [])

export class ProjectReference {
    constructor(
        public refRanges: RefRange[],
        public passageVideo: PassageVideo,
        public startingTime: number, // time for the segment containing the reference
        public endingTime: number
    ) {}

    dbg() {
        const { refRanges, startingTime, endingTime } = this
        return { refRanges, startingTime, endingTime }
    }

    info(rt: Root) {
        const { passageVideo, startingTime, endingTime, refRanges } = this

        const portion = rt.project.portions.find((_portion) => passageVideo._id.startsWith(_portion._id))
        const passage = portion?.passages.find((_passage) => passageVideo._id.startsWith(_passage._id))

        const parts = [portion?.name ?? '', passage?.name ?? '', rt.displayableReferences(refRanges)]

        // Remove redundant parts of reference name if present
        if (parts.length >= 2 && parts[1].startsWith(parts[0])) parts.splice(0, 1)
        if (parts.length >= 2 && parts[1].startsWith(parts[0])) parts.splice(0, 1)
        if (parts.length >= 3 && parts[2].startsWith(parts[1])) parts.splice(1, 1)

        const timestamp = startingTime > 0 ? ` (${startingTime.toFixed(2)}...${endingTime.toFixed(2)})` : ''

        const tooltip = parts.join(' / ') + timestamp

        return { portion, passage, tooltip }
    }
}

export class ProjectReferences {
    private refs: { [bbbccc: string]: Set<ProjectReference> } = {}

    private projectReferences: ProjectReference[] = []

    // private initialized = false
    private project?: Project

    // Call this to update the list of references whenever references are for
    // any portion/passage are changed
    update(rt: Root) {
        log('update')

        this.project = rt.project
        this.refs = {}
        this.projectReferences = []
        this.gatherProjectReferences()
        this.insertProjectReferencesIntoRefs()

        // log('update', this.projectReferences.map(pref => pref.info(rt).tooltip))
    }

    // get a list of all the ProjectReferences that overlap with targetRange
    get(targetRange: RefRange, firstOnly?: boolean): ProjectReference[] {
        const results: ProjectReference[] = []

        for (const bbbccc of targetRange.chapterIterator()) {
            const refSet = this.refs[bbbccc]
            if (refSet) {
                for (const projectReference of refSet) {
                    if (targetRange.overlaps(projectReference.refRanges)) {
                        results.push(projectReference)
                        if (firstOnly) return results
                    }
                }
            }
        }

        return results
    }

    // getForRanges(rt: Root, ranges: RefRange[]): ProjectReference[] {
    //     let results: ProjectReference[] = []

    //     for (let range of ranges) {
    //         let matches = this.get(range)
    //         for (let match of matches) {
    //             match.setup(rt)
    //             if (!results.find(r => r.key === match.key)) {
    //                 results.push(match)
    //             }
    //         }
    //     }

    //     results = _.sortBy(results, 'key')

    //     return results
    // }

    private gatherProjectReferences() {
        this.project?.portions.forEach((portion) => {
            portion.passages.forEach((passage) => {
                const videos = passage.videosNotDeleted
                const video = videos.slice(-1)[0]
                if (video) {
                    this.addReferencesForVideo(portion, passage, video)
                }
            })
        })
    }

    // Add all the references users have created for a specific passage video. Only include
    // the portion and passage references.
    private addReferencesForVideo(portion: Portion, passage: Passage, video: PassageVideo) {
        // We only care about either portion or passage references, because only one of them
        // applies at a time.
        const refRange = getVisiblePassageOrPortionRefRanges({ passage, portion })
        this.projectReferences.push(new ProjectReference(refRange, video, 0, video.computedDuration))
    }

    private insertProjectReferencesIntoRefs() {
        this.projectReferences.forEach((projectReference) => {
            projectReference.refRanges.forEach((refRange) => {
                for (const bbbccc of refRange.chapterIterator()) {
                    let refSet = this.refs[bbbccc]
                    if (!refSet) {
                        refSet = new Set<ProjectReference>()
                        this.refs[bbbccc] = refSet
                    }
                    refSet.add(projectReference)
                }
            })
        })
    }
}

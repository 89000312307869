import { observable } from 'mobx'

import { DBObject } from './DBObject'
import { Project } from './Project'
import { IVideoDownloadQuery } from './VideoCacheDownloader'
import { currentTimestampSafeString } from '../components/utils/Helpers'
import { MAX_RICHTEXT_SIZE } from '../components/utils/RichTextEditor'
import { fmt } from '../components/utils/Fmt'
import { shouldEncodeOpus, encodeOpus } from '../components/utils/Opus'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:Models')

interface IVideoDownloader {
    queryVideoDownload: (_id: string) => Promise<IVideoDownloadQuery>
}

export class PassageDocument extends DBObject {
    // If text starts with 's3:' then it is the url of the document in S3
    @observable text = ''

    @observable pdfUrl = ''

    @observable audioUrl = ''

    @observable title = ''

    @observable editable = true

    textHistory: string[] = []

    toDocument() {
        const { title, text, editable, audioUrl, pdfUrl } = this
        return this._toDocument({ title, text, editable, audioUrl, pdfUrl, model: 5 })
    }

    copy() {
        let copy = new PassageDocument(this._id, this.db)
        copy = Object.assign(copy, this)
        copy.textHistory = Array.from(this.textHistory)
        return copy
    }

    // True if the text of this document is available
    loaded(_text: string) {
        return !_text.startsWith('s3:')
    }

    async loadText(_text: string, videoDownloader: IVideoDownloader): Promise<string> {
        const timeout = (ms: number) =>
            new Promise((res) => {
                setTimeout(res, ms)
            })

        while (true) {
            const response = await videoDownloader.queryVideoDownload(_text.slice(3))
            if (response.blob) {
                const downloadedText = await response.blob.text()
                return downloadedText
            }

            await timeout(250)
        }
    }

    async getText(historyIndex: number, videoDownloader: IVideoDownloader) {
        if (historyIndex === 0) {
            if (!this.loaded(this.text)) {
                this.text = await this.loadText(this.text, videoDownloader)
            }
            return this.text
        }

        const i = this.textHistory.length - historyIndex
        if (i < 0) {
            log('### Invalid index passed to getText')
            return ''
        }

        if (!this.loaded(this.textHistory[i])) {
            this.textHistory[i] = await this.loadText(this.textHistory[i], videoDownloader)
        }
        return this.textHistory[i]
    }

    // Set text. If long push to s3 and make text be a reference to that bucket.
    async setText(text: string, projectName: string) {
        log('PassageDocument setText', fmt({ _id: this._id, text, projectName }))

        text = text.trim()
        if (this.text === text) {
            return
        }

        if (text.length >= MAX_RICHTEXT_SIZE) {
            const blob = new Blob([text], { type: 'text/plain' })
            const s3Path = `${projectName}/${this._id}/${currentTimestampSafeString()}.txt`
            log('s3Path', s3Path)

            const url = await Project.copyFileToVideoCache(blob as File, s3Path, undefined, true)

            text = `s3:${url}`
        }

        const doc = this._toDocument({ text, model: 5 })
        await this.db.put(doc)
    }

    async setTitle(title: string, forcePut: boolean) {
        log('PassageDocument setTitle', fmt({ _id: this._id, title }))

        title = title.trim()
        if (!forcePut && this.title === title) {
            return
        }

        const doc = this._toDocument({ title, model: 5 })
        await this.db.put(doc)
    }

    async setEditable(editable: boolean) {
        if (this.editable === editable) {
            return
        }
        const doc = this._toDocument({ editable, model: 5 })
        await this.db.put(doc)
    }

    async uploadAudioFile(file: File, projectName: string) {
        if (!file.type.startsWith('audio/')) {
            return
        }

        if (!Project.copyFileToVideoCache) {
            throw new Error('Project.copyFileToVideoCache not set')
        }

        // base url of an audio file is stored with mp3 at the end, for backward compatibility
        const baseUrl = `${projectName}/${this._id}/${currentTimestampSafeString()}.mp3`
        const fileToUpload = shouldEncodeOpus(file.name) ? await encodeOpus(file) : file
        const url = await Project.copyFileToVideoCache(fileToUpload, baseUrl, undefined, true)
        await this.setAudioUrl(url)
    }

    async uploadPDFFile(file: File, projectName: string) {
        if (!file.type.startsWith('application/pdf')) {
            return
        }

        if (!Project.copyFileToVideoCache) {
            throw new Error('Project.copyFileToVideoCache not set')
        }

        const baseUrl = `${projectName}/${this._id}/${currentTimestampSafeString()}.pdf`
        const url = await Project.copyFileToVideoCache(file, baseUrl, undefined, true)
        await this.setPdfUrl(url)
    }

    async setAudioUrl(audioUrl: string) {
        if (audioUrl === this.audioUrl) {
            return
        }
        const doc = this._toDocument({ audioUrl, model: 18 })
        await this.db.put(doc)
    }

    async setPdfUrl(pdfUrl: string) {
        if (pdfUrl === this.pdfUrl) {
            return
        }
        const doc = this._toDocument({ pdfUrl, model: 18 })
        await this.db.put(doc)
    }
}

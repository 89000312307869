import React, { FC, useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { useAppRoot } from './RootContext'
import { Root } from '../../models3/Root'
import MembersByRoleView from '../projectSettings/members/MembersByRoleView'
import { ProjectAddModal } from '../utils/ProjectAddModal'
import { ProjectDeleteModal } from '../utils/ProjectDeleteModal'
import { AddButton, DeleteButton } from '../utils/Buttons'
import { LoadingIcon } from '../utils/Icons'

interface ProjectsEditorProps {
    groupRoot?: Root
}

const ProjectsEditor: FC<ProjectsEditorProps> = observer(({ groupRoot }) => {
    const { t } = useTranslation()
    const [openProjectAddModal, setOpenProjectAddModal] = useState(false)
    const [openProjectDeleteModal, setOpenProjectDeleteModal] = useState('')
    const [loading, setLoading] = useState(false)
    const appRoot = useAppRoot()

    const showDetails = Boolean(groupRoot) // TODO: this can be a user selection

    useEffect(() => {
        let mounted = true

        async function initializeRoots() {
            setLoading(true)
            await Promise.all(
                appRoot.rts
                    .filter((rt) => !groupRoot || groupRoot.groupProjects?.includes(rt.project.name))
                    .map((rt) => rt.initialize())
            )
            if (mounted) setLoading(false)
        }

        if (showDetails) {
            initializeRoots()
        }

        return () => {
            mounted = false
        }
    }, [appRoot.rts, groupRoot, showDetails])

    if (loading) {
        return <LoadingIcon className="" />
    }

    return (
        <div>
            <div className="container-fluid">
                <div className="row">
                    <h3>{groupRoot ? t('Group Projects') : t('All Projects')}</h3>
                </div>
                <div className="row">
                    <div className="col-md-5">
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th>{groupRoot ? t('Group Project') : t('Project')}</th>
                                    {showDetails && (
                                        <>
                                            <th>{t('Admin Users')}</th>
                                            <th>
                                                <i className="fas fa-fw fa-video" title={t('project has recordings')} />
                                            </th>
                                        </>
                                    )}
                                    <th />
                                </tr>
                            </thead>
                            <tbody>
                                {appRoot.rts
                                    .filter((rt) => !groupRoot || groupRoot.groupProjects?.includes(rt.project.name))
                                    .sort((a, b) => {
                                        const aName = a.project.getFormattedDisplayName().toLocaleLowerCase()
                                        const bName = b.project.getFormattedDisplayName().toLocaleLowerCase()
                                        return aName.localeCompare(bName)
                                    })
                                    .map((rt) => (
                                        <tr key={rt.project.name}>
                                            <td>{rt.project.getFormattedDisplayName()}</td>
                                            {showDetails && (
                                                <>
                                                    <td>
                                                        <MembersByRoleView
                                                            project={rt.project}
                                                            includeRoles={['admin']}
                                                        />
                                                    </td>
                                                    <td>
                                                        {rt.project.videod() && (
                                                            <i
                                                                className="fas fa-fw fa-video"
                                                                title={t('project has recordings')}
                                                            />
                                                        )}
                                                    </td>
                                                </>
                                            )}
                                            {!appRoot.useMobileLayout && (appRoot.iAmRoot || appRoot.rt?.iAmAdmin) && (
                                                <td>
                                                    {openProjectDeleteModal === rt.project.name && (
                                                        <ProjectDeleteModal
                                                            setOpenModal={setOpenProjectDeleteModal}
                                                            projectName={rt.project.name}
                                                        />
                                                    )}
                                                    <DeleteButton
                                                        onClick={() => setOpenProjectDeleteModal(rt.project.name)}
                                                        className="sl-delete-button"
                                                        tooltip={t('Delete project')}
                                                        enabled={appRoot.iAmRoot} // temporarily for iAmRoot only
                                                    />
                                                </td>
                                            )}
                                        </tr>
                                    ))}
                                {!appRoot.useMobileLayout && (appRoot.iAmRoot || appRoot.rt?.iAmAdmin) && (
                                    <tr>
                                        <td colSpan={4}>
                                            {openProjectAddModal && (
                                                <ProjectAddModal
                                                    setOpenModal={setOpenProjectAddModal}
                                                    groupRoot={groupRoot}
                                                />
                                            )}
                                            <AddButton
                                                onClick={() => setOpenProjectAddModal(true)}
                                                className="project-plan-copy-button"
                                                buttonClassName=""
                                                tooltip={t('Add project')}
                                                enabled={appRoot.iAmRoot} // temporarily for iAmRoot only
                                            />
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
})

export default ProjectsEditor

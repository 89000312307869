import React, { FC, useState, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import Modal from 'react-bootstrap/lib/Modal'
import { observer } from 'mobx-react'

import ERTermView from './ERTermView'
import { ProjectReferenceVideoPlayer } from './ProjectReferenceVideoPlayer'

import Player from '../utils/Player'
import { PlayButton, RecordButton, StopButton, TrashButton } from '../utils/Buttons'
import { fmt } from '../utils/Fmt'
import { displayError } from '../utils/Errors'

import { RecordingDoneParams, VideoUploader } from '../video/VideoUploader'
import VideoRecorder, { AVTTRecordingState } from '../video/VideoRecorder'

import { Root } from '../../models3/Root'
import { ProjectTerm } from '../../models3/ProjectTerm'
import { ProjectReference } from '../../models3/ProjectReferences'
import { SignVideo } from '../../models3/SignVideo'

import { MarbleLemmas, MarbleLemma } from '../../scrRefs/Lemmas'

import './EnhancedResources.css'
import { enableKeyTerms } from '../app/slttAvtt'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:ERTermModal')

interface IERSignToolbar {
    rt: Root
    signPlaying: SignVideo | null
    setSignPlaying: (sign: SignVideo | null) => void
    signRecording: SignVideo | null
    oldSignRecording: boolean
    setOldSignRecording: (recording: boolean) => void
    projectReference: ProjectReference | null
    recordSignRef: React.MutableRefObject<any>
    lemma: MarbleLemma
}

const ERSignToolbar: FC<IERSignToolbar> = ({
    rt,
    signPlaying,
    setSignPlaying,
    signRecording,
    projectReference,
    recordSignRef,
    lemma,
    setOldSignRecording,
    oldSignRecording
}) => {
    const { t } = useTranslation()
    const url = lemma.signVideoUrl(rt)
    const signVideo = rt.project.signs[`sign/${lemma.id}`]
    const playEnabled = !signPlaying && !signRecording && !oldSignRecording && !projectReference && !!url && !!signVideo
    const recordEnabled = rt.iAmConsultant && !projectReference && !signPlaying

    return (
        <div className="er-toolbar">
            <div className="er-toolbar-first-group">
                {!signRecording && !oldSignRecording && (
                    <PlayButton
                        enabled={playEnabled}
                        selectionPresent={false}
                        tooltip={t('Play signs for this term.')}
                        className="er-play-button"
                        onClick={() => setSignPlaying(signVideo)}
                    />
                )}

                {!signRecording && !oldSignRecording && (
                    <RecordButton
                        tooltip={t('Record signs for this term.')}
                        enabled={recordEnabled}
                        selectionPresent={false}
                        className="er-record-button er-record-video-button"
                        onClick={() => setOldSignRecording(true)}
                    />
                )}

                {(signRecording || oldSignRecording) && (
                    <StopButton
                        className="er-video-stop-button"
                        enabled
                        tooltip={t('Stop recording.')}
                        onClick={() => {
                            recordSignRef.current?.stop()
                        }}
                    />
                )}
            </div>

            <div className="er-toolbar-last-group">
                {!signRecording && !oldSignRecording && (
                    <TrashButton
                        enabled={playEnabled}
                        buttonClassName=""
                        className="delete-video-button"
                        onClick={() => lemma.deleteSignVideo(rt).catch(displayError)}
                        tooltip={t('recordingDelete')}
                    />
                )}
            </div>
        </div>
    )
}

interface IERPlaySign {
    signPlaying: SignVideo
    setSignPlaying: (sign: SignVideo | null) => void
}

const ERPlaySign: FC<IERPlaySign> = ({ signPlaying, setSignPlaying }) => {
    log(`playSign`)

    return (
        <div className="er-video-element">
            <Player className="sign-player" videoUrl={signPlaying.url} autoClose onEnded={() => setSignPlaying(null)} />
        </div>
    )
}

interface IERRecordSenseSign {
    rt: Root
    signRecording: SignVideo
    setSignRecording: (sign: SignVideo | null) => void
    save: (signRecording: SignVideo) => Promise<void>
    recordSignRef: React.MutableRefObject<any>
}

const ERRecordSenseSign: FC<IERRecordSenseSign> = ({ rt, setSignRecording, signRecording, save, recordSignRef }) => {
    const { t } = useTranslation()
    const [recordingState, setRecordingState] = useState<AVTTRecordingState>('NOT_INITIALIZED')
    const videoRecorderRef = useRef<VideoRecorder>(null)

    const { name } = rt.project
    signRecording.url = `${name}/${signRecording._id}`

    log('ERRcordSign', fmt({ _id: signRecording._id, url: signRecording.url }))

    async function onRecordingDone({ err, blobsCount }: Partial<RecordingDoneParams>) {
        if (err) {
            setSignRecording(null)
            displayError(err)
            return
        }

        signRecording.url = `${signRecording.url}-${blobsCount}`
        log('onRecordingDone', signRecording.url)

        try {
            await save(signRecording)
            setSignRecording(null)
        } catch (saveError) {
            displayError(saveError)
            setSignRecording(null)
        }
    }

    const videoUploader = new VideoUploader(signRecording.url, onRecordingDone)

    return (
        <div className="er-record-sense-sign">
            {enableKeyTerms && (
                <StopButton
                    enabled={recordingState !== 'NOT_INITIALIZED'}
                    onClick={() => {
                        videoRecorderRef.current?.stop()
                    }}
                    className="er-video-stop-button"
                    tooltip={t('Stop recording.')}
                />
            )}
            <div className="er-record-sense-sign-video-player">
                <VideoRecorder
                    {...{
                        videoUploader,
                        ref: enableKeyTerms ? videoRecorderRef : recordSignRef,
                        setRecordingState
                    }}
                />
            </div>
        </div>
    )
}

interface IERRecordSign {
    rt: Root
    lemma: MarbleLemma
    setSignRecording: (playing: boolean) => void
    recordSignRef: React.MutableRefObject<any>
}

const ERRecordSign: FC<IERRecordSign> = ({ rt, lemma, setSignRecording, recordSignRef }) => {
    const signVideo = rt.project.createSignVideo(lemma.id)
    const { name } = rt.project
    const newId = rt.project.db.getNewId([], new Date())
    signVideo.url = `${name}/sign/${lemma.id}/${newId}`

    log('ERRcordSign', fmt({ _id: signVideo._id, url: signVideo.url }))

    async function onRecordingDone({ err, blobsCount }: Partial<RecordingDoneParams>) {
        if (err) {
            setSignRecording(false)
            displayError(err)

            return
        }

        signVideo.url = `${signVideo.url}-${blobsCount}`
        log('onRecordingDone', signVideo.url)

        try {
            /** [complicated things happen here]
             * Put writes a record to indexedB.
             * DBAcceptor sees this record and calls VideoCache.accept
             * which notices the video is not yet uploaded and initiates the upload.
             */
            await signVideo.db.put(signVideo.toDocument())
            setSignRecording(false)
        } catch (error) {
            displayError(error)
            setSignRecording(false)
        }
    }

    const videoUploader = new VideoUploader(signVideo.url, onRecordingDone)

    return <VideoRecorder {...{ videoUploader, ref: recordSignRef }} />
}

interface IERTermModal {
    rt: Root
    termId: string
    setTermId: (resource: string) => void
}

const ERTermModal: FC<IERTermModal> = observer(({ rt, termId, setTermId }) => {
    const { t } = useTranslation()
    const [signPlaying, setSignPlaying] = useState<SignVideo | null>(null)
    const [signRecording, setSignRecording] = useState<SignVideo | null>(null)
    const [oldSignRecording, setOldSignRecording] = useState(false)
    const [projectReference, setProjectReference] = useState<ProjectReference | null>(null)
    const [projectTerm, setProjectTerm] = useState<ProjectTerm | null>(null)
    const recordSignRef = useRef<any>(null)

    function _setSignRecording(sign: SignVideo | null) {
        if (!sign) {
            setProjectTerm(null)
        }
        setSignRecording(sign)
    }

    async function saveSign(sign: SignVideo) {
        return projectTerm?.addRendering(sign)
    }

    const lemma = MarbleLemmas.get(termId)
    log('ERTermModal', fmt({ termId, lemma }))

    useEffect(() => {
        if (lemma) {
            rt.project.migrateLemma(lemma)
        }
    }, [lemma, rt])

    if (!lemma) {
        return null
    }

    rt.projectReferences.update(rt) // expensive, memoize?

    return (
        <Modal
            style={{ top: '1%' }}
            bsSize="large"
            show
            onEnter={() => (rt.termModalOpen = true)}
            onHide={() => {
                setTermId('')
                rt.termModalOpen = false
            }}
            backdrop="static"
        >
            <Modal.Header closeButton>
                <h3>{t('Biblical Term Viewer')}</h3>
                {!enableKeyTerms && (
                    <ERSignToolbar
                        {...{
                            rt,
                            signPlaying,
                            setSignPlaying,
                            signRecording,
                            projectReference,
                            setOldSignRecording,
                            oldSignRecording,
                            recordSignRef,
                            lemma
                        }}
                    />
                )}
            </Modal.Header>
            <Modal.Body>
                <div className="er-term-modal-body">
                    {signRecording && (
                        <ERRecordSenseSign
                            {...{
                                rt,
                                setSignRecording: _setSignRecording,
                                signRecording,
                                save: saveSign,
                                recordSignRef
                            }}
                        />
                    )}
                    {oldSignRecording && (
                        <ERRecordSign {...{ rt, recordSignRef, lemma, setSignRecording: setOldSignRecording }} />
                    )}
                    {signPlaying && <ERPlaySign {...{ signPlaying, setSignPlaying }} />}
                    {projectReference && (
                        <ProjectReferenceVideoPlayer {...{ rt, projectReference, setProjectReference, termId }} />
                    )}
                    {!signPlaying && !signRecording && !oldSignRecording && !projectReference && (
                        <ERTermView
                            {...{
                                rt,
                                lemma,
                                setProjectReference,
                                termId,
                                setTermId,
                                setSignPlaying,
                                startRecording: (term) => {
                                    setProjectTerm(term)
                                    const signVideo = term.createRendering()
                                    _setSignRecording(signVideo)
                                }
                            }}
                        />
                    )}
                </div>
            </Modal.Body>
        </Modal>
    )
})

export default ERTermModal

/* eslint-disable react/no-array-index-key */

import { FC, useState, useEffect, useRef } from 'react'
import Modal from 'react-bootstrap/lib/Modal'

import ERTermModal from './ERTermModal'
import RBody from './ResourceViewer'

import { isAVTT } from '../app/slttAvtt'

import { StarButton } from '../utils/Buttons'
import { groupBy } from '../utils/Helpers'
import { ReferenceInput } from '../utils/ReferenceInput'
import { displayError } from '../utils/Errors'

import { ADBVersion, ApiDotBible } from '../../models3/ApiDotBible'
import { PassageHighlight } from '../../models3/PassageHighlight'
import { ProjectTerm } from '../../models3/ProjectTerm'
import { Root } from '../../models3/Root'

import { EnhancedResources, ERDiv, ERSpan } from '../../resources/EnhancedResources'

import { displayableBookNames } from '../../scrRefs/bookNames'
import { RefRange } from '../../scrRefs/RefRange'

import './EnhancedResources.css'

/*
    EnhancedResourcesViewer
        ERTermModal - show info for user selected term
            ERTermView - show info from Lexicon for term
 */

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:EnhancedResourcesViewer')

class HighlightRange {
    constructor(public firstId: string, public lastId: string) {}
}

function isSLTTReference(resource: string) {
    return resource.length !== 19
}

function getMarbleId(node: any) {
    while (node) {
        const id = node.getAttribute && node.getAttribute('data-marbleid')
        if (id) return { node, id }
        node = node.parentNode
    }

    return { node: undefined, id: undefined }
}

function erDivsMouseUpHandler(e: any, setHighlightRange: (highlightRange: HighlightRange | null) => void) {
    const selectionObj = window.getSelection()
    if (!selectionObj) {
        setHighlightRange(null)
        return
    }

    // if start and end of selection are the same no text was selected.
    // Nothing to highlight, return
    if (selectionObj.anchorNode === selectionObj.focusNode && selectionObj.anchorOffset === selectionObj.focusOffset) {
        log('onMouseUp empty-selection')
        setHighlightRange(null)
        return
    }

    const { node: firstNode, id: firstId } = getMarbleId(selectionObj.anchorNode)
    const { node: lastNode, id: lastId } = getMarbleId(selectionObj.focusNode)

    // If both notes are from the same paragraph and that paragraph is a header
    // or reference paragraph then the user has not selected any body text.
    // Ignore the selection.
    if (firstNode === lastNode) {
        const classNames = firstNode.className.split(' ')
        if (classNames.includes('er-s') || classNames.includes('er-r')) {
            setHighlightRange(null)
            return
        }
    }

    if (firstId && lastId) {
        setHighlightRange(new HighlightRange(firstId, lastId))
    } else {
        setHighlightRange(null)
    }
}

interface IERResourceSelector {
    resource: string
    setResource: (resource: string) => void
}

const ERResourceSelector: FC<IERResourceSelector> = ({ resource, setResource }) => {
    const [versions, setVersions] = useState<Record<string, ADBVersion[]>>({})

    useEffect(() => {
        const fetchVersions = async () => {
            try {
                const _versions = groupBy(await ApiDotBible.getBibleVersions(), (v) => v.language.name)
                setVersions(_versions)
            } catch (error) {
                displayError(error)
            }
        }

        if (!isAVTT) {
            fetchVersions()
        }
    }, [])

    const languageNames = Object.keys(versions).sort()

    const resources = [
        { value: 'NBS11', text: 'NBS11+', avttOnly: false },
        { value: 'NLT', text: 'NLT', avttOnly: true },
        { value: 'NRS89', text: 'NRS89+', avttOnly: false },
        { value: 'PDV17', text: 'PDV17', avttOnly: true },
        { value: 'RVR60', text: 'RVR60+', avttOnly: false },
        { value: 'TLA', text: 'TLA', avttOnly: true },
        { value: 'BHS', text: 'BHS+', avttOnly: true },
        { value: 'UBSGNT5', text: 'UBSGNT5+', avttOnly: true }
    ].filter((r) => !r.avttOnly || isAVTT)

    return (
        <select
            className="er-resources-select"
            value={resource}
            onChange={(e) => {
                setResource(e.target.value)
            }}
        >
            {resources.map(({ value, text }) => (
                <option value={value} key={value}>
                    {text}
                </option>
            ))}
            {languageNames.map((languageName) => (
                <optgroup label={languageName} key={languageName}>
                    {versions[languageName].map((version) => (
                        <option value={version.id} key={version.id}>
                            {version.abbreviation} - {version.name}
                        </option>
                    ))}
                </optgroup>
            ))}
        </select>
    )
}

interface IHighlightButton {
    rt: Root
    highlightRange: HighlightRange | null
    setHighlightRange: (highlightRange: HighlightRange | null) => void
    color: number
    resource: string
}

const HighlightButton: FC<IHighlightButton> = ({ rt, highlightRange, setHighlightRange, resource, color }) => {
    const setColor = (highlightColor: number) => {
        if (!highlightRange) return

        rt.passageVideo
            ?.addHighlight(highlightColor, highlightRange.firstId, highlightRange.lastId, resource)
            .then(() => setHighlightRange(null))
            .catch(displayError)
    }

    return (
        <button
            type="button"
            className={`er-highlight-button er-highlight-${color}${highlightRange ? '' : '-disabled'}`}
            onClick={() => {
                setColor(color)
            }}
        />
    )
}

interface IERImageModal {
    imagePaths: string[]
    setImageModalOpen: (open: boolean) => void
}

const ERImageModal: FC<IERImageModal> = ({ imagePaths, setImageModalOpen }) => {
    const basePath = 'https://s3.amazonaws.com/sltt-resources/SLMARBLE/images/images_resolutions/Medium'

    return (
        <Modal style={{ top: '1%' }} bsSize="large" show backdrop="static" onHide={() => setImageModalOpen(false)}>
            <Modal.Header closeButton> </Modal.Header>
            <Modal.Body>
                <div className="er-images-div">
                    {imagePaths.map((ip, key) => (
                        <div key={key}>
                            <img className="er-image-display" src={`${basePath}/${ip}`} />
                        </div>
                    ))}
                </div>
            </Modal.Body>
        </Modal>
    )
}
interface IERImageView {
    span: ERSpan
}

const ERImageView: FC<IERImageView> = ({ span }) => {
    const [imageModalOpen, setImageModalOpen] = useState(false)

    const imagePaths = span.attributes.image_links.split('|')

    return (
        <span>
            {imageModalOpen && <ERImageModal {...{ imagePaths, setImageModalOpen }} />}
            <span className="er-image fa-fw fa-image" onClick={() => setImageModalOpen(true)} />
        </span>
    )
}
interface IERSpanView {
    rt: Root
    span: ERSpan
    setTermId: (id: string) => void
}

type ERPlainTextProps = {
    text: string
}

const ERPlainText = ({ text }: ERPlainTextProps) => {
    return <span className="er-text">{text}</span>
}

type ERLinkProps = {
    text: string
    termId: string
    spanType: string
    getProjectTerm: (lexicalLink: string) => ProjectTerm | undefined
    setTermId: (id: string) => void
}

const ERLink = ({ text, termId, spanType, getProjectTerm, setTermId }: ERLinkProps) => {
    const isSigned = !!(getProjectTerm(termId)?.renderings.length ?? 0)

    let className = `er-${spanType}`
    if (isSigned) {
        className += ' er-sign-video-present'
    }

    return (
        <span
            className={className}
            onClick={() => {
                // termId = "SDBG:γένεσις:000001|SDBG:γένεσις:000002"
                log('ERSpanView click', JSON.stringify(termId.split(':')))
                setTermId(termId)
            }}
        >
            {text}
        </span>
    )
}

type ERTextViewProps = {
    span: ERSpan
    setTermId: (id: string) => void
    getProjectTerm: (id: string) => ProjectTerm | undefined
}

const ERTextView = ({ span, setTermId, getProjectTerm }: ERTextViewProps) => {
    let text = span.text || ''
    if (text.endsWith(' ')) {
        text = text.slice(0, -1)
    }

    const termIds = span.getLexicalLinks()

    return (
        <>
            {termIds.map((termId, index) => {
                let _text = text
                if (index !== 0) {
                    _text = '*'
                }

                // Is it okay to use termId as a key? AFAICT, this component only displays
                // a single link to a single term or several links to different terms, but
                // not several links to the same term.
                if (termId.trim() === '') {
                    return <ERPlainText key={termId} text={_text} />
                }

                return (
                    <ERLink
                        key={termId}
                        text={_text}
                        termId={termId}
                        spanType={span.type}
                        getProjectTerm={getProjectTerm}
                        setTermId={setTermId}
                    />
                )
            })}
        </>
    )
}

type ERKeyTermLinksProps = {
    span: ERSpan
    setTermId: (id: string) => void
    getProjectTerm: (id: string) => ProjectTerm | undefined
}

const ERKeyTermLinks = ({ span, setTermId, getProjectTerm }: ERKeyTermLinksProps) => {
    function doesLinkToKeyTerm(termId: string) {
        return getProjectTerm(termId)?.isKeyTerm ?? false
    }

    const termIds = span.getLexicalLinks()

    return (
        <>
            {termIds.filter(doesLinkToKeyTerm).map((termId) => (
                // Is it okay to use termId as a key? AFAICT, each item in this list links
                // to a different term.
                <StarButton
                    key={termId}
                    buttonClassName="star-button"
                    enabled
                    onClick={() => setTermId(termId)}
                    className="star-icon"
                    tooltip=""
                />
            ))}
        </>
    )
}
const ERSpanView: FC<IERSpanView> = ({ span, setTermId, rt }) => {
    if (span.type === 'verse')
        return (
            <span data-marbleid={span.id || ''} className="er-v">
                {span.verse}
            </span>
        )

    const isImage = span.attributes && span.attributes.image_links

    let postText = ''
    let text = span.text || ''
    if (text.endsWith(' ')) {
        text = text.slice(0, -1)
        postText = ' '
    }

    return (
        <span data-marbleid={span.id || ''}>
            {isImage && <ERImageView span={span} />}
            <ERTextView span={span} setTermId={setTermId} getProjectTerm={rt.project.getProjectTerm.bind(rt.project)} />
            <ERKeyTermLinks
                span={span}
                setTermId={setTermId}
                getProjectTerm={rt.project.getProjectTerm.bind(rt.project)}
            />
            {postText}
        </span>
    )
}
class SpanGroup {
    color = 0

    spans: ERSpan[] = []
}

interface IERSpanGroup {
    rt: Root
    spanGroup: SpanGroup
    setTermId: (id: string) => void
}

const ERSpanGroup: FC<IERSpanGroup> = ({ spanGroup, setTermId, rt }) => {
    return (
        <span className={`er-highlight-${spanGroup.color}`}>
            {spanGroup.spans.map((span, key) => (
                <ERSpanView key={key} {...{ rt, span, setTermId }} />
            ))}
        </span>
    )
}
interface IERDivView {
    rt: Root
    erDiv: ERDiv
    highlights: PassageHighlight[]
    setTermId: (id: string) => void
    resource: string
    previousErDiv: ERDiv | null
}

const ERDivView: FC<IERDivView> = ({ erDiv, highlights, setTermId, resource, rt, previousErDiv }) => {
    // Divide spans up into groups that all have the same value for highlighting
    const spanGroups: SpanGroup[] = []

    erDiv.spans.forEach((span) => {
        const color = PassageHighlight.highlighted(highlights, span.id || '', resource)
        const lastGroup = spanGroups.slice(-1)[0]

        if (!lastGroup || lastGroup.color !== color) {
            const newGroup = new SpanGroup()
            newGroup.color = color
            newGroup.spans.push(span)
            spanGroups.push(newGroup)
        } else {
            lastGroup.spans.push(span)
        }
    })

    let heading = ''
    if (previousErDiv && erDiv.bbbccc !== previousErDiv.bbbccc) {
        const BBB = erDiv.bbbccc.slice(0, 3)
        const CCC = erDiv.bbbccc.slice(3, 6)
        const rr = new RefRange(BBB, BBB)
        heading = `${rt.displayableReferences([rr])} ${parseInt(CCC)}`
    }

    return (
        <div data-marbleid={erDiv.id} className={`er-${erDiv.style}`}>
            {heading && <div className="er-new-chapter">{heading}</div>}
            {spanGroups.map((spanGroup, key) => (
                <ERSpanGroup key={key} rt={rt} spanGroup={spanGroup} setTermId={setTermId} />
            ))}
        </div>
    )
}

interface IERDivs {
    rt: Root
    erDivs: ERDiv[]
    resource: string
    setHighlightRange: (highlightRange: HighlightRange | null) => void
    setTermId: (resource: string) => void
}

const ERDivs: FC<IERDivs> = ({ rt, erDivs, setHighlightRange, setTermId, resource }) => {
    const highlights = rt.passageVideo?.highlights ?? []

    let className = ''
    if (resource === 'BHS') {
        className = 'bhs-text'
    } else if (resource === 'UBSGNT5') {
        className = 'ubsgnt5-text'
    }

    return (
        <div
            className={`er er-divs ${className}`}
            dir={resource === 'BHS' ? 'rtl' : undefined}
            onMouseUp={(e: any) => erDivsMouseUpHandler(e, setHighlightRange)}
        >
            {erDivs.map((erDiv, key) => (
                <ERDivView
                    key={key}
                    {...{
                        rt,
                        erDiv,
                        setTermId,
                        highlights,
                        resource,
                        previousErDiv: key > 0 ? erDivs[key - 1] : null
                    }}
                />
            ))}
        </div>
    )
}
interface IERBody {
    rt: Root
    setHighlightRange: (highlightRange: HighlightRange | null) => void
    resource: string
    refs: RefRange[]
}

const ERBody: FC<IERBody> = ({ rt, resource, refs, setHighlightRange }) => {
    const [termId, setTermId] = useState('')
    const [erDivs, setErDivs] = useState<ERDiv[]>([])
    const latestResourceRef = useRef<string>('')

    useEffect(() => {
        const fetchERDivs = async () => {
            if (!refs.length) return

            const refsString = refs.map((r) => r.startRef + r.endRef).toString()
            const resourceRef = resource + refsString
            latestResourceRef.current = resourceRef
            try {
                const _erDivs = await EnhancedResources.fetchRefs(resource, refs)

                // Make sure the last call to this function is the one that takes precendence
                // so we avoid a race condition.
                if (resourceRef === latestResourceRef.current) {
                    setErDivs(_erDivs)
                }
            } catch (error) {
                log('!!!', error)
                if (resourceRef === latestResourceRef.current) {
                    setErDivs([])
                }
            }
        }

        fetchERDivs()
    }, [resource, refs])

    return (
        <div>
            {termId && <ERTermModal {...{ rt, termId, setTermId }} />}
            <ERDivs {...{ rt, erDivs, setHighlightRange, setTermId, resource }} />
        </div>
    )
}

// A list of spans with the same background color

// Modal dialog to display an image associated with a word.

// A span representing a word or very short phrase in the enhanced resource.
// If it has lexical_links you can click on it to see info for that term.
// If it has image_links we display an image icon to click to see the image.

interface IEnhancedResourcesViewer {
    rt: Root
}

const EnhancedResourcesViewer: FC<IEnhancedResourcesViewer> = ({ rt }) => {
    const ENHANCED_RESOURCE = 'enhancedResource'

    const initialResource = rt.getDefault(ENHANCED_RESOURCE) || 'NRS89'
    const [resource, setDefaultResource] = useState(initialResource)
    const [highlightRange, setHighlightRange] = useState<HighlightRange | null>(null)

    const [refs, setRefs] = useState<RefRange[]>([])
    const [errored, setErrored] = useState(false)

    const setResource = (defaultResource: string) => {
        rt.setDefault(ENHANCED_RESOURCE, defaultResource)
        setDefaultResource(defaultResource)
    }

    const defaultReferenceId = `${ENHANCED_RESOURCE}Reference`

    const refInput = rt

    const _displayableBookNames = displayableBookNames(rt.project)

    return (
        <div className="er-viewer">
            <div className="er-viewer-toolbar">
                <ReferenceInput
                    {...{
                        refInput,
                        refs,
                        setRefs,
                        defaultReferenceId,
                        errored,
                        setErrored,
                        includeGotoReferenceButton: true
                    }}
                />
                {isSLTTReference(resource) && !isAVTT && (
                    <span className="er-highlight-buttons">
                        <HighlightButton {...{ rt, highlightRange, resource, setHighlightRange, color: 1 }} />
                        <HighlightButton {...{ rt, highlightRange, resource, setHighlightRange, color: 2 }} />
                        <HighlightButton {...{ rt, highlightRange, resource, setHighlightRange, color: 3 }} />
                        <HighlightButton {...{ rt, highlightRange, resource, setHighlightRange, color: 0 }} />
                    </span>
                )}
                <ERResourceSelector {...{ resource, setResource }} />
            </div>
            <div className="er-viewer-body">
                {isSLTTReference(resource) ? (
                    <ERBody {...{ rt, resource, refs, setHighlightRange }} />
                ) : (
                    <RBody {...{ resourceId: resource, refs, displayableBookNames: _displayableBookNames }} />
                )}
            </div>
        </div>
    )
}

export default EnhancedResourcesViewer

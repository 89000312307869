import React, { FC } from 'react'
import { observer } from 'mobx-react'
import { Dropdown, MenuItem } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { Passage } from '../../models3/Passage'
import { Portion } from '../../models3/Portion'

import './ProjectDataFilter.css'

interface IProjectDataFilterDropdown {
    portions: Portion[]
    currentValue: string
    setShowAll: () => void
    setShowCurrentPortion: () => void
    setShowCurrentPassage: () => void
    setPortion: (portion: Portion) => void
    setPassage: (portion: Portion, passage: Passage) => void
}

const ProjectDataFilter: FC<IProjectDataFilterDropdown> = observer(
    ({ portions, currentValue, setShowAll, setShowCurrentPassage, setShowCurrentPortion, setPortion, setPassage }) => {
        const { t } = useTranslation()

        return (
            <Dropdown id="project-data-filter">
                <Dropdown.Toggle className="project-data-filter-toggle">{currentValue}</Dropdown.Toggle>
                <Dropdown.Menu className="project-data-filter-dropdown">
                    <MenuItem className="project-data-filter-item" onClick={setShowAll} value="Show All">
                        {`*${t('Show All')}*`}
                    </MenuItem>
                    <MenuItem
                        className="project-data-filter-item"
                        onClick={setShowCurrentPortion}
                        value="Current Portion"
                    >
                        {`*${t('Current Portion')}*`}
                    </MenuItem>
                    <MenuItem
                        className="project-data-filter-item"
                        onClick={setShowCurrentPassage}
                        value="Current Passage"
                    >
                        {`*${t('Current Passage')}*`}
                    </MenuItem>
                    <MenuItem disabled>{t('Portions')}</MenuItem>
                    {portions.map((portion) => (
                        <MenuItem
                            key={portion._id}
                            className="project-data-filter-item"
                            onClick={() => setPortion(portion)}
                            value={portion.name}
                        >
                            {portion.name}
                        </MenuItem>
                    ))}
                    <MenuItem disabled>{t('Passages')}</MenuItem>
                    {portions.map((portion) =>
                        portion.passages.map((passage) => (
                            <MenuItem
                                key={passage._id}
                                className="project-data-filter-item"
                                onClick={() => setPassage(portion, passage)}
                                value={passage.name}
                            >
                                {passage.name}
                            </MenuItem>
                        ))
                    )}
                </Dropdown.Menu>
            </Dropdown>
        )
    }
)

export default ProjectDataFilter

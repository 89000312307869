import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'

import { isDev } from './components/app/slttAvtt'

i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        defaultNS: 'translation',
        fallbackLng: 'en',
        interpolation: { escapeValue: false },
        debug: isDev,
        react: {
            useSuspense: true
        }
    })

export default i18n

import { observable, computed } from 'mobx'
import _ from 'underscore'
import { t } from 'i18next'

import { DBObject } from './DBObject'
import { Project } from './Project'
import { ProjectStage } from './ProjectStage'
import { calculateRankBefore, remove } from './Utils'

import { fmt } from '../components/utils/Fmt'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:Models')

// A project plan divides the work for each passage into stages and tasks.

// To display project plan from console
//     console.log(JSON.stringify(window.appRoot.rt.project.plans[0].dbg(), null, 4))
// List all task ids
//     window.appRoot.rt.project.plans[0].stages.flatMap(stage => stage.tasks).map(task=>task.id).sort()

export class ProjectPlan extends DBObject {
    @observable stages: ProjectStage[] = []

    @computed get viewableStages() {
        return this.stages.filter((stage) => stage.name !== 'Not started' && stage.name !== 'Finished')
    }

    dbg() {
        const { stages } = this
        return {
            stages: stages.map((stage) => stage.dbg())
        }
    }

    // Verify that the id field for all tasks is unique.
    // Return true if there is a problem.
    checkIds() {
        const tasks = this.stages.flatMap((stage) => stage.tasks)
        const ids = _.pluck(tasks, 'id')
        const uniq = _.uniq(ids)
        const dups = ids.length !== uniq.length
        log('===checkIds', ids.sort().join(' '), dups ? '*Error*' : '*OK*')

        return dups
    }

    /**
     * In SLTT 1.10.1 and earlier there was a serious bug that caused us to generate
     * duplicate id's for tasks. The effect of this was that when you drag a passage
     * to a column with a duplicate id, the passage which display in the earlier
     * column with the same id.
     *
     * Find the duplicate ids in the later positions of the plan and replace them with
     * unique ids.
     *
     * @param dryRun - when true we only update the local data, this will be overwritten on refresh
     */
    async fixIds(dryRun: boolean) {
        const tasks = this.stages.flatMap((stage) => stage.tasks)
        for (let i = 1; i < tasks.length; ++i) {
            const task = tasks[i]
            const prevTasks = tasks.slice(0, i)
            if (prevTasks.find((_task) => _task.id === task.id)) {
                const id = this.getUniqueTaskId(this.stages[task.stagePosition])
                if (dryRun === false) {
                    // must explictly pass false to update non local data
                    await task.setId(id)
                } else {
                    task.id = id
                }
            }
        }

        if (this.checkIds()) {
            log('===FIX FAILED')
        }
    }

    /**
     * Get a new unique id (not! _id) for a task in stage
     *
     * The id field for each task must be unique because it is used to record the status of a PassageVideo.
     * Note that the _id field is unique but we can't use it because it was not present in earlier versions
     * of the data.
     *
     * It is not enough to just look at the id's in the current stage to determine uniqueness.
     * Adding and deleting stages changes the numbering of stages and means that some other
     * stage may already be using the next sequential id from the current stage.
     */
    getUniqueTaskId(projectStage: ProjectStage) {
        const ids = this.stages.flatMap((stage) => stage.tasks.map((task) => task.id))

        // Grab the next taskIndex for this stage
        let taskIndex = projectStage.tasks.length + 1

        while (true) {
            const id = `${projectStage.index}.${taskIndex}`

            // If no stage already contains this id, return it
            if (!ids.includes(id)) return id

            ++taskIndex // Otherwise try the next task index
        }
    }

    // // Syntax of project plan
    // static Parser = Par.createLanguage({
    //     Name: () => Par.regexp(/[^\n]+/),
    //     Difficulty: () => Par.regexp(/(\d+\.?\d*)|(\.\d+)/),
    //     Details: () => Par.regexp(/[^#]*/),

    //     Task: r =>
    //         Par.seq(Par.regexp(/##/), r.Name, Par.whitespace, r.Difficulty, Par.optWhitespace, r.Details)
    //             .map(([_marker, name, _space1, difficulty, _space2, details]) => {
    //                 let task = new ProjectTask('')
    //                 task.name = name.trim()
    //                 task.details = details.trim()
    //                 task.difficulty = parseFloat(difficulty)
    //                 return task
    //             }),
    //                 // new ProjectTask({ name: name.trim(), details: details.trim(), difficulty: parseFloat(difficulty) })),

    //     Stage: r =>
    //         Par.seq(
    //             Par.optWhitespace,
    //             Par.regexp(/#/),
    //             r.Name,
    //             Par.whitespace,
    //             r.Task.sepBy(Par.optWhitespace),
    //         ).map(([_space1, _marker, name, _space2, tasks]) => {
    //             let stage = new ProjectStage('')
    //             stage.name = name.trim()

    //             // What to do about tasks?

    //             return stage
    //             // return new ProjectStage({ name: name.trim(), tasks: tasks })
    //         }),

    //     Plan: r =>
    //         Par.seq(
    //             r.Stage.sepBy(Par.optWhitespace),
    //         ).map(([stages]) => {
    //             let plan = new ProjectPlan()

    //             // what to do about stages?

    //             return plan
    //             // return new ProjectPlan({ stages: stages })

    //         }),
    // })

    // /**
    //  * Create a project plan from a text string. Will throw if the syntax of the string is
    //  * incorrect.
    //  *
    //  * @param planText
    //  */
    // static parsePlan(planText: string) {
    //     // return new ProjectPlan()
    //     // return ProjectPlan.Parser.Plan.tryParse(planText) as ProjectPlan
    // }

    toDocument() {
        return this._toDocument({ model: 8 })
    }

    toSnapshot() {
        const snapshot: any = {}
        snapshot.stages = this.stages.map((stage) => stage.toSnapshot())
        return snapshot
    }

    createStage(name: string, rank: string) {
        name = name.trim()
        if (this.stages.find((s) => s.name === name)) {
            throw Error(`${t('System Error')}: Duplicate name`)
        }

        const creationDate = new Date(Date.now())

        const newId = this.db.getNewId(this.stages, creationDate, 'stg_')
        const stage = new ProjectStage(`${this._id}/${newId}`, this.db)

        stage.name = ProjectStage.validName(name)
        stage.rank = rank

        return stage
    }

    async addStage(addBeforeIndex: number, name: string) {
        const rank = calculateRankBefore(addBeforeIndex, this.stages)

        const stage = this.createStage(name, rank)
        await this.db.put(stage.toDocument())
        this.updateIndices()

        const _stage = _.findWhere(this.stages, { _id: stage._id })
        if (!_stage) throw Error('could not find newly created stage')
        return _stage
    }

    // Remove a stage and update the status of any passages or passageVideos assigned
    // to any tasks in that stage.
    async removeStage(project: Project, _id: string) {
        const plan = project.plans[0]
        if (!plan) {
            return
        }
        const { stages } = plan

        const deletedStageIndex = stages.findIndex((s) => s._id === _id)
        if (deletedStageIndex < 0) {
            return
        }

        // be careful! id != _id. id (e.g. '1.3') is what is stored in passageVideo

        const oldStatuses = stages[deletedStageIndex].tasks.map((task) => task.id)
        const newStatus = stages.slice(deletedStageIndex + 1).flatMap((stage) => stage.tasks)[0].id
        await this.updateVideoStatus(project, oldStatuses, newStatus)

        await remove(stages, _id)
        this.updateIndices()
    }

    async updateVideoStatus(project: Project, oldStatuses: string[], newStatus: string) {
        const passages = project.portions.flatMap((portion) => portion.passages)
        //! !! do we need to do this on deleted videos also to update their status?
        const passageVideos = passages.flatMap((passage) => passage.videosNotDeleted)

        log('updateVideoStatus', fmt({ oldStatuses, newStatus, currentStatuses: passageVideos.map((v) => v?.status) }))

        for (const passageVideo of passageVideos) {
            if (oldStatuses.includes(passageVideo?.status || 'NOSTATUS')) {
                await passageVideo.setStatus(newStatus)
            }
        }

        log('updateVideoStatus updates', fmt({ updatedStatuses: passageVideos.map((v) => v?.status) }))
    }

    updateIndices() {
        const { stages } = this
        for (const [i, stage] of stages.entries()) {
            stage.index = i // Not i + 1, b/c 1st editable stage is the 2nd stage
            stage.updateIndices()
        }
    }

    async addDefaultPlan() {
        await this.addStage(0, 'Not started')
        let stage = this.stages[0]
        await stage.addTask(this, 0, 'Not started', '', 0.0)

        await this.addStage(1, 'Team')
        stage = this.stages[1]
        await stage.addTask(this, 0, 'First Draft', 'Record first video draft', 1.0)
        await stage.addTask(this, 1, 'Review First Draft', 'Record first video draft', 1.0)
        await stage.addTask(this, 2, 'Team Review', 'Team checks video for accuracy, ...', 1.0)
        await stage.addTask(this, 3, 'Second Draft', '', 1.0)

        await this.addStage(2, 'Consultant')
        stage = this.stages[2]
        await stage.addTask(this, 0, 'Consultant Review', '', 1.0)
        await stage.addTask(this, 1, 'Third Draft', '', 1.0)

        await this.addStage(3, 'Finished')
        stage = this.stages[3]
        await stage.addTask(this, 0, 'Finished', '', 0.0)
    }

    get tasks() {
        return this.stages.flatMap((stage) => stage.tasks)
    }
}

import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { observable } from 'mobx'
import { t } from 'i18next'

import TextInput from '../../utils/TextInput'
import '../ProjectSettings.css'
import { PencilButton } from '../../utils/Buttons'
import { validBookNameParser } from '../../../scrRefs/bookNames'
import { BookNameView } from './BookNameRow'

interface IBookNameEditor {
    bookName: string
    save: (bookName: string) => void
}

class BookNameEditor extends Component<IBookNameEditor> {
    @observable editing = false

    constructor(props: IBookNameEditor) {
        super(props)
        this.validate = this.validate.bind(this)
        this.saveIfValidAndClose = this.saveIfValidAndClose.bind(this)
    }

    saveIfValidAndClose(bookName: string) {
        const { save } = this.props

        if (!this.validate(bookName)) {
            save(bookName)
        }
        this.editing = false
    }

    validate(value: string) {
        try {
            validBookNameParser.tryParse(value)
            return ''
        } catch (error) {
            return t('Invalid book name')
        }
    }

    render() {
        const { bookName } = this.props
        const { editing, validate, saveIfValidAndClose } = this
        return editing ? (
            <TextInput
                initialValue={bookName}
                message=""
                _onEscape={() => (this.editing = false)}
                _onEnter={saveIfValidAndClose}
                validate={validate}
            />
        ) : (
            <div className="book-name-row-not-editing">
                <div onClick={() => (this.editing = true)}>
                    <BookNameView bookName={bookName} />
                </div>
                <div className="book-name-buttons">
                    <PencilButton
                        enabled
                        onClick={() => (this.editing = true)}
                        className="book-name-edit-button"
                        tooltip={t('Edit')}
                    />
                </div>
            </div>
        )
    }
}

export default observer(BookNameEditor)

import { Component } from 'react'
import { observer } from 'mobx-react'
import { t } from 'i18next'

import { observable } from 'mobx'
import BookNameRow from './BookNameRow'
import { Root } from '../../../models3/Root'
import { getBookNames } from '../../../scrRefs/bookNames'
import { localeLanguages } from '../../utils/Languages'
import { LocaleSelector } from '../../app/LocaleSelector'

interface IPrepopulateBookNamesControl {
    initialValue: string
    populateBookNames: (language: string) => void
}

@observer
class PrepopulateBookNamesControl extends Component<IPrepopulateBookNamesControl> {
    @observable language = ''

    constructor(props: IPrepopulateBookNamesControl) {
        super(props)
        const { initialValue } = this.props

        this.language = initialValue
    }

    chooseOption = (value: string) => {
        this.language = value
    }

    render() {
        const { populateBookNames } = this.props
        const { language, chooseOption } = this
        return (
            <label className="project-references-populate-book-names-control">
                <span>{t('Set book names to...')}</span>
                <span className="font-weight-normal">
                    <LocaleSelector options={localeLanguages} currentValue={language} chooseOption={chooseOption} />
                </span>
                <button
                    type="button"
                    className="project-references-fill-button"
                    onClick={() => populateBookNames(language)}
                >
                    {t('Fill in book names')}
                </button>
            </label>
        )
    }
}
interface IProjectReferences {
    rt: Root
}

class ProjectReferences extends Component<IProjectReferences> {
    saveProjectBookName = (newBookName: string, index: number) => {
        const { rt } = this.props

        const bbbccc = `${index + 1}`.padStart(3, '0')
        rt.project.setBookName(bbbccc, newBookName)
    }

    prepopulateBookNames = (language: string) => {
        const { rt } = this.props
        const { bookNames, setBookName } = rt.project
        const names = getBookNames(language)
        const existingBookEntries = Object.keys(bookNames)
        existingBookEntries.forEach((entry, i) => {
            setBookName(entry, names[i])
        })
    }

    render() {
        const { saveProjectBookName, prepopulateBookNames } = this
        const { rt } = this.props
        const { iAmAdmin, uiLanguage, project } = rt

        const entries = Object.entries(project.bookNames)

        const uiNames = getBookNames(uiLanguage)

        const projectBookNames = entries.map((entry) => entry[1])

        if (uiNames.length !== projectBookNames.length) {
            return null
        }

        return (
            <div>
                <h3>{t('Book Names')}</h3>
                {iAmAdmin && (
                    <PrepopulateBookNamesControl
                        key={uiLanguage}
                        initialValue={uiLanguage}
                        populateBookNames={prepopulateBookNames}
                    />
                )}
                <table>
                    <thead className="project-references-table-header">
                        <tr>
                            <th className="project-references-table-item">{t('Name')}</th>
                            <th className="project-references-table-item">{t('Name in Project')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {uiNames.map((name, i) => (
                            <BookNameRow
                                rt={rt}
                                key={name}
                                uiBookName={name}
                                projectBookName={projectBookNames[i]}
                                saveProjectBookName={(newBookName) => saveProjectBookName(newBookName, i)}
                            />
                        ))}
                    </tbody>
                </table>
            </div>
        )
    }
}

export default observer(ProjectReferences)

import React from 'react'

import { docsUrl } from '../app/slttAvtt'

import { SlttHelp } from './Buttons'
import './Icons.css'

// Horizontal black line bisected by a vertical light blue line
// Refers to a segment of a video

interface IconProps {
    className: string
    tooltip?: string
    style?: any
}
interface PassageSegmentsIconProps extends IconProps {
    id: string
    place: string
    onClick: () => void
    enabled: boolean
    style: any
}

export const PassageSegmentsIcon = ({
    id,
    tooltip,
    place,
    onClick,
    enabled,
    style,
    className
}: PassageSegmentsIconProps) => {
    let verticalColor
    let horizontalColor
    if (enabled) {
        verticalColor = 'lightblue'
        horizontalColor = 'black'
    } else {
        verticalColor = 'lightgrey'
        horizontalColor = 'grey'
    }

    return (
        <SlttHelp id={id} tooltip={tooltip} place={place}>
            {/* <span className={className} onClick={() => onClick && enabled && onClick()} > */}
            <svg
                style={style}
                onClick={() => enabled && onClick()}
                className={`${className} sl-create-passage-segment-svg`}
                viewBox="0 0 24 25"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g>
                    <line x1="0" y1="13" x2="8" y2="13" stroke={horizontalColor} strokeWidth="2" />
                    <line x1="16" y1="13" x2="24" y2="13" stroke={horizontalColor} strokeWidth="2" />
                    <line x1="12" y1="0" x2="12" y2="25" stroke={verticalColor} strokeWidth="4" />
                </g>
            </svg>
            {/* </span> */}
        </SlttHelp>
    )
}

interface PassageVideoReferenceIconProps {
    className: string
    style: any
    onClick: () => void
    tooltip: string
    enabled: boolean
}

export const PassageVideoReferenceIcon = ({
    className,
    style,
    onClick,
    tooltip,
    enabled
}: PassageVideoReferenceIconProps) => {
    let lineColor
    if (enabled) {
        lineColor = 'darkslategray'
    } else {
        lineColor = 'lightgrey'
    }
    return (
        <SlttHelp id="verses.html#addverses" tooltip={tooltip} place="bottom">
            <span className={className} data-toggle="tooltip" onClick={() => enabled && onClick()}>
                <svg style={style} width="24" height="25" xmlns="http://www.w3.org/2000/svg">
                    <g>
                        <line x1="0" y1="13" x2="24" y2="13" stroke={lineColor} strokeWidth="2" />
                        <line x1="9" y1="15" x2="12" y2="22" stroke={lineColor} strokeWidth="2" />
                        <line x1="12" y1="22" x2="15" y2="15" stroke={lineColor} strokeWidth="2" />
                    </g>
                </svg>
            </span>
        </SlttHelp>
    )
}

interface PassageGlossIconProps {
    tooltip: string
    onClick: () => void
    enabled: boolean
    style: any
    className: string
}

export const PassageGlossIcon = ({ tooltip, onClick, enabled, style, className }: PassageGlossIconProps) => {
    let verticalColor
    let horizontalColor
    if (enabled) {
        verticalColor = 'lightblue'
        horizontalColor = 'black'
    } else {
        verticalColor = 'lightgrey'
        horizontalColor = 'grey'
    }

    return (
        <SlttHelp id="glosses.html" tooltip={tooltip} place="bottom">
            <span className={className} onClick={() => enabled && onClick()}>
                <svg
                    style={style}
                    className="sl-create-passage-gloss-svg"
                    width="24"
                    height="25"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g>
                        <line x1="0" y1="13" x2="8" y2="13" stroke={horizontalColor} strokeWidth="2" />
                        <line x1="16" y1="13" x2="24" y2="13" stroke={horizontalColor} strokeWidth="2" />
                        <line x1="12" y1="0" x2="12" y2="4" stroke={verticalColor} strokeWidth="4" />
                        <line x1="12" y1="9" x2="12" y2="16" stroke={verticalColor} strokeWidth="4" />
                        <line x1="12" y1="21" x2="12" y2="25" stroke={verticalColor} strokeWidth="4" />
                    </g>
                </svg>
            </span>
        </SlttHelp>
    )
}

export const HeadsetIcon = ({ className, tooltip }: IconProps) => {
    return (
        <span className={className} data-toggle="tooltip" title={tooltip}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 490.982 490.982">
                <path
                    d="M445.474,148.461h-8.201C426.92,65.167,342.339,0,239.876,0C137.283,0,52.614,65.327,42.437,148.77
	c-23.365,1.606-41.946,20.943-41.946,44.707v118.849c0,24.825,20.191,45.016,45.001,45.016h51.553
	c24.81,0,45.001-20.191,44.986-45.016V193.477c0-24.825-20.191-45.016-45.001-45.016H73.62
	C84.183,82.148,154.636,30.686,239.876,30.686c85.269,0,155.71,51.462,166.272,117.775h-12.196
	c-24.825,0-45.017,20.206-45.017,45.016v118.849c0,24.825,20.207,45.016,45.017,45.016h10.224
	c-2.465,20.74-9.822,37.481-22.483,49.497c-27.142,25.747-69.612,23.736-70.871,23.767h-16.371
	c-0.254-24.61-20.303-44.556-44.97-44.556h-35.703c-24.81,0-45.002,20.207-45.002,45.017v14.913
	c0,24.81,20.192,45.001,45.002,45.001h35.703c19.427,0,35.862-12.434,42.158-29.701l18.294-0.019
	c0.322,0.031,1.857,0.108,4.311,0.108c13.762,0,57.015-2.516,88.346-32.083c19.019-17.901,29.922-42.092,32.667-71.945h10.217
	c24.81,0,45.002-20.191,45.017-45.016V193.477C490.491,168.652,470.284,148.461,445.474,148.461z"
                />
            </svg>
        </span>
    )
}

export const ImageReferencesIcon = ({ className, tooltip }: IconProps) => {
    return (
        <SlttHelp id="images.html" tooltip={tooltip} place="top">
            <span className={`${className} sl-fa-button far fa-image`} />
        </SlttHelp>
    )
}

export const BiblicalSignsIcon = ({ className, tooltip }: IconProps) => {
    return (
        <SlttHelp id="signs.html" tooltip={tooltip} place="top">
            <span className={`${className} sl-fa-button far fa-hand-paper`} />
        </SlttHelp>
    )
}

export const WarningIcon = ({ className, tooltip }: IconProps) => {
    return (
        <span
            className={`${className} sl-fa-button fas fa-fw fa-exclamation-triangle`}
            data-toggle="tooltip"
            title={tooltip}
        />
    )
}

export const SolidCheckCircle = ({ className, tooltip }: IconProps) => {
    return (
        <span className={`${className} sl-fa-button fas fa-fw fa-check-circle`} data-toggle="tooltip" title={tooltip} />
    )
}

export const CheckIcon = ({ className, tooltip, style }: IconProps) => {
    return (
        <span
            className={`${className} sl-fa-button fas fa-fw fa-check`}
            data-toggle="tooltip"
            title={tooltip}
            style={style}
        />
    )
}

export const TimesIcon = ({ className, tooltip }: IconProps) => {
    return <span className={`${className} sl-fa-button fas fa-fw fa-times`} data-toggle="tooltip" title={tooltip} />
}

export const LoadingIcon = ({ className }: Omit<IconProps, 'tooltip'>) => {
    return <span className={`${className} sl-fa-button fas fa-fw fa-spinner fa-spin`} />
}

export const ReferencesIcon = ({ className, tooltip }: IconProps) => {
    return <span className={`${className} sl-fa-button fas fa-fw fa-book`} data-toggle="tooltip" title={tooltip} />
}

export const CopyrightIcon = ({ className, tooltip }: IconProps) => {
    return <span className={`${className} sl-fa-button far fa-fw fa-copyright`} data-toggle="tooltip" title={tooltip} />
}

export const ShareIcon = ({ className, tooltip }: IconProps) => {
    return <span className={`${className} sl-fa-button fas fa-fw fa-users`} data-toggle="tooltip" title={tooltip} />
}

export const TitleIcon = ({ className, tooltip }: IconProps) => {
    return <span className={`${className} sl-fa-button fa fa-fw fa-tag`} data-toggle="tooltip" title={tooltip} />
}

export const QuoteIcon = ({ className, tooltip }: IconProps) => {
    return (
        <span className={`${className} sl-fa-button fas fa-fw fa-quote-left`} data-toggle="tooltip" title={tooltip} />
    )
}

export const ApproveIcon = ({ className, tooltip }: IconProps) => {
    return <span className={`${className} sl-fa-button fas fa-fw fa-thumbs-up`} data-toggle="tooltip" title={tooltip} />
}
export const MembersIcon = ({ className, tooltip }: IconProps) => {
    return <span className={`${className} sl-fa-button fas fa-fw fa-user`} data-toggle="tooltip" title={tooltip} />
}

export const ProjectGroupsIcon = ({ className, tooltip }: IconProps) => {
    return <span className={`${className} sl-fa-button fas fa-sitemap`} data-toggle="tooltip" title={tooltip} />
}

export const DisapproveIcon = ({ className, tooltip }: IconProps) => {
    return (
        <span className={`${className} sl-fa-button fas fa-fw fa-thumbs-down`} data-toggle="tooltip" title={tooltip} />
    )
}

export const ArrowsIcon = ({ className, tooltip }: IconProps) => {
    return (
        <span className={`${className} sl-fa-button fas fa-fw fa-arrows-alt`} data-toggle="tooltip" title={tooltip} />
    )
}

export const PencilIcon = ({ className, tooltip }: IconProps) => {
    return <span className={`${className} sl-fa-button fas fa-pencil-alt`} data-toggle="tooltip" title={tooltip} />
}

export const CloneIcon = ({ className, tooltip }: IconProps) => {
    return <span className={`${className} sl-fa-button fas fa-clone`} data-toggle="tooltip" title={tooltip} />
}

export const GoBackIcon = ({ className, tooltip }: IconProps) => {
    return (
        <span className={`${className} sl-fa-button fas fa-angle-double-left`} data-toggle="tooltip" title={tooltip} />
    )
}

export const GoForwardIcon = ({ className, tooltip }: IconProps) => {
    return (
        <span className={`${className} sl-fa-button fas fa-angle-double-right`} data-toggle="tooltip" title={tooltip} />
    )
}

export const EditIcon = ({ className, tooltip }: IconProps) => {
    return <span className={`${className} sl-fa-button fas fa-edit`} data-toggle="tooltip" title={tooltip} />
}

export const UploadIcon = ({ className, tooltip }: IconProps) => {
    return (
        <span className={`${className} sl-fa-button fas fa-cloud-upload-alt`} data-toggle="tooltip" title={tooltip} />
    )
}

export const CloudDownloadIcon = ({ className, tooltip }: IconProps) => {
    return (
        <span className={`${className} sl-fa-button fas fa-cloud-download-alt`} data-toggle="tooltip" title={tooltip} />
    )
}

export const FileUploadIcon = ({ className, tooltip }: IconProps) => {
    return <span className={`${className} sl-fa-button fas fa-upload`} data-toggle="tooltip" title={tooltip} />
}

export const ConsultantIcon = ({ className, tooltip }: IconProps) => {
    return <span className={`${className} sl-fa-button fa fa-fw fa-user-check`} data-toggle="tooltip" title={tooltip} />
}

export const GotoReferenceIcon = ({ className, tooltip }: IconProps) => {
    return (
        <span
            className={`${className} sl-fa-button fa fa-fw fa-arrow-circle-right`}
            data-toggle="tooltip"
            title={tooltip}
        />
    )
}

export const CogIcon = ({ className, tooltip }: IconProps) => {
    return <span className={`${className} sl-fa-button fas fa-fw fa-cog`} data-toggle="tooltip" title={tooltip} />
}

export const UsersCogIcon = ({ className, tooltip }: IconProps) => {
    return <span className={`${className} sl-fa-button fas fa-users-cog`} data-toggle="tooltip" title={tooltip} />
}

interface CircleIconProps {
    className: string
    style: any
    tooltip: string
}

export const CircleIcon = ({ className, style, tooltip }: CircleIconProps) => {
    return (
        <span
            className={`${className} sl-fa-button fas fa-fw fa-circle`}
            style={style}
            data-toggle="tooltip"
            title={tooltip}
        />
    )
}

export const ThinCircleIcon = ({ className, tooltip, style }: IconProps) => {
    return (
        <span
            className={`${className} sl-fa-button far fa-fw fa-circle`}
            data-toggle="tooltip"
            title={tooltip}
            style={style}
        />
    )
}

export const CheckCircle = ({ className, tooltip, style }: IconProps) => {
    return (
        <span
            className={`${className} sl-fa-button far fa-fw fa-check-circle`}
            data-toggle="tooltip"
            title={tooltip}
            style={style}
        />
    )
}

export const SquareIcon = ({ className, tooltip }: IconProps) => {
    return <span className={`${className} sl-fa-button far fa-square`} data-toggle="tooltip" title={tooltip} />
}

export const ForwardIcon = ({ className, tooltip }: IconProps) => {
    return <span className={`${className} sl-fa-button fas fa-forward`} data-toggle="tooltip" title={tooltip} />
}

export const CompareDraftIcon = ({ className, tooltip }: IconProps) => {
    return (
        <span>
            <SquareIcon className={`${className} compare-draft-left-icon`} tooltip={tooltip} />
            <SquareIcon className={`${className} compare-draft-right-icon`} tooltip={tooltip} />
        </span>
    )
}

export const DbsPlayIcon = ({ className, tooltip }: IconProps) => {
    return <span className={`${className} sl-fa-button fab fa-youtube`} data-toggle="tooltip" title={tooltip} />
}

export const DesktopIcon = ({ className, tooltip }: IconProps) => {
    return <span className={`${className} sl-fa-button fas fa-desktop`} data-toggle="tooltip" title={tooltip} />
}

interface GenericIconProps {
    iconName: string
    className: string
    tooltip: string
}

// iconName, e.g. fa-flag
export const GenericIcon = ({ iconName, className = '', tooltip = '' }: GenericIconProps) => {
    return <span className={`${className} sl-fa-button fas ${iconName}`} data-toggle="tooltip" title={tooltip} />
}

export const DocumentIcon = ({ className, tooltip }: IconProps) => {
    return <span className={`${className} sl-fa-button far fa-file-alt`} data-toggle="tooltip" title={tooltip} />
}

export const PassageDocumentIcon = ({ className, tooltip }: IconProps) => {
    return (
        <SlttHelp id="passages.html#passagedocuments" tooltip={tooltip} place="top">
            <DocumentIcon className={className} tooltip="" />
        </SlttHelp>
    )
}

export const DownloadIcon = ({ className, tooltip }: IconProps) => {
    return <span className={`${className} sl-fa-button fas fa-download`} data-toggle="tooltip" title={tooltip} />
}

export const ClipboardIcon = ({ className, tooltip }: IconProps) => {
    return <span className={`${className} sl-fa-button far fa-clipboard`} data-toggle="tooltip" title={tooltip} />
}

export const TrashIcon = ({ className, tooltip }: IconProps) => {
    return (
        <span
            className={`${className} sl-fa-button fas fa-trash-alt`}
            data-id="trash-button"
            data-toggle="tooltip"
            title={tooltip}
        />
    )
}

export const PublishedBiblesIcon = ({ className, tooltip }: IconProps) => {
    return (
        <SlttHelp id="signs.html" tooltip={tooltip} place="top">
            <span className={`${className} sl-fa-button fas fa-bible`} />
        </SlttHelp>
    )
}

export const HelpLink = ({ className, tooltip }: IconProps) => {
    return (
        <a href={`${docsUrl}/index.html`} target="_blank" rel="noreferrer">
            <span className={`${className} fas fa-question-circle`} data-toggle="tooltip" title={tooltip} />
        </a>
    )
}

export const CopyIcon = ({ className, tooltip }: IconProps) => {
    return <span className={`${className} sl-fa-button far fa-copy`} data-toggle="tooltip" title={tooltip} />
}

export const EnvelopeIcon = ({ className, tooltip }: IconProps) => {
    return <span className={`${className} sl-fa-button fas fa-envelope`} data-toggle="tooltip" title={tooltip} />
}

export const StarIcon = ({ className, tooltip }: IconProps) => {
    return <span className={`${className} sl-fa-button fas fa-star`} data-toggle="tooltip" title={tooltip} />
}

export const HeadphonesIcon = ({ className, tooltip }: IconProps) => {
    return <span className={`${className} sl-fa-button fas fa-headphones`} data-toggle="tooltip" title={tooltip} />
}

export const PDFIcon = ({ className, tooltip }: IconProps) => {
    return <span className={`${className} sl-fa-button far fa-file-pdf`} data-toggle="tooltip" title={tooltip} />
}

export const NoInternetIcon = ({ className }: IconProps) => {
    return (
        <span className={`${className} fa-stack fa-2x`}>
            <span className="fas fa-wifi fa-stack-1x" />
            <span className="ban-icon fas fa-ban fa-stack-2x" />
        </span>
    )
}

export const RecordAudioNoteIcon = ({ className }: IconProps) => {
    return (
        <span className={`${className} fa-stack fa-2x`}>
            <span className="fas fa-circle fa-stack-1x" />
            <span className="fas fa-headset fa-stack-2x" />
        </span>
    )
}

export const FolderIcon = ({ className, tooltip }: IconProps) => {
    return <span className={`${className} sl-fa-button far fa-folder`} data-toggle="tooltip" title={tooltip} />
}

export const PassageResourcesIcon = ({ className, tooltip }: IconProps) => {
    return (
        <SlttHelp id="passages.html#passageresources" tooltip={tooltip} place="top">
            <FolderIcon className={className} tooltip="" />
        </SlttHelp>
    )
}

export const SegmentWarningIcon = () => {
    return <WarningIcon className="right-pane-button segment-warning-icon" tooltip="" />
}

export const EditDifficultyIcon = CogIcon

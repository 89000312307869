import React, { Component } from 'react'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import { confirmAlert } from 'react-confirm-alert'
import { t } from 'i18next'

import { EditableTaskList } from './Task'

import TextInput from '../../utils/TextInput'

import { ProjectStage } from '../../../models3/ProjectStage'
import { Root } from '../../../models3/Root'

import './EditableProjectStage.css'

interface IEditableProjectStage {
    stage: ProjectStage
    index: number
    showStageDetails: boolean
    deleteStage: (_id: string) => void
    rt: Root
}

export class EditableProjectStage extends Component<IEditableProjectStage> {
    @observable editingName = false

    validate = (value: string) => {
        value = value.trim()
        const { rt, stage } = this.props
        const plan = rt.project.plans[0]
        if (!plan) {
            return t('No project plan exists')
        }
        const otherStages = plan.stages.filter((s) => s._id !== stage._id)
        if (otherStages.find((otherStage) => otherStage.name === value)) {
            return t('Duplicate name')
        }
        if (value === '') {
            return t('Empty name')
        }
        return ''
    }

    renameStage = (newName: string) => {
        const { stage, rt } = this.props
        const plan = rt.project.plans[0]
        if (!plan) {
            return
        }
        if (!this.validate(newName)) {
            stage.setName(plan, newName)
        }
        this.editingName = false
    }

    onDelete = (e: any) => {
        const { stage, deleteStage } = this.props
        e.preventDefault()
        this.confirmDeletion(() => {
            deleteStage(stage._id)
        })
    }

    confirmDeletion = (doDeletion: () => void) => {
        const { stage } = this.props

        confirmAlert({
            title: t('Delete stage?'),
            message: `${stage.name}: ${t('Delete this stage and all the tasks in this stage?')}`,
            confirmLabel: t('Delete stage'),
            cancelLabel: t('Keep stage'),
            onConfirm: doDeletion
        })
    }

    render() {
        const { stage, rt } = this.props
        const { editingName, validate } = this
        const { iAmAdmin, useMobileLayout } = rt

        let editableStageName = stage.name
        const parts = stage.name.split(/^(\d+\.?\d*\s*)*/)
        if (parts.length > 0) {
            editableStageName = parts[parts.length - 1]
        }

        return (
            <div>
                <div className="stage-header">
                    {editingName ? (
                        <TextInput
                            initialValue={editableStageName}
                            message={t('Type Enter to change name or Esc to cancel.')}
                            _onEscape={() => {
                                this.editingName = false
                            }}
                            _onEnter={this.renameStage}
                            validate={validate}
                        />
                    ) : (
                        stage.displayedName
                    )}
                    {iAmAdmin && !useMobileLayout && (
                        <div className="stage-menu">
                            <span
                                className="portion-button"
                                data-toggle="tooltip"
                                data-id={`edit-${stage.name}`}
                                title={t('Rename stage.')}
                                onClick={(e) => {
                                    e.preventDefault()
                                    this.editingName = true
                                }}
                            >
                                <i className="fas fa-fw fa-pencil-alt" />
                            </span>
                            <span
                                className="portion-button"
                                data-toggle="tooltip"
                                data-id={`delete-${stage.name}`}
                                title={t('Delete stage.')}
                                onClick={this.onDelete}
                            >
                                <i className="fas fa-fw fa-trash-alt" />
                            </span>
                        </div>
                    )}
                </div>
                <div className="plan-editor-task-list">
                    <EditableTaskList stage={stage} rt={rt} />
                </div>
            </div>
        )
    }
}

export default observer(EditableProjectStage)

// Fetch images info and images from MARBLE

import { ImageMetadata } from './ImageMetadata'
import { RefRange } from '../scrRefs/RefRange'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:MARBLEImages')

class MARBLEImageDescription {
    public languageCode = ''

    public title = ''

    public description = ''
}

class MARBLEImageInfo {
    public id = '' // e.g. "LB00071" --- probably not needed

    public path = '' // e.g. "KNOWLES/600TIF"

    public fileName = '' // e.g. "LB00071B.jpg"

    public copyright = '' // e.g. "Louise Bass © BFBS 1994"

    public definitions: MARBLEImageDescription[] = []

    public references: string[] = [] // e.g. ["00100102000022-00100102100052", "00100102200040"]

    // references format is bbbcccvvv
    // last 5 digits are a word number in original text, ignore, they should go away
    public thumbnail = '' // base64 encoding of thumbnail .jpg
}

export class MARBLEImages {
    // Fetch the information for all MARBLE images in a specific book/chapter

    private static imagesByBbbccc: { [project: string]: ImageMetadata[] } = {}

    static async fetchInfo(bbbccc: string, params: any): Promise<ImageMetadata[]> {
        const { imagesByBbbccc } = MARBLEImages

        let images = imagesByBbbccc[bbbccc]
        if (!images) {
            try {
                images = await MARBLEImages.getMARBLEImages(bbbccc, params)
                imagesByBbbccc[bbbccc] = images
            } catch (error) {
                return []
            }
        }

        return images
    }

    static async getMARBLEImages(
        bbbccc: string, // e.g. 001002 (Gen 2)
        params: any
    ): Promise<ImageMetadata[]> {
        const url = `https://s3.amazonaws.com/sltt-resources/SLMARBLE/images/bychapter/${bbbccc}.json`
        const response = await fetch(url, params)
        if (!response.ok) {
            throw Error(`${response.url}: ${response.statusText}`)
        }

        const info: any = await response.json()
        return info.map((imageInfo: MARBLEImageInfo) => {
            const { id, definitions, path, fileName, copyright, references, thumbnail } = imageInfo
            const refRanges = references.map((reference) => {
                const parts = reference.split('-')
                const start = parts[0].substring(0, 9)
                const end = parts[1] ? parts[1].substring(0, 9) : start
                return new RefRange(start, end)
            })
            const image = new ImageMetadata()
            image.id = id
            image.definitions = definitions
            image.project = ''
            image.shared = true
            image.path = path
            image.fileName = fileName
            image.copyright = copyright
            image.references = refRanges
            image.thumbnail = `data:image/png;base64, ${thumbnail}`
            image.isProjectImage = false

            image.setSortKey()

            return image
        })
    }

    // Fetch blob with medium resolution image.
    static async fetchImage(
        path: string, // path from MARBLEImageInfo
        fileName: string
    ): Promise<Blob> {
        const url = `https://s3.amazonaws.com/sltt-resources/SLMARBLE/images/images_resolutions/Medium/${path}/${fileName}`
        const response = await fetch(url)
        if (!response.ok) {
            throw Error(`${response.url}: ${response.statusText}`)
        }

        const blob: any = await response.blob()
        log('fetchImage', blob.size, url)
        return blob
    }
}

/* eslint-disable no-underscore-dangle */
/* eslint-disable no-await-in-loop */
/* eslint-disable import/no-cycle */
/* eslint-disable max-classes-per-file */

// Library to perform non-PouchDB functions on the server
//     pushBlob - push a blob from a video being recorded in the browser
//     pushFile - push a file from the local machine to the server
//     concatBlobs - concatenate the pushed blobs, push this to S3
//     getUrl - get a signed url to access a S3 video

import { Auth } from 'aws-amplify'
import { t } from 'i18next'

import { fmt, s } from '../components/utils/Fmt'
import { isProjectRestoreInProgress, JSON_MIME_TYPE } from '../components/utils/Helpers'
import { ImageMetadata } from '../resources/ImageMetadata'

import { AuthType } from './AppRoot'
import { ProjectEntity } from './ProjectEntity'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:API')

const intest = localStorage.getItem('intest') === 'true'

const _window = window as any
const apiCalls: any[] = []
_window._apiCalls_ = apiCalls

function delay(duration: number) {
    return new Promise<void>(function (resolve) {
        setTimeout(() => resolve(), duration)
    })
}

/** Retry fetch call with an exponential back off until it succeeds.
@param maxLengthMs Abort after specified ms
*/
export async function retriableFetch(path: string, options: any, maxLengthMs?: number) {
    let gap = 1000
    const abortController = new AbortController()
    const timer = maxLengthMs !== undefined && setTimeout(() => abortController.abort(), maxLengthMs)

    // Since canAccessInternet() can be expensive if excessively used, just check navigator.onLine here.
    // This can give a false positive, but network error is handled below
    while (navigator.onLine) {
        const { signal } = abortController
        try {
            const response = await fetch(path, { ...options, signal })
            if (timer) {
                clearTimeout(timer)
            }
            return response
        } catch (err) {
            const error = err as Error
            if (error.name === 'AbortError') {
                if (timer) {
                    clearTimeout(timer)
                }

                throw new Error(
                    `Retriable fetch did not succeed after specified time of ${maxLengthMs} milliseconds while trying ${path}`
                )
            }

            log('Network Error', error)
            /* retriable network error */
        }

        await delay(gap)
        gap = gap <= 8000 ? 2 * gap : gap
        log(`Trying fetch again ${path} with gap ${gap}`)
    }

    log('Fetch failed because the app is offline')
    throw Error(t('noInternetAccessError', { path }))
}

async function putBlob(url: string, blob: Blob) {
    const options = {
        method: 'PUT',
        headers: { 'Content-Type': blob.type },
        body: blob
    }

    const response = await retriableFetch(url, options)

    if (response.status !== 200) throw Error(response.statusText)
}

async function getBlob(url: string) {
    const options = { method: 'GET' }

    const response = await retriableFetch(url, options)
    if (response.status !== 200) throw Error(response.statusText)

    return response.blob()
}

class RemoteDBItem {
    constructor(public project: string, public seq: number, public doc: any) {}
}

export default class API {
    static id_token: string

    static auth_type: AuthType

    // Determine url to access backend server
    static getHostUrl() {
        return process.env.REACT_APP_BACKEND_URL ?? ''
    }

    static getDBNotificationUrl() {
        return process.env.REACT_APP_DB_NOTIFICATION_URL ?? ''
    }

    static async authorization() {
        const id_token = API.id_token

        // legacy auth
        if (API.auth_type === 'legacy') {
            return `Bearer ${id_token}`
        }

        // make sure Cognito token is renewed if expired
        try {
            const session = await Auth.currentSession()
            if (!session) throw new Error('User must be signed in for this operation!')

            const sessionToken = session.getIdToken().getJwtToken()
            if (sessionToken !== API.id_token) {
                // Update appRoot, which will update local storage and API.
                // Note that we cannot update appRoot context because we are not in a component, so must use this kludge.
                log('Got a new token from Cognito')
                _window.appRoot.setUserToken(sessionToken)
                return sessionToken
            }
        } catch (error) {
            log('Failed to get a new token from Cognito', error)
        }

        log('Using previous token')
        return id_token
    }

    /**
     * Request a registration for the user with the named email.
     *
     * @param email - user email
     *      *email - don't email result to users, root can see result in console
     *      -email - generate link for localhost:300
     *      -*email - both
     *
     * @param includeAuth
     *    root can use when invoke from console
     *      window.API.register('-*wtyas@hotmail.com', true).catch(console.log)
     */
    static async register(email: string, includeAuth?: boolean) {
        const url = window.location.origin + window.location.pathname
        const body = { email, url }
        const _path = `${API.getHostUrl()}/register`

        const options: any = {
            method: 'PUT',
            headers: { 'Content-Type': JSON_MIME_TYPE },
            body: JSON.stringify(body)
        }

        if (includeAuth) {
            options.headers.Authorization = await API.authorization()
        }

        const response = await retriableFetch(_path, options)
        const json = await response.json()
        log('register', json && json.url)
    }

    static async pushBlob(projectName: string, path: string, seqNum: number, blob?: Blob) {
        if (intest) {
            apiCalls.push('/pushBlob')
            return
        }

        if (!blob) {
            throw Error('Blob is undefined in pushBlob!')
        }

        //! eventually we are probably going to want to retrieve multiple urls
        // with a single call and cache them to avoid upload delays
        const urls = await API.getUrls(path, blob.type, seqNum, seqNum, true)
        await putBlob(urls[0], blob)
    }

    // Pass a url for an S3 video file object to server.
    // Get back a singed url that allows downloading the object.
    // This url will expire in 6 days.

    static async getUrl(projectName: string, path: string) {
        // I am NOT supplying an 'intest' dummy version for this api.
        // In order to use these signed urls they must be dynamically generated because
        // they expire after a 24ish hour period.

        // can we treat everything as generic?
        const urls = await API.getUrls(path, 'application/octet-stream', 0, 0, false)
        return urls[0]
    }

    static async _getUrl(projectName: string, path: string): Promise<[Error | null, string]> {
        try {
            return [null, await API.getUrl(projectName, path)]
        } catch (error) {
            return [error as Error, '']
        }
    }

    static async _getBlob(url: string): Promise<[Error | null, Blob]> {
        try {
            return [null, await getBlob(url)]
        } catch (error) {
            return [error as Error, new Blob()]
        }
    }

    // static async getIotUrl(projectName: string) {
    //     let _path = `${API.getHostUrl()}/iotUrl`
    //     let body = {
    //         _id: projectName
    //     }

    //     let response = await retriableFetch(_path, API.getOptions('PUT', body))
    //     let { url } = await response.json()
    //     log('getIotUrl', url)

    //     return url
    // }

    static async sync(project: string, docs: any[], maxseq: number): Promise<RemoteDBItem[]> {
        if (isProjectRestoreInProgress()) {
            throw new Error(t('projectRestoreInProgressError'))
        }
        if (docs.length) {
            log(`sync`, project, maxseq, JSON.stringify(docs, null, 4))
        }

        const body = { project, docs, maxseq }
        const options = await API.getOptions('PUT', body)
        const response = await retriableFetch(`${API.getHostUrl()}/sync`, options, 30000)

        if (!response.ok) {
            throw Error(response.status.toString())
        }

        const json = await response.json()

        // log(`sync success`, JSON.stringify(json, null, 4))

        return json.map((j: any) => new RemoteDBItem(j.project, j.seq, j.doc))
    }

    static async createProject(projectName: string, isGroup: boolean, groupName?: string) {
        log(
            `createProject ${isGroup ? 'group' : 'project'} ${projectName} ${
                groupName ? `for group [${groupName}]` : ''
            } `
        )

        const url = `${API.getHostUrl()}/createproject`
        const body = { project: projectName, isGroup, group: groupName }
        const options = await API.getOptions('PUT', body)
        const response = await fetch(url, options)

        if (!response.ok) {
            const message = await response.text()
            throw Error(message)
        }

        const { project: createdProjectName } = await response.json()
        log(`createProject successfully created [${createdProjectName}]`)
        return createdProjectName
    }

    static async deleteProject(projectName: string, groupName?: string) {
        log(`deleteProject ${projectName}`)

        const body = { project: projectName, group: groupName }
        const options = await API.getOptions('PUT', body)
        await retriableFetch(`${API.getHostUrl()}/deleteproject`, options) // in case of a timeout, try again

        log(`deleteProject success`)
    }

    // Query to get a list of the projects this user is authorized to access.
    // Query also tells us if this is a root user.

    static async getAuthorizedProjects() {
        log('getAuthorizedProjects')

        let json

        try {
            const options = await API.getOptions('GET')
            const result = await retriableFetch(`${API.getHostUrl()}/projects`, options, 30000)
            json = await result.json()
            localStorage.setItem('projects', JSON.stringify(json))
        } catch (error) {
            log('could not fetch projects list from server', error)
        }

        if (!json) {
            let p = localStorage.getItem('projects')
            p = p || '{"iAmRoot": false, "projects": []}'

            json = JSON.parse(p)
            log('fall back to previous project list', json)
        }

        log(`getAuthorizedProjects success`)
        return json as AuthorizedProjects
    }

    static loggedIn() {
        return !!API.id_token
    }

    static async getOptions(method: string, body?: any) {
        if (!API.id_token) {
            throw Error('API call failed, not logged in.')
        }

        const Authorization = await API.authorization()
        const options: any = {
            method: method || 'GET',
            headers: { Authorization }
        }

        if (body) {
            options.headers['Content-Type'] = JSON_MIME_TYPE
            options.body = JSON.stringify(body)
        }

        return options
    }

    static async getUrls(path: string, type: string, startIndex: number, endIndex: number, upload: boolean) {
        // when testing return array of unsigned urls so that we don't have to go out to backend
        if (intest) {
            return [path]
        }

        const body = { _id: path, type, startIndex, endIndex, upload }
        const options = await API.getOptions('PUT', body)
        const response = await retriableFetch(`${API.getHostUrl()}/s3urls`, options)

        if (!response.ok) {
            throw Error(response.statusText || response.status.toString())
        }

        const json = await response.json()
        const { urls } = json
        return urls
    }

    static loginTestUser(testJWT: string) {
        API.id_token = testJWT
    }

    static async putProjectImageMetadata(info: any) {
        const url = `${API.getHostUrl()}/images`
        const body = { image: info }
        const options = await API.getOptions('PUT', body)

        const response = await fetch(url, options)
        if (!response.ok) {
            const message = await response.text()
            throw Error(`${response.url}: ${message}`)
        }
    }

    /** Delete an image.
     * @throws Throws if non-200 HTTP response
     */
    static async deleteProjectImageMetadata(image: ImageMetadata) {
        const url = `${API.getHostUrl()}/images`
        const { project, fileName } = image
        const body = { project, fileName }
        const options = await API.getOptions('DELETE', body)

        const response = await fetch(url, options)
        if (!response.ok) {
            const message = await response.text()
            throw Error(`${response.url}: ${message}`)
        }

        // We don't delete the image from S3, because it takes up very little space,
        // and is thus is not a pressing need.
    }

    static async addCognitoUser(projectName: string, addUserEmail: string) {
        log(`addCognitoUser ${addUserEmail} to project ${projectName}`)

        const url = `${API.getHostUrl()}/addCognitoUser`
        const body = { projectName, addUserEmail }
        const options = await API.getOptions('PUT', body)

        const response = await fetch(url, options)
        if (!response.ok) {
            const message = await response.text()
            throw Error(`${response.url}: ${message}`)
        }

        log(`addCognitoUser successfully added  ${addUserEmail} to project ${projectName}`)
    }
}

// Initiate multipart upload. Returns signed upload urls for each part and an upload id.
export async function createmulti(_id: string, type: string, endIndex: number, upload: boolean) {
    const body = { _id, type, endIndex, upload }

    const options = await API.getOptions('PUT', body)
    const response = await retriableFetch(`${API.getHostUrl()}/createmulti`, options)
    const json = await response.json()

    const { urls } = json
    const { uploadId } = json
    return { urls, uploadId }
}

interface MultipartPart {
    ETag: string
    PartNumber: number
}

// Complete multipart S3 upload and release temporary files.
export async function completemulti(_id: string, uploadId: string, parts: MultipartPart[], viewerUrl: string) {
    const body = { _id, uploadId, parts, viewerUrl }
    log('completemulti', fmt(body))

    const options = await API.getOptions('PUT', body)
    const response = await retriableFetch(`${API.getHostUrl()}/completemulti`, options)
    if (response.status !== 200) {
        throw Error(response.statusText)
    }

    const json = await response.json()
    const { shortUrl } = json

    log(`completemulti DONE`, s(json))

    return shortUrl
}

export interface AuthorizedProjects {
    iAmRoot: boolean
    projects: ProjectEntity[]
}

// allow console.log access to API
_window.API = API

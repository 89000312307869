/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-cycle */
/* eslint-disable max-classes-per-file */

import _ from 'underscore'

import { VideoCache } from './VideoCache'
import { VideoCacheRecord, VideoBlob } from './VideoCacheRecord'
import { IProgress } from './API2'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:VideoCacheDownloader')

// eslint-disable-next-line @typescript-eslint/no-var-requires
const dbg = require('debug')('slttdbg:_VideoCacheDownloader')

const FETCH_LIMIT = 4 // max simultaneous fetches
const PER_URL_FETCH_LIMIT = 3 // max simultaneous fetches to different chunks of same url

const DOWNLOADS_INTERVAL_MS = 10000 // check for new downloads every 10 secs

/**
 * Goal: Download the highest priority videos first.
 *
 * Constraints:
 *   - No more than FETCH_LIMIT (4) simultaneous downloads from S3
 *   - If this block is has been marked 'notUploaded' and there is an active fetch for
 *     a lower numbered block, this black cannot be started
 */

export interface IVideoDownloadQuery {
    message: string
    isError: boolean
    blob: Blob | undefined
}

// Request to download a chunk of a video blob
export class ScheduledDownload {
    public progress: IProgress | null = null // { total: number, loaded: number }
    // when non-null there is a fetch in progress for this chunk

    public delayUntil = 0
    // When non-zero, don't allow this fetch to be started until this date

    public retries = 0

    constructor(
        public videoUrl: string,
        public chunkNum: number, // 0 origin chunk number for this blob
        public priority: number, // bigger is more urgent
        public vcr: VideoCacheRecord
    ) {}

    match(vcr: VideoCacheRecord, chunkNum: number) {
        return this.videoUrl === vcr._id && this.chunkNum === chunkNum
    }

    exponentialBackoffRetry() {
        const backoffs = [1, 1, 2, 3, 5, 8, 13, 21, 34, 55, 89, 144, 233, 377, 610, 987] // Fibonacci
        const backoff = backoffs[Math.min(this.retries, backoffs.length - 1)]

        // for 10 sec interval: 10, 10, 20, 30, 50, 80, 130, 210, 340, 550, 890, 1440, 2330, 3770, 6100, 9870
        this.delayUntil = Date.now() + backoff * DOWNLOADS_INTERVAL_MS

        ++this.retries
    }

    clone() {
        const c = new ScheduledDownload(this.videoUrl, this.chunkNum, this.priority, this.vcr)
        if (this.progress) {
            const { total, loaded } = this.progress
            c.progress = { total, loaded }
        }

        c.delayUntil = this.delayUntil
        c.retries = this.retries

        return c
    }

    static eq(s1: ScheduledDownload, s2: ScheduledDownload) {
        return s1.videoUrl === s2.videoUrl && s1.chunkNum === s2.chunkNum
    }
}

export class VideoCacheDownloader {
    static unittestQuota = 0

    static unittestUsage = 0

    static systemError = (err: any) => {
        console.error(err)
    }

    requestCount = 0
    // track request number so that latest requests
    // can be given higher priority

    scheduledDownloads: ScheduledDownload[] = []

    constructor(public displayError?: (message: any) => void) {
        if (displayError) VideoCacheDownloader.systemError = displayError

        setInterval(this.startDownloads.bind(this), DOWNLOADS_INTERVAL_MS)
    }

    // Return a clone of the scheduled downloads queue.
    // Sort items in progress to the top.
    // This is used for debugging.
    queryScheduledDownloads() {
        const sds = this.scheduledDownloads.map((sd) => sd.clone())

        const compareSd = (sd1: ScheduledDownload, sd2: ScheduledDownload) => {
            if (sd1.progress && !sd2.progress) return -1
            if (!sd1.progress && sd2.progress) return 1
            if (sd1.priority < sd2.priority) return 1
            if (sd1.priority > sd2.priority) return -1
            return 0
        }

        return sds.sort(compareSd)
    }

    static getProgress(videoUrl: string) {
        const vcr = VideoCacheRecord.get(videoUrl)
        const { numberUploaded, numberDownloaded, totalBlobs } = vcr
        return { numberUploaded, numberDownloaded, totalBlobs }
    }

    // This method gets called every few seconds from a control that needs the
    // specified video data. If the download is complete the blob is returned.
    // If no download has been scheduled yet for the url it is scheduled.
    // Otherwise a progress message for the download is returned.
    async queryVideoDownload(videoUrl: string): Promise<IVideoDownloadQuery> {
        const vcr = VideoCacheRecord.get(videoUrl)

        // If data is already present because the video was locally created, return video blob
        if (vcr.uploadeds.length) {
            dbg(`queryVideoDownload locally created`)
            const blob = await vcr.getVideoBlob()
            if (blob) {
                return { message: '', isError: false, blob }
            }
        }

        // Ensure status array for downloads of individual chunks of video initialized
        await vcr.setupDownload()

        // If already completely downloaded, return video blob
        if (vcr.downloaded) {
            dbg(`queryVideoDownload downloaded`)
            const blob = await vcr.getVideoBlob()
            if (blob) {
                return { message: '', isError: false, blob }
            }
            // If we did not get the blob, it means that we incorrect in
            // thinking it was already downloaded, fall through to download
        }

        this.scheduleDownload(vcr, true)

        return { message: vcr.downloadMessage, isError: vcr.downloadErrors.some(Boolean), blob: undefined }
    }

    async implicitVideoDownload(videoUrl: string) {
        log(`implicitVideoDownload ${videoUrl}`)

        const vcr = VideoCacheRecord.get(videoUrl)

        // If data is already present because the video was locally created, return video blob
        if (vcr.uploadeds.length) return true

        // Ensure status array for downloads of individual chunks of video initialized
        await vcr.setupDownload()

        // If already compltedly downloaded, done
        if (vcr.downloaded) return true

        this.scheduleDownload(vcr, false)

        return false
    }

    // Add any entries to scheduledDownloads.
    // Each entry represents a chunk that needs to be downloaded
    scheduleDownload(vcr: VideoCacheRecord, explicitRequest: boolean) {
        // process chunks backwards because we want to start downloading
        // with first chunk and the last chunk processed has the hightest
        // priority
        for (let i = vcr.downloadeds.length - 1; i >= 0; --i) {
            if (!vcr.downloadeds[i]) {
                // chunk has not downloaded yet

                this.requestCount += 1
                // The recently requested videos have the higher download priority
                // If a video has been explicitly requested for display by a user
                // it gets a big priority bump.
                const priority = this.requestCount + (explicitRequest ? 1000000 : 0)

                const j = this.scheduledDownloads.findIndex((sd) => sd.match(vcr, i))
                if (j >= 0) {
                    // If download is already scheduled but its priority has gone up,
                    // bump up priority
                    if (priority > this.scheduledDownloads[j].priority) this.scheduledDownloads[j].priority = priority
                } else {
                    // If download not scheduled yet, schedule it
                    log('add ScheduledDownload', vcr.videoBlobId(i), priority)

                    const sd = new ScheduledDownload(vcr._id, i, priority, vcr)
                    this.scheduledDownloads.push(sd)
                }
            }
        }

        this.startDownloads()
    }

    startDownloads() {
        const sds = this.scheduledDownloads
        if (!sds.length) {
            log('Skipping starting downloads because there is nothing to download')
            return
        }

        const now = Date.now()

        const urlCount = _.countBy(
            sds.filter((sd) => sd.progress),
            (sd) => sd.videoUrl
        )

        const calcPriority = (sd: ScheduledDownload) => {
            const { delayUntil, progress, videoUrl, chunkNum, priority, vcr } = sd

            // After errors, we delay a while before retrying
            if (delayUntil && delayUntil > now) return -1

            // Should not restart if already in progress
            if (progress) return -2

            // Only allow a limited number fetches for each url (so that
            // one video cannot hog all the download bandwidth)
            if ((urlCount[videoUrl] || 0) >= PER_URL_FETCH_LIMIT) return -3

            // If an earlier chunk has not been uploaded (error=403), don't try to download this chunk
            if (vcr.downloadErrors.slice(0, chunkNum).includes('403 Forbidden')) return -4

            return priority
        }

        // Since canAccessInternet() can be expensive if excessively used, just check navigator.onLine here.
        // This can give a false positive, but network error is handled below
        log('Starting downloads')
        while (navigator.onLine) {
            const inProgress = this.scheduledDownloads.filter((sd) => sd.progress)
            dbg(`inProgress = ${inProgress.length}`)

            if (inProgress.length >= FETCH_LIMIT) break

            const topSd = _.max(sds, calcPriority) as ScheduledDownload

            // Break if best download items have negative priority
            const priority = calcPriority(topSd)
            if (priority < 0) {
                dbg(`negative priority [${topSd.videoUrl}] ${priority}`)
                break
            }

            urlCount[topSd.videoUrl] += 1
            topSd.progress = { total: 0, loaded: 0 }

            this.startDownload(topSd).catch((error) => {
                // NEVER supposed to happen
                VideoCacheDownloader.systemError(error)
            })
        }
    }

    // Start download for a single video chunk.
    // DO NOT throw an exception.
    // On error
    //      - write message to vcr
    //      - remove request from scheduledDownloads
    async startDownload(sd: ScheduledDownload) {
        const _id_ = `${sd.videoUrl}[${sd.chunkNum}]`

        dbg(`startDownload ${_id_}`)
        let message = ''

        try {
            const vcr = VideoCacheRecord.get(sd.videoUrl)

            // Clear out space in cache if necessary
            if (!(await this.makeSpaceAvailable())) {
                await vcr.setDownloadMessage('No disk space available')
                this.downloadCompleted(sd)
                return
            }

            if (vcr.downloadeds[sd.chunkNum]) {
                this.downloadCompleted(sd)
                return
            }

            const recordProgress = (progress: IProgress) => {
                // log(`reportProgress ${_id_}`, progress)

                if (!sd.progress) {
                    return
                }

                sd.progress.total = progress.total
                sd.progress.loaded = progress.loaded
                this.setProgressMessage(sd.videoUrl).catch()
            }

            message = await vcr.fetchBlob(sd.chunkNum, recordProgress)

            if (message) {
                // fetch failed
                sd.exponentialBackoffRetry()
                await vcr.setDownloadMessage(message)
            } else {
                // fetchBlob succeeded
                await this.setProgressMessage(sd.videoUrl)
                this.downloadCompleted(sd)
            }

            sd.progress = null
        } catch (err) {
            sd.progress = null
            sd.exponentialBackoffRetry()
            VideoCacheDownloader.systemError(err)
        }
    }

    // Set progress message by totaling the number of partially downloaded
    // blocks and fully downloaded blocks.

    async setProgressMessage(videoUrl: string) {
        const vcr = VideoCacheRecord.get(videoUrl)

        let downloadedBlocks = vcr.downloadeds.filter((d) => d).length

        let partialBlocks = 0
        for (const sd of this.scheduledDownloads) {
            if (sd.videoUrl === videoUrl && sd.progress && sd.progress.loaded) {
                // If a bug causes a block that has been previously downloaded to
                // start downloading again, avoid double counting the block
                // when calculating progress
                if (vcr.downloadeds[sd.chunkNum]) {
                    downloadedBlocks -= 1
                }

                partialBlocks += sd.progress.loaded / sd.progress.total
            }
        }

        const totalBlocks = vcr.seqNum()
        const percent = (100 * (partialBlocks + downloadedBlocks)) / totalBlocks

        await vcr.setDownloadMessage(`Downloading ... ${percent.toFixed(1)}%`)
    }

    downloadCompleted(sd: ScheduledDownload) {
        const _id_ = `${sd.videoUrl}[${sd.chunkNum}]`
        log('downloadCompleted', _id_)

        this.scheduledDownloads = this.scheduledDownloads.filter((_sd) => !ScheduledDownload.eq(_sd, sd))
        this.startDownloads()
    }

    /**
     *  Limit cache size  to 90% of available space by deleting old entries.
     *  @return {boolean} True iff we can delete enough items to fall within our space quota
     */
    async makeSpaceAvailable() {
        let tries = 0

        while (true) {
            let requiredToFree = await this.checkQuota()
            if (requiredToFree === 0) return true

            let vcrs = await VideoCache.getAllVcrs()
            vcrs = _.sortBy(vcrs, (x) => x.accessDate)
            log(`makeSpaceAvailable freeing ${(requiredToFree / (1024 * 1024)).toFixed(0)}`)

            for (let i = 0; i < vcrs.length; ++i) {
                const vcr = vcrs[i]

                // Do not delete blobs that have not been uploaded yet!
                if (vcr.uploadeds.length > 0 && !vcr.uploaded) continue

                // If nothing has been downloaded for this, there is nothing to delete
                if (!vcr.downloadeds.some((d) => d)) continue

                requiredToFree -= vcr.size
                await vcr.deleteBlobs()

                // if (VideoCacheDownloader.unittestQuota) {
                //     // simulate usage going down due to deletion
                //     VideoCacheDownloader.unittestUsage -= vcrs[i].size
                // }

                if (requiredToFree <= 0) break
            }

            tries += 1
            if (tries > 10) {
                return false // give up
            }
        }
    }

    /**
     *  @return {number} 0 if we are within quota, otherwise desired amount to free
     */
    async checkQuota() {
        const estimate = await this.getQuotaAndUsage()

        return estimate.usage <= estimate.quota ? 0 : estimate.usage - 0.9 * estimate.quota
    }

    // Get structure with current storage 'usage' and 'quota'.
    // Quota is what the browser is willing to give us or the hard limit of 10G,
    // whichever is less.
    async getQuotaAndUsage(): Promise<any> {
        const estimate = await navigator.storage.estimate()

        if (VideoCacheDownloader.unittestQuota) estimate.quota = VideoCacheDownloader.unittestQuota
        if (VideoCacheDownloader.unittestUsage) estimate.usage = VideoCacheDownloader.unittestUsage

        if (estimate.usage === undefined || estimate.quota === undefined) throw Error('Undefined storage estimage')

        const persistedHardQuota = localStorage.getItem('videoCacheLimitGB') || ''
        const persistedHardQuotaGB = parseFloat(persistedHardQuota) || 10
        const hardQuota = persistedHardQuotaGB * 1024 * 1024 * 1024
        const quota = 0.8 * estimate.quota
        estimate.quota = quota < hardQuota ? quota : hardQuota
        log(
            `quota=${(estimate.quota / (1024 * 1024)).toFixed(0)}, usage=${(estimate.usage / (1024 * 1024)).toFixed(0)}`
        )

        return estimate
    }

    // Run stress test on cacheing mechanism by creating a ton of VideoCacheRecords
    // and associated VideoBlobs.
    // In theory this should be able to run indefinitely since makeSpaceAvailable
    // should keep freeing blobs.
    // Execut from console: window.videoCacheStressTest()

    async stressTest() {
        // create blob
        const buffer = new ArrayBuffer(8 * 1024 * 1024)
        const blob = new Blob([buffer])
        let blobCount = 0

        VideoCacheDownloader.unittestQuota = 4 * 1024 * 1024 * 1024

        while (true) {
            const url = String(blobCount).padStart(8, '0')

            blobCount += 1
            if (Math.round(blobCount) % 50 === 0) {
                log(`created ${url}`)
                if (!(await this.makeSpaceAvailable())) {
                    console.error('makeSpaceAvailable failed')
                    return
                }
            }

            const vcr = new VideoCacheRecord({ _id: `${url}-1` })
            vcr.downloadeds = [true]
            vcr.size = 8 * 1024 * 1024

            try {
                await vcr.saveToDB()
            } catch (error) {
                log(`vcr.saveToDB failed`, error)
                return
            }

            // store it in cache
            const videoBlob = new VideoBlob(`${url}-1`, blob)

            try {
                await videoBlob.saveToDB()
            } catch (error) {
                log(`fetchBlob videoBlob.saveToDB failed`, error)
                return
            }
        }
    }
}

import React, { FC, useState } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import Modal from 'react-bootstrap/lib/Modal'

import { Portion } from '../../models3/Portion'
import { Root } from '../../models3/Root'

import { CancelEditSegmentButton, OkEditSegmentButton } from '../utils/Buttons'
import { displayError } from '../utils/Errors'
import { ReferencesIcon, TitleIcon } from '../utils/Icons'
import { ReferenceInput } from '../utils/ReferenceInput'

interface IPortionNameInput {
    rt: Root
    portion: Portion
    portionName: string
    setPortionName: (name: string) => void
    nameError: string
    setNameError: (name: string) => void
    onEnter?: () => void
}

const PortionNameInput: FC<IPortionNameInput> = ({
    rt,
    portion,
    portionName,
    setPortionName,
    nameError,
    setNameError,
    onEnter
}) => {
    const { t } = useTranslation()

    function validateName(name: string) {
        const newName = name.trim()

        if (newName === '') {
            return t('Empty name')
        }

        if (newName === portion.name) {
            return ''
        }

        if (rt.project.portions.some((projectPortion) => projectPortion.name === newName)) {
            return t('Duplicate name')
        }

        return ''
    }

    function onChange(e: React.ChangeEvent<HTMLInputElement>) {
        const name = e.target.value
        setPortionName(name)
        setNameError(validateName(name))
    }

    return (
        <div className="portion-name-input">
            <div>
                <input
                    autoFocus
                    value={portionName}
                    onKeyDown={(e) => e.keyCode === 13 && onEnter && onEnter()}
                    onChange={onChange}
                />
                {nameError && <span className="portion-error"> {nameError}</span>}
            </div>
        </div>
    )
}

interface IPortionEditor {
    rt: Root
    portion: Portion
    setEditing: (editing: boolean) => void
}

const PortionEditor: FC<IPortionEditor> = ({ rt, portion, setEditing }) => {
    const { t } = useTranslation()
    const [portionName, setPortionName] = useState(portion.name)
    const [nameError, setNameError] = useState('')
    const [references, setReferences] = useState(portion.references)
    const [referencesError, setReferencesError] = useState(false)

    function save() {
        if (portion.name !== portionName && !nameError) {
            portion.setName(portionName).catch(displayError)
        }

        if (!referencesError) {
            portion
                .setReferences(references)
                .then(() => rt.setDbsRefs(rt.portion))
                .catch(displayError)
        }
        setEditing(false)
    }

    return (
        <Modal style={{ top: '1%' }} show onHide={() => setEditing(false)} backdrop="static">
            <Modal.Header closeButton> {t('Portion')} </Modal.Header>
            <Modal.Body>
                <div className="portion-info-title">
                    <TitleIcon className="portion-info-icon" tooltip={t('Title')} />
                    <PortionNameInput
                        {...{ rt, portion, portionName, setPortionName, nameError, setNameError, onEnter: save }}
                    />
                </div>
                <div className="portion-info-input">
                    <ReferencesIcon className="portion-info-icon" tooltip={t('References')} />
                    <ReferenceInput
                        refInput={rt}
                        onEnter={save}
                        refs={references}
                        setRefs={setReferences}
                        errored={referencesError}
                        setErrored={setReferencesError}
                        includeGotoReferenceButton={false}
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <OkEditSegmentButton enabled={!nameError && !referencesError} onClick={save} />
                <CancelEditSegmentButton enabled onClick={() => setEditing(false)} />
            </Modal.Footer>
        </Modal>
    )
}

export default observer(PortionEditor)

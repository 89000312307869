import { Component, FC } from 'react'
import { observable } from 'mobx'
import { observer /* inject */ } from 'mobx-react'
import SplitPane from 'react-split-pane'

import { isAVTT } from '../app/slttAvtt'
import { BiblicalTermMarkerEditor } from '../glosses/BiblicalTermMarkerEditor'
import { displayError, systemError } from '../utils/Errors'

import { BiblicalTermMarker } from '../../models3/BiblicalTermMarker'
import { PassageVideo } from '../../models3/PassageVideo'
import { getVisiblePassageOrPortionRefRanges } from '../../models3/ProjectReferences'
import { Root } from '../../models3/Root'

import RootVideoPlayer from './RootVideoPlayer'
import { VideoMainBottom } from './VideoMainBottom'
import VideoToolbar from './VideoToolbar'
import VideoRecorder, { AudioRecorderComponent, AVTTRecordingState } from './VideoRecorder'
import { RecordingDoneParams, VideoUploader } from './VideoUploader'

import './Video.css'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:VideoMain')

const htmlElementVisible = (el: HTMLElement) => el.offsetWidth > 0

function getAbsoluteYPosition(el: HTMLElement | null) {
    let y = 0
    while (el) {
        y += el.offsetTop - el.scrollTop + el.clientTop
        el = el.offsetParent as HTMLElement | null
    }
    return y
}

function positionOfVisibleElements(videoMain: HTMLElement, compareDraftVideoMain: HTMLElement) {
    const vmParentPos = getAbsoluteYPosition(videoMain.offsetParent as HTMLElement | null)
    const cdvmPos = getAbsoluteYPosition(compareDraftVideoMain)
    const BORDER_AND_PADDING_OFFSET = 6 // Video main has 5px padding + 1px border
    return cdvmPos - vmParentPos - BORDER_AND_PADDING_OFFSET
}

function positionOfExistingElements(videoMainElement: HTMLElement, compareDraftVideoMainElement: HTMLElement) {
    const videoMainElementVisible = htmlElementVisible(videoMainElement)
    const compareDraftVideoMainElementVisible = htmlElementVisible(compareDraftVideoMainElement)
    if (videoMainElementVisible && compareDraftVideoMainElementVisible) {
        return positionOfVisibleElements(videoMainElement, compareDraftVideoMainElement)
    }
    return 0
}
interface WrapperProps {
    useSplitPane: boolean
}

const OptionalSplitPane: FC<WrapperProps> = observer(({ children, useSplitPane }) => {
    return useSplitPane ? (
        <SplitPane
            split="horizontal"
            minSize={300}
            defaultSize={300}
            maxSize={750}
            style={{ position: 'relative', height: 'auto', overflow: 'visible' }}
        >
            {children}
        </SplitPane>
    ) : (
        <div>{children}</div>
    )
})

interface IVideoMain {
    rt: Root
    onVideoEnded?: () => void
    videoHasLoaded?: () => void
    videoHasChanged?: () => void
    startPlaying?: () => void
    stopPlaying?: () => void
    setShowDetachedPlayer?: (value: boolean) => void
    recordingState: AVTTRecordingState
    setRecordingState: (state: AVTTRecordingState) => void
}

class VideoMain extends Component<IVideoMain> {
    videoBeingRecorded?: PassageVideo

    @observable videoUploader?: VideoUploader

    @observable videoAreaWidth = 640

    @observable videoAreaHeight = 360

    @observable videoMainHeightOffset = 0

    @observable recordAudioOnly = false

    @observable mediaStream?: MediaStream

    @observable biblicalTermMarker?: BiblicalTermMarker

    @observable isNewBiblicalTermMarker = false

    recorderComponent: any

    intervalId?: NodeJS.Timeout

    mainVideoWidthIntervalId?: NodeJS.Timeout

    constructor(props: IVideoMain) {
        super(props)

        // extendObservable(this, {})

        this.record = this.record.bind(this)
        this.stop = this.stop.bind(this)
        this._record = this._record.bind(this)
        this._stop = this._stop.bind(this)
        this.setNoteBarWidth = this.setNoteBarWidth.bind(this)
        this.setPositionOfVideoMain = this.setPositionOfVideoMain.bind(this)
        this.onRecordingDone = this.onRecordingDone.bind(this)
        this.setContainerHeight = this.setContainerHeight.bind(this)
        this.keydown = this.keydown.bind(this)
        this.pauseRecording = this.pauseRecording.bind(this)
        this.resumeRecording = this.resumeRecording.bind(this)
        this.createBiblicalTermMarker = this.createBiblicalTermMarker.bind(this)
        this.setBiblicalTermMarker = this.setBiblicalTermMarker.bind(this)
    }

    componentDidMount() {
        const { rt } = this.props

        this.setNoteBarWidth()
        this.intervalId = setInterval(this.setNoteBarWidth, 100) // Temporary, use ResizeObserver once Typescript adds type declarations for it
        this.mainVideoWidthIntervalId = setInterval(this.setPositionOfVideoMain, 100)
        rt.addListener('record', this.record)
        rt.addListener('stop', this.stop)
        window.addEventListener('keydown', this.keydown)
    }

    componentDidUpdate(prevProps: IVideoMain) {
        const { rt } = this.props

        if (prevProps.rt.name !== rt.name) {
            window.removeEventListener('keydown', this.keydown)
            prevProps.rt.removeListener('record', this.record)
            prevProps.rt.removeListener('stop', this.stop)
            if (this.intervalId) {
                clearInterval(this.intervalId)
            }
            if (this.mainVideoWidthIntervalId) {
                clearInterval(this.mainVideoWidthIntervalId)
            }
            this.intervalId = undefined
            this.mainVideoWidthIntervalId = undefined

            this.intervalId = setInterval(this.setNoteBarWidth, 100) // Temporary, use ResizeObserver once Typescript adds type declarations for it
            this.mainVideoWidthIntervalId = setInterval(this.setPositionOfVideoMain, 100)
            rt.addListener('record', this.record)
            rt.addListener('stop', this.stop)
            window.addEventListener('keydown', this.keydown)
        }
    }

    componentWillUnmount() {
        const { rt } = this.props

        window.removeEventListener('keydown', this.keydown)
        rt.removeListener('record', this.record)
        rt.removeListener('stop', this.stop)
        if (this.intervalId) {
            clearInterval(this.intervalId)
        }
        if (this.mainVideoWidthIntervalId) {
            clearInterval(this.mainVideoWidthIntervalId)
        }
        this.intervalId = undefined
        this.mainVideoWidthIntervalId = undefined
    }

    async onRecordingDone({ err, blobsCount, url, duration, mimeType }: Partial<RecordingDoneParams>) {
        const { rt } = this.props

        if (err) {
            this.videoBeingRecorded = undefined
            rt.recording = false
            displayError(err)
            return
        }

        if (!this.videoBeingRecorded) {
            return
        }

        this.videoBeingRecorded.url = `${url}-${blobsCount}`
        this.videoBeingRecorded.duration = duration ?? 0
        this.videoBeingRecorded.mimeType = mimeType ?? ''

        this.addVideo(this.videoBeingRecorded).catch(systemError)
    }

    setNoteBarWidth() {
        const videoArea = document.querySelectorAll("[data-id='main-video']")
        for (const element of videoArea) {
            const _element = element as HTMLElement
            const isVisible = htmlElementVisible(_element)
            if (isVisible) {
                this.videoAreaWidth = _element.offsetWidth
            }
        }
    }

    setPositionOfVideoMain() {
        const videoMain = document.querySelectorAll("[data-id='video-main']")
        const compareDraftVideoMain = document.querySelectorAll("[data-id='compare-draft-video-main']")
        if (videoMain.length && compareDraftVideoMain.length) {
            this.videoMainHeightOffset = positionOfExistingElements(
                videoMain[0] as HTMLElement,
                compareDraftVideoMain[0] as HTMLElement
            )
        } else {
            this.videoMainHeightOffset = 0
        }
    }

    setContainerHeight(height: number) {
        this.videoAreaHeight = height
    }

    createBiblicalTermMarker = async () => {
        const { rt } = this.props
        const marker = await rt.createBiblicalTermMarker(rt.currentTime)
        if (marker) {
            this.isNewBiblicalTermMarker = true
            this.biblicalTermMarker = marker
        }
    }

    setBiblicalTermMarker = (marker: BiblicalTermMarker) => {
        this.isNewBiblicalTermMarker = false
        this.biblicalTermMarker = marker
    }

    // Handle 'record' event that was sent by root.record(...).
    // This code is shared by the record main video and record patch paths.
    // The resulting data is directed to the correct location by onRecordingDone
    // which is set differently for various paths.
    record(
        videoBeingRecorded: PassageVideo,
        onRecordingDone: ({ err, blobsCount, url, duration, mimeType }: Partial<RecordingDoneParams>) => void,
        recordAudioOnly: boolean
    ) {
        if (this.videoBeingRecorded) {
            log(`record ignored`)
            return // ignore if already recording
        }

        const { rt } = this.props
        const onRecordingDone2 = ({ err, blobsCount, url, duration, mimeType }: Partial<RecordingDoneParams>) => {
            onRecordingDone({ err, blobsCount, url, duration, mimeType })
            this.videoBeingRecorded = undefined
            rt.recording = false
        }
        this.recordAudioOnly = recordAudioOnly
        this.videoBeingRecorded = videoBeingRecorded
        this.videoUploader = new VideoUploader(videoBeingRecorded.url, onRecordingDone2.bind(this))
        rt.recording = true
    }

    stop() {
        const { rt } = this.props
        const { recording, playing } = rt
        log(`stop ${recording} ${playing}`)

        if (recording) {
            this.recorderComponent.stop()
        }
    }

    async addVideo(video: PassageVideo) {
        const { rt } = this.props
        if (!rt.passage) {
            return
        }
        const newVideo = await rt.passage.addVideoWithDefaultSegment(video)

        // We set passageVideo to null first in order to ensure that
        // VideoMain will update. Otherwise there is a race condition
        // where DBAcceptor partially set up the passageVideo and triggers
        // a VideoMain render before the passageVideo has all the info
        // to correctly display
        await rt.setPassageVideo(null)
        await rt.setPassageVideo(newVideo)
    }

    pauseRecording() {
        this.recorderComponent?.pause()
    }

    resumeRecording() {
        this.recorderComponent?.resume()
    }

    keydown(e: KeyboardEvent) {
        const { rt, recordingState } = this.props
        const { videoPlaybackKeydownEnabled, editingSegment, passage } = rt

        // Ignore keydown handlers if this event meets certain conditions
        const element = (e.target && e.target.toString()) || ''
        const el = e.target as Element
        const shouldReject =
            element.includes('HTMLInputElement') ||
            element.includes('HTMLTextAreaElement') ||
            (el.getAttribute && el.getAttribute('contenteditable') === 'true') ||
            (el.getAttribute && el.getAttribute('role') === 'dialog') ||
            !videoPlaybackKeydownEnabled ||
            passage?.videoBeingCompressed
        if (shouldReject) {
            log('keydown rejected non-global')
            return
        }

        e.stopPropagation()

        log(`keydown code=${e.code}`, e)

        const adjustCurrentTime = function (delta: number) {
            e.preventDefault()
            rt.adjustCurrentTime(delta)
        }

        if (e.code === 'ArrowLeft' && e.shiftKey) {
            adjustCurrentTime(-1.0)
            return
        }

        if (e.code === 'ArrowLeft' && e.metaKey) {
            adjustCurrentTime(-0.05)
            return
        }

        if (e.code === 'ArrowRight' && e.shiftKey) {
            adjustCurrentTime(1.0)
            return
        }

        if (e.code === 'ArrowRight' && e.metaKey) {
            adjustCurrentTime(0.05)
            return
        }

        if (e.code === 'Space') {
            if (rt.recording) {
                if (recordingState === 'RECORDING') {
                    this.pauseRecording()
                } else if (recordingState === 'PAUSED') {
                    this.resumeRecording()
                }
            } else if (rt.playing) {
                rt.pause()
            } else if (!editingSegment) {
                rt.play(undefined, undefined, rt.currentTime)
            }

            e.preventDefault()
            return
        }

        if (
            e.code === 'Enter' &&
            rt.recording &&
            recordingState !== 'NOT_INITIALIZED' &&
            recordingState !== 'INITIALIZED' &&
            recordingState !== 'STOPPED'
        ) {
            rt.stop()
        }
    }

    // This is than handler for the VideoToolbar record button click
    _record() {
        if (this.videoBeingRecorded) {
            log(`record ignored`)
            return // ignore if already recording
        }

        const { rt } = this.props
        const { name, portion, passage, iAmTranslator } = rt
        if (!portion || !passage || !iAmTranslator) return

        const videoBeingRecorded = passage.createVideo(name)
        // this will trigger a 'record' event on root which will be handled by this.record()
        rt.record(videoBeingRecorded, this.onRecordingDone, rt.project.recordAudioOnly)
    }

    _stop() {
        const { rt } = this.props

        rt.stop()
    }

    render() {
        const { rt, setShowDetachedPlayer, recordingState, setRecordingState } = this.props
        const { project, portion, passage, passageVideo, recording, currentTime, currentVideos, useMobileLayout } = rt
        const {
            videoUploader,
            _record,
            _stop,
            videoAreaWidth,
            videoMainHeightOffset,
            videoAreaHeight,
            setContainerHeight,
            recordAudioOnly,
            mediaStream,
            pauseRecording,
            resumeRecording,
            biblicalTermMarker,
            isNewBiblicalTermMarker,
            setBiblicalTermMarker
        } = this

        if (!recording) {
            this.recorderComponent = null
        }

        const compressingVideo = !!passage?.videoBeingCompressed

        let isAllAudio =
            (!passageVideo && rt.project.recordAudioOnly) ||
            currentVideos.viewableVideos.every((vv) => vv.video.isAudioOnly())
        if (recording) {
            isAllAudio = recordAudioOnly
        }

        const refRanges = getVisiblePassageOrPortionRefRanges({ passage, portion })
        const terms = project.getKeyTermsThatOccurInVerses(refRanges)

        const markers = (passage && passageVideo?.getVisibleBiblicalTermMarkers(passage)) ?? []
        const markerIndex = biblicalTermMarker
            ? markers.findIndex((marker) => marker._id === biblicalTermMarker._id)
            : -1

        let termId = ''
        if (biblicalTermMarker?.targetGlossId) {
            for (const term of terms) {
                const gloss = term.glosses.find((g) => g._id === biblicalTermMarker.targetGlossId)
                if (gloss) {
                    termId = term._id
                    break
                }
            }
        }

        if (isAVTT) {
            return (
                <div className="video-main" style={{ marginTop: videoMainHeightOffset }} data-id="video-main">
                    {biblicalTermMarker && passageVideo && passage && (
                        <BiblicalTermMarkerEditor
                            closeViewer={() => {
                                this.biblicalTermMarker = undefined
                                this.isNewBiblicalTermMarker = false
                            }}
                            terms={terms}
                            termId={termId}
                            uiLanguage={rt.uiLanguage}
                            biblicalTermMarker={biblicalTermMarker}
                            isNewMarker={isNewBiblicalTermMarker}
                            passage={passage}
                            allowEditing={rt.iAmTranslator}
                            goToNextMarker={() => {
                                if (markerIndex >= 0 && markerIndex < markers.length - 1) {
                                    setBiblicalTermMarker(markers[markerIndex + 1])
                                }
                            }}
                            goToPreviousMarker={() => {
                                if (markerIndex > 0) {
                                    setBiblicalTermMarker(markers[markerIndex - 1])
                                }
                            }}
                            markers={markers}
                        />
                    )}
                    <VideoToolbar
                        rt={rt}
                        playAllVideos={() => rt.playAll()}
                        playCurrentVideo={() => rt.play()}
                        pausePlayback={() => rt.pause()}
                        pauseRecording={pauseRecording}
                        record={_record}
                        stopRecording={_stop}
                        recordingState={recordingState}
                        resumeRecording={resumeRecording}
                        openBiblicalTermMarkerEditor={this.createBiblicalTermMarker}
                    />
                    <OptionalSplitPane useSplitPane={!isAllAudio}>
                        <div className={isAllAudio ? 'avtt-audio-area' : 'avtt-video-area'} data-id="main-video">
                            {!compressingVideo && recording && recordAudioOnly && (
                                <AudioRecorderComponent
                                    ref={(c) => {
                                        this.recorderComponent = c
                                    }}
                                    videoUploader={videoUploader}
                                    setMediaStream={(stream) => (this.mediaStream = stream)}
                                    setRecordingState={setRecordingState}
                                    usePauseAndResume
                                />
                            )}
                            {!compressingVideo && recording && !recordAudioOnly && (
                                <VideoRecorder
                                    ref={(c) => {
                                        this.recorderComponent = c
                                    }}
                                    setMediaStream={(stream) => (this.mediaStream = stream)}
                                    usePauseAndResume
                                    setRecordingState={setRecordingState}
                                    videoUploader={videoUploader}
                                />
                            )}

                            {!compressingVideo && !recording && passage && (
                                <RootVideoPlayer
                                    rt={rt}
                                    setContainerHeight={setContainerHeight}
                                    showSegmentLabels={!useMobileLayout}
                                    initialTime={currentTime}
                                />
                            )}
                        </div>
                        <VideoMainBottom
                            rt={rt}
                            videoAreaWidth={videoAreaWidth}
                            mediaStream={mediaStream}
                            recordingState={recordingState}
                            setBiblicalTermMarker={setBiblicalTermMarker}
                            isAudioOnly={recordAudioOnly}
                        />
                    </OptionalSplitPane>
                </div>
            )
        }

        return (
            <div className="video-main" style={{ marginTop: videoMainHeightOffset }} data-id="video-main">
                <VideoToolbar
                    rt={rt}
                    playAllVideos={() => rt.playAll()}
                    playCurrentVideo={() => rt.play()}
                    pausePlayback={() => rt.pause()}
                    pauseRecording={pauseRecording}
                    record={_record}
                    stopRecording={_stop}
                    setShowDetachedPlayer={setShowDetachedPlayer}
                    recordingState={recordingState}
                    resumeRecording={resumeRecording}
                    openBiblicalTermMarkerEditor={this.createBiblicalTermMarker}
                />
                <div className="video-area" data-id="main-video" style={{ height: videoAreaHeight }}>
                    {!compressingVideo && recording && (
                        <VideoRecorder
                            ref={(c) => {
                                this.recorderComponent = c
                            }}
                            setMediaStream={(stream) => (this.mediaStream = stream)}
                            setRecordingState={setRecordingState}
                            usePauseAndResume
                            videoUploader={videoUploader}
                        />
                    )}

                    {!compressingVideo && !recording && passage && (
                        <RootVideoPlayer
                            rt={rt}
                            setContainerHeight={setContainerHeight}
                            showSegmentLabels={!useMobileLayout}
                            initialTime={currentTime}
                        />
                    )}
                </div>
                <VideoMainBottom
                    rt={rt}
                    videoAreaWidth={videoAreaWidth}
                    recordingState={recordingState}
                    setBiblicalTermMarker={setBiblicalTermMarker}
                    isAudioOnly={recordAudioOnly}
                />
            </div>
        )
    }
}

export default observer(VideoMain)

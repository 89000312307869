// Enhanced resources texts from MARBLE

import { RefRange } from '../scrRefs/RefRange'

export class ERSpan {
    public type: 'text' | 'verse' | 'gw' = 'text'

    public text?: string

    public attributes?: any

    public chapter?: string

    public verse?: string

    public id?: string

    constructor(span: any) {
        const { type, text, attributes, chapter, verse, id } = span
        this.type = type ?? 'text'
        this.text = text
        this.attributes = attributes
        this.chapter = chapter
        this.verse = verse
        this.id = id
    }

    /**
     * True iff the bbbcccvvv part of the id lies in the specified ref
     */
    includedIn(ref: RefRange) {
        let { startRef, endRef } = ref
        if (startRef.length === 6) startRef += '000'
        if (endRef.length === 6) endRef += '150'

        let { id } = this
        if (!id) return false

        id = id.slice(0, 9) // retain just bbbcccvvv
        return id >= startRef && id <= endRef
    }

    getLexicalLinks() {
        const { attributes } = this
        const lexicalLinks: string = (attributes && attributes.lexical_links) || ''
        return lexicalLinks.split('|')
    }
}

export class ERDiv {
    public style = 'p' // s, p, q (from usx marker)

    public id = ''

    public bbbccc = '' // book number/chapter number for this div

    public spans: ERSpan[] = []

    constructor(div: any) {
        const { style, id, spans, bbbccc } = div
        this.style = style ?? 'p'
        this.id = id ?? ''
        this.spans = spans ? spans.map((span: any) => new ERSpan(span)) : []
        this.bbbccc = bbbccc
    }
}

// Ensure that if any span in a div has an id that
// all spans in the div had an id.
// Spans at the beginning of the list without ids
// are given the first id found. After that spans
// without ids are given the id of the previous span.
function propagateSpanIds(div: ERDiv) {
    const span = div.spans.find((divSpan) => divSpan.id)
    if (!span) return

    let id = span.id || ''
    div.id = id

    div.spans.forEach((divSpan) => {
        if (divSpan.id) {
            id = divSpan.id
        } else {
            divSpan.id = id
        }
    })

    // Verse markers should always get the id of the next span. Example: NRS89 Ps 119:3
    // should get the id for 119:3, not 119:2.
    for (let i = div.spans.length - 2; i >= 0; --i) {
        if (div.spans[i].type === 'verse') {
            div.spans[i].id = div.spans[i + 1].id
        }
    }
}

function propagateIds(divs: ERDiv[]) {
    divs.forEach((div) => {
        propagateSpanIds(div)

        // Give div the id of the first span
        if (div.spans.length) {
            div.id = div.spans[0].id || ''
        }
    })

    // for divs with empty ids, give them the id of the next div
    for (let i = divs.length - 2; i >= 0; --i) {
        if (!divs[i].id) divs[i].id = divs[i + 1].id
    }
}

export class EnhancedResources {
    static async fetch(
        resource: string, // e.g. RVR60
        bbbccc: string // e.g. 001002 (Gen 2)
    ): Promise<ERDiv[]> {
        const url = `https://s3.amazonaws.com/sltt-resources/SLMARBLE/ers/${resource}/${bbbccc}.json`
        const response = await fetch(url)
        if (!response.ok) {
            throw Error(`${response.url}: ${response.statusText}`)
        }

        const _divs: any[] = await response.json()

        let divs: ERDiv[] = _divs.map((_div) => new ERDiv(_div))

        // If there is only one div in the chapter, make each verse a seperate div
        if (divs.length === 1) {
            divs = this.divsByVerse(divs[0].spans)
        }

        propagateIds(divs)
        return divs
    }

    static divsByVerse(spans: ERSpan[]) {
        const divs: ERDiv[] = []
        for (const span of spans) {
            if (divs.length === 0 || span.type === 'verse') {
                divs.push(new ERDiv({}))
            }

            divs.slice(-1)[0].spans.push(span)
        }

        return divs
    }

    /**
     * Fetch divs matching refs.
     * Filter there spans to be only this included in refs.
     */
    static async fetchRefs(
        resource: string, // e.g. RVR60
        refs: RefRange[]
    ): Promise<ERDiv[]> {
        const divs: ERDiv[] = []

        for (const ref of refs) {
            await this.fetchRef(resource, ref, divs)
        }

        return divs
    }

    /**
     * Fetch all the divs for chapters in ref from the resource.
     * Any spans include in ref range are pused onto divs.
     */
    static async fetchRef(
        resource: string, // e.g. RVR60
        ref: RefRange,
        divs: ERDiv[]
    ): Promise<void> {
        for (const bbbccc of ref.chapterIterator()) {
            const _divs = await this.fetch(resource, bbbccc)

            for (const _div of _divs) {
                const spans = _div.spans.filter((span) => span.includedIn(ref))

                if (spans.length) {
                    divs.push(new ERDiv({ style: _div.style, spans, bbbccc }))
                }
            }

            if (divs.length) {
                const lastDiv = divs.slice(-1)[0]

                if (lastDiv.spans.length && lastDiv.spans.slice(-1)[0].type === 'verse') {
                    lastDiv.spans.splice(lastDiv.spans.length - 1, 1)
                }
            }
        }
    }
}

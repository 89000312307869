import { observable } from 'mobx'

import { DBObject } from './DBObject'
import { fmt } from '../components/utils/Fmt'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:Models')

export class OralPassageResource extends DBObject {
    @observable title = ''

    @observable url = ''

    @observable description = ''

    static MAX_DESCRIPTION_CHARACTERS = 5000

    model = 17

    toDocument() {
        const { title, url, description, model } = this
        return this._toDocument({ title, url, description, model })
    }

    copy() {
        let copy = new OralPassageResource(this._id, this.db)
        copy = Object.assign(copy, this)
        return copy
    }

    async setTitle(title: string, forcePut: boolean) {
        log('PassageDocument setTitle', fmt({ _id: this._id, title }))

        title = title.trim()
        if (!forcePut && this.title === title) {
            return
        }

        const doc = this._toDocument({ title, model: this.model })
        await this.db.put(doc)
    }

    async setDescription(description: string) {
        if (this.description === description) {
            return
        }

        if (description.length > OralPassageResource.MAX_DESCRIPTION_CHARACTERS) {
            throw new Error('OralPassageResource description is too long. Aborting save.')
        }

        const doc = this._toDocument({ description, model: this.model })
        await this.db.put(doc)
    }

    getTitle() {
        return this.title
    }
}

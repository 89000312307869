/* eslint-disable react/no-array-index-key */

// Display location of notes in this chunk.
// Allow user to click them -> 'noteclicked' event

import React, { Component, FunctionComponent, FC } from 'react'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'

import './Note.css'
import '../utils/Buttons.css'
import { UnresolvedNoteMarker, CircleNoteMarker, SquareNoteMarker, TriangleNoteMarker } from '../utils/Buttons'

import { Passage } from '../../models3/Passage'
import { PassageNote } from '../../models3/PassageNote'
import { PassageVideo } from '../../models3/PassageVideo'
import { Project } from '../../models3/Project'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:NoteBar')

type MostRecentNoteDecoratorProps = {
    width: number
    height: number
    top: number
    left: number
}

const MostRecentNoteDecorator = ({ width, height, top, left }: MostRecentNoteDecoratorProps) => {
    return <div style={{ width, height, top, border: '2px dotted gray', position: 'absolute', left, zIndex: 1 }} />
}

interface INoteMarker {
    enabled: boolean
    project: Project
    note: PassageNote
    index: number
    noteBarHeight: number
    openNote: (note: PassageNote) => void
    mostRecentNoteIdViewed: string
}

const markerShapes: JSX.Element[] = [
    <CircleNoteMarker key="circle" className="note-bar-marker" enabled />,
    <SquareNoteMarker key="square" className="note-bar-marker" enabled />,
    <TriangleNoteMarker key="triangle" className="note-bar-marker" enabled />
]

const NoteMarker: FC<INoteMarker> = observer(
    ({ project, note, noteBarHeight, index, openNote, enabled, mostRecentNoteIdViewed }) => {
        const { type, resolved, onTop, consultantOnly, inIgnoredSegment, description, width: markerWidth } = note
        const typeIndex = parseInt(type)

        const fontSize = `${markerWidth / 2.666}px`
        const left = note.x
        const top = (noteBarHeight - markerWidth) / 2 + note.y - 2
        const width = markerWidth
        const height = markerWidth

        let color = ''
        if (!enabled) {
            color = 'grey'
        } else if (!onTop || inIgnoredSegment) {
            color = '#000000' // Black
        } else if (consultantOnly) {
            color = 'purple'
        } else if (resolved) {
            //
        } else {
            color = project.noteColors[typeIndex % 3]
        }

        let shape: JSX.Element = markerShapes[Math.floor(typeIndex / 3)]
        if (resolved) {
            shape = <UnresolvedNoteMarker className="note-bar-marker" />
        }

        // The fiddly expression for 'MostRecentNoteDecorator left' below is trying to ensure
        // that the square we draw around the different shapes looks centered.
        const marker = (
            <div
                className="clickable"
                data-id={`note-${index}`}
                style={{ position: 'absolute', width, height, left, top, fontSize }}
                onClick={() => enabled && openNote(note)}
            >
                <span style={{ color, position: 'relative' }}> {shape} </span>
                {note._id === mostRecentNoteIdViewed && (
                    <MostRecentNoteDecorator
                        top={-1.5}
                        width={width + 4}
                        height={height + 5}
                        left={typeIndex <= 2 || resolved ? -1 : -2}
                    />
                )}
            </div>
        )

        if (description) {
            return (
                <OverlayTrigger
                    placement="bottom"
                    overlay={
                        <Tooltip id="note-bar-tooltip">
                            {description.split('\n').map((para, i) => {
                                return <div key={i}> {para} </div>
                            })}
                        </Tooltip>
                    }
                >
                    {marker}
                </OverlayTrigger>
            )
        }

        return marker
    }
)

interface INoteBarMarkerList {
    enabled: boolean
    project: Project
    notes: PassageNote[]
    noteBarHeight: number
    openNote: (note: PassageNote) => void
    pixelsPerSecond: number
    mostRecentNoteIdViewed: string
}

const NoteBarMarkerList: FunctionComponent<INoteBarMarkerList> = observer((props) => {
    const { notes, noteBarHeight, openNote, project, enabled, mostRecentNoteIdViewed } = props

    return (
        <div>
            {notes.map((note, index) => (
                <NoteMarker
                    key={note._id}
                    {...{ note, index, noteBarHeight, openNote, project, enabled, mostRecentNoteIdViewed }}
                />
            ))}
        </div>
    )
})

interface INoteBarDisplay {
    passage: Passage | null
    passageVideo: PassageVideo | null
    canViewConsultantOnlyFeatures: boolean
    setNote: (note: PassageNote) => void
    project: Project
    mostRecentNoteIdViewed: string
}

interface INoteBar {
    noteBarDisplay: INoteBarDisplay
    w: number
    h?: number
}

class NoteBar extends Component<INoteBar> {
    @observable summary = ''

    openNote(note: PassageNote) {
        const { noteBarDisplay } = this.props

        const { setNote } = noteBarDisplay
        log('open note', note)
        setNote(note)
    }

    // In Passage notes must be in ascending order by position in order to render correctly
    render() {
        const { w, h, noteBarDisplay } = this.props
        const { passage, passageVideo, canViewConsultantOnlyFeatures, project, mostRecentNoteIdViewed } = noteBarDisplay

        if (!passage || !passageVideo) return null

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { _rev } = passage // force update when passage changes

        // This will have the side effect of (re)setting segment times if necessary
        const notes = passageVideo.getDrawableNotes(passage, w, canViewConsultantOnlyFeatures)

        const height = h || 30

        return (
            <div
                className="note-bar"
                data-id="note-bar"
                style={{ border: '1px solid #BBB', height, position: 'relative' }}
            >
                <NoteBarMarkerList
                    enabled={!passage?.videoBeingCompressed}
                    project={project}
                    notes={notes}
                    noteBarHeight={height}
                    openNote={this.openNote.bind(this)}
                    pixelsPerSecond={w / passageVideo.computedDuration}
                    mostRecentNoteIdViewed={mostRecentNoteIdViewed}
                />
                <p>{this.summary}</p>
            </div>
        )
    }
}

export default observer(NoteBar)

import React, { FunctionComponent } from 'react'
import { Language } from '../utils/Languages'

interface ILocaleSelector {
    options: Language[]
    currentValue: string
    chooseOption: (option: string) => void
}

export const LocaleSelector: FunctionComponent<ILocaleSelector> = ({ options, currentValue, chooseOption }) => (
    <div className="app-selector-icon">
        <select className="custom-select" value={currentValue} onChange={(e) => chooseOption(e.target.value)}>
            {options.map((item) => {
                const { code, fullName } = item
                return (
                    <option key={code} value={code}>
                        {fullName}
                    </option>
                )
            })}
        </select>
    </div>
)

const intest = localStorage.getItem('intest') === 'true'

/* Format message and send to central error log
 */
export function logError(err: any, message?: string) {
    if (intest) {
        console.log('<LOGERROR>', err, message)
        throw Error(message)
        return
    }

    import('./Errors').then((errors) => errors.logError(err, message))
}

/**
 * Display a non-serious error to the user.
 */
export function displayError(err: any, message?: string) {
    if (intest) {
        console.log('<ERROR>', err, message)
        throw Error(message)
        return
    }

    import('./Errors').then((errors) => errors.displayError(err, message))
}

/**
 * Report a serious error to logging system, and display a simple error message to user.
 */
export function systemError(err: any) {
    if (intest) {
        console.log('<systemError>', err)
        throw Error()
        return
    }

    import('./Errors').then((errors) => errors.systemError(err))
}

export function displayInfo(message: string) {
    if (intest) {
        console.log('<displayInfo>', message)
        return
    }

    import('./Errors').then((errors) => errors.displayInfo(message))
}

export function displaySuccess(message: string) {
    if (intest) {
        console.log('<displaySuccess>', message)
        return
    }

    import('./Errors').then((errors) => errors.displaySuccess(message))
}

export function dismissDisplay(_id: any) {
    if (intest) return

    import('./Errors').then((errors) => errors.dismissDisplay(_id))
}

import React from 'react'
import { observable } from 'mobx'
import { observer /* inject */ } from 'mobx-react'
import _ from 'underscore'
import { t } from 'i18next'

import SegmentSelector from './SegmentSelector'
import SegmentToolbar from './SegmentToolbar'
import { OldSegmentViewer } from './OldSegmentViewer'
import BackTranslationEditor from './BackTranslation'
import SegmentResourcesViewer from './SegmentResourcesViewer'
import { SegmentTimestamp } from './SegmentTimestamp'
import { SegmentTranscription } from './SegmentTranscription'
import { panelOrder, panelVisibility } from './SegmentPanelOrder'

import { EditingSegmentPosition } from '../translation/TranslationRightPane'
import { displayError, systemError } from '../utils/Errors'
import { AVTTRecordingState } from '../video/VideoRecorder'
import { RecordingDoneParams } from '../video/VideoUploader'

import { Passage } from '../../models3/Passage'
import { PassageSegment } from '../../models3/PassageSegment'
import { PassageVideo } from '../../models3/PassageVideo'
import { Root } from '../../models3/Root'

import './Segments.css'
import '../translation/Translation.css'

// Display the currently selected passageSegment and allow editing it.

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:SegmentsEditor')

interface ISegmentsEditor {
    rt: Root
    editingSegmentPosition: EditingSegmentPosition
    setEditingSegmentPosition: (value: EditingSegmentPosition) => void
    recordingState: AVTTRecordingState
}

@observer
export class SegmentsEditor extends React.Component<ISegmentsEditor> {
    @observable videoBeingRecorded?: PassageVideo

    @observable currentVersion: PassageVideo | null = null

    @observable currentVersionSegment: PassageSegment | null = null

    @observable oldSegmentViewerOpen = false

    constructor(props: ISegmentsEditor) {
        super(props)
        this.stopRecording = this.stopRecording.bind(this)
        this.chooseVersionToPlay = this.chooseVersionToPlay.bind(this)
        this.openOldSegmentViewer = this.openOldSegmentViewer.bind(this)
        this.closeOldSegmentViewer = this.closeOldSegmentViewer.bind(this)
        this.record = this.record.bind(this)
        this.play = this.play.bind(this)
        this.pause = this.pause.bind(this)
        this.isMostRecentVersion = this.isMostRecentVersion.bind(this)

        const { rt } = this.props
        const { passageSegment, passage, passageVideo } = rt

        if (passageSegment && passage && passageVideo) {
            if (passageSegment.videoPatchHistory.length > 0) {
                const last = passageSegment.videoPatchHistory.length - 1
                const mostRecent = passageSegment.videoPatchHistory[last]
                const currentVersion = this.videoInHistory(mostRecent, passage)
                if (currentVersion) {
                    this.currentVersion = currentVersion
                    this.currentVersionSegment = currentVersion.segments[0]
                }
            } else {
                this.currentVersion = passageVideo
                this.currentVersionSegment = passageSegment
            }
        }
    }

    componentWillUnmount() {
        const { rt } = this.props
        rt.setEditingSegment(false)
        rt.resetSegmentLabelsDraftChanges()
    }

    async onRecordingDone({ err, blobsCount, url, duration, mimeType }: Partial<RecordingDoneParams>) {
        const { rt, setEditingSegmentPosition } = this.props

        if (err) {
            this.videoBeingRecorded = undefined
            rt.recording = false
            displayError(err)
            return
        }

        const { passage, passageVideo, passageSegment, timeline } = rt
        if (!passage || !passageVideo || !passageSegment || !this.videoBeingRecorded) {
            return
        }

        this.videoBeingRecorded.url = `${url}-${blobsCount}`
        this.videoBeingRecorded.duration = duration ?? 0
        this.videoBeingRecorded.mimeType = mimeType ?? ''

        const patchableSelectionPresent = rt.patchableSelectionPresent()

        try {
            let segment = passageSegment
            if (patchableSelectionPresent) {
                const { segment: _segment } = await passageVideo.createSelectionSegment(passage, timeline)
                segment = _segment
            }

            await passage.addPatchVideo(passageVideo, this.videoBeingRecorded, segment, segment.actualSegment(passage))

            rt.setPassageVideo(passageVideo)
            rt.setPassageSegment(segment)
            const actualSegment = segment.actualSegment(passage)
            rt.resetCurrentTime(actualSegment.time + 0.01)

            this.videoBeingRecorded = undefined
            rt.recording = false

            // Edit beginning and ending position of patch
            setEditingSegmentPosition(EditingSegmentPosition.Both)
        } catch (error) {
            this.videoBeingRecorded = undefined
            rt.recording = false
            systemError(error)
        }
    }

    videoInHistory = (id: string, passage: Passage) => passage.videos.find((v) => v._id === id)

    chooseVersionToPlay(id: string) {
        const { rt } = this.props
        const { passage, passageVideo, passageSegment } = rt
        if (!passage || !passageVideo || !passageSegment) {
            return
        }

        const video = this.videoInHistory(id, passage)
        if (!video) {
            return
        }

        const isMainVideo = video._id === passageVideo._id
        const segment = isMainVideo ? passageSegment : video.segments[0]

        if (this.isMostRecentVersion(video)) {
            this.closeOldSegmentViewer()
        } else {
            this.openOldSegmentViewer()
        }

        this.currentVersion = video
        this.currentVersionSegment = segment
    }

    play() {
        const { rt } = this.props
        const { passageVideo, passage, passageSegment } = rt
        if (!passageVideo || !passage || !passageSegment) return

        const segment = passageSegment.actualSegment(passage)
        if (!segment) {
            return
        }

        rt.play(segment.time, segment.time + segment.duration, segment.time)
    }

    pause() {
        const { rt } = this.props
        rt.stop()
    }

    isMostRecentVersion(currentVersion: PassageVideo) {
        const { rt } = this.props
        const { passageVideo, passage, passageSegment } = rt
        if (!passageVideo || !passage || !passageSegment) {
            return true
        }

        const { videoPatchHistory } = passageSegment
        if (videoPatchHistory.length > 0) {
            const last = videoPatchHistory.length - 1
            const mostRecent = videoPatchHistory[last]
            if (currentVersion._id !== mostRecent) {
                return false
            }
        }
        return true
    }

    openOldSegmentViewer() {
        this.oldSegmentViewerOpen = true
    }

    closeOldSegmentViewer() {
        this.oldSegmentViewerOpen = false
    }

    /**
     * Record a patch for this segment (or selection)
     */
    record() {
        if (this.videoBeingRecorded) {
            log(`record ignored`)
            return // ignore if already recording
        }

        const { rt } = this.props
        const { name, portion, passage, iAmTranslator } = rt
        if (!portion || !passage || !iAmTranslator) return

        this.videoBeingRecorded = passage.createVideo(name)

        const recordAudioOnly = !rt.passageVideo?.mimeType.startsWith('video')

        rt.record(this.videoBeingRecorded, this.onRecordingDone.bind(this), recordAudioOnly)
    }

    stopRecording() {
        const { rt } = this.props

        rt.stop()
    }

    render() {
        const { rt, editingSegmentPosition, setEditingSegmentPosition, recordingState } = this.props
        const { passage, passageVideo, passageSegment, portion, project, editingSegment } = rt

        if (!portion || !passage || !passageVideo || !passageSegment) return null

        // access passage._rev to force render whenever any passage info received
        // eslint-disable-next-line
        passage._rev

        // Access to force re-renders on change
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        passageSegment._id

        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        passageSegment.videoPatchHistory.length

        const segments = passageVideo.visibleSegments(passage)
        const currentSegment = passageSegment.actualSegment(passage)
        if (!currentSegment) return null

        const idx = _.findIndex(segments, (ps) => ps._id === currentSegment._id)
        if (idx === -1) return null

        const {
            record,
            stopRecording,
            chooseVersionToPlay,
            closeOldSegmentViewer,
            currentVersion,
            currentVersionSegment,
            play,
            pause,
            oldSegmentViewerOpen
        } = this

        const defined = (e: PassageVideo | undefined) => e !== undefined
        const oldVersionOptions = passageSegment.videoPatchHistory
            .map((item) => this.videoInHistory(item, passage))
            .filter(defined)
            .reverse() as PassageVideo[]
        oldVersionOptions.push(passageVideo)

        return (
            <div className="passage-passage-segments-editor">
                <div className="segment-selector">
                    <div className="segment-selector-first-group">
                        <h4 className="segment-pane-title">{t('segmentEditor')}</h4>
                    </div>
                    <div className="segment-selector-middle-group">
                        <SegmentSelector segment={currentSegment} rt={rt} editing={editingSegment} />
                    </div>
                    <div className="segment-selector-last-group">
                        <SegmentTimestamp {...{ rt, passage, passageSegment, currentSegment }} />
                    </div>
                </div>
                <div className="segment-editor-bottom">
                    {panelVisibility(project, 'recordingPanel') && (
                        <>
                            <SegmentToolbar
                                rt={rt}
                                record={record}
                                segment={currentSegment}
                                baseSegment={passageSegment}
                                stopRecording={stopRecording}
                                oldVersionOptions={oldVersionOptions}
                                chooseVersionToPlay={chooseVersionToPlay}
                                play={play}
                                pause={pause}
                                editingSegmentPosition={editingSegmentPosition}
                                setEditingSegmentPosition={setEditingSegmentPosition}
                                startEditingLabels={() => {}}
                                className={panelOrder(project, 'recordingPanel')}
                                recordingState={recordingState}
                            />
                            {oldSegmentViewerOpen && currentVersion && currentVersionSegment && (
                                <OldSegmentViewer
                                    key={currentVersion._id} // Component will be recreated when this changes
                                    rt={rt}
                                    originalSegment={passageSegment}
                                    originalVideo={passageVideo}
                                    video={currentVersion}
                                    segment={currentVersionSegment}
                                    onDone={closeOldSegmentViewer}
                                    passage={passage}
                                />
                            )}
                        </>
                    )}
                    {panelVisibility(project, 'transcriptionPanel') && (
                        <SegmentTranscription
                            segment={currentSegment}
                            rt={rt}
                            className={panelOrder(project, 'transcriptionPanel')}
                            showMessage
                        />
                    )}
                    {panelVisibility(project, 'backTranslationPanel') && (
                        <BackTranslationEditor
                            segment={currentSegment}
                            rt={rt}
                            showMessage
                            className={panelOrder(project, 'backTranslationPanel')}
                        />
                    )}
                    {panelVisibility(project, 'referenceTextsPanel') && (
                        <SegmentResourcesViewer
                            segment={passageSegment}
                            rt={rt}
                            className={panelOrder(project, 'referenceTextsPanel')}
                        />
                    )}
                </div>
            </div>
        )
    }
}

export default observer(SegmentsEditor)

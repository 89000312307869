import React, { useState } from 'react'
import { Table } from 'react-bootstrap'
import { observer } from 'mobx-react'

import { useTranslation } from 'react-i18next'
import ERTermModal from './ERTermModal'

import { CheckIcon } from '../utils/Icons'
import { getTermSenseName } from '../../scrRefs/Lemmas'
import { refRangesToDisplay } from '../../scrRefs/Utils'

import { Passage } from '../../models3/Passage'
import { getVisiblePassageOrPortionRefRanges } from '../../models3/ProjectReferences'
import { PassageVideo } from '../../models3/PassageVideo'
import { Project } from '../../models3/Project'
import { Root } from '../../models3/Root'

import './EnhancedResources.css'
import { Portion } from '../../models3/Portion'
import { HelpLinkWithMessage } from '../utils/Buttons'

type PassageBiblicalTermsViewerProps = {
    portion: Portion | null
    passage: Passage | null
    passageVideo: PassageVideo | null
    project: Project
    rt: Root
}

const PassageBiblicalTermsViewer = observer(
    ({ rt, passage, passageVideo, project, portion }: PassageBiblicalTermsViewerProps) => {
        const { t } = useTranslation()
        const [termId, setTermId] = useState('')
        const markers = passage && passageVideo ? passageVideo.getVisibleBiblicalTermMarkers(passage) : []
        const refRanges = getVisiblePassageOrPortionRefRanges({ passage, portion })
        const displayableReferences = refRangesToDisplay(refRanges, project)
        const terms = project.getKeyTermsThatOccurInVerses(refRanges)

        return (
            <div>
                {termId && <ERTermModal rt={rt} termId={termId} setTermId={setTermId} />}
                {displayableReferences.trim() !== '' && <p>{displayableReferences}</p>}
                {terms.length === 0 && (
                    <HelpLinkWithMessage message={t('noPassageBiblicalTerms')} id="biblicalTerms.html" />
                )}
                {terms.length > 0 && (
                    <Table>
                        <thead>
                            <tr>
                                <th>{t('termUsed')}</th>
                                <th>{t('biblicalTermHeader')}</th>
                                <th>{t('sourceGlossesHeader')}</th>
                                <th>{t('targetGlossesHeader')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {terms.map((term) => {
                                const lemma = term.getMarbleLemma()
                                if (lemma) {
                                    const lexMeaning = lemma.meanings.find(
                                        (meaning) => meaning.lexicalLink === term.lexicalLink
                                    )
                                    let termString = ''
                                    let sourceGlosses = ''
                                    if (lexMeaning) {
                                        termString = getTermSenseName(lemma, lexMeaning)
                                        sourceGlosses = lexMeaning.glosses(rt.uiLanguage)
                                    }

                                    return (
                                        <tr key={term._id}>
                                            <td>
                                                {term.glosses.some((gloss) =>
                                                    markers.find((marker) => marker.targetGlossId === gloss._id)
                                                ) && <CheckIcon className="er-term-found" tooltip="" />}
                                            </td>
                                            <td>
                                                <span
                                                    className="passage-key-term-link"
                                                    onClick={() => setTermId(term.lexicalLink)}
                                                >
                                                    {termString}
                                                </span>
                                            </td>
                                            <td>{sourceGlosses}</td>
                                            <td>{term.glosses.map((gloss) => gloss.text).join('; ')}</td>
                                        </tr>
                                    )
                                }
                                return null
                            })}
                        </tbody>
                    </Table>
                )}
            </div>
        )
    }
)

export default PassageBiblicalTermsViewer

import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { DragDropContext, DropResult, DraggableLocation } from 'react-beautiful-dnd'

import TaskColumn from './TaskColumn'
import { DragAndDropData, createDataModel } from './DragAndDropData'
import { FilteredPortionPassages } from './StatusEditor'

import { Project } from '../../models3/Project'
import { ProjectPlan } from '../../models3/ProjectPlan'
import { Root } from '../../models3/Root'

interface IStatusBoard {
    rt: Root
    project: Project
    projectPlan: ProjectPlan
    portionPassages: FilteredPortionPassages[]
    showLongTaskNames: boolean
    updateStatus: (
        data: DragAndDropData,
        source: DraggableLocation,
        destination: DraggableLocation,
        draggableId: string
    ) => void
}

class StatusBoard extends Component<IStatusBoard> {
    data: DragAndDropData

    constructor(props: IStatusBoard) {
        super(props)
        this.data = new DragAndDropData([])
    }

    onDragEnd = (result: DropResult) => {
        const { updateStatus } = this.props

        const { destination, source, draggableId } = result
        if (!destination) {
            return
        }

        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return
        }

        updateStatus(this.data, source, destination, draggableId)
    }

    render() {
        const { rt, portionPassages, projectPlan, showLongTaskNames } = this.props
        this.data = createDataModel(portionPassages, projectPlan, showLongTaskNames)

        return (
            <DragDropContext onDragEnd={this.onDragEnd}>
                {this.data.columns.map(({ id, items, task }) => (
                    <TaskColumn
                        key={id}
                        id={id}
                        items={items}
                        task={task}
                        rt={rt}
                        updateDifficulty={(difficulty: number) => {
                            task.setDifficulty(difficulty)
                        }}
                    />
                ))}
            </DragDropContext>
        )
    }
}

export default observer(StatusBoard)

import React, { FC } from 'react'
import { observer } from 'mobx-react'
import { LoadingIcon } from './Icons'
import '../app/SLTool.css'
import './Icons.css'

interface IInitializationMessage {
    loadingMessage: string
}

const LoadingMessage: FC<IInitializationMessage> = ({ loadingMessage }) => {
    return (
        <div className="initialization-message">
            <LoadingIcon className="initialization-message-icon" />
            {loadingMessage}
        </div>
    )
}

export default observer(LoadingMessage)

import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Passage } from '../../models3/Passage'
import { PassageSegment } from '../../models3/PassageSegment'
import { Root } from '../../models3/Root'

interface ISegmentTimestamp {
    rt: Root
    passage: Passage
    passageSegment: PassageSegment
    currentSegment: PassageSegment
}

export const SegmentTimestamp: FC<ISegmentTimestamp> = ({ rt, passage, passageSegment, currentSegment }) => {
    const { t } = useTranslation()

    const video = passage.findVideo(currentSegment._id)
    if (!video) return null // should never happen

    const isAPatch = passageSegment.isPatched && passageSegment._id !== currentSegment._id
    const className = isAPatch ? 'segment-selector-patch-time' : 'segment-selector-time'
    return (
        <div>
            <div className={className}>{t('recordingBy', { userEmail: video.creator })}</div>
            <div className={className}>{rt.dateFormatter.format(new Date(video.creationDate))}</div>
        </div>
    )
}

import { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import SplitPane from 'react-split-pane'
import InputSlider from 'react-input-slider'

import { enableGlosses, enableLegacyWaveform, enableWaveSurfer } from '../app/slttAvtt'
import { WaveSurferAudioPlayer } from '../audio/WaveSurferAudioPlayer'
import ERTermModal from '../enhancedResources/ERTermModal'
import GlossBar from '../glosses/GlossBar'
import NoteBar from '../notes/NoteBar'
import { SegmentsEditor } from '../segments/SegmentsEditor'
import { EditingSegmentPosition } from '../translation/TranslationRightPane'
import ErrorBoundary from '../utils/Errors'
import { useDebouncedValue } from '../utils/Hooks'

import { BiblicalTermMarker } from '../../models3/BiblicalTermMarker'
import { IDrawableSegment } from '../../models3/PassageVideo'
import { Root } from '../../models3/Root'
import { TimelineSelection } from '../../models3/RootBase'

import { AdvancedDownloadingMessage } from './DownloadingMessage'
import ThinVideoPositionBar from './ThinVideoPositionBar'
import { VideoPositionBarControls } from './VideoPositionBarControls'
import { AVTTRecordingState } from './VideoRecorder'
import { VerseReferenceEditor } from './VerseReferenceEditor'
import { VideoMessage } from './VideoMessage'
import VideoPositionBar from './VideoPositionBar'
import {
    AudioWaveformCreator,
    SelectionVisualization,
    WaveformVisualizer,
    SegmentMarkerVisualization,
    CurrentPositionMarkerVisualization,
    LiveWaveformVisualizerWrapper,
    SelectionLayer
} from './WaveformVisualizer'

import './Video.css'

type SelectionLayerWrapperProps = {
    width: number
    rt: Root
    className: string
}

const SelectionLayerWrapper = observer(({ rt, className, width }: SelectionLayerWrapperProps) => {
    const { timeline } = rt

    function xToTime(x: number) {
        const canvasWidth = width
        const frac = x / canvasWidth
        return timeline.start + frac * timeline.duration
    }

    function setCurrentTime(x: number) {
        const { resetCurrentTime } = rt
        resetCurrentTime(xToTime(x))
    }

    function onClick(x: number) {
        const { editingSegment, passage } = rt
        const disabled = passage?.videoBeingCompressed || editingSegment
        if (disabled) {
            return
        }

        setCurrentTime(x)
        rt.timeline.selection = undefined
    }

    function onDrag(startX: number, startY: number, currentX: number) {
        const { editingSegment, passage } = rt
        const disabled = passage?.videoBeingCompressed || editingSegment
        if (disabled) {
            return
        }

        if (startY >= 0) {
            const startTime = xToTime(startX)
            const currentTime = xToTime(currentX)
            if (currentTime < startTime) {
                rt.timeline.selection = new TimelineSelection(currentTime, startTime)
            } else {
                rt.timeline.selection = new TimelineSelection(startTime, currentTime)
            }
        }

        setCurrentTime(currentX)
    }

    function onDragEnd() {
        const { editingSegment, passage } = rt
        const disabled = passage?.videoBeingCompressed || editingSegment
        if (disabled) {
            return
        }

        // Make sure startTime < endTime if the user has dragged left
        if (
            timeline.selection &&
            timeline.selection.start >= 0 &&
            timeline.selection.end >= 0 &&
            timeline.selection.start > timeline.selection.end
        ) {
            rt.timeline.selection = new TimelineSelection(timeline.selection.end, timeline.selection.start)
        }
    }

    return <SelectionLayer className={className} onClick={onClick} onDrag={onDrag} onDragEnd={onDragEnd} />
})

type AudioWaveformVisualizerProps = {
    rt: Root
    className: string
}

const AdvancedWaveformVisualizer = observer(({ rt, className }: AudioWaveformVisualizerProps) => {
    const [waveformData, setWaveformData] = useState<number[]>([])
    const [samplesPerSecond, setSamplesPerSecond] = useState(4)
    const debouncedZoom = useDebouncedValue(rt.timeline.zoomLevel, 1000)
    const debouncedComputedDuration = useDebouncedValue(rt.passageVideo?.computedDuration, 1000)
    const [width, setWidth] = useState(0)
    const [, setHeight] = useState(0)

    const { currentTime, timeline } = rt

    useEffect(() => {
        async function getTheData() {
            const samplesOnScreen = 500
            const { passageVideo, passage } = rt
            if (passageVideo && passage) {
                const waveformCreator = new AudioWaveformCreator()
                const audioSlices = await passageVideo.getPlayableSlices(passage, rt.currentVideos)
                const waveform = await waveformCreator.getNormalizedPCMValues(
                    audioSlices,
                    samplesOnScreen * debouncedZoom
                )
                setSamplesPerSecond(waveform.samplesPerSecond)
                setWaveformData(waveform.normalizedPCMValues)
            } else {
                setSamplesPerSecond(0)
                setWaveformData([])
            }
        }
        getTheData()
    }, [
        rt,
        rt.currentVideos,
        rt.passage,
        rt.passageVideo,
        debouncedComputedDuration,
        rt.currentVideos.allSourcesPresent,
        rt.currentVideos.viewableVideos,
        debouncedZoom
    ])

    function xPosition(time: number) {
        return ((time - timeline.start) / timeline.duration) * width
    }

    function getSegmentPositions() {
        let drawableSegments: IDrawableSegment[] = []
        const { passage, passageVideo } = rt
        if (passage && passageVideo) {
            drawableSegments = passageVideo.getDrawableSegments(passage, timeline)
        }
        const positions = []
        for (const drawableSegment of drawableSegments) {
            const { time } = drawableSegment
            const startX = Math.max(xPosition(time), 0)
            positions.push(startX)
        }
        return positions
    }

    function getTimelineSelection() {
        let timelineSelection
        if (
            timeline.selection &&
            timeline.selection.start >= 0 &&
            timeline.selection.start <= timeline.end &&
            timeline.selection.end >= timeline.start
        ) {
            const start = xPosition(Math.max(timeline.selection.start, timeline.start))
            const end = xPosition(Math.min(timeline.selection.end, timeline.end))
            timelineSelection = { start, end }
        }
        return timelineSelection
    }

    const positions = getSegmentPositions()
    const currentTimeX = xPosition(currentTime)
    const timelineSelection = getTimelineSelection()

    // The first component listed here is rendered on the bottom, and the last
    // component is rendered on the top. Use this to achieve layering.

    // We render the selection layer in a wrapper component because it was
    // rerendering too often.
    return (
        <div style={{ position: 'relative', width: '100%' }}>
            <SelectionVisualization selection={timelineSelection} className={className} />
            <WaveformVisualizer
                waveformData={waveformData.slice(samplesPerSecond * timeline.start, samplesPerSecond * timeline.end)}
                setWidth={setWidth}
                setHeight={setHeight}
                className={className}
            />
            <SegmentMarkerVisualization positions={positions} className={className} />
            <CurrentPositionMarkerVisualization currentPosition={currentTimeX} className={className} />
            <SelectionLayerWrapper width={width} rt={rt} className={className} />
        </div>
    )
})

interface VideoMainBottomSplitBottomProps {
    rt: Root
    videoAreaWidth: number
    setTermId: (termId: string) => void
}

const VideoMainBottomSplitBottom = observer(({ rt, videoAreaWidth, setTermId }: VideoMainBottomSplitBottomProps) => {
    const { recording, passageVideo, verseReference } = rt
    const videoPosition = rt
    const noteBarDisplay = rt
    const glossBarDisplay = rt
    const w = videoAreaWidth

    if (!recording && passageVideo) {
        return (
            <>
                <VideoPositionBar {...{ videoPosition, className: 'video-positionbar video-positionbar-audio' }} />
                {verseReference && <VerseReferenceEditor {...{ videoPosition }} />}
                <ThinVideoPositionBar {...{ videoPosition }} />
                <NoteBar {...{ noteBarDisplay, w: w - 10 }} />{' '}
                {/* Setting width to w is causing the video to expand indefinitely in mobile layout. This seems to fix it. */}
                {enableGlosses && <GlossBar {...{ glossBarDisplay, w: w - 10, setTermId }} />}
            </>
        )
    }

    return <div />
})

type VideoMainBottomProps = {
    rt: Root
    videoAreaWidth: number
    recordingState: AVTTRecordingState
    mediaStream?: MediaStream
    setBiblicalTermMarker: (marker: BiblicalTermMarker) => void
    isAudioOnly: boolean
}

interface AdvancedWaveformVisualizerWrapperProps {
    rt: Root
}

const AdvancedWaveformVisualizerWrapper = observer(({ rt }: AdvancedWaveformVisualizerWrapperProps) => {
    if (rt.currentVideos.downloaded) {
        return <AdvancedWaveformVisualizer {...{ rt, className: 'audio-waveform-visualizer' }} />
    }

    return (
        <AdvancedDownloadingMessage
            urls={rt.currentVideos.viewableVideos.map((vv) => vv.video.url)}
            creator={rt.currentVideos.creator}
        />
    )
})

interface VideoMainBottomSplitTopProps {
    rt: Root
    recordingState: AVTTRecordingState
    mediaStream?: MediaStream
    isAudioOnly: boolean
}

const VideoMainBottomSplitTop = observer(
    ({ rt, recordingState, mediaStream, isAudioOnly }: VideoMainBottomSplitTopProps) => {
        const { passage, passageVideo, recording } = rt
        const videoBeingCompressed = !!passage?.videoBeingCompressed

        if (videoBeingCompressed || (!recording && !passageVideo)) {
            return <VideoMessage rt={rt} />
        }

        if (recording) {
            return (
                <LiveWaveformVisualizerWrapper
                    {...{ mediaStream, recordingState, isAudioOnly, className: 'audio-waveform-visualizer' }}
                />
            )
        }

        if (passageVideo) {
            return <AdvancedWaveformVisualizerWrapper {...{ rt }} />
        }

        return <div />
    }
)

export const VideoMainBottom = observer(
    ({ rt, videoAreaWidth, mediaStream, recordingState, setBiblicalTermMarker, isAudioOnly }: VideoMainBottomProps) => {
        const MIN_MIN_PX_PER_SECOND = 1
        const MAX_MIN_PX_PER_SECOND = 200

        const [termId, setTermId] = useState('')
        const [waveSurferZoom, setWaveSurferZoom] = useState(MIN_MIN_PX_PER_SECOND)
        const [zoomOutEnabled, setZoomOutEnabled] = useState(true)

        // Needed to lift this up to this component because lower level components
        // were being re-constructed at getting this value set back to 0
        // after uploading a patch
        const [editingSegmentPosition, setEditingSegmentPosition] = useState<EditingSegmentPosition>(
            EditingSegmentPosition.None
        )

        const { passageVideo, recording, timeline, passage, canPlayThrough, playbackRate, useMobileLayout } = rt

        const videoPositionControls = rt

        const enabled = !passage?.videoBeingCompressed
        const adjustTimeEnabled = canPlayThrough && enabled

        const tooltip = `Speed = ${playbackRate.toFixed(1)}`

        const onRateChange = (pos: any /* {x:, y: } */) => {
            const newPlaybackRate = 2.0 - pos.y
            rt.setPlaybackRate(newPlaybackRate)
        }

        return (
            <div className="video-main-bottom">
                {termId.trim() !== '' && <ERTermModal rt={rt} termId={termId} setTermId={setTermId} />}
                {enableWaveSurfer && !recording && (
                    <>
                        <VideoPositionBarControls
                            videoPositionControls={videoPositionControls}
                            zoomIn={() => setWaveSurferZoom(Math.min(waveSurferZoom * 2, MAX_MIN_PX_PER_SECOND))}
                            zoomOut={() => setWaveSurferZoom(waveSurferZoom / 2)}
                            zoomInEnabled={enabled && waveSurferZoom < MAX_MIN_PX_PER_SECOND}
                            zoomOutEnabled={enabled && zoomOutEnabled}
                            adjustTimeEnabled={adjustTimeEnabled}
                        />
                        <div className="video-main-bottom-bottom">
                            <WaveSurferAudioPlayer
                                rt={rt}
                                minPxPerSecond={waveSurferZoom}
                                setMinPxPerSecond={setWaveSurferZoom}
                                setBiblicalTermMarker={setBiblicalTermMarker}
                                setZoomOutEnabled={setZoomOutEnabled}
                            />
                            <div className="main-video-player-controls">
                                <div className="u-slider u-slider-y video-player-slider">
                                    <InputSlider
                                        className="slider video-rate-input-slider"
                                        slidertooltip={tooltip}
                                        axis="y"
                                        y={2.0 - playbackRate}
                                        ymax={2}
                                        ystep={0.1}
                                        onChange={onRateChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                )}
                {enableWaveSurfer && rt.verseReference && <VerseReferenceEditor videoPosition={rt} />}
                {(enableLegacyWaveform || recording) && (
                    <>
                        {!recording && (
                            <VideoPositionBarControls
                                videoPositionControls={videoPositionControls}
                                zoomIn={() => {
                                    const { currentTime, duration } = rt
                                    rt.setTimelineZoom(timeline.zoomLevel * 2)
                                    timeline.adjustZoomLimits(true, currentTime, duration)
                                }}
                                zoomOut={() => {
                                    const { currentTime, duration } = rt
                                    rt.setTimelineZoom(timeline.zoomLevel / 2)
                                    timeline.adjustZoomLimits(true, currentTime, duration)
                                }}
                                zoomInEnabled={timeline.zoomLevel < 64 && enabled}
                                zoomOutEnabled={timeline.zoomLevel > 1 && enabled}
                                adjustTimeEnabled={adjustTimeEnabled}
                            />
                        )}
                        <div className="video-main-bottom-bottom">
                            <SplitPane
                                split="horizontal"
                                minSize={40}
                                size={80}
                                maxSize={200}
                                style={{ position: 'relative', height: 'auto', overflow: 'visible', flex: 1 }}
                                allowResize={!recording && !!rt.passageVideo}
                            >
                                <VideoMainBottomSplitTop
                                    rt={rt}
                                    recordingState={recordingState}
                                    mediaStream={mediaStream}
                                    isAudioOnly={isAudioOnly}
                                />
                                <VideoMainBottomSplitBottom
                                    rt={rt}
                                    videoAreaWidth={videoAreaWidth}
                                    setTermId={setTermId}
                                />
                            </SplitPane>
                            <div className="main-video-player-controls">
                                <div className="u-slider u-slider-y video-player-slider">
                                    <InputSlider
                                        className="slider video-rate-input-slider"
                                        slidertooltip={tooltip}
                                        axis="y"
                                        y={2.0 - playbackRate}
                                        ymax={2}
                                        ystep={0.1}
                                        onChange={onRateChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                )}
                {!useMobileLayout && (
                    <ErrorBoundary>
                        <SegmentsEditor
                            rt={rt}
                            editingSegmentPosition={editingSegmentPosition}
                            setEditingSegmentPosition={(value) => {
                                if (passageVideo && !passageVideo.isAudioOnly()) {
                                    setEditingSegmentPosition(value)
                                }
                            }}
                            recordingState={recordingState}
                        />
                    </ErrorBoundary>
                )}
            </div>
        )
    }
)

export interface IProgress {
    total: number
    loaded: number
}
export interface IReportProgress {
    (progress: IProgress): void
}

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:API2')

const intest = localStorage.getItem('intest') === 'true'

// Fetch a blob at path. Optionally report progress.
// CORS must <ExposeHeader>Content-Length</ExposeHeader>

// https://stackoverflow.com/questions/64233494/mediarecorder-does-not-produce-a-valid-webm-file
// As far as I can tell, Chrome records videos in video/x-matroska. The HTML <video>
// element cannot play Matroska files at this time. We don't currently read the MIME
// type of files we download, and Chrome is able to play these types of videos. If
// we ever do read the MIME type from the file, we will need to keep this in mind. I
// believe you can just set the mime type of the blob we return to 'video/webm'.
// Matroska is a subset of webm, so Chrome will play our recorded videos. Another option
// would be to convert the videos into a format Chrome can play, like mp4.
export async function fetchBlob2(url: string, report?: IReportProgress) {
    const response = await fetch(url)
    if (!response.ok) throw Error(`${response.status} ${response.statusText}`)

    const contentType = response.headers.get('content-type') ?? ''
    if (intest) {
        // When testing ignore progress reporting because cypress cannot deal
        // I never managed to get this to work in Cypress to read from fixture files.
        let _blob = await response.blob()
        _blob = new Blob([_blob], { type: contentType })
        log('intest fetchBlob2', url, _blob.size, _blob.type)
        return _blob
    }

    const contentLength = response.headers.get('content-length')
    if (report && !contentLength) {
        // If this test fails it is very likely because the CORS policy in
        // S3 for this bucket does not have
        //     <ExposeHeader>Content-Length</ExposeHeader>
        // In theory serverless.yml but has not been tested in a new deployment.
        throw Error('Content-Length response header unavailable')
    }

    if (!response.body) {
        throw Error('Empty body')
    }
    const reader = response.body.getReader()

    const total = parseInt(contentLength || '9999', 10)
    let loaded = 0

    // Create handler to consume blocks as they are received
    // See: https://developer.mozilla.org/en-US/docs/Web/API/Streams_API/Using_readable_streams
    const start = async function (controller: any) {
        while (true) {
            const { done, value } = await reader.read()
            if (done) {
                break
            }

            if (value !== undefined) {
                loaded += value.byteLength
            }
            report?.({ loaded, total })
            // log('fetchBlob2', url, total, loaded, value.byteLength)

            controller.enqueue(value)
        }

        controller.close()

        // Sometimes when using the compressor the following line throws an
        // exception saying the reader has already been released. Don't know why.
        try {
            reader.releaseLock()
        } catch (error) {
            //
        }
    }

    const rs2 = new ReadableStream({ start })
    const response2 = new Response(rs2)
    let blob = await response2.blob()
    blob = new Blob([blob], { type: contentType })

    return blob
}

/* eslint-disable react/prefer-stateless-function */

// This component controls the recording of videos.
// It displays the video while the recording is happening.
// It pushes video data blobs to videoUploader.
// It calls videoUploader.onRecordingDone when recording is complete.

import { Component } from 'react'
import { observer } from 'mobx-react'
import { observable } from 'mobx'
import { EventEmitter } from 'events'
import { delay } from 'q'
import { t } from 'i18next'

import { VideoUploader } from './VideoUploader'

import { isAVTT } from '../app/slttAvtt'
import { fmt } from '../utils/Fmt'
import { displayError, RecordingNotAllowedByBrowserErrorMessage } from '../utils/Errors'

import './Video.css'
import { AudioContextFactory } from './WaveformVisualizer'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:VideoRecorder')

const beep = (durationMs: number, frequencyHz: number, volume: number): Promise<boolean> => {
    const audioContext = AudioContextFactory.getAudioContext()
    return new Promise((resolve, reject) => {
        try {
            const oscillatorNode = audioContext.createOscillator()
            const gainNode = audioContext.createGain()
            oscillatorNode.connect(gainNode)
            oscillatorNode.frequency.value = frequencyHz
            oscillatorNode.type = 'sine'
            gainNode.connect(audioContext.destination)

            gainNode.gain.value = volume * 0.01

            oscillatorNode.start(audioContext.currentTime)
            oscillatorNode.stop(audioContext.currentTime + durationMs * 0.001)
            oscillatorNode.onended = () => {
                resolve(true)
            }
        } catch (error) {
            reject(error)
        }
    })
}

const beepHighC = () => beep(250, 1046, 100)

interface IVideoRecorder {
    videoUploader?: VideoUploader
    // The video uploader is passed to us from our parent as a tricky (?!) way to allow
    // the parent to create a new item of an appropriate type when the recording is complete.

    usePauseAndResume?: boolean
    setRecordingState?: (state: AVTTRecordingState) => void
    setMediaStream?: (stream: MediaStream) => void
    confirmBeforeStarting?: boolean
}

export type AVTTRecordingState =
    | 'NOT_INITIALIZED'
    | 'INITIALIZED'
    | 'RECORDING_IN_THREE_SECONDS'
    | 'RECORDING_IN_TWO_SECONDS'
    | 'RECORDING_IN_ONE_SECOND'
    | 'RECORDING'
    | 'PAUSED'
    | 'STOPPED'

@observer
export default class VideoRecorder extends Component<IVideoRecorder> {
    private vc: any

    private mediaRecorder: MediaRecorder | null = null

    @observable recordingState: AVTTRecordingState = 'NOT_INITIALIZED'

    cancelled = false

    mediaStream: MediaStream | null = null

    constructor(props: IVideoRecorder) {
        super(props)

        this.errorStop = this.errorStop.bind(this)
        this.stop = this.stop.bind(this)
        this.setupVideoAndAudio = this.setupVideoAndAudio.bind(this)
        this.startRecording = this.startRecording.bind(this)
        this.pause = this.pause.bind(this)
        this.resume = this.resume.bind(this)
    }

    componentDidMount() {
        const { confirmBeforeStarting } = this.props

        const _record = async () => {
            try {
                await this.setupVideoAndAudio()
                if (!confirmBeforeStarting) {
                    await this.startRecordingAfterCountdown()
                }
            } catch (err) {
                this.errorStop(err)
            }
        }

        setTimeout(_record, 1000)
    }

    componentWillUnmount() {
        const { mediaRecorder, mediaStream } = this
        const { setRecordingState } = this.props

        if (mediaRecorder) {
            const { state } = mediaRecorder
            if (state === 'recording' || state === 'paused') {
                this.cancel()
            }
        }

        // Release camera and microphone
        if (mediaStream) {
            const tracks = mediaStream.getTracks()
            for (const track of tracks) {
                track.stop()
            }
        }

        setRecordingState?.('NOT_INITIALIZED') // reset recording state
    }

    setRecordingState = (state: AVTTRecordingState) => {
        const { setRecordingState } = this.props
        log('setRecordingState', state)
        this.recordingState = state
        setRecordingState?.(state)
    }

    async setupVideoAndAudio() {
        this.setRecordingState('NOT_INITIALIZED')
        const { setMediaStream } = this.props

        try {
            // let frameRate = API.idealFrameRate(videoUploader.projectName)

            log('initializing media stream')

            this.mediaStream = await navigator.mediaDevices.getUserMedia({
                audio: isAVTT,
                video: {
                    // width: { ideal: 1280 },
                    height: { ideal: 480 }
                    // frameRate: { ideal: frameRate },
                }
            })

            setMediaStream?.(this.mediaStream)

            this.vc.srcObject = this.mediaStream
            if (isAVTT) {
                this.vc.volume = 0.0 // prevent feedback
            }

            this.setRecordingState('INITIALIZED')
        } catch (err) {
            this.errorStop(err)
        }
    }

    async beginCountdown() {
        this.setRecordingState('RECORDING_IN_THREE_SECONDS')
        beepHighC()
        await delay(1000)
        this.setRecordingState('RECORDING_IN_TWO_SECONDS')
        beepHighC()
        await delay(1000)
        this.setRecordingState('RECORDING_IN_ONE_SECOND')
        beepHighC()
        await delay(1000)
    }

    async startRecordingAfterCountdown() {
        await this.beginCountdown()
        await this.startRecording()
    }

    async startRecording() {
        try {
            // let frameRate = API.idealFrameRate(videoUploader.projectName)

            if (!this.mediaStream) {
                log('### startRecording failed, no mediaStream')
                return
            }

            log('startRecording')

            const mediaRecorder = new MediaRecorder(this.mediaStream)
            this.mediaRecorder = mediaRecorder

            mediaRecorder.ondataavailable = this.dataAvailable.bind(this)

            mediaRecorder.start(10000)
            this.setRecordingState('RECORDING')
        } catch (err) {
            this.errorStop(err)
        }
    }

    // This is an event handler for mediaRecorder
    dataAvailable(event: any) {
        const {
            cancelled,
            mediaRecorder,
            props: { videoUploader }
        } = this

        // If the mediaRecorder is not active then there will be no more blobs
        const lastBlob = this.mediaRecorder?.state === 'inactive'

        log(
            'dataAvailable',
            fmt({
                state: mediaRecorder?.state,
                lastBlob,
                cancelled
            })
        )

        if (cancelled) return

        videoUploader?.pushVideoBlob(event.data, lastBlob).catch(this.errorStop)
    }

    errorStop(err: any) {
        const { videoUploader } = this.props
        log(`errorStop`, err, videoUploader)

        if (videoUploader && !this.cancelled) {
            if (err.name === 'NotAllowedError') {
                displayError(err, undefined, <RecordingNotAllowedByBrowserErrorMessage />)
            } else {
                displayError(err)
            }
            videoUploader.onRecordingDone({ err })
        }
        this.stop()
    }

    // Only called when user has explictily requeted that video recording be permanently
    // stopped.
    // Should not be called when recording has been paused.
    // May be invoked by this control or externally.
    stop() {
        log('stop')
        const {
            mediaRecorder,
            mediaStream,
            props: { videoUploader }
        } = this

        try {
            if (mediaRecorder) {
                // state is inactive/recording/paused
                const { state } = mediaRecorder
                if (state === 'recording' || state === 'paused') {
                    mediaRecorder.stop()
                }
            }

            // release camera and microphone
            if (mediaStream) {
                const tracks = mediaStream.getTracks()
                for (const track of tracks) {
                    track.stop()
                }
            }
            this.setRecordingState('STOPPED')
        } catch (err) {
            if (!this.cancelled) {
                videoUploader?.onRecordingDone({ err })
            }

            // release camera and microphone
            if (mediaStream) {
                const tracks = mediaStream.getTracks()
                for (const track of tracks) {
                    track.stop()
                }
            }
        }
    }

    pause() {
        log('pause')
        const { mediaRecorder } = this

        if (mediaRecorder === null) {
            log('### mediaRecorder not set, PAUSE action skiped')
            return
        }

        try {
            this.setRecordingState('PAUSED')
            mediaRecorder.pause()
        } catch (err) {
            console.log(err)
        }
    }

    resume() {
        log('resume')
        const { mediaRecorder } = this

        if (mediaRecorder === null) {
            log('### mediaRecorder not set, RESUME action skiped')
            return
        }

        try {
            mediaRecorder.resume()
            this.setRecordingState('RECORDING')
        } catch (err) {
            console.log('### resume failed', err)
        }
    }

    cancel() {
        log('cancel')
        this.cancelled = true
        this.stop()
    }

    render() {
        const { recordingState } = this

        const watermarkText = recordingState === 'PAUSED' ? t('Paused') : ''

        let countdown = ''
        if (recordingState === 'RECORDING_IN_THREE_SECONDS') {
            countdown = '3'
        } else if (recordingState === 'RECORDING_IN_TWO_SECONDS') {
            countdown = '2'
        } else if (recordingState === 'RECORDING_IN_ONE_SECOND') {
            countdown = '1'
        }

        return (
            <div className="video-recording-area">
                {watermarkText && (
                    <div className="video-recording-area-message-wrapper">
                        <div className="video-recording-area-message">{watermarkText}</div>
                    </div>
                )}
                {countdown && (
                    <div className="video-recording-countdown-wrapper">
                        <div className="video-recording-area-message">{countdown}</div>
                    </div>
                )}
                <video
                    className="video-recorder video-border"
                    ref={(vc) => {
                        this.vc = vc
                    }}
                    autoPlay
                />
            </div>
        )
    }
}

// Events:
// onRecordingDone: () => void,
// onError: (err: any) => void,
export class AudioRecorder extends EventEmitter {
    blobs: Blob[] = []

    cancelled = false

    @observable recordingState: AVTTRecordingState = 'NOT_INITIALIZED'

    @observable mediaStream?: MediaStream

    mediaRecorder?: MediaRecorder

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onRecordingStateChange?: (state: AVTTRecordingState) => void

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setMediaStream?: (stream: MediaStream) => void

    constructor(
        onRecordingStateChange?: (state: AVTTRecordingState) => void,
        setMediaStream?: (stream: MediaStream) => void
    ) {
        super()
        this.onRecordingStateChange = onRecordingStateChange
        this.setMediaStream = setMediaStream
    }

    setRecordingState(state: AVTTRecordingState) {
        this.recordingState = state
        this.onRecordingStateChange?.(state)
    }

    async _record() {
        try {
            await this.setupAudio()
            await this.beginCountdown()
            this.startRecording()
        } catch (err) {
            this.errorStop(err)
            this.releaseTracks()
        }
    }

    async beginCountdown() {
        this.setRecordingState('RECORDING_IN_THREE_SECONDS')
        beepHighC()
        await delay(1000)
        this.setRecordingState('RECORDING_IN_TWO_SECONDS')
        beepHighC()
        await delay(1000)
        this.setRecordingState('RECORDING_IN_ONE_SECOND')
        beepHighC()
        await delay(1000)
    }

    async setupAudio() {
        this.setRecordingState('NOT_INITIALIZED')

        try {
            const _mediaStream = await navigator.mediaDevices.getUserMedia({
                audio: true,
                video: false
            })

            this.mediaStream = _mediaStream
            this.setMediaStream?.(_mediaStream)
            this.setRecordingState('INITIALIZED')
        } catch (err) {
            this.errorStop(err)
            this.releaseTracks()
        }
    }

    async startRecording() {
        if (!this.mediaStream) {
            log('### startRecording failed, no mediaStream')
            return
        }

        log('startRecording')

        const mediaRecorder = new MediaRecorder(this.mediaStream)
        this.mediaRecorder = mediaRecorder

        mediaRecorder.ondataavailable = this.onDataAvailable.bind(this)
        mediaRecorder.start(10000)
        this.setRecordingState('RECORDING')
    }

    onDataAvailable(event: any) {
        const lastBlob = this.mediaRecorder?.state === 'inactive'

        log(
            'dataAvailable',
            fmt({
                state: this.mediaRecorder?.state,
                lastBlob,
                cancelled: this.cancelled
            })
        )

        if (this.cancelled) {
            return
        }

        this.blobs.push(event.data)
        if (lastBlob) {
            this.emit('onRecordingDone')
        }
    }

    errorStop(err: any) {
        log('errorStop', err)

        if (!this.cancelled) {
            if (err.name === 'NotAllowedError') {
                displayError(err, undefined, <RecordingNotAllowedByBrowserErrorMessage />)
            } else {
                displayError(err)
            }
            this.emit('onError', err)
        }
        this.stopRecording()
    }

    // Not named stop() because stop function exists on window
    stopRecording() {
        log('stop')

        try {
            if (this.mediaRecorder) {
                const { state } = this.mediaRecorder
                if (state === 'recording' || state === 'paused') {
                    this.mediaRecorder.stop()
                }
            }

            this.releaseTracks()
            this.setRecordingState('STOPPED')
        } catch (err) {
            if (!this.cancelled) {
                this.emit('onError', err)
            }
            this.releaseTracks()
        }
    }

    pause() {
        log('pause')
        const { mediaRecorder } = this

        if (!mediaRecorder) {
            log('### mediaRecorder not set, PAUSE action skiped')
            return
        }

        try {
            this.setRecordingState('PAUSED')
            mediaRecorder.pause()
        } catch (err) {
            console.log(err)
        }
    }

    resume() {
        log('resume')
        const { mediaRecorder } = this

        if (!mediaRecorder) {
            log('### mediaRecorder not set, RESUME action skiped')
            return
        }

        try {
            mediaRecorder.resume()
            this.setRecordingState('RECORDING')
        } catch (err) {
            console.log('### resume failed', err)
        }
    }

    cancel() {
        log('cancel')
        this.cancelled = true
        this.stopRecording()
    }

    releaseTracks() {
        const tracks = this.mediaStream?.getTracks() ?? []
        for (const track of tracks) {
            track.stop()
        }
    }
}

// Adapts AudioRecorder to the IVideoRecorder interface so that it can be used
// everywhere VideoRecorder can.
// Note: Clients may want to create a ref to this component, so it must be a class component
@observer
export class AudioRecorderComponent extends Component<IVideoRecorder> {
    private recorder: AudioRecorder

    // eslint-disable-next-line react/no-unused-class-component-methods
    @observable recordingState: AVTTRecordingState = 'NOT_INITIALIZED'

    constructor(props: IVideoRecorder) {
        super(props)

        const { setMediaStream } = this.props

        this.stop = this.stop.bind(this)
        this.startRecording = this.startRecording.bind(this)
        this.pause = this.pause.bind(this)
        this.resume = this.resume.bind(this)
        this.cancel = this.cancel.bind(this)
        this.onRecordingDone = this.onRecordingDone.bind(this)
        this.onError = this.onError.bind(this)

        this.recorder = new AudioRecorder(this.setRecordingState.bind(this), setMediaStream)
    }

    componentDidMount() {
        this.recorder.addListener('onRecordingDone', this.onRecordingDone)
        this.recorder.addListener('onError', this.onError)
        const _record = () => this.recorder._record()
        setTimeout(_record, 1000)
    }

    componentWillUnmount() {
        const { setRecordingState } = this.props

        this.recorder.removeListener('onRecordingDone', this.onRecordingDone)
        this.recorder.removeListener('onError', this.onError)
        this.recorder.releaseTracks()
        setRecordingState?.('NOT_INITIALIZED') // reset recording state
    }

    async onRecordingDone() {
        const { videoUploader } = this.props

        for (let i = 0; i < this.recorder.blobs.length; i++) {
            await videoUploader?.pushVideoBlob(this.recorder.blobs[i], i === this.recorder.blobs.length - 1)
        }
    }

    onError(err: any) {
        const { videoUploader } = this.props

        videoUploader?.onRecordingDone({ err })
    }

    setRecordingState = (state: AVTTRecordingState) => {
        const { setRecordingState } = this.props

        log('setRecordingState', state)
        // eslint-disable-next-line react/no-unused-class-component-methods
        this.recordingState = state

        // Following line has ? because not all callers want to be informed
        setRecordingState?.(state)
    }

    startRecording() {
        this.recorder.startRecording()
    }

    stop() {
        this.recorder.stopRecording()
    }

    pause() {
        this.recorder.pause()
    }

    resume() {
        this.recorder.resume()
    }

    cancel() {
        this.recorder.cancel()
    }

    render() {
        return <div className="video-recorder" />
    }
}

import { observable } from 'mobx'

import { _LevelupDB } from './_LevelupDB'
import { DBObject } from './DBObject'
import { IDateFormatter } from './DateUtilities'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:Models')

export class ProjectMessage extends DBObject {
    // S3 url for video (videoMessage=true) or text document
    @observable videoUrl = ''

    @observable text = ''

    // True if this is a sltt project wide message
    @observable globalMessage = false

    // Subject line for message
    @observable subject = ''

    // If this message is a response to another message,
    // the _id of the parent message. Otherwise ''.
    @observable parent = ''

    @observable responses: ProjectMessage[] = []

    // True if the user has viewed the content of this message
    // Persisted to localDB, Not persisted to DynamoDB
    @observable viewed = false

    // Seq field of local database record that generated this entry
    // Not persisted to DynamoDB
    seq = 0

    model = 16

    toDocument() {
        const { videoUrl, text, rank, globalMessage, subject, parent, viewed, seq, model } = this
        return this._toDocument({
            videoUrl,
            text,
            rank,
            globalMessage,
            subject,
            parent,
            viewed,
            seq,
            model
        })
    }

    static isGlobalProject(name: string) {
        return name === 'SLTT' || name === 'AVTT'
    }

    async setGlobalMessage(globalMessage: boolean) {
        if (globalMessage === this.globalMessage) {
            return
        }

        const doc = this._toDocument({ model: this.model, globalMessage })
        await this.db.put(doc)
    }

    dbg() {
        const doc = this.toDocument()
        doc.responses = this.responses.map((r) => r.dbg())

        return doc
    }

    dbg2(): any {
        const { _id, parent, text, videoUrl, viewed, subject } = this
        return {
            _id,
            // creationDate: creationDate.slice(0,19),
            parent,
            subject,
            text: text?.slice(0, 50),
            videoUrl,
            viewed,
            responses: this.responses.map((r) => r.dbg2())
        }
    }

    displayedCreationDate(dateFormatter: IDateFormatter) {
        const date = new Date(this.creationDate)
        return dateFormatter.format(date)
    }

    async setViewed(viewed: boolean) {
        for (const response of this.responses) {
            await response.setViewed(viewed)
        }

        if (this.viewed === viewed) return
        log('setViewed', this._id, viewed, this.seq)

        const _db = this.db as _LevelupDB
        const doc = this.toDocument()
        doc.viewed = viewed
        await _db.db.put(this.seq, JSON.stringify(doc))

        this.viewed = viewed
    }

    /**
     * True iff this message or any of its responses are unviewed
     */
    anyUnviewed(username: string) {
        if (!this.viewed && this.creator !== username) return true

        if (this.responses.some((r) => !r.viewed && r.creator !== username)) {
            return true
        }

        return false
    }
}

import React, { FC } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import NumberInput from './NumberInput'
import { RootConsumer } from '../app/RootContext'

interface IDifficultyView {
    stopEditing: () => void
    difficulty: number
    setDifficulty: (difficulty: number) => void
}

const DifficultyView: FC<IDifficultyView> = ({ difficulty, setDifficulty, stopEditing }) => {
    const { t } = useTranslation()

    return (
        <RootConsumer>
            {() => (
                <span className="difficulty-editor">
                    <NumberInput
                        message={t('Type Enter to set new difficulty or Esc to cancel.')}
                        initialValue={difficulty}
                        min={0}
                        onEnter={(value) => {
                            if (!isNaN(value)) {
                                setDifficulty(value)
                            }
                            stopEditing()
                        }}
                        onEscape={stopEditing}
                    />
                </span>
            )}
        </RootConsumer>
    )
}

export default observer(DifficultyView)

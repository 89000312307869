/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prefer-stateless-function */

import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { t } from 'i18next'

import ProjectStageAdder from './ProjectStageAdder'
import { EditableProjectStage } from './EditableProjectStage'

import { ProjectStage } from '../../../models3/ProjectStage'
import { Root } from '../../../models3/Root'

import { displayError } from '../../utils/Errors'

interface IStage {
    index: number
    stage: ProjectStage
    addStage: (name: string, index: number) => void
    deleteStage: (_id: string) => void
    rt: Root
}

class Stage extends Component<IStage> {
    render() {
        const { index, stage, addStage, deleteStage, rt } = this.props
        const { iAmAdmin } = rt
        const plan = rt.project.plans[0]
        if (!plan) {
            return null
        }
        return (
            <div className="stage">
                {iAmAdmin && index === 0 && (
                    <ProjectStageAdder plan={plan} addStage={(newStage) => addStage(newStage, index)} />
                )}
                <EditableProjectStage
                    stage={stage}
                    index={index}
                    showStageDetails
                    deleteStage={() => deleteStage(stage._id)}
                    rt={rt}
                />
                {iAmAdmin && <ProjectStageAdder plan={plan} addStage={(newStage) => addStage(newStage, index + 1)} />}
            </div>
        )
    }
}

interface IEditableStageList {
    rt: Root
}

@observer
export class EditableStageList extends Component<IEditableStageList> {
    addStage = async (name: string, index: number) => {
        const { rt } = this.props
        const { project } = rt
        const plan = project.plans[0]

        if (plan) {
            if (plan.stages.find((stage) => stage.name === 'Not started')) {
                // 'Not started' is the 1st stage
                index += 1
            }

            // Ensure stage added before 'Finished' stage
            index = Math.min(index, plan.stages.length - 1)
            try {
                await plan.addStage(index, name)
            } catch (error) {
                displayError(error)
            }
        }
    }

    deleteStage = (_id: string) => {
        const { rt } = this.props
        const { project } = rt
        project.plans[0]?.removeStage(project, _id)
    }

    render() {
        const { rt } = this.props
        const { iAmAdmin, useMobileLayout, project } = rt
        const plan = project.plans[0]

        if (!plan) {
            return null
        }

        const viewableStages = plan.viewableStages || []
        return (
            <div>
                {viewableStages.length > 0 ? (
                    <div className="project-plan">
                        {viewableStages.map((stage, index) => (
                            <Stage
                                key={index}
                                index={index}
                                stage={stage}
                                addStage={this.addStage}
                                deleteStage={this.deleteStage}
                                rt={rt}
                            />
                        ))}
                    </div>
                ) : (
                    <div>
                        {!iAmAdmin && <div>{t('An admin has not created a project plan yet.')}</div>}
                        {iAmAdmin && (
                            <>
                                <div>{t('Create a project plan to keep track of the status of each portion.')}</div>
                                {useMobileLayout && (
                                    <div>{t('Switch to a larger screen to create a project plan.')}</div>
                                )}
                                {!useMobileLayout && (
                                    <ProjectStageAdder plan={plan} addStage={(name) => this.addStage(name, 0)} />
                                )}
                            </>
                        )}
                    </div>
                )}
            </div>
        )
    }
}

export default observer(Stage)

import React, { Component } from 'react'
import { confirmAlert } from 'react-confirm-alert'
import { observer } from 'mobx-react'
import { t } from 'i18next'
import { RootConsumer } from '../app/RootContext'

interface INavigateAway {
    children: (props: NavigateAwayChildrenProps) => JSX.Element
}

export interface NavigateAwayChildrenProps {
    confirm: () => Promise<boolean>
    notifyOfUnsavedChanges: (value: boolean) => void
}

class NavigateAway extends Component<INavigateAway> {
    unsavedChangesExist = false

    childrenProps: NavigateAwayChildrenProps

    constructor(props: INavigateAway) {
        super(props)
        this.childrenProps = {
            confirm: this.navigateAway.bind(this),
            notifyOfUnsavedChanges: this.notifyOfUnsavedChanges.bind(this)
        }
    }

    displayUnsavedChangesDialog = (onConfirm: () => void, onCancel: () => void) => {
        confirmAlert({
            title: t('Unsaved changes exist'),
            message: t('Unsaved changes exist. Are you sure you want to continue?'),
            confirmLabel: t('Proceed without saving'),
            cancelLabel: t('Cancel'),
            onConfirm,
            onCancel
        })
    }

    notifyOfUnsavedChanges(value: boolean) {
        this.unsavedChangesExist = value
    }

    async navigateAway(): Promise<boolean> {
        return new Promise((resolve) => {
            const { unsavedChangesExist } = this

            if (unsavedChangesExist) {
                this.displayUnsavedChangesDialog(
                    () => {
                        this.notifyOfUnsavedChanges(false)
                        resolve(true)
                    },
                    () => resolve(false)
                )
            } else {
                resolve(true)
            }
        })
    }

    render() {
        const { children } = this.props

        return <RootConsumer>{() => <div>{children({ ...this.childrenProps })}</div>}</RootConsumer>
    }
}

export default observer(NavigateAway)

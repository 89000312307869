import React, { FC } from 'react'
import { confirmAlert } from 'react-confirm-alert'
import { useTranslation } from 'react-i18next'

import { Passage } from '../../models3/Passage'
import { PassageSegment } from '../../models3/PassageSegment'
import { PassageVideo } from '../../models3/PassageVideo'
import { Root } from '../../models3/Root'

import { DeletePatchVideoButton } from '../utils/Buttons'
import { systemError } from '../utils/Errors'

interface IPatchVideoDeleter {
    rt: Root
    passage: Passage
    passageVideo: PassageVideo
    segment: PassageSegment
    deleteSelectionSegments?: boolean
}

export async function deletePatch(props: IPatchVideoDeleter) {
    const { rt, passage, passageVideo, segment, deleteSelectionSegments } = props

    const segmentIndex = passageVideo.findSegmentIndex(segment._id, true)
    const patchVideo = passage.findVideo(segment.videoPatchHistory.slice(-1)[0])

    if (!passage || !patchVideo || !patchVideo.isPatch || segmentIndex < 0) {
        throw Error('Something went wrong')
    }

    await passage.deletePatchVideo(passageVideo, patchVideo, segment)

    // If no patches in the previous (and next) segment,
    // reset the ending (starting) position back to the default value.
    if (segmentIndex > 0) {
        // not first segment
        const previousSegment = passageVideo.segments[segmentIndex - 1]
        if (!previousSegment.isPatched) {
            const newEndPosition = previousSegment.hardEndPosition(passageVideo)
            await previousSegment.setEndPosition(newEndPosition, passageVideo)
        }
    }
    if (segmentIndex < passageVideo.segments.length - 1) {
        // not last segment
        const nextSegment = passageVideo.segments[segmentIndex + 1]
        if (!nextSegment.isPatched) {
            const newStartPosition = nextSegment.hardStartPosition(passageVideo)
            await nextSegment.setStartPosition(newStartPosition, passageVideo)
        }
    }

    // If we auto added two segments, remove them now
    if (deleteSelectionSegments) {
        await passageVideo.removeSegment(passageVideo.segments[segmentIndex + 1]._id)
        await passageVideo.removeSegment(passageVideo.segments[segmentIndex]._id)
    }

    // Reset times to account for the fact that we removed a patch and thus
    // changed the length of the total video.
    passageVideo.setSegmentTimes(passage, true)

    await rt.setPassageVideo(passageVideo) // Cause view to refresh
    rt.resetCurrentTime(segment.time + 0.01, passageVideo.computedDuration)
}
interface IPatchVideoDeleterComponent {
    deleter: IPatchVideoDeleter
    enabled: boolean
}

export const PatchVideoDeleter: FC<IPatchVideoDeleterComponent> = ({ deleter, enabled }) => {
    const { t } = useTranslation()

    function confirmDeletion(doDeletion: () => void) {
        confirmAlert({
            title: t('recordingDeleteThisPatch'),
            message: t(
                'All notes and glosses that were applied to this patch will be deleted as well. This segment will revert back to the unpatched version. Are you sure you want to do this?'
            ),
            confirmLabel: t('Delete!!!'),
            cancelLabel: t('Keep.'),
            onConfirm: doDeletion
        })
    }

    return (
        <DeletePatchVideoButton
            onClick={() => {
                confirmDeletion(() => {
                    deletePatch(deleter).catch(systemError)
                })
            }}
            enabled={enabled}
        />
    )
}

import React, { Component, FC, useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react'
import { observable } from 'mobx'
import { t } from 'i18next'

import { Root } from '../../models3/Root'
import { ImageMetadata, ImageDefinition } from '../../resources/ImageMetadata'
import { ProjectImages } from '../../resources/ProjectImages'

import { ExpandButton, PreviousSegmentButton, NextSegmentButton, PaneCloseButton, EditButton } from '../utils/Buttons'
import DropTarget from '../utils/DropTarget'
import { DropTargetViewLarge } from '../utils/DropTargetView'
import { displayError } from '../utils/Errors'
import { currentTimestampSafeString } from '../utils/Helpers'
import { LoadingIcon, TitleIcon } from '../utils/Icons'
import { Optional } from '../utils/Optional'
import { ReferenceInput } from '../utils/ReferenceInput'
import { Switch } from '../utils/Switch'
import VideoDownloading from '../utils/VideoDownloading'

import ImageMetadataEditor from './ImageMetadataEditor'
import { ImagesRoot } from './ImagesRoot'

import './Images.css'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:Images')

/*
    Displays images for search parameter references.
    Images are either from MARBLE or from projects.
    Project images may be shared with other projects.
    Captures/uploads project image file drops.

    components
        Images.tsx
            ImageDropTarget
                ImageSearchToolbar - allow user to enter search parameter, e.g. Luke 1.1-3
                ImagesList - show images matching search parameter
                    ImageViewer - show currently selected image
                        ImageMetadataEditor
                        FullSizeImageViewer
                    ImageThumbnail
                ImageUploader - upload a dropped image
                    ImageMetadataEditor.tsx
        ImagesRoot.ts - shared variables for images components
        ImageCollection.ts - handle search by reference for both project and MARBLE images
    resources
        ImageMetadata.ts
        ProjectImages.ts - get/put project images from DynamoDB via API
        MARBLEImages.ts - get MARBLE images from S3
 */

/* Functions

   - drag/drop new image, cancel
   - drag/drop new image, edit all fields, save
   - delete existing image
   - edit metadata for existing image
   - select image and show
        - step through images
   - share image, verify other project can see (disable sharing, should not appear)
   - images with numbered citations should be shown at front and in correct order
   - show solid border for shared project images
   - show dotted border for non-shared project images
 */

const imageTooltip = (image: ImageMetadata, rt: Root) => {
    const { copyright, references } = image

    const definition = image.getDefinition(rt.uiLanguage)

    const { title, description } = definition

    const lines: string[] = []
    if (title || description) {
        lines.push(title + (description ? ` / ${description}` : ''))
    }

    if (copyright) {
        lines.push(`\u00a9${copyright}`)
    }

    lines.push(rt.displayableReferences(references))

    return lines.join('\n')
}

interface IFullSizeImageViewer {
    irt: ImagesRoot
    image: ImageMetadata
    setSrc: (src: string) => void
}

const FullSizeImageViewer: FC<IFullSizeImageViewer> = ({ irt, image, setSrc }) => {
    log('FullSizeImageViewer')

    const [source, setSource] = useState('')

    const componentIsMounted = useRef(true)
    useEffect(() => {
        return () => {
            componentIsMounted.current = false
        }
    }, [])

    useEffect(() => {
        async function fetchImage() {
            log('FullSizeImageViewer fetchImage', image.id)

            const src = await image.fetchImageSrc()
            if (componentIsMounted.current) {
                setSrc(src)
                setSource(src)
            }
        }

        fetchImage()
    }, [image, setSrc])

    let className = 'image-thumbnail'
    if (irt.rt.editingImage) className += ' image-thumbnail-editing'

    if (source) {
        return <img src={source} className={className} />
    }

    return <div className="media-placeholder" />
}

interface IImageUploader {
    irt: ImagesRoot
    imageFile: File
    onDone: () => void
}

@observer
class ImageUploader extends Component<IImageUploader> {
    @observable image: ImageMetadata

    @observable message = ''

    constructor(props: IImageUploader) {
        super(props)

        const { irt } = this.props
        const { rt, searchRefs } = irt
        const image = new ImageMetadata()

        const fileName = currentTimestampSafeString() // Use date/time as file name
        image.fileName = fileName
        image.isProjectImage = true

        const projectName = rt.project.name

        image.id = `${projectName}/${fileName}`
        image.project = projectName
        image.path = projectName

        image.references = searchRefs
        image.copyright = rt.project.copyrightStatement
        const definition = new ImageDefinition()
        definition.languageCode = 'en'
        image.definitions = [definition]

        this.image = image
        this.message = ''

        log('createDefaultImageFromFile', JSON.stringify(image, null, 4))
    }

    uploadImage = async () => {
        const { image } = this
        const { imageFile, irt, onDone } = this.props

        this.message = t('Uploading image...')
        log(this.message)

        try {
            await ProjectImages.putProjectImageDataAndMetadata(imageFile, image)
            irt.adjustSearchRefs(image.references)

            // remember copyright used so that we can supply it as the default
            // for this project in the future.
            await irt.rt.project.setCopyrightStatement(image.copyright)
        } catch (error) {
            log('###uploadImage', error)
            displayError(error, t('Cannot upload image at this time.'))
        }

        this.message = ''
        onDone()
    }

    render() {
        const { image, message } = this
        const { irt, imageFile, onDone } = this.props

        if (image) {
            const src = window.URL.createObjectURL(imageFile)
            return (
                <div>
                    <div>
                        <img src={src} className="image-thumbnail-editing" />
                    </div>
                    <ImageMetadataEditor
                        irt={irt}
                        showDeleteButton={false} // Image has not been created yet, nothing to delete
                        close={async (saved) => {
                            if (saved) {
                                await this.uploadImage()
                                await irt.refresh()
                            }
                            irt.rt.editingImage = false
                            onDone()
                        }}
                        image={image}
                    />
                </div>
            )
        }

        return (
            <span>
                {message || ''} <LoadingIcon className="" />
            </span>
        )
    }
}

interface IImagesDropTarget {
    irt: ImagesRoot
}

@observer
export class ImagesDropTarget extends Component<IImagesDropTarget> {
    @observable imageFile: File | null = null

    upload = async (files: FileList) => {
        const {
            irt: { rt }
        } = this.props
        const { iAmConsultant } = rt

        if (!iAmConsultant) {
            displayError(t('Observers cannot upload images.'))
            return
        }

        if (files.length > 1) {
            displayError(t('You must drop exactly one file.'))
            return
        }

        const file = files[0]
        const allowedImageTypes = ['image/jpeg', 'image/png']
        if (!allowedImageTypes.includes(file.type)) {
            displayError(t('Cannot upload this type of file.'))
            return
        }

        this.imageFile = file
    }

    render() {
        const { irt, children } = this.props
        const { imageFile } = this

        const dropTargetMessage = <div>{t('Drop an image here to upload.')}</div>
        const dropTargetView = <DropTargetViewLarge message={dropTargetMessage} />

        if (imageFile) {
            return (
                <div className="images-tab">
                    <ImageUploader
                        irt={irt}
                        imageFile={imageFile}
                        onDone={() => {
                            this.imageFile = null
                        }}
                    />
                </div>
            )
        }

        return (
            <div className="images-tab">
                <DropTarget upload={this.upload} dropTargetView={dropTargetView}>
                    {children}
                </DropTarget>
            </div>
        )
    }
}

interface IImageSearchToolbar {
    irt: ImagesRoot
}

@observer
class ImageSearchToolbar extends Component<IImageSearchToolbar> {
    @observable errored = false

    render() {
        const { irt } = this.props
        const { rt, showImageTitles } = irt

        const { errored } = this

        return (
            <div className="image-references-toolbar">
                {!rt.editingImage && (
                    <ReferenceInput
                        refInput={rt}
                        refs={[]}
                        setRefs={(refRanges) => {
                            irt.setSearchRefs(refRanges)
                        }}
                        errored={errored}
                        setErrored={(value) => (this.errored = value)}
                        includeGotoReferenceButton
                        defaultReferenceId="ImagesReference"
                    />
                )}
                <label className="image-display-control">
                    <div className="image-title-switch">
                        <Switch
                            className="image-title-switch"
                            value={showImageTitles}
                            setValue={(value: boolean) => {
                                irt.showImageTitles = value
                                localStorage.setItem('showImageTitles', value ? 'true' : '')
                            }}
                            enabled
                            tooltip={t('Show image titles')}
                        >
                            <TitleIcon className="image-title-tag" tooltip="" />
                        </Switch>
                    </div>
                </label>
            </div>
        )
    }
}

interface IImageThumbnail {
    irt: ImagesRoot
    image: ImageMetadata
    viewImage: () => void
    editImage: () => void
}

const ImageThumbnail: FC<IImageThumbnail> = ({ irt, image, viewImage, editImage }) => {
    const [src, setSrc] = useState(image.isProjectImage ? '' : image.thumbnail)

    const componentIsMounted = useRef(true)
    useEffect(() => {
        return () => {
            componentIsMounted.current = false
        }
    }, [])

    if (!src && image.isProjectImage) {
        return (
            <div className="image-search-item">
                <VideoDownloading
                    className="media-placeholder"
                    videoUrl={image.thumbnail}
                    creator=""
                    fontSizePt={28}
                    onEnded={(blob) => componentIsMounted.current && setSrc(window.URL.createObjectURL(blob))}
                />
            </div>
        )
    }

    const { rt } = irt
    const { name } = rt.project

    const definition = image.getDefinition(rt.uiLanguage)
    const { title } = definition

    const tooltip = imageTooltip(image, rt)

    const projectNamesMatch = name === image.project

    // For shared (global) images, only root can edit.
    const canEditImage = image.shared ? irt.rt.iAmRoot : image.isProjectImage && projectNamesMatch

    let className = 'image-search-item'

    if (image.isProjectImage) {
        className = `${image.shared ? 'shared-' : ''}project-image-thumbnail ${className}`
    }

    if (src) {
        return (
            <div className={className}>
                <img
                    src={src}
                    className="image-container image-thumbnail image-button"
                    data-toggle="tooltip"
                    title={tooltip}
                    onClick={viewImage}
                />
                {canEditImage && (
                    <EditButton
                        enabled
                        className="image-search-edit-button"
                        onClick={editImage}
                        tooltip={t('Edit image')}
                    />
                )}
                {irt.showImageTitles && <span className="image-title">{title}</span>}
            </div>
        )
    }

    return null
}

interface IImageViewerProps {
    irt: ImagesRoot
    image: ImageMetadata
    images: ImageMetadata[] // List of images from which image was chosen
    onClose: () => void // called when user is done viewing individual images
}

@observer
class ImageViewer extends Component<IImageViewerProps> {
    @observable src = ''

    @observable image: ImageMetadata

    index = 0

    constructor(props: IImageViewerProps) {
        super(props)

        const { image, images } = this.props

        this.image = image
        this.index = images.findIndex((img) => img.id === this.image.id)
    }

    imageTitle = () => {
        const { image } = this
        const { irt } = this.props

        const { title } = image.getDefinition(irt.rt.uiLanguage)

        return title
    }

    goToImage = (index: number) => {
        const { images } = this.props
        log('goToImage', index, images.length)

        this.src = ''
        this.index = index
        this.image = images[index]
    }

    onKeyDown = (e: React.KeyboardEvent) => {
        const { onClose: close } = this.props
        e.stopPropagation()
        if (e.key === 'Escape') {
            close()
        }
    }

    render() {
        const { irt, onClose, images } = this.props
        const { rt } = irt

        const { src, index, image } = this

        log('ImageViewer render', JSON.stringify(image, null, 4))

        const x = index + 1
        const y = images.length

        // example: (image number) 1 of 5
        const imagePositionMessage = t('{{x}} of {{y}}', { x, y })

        return (
            <div className="image-viewer" onKeyDown={this.onKeyDown}>
                <Optional show={!rt.editingImage}>
                    <div className="image-viewer-toolbar">
                        <div className="image-viewer-toolbar-start">
                            {src && (
                                <ExpandButton className="image-expand-button" tooltip={t('View full size')} src={src} />
                            )}
                        </div>
                        {images.length > 1 && (
                            <div className="image-viewer-toolbar-middle">
                                <PreviousSegmentButton
                                    enabled={index > 0}
                                    onClick={() => this.goToImage(index - 1)}
                                    tooltip={t('Go to previous image')}
                                />
                                <div className="image-pane-header-label">{imagePositionMessage}</div>
                                <NextSegmentButton
                                    enabled={index !== -1 && index < images.length - 1}
                                    onClick={() => this.goToImage(index + 1)}
                                    tooltip={t('Go to next image')}
                                />
                            </div>
                        )}
                        <div className="image-viewer-toolbar-end">
                            <PaneCloseButton
                                className="image-viewer-close-button"
                                tooltip={t('Close pane')}
                                enabled
                                onClick={onClose}
                            />
                        </div>
                    </div>
                    <div className="image-viewer-title">{this.imageTitle()}</div>
                </Optional>
                <FullSizeImageViewer irt={irt} image={image} setSrc={(source) => (this.src = source)} />
                {rt.editingImage && (
                    <ImageMetadataEditor
                        irt={irt}
                        showDeleteButton
                        image={image}
                        close={async (saved) => {
                            if (saved) {
                                await ProjectImages.putProjectImageMetadataToDynamoDB(image)
                                irt.adjustSearchRefs(image.references)
                                await irt.refresh()
                            }
                            irt.rt.editingImage = false
                            onClose()
                        }}
                    />
                )}
            </div>
        )
    }
}

// =======================================================================
// Display thumbnails for a list of images or the currently selected image.

interface IImagesList {
    irt: ImagesRoot
}

@observer
class ImagesList extends Component<IImagesList> {
    @observable currentImage: ImageMetadata | null = null

    render() {
        const { irt } = this.props
        const { currentImage } = this
        const { images } = irt
        const { loading } = irt.imageCollection

        // log('ImagesList render', fmt({loading, length: images.length, currentImage}))

        if (loading) {
            return <LoadingIcon className="" />
        }

        // Need to do this to force a re-render of when this changes
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { showImageTitles } = irt

        if (currentImage)
            return (
                <ImageViewer
                    irt={irt}
                    image={currentImage}
                    images={images}
                    onClose={() => (this.currentImage = null)}
                />
            )

        if (images.length === 0) return t('No results found')

        const projectImages = images.filter((img) => img.isProjectImage)
        const marbleImages = images.filter((img) => !img.isProjectImage)

        return (
            <div>
                <div className="image-area-images">
                    <div className="image-area-grid">
                        {projectImages.map((image) => (
                            <ImageThumbnail
                                key={image.id}
                                irt={irt}
                                image={image}
                                viewImage={() => {
                                    this.currentImage = image
                                }}
                                editImage={() => {
                                    this.currentImage = image
                                    irt.rt.editingImage = true
                                }}
                            />
                        ))}
                    </div>

                    {projectImages.length && marbleImages.length ? <hr /> : undefined}

                    <div className="image-area-grid">
                        {marbleImages.map((image) => (
                            <ImageThumbnail
                                key={image.id}
                                irt={irt}
                                image={image}
                                viewImage={() => {
                                    this.currentImage = image
                                }}
                                editImage={() => {
                                    this.currentImage = image
                                    irt.rt.editingImage = true
                                }}
                            />
                        ))}
                    </div>
                </div>
            </div>
        )
    }
}

/*
    This is the control that is seen after the user has selected a specific image.
    It allows viewing that image and stepping through the list of images.
    Allow user to step through list.
 */

interface IImages {
    rt: Root
}

@observer
export class Images extends Component<IImages> {
    irt: ImagesRoot

    constructor(props: IImages) {
        super(props)
        const { rt } = props

        const irt = new ImagesRoot(rt)
        this.irt = irt

        const _window = window as any
        _window.irt = irt // easy access to irt for debugging

        if (irt.rt.portion) {
            irt.setSearchRefs(irt.rt.defaultReferences(irt.rt.portion))
        }
    }

    render() {
        const { irt } = this

        return (
            <ImagesDropTarget irt={irt}>
                <div className="images-area">
                    {!irt.rt.editingImage && <ImageSearchToolbar irt={irt} />}
                    <ImagesList irt={irt} />
                </div>
            </ImagesDropTarget>
        )
    }
}

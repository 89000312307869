import * as DateUtilities from './DateUtilities'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:FileDateParser')

export class FileDateParser {
    existingDates: Date[] = []

    file: File

    constructor(existingVideos: any[], file: File) {
        this.existingDates = existingVideos.map((e) => new Date(e.creationDate))
        this.file = file
    }

    getCreationDate() {
        const creationDate = this.getCreationDateFromFile()
        return DateUtilities.adjustForDuplicates(this.existingDates, creationDate)
    }

    private getCreationDateFromFile() {
        const { file } = this
        const isoDateFormat = file.name.match(/\d\d\d\d[-]\d\d[-]\d\d/)
        if (isoDateFormat) {
            const year = parseInt(isoDateFormat[0].slice(0, 4).toString())
            let month = parseInt(isoDateFormat[0].slice(5, 7).toString())
            let day = parseInt(isoDateFormat[0].slice(8).toString())

            // User probably switched month and day. I think better to assume this than give them a complicated message.
            if (month > 12) {
                ;[day, month] = [month, day]
            }

            if (month >= 1 && month <= 12 && day >= 1 && day <= 31 && year >= 2000) {
                month -= 1 // months are 0-based
                const creationDate = new Date(year, month, day, 0, 0)
                return creationDate
            }
        }
        log('Filename did not contain a date that we could parse. Using file modification date instead')

        return new Date(file.lastModified)
    }
}

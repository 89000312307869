import React, { FC, useEffect, useRef, useState } from 'react'
import { useTranslation, TFunction } from 'react-i18next'
import { observer } from 'mobx-react'
import _ from 'underscore'
import Modal from 'react-bootstrap/lib/Modal'

import { AppRoot } from '../../models3/AppRoot'
import { _LevelupDB } from '../../models3/_LevelupDB'
import { Member } from '../../models3/Member'
import { Project } from '../../models3/Project'
import { ProjectMessage } from '../../models3/ProjectMessage'
import { Root } from '../../models3/Root'

import { isAVTT } from '../app/slttAvtt'
import { downloadUrl } from '../notes/NoteItem'
import { NoteVideoRecorder } from '../notes/NoteMain'

import { CancelButton, DeleteButton, DownloadButton, FAButton, OKButton } from '../utils/Buttons'
import DropTarget from '../utils/DropTarget'
import { DropTargetViewLarge } from '../utils/DropTargetView'
import { systemError, displayError } from '../utils/Errors'
import { s } from '../utils/Fmt'
import { VideoThumbnailPlayer } from '../utils/MediaThumbnail'
import { MemberDisplay } from '../utils/MemberDisplay'
import { EditableRichText } from '../utils/RichTextEditor'
import { RecordingDoneParams } from '../video/VideoUploader'

import './ProjectMessages.css'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:ProjectMessages')

// show message counts for other projects???

interface IMessageAddItemButtons {
    addText: () => void
    addVideo: () => void
    iAmInterpreter: boolean
    recordMessage: string
    writeMessage: string
    dropMessage: string
}

const MessageAddItemButtons: FC<IMessageAddItemButtons> = ({
    iAmInterpreter,
    addText,
    addVideo,
    recordMessage,
    writeMessage,
    dropMessage
}) => {
    if (!iAmInterpreter) {
        return null
    }

    return (
        <div className="note-add-buttons">
            <div className="p-n-record-button">
                <div
                    className=" note-record-button-video et-right fa fa-3x fa-circle text-danger"
                    data-id="create-video-note-item"
                    onClick={addVideo}
                />
                <div>{recordMessage}</div>
            </div>
            <div className="note-record-button">
                <div
                    className="p-n-record-button-write et-right fa fa-3x fa-edit"
                    data-id="create-text-note-item"
                    onClick={addText}
                />
                <div>{writeMessage}</div>
            </div>
            <div className="p-n-record-button p-n-drop-button">
                <div className="et-right fa fa-3x fa-cloud-upload-alt" data-id="create-drop-note-item" />
                <div>{dropMessage}</div>
            </div>
        </div>
    )
}

interface IProjectMessageSubject {
    subject: string
    setSubject: (subject: string) => void
}

const ProjectMessageSubject: FC<IProjectMessageSubject> = ({ subject, setSubject }) => {
    const { t } = useTranslation()

    return (
        <div className="p-n-subject">
            <input
                type="text"
                autoFocus
                className={subject.trim() === '' ? ' p-n-error' : ''}
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                placeholder={t('Subject...')}
            />
        </div>
    )
}

interface IProjectMessageText {
    text: string
    setText: (text: string) => void
    editing: boolean
    setEditing: (editing: boolean) => void
}

const ProjectMessageText: FC<IProjectMessageText> = ({ text, setText, editing, setEditing }) => {
    return (
        <div className="p-n-text">
            <EditableRichText
                savedText={text}
                save={(_text?: string) => {
                    setText(_text ?? '')
                    setEditing(false)
                }}
                cancel={() => {
                    setEditing(false)
                }}
                editorOpen={editing}
                setEditorOpen={setEditing}
                prefix="message-document"
            />
        </div>
    )
}

async function uploadDroppedVideo(
    files: FileList,
    baseUrl: string,
    creationDate: string,
    t: TFunction
): Promise<string> {
    const file = files[0]
    const video = document.createElement('video')
    if (!video.canPlayType(file.type)) {
        displayError(t('Cannot upload this type of file.'))
        return ''
    }

    return Project.copyFileToVideoCache(file, baseUrl, creationDate)
}

interface ICreateProjectMessage {
    rt: Root
    creatingMessage: boolean
    setCreatingMessage: (creating: boolean) => void
}

const CreateProjectMessage: FC<ICreateProjectMessage> = ({ rt, setCreatingMessage }) => {
    const { t } = useTranslation()

    const [subject, setSubject] = useState('') // subject line
    const [editing, setEditing] = useState(false) // written text editing in process
    const [text, setText] = useState('')
    const [recording, setRecording] = useState(false) // video recording in process
    const [videoUrl, setVideoUrl] = useState('')

    const messageRef = useRef(rt.project.createProjectMessage())

    const { iAmInterpreter } = rt

    function addText() {
        setEditing(true)
    }

    const baseUrl = () => `${rt.project.name}/${messageRef.current._id}`

    function startRecording() {
        // If we have already recorded a video we need to create a new message _id in order
        // to ensure that the uploaded video files to not conflict with the previously
        // recorded video files (which we are abandoning)
        if (videoUrl) {
            const message = messageRef.current
            const parts = message._id.split('_')
            const _id = `${parts[0]}_${(parseInt(parts[1]) + 1).toString().padStart(6, ' ')}`
            message._id = _id
        }

        setRecording(true)
    }

    async function finishRecording({ err, blobsCount }: Partial<RecordingDoneParams>) {
        if (err) {
            setRecording(false)
            displayError(err)
            return
        }

        setVideoUrl(`${baseUrl()}-${blobsCount}`)
        setRecording(false)
    }

    async function createMessage() {
        const _notif = messageRef.current
        _notif.subject = subject
        _notif.text = text
        _notif.videoUrl = videoUrl

        log('createMessage', s(_notif.dbg()))
        await rt.project.addProjectMessage(_notif)

        setCreatingMessage(false)
    }

    const projectMessageDropMessage = <div>{t('projectMessageDropMessage')}</div>
    const dropTargetView = <DropTargetViewLarge message={projectMessageDropMessage} />

    const { creator, creationDate } = messageRef.current

    const recordMessage = t('Record message.')
    const writeMessage = t('Write message.')
    const dropMessage = t('Drop message.')

    return (
        <Modal backdrop="static" keyboard dialogClassName="" show onHide={() => {}} bsSize="large">
            <Modal.Header>
                <Modal.Title>{t('Create Message')}</Modal.Title>
            </Modal.Header>
            <DropTarget
                upload={async (files) => {
                    uploadDroppedVideo(files, baseUrl(), creationDate, t)
                        .then((_url) => setVideoUrl(_url))
                        .catch(systemError)
                }}
                dropTargetView={dropTargetView}
            >
                <Modal.Body>
                    <ProjectMessageSubject {...{ subject, setSubject }} />
                    <hr />
                    {recording && (
                        <div className="p-n-video">
                            <NoteVideoRecorder
                                cancel={() => setRecording(false)}
                                url={baseUrl()}
                                save={finishRecording}
                            />
                        </div>
                    )}
                    {videoUrl && !recording && <VideoThumbnailPlayer {...{ videoUrl, creator }} />}
                    {(text || editing) && !recording && (
                        <ProjectMessageText {...{ text, setText, editing, setEditing }} />
                    )}
                    {!recording && !editing && (
                        <MessageAddItemButtons
                            {...{
                                iAmInterpreter,
                                addText,
                                addVideo: startRecording,
                                recordMessage,
                                writeMessage,
                                dropMessage
                            }}
                        />
                    )}
                </Modal.Body>
            </DropTarget>
            <Modal.Footer>
                <div className="p-n-dialog-footer">
                    <OKButton
                        enabled={subject.trim() !== '' && !recording && !editing}
                        buttonClassName=""
                        className="ok-button"
                        tooltip={t('Create message.')}
                        onClick={createMessage}
                    />
                    <CancelButton
                        enabled={!recording && !editing}
                        className="ok-button pull-right"
                        tooltip={t('Cancel. Do not create message.')}
                        onClick={() => setCreatingMessage(false)}
                    />
                </div>
            </Modal.Footer>
        </Modal>
    )
}

function getMember(rt: Root, creator: string) {
    let member = rt.project.members.find((mem) => mem.email === creator)
    if (!member) {
        member = new Member(creator) //  Create fake member
    }

    return member
}

interface IProjectMessageDetails {
    rt: Root
    notif: ProjectMessage
    removable: boolean
    firstUnviewed: boolean // true if this is the first unread message
}

const ProjectMessageDetails: FC<IProjectMessageDetails> = ({ rt, notif, removable, firstUnviewed }) => {
    const { t } = useTranslation()
    const { dateFormatter, username } = rt
    const { videoUrl, text, creator, creationDate, subject } = notif
    let { viewed } = notif

    // you have always 'viewed' your own videos
    if (creator === rt.username) viewed = true

    const allowDelete = removable && username === creator

    const member = getMember(rt, creator)
    const _creationDate = notif.displayedCreationDate(dateFormatter)

    let downloadFilename = `${creationDate.slice(0, 16)} ${rt.project.name} ${subject}`
    downloadFilename = `${downloadFilename.replace(/[^a-z0-9]/gi, '_')}.webm`

    // When there are a lot of items it is useful to scroll the first
    // unread item into view
    const itemRef = useRef<HTMLDivElement | null>(null)
    useEffect(() => {
        if (!itemRef.current) {
            log('### itemRef null')
            return
        }
        if (firstUnviewed) {
            itemRef.current.scrollIntoView()
        }
    }, [firstUnviewed])

    const className = viewed ? '' : 'p-n-unviewed-font'

    return (
        <div className="p-n-item" ref={itemRef}>
            <div className="p-n-item-header">
                <div className="p-n-item-creation-date">{_creationDate}</div>
                <i {...{ className }}>
                    <MemberDisplay member={member} imageSize="large" />
                </i>
            </div>
            <div>
                {videoUrl && <VideoThumbnailPlayer {...{ videoUrl, creator }} />}
                {text && (
                    <ProjectMessageText
                        {...{
                            text,
                            setText: () => {},
                            editing: false,
                            setEditing: () => {}
                        }}
                    />
                )}
            </div>
            <div>
                <span className="p-n-response-item-buttons">
                    {notif.videoUrl && (
                        <DownloadButton
                            className="p-n-response-download-button"
                            tooltip={t('Export')}
                            onClick={() => downloadUrl(notif.videoUrl, downloadFilename)}
                            enabled
                        />
                    )}
                    {allowDelete && (
                        <DeleteButton
                            className="p-n-response-delete-button"
                            tooltip={t('Delete response.')}
                            enabled
                            onClick={() => {
                                rt.project.removeProjectMessage(notif).catch(displayError)
                            }}
                        />
                    )}
                </span>
            </div>
        </div>
    )
}

interface IProjectMessageUsername {
    rt: Root
    notif: ProjectMessage
    last: boolean
}

const ProjectMessageUsername: FC<IProjectMessageUsername> = ({ rt, notif, last }) => {
    const { t } = useTranslation()
    let { creator, viewed } = notif

    if (creator === rt.username) {
        creator = t('me')
        viewed = true
    } else {
        creator = creator.split('@')[0]
    }

    const className = viewed ? 'p-n-viewed-font' : 'p-n-unviewed-font'

    return (
        <>
            <span {...{ className }}>{creator}</span>
            {last ? '' : ', '}
        </>
    )
}

interface IProjectMessageUsernames {
    rt: Root
    notif: ProjectMessage
}

const ProjectMessageUsernames: FC<IProjectMessageUsernames> = observer(({ rt, notif }) => {
    const { responses } = notif

    // need to access these to force view to update when they change
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const vieweds = responses.map((r) => r.viewed)

    const shownResponses = responses.slice(-2)

    return (
        <div>
            <ProjectMessageUsername {...{ rt, notif, last: responses.length === 0, key: notif._id }} />
            {responses.length > 2 && '..., '}
            {shownResponses.map((n, i) => (
                <ProjectMessageUsername key={n._id} {...{ rt, notif: n, last: i === shownResponses.length - 1 }} />
            ))}
            {responses.length > 0 ? ` ${responses.length + 1}` : ''}
        </div>
    )
})

interface IProjectMessageSummary {
    rt: Root
    notif: ProjectMessage
    showMessage(_notif: ProjectMessage): void
    appRoot: AppRoot
}

const ProjectMessageSummary: FC<IProjectMessageSummary> = observer(({ rt, notif, showMessage, appRoot }) => {
    const { t } = useTranslation()
    const { username, dateFormatter, iAmRoot } = rt
    const { creator } = notif
    let { subject } = notif

    const allowDelete = username === creator

    const allowPublish = !notif.globalMessage && iAmRoot && ProjectMessage.isGlobalProject(rt.name)

    if (notif.globalMessage) {
        subject = (isAVTT ? '(AVTT) ' : '(SLTT) ') + subject
    }

    async function deleteProjectMessageAndChildren() {
        const { project } = rt
        for (const _notif of notif.responses) {
            await project.removeProjectMessage(_notif)
        }

        await project.removeProjectMessage(notif)
    }

    async function publishProjectMessage() {
        const testPublishMessage = localStorage.getItem('testPublishMessage')

        await notif.setGlobalMessage(true)
        for (const _rt of appRoot.rts) {
            if (_rt.name === rt.name) continue
            if (testPublishMessage && _rt.name !== 'TESTnm') continue

            await _rt.project.addProjectMessage(notif)
        }
    }

    return (
        <>
            <td className="p-n-cell" onClick={() => showMessage(notif)}>
                <ProjectMessageUsernames {...{ rt, notif }} />
            </td>
            <td className="p-n-cell" onClick={() => showMessage(notif)}>
                {subject}
            </td>
            <td className="p-n-cell" onClick={() => showMessage(notif)}>
                {notif.displayedCreationDate(dateFormatter)}
            </td>
            <td className="p-n-cell">
                <span className="p-n-item-buttons">
                    {allowPublish && (
                        <span
                            className="p-n-publish-button"
                            onClick={() => publishProjectMessage().catch(displayError)}
                        >
                            <i className="fas fa-fw fa-rss" />
                        </span>
                    )}
                    {allowDelete && (
                        <DeleteButton
                            className="p-n-delete-button"
                            tooltip={t('Delete response.')}
                            enabled
                            onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                                e.preventDefault()
                                deleteProjectMessageAndChildren().catch(displayError)
                            }}
                        />
                    )}
                </span>
            </td>
        </>
    )
})

async function createResponse(
    rt: Root,
    notif: ProjectMessage,
    response: ProjectMessage,
    text: string | undefined,
    videoUrl: string | undefined
) {
    response.subject = ''
    response.text = text || ''
    response.videoUrl = videoUrl || ''
    response.parent = notif._id

    log('createResponse', s(response.dbg()))
    await rt.project.addProjectMessage(response)
}

interface ICreateProjectMessageTextResponse {
    rt: Root
    notif: ProjectMessage
    setEditing: (editing: boolean) => void
}

const CreateProjectMessageTextResponse: FC<ICreateProjectMessageTextResponse> = ({ rt, notif, setEditing }) => {
    const messageRef = useRef(rt.project.createProjectMessage())

    function createTextResponse(_text?: string) {
        setEditing(false)
        if (!_text) return

        createResponse(rt, notif, messageRef.current, _text, undefined).catch(displayError)
    }

    return (
        <div className="p-n-text">
            <EditableRichText
                savedText=""
                save={createTextResponse}
                cancel={() => {
                    setEditing(false)
                }}
                editorOpen
                setEditorOpen={setEditing}
                prefix="message-document"
            />
        </div>
    )
}

interface ICreateProjectMessageVideoResponse {
    rt: Root
    notif: ProjectMessage
    setRecording: (editing: boolean) => void
}

const CreateProjectMessageVideoResponse: FC<ICreateProjectMessageVideoResponse> = ({ rt, notif, setRecording }) => {
    const messageRef = useRef(rt.project.createProjectMessage())

    const baseUrl = () => `${rt.project.name}/${messageRef.current._id}`

    async function finishRecording({ err, blobsCount }: Partial<RecordingDoneParams>) {
        if (err) {
            setRecording(false)
            displayError(err)
            return
        }
        if (blobsCount === undefined) return

        const videoUrl = `${baseUrl()}-${blobsCount}`
        createResponse(rt, notif, messageRef.current, undefined, videoUrl).catch(displayError)

        setRecording(false)
    }

    return (
        <div className="p-n-video">
            <NoteVideoRecorder cancel={() => setRecording(false)} url={baseUrl()} save={finishRecording} />
        </div>
    )
}

interface IMessageSelectionArrows {
    notif: ProjectMessage
    setShowingMessage: (showing: boolean) => void
    previousMessage?: () => void
    nextMessage?: () => void
}

const MessageSelectionArrows: FC<IMessageSelectionArrows> = ({
    notif,
    setShowingMessage,
    previousMessage,
    nextMessage
}) => {
    const { t } = useTranslation()

    return (
        <div className="p-n-message-selection-arrows">
            <div>
                <FAButton
                    className="fa-arrow-left p-n-arrow"
                    tooltip={t('View all messages')}
                    enabled
                    onClick={async () => {
                        await notif.setViewed(true)
                        setShowingMessage(false)
                    }}
                />
            </div>
            <div className="text-right">
                <FAButton
                    className="fa-arrow-down p-n-small-arrow"
                    tooltip={t('Go to next message')}
                    enabled={!!nextMessage}
                    onClick={() => nextMessage && nextMessage()}
                />
                <FAButton
                    className="fa-arrow-up p-n-small-arrow"
                    tooltip={t('Go to previous message')}
                    enabled={!!previousMessage}
                    onClick={() => previousMessage && previousMessage()}
                />
            </div>
        </div>
    )
}

interface IShowMessage {
    rt: Root
    selectedMessage: ProjectMessage
    setShowingMessage: (showing: boolean) => void
    previousMessage?: () => void
    nextMessage?: () => void
}

const ShowProjectMessage2: FC<IShowMessage> = observer(
    ({ rt, selectedMessage, setShowingMessage, previousMessage, nextMessage }) => {
        const { t } = useTranslation()
        const { iAmInterpreter } = rt
        const notif = selectedMessage

        const [editing, setEditing] = useState(false) // written text editing in process
        const [recording, setRecording] = useState(false) // video recording in process

        const recordMessage = t('Record response.')
        const writeMessage = t('Write response.')
        const dropMessage = t('Drop response.')

        const firstUnviewed = notif.responses.find((r) => !r.viewed)

        return (
            <div>
                <h2 className="p-n-subject">{selectedMessage.subject}</h2>
                {!editing && !recording && (
                    <>
                        <MessageSelectionArrows {...{ notif, setShowingMessage, previousMessage, nextMessage }} />
                        <hr />
                        <ProjectMessageDetails
                            {...{ rt, notif: selectedMessage, removable: false, firstUnviewed: false }}
                        />
                        <hr />
                        {notif.responses.map((response) => (
                            <ProjectMessageDetails
                                key={response._id}
                                {...{
                                    rt,
                                    notif: response,
                                    removable: true,
                                    firstUnviewed: response === firstUnviewed
                                }}
                            />
                        ))}
                        <hr />
                        <MessageAddItemButtons
                            {...{ iAmInterpreter, recordMessage, writeMessage, dropMessage }}
                            addText={() => setEditing(true)}
                            addVideo={() => setRecording(true)}
                        />
                        <hr />
                        <MessageSelectionArrows {...{ notif, setShowingMessage, previousMessage, nextMessage }} />
                    </>
                )}
                {editing && <CreateProjectMessageTextResponse {...{ rt, notif: selectedMessage, setEditing }} />}
                {recording && <CreateProjectMessageVideoResponse {...{ rt, notif: selectedMessage, setRecording }} />}
            </div>
        )
    }
)

const ShowProjectMessage: FC<IShowMessage> = ({
    rt,
    selectedMessage,
    setShowingMessage,
    previousMessage,
    nextMessage
}) => {
    const { t } = useTranslation()
    const { iAmInterpreter } = rt
    const projectMessageDropResponse = <div>{t('projectMessageDropResponse')}</div>
    const dropTargetView = <DropTargetViewLarge message={projectMessageDropResponse} />

    async function upload(files: FileList) {
        if (!iAmInterpreter) {
            displayError(t('You must be at least an interpreter.'))
            return
        }

        const response = rt.project.createProjectMessage()
        const baseUrl = `${rt.project.name}/${response._id}`

        response.videoUrl = await uploadDroppedVideo(files, baseUrl, response.creationDate, t)
        response.parent = selectedMessage._id

        log('create dropped response', s(response.dbg()))
        await rt.project.addProjectMessage(response)
    }

    return (
        <DropTarget
            upload={async (files) => {
                upload(files).catch(displayError)
            }}
            dropTargetView={dropTargetView}
        >
            <ShowProjectMessage2
                {...{
                    rt,
                    selectedMessage,
                    setShowingMessage,
                    previousMessage,
                    nextMessage
                }}
            />
        </DropTarget>
    )
}

interface ICreateProjectMessageButton {
    setCreatingMessage: (creating: boolean) => void
}

const CreateProjectMessageButton: FC<ICreateProjectMessageButton> = ({ setCreatingMessage }) => {
    const { t } = useTranslation()

    return (
        <a className="p-n-create-button">
            <i
                className="fa-comment-dots fas fa-2x edit-project-settings-button"
                onClick={() => setCreatingMessage(true)}
                data-toggle="tooltip"
                title={t('projectMessageCreateMessage')}
            />
            <span>+</span>
        </a>
    )
}

interface IMessageCount {
    projectName: string
    count: number
}

async function getProjectMessageCount(projectName: string): Promise<IMessageCount> {
    log('getProjectMessageCount', projectName)

    const ids: Set<string> = new Set()

    const records = await _LevelupDB.readDBRecords(projectName)
    for (const record of records) {
        const { _id, viewed, removed } = record.doc
        if (!_id.startsWith('notify/')) continue
        if (viewed) continue
        if (removed) {
            ids.delete(_id)
            continue
        }
        ids.add(_id)
    }

    log('getProjectMessageCount DONE', ids.size)
    return { projectName, count: ids.size }
}

async function getOtherProjectsMessageCounts(
    projectName: string,
    appRoot: AppRoot,
    setMessageCounts: (messageCounts: IMessageCount[]) => void
): Promise<void> {
    const _messageCounts: IMessageCount[] = []

    for (const _rt of appRoot.rts) {
        const { name } = _rt.project
        if (name === projectName) continue

        const messageCount = await getProjectMessageCount(name)
        if (messageCount.count) {
            _messageCounts.push(messageCount)
        }
    }

    setMessageCounts(_messageCounts)
}

interface IOtherProjectMessageCounts {
    appRoot: AppRoot
    messageCounts: IMessageCount[]
}

const OtherProjectMessageCounts: FC<IOtherProjectMessageCounts> = ({ appRoot, messageCounts }) => {
    if (messageCounts.length === 0) return null

    return (
        <div className="p-n-message-counts">
            {messageCounts.map((mc, i) => (
                <a key={mc.projectName} onClick={() => appRoot.setCurrentProject(mc.projectName)}>
                    {`${mc.projectName}* (${mc.count})${i < messageCounts.length - 1 ? ', ' : ''}`}
                </a>
            ))}
        </div>
    )
}

interface IProjectMessages {
    rt: Root
    appRoot: AppRoot
}

const ProjectMessages: FC<IProjectMessages> = observer(({ rt, appRoot }) => {
    const { t } = useTranslation()
    const { project, iAmInterpreter, iAmRoot } = rt
    const messages = _.sortBy(project.messages, (n) => n.creationDate).reverse()

    const [creatingMessage, setCreatingMessage] = useState(false)
    const [showingMessage, setShowingMessage] = useState(false)
    const [selectedMessage, setSelectedMessage] = useState<ProjectMessage>()
    const [messageCounts, setMessageCounts] = useState<IMessageCount[]>([])

    useEffect(() => {
        // Root has access to all projects. We don't try to search every project on the system.
        if (!iAmRoot) {
            getOtherProjectsMessageCounts(project.name, appRoot, setMessageCounts).catch(displayError)
        }
    }, [appRoot, project.name, iAmRoot])

    function showMessage(_notif: ProjectMessage) {
        setSelectedMessage(_notif)
        setShowingMessage(true)
    }

    if (showingMessage && selectedMessage) {
        const i = messages.findIndex((message) => message === selectedMessage)
        return (
            <ShowProjectMessage
                {...{
                    rt,
                    selectedMessage,
                    setShowingMessage,
                    nextMessage:
                        i >= 0 && i < messages.length - 1 ? () => setSelectedMessage(messages[i + 1]) : undefined,
                    previousMessage: i >= 1 ? () => setSelectedMessage(messages[i - 1]) : undefined
                }}
            />
        )
    }

    return (
        <div>
            {creatingMessage && <CreateProjectMessage {...{ rt, creatingMessage, setCreatingMessage }} />}
            <div className="p-n-header">
                <div className="p-n-header-item1">
                    {iAmInterpreter && <CreateProjectMessageButton {...{ setCreatingMessage }} />}
                </div>
                <div className="p-n-header-item2">{t('Project Messages')}</div>
            </div>
            <div className="p-n-table">
                <table className="table .table-bordered p-n-viewed">
                    <tbody>
                        {messages.map((notif) => (
                            <tr
                                className={`p-n ${
                                    notif.anyUnviewed(rt.username) ? 'p-n-unviewed-color' : 'p-n-viewed-color'
                                }`}
                                key={notif._id}
                            >
                                <ProjectMessageSummary {...{ rt, notif, showMessage, appRoot }} />
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <OtherProjectMessageCounts {...{ appRoot, messageCounts }} />
        </div>
    )
})

export default ProjectMessages

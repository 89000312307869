import React from 'react'

interface IconProps {
    className: string
    tooltip: string
}

export const ScrollIcon = ({ className, tooltip }: IconProps) => {
    return (
        <span className={className} data-toggle="tooltip" title={tooltip}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 518.727 518.728">
                <g>
                    <path
                        d="M454.222,56.416c-1.849,0-3.675,0.123-5.481,0.278c-0.45-0.124-0.892-0.278-1.375-0.278H182.764
			c-35.57,0-64.505,28.935-64.505,64.505v271.51H5.375c-2.973,0-5.375,2.399-5.375,5.376c0,35.562,28.935,64.505,64.505,64.505
			c1.323,0,2.493-0.537,3.431-1.324c0.935,0.787,2.105,1.324,3.428,1.324h264.6c35.57,0,64.505-28.942,64.505-64.505
			c0-0.483-0.158-0.925-0.272-1.366c0.114-0.451,0.272-0.882,0.272-1.37v-17.47V126.301h112.883c2.971,0,5.376-2.404,5.376-5.375
			C518.727,85.351,489.792,56.416,454.222,56.416z M335.963,451.561c-29.639,0-53.754-24.105-53.754-53.754
			c0-2.977-2.404-5.376-5.375-5.376H129.01v-271.51c0-29.642,24.114-53.754,53.754-53.754h235.877
			c-17.407,11.562-28.924,31.328-28.924,53.754v256.674v17.47c0,0.483,0.156,0.925,0.272,1.366
			c-0.116,0.451-0.272,0.883-0.272,1.363C389.717,427.443,365.602,451.561,335.963,451.561z"
                    />
                    <path
                        d="M342.682,345.344H192.171c-2.974,0-5.375,2.402-5.375,5.376c0,2.971,2.402,5.373,5.375,5.373h150.511
			c2.971,0,5.375-2.402,5.375-5.373C348.057,347.746,345.653,345.344,342.682,345.344z"
                    />
                    <path
                        d="M342.682,291.59H192.171c-2.974,0-5.375,2.403-5.375,5.376c0,2.971,2.402,5.373,5.375,5.373h150.511
			c2.971,0,5.375-2.402,5.375-5.373C348.057,293.993,345.653,291.59,342.682,291.59z"
                    />
                    <path
                        d="M342.682,237.835H192.171c-2.974,0-5.375,2.402-5.375,5.375c0,2.971,2.402,5.375,5.375,5.375h150.511
			c2.971,0,5.375-2.404,5.375-5.375C348.057,240.237,345.653,237.835,342.682,237.835z"
                    />
                    <path
                        d="M342.682,184.082H192.171c-2.974,0-5.375,2.401-5.375,5.375c0,2.972,2.402,5.376,5.375,5.376h150.511
			c2.971,0,5.375-2.404,5.375-5.376C348.057,186.482,345.653,184.082,342.682,184.082z"
                    />
                    <path
                        d="M342.682,130.327H192.171c-2.974,0-5.375,2.402-5.375,5.375c0,2.971,2.402,5.375,5.375,5.375h150.511
			c2.971,0,5.375-2.405,5.375-5.375C348.057,132.729,345.653,130.327,342.682,130.327z"
                    />
                </g>
            </svg>
        </span>
    )
}

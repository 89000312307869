import { observable } from 'mobx'
import _ from 'underscore'

import { normalizeUsername } from './Utils'

interface IMemberDoc {
    email: string
    role: string
}

// Make sure that changes in case or leading/trailing space does not cause email
// match to fail.
export function findMemberIndex(members: any[], email: string) {
    email = normalizeUsername(email) // should not be necessary
    return _.findIndex(members, (m: IMemberDoc) => m.email === email)
}

export function findMember(members: Member[], email: string) {
    const index = findMemberIndex(members, email)
    return index >= 0 ? members[index] : undefined
}

function checkRole({ role, requiredRole }: { role: MemberRole; requiredRole: MemberRole }) {
    let result = false
    switch (requiredRole) {
        case 'observer':
            result = ['observer', 'interpreter', 'consultant', 'translator', 'admin'].includes(role)
            break
        case 'interpreter':
            result = ['interpreter', 'consultant', 'translator', 'admin'].includes(role)
            break
        case 'consultant':
            result = ['consultant', 'translator', 'admin'].includes(role)
            break
        case 'translator':
            result = ['translator', 'admin'].includes(role)
            break
        case 'admin':
            result = ['admin'].includes(role)
            break
        default:
    }

    return result
}

export function memberExistsWithRole({
    members,
    email,
    requiredRole
}: {
    members: Member[]
    email: string
    requiredRole: MemberRole
}) {
    const index = findMemberIndex(members, email)
    return index >= 0 ? checkRole({ role: members[index].role, requiredRole }) : false
}

export type MemberRole = 'admin' | 'translator' | 'consultant' | 'interpreter' | 'observer' | 'translator'

/** WARNING
 * There is a bit of a mess with the email field in the Member class.
 * We should have forced this to be lowercase and never allowed anything else, but originally we didn't.
 * This means that there is a variety of persisted data that may have this value (and
 * the associated creator, username, and viewedBy fields) in some random upper/lower case.
 *
 * To repair this when we set or accept one of these values
 * we run it through normalizeUsername. This allows us to safely compare these values
 * anywhere without worrying about case.
 * This confines the problem to: AppRoot.ts, ProjectModel.ts, DBAcceptor.ts, Root.ts.
 */
export class Member {
    email = ''

    @observable public role: MemberRole

    @observable imageUrl = ''

    constructor(email?: string, role: MemberRole = 'translator', imageUrl?: string) {
        this.email = normalizeUsername(email ?? '')
        this.role = role
        this.imageUrl = imageUrl || ''
    }

    toSnapshot() {
        const { email, role } = this
        return { email, role }
    }
}

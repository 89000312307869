import { PassageResource } from './Passage'
import { Project } from './Project'
import { RefRange } from '../scrRefs/RefRange'

function createRefString(refs: RefRange[]) {
    return refs
        .map((ref) => `${ref.startRef}-${ref.endRef}`)
        .sort()
        .join(',')
}
export class ResourceCollection {
    private map: Map<string, PassageResource[]> = new Map()

    initialize = (project: Project) => {
        const newMap: Map<string, PassageResource[]> = new Map()
        for (const portion of project.portions) {
            for (const passage of portion.passages) {
                if (
                    passage.references.length &&
                    (passage.documents.length || passage.oralResources.length || passage.pdfs.length)
                ) {
                    const refString = createRefString(passage.references)
                    const existing = newMap.get(refString)
                    if (existing) {
                        newMap.set(refString, [
                            ...existing,
                            ...passage.documents,
                            ...passage.oralResources,
                            ...passage.pdfs
                        ])
                    } else {
                        newMap.set(refString, [...passage.documents, ...passage.oralResources, ...passage.pdfs])
                    }
                }
            }
        }
        this.map = newMap
    }

    get = (refs: RefRange[]) => {
        return this.map.get(createRefString(refs)) ?? []
    }
}

// Allow user to select which portion in the current project they wish to work with
import React, { FC } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { PassageVideo } from '../../models3/PassageVideo'
import { ProjectPlan } from '../../models3/ProjectPlan'

interface IPassageStatus {
    passageVideo: PassageVideo
    plan: ProjectPlan
}

const PassageStatus: FC<IPassageStatus> = observer(({ passageVideo, plan }) => {
    const { t } = useTranslation()

    const { status } = passageVideo
    const task = plan.tasks.find((planTask) => planTask.id === status)

    let visibleStatus = ''

    if (!task) {
        return null
    }

    if (task.name === 'Finished') {
        visibleStatus = `(${t('Finished')})`
    } else if (task.name !== 'Not started') {
        visibleStatus = `(${task.stagePosition}.${task.taskPosition})`
    }

    return <span>{visibleStatus}</span>
})

export default PassageStatus

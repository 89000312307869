/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { DropdownButton, MenuItem } from 'react-bootstrap'
import { Root } from '../../models3/Root'
import GlossBar from '../glosses/GlossBar'
import NoteBar from '../notes/NoteBar'
import ThinVideoPositionBar from './ThinVideoPositionBar'
import VideoPositionBar from './VideoPositionBar'
import { VideoPositionBarControls } from './VideoPositionBarControls'
import './Video.css'
import { DoublePlayButton, PauseButton, PlayButton, StopButton } from '../utils/Buttons'
import PassageVideoSelector from '../passages/PassageVideoSelector'

import DraftVideoPlayer from './DraftVideoPlayer'
import { ViewableVideoCollection } from './ViewableVideoCollection'
import NoteDialog, { INoteRoot } from '../notes/NoteDialog'
import { VerseReferenceEditor } from './VerseReferenceEditor'
import { VideoMessage } from './VideoMessage'

import { IDateFormatter } from '../../models3/DateUtilities'
import { DraftVideoRoot } from '../../models3/DraftVideoRoot'
import { Passage } from '../../models3/Passage'
import { PassageVideo } from '../../models3/PassageVideo'
import { Portion } from '../../models3/Portion'
import { ProjectPlan } from '../../models3/ProjectPlan'

import { Timeline } from '../../models3/RootBase'
import ERTermModal from '../enhancedResources/ERTermModal'
import { displayError } from '../utils/Errors'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:CompareDraftVideoMain')

const htmlElementVisible = (el: HTMLElement) => el.offsetWidth > 0 && el.offsetHeight > 0

type CompareDraftToolbarProps = {
    compareDraftToolbar: ICompareDraftToolbar
    playBothVideos: () => void
    pauseBothVideos: () => void
    stopBothVideosAndReset: () => void
}

interface ICompareDraftToolbar {
    plans: ProjectPlan[]
    portion: Portion | null
    passage: Passage | null
    passageVideo: PassageVideo | null
    iAmAdmin: boolean
    hardNotificationCutoff: () => Date
    canViewConsultantOnlyFeatures: boolean
    dateFormatter: IDateFormatter
    playing: boolean
    setPassage: (passage: Passage) => Promise<void>
    setPassageVideo: (video: PassageVideo) => Promise<void>
    canPlayThrough: boolean
    bothCanPlayThrough: boolean
    currentVideos: ViewableVideoCollection
    bothPlaying: boolean
    play: () => void
    pause: () => void
    timeline: Timeline
}

type VideoPlaybackControlsProps = {
    pauseBothVideos: () => void
    playBothVideos: () => void
    stopBothVideosAndReset: () => void
    compareDraftToolbar: ICompareDraftToolbar
}

const VideoPlaybackControls: FC<VideoPlaybackControlsProps> = observer(
    ({ compareDraftToolbar, pauseBothVideos, playBothVideos, stopBothVideosAndReset }) => {
        const { t } = useTranslation()
        const { play, pause, playing, bothPlaying, canPlayThrough, bothCanPlayThrough, passage } = compareDraftToolbar
        const playShown = !playing || bothPlaying
        const enabled = !passage?.videoBeingCompressed
        const playEnabled = canPlayThrough && !playing && enabled
        const doublePlayShown = !bothPlaying
        const doublePlayEnabled = bothCanPlayThrough && !playing && enabled
        const pauseShown = playing
        const stopShown = bothPlaying
        const selectionPresent = compareDraftToolbar.timeline.selectionPresent()

        return (
            <div className="video-toolbar-left">
                {playShown && (
                    <PlayButton
                        enabled={playEnabled}
                        selectionPresent={selectionPresent}
                        tooltip={t('Play.')}
                        className="main-video-play-button"
                        onClick={() => play()}
                    />
                )}
                {pauseShown && (
                    <PauseButton
                        enabled
                        className="main-video-pause-button"
                        onClick={bothPlaying ? pauseBothVideos : () => pause()}
                        tooltip={bothPlaying ? t('recordingPauseBoth') : t('Pause.')}
                    />
                )}
                {doublePlayShown && (
                    <DoublePlayButton
                        enabled={doublePlayEnabled}
                        selectionPresent={doublePlayEnabled && selectionPresent}
                        className="main-video-play-button"
                        tooltip={t('recordingPlayBoth')}
                        onClick={playBothVideos}
                    />
                )}
                {stopShown && (
                    <StopButton
                        enabled
                        className="main-video-stop-button"
                        tooltip={t('recordingStopBoth')}
                        onClick={stopBothVideosAndReset}
                    />
                )}
            </div>
        )
    }
)

type PassageSelectorProps = {
    passages: Passage[]
    passage: Passage
    onSelect: (passage: Passage) => void
}

export const PassageSelector: FC<PassageSelectorProps> = ({ passages, passage, onSelect }) => (
    <DropdownButton title={passage.name} id="passage-dropdown" className="sl-dropdown passage-dropdown">
        {passages.map((p) => (
            <MenuItem key={p._id} onSelect={() => onSelect(p)}>
                {p.name}
            </MenuItem>
        ))}
    </DropdownButton>
)

type VideoSelectorProps = {
    compareDraftToolbar: ICompareDraftToolbar
}

const VideoSelector: FC<VideoSelectorProps> = observer(({ compareDraftToolbar }) => {
    const {
        passage,
        iAmAdmin,
        setPassageVideo,
        passageVideo,
        portion,
        setPassage,
        hardNotificationCutoff,
        canViewConsultantOnlyFeatures,
        dateFormatter,
        plans
    } = compareDraftToolbar

    async function makeSelection(video: PassageVideo) {
        if (!passage) {
            return
        }

        try {
            await setPassageVideo(video)
        } catch (error) {
            displayError(error)
        }
    }

    async function undeleteVideo(video: PassageVideo) {
        if (!passage || !iAmAdmin) {
            return
        }

        try {
            await passage.undeleteVideo(video)
            await makeSelection(video)
        } catch (error) {
            displayError(error)
        }
    }

    async function selectVideo(video: PassageVideo) {
        if (video.removed) {
            return undeleteVideo(video)
        }
        return makeSelection(video)
    }

    const videos = passage ? (iAmAdmin ? passage.videos : passage.videosNotDeleted).filter((v) => !v.isPatch) : []
    const currentVisibleVideo = passageVideo || (videos.length > 0 && videos[videos.length - 1])
    const passages = portion?.passages || []
    const onSelectPassage = (selectedPassage: Passage) => setPassage(selectedPassage)
    const passageVideoNotification = {
        passage,
        hardNotificationCutoff,
        canViewConsultantOnlyFeatures,
        dateFormatter
    }

    return (
        <div className="video-toolbar-right">
            {passage && <PassageSelector {...{ passages, passage, onSelect: onSelectPassage }} />}
            {currentVisibleVideo && plans.length > 0 && (
                <PassageVideoSelector
                    enabled
                    videos={videos}
                    currentVisibleVideo={currentVisibleVideo}
                    onSelect={selectVideo}
                    dateFormatter={dateFormatter}
                    passageVideoNotification={passageVideoNotification}
                    plan={plans[0]}
                />
            )}
        </div>
    )
})

type VideoFooterProps = {
    draftVideoRoot: DraftVideoRoot
    videoAreaWidth: number
    setTermId: (termId: string) => void
}

const VideoFooter: FC<VideoFooterProps> = observer(({ draftVideoRoot, videoAreaWidth, setTermId }) => {
    const w = videoAreaWidth
    const videoPosition = draftVideoRoot
    const videoPositionControls = draftVideoRoot
    const noteBarDisplay = draftVideoRoot
    const glossBarDisplay = draftVideoRoot
    const { verseReference } = draftVideoRoot

    const zoomIn = () => {
        const { timeline, currentTime, duration } = draftVideoRoot
        draftVideoRoot.setTimelineZoom(timeline.zoomLevel * 2)
        timeline.adjustZoomLimits(true, currentTime, duration)
    }

    const zoomOut = () => {
        const { timeline, currentTime, duration } = draftVideoRoot
        draftVideoRoot.setTimelineZoom(timeline.zoomLevel / 2)
        timeline.adjustZoomLimits(true, currentTime, duration)
    }

    const { timeline, passage, canPlayThrough } = draftVideoRoot
    const enabled = !passage?.videoBeingCompressed
    const zoomInEnabled = timeline.zoomLevel < 64 && enabled
    const zoomOutEnabled = timeline.zoomLevel > 1 && enabled
    const adjustTimeEnabled = canPlayThrough && enabled

    return (
        <div>
            <VideoPositionBarControls
                videoPositionControls={videoPositionControls}
                zoomIn={zoomIn}
                zoomOut={zoomOut}
                zoomInEnabled={zoomInEnabled}
                zoomOutEnabled={zoomOutEnabled}
                adjustTimeEnabled={adjustTimeEnabled}
            />
            <VideoPositionBar {...{ videoPosition, className: 'video-positionbar' }} />
            {verseReference && <VerseReferenceEditor {...{ videoPosition }} />}
            <ThinVideoPositionBar {...{ videoPosition }} />
            <NoteBar {...{ noteBarDisplay, w }} />
            <GlossBar {...{ glossBarDisplay, w, setTermId }} />
        </div>
    )
})

const CompareDraftToolbar: FC<CompareDraftToolbarProps> = observer(
    ({ compareDraftToolbar, playBothVideos, pauseBothVideos, stopBothVideosAndReset }) => (
        <div className="video-toolbar">
            <VideoPlaybackControls
                {...{ compareDraftToolbar, pauseBothVideos, playBothVideos, stopBothVideosAndReset }}
            />
            <VideoSelector {...{ compareDraftToolbar }} />
        </div>
    )
)

type CompareDraftVideoMainProps = {
    rt: Root
}

const CompareDraftVideoMain: FC<CompareDraftVideoMainProps> = observer(({ rt }) => {
    const [videoAreaWidth, setVideoAreaWidth] = useState(640)
    const [videoAreaHeight, setVideoAreaHeight] = useState(360)
    const [videoMainWidth, setVideoMainWidth] = useState(640)
    const [draftVideoRoot] = useState(new DraftVideoRoot(rt))
    const [termId, setTermId] = useState('')

    function stopAndReset() {
        draftVideoRoot.stop()
        draftVideoRoot.removeListener('stop-main-video', stopAndReset)
    }

    function playWhileRecording() {
        const _play = () => {
            draftVideoRoot.play()
            draftVideoRoot.addListener('stop-main-video', stopAndReset)
        }

        // There is a 1000ms delay in VideoRecorder before recording starts.
        // Not sure what delay we want here.
        // How far behind does the signer want to be?
        setTimeout(_play, 3000)
    }

    function setComponentWidth() {
        const videoMain = document.querySelectorAll("[data-id='video-main']")
        for (const element of videoMain) {
            const _element = element as HTMLElement
            const isVisible = htmlElementVisible(_element)
            const BUFFER = 12 // 5px left padding + 5px right padding + 1px left border + 1px right border
            const width = _element.getBoundingClientRect().width - BUFFER
            if (isVisible) {
                setVideoMainWidth(width)
            }
        }
    }

    function setNoteBarWidth() {
        const videoArea = document.querySelectorAll("[data-id='compare-video-area']")
        for (const element of videoArea) {
            const _element = element as HTMLElement
            const isVisible = htmlElementVisible(_element)
            if (isVisible) {
                setVideoAreaWidth(_element.offsetWidth)
            }
        }
    }

    function keydown(e: KeyboardEvent) {
        const { playing, stop, play, pause, currentTime, videoPlaybackKeydownEnabled, passage } = draftVideoRoot

        // Ignore keydown handlers if this event meets certain conditions
        const element = e.target?.toString() || ''
        const el = e.target as Element
        const shouldReject =
            element.includes('HTMLInputElement') ||
            element.includes('HTMLTextAreaElement') ||
            (el.getAttribute && el.getAttribute('contenteditable') === 'true') ||
            (el.getAttribute && el.getAttribute('role') === 'dialog') ||
            !videoPlaybackKeydownEnabled ||
            passage?.videoBeingCompressed
        if (shouldReject) {
            log('keydown rejected non-global')
            return
        }

        e.stopPropagation()

        log(`keydown code=${e.code}`, e)

        if (e.code === 'Space') {
            if (playing) {
                pause()
            } else {
                play(undefined, undefined, currentTime)
                draftVideoRoot.bothPlayingRequested = true
            }
        }

        if (e.code === 'Escape') {
            stop()
        }
    }

    const draftVideoPlayer = draftVideoRoot
    const compareDraftToolbar = draftVideoRoot

    const playBothVideos = () => {
        draftVideoRoot.play(undefined, undefined, draftVideoRoot.currentTime)
        rt.play(undefined, undefined, rt.currentTime)
        draftVideoRoot.bothPlayingRequested = true
    }

    const pauseBothVideos = () => {
        draftVideoRoot.pause()
        rt.pause()
    }

    const stopBothVideosAndReset = () => {
        draftVideoRoot.stop()
        rt.stop()
    }

    const { note, passage } = draftVideoRoot

    // Is this the right thing to do? All we want is to ensure note is never
    // undefined in the note dialog
    const noteRoot = draftVideoRoot as INoteRoot
    const closeNoteDialog = () => draftVideoRoot.setNote(undefined)

    const compressingVideo = !!passage?.videoBeingCompressed

    useEffect(() => {
        const _interval = setInterval(() => {
            setNoteBarWidth()
            setComponentWidth()
        }, 100)

        return () => {
            clearInterval(_interval)
        }
    }, [])

    useEffect(() => {
        window.addEventListener('keydown', keydown)
        return () => window.removeEventListener('keydown', keydown)
    }, [])

    useEffect(() => {
        draftVideoRoot.initialize()
    }, [])

    useEffect(() => {
        draftVideoRoot.addListener('record', playWhileRecording)
        return () => {
            draftVideoRoot.removeListener('record', playWhileRecording)
            draftVideoRoot.removeListener('stop-main-video', stopAndReset)
        }
    })

    return (
        <div className="compare-draft-video-main" data-id="compare-draft-video-main" style={{ width: videoMainWidth }}>
            {termId.trim() !== '' && <ERTermModal rt={rt} termId={termId} setTermId={setTermId} />}
            {note && <NoteDialog {...{ noteRoot, closeNoteDialog }} />}
            <div className="compare-draft-video-main-top">
                <CompareDraftToolbar
                    {...{ compareDraftToolbar, playBothVideos, pauseBothVideos, stopBothVideosAndReset }}
                />
                <div
                    className="video-area compare-draft-video-main-drop-target-container"
                    data-id="compare-video-area"
                    style={{ height: videoAreaHeight }}
                >
                    {!compressingVideo && (
                        <DraftVideoPlayer {...{ draftVideoPlayer, setContainerHeight: setVideoAreaHeight }} />
                    )}
                    {compressingVideo && <VideoMessage rt={rt} />}
                </div>
            </div>
            <VideoFooter {...{ draftVideoRoot, videoAreaWidth, setTermId }} />
        </div>
    )
})

export default CompareDraftVideoMain

import _ from 'underscore'
import { DBObject } from './DBObject'

export const smallNoteMarker = 8
export const largeNoteMarker = 15

// Find rank of item item before index 'before'.
export function calculateRankBefore(before: number, items: DBObject[]) {
    const ranks = items.map((item) => parseFloat(item.rank))

    const rankBefore = before - 1 in ranks ? ranks[before - 1] : 0
    const rankAfter = before in ranks ? ranks[before] : rankBefore + 200

    const rank = DBObject.numberToRank((rankBefore + rankAfter) / 2)

    // log('calculateRank', fmt({before, ranks, rank}))
    return rank
}

export function limit(value: number, low: number, high: number) {
    value = Math.max(value, low)
    value = Math.min(value, high)
    return value
}

export function normalizeUsername(username: string) {
    username = username ?? '' // just in case an undefined somehow gets passed
    return username.trim().toLowerCase()
}

export async function move(items: any[], oldIndex: number, newIndex: number) {
    if (newIndex === oldIndex) return
    if (items.length <= 1) return

    let rank: number

    // Assign a rank to an item to cause it to order at the desired index
    if (newIndex === 0) {
        // Insert at beginning
        rank = items[0].rankAsNumber / 2
    } else if (newIndex === items.length - 1) {
        // Insert at end
        rank = items[items.length - 1].rankAsNumber + 100
    } else {
        let i
        if (newIndex > oldIndex) {
            i = newIndex
        } else {
            i = newIndex - 1
        }

        // Insert between two items
        const prevRank = items[i].rankAsNumber
        const nextRank = items[i + 1].rankAsNumber
        rank = (prevRank + nextRank) / 2
    }

    const item = items[oldIndex]
    await item.setRank(rank)
}

export async function remove(items: any[], _id: string) {
    const idx = _.findIndex(items, { _id })
    if (idx < 0) return

    const item = items[idx]

    const doc = item._toDocument({})
    doc.removed = true
    await item.db.put(doc)
}

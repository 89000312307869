/* eslint-disable import/no-cycle */
import { fmt } from '../components/utils/Fmt'

import { RefRange } from '../scrRefs/RefRange'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:albums')

// records all the passages for a given book/chapter
export type PassagesMap = { [bbbccc: string]: SLBiblePassage[] }
const passagesByBbbccc: PassagesMap = {}

// --------------------------------------

export type SLBiblePassage = {
    languageName: string
    copyright: string
    teamName: string
    albumName: string
    hostedBy: string
    name: string
    url: string
    refs: RefRange[]
}

async function getSLBiblePassagesByBbbccc(bbbccc: string) {
    let passages = passagesByBbbccc[bbbccc]
    if (passages !== undefined) {
        return passages
    }

    const url = `https://s3.amazonaws.com/sltt-resources/SLBibleIndex/${bbbccc}.json`
    const response = await fetch(url)
    if (!response.ok) {
        log(`### SLBibleIndex fetch failed: ${response.url}: ${response.statusText}`)
        passages = []
    } else {
        let jsons: any[] = []
        try {
            jsons = await response.json()
        } catch (error) {
            log('### getSLBiblePassagesByBbbccc response.json', error)
        }

        for (const passage of jsons) {
            let refs: any[] = []
            try {
                refs = JSON.parse(passage.refs)
            } catch (error) {
                log('### getSLBiblePassagesByBbbccc parse refs ERROR', passage)
            }
            passage.refs = refs.map((ref: any) => new RefRange(ref.startRef, ref.endRef))
        }

        passages = jsons
        log('getSLBiblePassagesByBbbccc', fmt({ bbbccc, count: passages.length }))
    }

    passagesByBbbccc[bbbccc] = passages
    return passages
}

export async function getSLBiblePassages(refs: RefRange[]) {
    const passages: SLBiblePassage[] = []

    for (const ref of refs) {
        for (const bbbccc of ref.chapterIterator()) {
            const _passages = await getSLBiblePassagesByBbbccc(bbbccc)
            for (const _passage of _passages) {
                if (!passages.some((p) => p.url === _passage.url)) {
                    passages.push(_passage)
                }
            }
        }
    }

    return passages
}

import { createContext, useCallback, useContext, useEffect, useState, FC } from 'react'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('OnlineStatusContext')

const CHECK_INTERNET_ACCESS_FREQUENCY_MS = Number(process.env.REACT_APP_CHECK_INTERNET_ACCESS_FREQUENCY_MS ?? 30000)

// idea comes from
// https://dev.to/maxmonteil/is-your-app-online-here-s-how-to-reliably-know-in-just-10-lines-of-js-guide-3in7
export const canAccessInternet = async (reason = '') => {
    log(`Checking internet access ${reason ? `for ${reason}` : ''}`)

    if (!navigator.onLine) {
        log('Checking internet access and got no network, so false')
        return false
    }

    // Navigator.onLine sometimes gives false positives.
    // See https://developer.mozilla.org/en-US/docs/Web/API/Navigator/onLine

    // avoid CORS errors with a request to your own origin
    const url = new URL(window.location.origin)

    // random value to prevent cached responses
    url.searchParams.set('ping', Math.random().toString(36).substring(2, 15))
    try {
        const response = await fetch(url.toString(), { method: 'HEAD' })
        log('Checking internet access and got result', response.ok)
        return response.ok
    } catch {
        log('Checking internet access and got error, so false')
        return false
    }
}

interface OnlineStatusContextParams {
    isOnline: boolean
    checkIsOnline: () => Promise<boolean>
}

export const OnlineStatusContext = createContext<OnlineStatusContextParams>({
    isOnline: false,
    checkIsOnline: () => {
        throw new Error('OnlineStatusContext not available')
    }
})

export const OnlineStatusProvider: FC = ({ children }) => {
    const [isOnline, setIsOnline] = useState(true)

    const checkIsOnline = useCallback(async () => {
        const canAccess = await canAccessInternet('OnlineStatusContext')
        setIsOnline(canAccess)
        return canAccess
    }, [])

    useEffect(() => {
        window.addEventListener('offline', () => {
            setIsOnline(false)
        })

        const id = setInterval(() => {
            checkIsOnline()
        }, CHECK_INTERNET_ACCESS_FREQUENCY_MS)

        return () => {
            window.removeEventListener('offline', () => {
                setIsOnline(false)
            })

            clearInterval(id)
        }
    }, [checkIsOnline])

    return (
        // eslint-disable-next-line react/jsx-no-constructed-context-values
        <OnlineStatusContext.Provider value={{ isOnline, checkIsOnline }}>{children}</OnlineStatusContext.Provider>
    )
}

export const useOnlineStatus = () => useContext(OnlineStatusContext)

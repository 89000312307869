import _ from 'underscore'
import { RefRange } from '../scrRefs/RefRange'
import { ptxBookIds } from '../scrRefs/bookNames'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:ApiDotBible')

const API_KEY = 'bb5dd32f6205ef9b9da164c545a2db86'

async function fetchADB(url: string) {
    const options = {
        method: 'GET',
        headers: { 'api-key': API_KEY }
    }

    const _url = `https://api.scripture.api.bible/v1/${url}`

    const response = await fetch(_url, options)
    if (response.status !== 200) throw Error(response.statusText)

    const json = await response.json()
    return json
}

const languageNames = [
    'Arabic, Standard',
    'Assamese',
    'Belarusan',
    'Belarusian',
    'Bengali',
    'Czech',
    'Kurdish, Central',
    'German',
    'German, Standard',
    'English',
    'French',
    'Greek, Ancient',
    'Gujarati',
    'Hausa',
    'Hindi',
    'Indonesian',
    'Italian',
    'Kannada',
    'Malayalam',
    'Marathi',
    'Dutch',
    'Chichewa',
    'Panjabi',
    'Polish',
    'Portuguese',
    'Romani, Carpathian',
    'Romani, Balkan',
    'Romani, Vlax',
    'Russian',
    'Spanish',
    'Serbian',
    'Swedish',
    'Swahili',
    'Tamil',
    'Telugu',
    'Thai',
    'Urdu',
    'Vietnamese',
    'Yoruba'
]

const excludedIds = [
    'de4e12af7f28f599-02',
    '9879dbb7cfe39e4d-02',
    '9879dbb7cfe39e4d-03',
    '9879dbb7cfe39e4d-04',
    '7142879509583d59-02',
    '7142879509583d59-03',
    '7142879509583d59-04',
    '3548ab6114a312d4-01',
    '0ab0c764d56a715d-01',
    'e952663db2e91691-01'
]

export interface ADBVersion {
    id: string
    name: string
    language: any // language.name
    abbreviation: string
}

export class ApiDotBible {
    static versions: ADBVersion[] = []

    static async getBibleVersions() {
        if (this.versions.length === 0) {
            const json = await fetchADB(`bibles`)
            let versions: ADBVersion[] = json.data || []
            versions = versions.filter((version: any) => languageNames.includes(version.language.name))
            versions = versions.filter((version: any) => !excludedIds.includes(version.id))
            versions = _.sortBy(versions, (version: any) => version.abbreviation.toLowerCase())

            this.versions = versions
        }

        return this.versions
    }

    static async getBibleVersion(bibleVersionID: string) {
        const versions = await this.getBibleVersions()
        return versions.find((version) => version.id === bibleVersionID)
    }

    static async getBooks(bibleVersionID: string) {
        // bibleVersionID = "685d1470fe4d5c3b-01" // ASV

        const json = await fetchADB(`bibles/${bibleVersionID}/books`)

        return json.data.map((book: any) => book.id) // 'GEN', 'EXO' ...
    }

    static async getChapters(bibleVersionID: string, bibleBookId: string) {
        // bibleVersionID = "685d1470fe4d5c3b-01" // ASV
        // bibleBookId = 'JAS'

        const json = await fetchADB(`bibles/${bibleVersionID}/books/${bibleBookId}/chapters`)

        return json.data.map((chapter: any) => chapter.number) // 'intro', '1', '2'. ...
    }

    static async getChapterText(bibleVersionID: string, chapterId: string) {
        // bibleVersionID = bibleVersionID || "685d1470fe4d5c3b-01" // ASV
        bibleVersionID = bibleVersionID || '592420522e16049f-01' // RVR

        chapterId = chapterId || 'JAS.1'

        try {
            const json = await fetchADB(`bibles/${bibleVersionID}/chapters/${chapterId}`)
            const html: string = json.data.content
            return html
        } catch (error) {
            log('!!!', error)
        }

        return '<div>***</div>'
    }

    static async fetchRefs(bibleVersionID: string, refs: RefRange[], displayableBookNames: string[]) {
        const bbbcccs: string[] = []
        for (const ref of refs) {
            for (const bbbccc of ref.chapterIterator()) {
                if (!bbbcccs.includes(bbbccc)) {
                    bbbcccs.push(bbbccc)
                }
            }
        }

        const chapters: string[] = []
        for (const bbbccc of bbbcccs) {
            const bookNum = parseInt(bbbccc.slice(0, 3))
            const chapterNum = parseInt(bbbccc.slice(3, 6))
            const bookId = ptxBookIds[bookNum - 1]

            const chapterText = await this.getChapterText(bibleVersionID, `${bookId}.${chapterNum}`)
            if (chapterText) {
                if (bbbcccs.length > 1) {
                    const displayableBookName = displayableBookNames[bookNum - 1] || bookId
                    chapters.push(`<div class="ms">${displayableBookName} ${chapterNum}</div>`)
                }
                chapters.push(chapterText)
                if (bbbcccs.length > 1) {
                    chapters.push(`<br></br>`)
                }
            }
        }

        return `<div>\n${chapters.join('')}</div>`
    }
}

const _window = window as any
_window.ApiDotBible = ApiDotBible

import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import '../app/SLTool.css'
import { useOnlineStatus } from '../app/OnlineStatusContext'
import { NoInternetIcon } from './Icons'

export const OfflineMessage: FC = () => {
    const { t } = useTranslation()
    const { isOnline } = useOnlineStatus()

    return (
        <div className="offline-message">
            {!isOnline && (
                <div className="message-warning">
                    <NoInternetIcon className="no-internet-icon" />
                    {t(
                        'You have not connected to the internet in a while. Please connect so that your changes will be synced and made available to others in your team.'
                    )}
                </div>
            )}
        </div>
    )
}

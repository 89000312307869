export const isAVTT = !!process.env.REACT_APP_IS_AVTT

export const isDev = !!process.env.REACT_APP_IS_DEV

export const supportEmail =
    process.env.REACT_APP_SUPPORT_EMAIL ?? isAVTT ? 'support@avtt.bible' : 'nmiles@biblesocieties.org'

export const websiteShortName = isAVTT ? (isDev ? 'AVTT DEV' : 'AVTT') : 'SLTT'

export const websiteName = isAVTT
    ? isDev
        ? 'AVTT Development'
        : 'Audio Video Translation Tool'
    : 'Sign Language Translation Tool'

export const docsUrl = process.env.DOCS_URL ?? 'https://s3.amazonaws.com/avtt-help'

export const enableWaveSurfer = !(localStorage.getItem('disableWaveSurfer') === 'true')

export const enableLegacyWaveform = localStorage.getItem('enableLegacyWaveform') === 'true'

export const enableKeyTerms = !(localStorage.getItem('disableKeyTerms') === 'true')

export const enableGlosses = !(localStorage.getItem('disableKeyTerms') === 'true')

export const sat = (slttString: string, avttString: string) => (isAVTT ? avttString : slttString)

/* When we host the app from localhost the base route is just '/'.
 * When we host from S3 however the base route is '/sltt-hosting-dev/' or
 * '/sltt-hosting-prd/' to account for the fact that we have separate
 * S3 buckets for the development and production versions.
 * Value is set in constructor based on an PUBLIC_URL environment
 * variable which is set in package.json during the deploy-dev and deploy-prd
 * commands.
 */
export const routePrefix = process.env.PUBLIC_URL ? new URL(process.env.PUBLIC_URL).pathname.replace(/\/$/, '') : ''

export const avttVersion = '1.0'

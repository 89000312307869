/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable import/no-cycle */
/* eslint-disable no-underscore-dangle */

import { t } from 'i18next'
import { observable } from 'mobx'
import _ from 'underscore'

import { RefRange } from '../../scrRefs/RefRange'
import { ImageMetadata } from '../../resources/ImageMetadata'
import { MARBLEImages } from '../../resources/MARBLEImages'
import { fmt } from '../utils/Fmt'
import { ProjectImages } from '../../resources/ProjectImages'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:ImageCollection')

/*
    A collection of images that match search refs (RefRange[]).
    The routines to do async queries to load these.
 */

class ImageCollection {
    _controller = new AbortController()

    _numFinished = 0

    _numStarted = 0

    @observable loading = false

    /**
     * If the user pass a large range it may trigger reading the MARBLE image data
     * for many chapters. Because of this we use an AbortController to allow canceling
     * all these reads if we move to a different reference.
     * This controller only affects the MARBLE images (not the project images) to it
     * is odd for it to be in a class that I think should just contain the code
     * that is commont to MARBLE and project images.
     */

    fetchImages = async (projectName: string, refs: RefRange[]) => {
        try {
            log('fetchImages', JSON.stringify(refs))
            this.stopSearching()

            if (refs.length === 0) return []

            const newController = new AbortController() // Need a new controller since current one has been aborted
            this._controller = newController

            this._numStarted += 1
            if (this._numStarted !== this._numFinished) {
                this.loading = true
            }

            const images = await this.getImagesForRefs(projectName, refs, newController.signal)

            this._numFinished += 1 // Will get called when request is aborted or finishes
            if (this._numStarted === this._numFinished) {
                this.loading = false
            }

            return images
        } catch (error) {
            this._numStarted = 0
            this._numFinished = 0
            this.loading = false
            throw Error(error as string)
        }
    }

    stopSearching = () => this._controller.abort()

    private getImagesForRefs = async (projectName: string, refs: RefRange[], signal: AbortSignal) => {
        try {
            let images = await ImageCollection.getImagesInRange(projectName, refs, signal)
            log('getImagesForRefs', fmt({ refs, projectName, length: images.length }))

            const ids = new Set<string>()
            const first = (id: string) => {
                if (ids.has(id)) return false
                ids.add(id)
                return true
            }

            // Filter out all but the first occurrence of each image
            images = images.filter((img) => first(img.id))
            images = _.sortBy(images, (img) => img.sortKey)

            return images
        } catch (err) {
            const error = err as Error
            if (error.name !== 'AbortError') {
                log('###', error)
                throw Error(t('Cannot get images at this time'))
            }
            return []
        }
    }

    // WARNING: Passing too many references may cause long search times
    private static getImagesInRange = async (projectName: string, references: RefRange[], signal: AbortSignal) => {
        // fetch images for all chapters in range
        const bbbcccs = references.flatMap((ref) => [...ref.chapterIterator()])
        const images = await ImageCollection.fetchChapterInfo(projectName, bbbcccs, signal)

        // Filter out images that don't fall in exact verse ranges specified
        return images.filter((image) => image.fallsInRanges(references))
    }

    private static fetchChapterInfo = async (projectName: string, chapters: string[], signal: AbortSignal) => {
        let results: ImageMetadata[] = []

        for (const bbbccc of chapters) {
            try {
                let result = await MARBLEImages.fetchInfo(bbbccc, { signal })
                result = result.concat(await ProjectImages.fetchInfo(projectName, bbbccc))
                results = results.concat(result)
            } catch (error) {
                log('fetchChapterInfo ERROR', error)
                // If user enters invalid chapter number we end up here
            }
        }

        return results
    }
}

export default ImageCollection

import React, { Component, useState } from 'react'
import { observer } from 'mobx-react'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import _ from 'underscore'
import { t } from 'i18next'

import PassageView from './PassageView'
import TourPassage from './TourPassage'
import PassageAdder from './PassageAdder'
import TourPassageAdder from './TourPassageAdder'
import PortionSelector from './PortionSelector'
import PassageEditor from './PassageEditor'

import { RootConsumer } from '../app/RootContext'
import { useHoverDelay } from '../utils/Hooks'
import { displayError, systemError, CustomErrorBoundary } from '../utils/Errors'

import { Passage } from '../../models3/Passage'
import { Portion } from '../../models3/Portion'
import { Root } from '../../models3/Root'

import './Passage.css'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:PassageList')

const DragHandle = SortableHandle(() => {
    return (
        <RootConsumer>
            {() => (
                <span className="passage-handle" data-toggle="tooltip" title={t('Drag to reorder.')}>
                    <i className="fas fa-bars" />
                </span>
            )}
        </RootConsumer>
    )
})

interface ISortableElement {
    rt: Root
    passage: Passage
}

const SortableItem = SortableElement(function (props: ISortableElement) {
    const [copyModalOpen, setCopyModalOpen] = useState(false)
    const [editing, setEditing] = useState(false)
    const [mouseOver, setMouseOver] = useState(false)
    const showEditingIcons = useHoverDelay(mouseOver)

    const { rt, passage } = props
    const { useMobileLayout, username } = rt
    const assigned = passage.assignee === username && passage.task !== 'Finished'
    return (
        <div
            className={`passage passage-list-item ${assigned ? 'passage-assigned' : ''}`}
            data-id={`passage-${props.passage.name}`}
            onMouseEnter={() => setMouseOver(true)}
            onMouseLeave={() => setMouseOver(false)}
        >
            {!useMobileLayout && !editing && <DragHandle />}
            {editing && <PassageEditor {...{ rt, passage, setEditing }} />}
            <PassageView {...{ rt, passage, copyModalOpen, setCopyModalOpen, editing, setEditing, showEditingIcons }} />
        </div>
    )
})

interface ISortableContainer {
    rt: Root
    items: Array<Passage>
}

const SortableList = SortableContainer((props: ISortableContainer) => {
    return (
        <div>
            {props.items.map((passage, index) => (
                <CustomErrorBoundary
                    key={passage._id}
                    fallbackUI={
                        <div
                            className="passage passage-error"
                            data-id={`passage-${passage.name}`}
                            title={t('Something went wrong while displaying this passage')}
                        >
                            <b>{`${passage.name}???`}</b>
                        </div>
                    }
                    fallbackAction={() => {
                        props.rt.setPassage(null)
                    }}
                >
                    <SortableItem index={index} rt={props.rt} passage={passage} />
                </CustomErrorBoundary>
            ))}
        </div>
    )
})

interface IPassageList {
    rt: Root
}

class PassageList extends Component<IPassageList> {
    passageCounts = new Map<string, number>()

    componentDidUpdate() {
        const { rt } = this.props
        const { passage } = rt

        if (!passage) return

        // If the number of videos for the current passage changes,
        // reset to the last video present to make sure the user
        // sees that there is a new video.

        const count1 = this.passageCounts.get(passage._id) || 0
        const count2 = passage.videos.length
        log('componentDidUpdate', passage._id, count1, count2)

        if (count2 !== 0 && count1 !== count2) {
            const activeVideos = passage.videosNotDeleted
            if (activeVideos.length > 0) {
                rt.setPassageVideo(activeVideos.slice(-1)[0])
            }
        }
    }

    onChange(event: React.ChangeEvent<HTMLSelectElement>) {
        const { rt } = this.props
        const { project } = rt

        const _id = event.target.value
        const portions: Array<Portion> = (project.portions && project.portions) || []
        const portion = _.findWhere(portions, { _id })
        if (portion) {
            rt.setPortion(portion).catch(displayError)
        }
    }

    onSortEnd(indices: any) {
        const { rt } = this.props
        const { iAmTranslator, portion } = rt

        if (!iAmTranslator || !portion) {
            return
        }

        const { _id } = portion.passages[indices.oldIndex]

        portion.movePassage(_id, indices.newIndex).catch(systemError)
    }

    render() {
        const { rt } = this.props
        const { project, portion, tourOpen, useMobileLayout } = rt

        const items = (portion && portion.passages) || []
        /* eslint-disable-next-line @typescript-eslint/no-unused-expressions */
        items.length // required to access length or list appears empty in view!!! why?

        // Remember how many videos each passage has
        this.passageCounts.clear()
        items.forEach((item) => this.passageCounts.set(item._id, item.videos.length))

        const initial = (rt.portion && rt.portion._id) || ''

        if (tourOpen)
            return (
                <div className="passages">
                    <PortionSelector
                        onChange={this.onChange.bind(this)}
                        portions={project.portions}
                        selectedValue={initial}
                    />
                    <TourPassage />
                    <TourPassageAdder />
                </div>
            )

        return (
            <div className="passages">
                <PortionSelector
                    onChange={this.onChange.bind(this)}
                    portions={project.portions}
                    selectedValue={initial}
                />
                <SortableList rt={rt} items={items} onSortEnd={this.onSortEnd.bind(this)} distance={5} />
                {portion && !useMobileLayout && <PassageAdder rt={rt} />}
            </div>
        )
    }
}

export default observer(PassageList)

/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { confirmAlert } from 'react-confirm-alert'
import { ControlLabel, FormGroup, Modal, Radio } from 'react-bootstrap'
import { useTranslation, TFunction } from 'react-i18next'

import { RootContext } from '../app/RootContext'

import {
    CancelButton,
    DeleteButton,
    OKButton,
    PencilButton,
    RecordButton,
    SettingsButton,
    StopButton
} from '../utils/Buttons'
import { displayError } from '../utils/Errors'
import TextInput from '../utils/TextInput'
import { AudioRecorder, AVTTRecordingState } from '../video/VideoRecorder'
import { RecordingDoneParams, VideoUploader } from '../video/VideoUploader'
import { BlobDownloading } from '../utils/VideoDownloading'
import { SegmentTextEditor } from '../utils/SegmentTextEditor'
import { SegmentWarningIcon } from '../utils/Icons'

import { AudioClip } from '../../models3/AudioClip'
import { PassageSegment } from '../../models3/PassageSegment'
import { PassageSegmentDocument } from '../../models3/PassageSegmentDocument'
import { Root } from '../../models3/Root'
import { LiveWaveformVisualizerWrapper } from '../video/WaveformVisualizer'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('avtt:BackTranslation')

declare const webkitSpeechRecognition: any

class OralTranscriber {
    recognition: any

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onTranscriptionUpdate: (transcription: string) => void

    t: TFunction

    static supportedLanguages = ['en-GB', 'en-US', 'en-ZA', 'es-MX', 'fr-CA', 'fr-FR', 'pt-BR', 'pt-PT', 'ru-RU']

    constructor(onTranscriptionUpdate: (transcription: string) => void, languageCode: string, t: TFunction) {
        if ('webkitSpeechRecognition' in window) {
            // eslint-disable-next-line new-cap
            this.recognition = new webkitSpeechRecognition()
            this.recognition.interimResults = true
            this.recognition.continuous = true
            this.recognition.lang = languageCode
            this.onTranscriptionUpdate = onTranscriptionUpdate
        } else {
            this.recognition = undefined
            this.onTranscriptionUpdate = () => {}
            log('Speech recognition not supported')
        }

        this.t = t
    }

    start() {
        if (!this.recognition) {
            return
        }

        let interimTranscript = ''
        let finalTranscript = ''

        this.recognition.onstart = () => {
            log('Auto transcriber recognition started')
        }

        this.recognition.onerror = () => {
            this.onTranscriptionUpdate('')
            displayError(this.t('An error occurred while transcribing'))
            this.recognition?.stop()
        }

        this.recognition.onend = () => {
            log('Auto transcriber recognition stopped')
        }

        this.recognition.onresult = (event: any) => {
            for (let i = event.resultIndex; i < event.results.length; i++) {
                if (event.results[i].isFinal) {
                    finalTranscript += event.results[i][0].transcript
                    this.onTranscriptionUpdate(finalTranscript)
                } else {
                    interimTranscript = finalTranscript + event.results[i][0].transcript
                    this.onTranscriptionUpdate(interimTranscript)
                }
            }
        }

        this.recognition.start()
    }

    stop() {
        this.recognition?.stop()
    }
}

type BackTranslationSettingsProps = {
    rt: Root
    closeModal: () => void
}

const BackTranslationSettings = observer(({ rt, closeModal }: BackTranslationSettingsProps) => {
    const { t } = useTranslation()
    const [languageCode, setLanguageCode] = useState(() => rt.getDefault('oralBackTranslationLanguage') ?? '')

    return (
        <Modal show onHide={closeModal} backdrop="static">
            <Modal.Header closeButton>
                <h3>{t('Back Translation Settings')}</h3>
            </Modal.Header>
            <Modal.Body>
                <form>
                    <FormGroup controlId="oral-transcription-settings">
                        <ControlLabel>
                            {t('Automatic transcription language (requires internet connection)')}
                        </ControlLabel>
                        <Radio
                            name="radioGroup"
                            onChange={() => {
                                setLanguageCode('')
                            }}
                            checked={languageCode === ''}
                        >
                            {t('Do not automatically transcribe')}
                        </Radio>
                        {OralTranscriber.supportedLanguages.map((lang) => (
                            <Radio
                                key={lang}
                                name="radioGroup"
                                onChange={() => {
                                    setLanguageCode(lang)
                                }}
                                checked={languageCode === lang}
                            >
                                {lang}
                            </Radio>
                        ))}
                    </FormGroup>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <div className="button-row">
                    <OKButton
                        enabled
                        onClick={() => {
                            rt.setDefault('oralBackTranslationLanguage', languageCode)
                            closeModal()
                        }}
                        className="modal-footer-button ok-icon"
                        buttonClassName=""
                        tooltip={t('Save')}
                    />
                    <CancelButton
                        enabled
                        onClick={() => {
                            closeModal()
                        }}
                        className="modal-footer-button"
                        tooltip={t('Cancel')}
                    />
                </div>
            </Modal.Footer>
        </Modal>
    )
})

type BackTranslationEditorProps = {
    segment: PassageSegment
    speaker: string
    setSpeaker: (value: string) => void
    transcription: string
    setTranscription: (value: string) => void
    recordedBlobSrc: string
    enabled: boolean
    readOnly?: boolean
    lastUpdatedDate?: Date
    showMessage?: boolean
}

const BackTranslationEditor = observer(
    ({
        segment,
        speaker,
        setSpeaker,
        transcription,
        setTranscription,
        recordedBlobSrc,
        enabled,
        readOnly,
        lastUpdatedDate,
        showMessage
    }: BackTranslationEditorProps) => {
        const { t } = useTranslation()
        const [editingSpeaker, setEditingSpeaker] = useState(false)
        const [editingTranscription, setEditingTranscription] = useState(false)
        const rt = useContext(RootContext)

        const recordingDateString = rt?.passage?.findVideo(segment._id)?.creationDate ?? ''
        const recordingDate = recordingDateString ? new Date(recordingDateString) : undefined

        return (
            <>
                {showMessage && recordingDate && lastUpdatedDate && lastUpdatedDate < recordingDate && (
                    <>
                        <SegmentWarningIcon />
                        {t('olderThanSegment')}
                    </>
                )}
                {recordedBlobSrc.trim() !== '' && (
                    <>
                        <audio className="oral-back-translation-player" src={recordedBlobSrc} controls />
                        <div className="oral-back-translation-speaker">
                            {t('Speaker: ')}
                            &nbsp;
                            {editingSpeaker && (
                                <TextInput
                                    message=""
                                    initialValue={speaker}
                                    _onEnter={(value) => {
                                        setSpeaker(value)
                                        setEditingSpeaker(false)
                                    }}
                                    _onEscape={() => {
                                        setEditingSpeaker(false)
                                    }}
                                    allowEmptyValue
                                />
                            )}
                            {!editingSpeaker && (
                                <>
                                    <input
                                        type="text"
                                        className="segment-resource-text-input"
                                        value={speaker}
                                        readOnly
                                    />
                                    {!readOnly && (
                                        <PencilButton
                                            enabled={enabled}
                                            onClick={() => {
                                                setEditingSpeaker(true)
                                            }}
                                            className="right-pane-button default-blue-icon opaque-on-hover"
                                            tooltip={t('Edit speaker')}
                                        />
                                    )}
                                </>
                            )}
                        </div>
                    </>
                )}
                <SegmentTextEditor
                    savedText={transcription}
                    save={setTranscription}
                    onClose={() => {
                        setEditingTranscription(false)
                    }}
                    editing={editingTranscription}
                    notEditingControls={
                        !readOnly && (
                            <PencilButton
                                enabled={enabled}
                                onClick={() => {
                                    setEditingTranscription(true)
                                }}
                                className="right-pane-button default-blue-icon opaque-on-hover"
                                tooltip={t('Edit transcription')}
                            />
                        )
                    }
                />
            </>
        )
    }
)

type ExistingBackTranslationProps = {
    segment: PassageSegment
    currentAudio?: AudioClip
    currentDocument?: PassageSegmentDocument
    enabled: boolean
    readOnly?: boolean
    showMessage?: boolean
}

const ExistingBackTranslation = observer(
    ({ segment, currentAudio, enabled, currentDocument, readOnly, showMessage }: ExistingBackTranslationProps) => {
        const [recordedBlob, setRecordedBlob] = useState<Blob>()
        const [recordedBlobSrc, setRecordedBlobSrc] = useState('')
        const url = currentAudio?.url ?? ''

        useEffect(() => {
            let src = ''
            if (recordedBlob) {
                src = window.URL.createObjectURL(recordedBlob)
                setRecordedBlobSrc(src)
            }

            return () => {
                if (src.trim() !== '') {
                    window.URL.revokeObjectURL(src)
                }
            }
        }, [recordedBlob])

        useEffect(() => {
            setRecordedBlob(undefined)
            setRecordedBlobSrc('')
        }, [url])

        if (url.trim() !== '' && recordedBlobSrc.trim() === '') {
            return <BlobDownloading {...{ url, onEnded: setRecordedBlob }} />
        }

        const speaker = currentAudio?.speaker ?? ''

        const documentModDate = currentDocument ? new Date(currentDocument.modDate) : undefined
        const audioModDate = currentAudio ? new Date(currentAudio.modDate) : undefined
        let lastUpdatedDate
        if (documentModDate && audioModDate) {
            lastUpdatedDate = documentModDate < audioModDate ? audioModDate : documentModDate
        } else {
            lastUpdatedDate = documentModDate || audioModDate
        }

        return (
            <BackTranslationEditor
                segment={segment}
                speaker={speaker}
                setSpeaker={(value) => currentAudio?.setSpeaker(value)}
                transcription={currentDocument?.text ?? ''}
                setTranscription={(text) => {
                    if (!currentDocument && text.trim() !== '') {
                        const doc = segment.createPassageSegmentDocument()
                        doc.text = text
                        segment.addPassageSegmentDocument(doc).catch(displayError)
                    } else {
                        currentDocument?.setText(text).catch(displayError)
                    }
                }}
                recordedBlobSrc={recordedBlobSrc}
                enabled={enabled}
                readOnly={readOnly}
                showMessage={showMessage}
                lastUpdatedDate={lastUpdatedDate}
            />
        )
    }
)

type BackTranslationReviewProps = {
    segment: PassageSegment
    recordedBlobs: Blob[]
    closeRecorder: () => void
    save: (speaker: string) => void
    transcription: string
    setTranscription: (value: string) => void
}

const BackTranslationReview = observer(
    ({ segment, recordedBlobs, closeRecorder, save, transcription, setTranscription }: BackTranslationReviewProps) => {
        const { t } = useTranslation()
        const [speaker, setSpeaker] = useState('')
        const [recordedBlobSrc, setRecordedBlobSrc] = useState('')

        useEffect(() => {
            const blob = new Blob(recordedBlobs, { type: 'audio/webm' })
            const src = window.URL.createObjectURL(blob)
            setRecordedBlobSrc(src)

            return () => {
                if (src.trim() !== '') {
                    window.URL.revokeObjectURL(src)
                }
            }
        }, [recordedBlobs])

        return (
            <>
                <BackTranslationEditor
                    {...{
                        segment,
                        speaker,
                        setSpeaker,
                        transcription,
                        setTranscription,
                        recordedBlobSrc,
                        enabled: true,
                        readOnly: false,
                        showMessage: true
                    }}
                />
                <div className="oral-back-translation-bottom button-row">
                    <OKButton
                        enabled
                        onClick={() => {
                            save(speaker)
                            closeRecorder()
                        }}
                        buttonClassName=""
                        className="large-right-pane-button oral-back-translation-ok-button"
                        tooltip={t('Save recording')}
                    />
                    <CancelButton
                        enabled
                        onClick={closeRecorder}
                        className="large-right-pane-button"
                        tooltip={t('Cancel recording')}
                    />
                </div>
            </>
        )
    }
)

type AudioRecordingRecorderProps = {
    rt: Root
    closeRecorder: () => void
    setTranscription: (value: string) => void
    onDoneRecording: (blobs: Blob[]) => void
}

const AudioRecordingRecorder = observer(
    ({ rt, closeRecorder, setTranscription, onDoneRecording }: AudioRecordingRecorderProps) => {
        const { t } = useTranslation()
        const [recordingState, setRecordingState] = useState<AVTTRecordingState>('NOT_INITIALIZED')
        const [audioRecorder] = useState(new AudioRecorder(setRecordingState))
        const [transcriptionStarted, setTranscriptionStarted] = useState(false)

        useEffect(() => {
            function _onDoneRecording() {
                onDoneRecording(audioRecorder.blobs)
            }

            function _onError() {
                closeRecorder()
            }

            audioRecorder.addListener('onRecordingDone', _onDoneRecording)
            audioRecorder.addListener('onError', _onError)

            return () => {
                audioRecorder.removeListener('onRecordingDone', _onDoneRecording)
                audioRecorder.removeListener('onError', _onError)
            }
        })

        const [transcriber] = useState(() => {
            const languageCode = rt.getDefault('oralBackTranslationLanguage') ?? ''
            if (OralTranscriber.supportedLanguages.includes(languageCode)) {
                return new OralTranscriber(setTranscription, languageCode, t)
            }
            return undefined
        })

        useEffect(() => {
            if (recordingState === 'RECORDING' && !transcriptionStarted) {
                transcriber?.start()
                setTranscriptionStarted(true)
            }
        }, [recordingState, transcriptionStarted])

        useEffect(() => {
            setTimeout(audioRecorder._record.bind(audioRecorder), 1000)

            return () => {
                if (audioRecorder.mediaRecorder) {
                    const { state } = audioRecorder.mediaRecorder
                    if (state === 'recording' || state === 'paused') {
                        audioRecorder.cancel()
                    }
                }
            }
        }, [])

        return (
            <div className="oral-back-translation-recorder">
                <StopButton
                    enabled={recordingState !== 'NOT_INITIALIZED' && recordingState !== 'INITIALIZED'}
                    onClick={() => {
                        audioRecorder.stopRecording() // will trigger onRecordingDone listener
                        transcriber?.stop()
                    }}
                    className="right-pane-button oral-back-translation-stop-button"
                    tooltip={t('Stop recording and preview it')}
                />
                <CancelButton
                    enabled
                    onClick={() => {
                        audioRecorder.cancel()
                        transcriber?.stop()
                        closeRecorder()
                    }}
                    className="right-pane-button oral-back-translation-cancel-button"
                    tooltip={t('Cancel recording')}
                />
                <LiveWaveformVisualizerWrapper
                    mediaStream={audioRecorder.mediaStream}
                    recordingState={recordingState}
                    isAudioOnly
                    className="audio-recorder-visualization"
                />
            </div>
        )
    }
)

type BackTranslationRecorderProps = {
    closeRecorder: () => void
    rt: Root
    segment: PassageSegment
}

const BackTranslationRecorder = observer(({ rt, closeRecorder, segment }: BackTranslationRecorderProps) => {
    const [isRecording, setIsRecording] = useState(true)
    const [recordedBlobs, setRecordedBlobs] = useState<Blob[]>([])
    const [transcription, setTranscription] = useState('')

    const onDoneRecording = useCallback((blobs: Blob[]) => {
        setRecordedBlobs(blobs)
        setIsRecording(false)
    }, [])

    async function save(speaker: string) {
        const translation = segment.createAudioClip(rt.name)
        translation.speaker = speaker

        async function onRecordingDone({ err, blobsCount, url, duration }: Partial<RecordingDoneParams>) {
            if (err) {
                displayError(err)
                return
            }

            if (url !== undefined && url.trim() !== '' && blobsCount !== undefined) {
                translation.url = `${url}-${blobsCount}`
            }

            if (duration !== undefined) {
                translation.duration = duration
            }

            await segment.addAudioClip(translation)

            const passageSegmentDocument = segment.createPassageSegmentDocument()
            passageSegmentDocument.text = transcription
            await segment.addPassageSegmentDocument(passageSegmentDocument)
        }

        const uploader = new VideoUploader(translation.url, onRecordingDone)
        for (let i = 0; i < recordedBlobs.length; i++) {
            await uploader.pushVideoBlob(recordedBlobs[i], i === recordedBlobs.length - 1)
        }
    }

    if (isRecording) {
        return (
            <div>
                <AudioRecordingRecorder {...{ rt, closeRecorder, onDoneRecording, setTranscription }} />
                <SegmentTextEditor
                    savedText={transcription}
                    save={() => {}}
                    onClose={() => {}}
                    editing={false}
                    notEditingControls=""
                />
            </div>
        )
    }

    return (
        <BackTranslationReview {...{ segment, recordedBlobs, closeRecorder, save, transcription, setTranscription }} />
    )
})

type BackTranslationProps = {
    segment: PassageSegment
    rt: Root
    readOnly?: boolean
    className: string
    showMessage?: boolean
}

export default observer(({ segment, rt, readOnly, className, showMessage }: BackTranslationProps) => {
    const { t } = useTranslation()
    const [recording, setRecording] = useState(false)
    const [settingsOpen, setSettingsOpen] = useState(false)

    const { editingSegment, iAmInterpreter } = rt

    // trigger render on change to segment
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { _rev } = segment

    const currentAudio = segment.audioClips.length ? segment.audioClips[segment.audioClips.length - 1] : undefined
    const currentDocument = segment.documents.length ? segment.documents[segment.documents.length - 1] : undefined
    const backTranslationExists = currentAudio || currentDocument
    const buttonsEnabled = !editingSegment && !recording && iAmInterpreter

    function deleteBackTranslation() {
        if (backTranslationExists) {
            confirmAlert({
                title: t('Delete back translation?'),
                message: t(
                    'Are you sure you want to delete the back translation for this segment? It is not easy to get it back once you have deleted it.'
                ),
                confirmLabel: t('Delete'),
                cancelLabel: t('Cancel'),
                onConfirm: async () => {
                    if (currentAudio) {
                        await segment.removeAudioClip(currentAudio._id)
                    }
                    if (currentDocument) {
                        await segment.removePassageSegmentDocument(currentDocument._id)
                    }
                }
            })
        }
    }

    return (
        <div className={`back-translation ${className}`}>
            <div className="oral-back-translation">
                <div className="segment-dialog-heading">{t('backTranslationPanel')}</div>
                {!readOnly && (
                    <div className="button-row-space-between toolbar-bottom">
                        <div className="button-row">
                            <RecordButton
                                enabled={buttonsEnabled}
                                onClick={() => {
                                    setRecording(true)
                                }}
                                className="right-pane-button oral-back-translation-record-button"
                                tooltip={t('Record back translation')}
                                selectionPresent={false}
                            />
                            {backTranslationExists && (
                                <DeleteButton
                                    enabled={buttonsEnabled}
                                    onClick={deleteBackTranslation}
                                    className="right-pane-button"
                                    tooltip={t('Delete back translation')}
                                />
                            )}
                        </div>
                        <SettingsButton
                            onClick={() => setSettingsOpen(true)}
                            className="right-pane-button oral-back-translation-button"
                            tooltip={t('Settings')}
                            enabled={buttonsEnabled}
                        />
                        {settingsOpen && <BackTranslationSettings rt={rt} closeModal={() => setSettingsOpen(false)} />}
                    </div>
                )}
                {recording && (
                    <BackTranslationRecorder {...{ rt, segment, closeRecorder: () => setRecording(false) }} />
                )}
                {!recording && (
                    <ExistingBackTranslation
                        {...{
                            segment,
                            currentAudio,
                            currentDocument,
                            enabled: buttonsEnabled,
                            readOnly,
                            showMessage
                        }}
                    />
                )}
            </div>
        </div>
    )
})

import { FC, useCallback, useState, useEffect, useRef } from 'react'

import { useTranslation } from 'react-i18next'

import { RefRange } from '../../scrRefs/RefRange'
import { SearchBox } from './SearchBox'
import { displayableBookNames } from '../../scrRefs/bookNames'
import { GotoReferenceIcon } from './Icons'
import './ReferenceInput.css'
import { Project } from '../../models3/Project'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:ReferenceInput')

/**
 * Allow user to enter a Scripture reference.
 * If defaultReferenceId present, save any reference entered as a default reference
 * for this parameter of this project.
 * If rt.dbsRefs has been set with references from the current segment or portion,
 * use this as a default.
 *
 * This control uses 'displayableReferences'. These references can be entered using
 * the project book name, the UI language, or English.
 * They are displayed in the project language.
 */

interface IReferenceInput {
    refs: RefRange[]
    setRefs: (_refs: RefRange[]) => void
    refInput: IRefInput
    defaultReferenceId?: string
    onEnter?: () => void
    onEscape?: () => void
    allowBookNameOnly?: boolean
    errored: boolean
    setErrored: (value: boolean) => void
    autoFocus?: boolean
    // Allow user to enter a reference like 'Genesis', return a range containing
    // all the chapters in the book.
    // When false (or missing) no reference is returned until the user enters
    // a (at least) book and chapter number
    includeGotoReferenceButton?: boolean
    referenceRequired?: boolean
}

interface IRefInput {
    displayableReferences: (references: RefRange[] | null | undefined) => string
    dbsRefs: RefRange[]
    parseReferences: (references: string) => RefRange[]
    project: Project
    setDefault: (tag: string, value: string | null) => void
    getDefault: (tag: string) => string | null
}

export const ReferenceInput: FC<IReferenceInput> = ({
    refInput,
    refs,
    setRefs,
    defaultReferenceId,
    allowBookNameOnly,
    onEnter,
    onEscape,
    errored,
    setErrored,
    autoFocus,
    includeGotoReferenceButton,
    referenceRequired
}) => {
    const { t } = useTranslation()

    const [references, setReferences] = useState(refInput.displayableReferences(refs))
    const searchRef = useRef<any>(null)

    const setInputReferences = useCallback(
        (refRanges: RefRange[]) => {
            log('!!!setInputReferences', JSON.stringify(refRanges))
            setRefs(refRanges)
        },
        [setRefs]
    )

    useEffect(() => {
        if (refs.length > 0) return

        // No references passed try to get a default reference from localStorage
        // or from the current rt.dbsRefs
        const defaultReference = (defaultReferenceId && refInput.getDefault(defaultReferenceId)) ?? ''
        log('defaultReference', defaultReference)
        if (defaultReference) {
            const defaultReferences = JSON.parse(defaultReference).map((r: any) => new RefRange(r.startRef, r.endRef))
            log('parseDefault', defaultReferences)
            if (defaultReferences.length) {
                setInputReferences(defaultReferences)
                setReferences(refInput.displayableReferences(defaultReferences))
                return
            }
        }

        if (refInput.dbsRefs.length) {
            log('!!!dbsRefs')
            setInputReferences(refInput.dbsRefs)
            setReferences(refInput.displayableReferences(refInput.dbsRefs))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const bookNames = displayableBookNames(refInput.project)
    const passageReferences = refInput.displayableReferences(refInput.dbsRefs)
    return (
        <div className="reference-input">
            {includeGotoReferenceButton && (
                <button
                    type="button"
                    className="wraparound-button"
                    onClick={() => {
                        if (!refInput.dbsRefs.length) {
                            return
                        }
                        setInputReferences(refInput.dbsRefs)
                        setReferences(refInput.displayableReferences(refInput.dbsRefs))
                        if (defaultReferenceId) {
                            log('resetDefaultReference')
                            // clear localStorage so navigating to other passages will not always set to this ref
                            refInput.setDefault(defaultReferenceId, '')
                        }
                    }}
                >
                    <GotoReferenceIcon className="reference-input-goto-reference-icon" tooltip={passageReferences} />
                </button>
            )}
            <div
                className={`reference-input-searchbox ${
                    errored || (referenceRequired && refs.length === 0) ? 'reference-input-error' : ''
                }`}
            >
                <SearchBox
                    searchParameter={references}
                    options={bookNames}
                    onEnter={() => {
                        if (!errored && onEnter) {
                            onEnter()
                        }
                    }}
                    onEscape={() => {
                        if (onEscape) {
                            onEscape()
                        }
                    }}
                    ref={searchRef}
                    searchParameterChanged={(newReferences: string) => {
                        setReferences(newReferences)
                        try {
                            let parsedNewReferences = refInput.parseReferences(newReferences.trim())
                            log('setRefs', refInput.displayableReferences(parsedNewReferences))
                            setErrored(false)

                            // If some references have only a book name, i.e. no chapter number,
                            // ignore the reference until they enter a chapter number (unless allBookNameOnly is true)
                            if (parsedNewReferences.some((ref) => ref.isBBBOnly())) {
                                if (!allowBookNameOnly) {
                                    return
                                }
                                // expand book name reference(s) to have first and last chapter number
                                parsedNewReferences = parsedNewReferences.map((ref) => ref.fullBook())
                            }

                            // save to localStorage
                            if (defaultReferenceId) {
                                const parsedNewReferencesString = JSON.stringify(parsedNewReferences)
                                log('setDefaultReference', parsedNewReferencesString)
                                refInput.setDefault(defaultReferenceId, parsedNewReferencesString)
                            }

                            setInputReferences(parsedNewReferences)
                        } catch (error) {
                            setErrored(true)
                        }
                    }}
                    tooltip={t('Show book name suggestions')}
                    autoFocus={autoFocus}
                />
            </div>
        </div>
    )
}

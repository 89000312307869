import { Component } from 'react'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import { t } from 'i18next'

import { RootContext } from '../../app/RootContext'
import TextInput from '../../utils/TextInput'

import { ProjectStage } from '../../../models3/ProjectStage'
import { Root } from '../../../models3/Root'

interface IProjectTaskAdder {
    buttonClassName: string
    addTask: (name: string) => void
    stage: ProjectStage
}

class ProjectTaskAdder extends Component<IProjectTaskAdder> {
    // eslint-disable-next-line react/static-property-placement
    static contextType = RootContext

    @observable adding = false

    validate = (value: string) => {
        value = value.trim()
        const { stage } = this.props
        if (stage.tasks.find((task) => task.name === value)) {
            return t('Duplicate name')
        }
        if (value === '') {
            return t('Empty name')
        }
        return ''
    }

    onCancel = () => (this.adding = false)

    onEnter = (newTaskName: string) => {
        const { addTask } = this.props

        if (!this.validate(newTaskName)) {
            addTask(newTaskName)
        }
        this.adding = false
    }

    render() {
        const rt: Root = this.context
        if (!rt) return null

        const { validate } = this

        const _addTask = t('Add task.')

        const { buttonClassName } = this.props
        if (this.adding) {
            return (
                <div className="project-task-adder">
                    <TextInput
                        placeholder={_addTask}
                        message={t('Type Enter to add new task or Esc to cancel.')}
                        validate={validate}
                        _onEscape={this.onCancel}
                        _onEnter={this.onEnter}
                    />
                </div>
            )
        }

        return (
            <div
                onClick={() => (this.adding = true)}
                className={buttonClassName}
                data-toggle="tooltip"
                title={_addTask}
            >
                <i className="far fa-fw fa-plus-square" />
            </div>
        )
    }
}

export default observer(ProjectTaskAdder)

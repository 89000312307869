/* eslint-disable react/no-array-index-key */

import React, { Component } from 'react'
import { observable } from 'mobx'
import { observer } from 'mobx-react'

import GlossView from './GlossView'
import GlossEditor from './GlossEditor'

import { displayError } from '../utils/Errors'
import { ZoomInTimelineButton, ZoomOutTimelineButton } from '../utils/Buttons'

import { Passage } from '../../models3/Passage'
import { IDrawablePassageGloss } from '../../models3/PassageGloss'
import { PassageVideo } from '../../models3/PassageVideo'
import { Project } from '../../models3/Project'

import './Gloss.css'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:GlossBar')

interface IGlossBar {
    w: number
    setTermId: (termId: string) => void
    glossBarDisplay: IGlossBarDisplay
}

/*
tooltip for play button

adjust scale/font size
record video gloss
setStartIndex - use binary search to find start position in loop
 */

interface IGlossBarDisplay {
    glossScale: number
    setGlossScale: (scale: number) => void
    passage: Passage | null
    passageVideo: PassageVideo | null
    project: Project
    currentTime: number
    iAmInterpreter: boolean
    drawableGloss: IDrawablePassageGloss | null
    setDrawableGloss: (gloss: IDrawablePassageGloss | null) => void
    play: (startTime?: number, endPosition?: number, resetTime?: number) => void
    stop: () => void
    resetCurrentTime: (newTime: number) => void
}

class GlossBar extends Component<IGlossBar> {
    drawables: IDrawablePassageGloss[] = []

    @observable glossIndex = -1 // index of passageGloss in drawables, -1 if none selected

    constructor(props: IGlossBar) {
        super(props)

        // this.element = React.createRef()
        this.onDelete = this.onDelete.bind(this)
        this.onDone = this.onDone.bind(this)
    }

    async onDelete() {
        const { glossBarDisplay } = this.props
        const { passage, passageVideo, drawableGloss } = glossBarDisplay
        if (!passage || !passageVideo || !drawableGloss) return

        const { onDone } = this

        const containingVideo = drawableGloss.gloss.toVideo(passage)
        if (!containingVideo) return

        onDone()

        containingVideo.removeGloss(drawableGloss.gloss._id).catch(displayError)
    }

    onDone() {
        const { glossBarDisplay } = this.props
        const { setDrawableGloss } = glossBarDisplay
        this.glossIndex = -1
        setDrawableGloss(null)
    }

    setGlossIndex = (index: number) => {
        const { glossBarDisplay } = this.props
        const { setDrawableGloss, play } = glossBarDisplay

        if (index < 0) {
            setDrawableGloss(null)
        } else {
            setDrawableGloss(this.drawables[index])
        }
        this.glossIndex = index

        const { time, duration } = this.drawables[index]
        log(`setGlossIndex index=${index}, time=${time.toFixed(1)}, duration=${duration.toFixed(1)}`)
        play(time, time + duration, time)
    }

    // When the passageVideo changes, update the list of drawable glosses.
    // Drawable glosses are those that are not covered by later patches.
    // The x values must be recomputed when the glossScale changes.
    getDrawables() {
        const { glossBarDisplay } = this.props
        const { passage, passageVideo } = glossBarDisplay

        this.drawables = []
        const { drawables } = this

        if (!passage || !passageVideo) return []

        const glosses = passageVideo.getVisibleGlosses(passage)

        for (let i = 0; i < glosses.length; ++i) {
            const gloss = glosses[i]
            const x = this.timeToX(gloss.time)
            const width = 100
            const duration = gloss.duration(passage, passageVideo)
            const { time } = gloss

            drawables.push({ x, duration, width, time, gloss })

            if (i > 0) {
                drawables[i - 1].width = drawables[i].x - drawables[i - 1].x
            }
        }

        // let dbg = (dr: IDrawablePassageGloss) => ({
        //     x: dr.x.toFixed(0),
        //     duration: dr.duration.toFixed(0),
        //     text: dr.gloss.text,
        //     position: dr.gloss.position.toFixed(2)
        // })

        // log('drawables', JSON.stringify(drawables.map(dbg), null, 4))

        return drawables
    }

    timeToX(time: number) {
        const { glossBarDisplay, w } = this.props

        return 2 * time * glossBarDisplay.glossScale + w / 2 + 100
    }

    render() {
        const { glossBarDisplay, setTermId } = this.props
        const {
            passage,
            passageVideo,
            currentTime,
            iAmInterpreter,
            drawableGloss,
            glossScale,
            setGlossScale,
            play,
            stop,
            resetCurrentTime,
            project
        } = glossBarDisplay
        if (!passage || !passageVideo) return null

        const { w } = this.props

        // access _rev forces redraw when anything about passage video changes
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { _rev } = passageVideo

        const drawables = this.getDrawables()

        const { glossIndex, onDelete, setGlossIndex, onDone } = this

        const xCurrent = this.timeToX(currentTime)

        const xMin = xCurrent - w / 2
        const xMax = xCurrent + w / 2 - 60

        let start = drawables.findIndex((dr) => dr.x > xMin)
        if (start === -1) {
            start = drawables.length
        }

        let end = start
        for (; end < drawables.length && drawables[end].x < xMax; ++end) {
            //
        }

        // log(`xMin ${xMin}, start=${start}, end=${end}`)

        const _drawables = drawables.slice(start, end)

        const width = _drawables.length ? _drawables[0].x - xMin : 100
        const glossesAreaWidth = w - 22 // Leave room for scale adjuster buttons

        const editGloss = !!passageVideo && glossIndex >= 0 && !!iAmInterpreter

        const enabled = !passage?.videoBeingCompressed
        const zoomInEnabled = enabled && drawables.length > 0
        const zoomOutEnabled = enabled && drawables.length > 0

        return (
            <div className="gloss-bar">
                <div>
                    <div style={{ left: w / 2 }} className="gloss-cursor">
                        v
                    </div>
                    <div
                        className="gloss-glosses"
                        style={{ width: glossesAreaWidth, display: 'inline-block', verticalAlign: '25px' }}
                    >
                        <div style={{ width, display: 'inline-block' }} />
                        {_drawables.map((dr, i) => {
                            return (
                                <GlossView
                                    setTermId={setTermId}
                                    project={project}
                                    onClick={() => enabled && this.setGlossIndex(start + i)}
                                    key={i}
                                    gloss={dr.gloss}
                                    width={dr.width}
                                    focused={start + i === glossIndex}
                                    last={i === drawables.length - 1}
                                />
                            )
                        })}
                    </div>
                    <div className="gloss-scale-container">
                        <div>
                            <ZoomInTimelineButton
                                enabled={zoomInEnabled}
                                onClick={() => {
                                    setGlossScale(1.1 * glossScale)
                                    this.getDrawables()
                                }}
                            />
                        </div>
                        <div>
                            <ZoomOutTimelineButton
                                enabled={zoomOutEnabled}
                                onClick={() => {
                                    setGlossScale(0.9 * glossScale)
                                    this.getDrawables()
                                }}
                            />
                        </div>
                    </div>
                </div>
                {editGloss && (
                    <GlossEditor
                        {...{
                            passage,
                            passageVideo,
                            drawableGloss,
                            play,
                            stop,
                            resetCurrentTime,
                            drawables,
                            glossIndex,
                            setGlossIndex,
                            onDelete,
                            onDone,
                            project
                        }}
                    />
                )}
            </div>
        )
    }
}

export default observer(GlossBar)

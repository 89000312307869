/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-cycle */
/* eslint-disable max-classes-per-file */

import { delay } from 'q'
import API from './API'
import { fetchBlob2, IReportProgress } from './API2'
import { VideoCache } from './VideoCache'
import { Root } from './Root'
import { IDateCreator, SLTTDateCreator } from './DateUtilities'
import { canAccessInternet } from '../components/app/OnlineStatusContext'
import { isProjectRestoreInProgress } from '../components/utils/Helpers'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:VideoCacheRecord')
// eslint-disable-next-line @typescript-eslint/no-var-requires
const debug = require('debug')('slttdbg:VideoCacheRecord')

// A VideoBlob is a video file or a section of a video file.
// Sections are uploaded individually to S3 then concatenated to reform original file.

export class VideoBlob {
    constructor(public _id: string, public blob?: Blob) {
        if (!this._id) throw Error('No _id')
    }

    async saveToDB() {
        if (!this.blob) throw Error('No blob to save')
        if (isProjectRestoreInProgress()) {
            return
        }
        await VideoCache._db.put(VideoCache.VIDEOBLOBS, { _id: this._id, blob: this.blob })
    }

    async loadFromDB(mustBePresent?: boolean) {
        const doc = await VideoCache._db.get(VideoCache.VIDEOBLOBS, this._id)
        if (!doc || !doc.blob) {
            if (mustBePresent) {
                throw Error(`not present in VIDEOBLOBS ${this._id}`)
            }
            this.blob = undefined
            return
        }

        this.blob = doc.blob
    }
}

export class VideoCacheRecord {
    static systemError = (err: any) => {
        throw Error(err)
    }

    static _count = 0

    public _id = ''

    public size = 0

    // When this array is non-empty, the video has been broken into segments.
    // videBlobId(i) names the segments starting with index 0.
    // The i'th entry is true iff the corresponding segment blob has been uploaded.
    public uploadeds: boolean[] = []

    public downloadeds: boolean[] = []

    public downloadErrors: string[] = []

    // Show what has been uploaded so far.
    // If the video is being uploaded from another computer we don't know for sure.
    public get uploadedBlobs() {
        // uploadeds is non-empty iff video uploaded from current computer,
        // we know what is uploaded so far
        if (this.uploadeds.length > 0) return [...this.uploadeds]

        // Video was uploaded from another computer, start by guessing everything uploaded
        const uploadeds = Array(this.downloadeds.length).fill(true)

        // If no record is currently showing with a 403 error assume (for now)
        // that everything has been successfully uploaded at the other end
        const i = this.downloadErrors.indexOf('403 Forbidden')
        if (i < 0) return uploadeds

        // We found a 403, assume everything from that point on has not been uploaded yet
        uploadeds.fill(false, i, uploadeds.length)
        return uploadeds
    }

    public downloadMessage = ''

    public accessDate = ''

    public uploadStartTimeMs = -1

    public uploadFinishTimeMs = -1

    public path = ''

    private token = ''

    public serverUrl = ''

    // If the download/upload for this video failed, the error reason is here.
    public error = ''

    private static cache = new Map<string, VideoCacheRecord>()

    static updateCache(vcr: VideoCacheRecord) {
        const { cache } = VideoCacheRecord
        cache.set(vcr._id, vcr)
    }

    static get(_id: string) {
        const { cache } = VideoCacheRecord
        let vcr = cache.get(_id)
        if (vcr) return vcr

        /* Sigh, there was a bad error for 2 months starting mid June 2020
         * when creating url fields for PassaageNoteItem.
         * the project name, which is the first thing in the url, was left empty.
         * Add a check to see if the video was cached under the no project name url.
         * This avoids needing to redownload the video which may be slow.
         */

        const parts = _id.split('/')
        const _idNoProjectName = `/${parts.slice(1).join('/')}`
        vcr = cache.get(_idNoProjectName)
        if (vcr) {
            log('!!!cache HIT _idNoProjectName')
            return vcr
        }

        // No cache entry for this video, create one
        vcr = new VideoCacheRecord({ _id })
        cache.set(_id, vcr)
        return vcr
    }

    // Normally you should not use this.
    // Use static get() above so there is a cached copy
    constructor(doc: any) {
        if (!doc._id) throw Error('No _id') // must at least have _id
        this.downloadErrors = new Array(this.seqNum(doc._id)).fill('')

        this.clone(doc)
    }

    dbg() {
        const { _id, uploadeds, downloadeds, error } = this
        return { _id, uploadeds, downloadeds, error }
    }

    // WARNING!!! if new attributes added to this class, they MUST be added here
    // or constructor will not work correctly.
    private clone(doc: any) {
        this._id = doc._id
        this.size = doc.size || 0
        // this.blobsCount = doc.blobsCount || 0
        this.uploadeds = doc.uploadeds || []
        this.downloadeds = doc.downloadeds || []
        this.accessDate = doc.accessDate || ''
        this.error = doc.error || ''
        this.downloadMessage = doc.downloadMessage || ''

        this.uploadStartTimeMs = doc.uploadStartTimeMs || -1
        this.uploadFinishTimeMs = doc.uploadFinishTimeMs || -1
    }

    get downloadProgress() {
        if (this.downloadeds.length === 0) return 100

        const downloadedBlobs = this.downloadeds.filter((x) => x).length
        return Math.floor((100 * downloadedBlobs) / this.downloadeds.length + 0.5)
    }

    // The video data is present in cache if download is complete OR
    // it was generated locally
    get hasBeenCached() {
        return (
            this.downloaded /* generated remotely and downloaded */ || this.uploadeds.length
        ) /* generated locally so started out in cache */
    }

    // All blobs for this video have been downloaded from S3
    get downloaded() {
        return this.downloadeds.length && this.downloadeds.every((d) => d)
    }

    // All blobs for this locally generated video have been uploaded to S3
    get uploaded() {
        return this.uploadeds.length > 0 && this.uploadeds.every((d) => d)
    }

    // Some blobs for this VCR need uploaded
    get needsUploaded() {
        return this.uploadeds.length > 0 && !this.uploadeds.every((d) => d)
    }

    // If video did not originate on this computer, this is partly a guess
    get numberUploaded() {
        return this.uploadedBlobs.filter(Boolean).length
    }

    get isLocallyCreatedVideo() {
        return this.uploadeds.length > 0
    }

    get numberDownloaded() {
        // If locally created they are by definition already downloaded
        if (this.isLocallyCreatedVideo) return this.totalBlobs

        return this.downloadeds.filter(Boolean).length
    }

    get totalBlobs() {
        const { _id } = this
        // _id is similar to: TESTnm/200829_201205/210519_153942/210519_153943-9
        // The -9 is the blob count.

        try {
            return parseInt(_id.slice(_id.lastIndexOf('-') + 1))
        } catch (err) {
            throw Error(`Video _id missing blob count ${_id}`)
        }
    }

    // Id without the project and sequence number
    get docId() {
        const { _id } = this
        return _id.slice(_id.indexOf('/') + 1, _id.lastIndexOf('-'))
    }

    findPortion(rt: Root) {
        return rt.project.findPortion(this.docId)
    }

    findPassage(rt: Root) {
        return rt.project.findPassage(this.docId)
    }

    findNote(rt: Root) {
        return this.findPassage(rt)?.findNote(this.docId)
    }

    findVideo(rt: Root) {
        return this.findPassage(rt)?.findVideo(this.docId)
    }

    /**
     * Setup to download the s3 items for this video, if this has not already been done
     */
    async setupDownload() {
        if (!this.downloadeds || !this.downloadeds.length) {
            this.downloadeds = new Array(this.seqNum(this._id)).fill(false)
            await this.saveToDB()
        }
    }

    /**
     * Access this cache record. This will make it less likely
     * that it is deleted from cache when more space is needed.
     */
    async touch() {
        const dateCreator = VideoCacheRecord.getDateCreator()
        this.accessDate = dateCreator.getDate()
        await this.saveToDB()
    }

    static getDateCreator(): IDateCreator {
        return new SLTTDateCreator()
    }

    async resetError() {
        if (this.error) {
            this.error = ''
            await this.saveToDB()
        }
    }

    async setError(_id: string, error: string) {
        log(`setError ${error}, ${_id}`)

        this.error = error
        await this.saveToDB()
    }

    async setInvalidSeqNumError(_id: string) {
        this.error = `BUG: Invalid sequence number ${_id}`
        await this.saveToDB()
    }

    // should not throw due to network errors
    async uploadToServer() {
        const { uploadeds } = this
        await this.resetError()

        for (let i = 0; i < uploadeds.length; ++i) {
            // If VideoBlob already uploaded, skip it
            if (uploadeds[i]) continue

            const successful = await this.uploadOneBlobToS3(this.videoBlobId(i)) // should not throw due to network errors
            if (successful) {
                uploadeds[i] = true
                await this.saveToDB()
            } else {
                break
            }
        }
    }

    async saveUploadStartTime() {
        this.uploadStartTimeMs = Date.now() // current time in ms
        await this.saveToDB()
    }

    async saveUploadFinishTime() {
        this.uploadFinishTimeMs = Date.now() // current time in ms
        await this.saveToDB()
    }

    async saveUploadRequest() {
        this.path = VideoCacheRecord.baseUrl(this._id)
        this.token = API.id_token
        this.serverUrl = API.getHostUrl()
        await this.saveToDB()
    }

    async sanitize() {
        this.token = ''
        this.serverUrl = ''
        await this.saveToDB()
    }

    /* ===== Uploader Routines ===== */
    // Upload a single cached blob to server
    // Return true if successful.
    // Network related error should not cause a throw.
    private async uploadOneBlobToS3(_id: string) {
        log(`uploadToS3 start [${_id}]`)

        const projectName = this.projectName()
        const seqNum = this.seqNum(_id)
        if (seqNum === -1) {
            await this.setInvalidSeqNumError(_id)
            return false
        }

        const videoBlob = new VideoBlob(_id)
        try {
            await videoBlob.loadFromDB(true)
        } catch (error) {
            this.error = `BUG: loadFromDB failed ${_id}`
            this.uploadeds = []
            await this.saveToDB()
            return false
        }

        if (!videoBlob.blob || !videoBlob.blob.type || !videoBlob.blob.size) {
            this.error = `BUG: Invalid blob ${_id} size: ${videoBlob.blob?.size} type: ${videoBlob.blob?.type}`
            this.uploadeds = []
            await this.saveToDB()
            return false
        }

        let retryLimit = 5
        let error
        while (retryLimit > 0) {
            try {
                await API.pushBlob(projectName, VideoCacheRecord.baseUrl(_id), seqNum, videoBlob.blob)
                log(`uploadToS3 done [${_id}]`)
                await this.resetError()
                return true
            } catch (err) {
                error = err as Error
                log(`uploadToS3 error`, error)
                await this.setError(_id, error.message)
                await delay(2000)
                --retryLimit
                // TODO: Exponential back off?
            }
        }

        if (error && (await canAccessInternet('uploadOneBlobToS3 error'))) {
            // must not have been a network related error
            throw Error(error.message)
        }

        return false
    }

    uploadRate() {
        const { uploadStartTimeMs, uploadFinishTimeMs, size } = this
        if (uploadStartTimeMs === -1 || uploadFinishTimeMs === -1) {
            return 0
        }
        return size / 1024 / ((uploadFinishTimeMs - uploadStartTimeMs + 1) / 1000) // KB/s
    }

    /* ===== Downloader Routines ===== */

    /* Fetch the j'th (0 index) blob for this videos.
     * Return emoty string on success, otherwise error message.
     * Sets downloades[j] on success
     * Do NOT throw an exception
     * */
    async fetchBlob(j: number, reportProgress: IReportProgress): Promise<string> {
        const url = this.videoBlobId(j)
        log('fetchBlob', url)

        const vb = new VideoBlob(url)
        await vb.loadFromDB()
        if (vb.blob) {
            // Someone got here first and already downloaded this
            this.downloadeds[j] = true
            await this.saveToDB()
            return ''
        }

        const [error, blob] = await this._fetchBlob(url, reportProgress)
        if (error) {
            const { message } = error
            this.downloadErrors[j] = message
            return message
        }

        const videoBlob = new VideoBlob(url, blob)

        try {
            await videoBlob.saveToDB()
        } catch (errorSaveToDB) {
            log(`fetchBlob videoBlob.saveToDB failed`, errorSaveToDB)
            return 'videoBlob.saveToDB failed'
        }

        this.size += blob.size
        this.downloadeds[j] = true
        this.downloadErrors[j] = ''
        log(`fetchBlob [${url}] succeeded`, this.downloadeds)
        await this.saveToDB()

        return ''
    }

    async _fetchBlob(_id: string, reportProgress: IReportProgress): Promise<[Error | null, Blob]> {
        debug(`_fetchBlob ${_id}`)

        const [error1, signedUrl] = await API._getUrl(this.projectName(), _id)

        if (error1) return [error1, new Blob()]

        let blob = new Blob()

        try {
            blob = await fetchBlob2(signedUrl, reportProgress)
        } catch (error2) {
            return [error2 as Error, blob]
        }

        return [null, blob]
    }

    async setDownloadMessage(message: string) {
        debug(`setDownloadMessage ${message}`)

        this.downloadMessage = message
        // Dont do this, causes too many DB writes and we don't really need to persist
        // await this.saveToDB()
    }

    // Extract the sequence number (-n) at end of id
    seqNum(_id?: string) {
        const id = _id || this._id
        const i = id.lastIndexOf('-')
        if (i === -1) {
            return -1
        }
        return parseInt(id.slice(i + 1))
    }

    // Get the id without the sequence number
    static baseUrl(_id: string) {
        const i = _id.lastIndexOf('-')
        if (i === -1) {
            throw Error(`no seqnum in _id [${_id}]`)
        }
        return _id.slice(0, i)
    }

    // Return the id of the i'th blob (0 origin)
    videoBlobId(i: number) {
        return `${VideoCacheRecord.baseUrl(this._id)}-${i + 1}`
    }

    projectName() {
        return this._id.split('/')[0]
    }

    async saveToDB(tag?: string) {
        if (tag) {
            log(tag, JSON.stringify(this, null, 4))
        }

        try {
            if (isProjectRestoreInProgress()) {
                return
            }
            await VideoCache._db.put(VideoCache.CACHEDVIDEOS, this)
            VideoCacheRecord.updateCache(this)
        } catch (err) {
            const error = err as Error
            this.error = error.message
        }
    }

    /**
     * @return {boolean} true iff a record with this id is already present in DB
     */
    async loadFromDB(tag?: string) {
        const doc = await VideoCache._db.get(VideoCache.CACHEDVIDEOS, this._id)
        if (doc) {
            if (tag) {
                log(`loadFromDB [${tag}] ${this._id}`, doc)
            }
            this.clone(doc)
        } else {
            if (tag) {
                log(`loadFromDB ${this._id} [not present yet]`)
            }
            return false
        }

        return true
    }

    // Delete cached blobs and reset state of cache entry to not downloaded.
    // This frees up space in our disk quota.
    async deleteBlobs() {
        if (isProjectRestoreInProgress()) {
            return
        }
        const { _id } = this

        for (let i = 0; i < this.seqNum(_id); ++i) {
            await VideoCache._db.delete(VideoCache.VIDEOBLOBS, this.videoBlobId(i))
        }

        this.uploadeds = []
        this.downloadeds = []
        await this.saveToDB()
    }

    // Add a blob to be uploaded and save blob in database
    async addBlob(blob: Blob) {
        this.uploadeds.push(false)
        this.size += blob.size

        const id = this.videoBlobId(this.uploadeds.length - 1)
        debug('addBlob', id, blob.size)

        const videoBlob = new VideoBlob(id, blob)
        await videoBlob.saveToDB()
    }

    // Read and concatentate individual blobs to create complete video blob
    async getVideoBlob(_type?: string): Promise<Blob | null> {
        const { _id } = this
        const blobs: Blob[] = []

        this.touch()

        const seqNum = this.seqNum(_id)
        if (seqNum <= 0) throw Error(`no seq number ${_id}`)

        let failed = false

        for (let i = 0; i < seqNum; ++i) {
            const videoBlob = new VideoBlob(this.videoBlobId(i))
            await videoBlob.loadFromDB(false)
            if (videoBlob.blob) {
                blobs.push(videoBlob.blob)
            } else {
                // Something has gone wrong, blob is not present in cache
                this.downloadeds[i] = false
                failed = true
            }
        }

        if (failed) {
            await this.saveToDB(`### must re-download video blob: ${this._id}`)
            return null
        }

        return new Blob(blobs, { type: _type || blobs[0].type })
    }

    // ---- Utilities for unit testing ----

    async delete() {
        if (isProjectRestoreInProgress()) {
            return
        }
        await this.deleteBlobs()
        await VideoCache._db.delete(VideoCache.CACHEDVIDEOS, this._id)
    }
}

// For cypress testing, write a file with a name like '__video1.mp4' to cache

async function _writeTestFileToCache_(file: File) {
    const _id = `${file.name.slice(2, -4)}-1`
    log('writeTestFileToCache', _id)

    const vcr = VideoCacheRecord.get(_id)
    await vcr.deleteBlobs() // if existing video data for this file, remove it
    vcr.size = file.size
    await vcr.addBlob(file)

    log('!!!writeTestFileToCache DONE', _id)
}

const _window: any = window
_window._writeTestFileToCache_ = _writeTestFileToCache_
_window.VideoCacheRecord = VideoCacheRecord

// console.log(await window.VideoCacheRecord.get('TESTnm/200829_201205/210519_194355/210519_194356-9'))

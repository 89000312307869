import { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { DEFAULT_AUDIO_ENCODE_TYPE } from '../utils/DownloadPassage'
import { exportToFile, safeFileName } from '../utils/Helpers'

import { Root } from '../../models3/Root'

import { ExportItem, generateZip, getExportItem } from './Export'
import {
    AudioEncodingSwitch,
    CombineToggle,
    ExportModalFooter,
    ExportResultsPage,
    SecondsOfSilence
} from './ExportModal'

import { HelpLinkWithMessage } from '../utils/Buttons'
import { Portion } from '../../models3/Portion'

import './ExportModal.css'

interface ExportPortionsModalProps {
    rt: Root
    setOpen: (value: boolean) => void
}

export const ExportPortionsModal = ({ rt, setOpen }: ExportPortionsModalProps) => {
    const { t } = useTranslation()
    const [audioEncodeType, setAudioEncodeType] = useState(DEFAULT_AUDIO_ENCODE_TYPE)
    const [combinePassages, setCombinePassages] = useState(false)
    const [secondsOfSilence, setSecondsOfSilence] = useState(1.0)
    const [showResultsPage, setShowResultsPage] = useState(false)
    const [exportInProgress, setExportInProgress] = useState(false)
    const [error, setError] = useState('')
    const [isFileToDownload, setIsFileToDownload] = useState(true)
    const [isMissingRecordings, setIsMissingRecordings] = useState(false)

    const { project } = rt

    const audioSuffix = `-audio.${audioEncodeType}`
    const transcriptionSuffix = '-transcription.txt'

    const safePortionName = (portion: Portion) => safeFileName(portion.name)

    const exportPortions = async () => {
        try {
            const dataPromises: Promise<ExportItem>[] = []
            project.portions.forEach((portion) => {
                if (
                    !portion.passages.length ||
                    !portion.passages.some((passage) => passage.videosNotDeleted.length > 0)
                ) {
                    return
                }

                if (combinePassages) {
                    const portionName = safePortionName(portion)
                    dataPromises.push(
                        getExportItem({
                            portion,
                            inputParams: {
                                transcription: { path: `${portionName}${transcriptionSuffix}`, project },
                                recording: { path: `${portionName}${audioSuffix}`, secondsOfSilence, audioEncodeType }
                            }
                        })
                    )
                } else {
                    portion.passages.forEach((passage) => {
                        const passageVideos = passage.videosNotDeleted
                        if (!passageVideos.length) {
                            return
                        }

                        const latestAudio = passageVideos.slice(-1)[0]
                        const passageName = `${safePortionName(portion)}/${safeFileName(passage.name)}`
                        dataPromises.push(
                            getExportItem({
                                passage,
                                passageVideo: latestAudio,
                                inputParams: {
                                    recording: { path: `${passageName}${audioSuffix}`, audioEncodeType },
                                    transcription: { path: `${passageName}${transcriptionSuffix}`, project }
                                }
                            })
                        )
                    })
                }
            })

            const data = await Promise.all(dataPromises)
            setIsMissingRecordings(data.some((item) => item.recording?.isMissingParts))
            const zipFile = await generateZip(data)
            if (!zipFile) {
                setIsFileToDownload(false)
                setExportInProgress(false)
                return
            }
            exportToFile(zipFile, `AVTT-${project.displayName}-portions`, '.zip')
            setExportInProgress(false)
        } catch (err) {
            setError(`${t('Error')}: ${err}`)
        }
    }

    return (
        <Modal show onHide={() => setOpen(false)} backdrop="static">
            <Modal.Header closeButton>
                <h3>
                    {t('exportPortions', { projectName: project.displayName })}
                    <HelpLinkWithMessage id="portions.html#export-portions" />
                </h3>
            </Modal.Header>
            {showResultsPage && (
                <ExportResultsPage
                    closeModal={() => setOpen(false)}
                    error={error}
                    loading={exportInProgress}
                    isFileToDownload={isFileToDownload}
                    isMissingRecordings={isMissingRecordings}
                />
            )}
            {!showResultsPage && (
                <>
                    <Modal.Body>
                        <AudioEncodingSwitch setAudioEncodeType={setAudioEncodeType} />
                        <CombineToggle toggle={combinePassages} setToggle={setCombinePassages} />
                        {combinePassages && (
                            <SecondsOfSilence
                                secondsOfSilence={secondsOfSilence}
                                setSecondsOfSilence={setSecondsOfSilence}
                            />
                        )}
                    </Modal.Body>
                    <ExportModalFooter
                        onOK={() => {
                            setExportInProgress(true)
                            setShowResultsPage(true)
                            exportPortions()
                        }}
                        onCancel={() => setOpen(false)}
                    />
                </>
            )}
        </Modal>
    )
}

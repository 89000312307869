/* eslint-disable react/prefer-stateless-function */

import React, { Component } from 'react'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import {
    Slider,
    Handles,
    SliderItem,
    GetHandleProps,
    Tracks,
    Rail,
    GetEventData,
    SliderModeValue
} from 'react-compound-slider'
import _ from 'underscore'

import { limit } from '../../models3/Utils'

import './VideoTimeline.css'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:VideoTimeline')

interface IVideoTimeline {
    setters: PositionSetter[]
    domainStartPosition: number
    domainEndPosition: number
    adjustTime: (newTime: number) => void
    allowAdjustingPositions: boolean
    enabled: boolean
}

interface IClickableRail {
    onMouseDown: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
    onMouseMove: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
    onMouseUp: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

const ClickableRail: React.FunctionComponent<IClickableRail> = ({ onMouseDown, onMouseMove, onMouseUp }) => {
    return (
        <div className="timeline-rail-outer" onMouseUp={onMouseUp} onMouseDown={onMouseDown} onMouseMove={onMouseMove}>
            <div className="timeline-rail" />
        </div>
    )
}
class Handle extends Component<IEditableHandle> {
    render() {
        const { enabled, handle, getHandleProps } = this.props
        const { onKeyDown, onMouseDown, onTouchStart } = getHandleProps(handle.id)
        return enabled ? (
            <div
                className="marker-handle clickable note-handle"
                style={{
                    left: `${handle.percent}%`,
                    color: enabled ? 'green' : 'gray'
                }}
                onKeyDown={onKeyDown}
                onMouseDown={onMouseDown}
                onTouchStart={onTouchStart}
            >
                <i className="fas fa-fw fa-circle" />
            </div>
        ) : (
            <div
                className="marker-handle note-handle"
                style={{
                    left: `${handle.percent}%`,
                    color: 'gray'
                }}
            >
                <i className="fas fa-fw fa-circle" />
            </div>
        )
    }
}

interface IEditableHandle {
    handle: SliderItem
    enabled: boolean
    getHandleProps: GetHandleProps
}

class LeftMarkerHandle extends Component<IEditableHandle> {
    render() {
        const { enabled, handle, getHandleProps } = this.props
        const { onKeyDown, onMouseDown, onTouchStart } = getHandleProps(handle.id)

        return enabled ? (
            <div
                className="marker-handle clickable arrow-handle"
                style={{
                    left: `${handle.percent}%`,
                    color: '#333333'
                }}
                onKeyDown={onKeyDown}
                onMouseDown={onMouseDown}
                onTouchStart={onTouchStart}
            >
                <i className="fas fa-fw fa-caret-left fa-flip-horizontal" />
            </div>
        ) : (
            <div
                className="marker-handle arrow-handle"
                style={{
                    left: `${handle.percent}%`,
                    color: 'gray'
                }}
            >
                <i className="fas fa-fw fa-caret-left fa-flip-horizontal" />
            </div>
        )
    }
}

class RightMarkerHandle extends Component<IEditableHandle> {
    render() {
        const { enabled, getHandleProps, handle } = this.props
        const { onKeyDown, onMouseDown, onTouchStart } = getHandleProps(handle.id)

        return enabled ? (
            <div
                className="marker-handle clickable arrow-handle"
                style={{
                    left: `${handle.percent}%`,
                    color: '#333333'
                }}
                onKeyDown={onKeyDown}
                onMouseDown={onMouseDown}
                onTouchStart={onTouchStart}
            >
                <i className="fas fa-fw fa-caret-left" />
            </div>
        ) : (
            <div
                className="marker-handle arrow-handle"
                style={{
                    left: `${handle.percent}%`,
                    color: 'gray'
                }}
            >
                <i className="fas fa-fw fa-caret-left" />
            </div>
        )
    }
}

interface ITimeCursorHandle {
    handle: SliderItem
    getHandleProps: GetHandleProps
    onMouseUp: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
    enabled: boolean
}

class TimeCursorHandle extends Component<ITimeCursorHandle> {
    render() {
        const { getHandleProps, handle, enabled, onMouseUp } = this.props
        const { onKeyDown, onMouseDown, onTouchStart } = getHandleProps(handle.id)

        return (
            <div
                className={`marker-handle ${enabled && 'clickable'} time-cursor-handle`}
                style={{
                    left: `${handle.percent}%`
                }}
                onMouseUp={onMouseUp}
                onKeyDown={onKeyDown}
                onMouseDown={onMouseDown}
                onTouchStart={onTouchStart}
            />
        )
    }
}

interface ITrack {
    source: SliderItem
    target: SliderItem
}

class Track extends Component<ITrack> {
    render() {
        const { source, target } = this.props
        return (
            <div
                style={{
                    left: `${source.percent}%`,
                    width: `${target.percent - source.percent}%`
                }}
            />
        )
    }
}

export class PositionSetter {
    @observable public value = 0

    constructor(
        public key: string,
        public getLowLimit: () => number,
        public getHighLimit: () => number,
        public onSetValue: (value: number) => void
    ) {}

    setValue(_value: number) {
        const lowLimit = this.getLowLimit()
        const highLimit = this.getHighLimit()

        const value = limit(_value, lowLimit, highLimit)
        const changed = Math.abs(value - this.value) > 0.001

        // log(`setValue[${this.key}/${changed}] ${fx(lowLimit)}> ${fx(_value)} <${fx(highLimit)} `)

        if (!changed) {
            return value
        }

        this.value = value
        this.onSetValue(value)

        return value
    }
}

@observer
export class VideoTimeline extends Component<IVideoTimeline> {
    mouseDown = false

    step = 0.01

    mode = (curr: SliderModeValue[], next: SliderModeValue[]) => {
        const { setters } = this.props

        // We assume that next always has 4 values and that when sorted
        // by key they are startPosition, position, endPosition, time

        // ensure values in ascending order by key so we can use a fixed index
        const _next = _.sortBy([...next], 'key')
        log(
            '_next[pre]',
            _next.map((x) => x.val.toFixed(2))
        )

        if (_next.length === 1) {
            setters[0].setValue(_next[0].val) // set currentTime
            return _next
        }

        setters[3].setValue(_next[3].val) // set currentTime

        // position establishes the limits for startPosition and endPosition
        // so set it first
        _next[1].val = setters[1].setValue(_next[1].val)

        // Set start and end position
        _next[0].val = setters[0].setValue(_next[0].val)
        _next[2].val = setters[2].setValue(_next[2].val)

        // Return updated values for cursor positions
        return _next
    }

    onMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, getEventData: GetEventData) => {
        const { adjustTime } = this.props
        log('onMouseDown', getEventData(e).value)
        this.mouseDown = true
        adjustTime(getEventData(e).value)
    }

    onMouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, getEventData: GetEventData) => {
        if (this.mouseDown) {
            const { adjustTime } = this.props
            adjustTime(getEventData(e).value)
        }
    }

    onMouseUp = () => {
        this.mouseDown = false
    }

    render() {
        const { domainStartPosition, domainEndPosition, setters, enabled, allowAdjustingPositions } = this.props
        const { step } = this

        const values = setters.map((setter) => setter.value)
        // log('render', values)

        return (
            <div>
                <Slider
                    className="timeline-slider"
                    domain={[domainStartPosition, domainEndPosition]}
                    step={step}
                    mode={this.mode}
                    values={values}
                    disabled={!enabled}
                >
                    <Rail>
                        {({ getEventData }) => (
                            <ClickableRail
                                onMouseDown={(e) => this.onMouseDown(e, getEventData)}
                                onMouseMove={(e) => this.onMouseMove(e, getEventData)}
                                onMouseUp={this.onMouseUp}
                            />
                        )}
                    </Rail>
                    <Handles>
                        {({ handles, getHandleProps }) => {
                            const leftMarkerHandle = handles.find((e) => e.id === '$$-0')
                            const handle = handles.find((e) => e.id === '$$-1')
                            const rightmarkerHandle = handles.find((e) => e.id === '$$-2')
                            const timeHandle = handles.find((e) => e.id === '$$-3')

                            return (
                                <div>
                                    {leftMarkerHandle && (
                                        <LeftMarkerHandle
                                            key={leftMarkerHandle.id}
                                            handle={leftMarkerHandle}
                                            getHandleProps={getHandleProps}
                                            enabled={enabled && allowAdjustingPositions}
                                        />
                                    )}
                                    {handle && (
                                        <Handle
                                            key={handle.id}
                                            handle={handle}
                                            getHandleProps={getHandleProps}
                                            enabled={enabled && allowAdjustingPositions}
                                        />
                                    )}
                                    {rightmarkerHandle && (
                                        <RightMarkerHandle
                                            key={rightmarkerHandle.id}
                                            handle={rightmarkerHandle}
                                            getHandleProps={getHandleProps}
                                            enabled={enabled && allowAdjustingPositions}
                                        />
                                    )}
                                    {timeHandle && (
                                        <TimeCursorHandle
                                            key={timeHandle.id}
                                            handle={timeHandle}
                                            getHandleProps={getHandleProps}
                                            onMouseUp={this.onMouseUp}
                                            enabled={enabled}
                                        />
                                    )}
                                </div>
                            )
                        }}
                    </Handles>
                    <Tracks left={false} right={false}>
                        {({ tracks }) => (
                            <div>
                                {tracks.map(({ id, source, target }) => (
                                    <Track key={id} source={source} target={target} />
                                ))}
                            </div>
                        )}
                    </Tracks>
                </Slider>
            </div>
        )
    }
}

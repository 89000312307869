import { observable } from 'mobx'
import _ from 'underscore'

import { AudioClip } from './AudioClip'
import { DBObject } from './DBObject'
import { IDB } from './IDB'
import { MediaSlice } from './MediaSlice'
import { Passage } from './Passage'
import { PassageSegmentGloss } from './PassageSegmentGloss'
import { PassageSegmentLabel } from './PassageSegmentLabel'
import { PassageSegmentDocument } from './PassageSegmentDocument'
import { PassageSegmentResource } from './PassageSegmentResource'
import { PassageSegmentTranscription } from './PassageSegmentTranscription'
import { PassageVideo } from './PassageVideo'
import { remove } from './Utils'

import {
    SingleVideoViewableVideoCollection,
    ViewableVideoCollection
} from '../components/video/ViewableVideoCollection'
import { RefRange } from '../scrRefs/RefRange'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:Models')

/* Zero or tiny segments are hard to display and break our ability to seek to the
 * correct segment based on the time ... so don't let segments get too short.
 */
export const MIN_SEGMENT_LENGTH = 0.1

export enum PassageSegmentApproval {
    State0,
    State1,
    State2,
    State3
}

export class PassageSegment extends DBObject {
    // A segment may be replaced by patch.
    // Each patch is a PassageVideo.
    // The _id of the PassageVideo containing the patch is stored here.
    // If a segment has been patched multiple times the latest patch is
    // the last entry in the array.
    @observable videoPatchHistory: string[] = []

    endPosition = 0 // end of segment in containing video

    position = 0 // start of segment in containing video

    @observable approved: PassageSegmentApproval = PassageSegmentApproval.State0

    @observable approvedBy = ''

    @observable approvalDate = ''

    @observable labels: PassageSegmentLabel[] = []

    glosses: PassageSegmentGloss[] = [] // These are glosses for the entire segment

    @observable documents: PassageSegmentDocument[] = []

    @observable audioClips: AudioClip[] = []

    @observable ignoreWhenPlayingVideo = false

    @observable transcriptions: PassageSegmentTranscription[] = []

    // Time offset for this note in main video timeline
    // Set by videoPassage.setupSegmentsAndNotes
    time = 0

    // Human readable form of references for this segment, e.g. Gen 3.1-10; Ex 4.11
    references: RefRange[] = []

    @observable resources: PassageSegmentResource[] = []

    cc = '' // closed caption

    @observable _rev = 0

    constructor(_id: string, db?: IDB) {
        super(_id, db)
        this.setIgnoreWhenPlayingVideo = this.setIgnoreWhenPlayingVideo.bind(this)
    }

    toDocument() {
        const {
            videoPatchHistory,
            ignoreWhenPlayingVideo,
            position,
            endPosition,
            approved,
            approvalDate,
            approvedBy,
            labels,
            cc,
            references,
            glosses
        } = this
        const serializedReferences = JSON.stringify(references)
        return this._toDocument({
            videoPatchHistory,
            ignoreWhenPlayingVideo,
            position,
            endPosition,
            approved,
            approvalDate,
            approvedBy,
            labels,
            cc,
            references: serializedReferences,
            glosses
        })
    }

    toSnapshot() {
        const snapshot = this.toDocument()

        return snapshot
    }

    dbg(passage: Passage | null, details?: string) {
        const doc = this.toDocument()

        doc.time = this.time
        doc.labels = this.labels.map((label) => label.dbg())
        doc.glosses = this.glosses.map((gloss) => gloss.dbg())

        doc.patches = this.videoPatchHistory.map((patchId) => ({
            patchId,
            patchVideo: passage?.findVideo(patchId)?.dbg(passage, details),
            patchSegment: passage?.findVideo(patchId)?.segments[0].dbg(passage, details)
        }))

        return doc
    }

    log(passage: Passage | null, label: string) {
        log(`[PassageSegment] ${label}`, JSON.stringify(this.dbg(passage), null, 4))
    }

    copy() {
        let copy = new PassageSegment(this._id, this.db)
        copy = Object.assign(copy, this)
        copy.labels = this.labels.map((l) => l.copy())
        copy.references = this.references.map((ref) => ref.copy())
        copy.glosses = this.glosses.map((gloss) => gloss.copy())
        copy.videoPatchHistory = Array.from(this.videoPatchHistory)
        copy.documents = this.documents.map((doc) => doc.copy())
        copy.audioClips = this.audioClips.map((clip) => clip.copy())
        copy.resources = this.resources.map((rsc) => rsc.copy())
        copy.transcriptions = this.transcriptions.map((t) => t.copy())
        return copy
    }

    async addVideoPatchToHistory(video: PassageVideo) {
        const { _id, isPatch } = video
        if (!isPatch) {
            throw Error('Video is not a patch')
        }
        if (this.videoPatchHistory.includes(_id)) {
            return
        }

        const doc = this.toDocument()
        doc.videoPatchHistory = [...this.videoPatchHistory, _id]
        await this.db.put(doc)
    }

    async removeVideoPatchFromHistory(video: PassageVideo) {
        const { _id } = video
        const { videoPatchHistory } = this
        const index = videoPatchHistory.findIndex((e) => e === _id)
        if (videoPatchHistory.length <= 0 || index < 0) {
            return
        }
        const doc = this.toDocument()
        doc.videoPatchHistory = videoPatchHistory.filter((e) => e !== _id)
        await this.db.put(doc)
    }

    async setStartPosition(value: number, video: PassageVideo) {
        await this.setPositions(value, null, video)
    }

    async setEndPosition(value: number, video: PassageVideo) {
        await this.setPositions(null, value, video)
    }

    // We have to change both positions in a single update to db otherwise
    // the display looks really odd during the time delay between when we
    // see the result of updating the start position and the result of updating
    // the ending position.
    async setPositions(value: number | null, endValue: number | null, video: PassageVideo) {
        let changed = false
        const doc = this.toDocument()

        const hardStartPosition = this.hardStartPosition(video)
        const hardEndPosition = this.hardEndPosition(video)
        if (hardStartPosition === -1 || hardEndPosition === -1 || this.videoPatchHistory.length > 0) {
            log('### setPositions failed')
            return
        }

        let minEndValue = this.position + MIN_SEGMENT_LENGTH

        if (value !== null) {
            value = Math.max(value, hardStartPosition)
            // Don't let start position get too close to end of segment
            // 0 length segments do not display and mess up our seek by time logic
            value = Math.min(value, hardEndPosition - MIN_SEGMENT_LENGTH)

            // Don't let endPosition get too close to position
            minEndValue = value + MIN_SEGMENT_LENGTH

            if (value !== this.position) {
                changed = true
                doc.position = value
            }
        }

        if (endValue !== null) {
            endValue = Math.max(endValue, minEndValue)
            endValue = Math.min(endValue, hardEndPosition)

            if (endValue !== this.endPosition) {
                changed = true
                doc.endPosition = endValue
            }
        }

        if (!changed) return

        this.db.submitChange(doc)
    }

    isAllowedToDrag(passageVideo: PassageVideo) {
        const { segments } = passageVideo
        const index = segments.indexOf(this)

        // Adjusting the boundaries of patches is complicated. We don't allow
        // users to drag the boundaries of segments that are either in a patch,
        // or are adjacent to a patch.
        const isAllowedToDrag = index > 0 && !this.isPatched && !segments[index - 1]?.isPatched
        return isAllowedToDrag
    }

    canChangePositionToTime(time: number, passageVideo: PassageVideo) {
        const hardStartPosition = this.hardStartPosition2(passageVideo)
        const hardEndPosition = this.hardEndPosition(passageVideo)
        const position = this.timeToPosition(time)
        const tooClose = passageVideo.segments
            .filter((seg) => seg._id !== this._id)
            .find((seg) => Math.abs(time - seg.time) < MIN_SEGMENT_LENGTH)
        return !this.isPatched && !tooClose && position > hardStartPosition && position < hardEndPosition
    }

    setDefaultEndPosition(video: PassageVideo) {
        const hardEndPosition = this.hardEndPosition(video)
        if (hardEndPosition >= 0) {
            this.endPosition = hardEndPosition
        }
    }

    get duration() {
        return this.endPosition - this.position
    }

    hardStartPosition2(video: PassageVideo) {
        const { segments } = video
        const index = segments.findIndex((s) => s._id === this._id)
        if (index < 0) return -1
        return index > 0 ? segments[index - 1].position : 0
    }

    hardStartPosition(video: PassageVideo) {
        const { segments } = video
        const index = segments.findIndex((s) => s._id === this._id)
        if (index < 0) return -1
        return index > 0 ? segments[index - 1].endPosition : 0
    }

    hardEndPosition(video: PassageVideo) {
        const { segments, duration } = video
        const index = segments.findIndex((s) => s._id === this._id)
        if (index < 0) return -1
        return index < segments.length - 1 ? segments[index + 1].position : duration
    }

    async setApproved(approval: PassageSegmentApproval, username: string) {
        if (this.approved === approval) {
            return
        }
        const doc = this._toDocument({})
        doc.approved = approval
        doc.approvalDate = this.db.getDate()
        doc.approvedBy = username
        await this.db.put(doc)
    }

    async setReferences(references: RefRange[]) {
        const serializedReferences = JSON.stringify(references)
        log('setReferences', serializedReferences)
        if (JSON.stringify(this.references) === serializedReferences) {
            log('setReferences no change')
            return
        }

        const doc = this.toDocument()
        doc.references = serializedReferences
        await this.db.put(doc)
    }

    async setLabels(labels: PassageSegmentLabel[]) {
        const serializedLabels = JSON.stringify(labels)
        if (JSON.stringify(this.labels) === serializedLabels) {
            return
        }
        const doc = this._toDocument({})
        doc.labels = labels

        await this.db.put(doc)
    }

    async setGloss(identity: string, gloss: string) {
        let glosses = this.glosses.map((g) => ({ ...g }))
        const sg = glosses.find((g) => g.identity === identity)
        if (sg) {
            sg.gloss = gloss
        } else if (gloss.trim() !== '') {
            glosses.push(new PassageSegmentGloss(identity, gloss))
        }

        // Ensure that all glosses are ordered by identify of creator
        glosses = _.sortBy(glosses, (g) => g.identity)

        if (JSON.stringify(this.glosses) === JSON.stringify(glosses)) {
            return
        }
        const doc = this.toDocument()
        doc.glosses = glosses

        await this.db.put(doc)
    }

    // If this segment is patched, return the 0'th segment from the patched video.
    // Otherwise return this segment.
    actualSegment(passage: Passage) {
        const patchVideo = this.patchVideo(passage)
        return patchVideo ? patchVideo.segments[0] : this
    }

    // Return passageVideo for latest patch for this segment.
    // Return undefined if no patch present for segment.
    patchVideo(passage: Passage) {
        const { videoPatchHistory } = this
        const latestPatchId = videoPatchHistory.slice(-1)[0]
        if (!latestPatchId) return undefined

        const video = passage.findVideo(latestPatchId)

        return video || null
    }

    // If segment is patched return the patch video
    // Otherise return the passage video.
    actualVideo(passage: Passage) {
        let video = this.patchVideo(passage)
        if (video) return video
        video = passage.findVideo(this._id)
        return video || null
    }

    // Has this segment been patched?
    get isPatched() {
        return this.videoPatchHistory.length > 0
    }

    // Convert a position in this segment to a time.
    // Don't allow times earlier than 0.
    positionToTime(position: number) {
        const time = Math.max(this.time + position - this.position, 0)
        return time
    }

    // Convert a time to a position in the visible area of this segment
    timeToPosition(time: number, limitToSegment?: boolean) {
        let position = this.position + time - this.time

        if (limitToSegment) {
            position = Math.max(position, this.position)
            position = Math.min(position, this.endPosition)
        }

        return position
    }

    async setIgnoreWhenPlayingVideo(value: boolean) {
        if (this.ignoreWhenPlayingVideo === value) {
            return
        }
        const doc = this._toDocument({ model: 10, ignoreWhenPlayingVideo: value })
        await this.db.put(doc)
    }

    createPassageSegmentDocument() {
        const newId = this.db.getNewId(this.documents, new Date(Date.now()), 'segDoc_')
        const passageSegmentDocument = new PassageSegmentDocument(`${this._id}/${newId}`, this.db)
        let rank = 100
        if (this.documents.length > 0) {
            rank = this.documents.slice(-1)[0].rankAsNumber + 100
        }
        passageSegmentDocument.rank = DBObject.numberToRank(rank)
        return passageSegmentDocument
    }

    createPassageSegmentDocumentFromExisting(segmentDoc: PassageSegmentDocument) {
        if (segmentDoc.removed) {
            return
        }
        const documentCopy = this.createPassageSegmentDocument()
        const copy = segmentDoc.copy()
        copy._id = documentCopy._id
        return copy
    }

    async addPassageSegmentDocument(segmentDoc: PassageSegmentDocument, useExistingModDate?: boolean) {
        for (const document of this.documents) {
            await this.removePassageSegmentDocument(document._id)
        }
        await this.db.put(segmentDoc.toDocument(useExistingModDate))
    }

    async removePassageSegmentDocument(_id: string) {
        await remove(this.documents, _id)
    }

    createAudioClip(projectName: string) {
        const newId = this.db.getNewId(this.audioClips, new Date(Date.now()), 'segAudClip_')
        const itemId = `${this._id}/${newId}`
        const audioBackTranslation = new AudioClip(itemId, this.db)
        audioBackTranslation.url = `${projectName}/${itemId}`
        let rank = 100
        if (this.audioClips.length > 0) {
            rank = this.audioClips.slice(-1)[0].rankAsNumber + 100
        }
        audioBackTranslation.rank = DBObject.numberToRank(rank)
        return audioBackTranslation
    }

    createAudioClipFromExisting(audioClip: AudioClip, projectName: string) {
        if (audioClip.removed) {
            return
        }
        const newAudioClip = this.createAudioClip(projectName)
        const copy = audioClip.copy()
        copy._id = newAudioClip._id
        return copy
    }

    async addAudioClip(audioClip: AudioClip, useExistingModDate?: boolean) {
        for (const clip of this.audioClips) {
            await this.removeAudioClip(clip._id)
        }
        await this.db.put(audioClip.toDocument(useExistingModDate))
    }

    async removeAudioClip(_id: string) {
        await remove(this.audioClips, _id)
    }

    async addSegmentResource(resource: PassageSegmentResource) {
        await this.db.put(resource.toDocument())
    }

    createSegmentResource() {
        const newId = this.db.getNewId(this.resources, new Date(Date.now()), 'segRsc_')
        const segmentResource = new PassageSegmentResource(`${this._id}/${newId}`, this.db)
        let rank = 100
        if (this.resources.length > 0) {
            rank = this.resources.slice(-1)[0].rankAsNumber + 100
        }
        segmentResource.rank = DBObject.numberToRank(rank)
        return segmentResource
    }

    createSegmentResourceFromExisting(resource: PassageSegmentResource) {
        if (resource.removed) {
            return
        }
        const newResource = this.createSegmentResource()
        const copy = resource.copy()
        copy._id = newResource._id
        return copy
    }

    async removeSegmentResource(_id: string) {
        await remove(this.resources, _id)
    }

    createTranscription() {
        const newId = this.db.getNewId(this.resources, new Date(Date.now()), 'segTrs_')
        const transcription = new PassageSegmentTranscription(`${this._id}/${newId}`, this.db)
        let rank = 100
        if (this.transcriptions.length) {
            rank = this.transcriptions.slice(-1)[0].rankAsNumber + 100
        }
        transcription.rank = DBObject.numberToRank(rank)
        return transcription
    }

    createTranscriptionFromExisting(transcription: PassageSegmentTranscription) {
        if (transcription.removed) {
            return
        }
        const newTranscription = this.createTranscription()
        const copy = transcription.copy()
        copy._id = newTranscription._id
        return copy
    }

    async addTranscription(transcription: PassageSegmentTranscription, useExistingModDate?: boolean) {
        for (const tr of this.transcriptions) {
            await this.removeTranscription(tr._id)
        }
        await this.db.put(transcription.toDocument(useExistingModDate))
    }

    async removeTranscription(_id: string) {
        await remove(this.transcriptions, _id)
    }

    async getPlayableSlicesForOnTopSegment(passage: Passage) {
        const actualVideo = this.actualVideo(passage)
        if (!actualVideo) {
            throw new Error('No video for this segment')
        }
        const vvc = new ViewableVideoCollection()
        vvc.setup(passage, actualVideo)
        vvc.download()
        await vvc.waitUntilDownloaded()
        if (!vvc.allSourcesPresent) {
            return []
        }
        const hardStartPosition = this.hardStartPosition(actualVideo)
        const hardEndPosition = this.hardEndPosition(actualVideo)
        const viewableVideo = vvc.viewableVideos.find((vv) => vv.video._id === actualVideo._id)
        if (!viewableVideo) {
            throw new Error('No viewable video for this slice')
        }
        return [new MediaSlice(hardStartPosition, hardEndPosition, viewableVideo.src)]
    }

    async getPlayableSlices(passage: Passage) {
        // get video that this segment is a part of
        const video = passage.findVideo(this._id)
        if (!video) {
            throw new Error('No video for this segment')
        }
        const vvc = new SingleVideoViewableVideoCollection()
        vvc.setup(passage, video)
        vvc.download()
        await vvc.waitUntilDownloaded()
        if (!vvc.allSourcesPresent) {
            return []
        }
        const hardStartPosition = this.hardStartPosition(video)
        const hardEndPosition = this.hardEndPosition(video)
        const viewableVideo = vvc.viewableVideos.find((vv) => vv.video._id === video._id)
        if (!viewableVideo) {
            throw new Error('No viewable video for this slice')
        }
        return [new MediaSlice(hardStartPosition, hardEndPosition, viewableVideo.src)]
    }

    async getPlayableSlicesForViewablePartOfSegment(passage: Passage) {
        // get video that this segment is a part of
        const video = passage.findVideo(this._id)
        if (!video) {
            throw new Error('No video for this segment')
        }
        const vvc = new SingleVideoViewableVideoCollection()
        vvc.setup(passage, video)
        vvc.download()
        await vvc.waitUntilDownloaded()
        if (!vvc.allSourcesPresent) {
            return []
        }
        const viewableVideo = vvc.viewableVideos.find((vv) => vv.video._id === video._id)
        if (!viewableVideo) {
            throw new Error('No viewable video for this slice')
        }
        return [new MediaSlice(this.position, this.endPosition, viewableVideo.src)]
    }

    async copyPassageSegmentDocuments(documents: PassageSegmentDocument[]) {
        // These have to been done in order, so we cannot use Promise.all
        for (const doc of documents) {
            const newDoc = this.createPassageSegmentDocumentFromExisting(doc)
            if (newDoc) {
                newDoc.creationDate = doc.creationDate
                newDoc.modDate = doc.modDate
                await this.addPassageSegmentDocument(newDoc, true)
            }
        }
    }

    async copyAudioClips(audioClips: AudioClip[]) {
        // These have to been done in order, so we cannot use Promise.all
        for (const clip of audioClips) {
            const newClip = this.createAudioClipFromExisting(clip, '')
            if (newClip) {
                newClip.creationDate = clip.creationDate
                newClip.modDate = clip.modDate
                await this.addAudioClip(newClip, true)
            }
        }
    }

    async copyTranscriptions(transcriptions: PassageSegmentTranscription[]) {
        // These have to been done in order, so we cannot use Promise.all
        for (const tr of transcriptions) {
            const newTr = this.createTranscriptionFromExisting(tr)
            if (newTr) {
                newTr.creationDate = tr.creationDate
                newTr.modDate = tr.modDate
                await this.addTranscription(newTr, true)
            }
        }
    }
}

import { DBObject } from './DBObject'
import { IDB } from './IDB'

// A video showing how a specific Biblical term is signed in this project.

export class SignVideo extends DBObject {
    url = ''

    constructor(_id: string, db: IDB, creationDate?: string) {
        super(_id, db)

        this.creationDate = creationDate || this.creationDate
    }

    toDocument() {
        const { url } = this
        return this._toDocument({ model: 15, url })
    }

    async delete() {
        const doc = this._toDocument({})
        doc.removed = true
        await this.db.put(doc)
    }

    dbg() {
        const doc = this.toDocument()
        return doc
    }
}

import { Project } from '../models3/Project'
import { RefRange } from './RefRange'
import { displayableBookNames } from './bookNames'

// Convert array of RefRange to an array of human readable strings
// where each array element corresponds to an input RefRange
export const refRangesToDisplayParts = (refs: Array<RefRange>, project?: Project) => {
    const parts: Array<string> = refs.map((rr, i) => {
        const book = rr.startRef.slice(0, 3)
        const prevBook = i > 0 ? refs[i - 1].startRef.slice(0, 3) : ''

        let bookName = ''
        if (book !== prevBook) {
            bookName = `${displayableBookNames(project)[parseInt(book, 10) - 1]} `
        }

        if (rr.isBBBOnly()) {
            return bookName
        }

        const c1 = rr.startRef.slice(3, 6).replace(/^0+/, '')
        const v1 = rr.startRef.slice(6, 9).replace(/^0+/, '')
        const c2 = rr.endRef.slice(3, 6).replace(/^0+/, '')
        const v2 = rr.endRef.slice(6, 9).replace(/^0+/, '')

        const verse = (v: string) => (v ? `.${v}` : '')
        if (c1 !== c2) return `${bookName}${c1}${verse(v1)}-${c2}${verse(v2)}`
        if (v1 !== v2) return `${bookName}${c1}.${v1}-${v2}`
        return `${bookName}${c1}${verse(v1)}`
    })

    return parts
}

// Convert array of RefRange to human readable string
export const refRangesToDisplay = (refs: Array<RefRange>, project?: Project) =>
    refRangesToDisplayParts(refs, project).join('; ')

export interface Language {
    code: string
    fullName: string
}

export const localeLanguages: Language[] = [
    { code: 'en', fullName: 'English' },
    { code: 'es', fullName: 'Español' },
    { code: 'fr', fullName: 'Français' },
    { code: 'pt', fullName: 'Português' },
    { code: 'ru', fullName: 'Russkij' }
]

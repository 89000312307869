import { observer } from 'mobx-react'
import React, { useRef, FC } from 'react'
import { useTranslation } from 'react-i18next'
import { UploadButton } from './Buttons'

interface FilePickerProps {
    enabled: boolean
    setSelectedFiles: (files: FileList) => void
    className: string
    accept?: string
}

const FilePicker: FC<FilePickerProps> = observer(({ enabled, setSelectedFiles, className, accept }) => {
    const { t } = useTranslation()
    const inputRef = useRef<HTMLInputElement>(null)

    return (
        <>
            <input
                type="file"
                accept={accept}
                style={{ display: 'none' }}
                onChange={() => inputRef.current?.files && setSelectedFiles(inputRef.current.files)}
                ref={inputRef}
            />
            <UploadButton
                onClick={() => {
                    if (inputRef.current) {
                        // Reset input so change event will fire once we've selected a file
                        inputRef.current.value = ''
                        inputRef.current.click()
                    }
                }}
                className={className}
                tooltip={t('recordingImport')}
                enabled={enabled}
            />
        </>
    )
})

export default FilePicker

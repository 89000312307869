/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { Component } from 'react'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs'
import { t } from 'i18next'

import StatusBoardEditor from './StatusBoardEditor'
import { ProjectPlanEditor } from './plan/ProjectPlanEditor'
import ProgressReport from './progress/ProgressReport'
import { ProgressGraphPage } from './progress/ProgressGraph'

import LoadingMessage from '../utils/InitializationMessage'
import ErrorBoundary from '../utils/Errors'

import { Passage } from '../../models3/Passage'
import { Portion } from '../../models3/Portion'
import { Root } from '../../models3/Root'

interface IStatusEditor {
    rt: Root
}

export type FilteredPortionPassages = {
    portion: Portion
    passages: Passage[]
}

class StatusEditor extends Component<IStatusEditor> {
    @observable specificPortionSelected = false // User has selected a portion, but not *Current Portion*

    @observable specificPassageSelected = false // User has selected a passage, but not *Current Passage*

    @observable selectedPassage?: Passage

    @observable selectedPortion?: Portion

    constructor(props: IStatusEditor) {
        super(props)
        const { rt } = this.props
        const { statusShowAll, statusShowCurrentPassage, statusShowCurrentPortion } = rt
        if (!statusShowAll && !statusShowCurrentPassage && !statusShowCurrentPortion) {
            this.setShowCurrentPortion() // Default to current portion
        }
    }

    setShowAll = () => {
        const { rt } = this.props
        this.specificPassageSelected = false
        this.specificPortionSelected = false
        this.selectedPortion = undefined
        this.selectedPassage = undefined
        rt.setStatusShowAll(true)
    }

    setShowCurrentPortion = () => {
        const { rt } = this.props

        this.specificPassageSelected = false
        this.specificPortionSelected = false
        this.selectedPortion = undefined
        this.selectedPassage = undefined
        rt.setStatusShowCurrentPortion(true)
    }

    setShowCurrentPassage = () => {
        const { rt } = this.props

        this.specificPassageSelected = false
        this.specificPortionSelected = false
        this.selectedPortion = undefined
        this.selectedPassage = undefined
        rt.setStatusShowCurrentPassage(true)
    }

    setCurrentPassage = (portion: Portion, passage: Passage) => {
        const { rt } = this.props

        this.specificPassageSelected = true
        this.specificPortionSelected = false
        this.selectedPortion = portion
        this.selectedPassage = passage
        rt.resetStatusFilter()
    }

    setCurrentPortion = (portion: Portion) => {
        const { rt } = this.props

        this.specificPassageSelected = false
        this.specificPortionSelected = true
        this.selectedPortion = portion
        this.selectedPassage = undefined
        rt.resetStatusFilter()
    }

    render() {
        const { rt } = this.props
        const { initialized, loadingMessage } = rt

        if (!initialized) return <LoadingMessage {...{ loadingMessage }} />

        const { project, statusShowAll, statusShowCurrentPassage, statusShowCurrentPortion } = rt
        const { specificPassageSelected, specificPortionSelected, selectedPassage, selectedPortion } = this

        let portionPassages: FilteredPortionPassages[] = []
        let currentValue = ''
        if (statusShowAll) {
            currentValue = `*${t('Show All')}*`
            portionPassages = project.portions.map((portion) => ({ portion, passages: portion.passages }))
        } else if (statusShowCurrentPassage) {
            currentValue = `*${t('Current Passage')}*`
            const portion = rt.portion!
            const passage = rt.passage!
            portionPassages = [
                {
                    portion,
                    passages: [passage]
                }
            ]
        } else if (statusShowCurrentPortion) {
            currentValue = `*${t('Current Portion')}*`
            const portion = rt.portion!
            portionPassages = [
                {
                    portion,
                    passages: portion.passages
                }
            ]
        } else if (specificPassageSelected) {
            currentValue = selectedPassage!.name
            portionPassages = [
                {
                    portion: selectedPortion!,
                    passages: [selectedPassage!]
                }
            ]
        } else if (specificPortionSelected) {
            currentValue = selectedPortion!.name
            portionPassages = [
                {
                    portion: selectedPortion!,
                    passages: selectedPortion!.passages
                }
            ]
        }

        const { setCurrentPassage, setCurrentPortion, setShowAll, setShowCurrentPassage, setShowCurrentPortion } = this
        return (
            <div>
                <Tabs>
                    <TabList>
                        <Tab>{t('Status')}</Tab>
                        <Tab>{t('Plan')}</Tab>
                        <Tab>{t('Progress')}</Tab>
                        <Tab>{t('Graph')}</Tab>
                    </TabList>

                    <TabPanel>
                        <ErrorBoundary>
                            <StatusBoardEditor
                                rt={rt}
                                currentSelectedOption={currentValue}
                                portionPassages={portionPassages}
                                setCurrentPassage={setCurrentPassage}
                                setCurrentPortion={setCurrentPortion}
                                setShowAll={setShowAll}
                                setShowCurrentPortion={setShowCurrentPortion}
                                setShowCurrentPassage={setShowCurrentPassage}
                            />
                        </ErrorBoundary>
                    </TabPanel>
                    <TabPanel>
                        <ErrorBoundary>
                            <ProjectPlanEditor rt={rt} />
                        </ErrorBoundary>
                    </TabPanel>
                    <TabPanel>
                        <ErrorBoundary>
                            <ProgressReport rt={rt} />
                        </ErrorBoundary>
                    </TabPanel>
                    <TabPanel>
                        <ErrorBoundary>
                            <ProgressGraphPage
                                rt={rt}
                                currentSelectedOption={currentValue}
                                portionPassages={portionPassages}
                                setCurrentPassage={setCurrentPassage}
                                setCurrentPortion={setCurrentPortion}
                                setShowAll={setShowAll}
                                setShowCurrentPortion={setShowCurrentPortion}
                                setShowCurrentPassage={setShowCurrentPassage}
                            />
                        </ErrorBoundary>
                    </TabPanel>
                </Tabs>
            </div>
        )
    }
}

export default observer(StatusEditor)

import React, { FC, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { LoadingIcon } from '../utils/Icons'
import { useGetProgress } from './useGetProgress'

interface AdvancedDownloadingMessageProps {
    urls: string[]
    creator: string
}

export const AdvancedDownloadingMessage: FC<AdvancedDownloadingMessageProps> = observer(({ urls }) => {
    const [showMessage, setShowMessage] = useState(false)

    const { t } = useTranslation()

    const { numberUploaded, numberDownloaded, totalBlobs, progressQueried } = useGetProgress(urls)

    // We only want to show progress if it is taking a while, otherwise there is unnecessary flashing
    useEffect(() => {
        const timer = setTimeout(() => {
            setShowMessage(true)
        }, 3000)
        return () => {
            if (timer) clearTimeout(timer)
        }
    }, [])

    let uploadPercent = (numberUploaded / totalBlobs) * 100
    if (isNaN(uploadPercent) || uploadPercent === Infinity) {
        // Divided by 0
        uploadPercent = 0
    }

    let downloadPercent = (numberDownloaded / totalBlobs) * 100
    if (isNaN(downloadPercent) || downloadPercent === Infinity) {
        // Divided by 0
        downloadPercent = 0
    }

    if (!(showMessage && progressQueried)) {
        return null
    }

    const uploadingMessage = t('recordingLoadingUploadMessage')
    const downloadingMessage = t('recordingLoadingDownloadMessage', { downloadPercent: downloadPercent.toFixed(0) })

    return (
        <div className="video-message">
            <div className="video-message-child">
                <div className="video-message-message">
                    <LoadingIcon className="passage-recording-loading-icon" />
                    {numberUploaded < totalBlobs ? uploadingMessage : downloadingMessage}
                </div>
            </div>
        </div>
    )
})

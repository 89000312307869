import { observer } from 'mobx-react'
import { TFunction, useTranslation } from 'react-i18next'

import { confirmAlert } from 'react-confirm-alert'

import FilePicker from '../utils/FilePicker'

import { Passage } from '../../models3/Passage'
import { Root } from '../../models3/Root'
import { displayError, displayInfo } from '../utils/Errors'
import VideoCompressor from '../utils/VideoCompressor'
import { FfmpegParameters } from '../../models3/FfmpegParameters'
import { fmt } from '../utils/Fmt'
import { PassageVideo } from '../../models3/PassageVideo'
import { FileDateParser } from '../../models3/FileDateParser'
import { VideoCacheRecord } from '../../models3/VideoCacheRecord'
import { encodeOpus, shouldEncodeOpus } from '../utils/Opus'
import { downloadWithFilename } from '../utils/Helpers'
import { downloadFcpxml } from '../../finalcutpro/finalcutpro'
import { importEAFFile } from '../../elan/ELANCreator'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:BaseRecordingFilePicker')

interface BaseRecordingFilePickerProps {
    enabled: boolean
    rt: Root
    passage: Passage
    className: string
}

const writeTestFileToCache = async (file: File) => {
    const _id = `${file.name.slice(2, -4)}-1`
    log('writeTestFileToCache', _id)

    const vcr = VideoCacheRecord.get(_id)
    await vcr.deleteBlobs() // if existing video data for this file, remove it
    vcr.size = file.size
    await vcr.addBlob(file)

    log('!!!writeTestFileToCache DONE', _id)
}

const needsCompression = (file: File) => {
    if (file.name.includes('_force_compression_')) return true

    const sizeMB = file.size / (1024 * 1024)
    log('needsCompression?', fmt({ sizeMB: sizeMB.toFixed(0) }))

    if (shouldEncodeOpus(file.name)) return true

    if (file.name.toLowerCase().endsWith('.mxf')) return true
    if (file.name.toLowerCase().endsWith('.mov')) return true
    if (file.name.toLowerCase().endsWith('.wmv')) return true

    return sizeMB > 100
}

const confirmUseTodaysDate = async (fileCreationDate: Date, t: TFunction): Promise<boolean> => {
    return new Promise((resolve) => {
        confirmAlert({
            title: t('File date warning'),
            message: t(`recordingDropFileDateWarning`, { fileCreationDate }),
            confirmLabel: t('Yes'),
            cancelLabel: t('No'),
            onConfirm: () => resolve(true),
            onCancel: () => resolve(false)
        })
    })
}

const shouldUseTodaysDate = async (fileCreationDate: Date, videos: PassageVideo[], t: TFunction) => {
    // If no videos already present, always use date from file.
    if (videos.length === 0) return false

    const mostRecentVideo = videos[videos.length - 1]
    const mostRecentCreationDate = new Date(mostRecentVideo.creationDate)

    if (fileCreationDate.getTime() < mostRecentCreationDate.getTime()) {
        return confirmUseTodaysDate(fileCreationDate, t)
    }
    return false
}

const getCreationDate = async (file: File, videos: PassageVideo[], t: TFunction) => {
    const fileDateParser = new FileDateParser(videos, file)
    let creationDate = fileDateParser.getCreationDate()
    if (creationDate.getTime() > Date.now()) {
        throw new Error(t('recordingCreationDateFutureError'))
    }

    const useToday = await shouldUseTodaysDate(creationDate, videos, t)
    if (useToday) {
        creationDate = new Date()
    }
    return creationDate
}

const validateFile = (file: File, maxVideoSizeMB: number, t: TFunction) => {
    if (file.size > maxVideoSizeMB * 1024 * 1024) {
        throw new Error(t('fileSizeTooLarge', { maxVideoSizeMB }))
    }

    const video = document.createElement('video')
    if (!video.canPlayType(file.type)) {
        throw new Error(t('Cannot play this type of file.'))
    }
}

interface UploaderProps {
    files: File[]
    rt: Root
    passage: Passage
    t: TFunction
    copyVerseReferences?: boolean
}

export const uploadRecording = async ({ files, rt, passage, t, copyVerseReferences = true }: UploaderProps) => {
    const uploadFile = async (file: File, creationDate: Date, ffmpegParametersUsed?: FfmpegParameters) => {
        const video = await passage.uploadFile(file, creationDate, rt.name)

        if (ffmpegParametersUsed !== undefined) {
            video.ffmpegParametersUsed = ffmpegParametersUsed
        }

        // This will indirectly trigger uploading the video blob in the cache to S3
        // when DBAcceptor calls VideoCache.acceptPassageVideo
        await passage.addVideoWithDefaultSegment(video, copyVerseReferences)
        await rt.setPassage(passage)

        // We set rt.passageVideo to null first in order to ensure that
        // VideoMain will update. Otherwise there is a race condition
        // where DBAcceptor partially set up the rt.passageVideo and triggers
        // a VideoMain render before the rt.passageVideo has all the info
        // to correctly display.
        await rt.setPassageVideo(null)

        const foundVideo = passage.videos.find((v) => v._id === video._id)
        if (foundVideo) {
            await rt.setPassageVideo(foundVideo)
        }
    }

    const uploadVideo = async (file: File, creationDate: Date, ffmpegParametersUsed?: FfmpegParameters) => {
        try {
            validateFile(file, rt.project.maxVideoSizeMB, t)
            await uploadFile(file, creationDate, ffmpegParametersUsed)
        } catch (err) {
            displayError(err)
        }
    }

    const compressAndUpload = async (file: File, setProgressMessage: (message: string) => void) => {
        log('compressAndUpload start')

        try {
            // The original file may not exist after the compression. So get file
            // statistics beforehand.
            const creationDate = await getCreationDate(file, passage.videos, t)

            if (shouldEncodeOpus(file.name)) {
                setProgressMessage(t('Starting compression...'))
                const compressedFile = await encodeOpus(file)
                uploadVideo(compressedFile, creationDate)
                setProgressMessage('')
                return
            }

            const isCompressorRunning = await VideoCompressor.checkIfServerRunning()
            if (!isCompressorRunning) return

            let resolution = rt.project.compressedVideoResolution
            let quality = rt.project.compressedVideoQuality
            while (true) {
                const compressor = new VideoCompressor(
                    rt.project.compressedVideoQuality,
                    rt.project.compressedVideoResolution,
                    rt.project.maxVideoSizeMB,
                    setProgressMessage
                )
                const { compressedFile, ffmpegParameters } = await compressor.compressVideo(file)

                const sizeMB = compressedFile.size / (1024 * 1024)
                log(
                    'compressAndUpload compressVideo',
                    fmt({
                        sizeMB,
                        resolution,
                        quality
                    })
                )

                if (sizeMB <= rt.project.maxVideoSizeMB) {
                    uploadVideo(compressedFile, creationDate, ffmpegParameters)
                    break
                }

                if (resolution > 480) {
                    displayInfo(
                        `${t('Compressed 720p file too large. Try compressing to 480p.')} [${sizeMB.toFixed(0)}mb]`
                    )
                    resolution = 480
                    continue
                }

                if (quality < 28) {
                    quality = Math.min(rt.project.compressedVideoQuality + 3, 28)
                    displayInfo(
                        `${t('recordingCompressedTooLargeTryingAgain')} [${sizeMB.toFixed(0)}mb, crf=${quality}]`
                    )
                    continue
                }

                displayError(t('recordingCompressedTooLarge'))
                break
            }
        } catch (err) {
            const error = err as Error
            log('compressAndUpload ERROR', JSON.stringify(error))

            setProgressMessage('')

            if (error.name === 'NotEnoughFreeSpace') {
                displayError(t('Your hard drive does not have enough free space.'))
            } else if (error.name === 'PayloadTooLarge') {
                displayError(t('File upload is too large.'))
            } else if (error.name === 'CompressedFileTooLarge') {
                displayError(
                    t('recordingCouldNotCompressSmallerThanMax', {
                        maxVideoSizeMB: rt.project.maxVideoSizeMB
                    }) + t('Retry with different compression settings.')
                )
            } else {
                displayError(t('recordingCouldNotCompress'))
            }
        }
    }

    const testCompressionServer = async (file: File, setProgressMessage: (message: string) => void) => {
        const isCompressorRunning = await VideoCompressor.checkIfServerRunning()
        if (!isCompressorRunning) {
            return
        }

        try {
            const compressor = new VideoCompressor(
                rt.project.compressedVideoQuality,
                rt.project.compressedVideoResolution,
                rt.project.maxVideoSizeMB,
                setProgressMessage
            )
            const compressedData = await compressor.compressVideo(file)
            const { compressedFile } = compressedData

            let fileExtension = 'mp4'
            if (compressedFile.type.startsWith('audio')) {
                fileExtension = 'mp3'
            }

            // Download compressed video file
            const href = window.URL.createObjectURL(compressedFile)
            downloadWithFilename(href, `compressed-video.${fileExtension}`)
        } catch (err) {
            const error = err as Error
            setProgressMessage('')
            displayError(error.name)
        }
    }

    const upload = async () => {
        // Files with rt.names starting __video are treated as test files and directly
        // loaded to the video cache
        if (files.length > 0 && files[0].name.startsWith('__video')) {
            for (const file of files) {
                await writeTestFileToCache(file)
            }

            return
        }

        if (!rt.iAmTranslator) {
            displayError(t('recordingOnlyTranslatorsCanUpload'))
            return
        }

        const setProgressMessage = passage.setCompressionProgressMessage.bind(passage)

        if (files.length !== 1) {
            displayError(t('You must drop exactly one file.'))
            return
        }

        const file = files[0]
        const isAudioFile = file.type.startsWith('audio')
        const isVideoFile = file.type.startsWith('video')

        if (file.name.startsWith('_test_comp_')) {
            await testCompressionServer(file, setProgressMessage)
            return
        }

        if (rt.passageVideo) {
            // Process Final Cut Pro file
            if (file.name.endsWith('.fcpxml')) {
                downloadFcpxml(rt, passage, rt.passageVideo, file).catch(displayError)
                return
            }

            // Import ELAN gloss file
            if (file.name.endsWith('.eaf')) {
                importEAFFile(rt, passage, rt.passageVideo, file).catch(displayError)
                return
            }
        }

        if (rt.project.recordAudioOnly && !isAudioFile) {
            displayError(t('This file must be an audio file.'))
            return
        }

        if (!rt.project.recordAudioOnly && !isVideoFile) {
            displayError(t('This file must be a video file.'))
            return
        }

        if (needsCompression(file)) {
            await compressAndUpload(file, setProgressMessage)
            return
        }

        const creationDate = await getCreationDate(file, passage.videos, t)
        await uploadVideo(file, creationDate)
    }

    return upload()
}

export const BaseRecordingFilePicker = observer(({ enabled, rt, passage, className }: BaseRecordingFilePickerProps) => {
    const { t } = useTranslation()
    return (
        <FilePicker
            enabled={enabled}
            setSelectedFiles={(files) => uploadRecording({ files: Array.from(files), rt, passage, t })}
            className={className}
            accept="audio/*,video/*"
        />
    )
})

import { Component } from 'react'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import { t } from 'i18next'

import { RootContext } from '../../app/RootContext'
import TextInput from '../../utils/TextInput'

import { ProjectPlan } from '../../../models3/ProjectPlan'
import { Root } from '../../../models3/Root'

interface IProjectStageAdder {
    addStage: (name: string) => void
    plan: ProjectPlan
}

class ProjectStageAdder extends Component<IProjectStageAdder> {
    // eslint-disable-next-line react/static-property-placement
    static contextType = RootContext

    @observable adding = false

    validate = (value: string) => {
        value = value.trim()
        const { plan } = this.props
        if (plan.stages.find((stage) => stage.name === value)) {
            return t('Duplicate name')
        }
        if (value === '') {
            return t('Empty name')
        }
        return ''
    }

    onCancel = () => (this.adding = false)

    onEnter = (newStageName: string) => {
        const { addStage } = this.props

        if (!this.validate(newStageName)) {
            addStage(newStageName)
        }
        this.adding = false
    }

    render() {
        const rt: Root = this.context
        if (!rt) return null

        const { validate } = this

        const _addStage = t('Add stage.')

        if (this.adding) {
            return (
                <TextInput
                    placeholder={_addStage}
                    message={t('Type Enter to add new stage or Esc to cancel.')}
                    validate={validate}
                    _onEscape={this.onCancel}
                    _onEnter={this.onEnter}
                />
            )
        }

        return (
            <div onClick={() => (this.adding = true)} className="stage-adder" data-toggle="tooltip" title={_addStage}>
                <i className="far fa-fw fa-plus-square" />
            </div>
        )
    }
}

export default observer(ProjectStageAdder)

import { observable } from 'mobx'

import { DBObject } from './DBObject'

export class PassagePDF extends DBObject {
    @observable title = ''

    @observable url = ''

    @observable description = ''

    static MAX_DESCRIPTION_CHARACTERS = 5000

    model = 17

    toDocument() {
        const { url, title, model } = this
        return this._toDocument({ url, title, model })
    }

    copy() {
        let copy = new PassagePDF(this._id, this.db)
        copy = Object.assign(copy, this)
        return copy
    }

    async setTitle(title: string, forcePut: boolean) {
        title = title.trim()
        if (!forcePut && this.title === title) {
            return
        }

        const doc = this._toDocument({ title, model: this.model })
        await this.db.put(doc)
    }

    async setDescription(description: string) {
        if (this.description === description) {
            return
        }

        if (description.length > PassagePDF.MAX_DESCRIPTION_CHARACTERS) {
            throw new Error('PassagePDF description is too long. Aborting save.')
        }

        const doc = this._toDocument({ description, model: this.model })
        await this.db.put(doc)
    }

    getTitle() {
        return this.title
    }
}

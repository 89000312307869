/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { openDB, deleteDB, IDBPDatabase } from 'idb'
import { t } from 'i18next'

import { VideoCacheRecord, VideoBlob } from './VideoCacheRecord'
import { VideoCacheUploader } from './VideoCacheUploader'
import { VideoCacheDownloader, IVideoDownloadQuery } from './VideoCacheDownloader'
import { systemError } from '../components/utils/Errors'
import { IDateCreator, SLTTDateCreator } from './DateUtilities'
import { isProjectRestoreInProgress } from '../components/utils/Helpers'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:VideoCache')

// flag to allow disabling implicit downloads when debugging
const disableImplicit = localStorage.getItem('disableImplicit')

// VideoCache acts as a singleton cache to store all local videos.
// This includes videos that have been downloaded from S3
// and videos waiting to be uploaded to S3.

export class VideoCache {
    static VIDEOBLOBS = 'videoBlobs'

    static CACHEDVIDEOS = 'cachedVideos'

    static capacity = 50 // max capacity in Gig

    static _db: IDBPDatabase<unknown>

    static videoCacheUploader = new VideoCacheUploader(systemError)

    static videoCacheDownloader = new VideoCacheDownloader(systemError)

    static async initialize() {
        if (VideoCache._db) return

        await VideoCache.checkForCacheResetRequest()

        VideoCache._db = await openDB('VideoCache', 1, {
            upgrade(db) {
                db.createObjectStore(VideoCache.VIDEOBLOBS, {
                    keyPath: '_id',
                    autoIncrement: true
                })
                db.createObjectStore(VideoCache.CACHEDVIDEOS, {
                    keyPath: '_id',
                    autoIncrement: true
                })
            }
        })

        const vcrs = await VideoCache.getAllVcrs()
        for (const vcr of vcrs) {
            VideoCacheRecord.updateCache(vcr)
        }

        ;(window as any).VideoCache = VideoCache

        // await VideoCache.deleteAll()
    }

    static async export(match: RegExp) {
        const [allCachedVideos, allVideoBlobs] = await Promise.all([
            VideoCache._db.getAll(VideoCache.CACHEDVIDEOS),
            VideoCache._db.getAll(VideoCache.VIDEOBLOBS)
        ])
        return {
            cachedVideos: allCachedVideos.filter((v) => v._id.match(match)),
            videoBlobs: allVideoBlobs.filter((v) => v._id.match(match))
        }
    }

    static async checkForCacheResetRequest() {
        if (localStorage.getItem('cacheResetRequest') !== 'YES') return

        localStorage.setItem('cacheResetRequest', '')

        try {
            await deleteDB('VideoCache')
            log('VideoCache reset DONE')
        } catch (error) {
            log('VideoCache reset FAILED', error)
        }
    }

    // User this when user has explicitly requested to display a video.
    // It will be scheduled to download with high priority.
    // You can call this repeated with a short delay to get updated information
    // about the download status of the video ... and when the download completes
    // a blob containing the video.
    static async queryVideoDownload(videoUrl: string): Promise<IVideoDownloadQuery> {
        return this.videoCacheDownloader.queryVideoDownload(videoUrl)
    }

    // Return a sorted list of scheduled downloads for debugging purposes
    static queryScheduledDownloads() {
        return this.videoCacheDownloader.queryScheduledDownloads()
    }

    static queryProgress(videoUrl: string) {
        return VideoCacheDownloader.getProgress(videoUrl)
    }

    static queryProgressMultiple(urls: string[]) {
        const responses = urls.map((url) => VideoCache.queryProgress(url))
        return responses.reduce(
            (prev, curr) => {
                return {
                    uploaded: prev.uploaded + curr.numberUploaded,
                    downloaded: prev.downloaded + curr.numberDownloaded,
                    total: prev.total + curr.totalBlobs
                }
            },
            { uploaded: 0, downloaded: 0, total: 0 }
        )
    }

    // Use this when the user has done something that makes it reasonably likely
    // they are going to want to access this video in the near future.
    // Video will be scheduled for downloading but at a low priority.
    // Returns true if we know that the requested video has already been downloaded.
    static async implicitVideoDownload(videoUrl: string) {
        if (disableImplicit) return false

        return this.videoCacheDownloader.implicitVideoDownload(videoUrl)
    }

    /**
     * Return status of upload for this video.
     * Empty string means video did not need uploading or uploading complete.
     */
    static queryVideoUpload(_id: string) {
        const vcr = VideoCacheRecord.get(_id)

        const { uploadeds, error } = vcr

        if (uploadeds.length === 0) return ''

        const totalUploaded = uploadeds.filter(Boolean).length
        if (totalUploaded === uploadeds.length) return ''

        if (error) return error

        let message = `Uploaded ${totalUploaded} of ${uploadeds.length} ...`
        if (totalUploaded === 0) message = 'Waiting to upload ...'

        return message
    }

    static async accept(_id: string, creationDate: string) {
        const vcr = VideoCacheRecord.get(_id)

        // Every -id should end in -n to tell us how many S3 items
        // for this video, if not we cannot download ig
        const seqNum = vcr.seqNum(_id)
        if (seqNum <= 0) {
            await vcr.setInvalidSeqNumError(_id)
            return
        }

        if (vcr.uploadeds.length) {
            if (!vcr.uploaded) {
                VideoCache.videoCacheUploader.postMessage({ func: 'upload', _id: vcr._id })
            }
            return
        }

        // If this video was created recently but not downloaded yet,
        // schedule it to be downloaded at a low priority.
        if (!vcr.downloaded) {
            const dt = new Date()
            dt.setDate(dt.getDate() - 7) // 7 days before today
            if (new Date(creationDate) >= dt) {
                VideoCache.implicitVideoDownload(_id)
            }
        }
    }

    /**
     * @return the record present in the cache for this id.
     *    WARNING the presence of a cache record does not mean that the item
     *    has finished uploading or downloading.
     */
    static getVideoCacheRecord(_id: string) {
        return VideoCacheRecord.get(_id)
    }

    // If this video has already been downloaded, return it as a blob.
    // Otherwise return null.
    static async getVideoBlob(_id: string): Promise<Blob | null> {
        log(`getVideoBlob ${_id}`)
        const vcr = VideoCacheRecord.get(_id)

        // Update the access date. Least recently used unlocked blobs are
        // deleted when necessary to make room for more recently accessed blobs.
        await vcr.touch()

        if (!vcr.hasBeenCached) return null

        return vcr.getVideoBlob()
    }

    static async copyFileToVideoCache(file: Blob, baseUrl: string, creationDate?: string, requestUpload?: boolean) {
        if (!creationDate) {
            const dateCreator = VideoCache.getDateCreator()
            creationDate = dateCreator.getDate()
        }

        return VideoCache.copyMultipartFileToVideoCache(file, baseUrl, creationDate, requestUpload)
    }

    static getDateCreator(): IDateCreator {
        return new SLTTDateCreator()
    }

    private static async copyMultipartFileToVideoCache(
        file: Blob,
        baseUrl: string,
        creationDate?: string,
        requestUpload?: boolean
    ) {
        const blockSize = 4 * 1024 * 1024
        const blobsCount = Math.floor((file.size - 1) / blockSize + 1)
        const _id = `${baseUrl}-${blobsCount}`
        log('copyFileToVideoCache', requestUpload, file.size, _id)

        let offset = 0
        const vcr = VideoCacheRecord.get(_id)

        // Project images may have been previously uploaded. Start over.
        // Other uploads contain unique names so the following line does nothing.
        vcr.uploadeds = []

        vcr.size = file.size

        while (offset < file.size) {
            let endOffset = offset + blockSize
            if (endOffset > file.size) endOffset = file.size

            const blob = file.slice(offset, endOffset, file.type)
            vcr.addBlob(blob)

            offset += blockSize
        }

        await vcr.saveToDB()

        const uploadSuccess = await VideoCache.didUploadSucceed(vcr._id)
        if (!uploadSuccess) {
            throw Error(
                t('Could not read file. If copying from Dropbox or Google Drive try making a local copy first.')
            )
        }

        // For videos and images which are referenced in Project the upload is requested
        // when the referencing DB record is accepted.
        // For project images however there is no DB record in the local DB.
        // For those entries we set requestUpload to trigger the upload.
        if (requestUpload) {
            VideoCache.videoCacheUploader.postMessage({ func: 'upload', _id: vcr._id })
        }

        log('copyFileToVideoCache DONE')
        return _id
    }

    // Verify all blobs for a video cache record are actually stored in the cache.
    // Sometimes copying from dropbox does odd things that we need to catch.
    private static async didUploadSucceed(_id: string) {
        const vcr = VideoCacheRecord.get(_id)
        for (let i = 0; i < vcr.uploadeds.length; ++i) {
            try {
                const blobId = vcr.videoBlobId(i)
                const videoBlob = new VideoBlob(blobId)
                await videoBlob.loadFromDB(true)
            } catch (error) {
                return false
            }
        }

        return true
    }

    static async getAllVcrs() {
        const docs = await VideoCache._db.getAll(VideoCache.CACHEDVIDEOS)
        return docs.map((doc) => new VideoCacheRecord(doc))
    }

    // UTILITY methods for unit tests

    static async getAllVideoBlobs() {
        const docs = await VideoCache._db.getAll(VideoCache.VIDEOBLOBS)
        return docs
    }

    static async getAllVideoBlobKeys() {
        return VideoCache._db.getAllKeys(VideoCache.VIDEOBLOBS)
    }

    static dump(match?: string) {
        VideoCache.getAllVcrs()
            .then((vcrs) => {
                for (const vcr of vcrs) {
                    if (match && !vcr._id.includes(match)) continue
                    console.log(JSON.stringify(vcr, null, 4))
                }

                return VideoCache.getAllVideoBlobs()
            })
            .then((vbs) => {
                for (const vb of vbs) {
                    if (match && !vb._id.includes(match)) continue
                    console.log(vb._id, vb.blob ? '' : '!!!')
                }
            })
    }

    static async putItems({
        cachedVideoItems,
        videoBlobItems,
        allow = false
    }: {
        cachedVideoItems: any[]
        videoBlobItems: any[]
        allow?: boolean
    }) {
        if (!allow && isProjectRestoreInProgress()) {
            return
        }

        const transaction = VideoCache._db.transaction([VideoCache.CACHEDVIDEOS, VideoCache.VIDEOBLOBS], 'readwrite')
        const cachedVideosStore = transaction.objectStore(VideoCache.CACHEDVIDEOS)
        const videoBlobsStore = transaction.objectStore(VideoCache.VIDEOBLOBS)
        await Promise.all([
            ...cachedVideoItems.map((item) => cachedVideosStore.put(item)),
            ...videoBlobItems.map((item) => videoBlobsStore.put(item))
        ])
        await transaction.done
    }

    static async deleteAll() {
        if (isProjectRestoreInProgress()) {
            return
        }
        const vcrs = await VideoCache.getAllVcrs()
        for (const vcr of vcrs) {
            log(`delete vcr ${vcr._id}`)
            await VideoCache._db.delete(VideoCache.CACHEDVIDEOS, vcr._id)
        }

        const vbs = await VideoCache.getAllVideoBlobs()
        for (const vb of vbs) {
            log(`delete VideoBlob ${vb._id}`)
            await VideoCache._db.delete(VideoCache.VIDEOBLOBS, vb._id)
        }
    }

    static async deleteCache() {
        if (isProjectRestoreInProgress()) {
            return
        }
        try {
            await deleteDB(VideoCache.CACHEDVIDEOS)
        } catch (err) {
            log('deletecache error', err)
        }

        try {
            await deleteDB(VideoCache.VIDEOBLOBS)
        } catch (err) {
            log('deletecache error', err)
        }
    }

    static stressTest() {
        VideoCache.videoCacheDownloader.stressTest().catch((err) => log(err))
    }
}

const _window = window as any
_window.videoCacheStressTest = VideoCache.stressTest
_window.VideoCache = VideoCache

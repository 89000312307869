/* eslint-disable import/no-cycle */
/* eslint-disable max-classes-per-file */

import { transliterate as hebrewTransliterate } from 'hebrew-transliteration'
// Lemma information from Marble dictionaries.

import { routePrefix } from '../components/app/slttAvtt'
import { fmt } from '../components/utils/Fmt'
import { retriableFetch } from '../models3/API'
import { Root } from '../models3/Root'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:Lemmas')

// Language specific definition for a LexMeaning
export class LexDefinition {
    public languageCode: string // en, sp, fr

    public definitionShort: string

    public glosses: string

    /*
        {
            "languageCode": "en",
            "definitionShort": "= {L:threshing-floor<SDBH:גֹּרֶן>}, ◄ near river {L:Jordan<SDBH:יַרְדֵּן>}...",
            "glosses": "threshing floor of Atad"
        },
     */
    constructor(_lexDefinition: any) {
        this.languageCode = _lexDefinition.languageCode || ''
        this.definitionShort = _lexDefinition.definitionShort || ''
        this.glosses = _lexDefinition.glosses || ''
    }
}

export interface DefinitionLink {
    text: string
    link: string // e.g SDBH:<lemma>, '' if no link
}

// Information for a meaning (sense) of a Greek/Hebrew word
export class LexMeaning {
    public id: string // e.g. "sdbh000002001001000"

    public lexicalLink: string // e.g. "SDBH:<Hebrew>:000001"

    public definitions: LexDefinition[]

    public references: string[] // bbbcccvvv, where this meaning is used

    private static readonly SENSE_INDEX_START = 13

    private static readonly SENSE_INDEX_END = 15

    /* _meaning
        {
            "definitions": [
                {
                    "languageCode": "en",
                    "definitionShort": "= {L:threshing-floor<SDBH:גֹּרֶן>}, ◄ near river {L:Jordan<SDBH:יַרְדֵּן>}...",
                    "glosses": "threshing floor of Atad"
                },
                ...
            ],
            "references": "001050010|001050011"
        }
     */
    constructor(prefix: string, dictionaryLemma: string, _lexMeaning: any) {
        this.id = prefix + _lexMeaning.id
        const senseNumberString = this.id.slice(LexMeaning.SENSE_INDEX_START, LexMeaning.SENSE_INDEX_END + 1)
        let senseNumber = parseInt(senseNumberString) - 1
        if (isNaN(senseNumber)) {
            senseNumber = 0
        }
        const lexicalLinkSense = `000${senseNumber.toString().padStart(3, '0')}`
        const TAG_INDEX_END = 3
        const tag = this.id.slice(0, TAG_INDEX_END + 1).toUpperCase()
        this.lexicalLink = `${tag}:${dictionaryLemma}:${lexicalLinkSense}`
        this.definitions = _lexMeaning.definitions.map((d: any) => new LexDefinition(d))
        this.references = (_lexMeaning.references && _lexMeaning.references.split('|')) || []
    }

    getDefinition(uiLanguage: string) {
        if (uiLanguage === 'es') uiLanguage = 'sp'

        let definition = this.definitions.find((def) => def.languageCode === uiLanguage)

        if (!definition) {
            definition = this.definitions.find((def) => def.languageCode === 'en')
        }

        if (!definition && this.definitions.length > 0) {
            definition = this.definitions[0]
        }

        return definition
    }

    glosses(uiLanguage: string) {
        const definition = this.getDefinition(uiLanguage)
        return definition?.glosses ?? ''
    }

    definitionShort(uiLanguage: string): DefinitionLink[] {
        const definition = this.getDefinition(uiLanguage)

        const parts = definition?.definitionShort.split(/(\{L:.*?\})/) ?? []

        const definitionParts = parts.map((part, i) => {
            if (i % 2 === 0) {
                return { text: part, link: '' }
            }

            part = part.slice(3, -2) // strip off '{L:' and '>}'
            const [text, link] = part.split('<')
            return { text, link }
        })

        return definitionParts
    }

    /** Get which meaning this is in relation to the lemma. Starts at 1.
     * @throws if cannot get number
     */
    getMeaningNumber() {
        const meaningNumber = parseInt(this.id.slice(LexMeaning.SENSE_INDEX_START, LexMeaning.SENSE_INDEX_END + 1))
        if (isNaN(meaningNumber) || !isFinite(meaningNumber)) {
            throw new Error()
        }
        return meaningNumber
    }

    static getMeaningNumberFromLexicalLink(lexicalLink: string) {
        const parts = lexicalLink.split(':')
        let termIndex = -1
        if (parts.length >= 3) {
            const _termIndex = parts[2]
            termIndex = parseInt(_termIndex)
            if (isNaN(termIndex) || !isFinite(termIndex)) {
                termIndex = -1
            }
        }
        return termIndex + 1
    }
}

export class MarbleLemma {
    public dictionaryLemma: string // e.g. "Ἀαρών", "אָטָד"

    public id: string // e.g. "sdbh000002"

    public meanings: LexMeaning[] = []

    /* _lemma
        {
            "lexMeanings": [
                {
                    "id": "000309002001000",
                    "definitions": [
                        {
                            "languageCode": "en",
                            "definitionShort": "= {L:threshing-floor<SDBH:גֹּרֶן>}, ◄ near river {L:Jordan<SDBH:יַרְדֵּן>}...",
                            "glosses": "threshing floor of Atad"
                        },
                        ...
                    ],
                    "references": "001050010|001050011"
                }
            ],
            "id": "000309002000000",
            "lemma": "אָטָד"
        }
     */
    constructor(prefix: string /* sdbg, sdbh */, _lemma: any) {
        this.dictionaryLemma = _lemma.lemma
        this.id = prefix + _lemma.id.slice(0, 6)
    }

    addMeanings(prefix: string, _lemma: any) {
        for (const _meaning of _lemma.lexMeanings) {
            this.meanings.push(new LexMeaning(prefix, this.dictionaryLemma, _meaning))
        }
    }

    // If a video has been record for this biblical term return
    // its url, otherwise ''
    signVideoUrl(rt: Root) {
        const signVideo = rt.project.signs[`sign/${this.id}`]
        return signVideo ? signVideo.url : ''
    }

    async deleteSignVideo(rt: Root) {
        log('deleteSignVideo', this.id)

        const signVideo = rt.project.signs[`sign/${this.id}`]
        if (signVideo) {
            log('found')
            await signVideo.delete()
        }
    }
}

export class MarbleLemmas {
    // Lemmas are present for each ref (bbbcccvvv)
    public static lemmasDict: { [dictionaryLemma: string]: MarbleLemma } = {}

    static get(termId: string) {
        // termId = "SDBG:γένεσις:000001|SDBG:γένεσις:000002"
        const dictionaryLemma = termId.split(':')[1]

        const lemma = this.lemmasDict[dictionaryLemma]

        log('get', fmt({ dictionaryLemma, found: !!lemma }))
        log('get lemma', fmt({ lemma }))

        if (!lemma) {
            return undefined
        }

        return lemma
    }

    // Fetch Greek and Hebrew terms info and add to table
    static async fetch() {
        this.lemmasDict = {}
        await MarbleLemmas.fetchLemmas('sdbg')
        await MarbleLemmas.fetchLemmas('sdbh')
    }

    // Fetch sdbgs or sdbhs data from S3.
    static async fetchLemmas(prefix: string /* 'sdbg' or 'sdbh' */) {
        const url = `${routePrefix}/data/${prefix}s.json`

        const response = await retriableFetch(url, {})
        if (!response.ok) {
            throw Error(`${response.url}: ${response.statusText}`)
        }

        const jsons: any[] = await response.json()
        log('fetchLemmas', fmt({ url, count: jsons.length }))

        for (const json of jsons) {
            const newLemma = new MarbleLemma(prefix, json)
            let _lemma = this.lemmasDict[newLemma.dictionaryLemma]
            if (!_lemma) {
                this.lemmasDict[newLemma.dictionaryLemma] = newLemma
                _lemma = newLemma
            }

            _lemma.addMeanings(prefix, json)
        }
    }
}

// Not currently used and untested.
// At the moment we access lemmas by their id which we get from
// the Paratext enhanced resource text.
// At some point we may want to list the lemmas in a Scripture range and let the user pick.
// This class would be used for that.

// export class RangeToLemmaMap {
//     // Lemmas are present for each ref (bbbcccvvv)
//     private static refToLexMeanings: { [bbbcccvvv: string]: Set<LexMeaning> } = {}

//     // Max verse number in each chapter based on lemma references
//     private static bbbcccToMaxV: { [bbbccc: string]: number } = {}

//     static add(lemmas: Lemma[]) {
//         lemmas.forEach(lemma => {
//             lemma.lexMeanings.forEach(lexMeaning => {
//                 lexMeaning.references.forEach(ref => {
//                     // Get set corresponding to this lemma, create one if necessary
//                     let _set = this.refToLexMeanings[ref]
//                     if (!_set) {
//                         _set = new Set()
//                         this.refToLexMeanings[ref] = _set
//                     }

//                     if (!_set.has(lexMeaning)) _set.add(lexMeaning)  // Add lemma to set

//                     // Keep track of the highest verse number in each chapter.
//                     // We need this to iterate references across chapter boundaries.
//                     let bbbccc = ref.slice(0, 6)
//                     let v = parseInt(ref.slice(6, 9), 10)
//                     let max = this.bbbcccToMaxV[bbbccc] || 0
//                     if (v > max) this.bbbcccToMaxV[bbbccc] = v
//                 })
//             })
//         })
//     }

//     // Find all lexMeanings in the specified range(s)
//     static lexMeaningsForRefRanges(refRanges: RefRange[]): LexMeaning[] {
//         let lexMeanings = new Set<LexMeaning>()

//         refRanges.forEach(refRange => {
//             for (let ref of refRange.iterator(this.bbbcccToMaxV)) {
//                 let lexMeaningsForRef = this.refToLexMeanings[ref]
//                 if (lexMeaningsForRef) {
//                     lexMeaningsForRef.forEach(lexMeaning => {
//                         if (!lexMeanings.has(lexMeaning)) lexMeanings.add(lexMeaning)
//                     })
//                 }
//             }
//         })

//         let lemmas2 = Array.from(lexMeanings)
//         return _.sortBy(lemmas2, 'gloss')
//     }
// }

function greekTransliterate(lemma: string) {
    lemma = lemma.normalize('NFD') // separate letters from diacritics
    lemma = lemma.replace(/[\u0300-\u036f]/g, '') // remove diacritics

    const table = `α a
β b
γγ nγ
γκ nκ
γξ nξ
γχ nχ
γ g
δ d
ε e
ζ z
η ē
θ th
ι i
κ k
λ l
μ m
ν n
ξ x
ο o
π p
ῥ rh
ρ r
σ s 
ς s
τ t
aυ au
eυ eu
ēυ ēu
oυ ou
υi ui
υ y
φ ph
χ ch
ψ ps
ω ō
Α A
Β B
Γ G
Δ D
Ε E
Ζ Z
Η Ē
Θ Th
Ι I
Κ K
Λ L
Μ M
Ν N
Ξ X
Ο O
Π P
Ρ R
Σ S
Τ T
Υ Y
Φ Ph
Χ Ch
Ψ Ps
Ω Ō
῾ h`

    let trans = lemma
    table.split('\n').forEach((item) => {
        const parts = item.trim().split(' ')
        while (trans.includes(parts[0])) {
            trans = trans.replace(parts[0], parts[1])
        }
    })

    return lemma === trans ? '' : trans
}

export function transliterate(lemma: MarbleLemma) {
    return lemma.id.startsWith('sdbh')
        ? hebrewTransliterate(lemma.dictionaryLemma)
        : greekTransliterate(lemma.dictionaryLemma)
}

function getSenseString(lemma: MarbleLemma, meaning: LexMeaning) {
    if (lemma.meanings.length === 1) {
        return ''
    }

    try {
        const senseNumber = meaning.getMeaningNumber()
        return ` - ${senseNumber}`
    } catch (error) {
        return ''
    }
}

export function getTermSenseName(lemma: MarbleLemma, meaning: LexMeaning) {
    const { dictionaryLemma } = lemma
    const transliteration = transliterate(lemma)
    const meaningString = getSenseString(lemma, meaning)
    return `${dictionaryLemma} (${transliteration})${meaningString}`
}

// VideoToolbar allows controlling playing and recording by means of actions and variable of rt

import React, { FC, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { confirmAlert } from 'react-confirm-alert'
import { Dropdown, MenuItem } from 'react-bootstrap'
import { t } from 'i18next'

import { isAVTT } from '../app/slttAvtt'
import { ExportModal } from '../export/ExportModal'
import { UnresolvedTextNotification } from '../notifications/Notifications'
import PassageVideoSelector from '../passages/PassageVideoSelector'

import { completemulti, createmulti } from '../../models3/API'
import { Passage } from '../../models3/Passage'
import { PassageNote } from '../../models3/PassageNote'
import { MIN_SEGMENT_LENGTH } from '../../models3/PassageSegment'
import { PassageVideo } from '../../models3/PassageVideo'
import { Root } from '../../models3/Root'

import {
    RecordButton,
    StopButton,
    CreateNoteButton,
    TrashButton,
    PlayButton,
    PauseButton,
    SlttHelp,
    CutButton,
    BarsIcon,
    KeyTermButton
} from '../utils/Buttons'
import { displayError, displayInfo, systemError } from '../utils/Errors'
import { fmt } from '../utils/Fmt'
import { safeFileName, downloadWithFilename, createLink } from '../utils/Helpers'
import {
    PassageSegmentsIcon,
    PassageVideoReferenceIcon,
    DownloadIcon,
    DesktopIcon,
    ClipboardIcon,
    CopyIcon,
    FolderIcon
} from '../utils/Icons'
import { encodeOpus } from '../utils/Opus'
import VideoCompressor from '../utils/VideoCompressor'
import { deleteAudioSection, Section } from '../utils/Wav'

import { BaseRecordingFilePicker, uploadRecording } from './BaseRecordingFilePicker'
import { createVerseReference } from './VerseReferenceEditor'
import { AVTTRecordingState } from './VideoRecorder'
import { AudioContextFactory } from './WaveformVisualizer'
import { AudioEncodeType, getPassageAudio } from '../utils/DownloadPassage'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:VideoToolbar')

const deleteSectionOfAudio = async (rt: Root) => {
    try {
        const { passage, name, passageVideo, passageSegment, timeline } = rt
        if (!timeline.selectionPresent() || !passage || !passageVideo || !passageSegment) {
            return
        }

        const actualSegment = passageSegment.actualSegment(passage)
        const slices = await actualSegment.getPlayableSlicesForViewablePartOfSegment(passage)
        const { selectionStartTime, selectionEndTime } = timeline.getSelectionTimes()
        if (!slices.length) {
            return
        }

        const slice = slices[0]
        const sections: Section[] = [
            { start: slice.startPosition, end: actualSegment.timeToPosition(selectionStartTime) },
            { start: actualSegment.timeToPosition(selectionEndTime), end: slice.endPosition }
        ]

        const audioContext = AudioContextFactory.getAudioContext()
        const wavBlob = await deleteAudioSection(audioContext, slice.src, sections)
        const file = await encodeOpus(wavBlob)
        const video = await passage.uploadFile(file, new Date(), name)
        await passage.addPatchVideo(passageVideo, video, passageSegment, passageSegment.actualSegment(passage))

        rt.setPassageVideo(passageVideo)
        rt.setPassageSegment(passageSegment)
        rt.resetCurrentTime(passageSegment.actualSegment(passage).time + 0.01)
    } catch (error) {
        systemError(error)
    }
}

const allowAudioDeletion = (rt: Root) => {
    if (!rt.timeline.selectionPresent()) {
        return false
    }

    const { passageVideo, passage, passageSegment, iAmTranslator } = rt

    if (!(iAmTranslator && passageVideo && passage && passageSegment && passageVideo.segments.length > 1)) {
        return false
    }

    const { selectionStartTime, selectionEndTime } = rt.timeline.getSelectionTimes()
    const startIndex = passageVideo.timeToSegmentIndex(selectionStartTime)
    const endIndex = passageVideo.timeToSegmentIndex(selectionEndTime)
    if (startIndex === -1 || endIndex === -1 || startIndex !== endIndex) {
        return false
    }

    const actualSegment = passageSegment.actualSegment(passage)
    if (
        actualSegment.endPosition - actualSegment.position - (selectionEndTime - selectionStartTime) <
        MIN_SEGMENT_LENGTH
    ) {
        return false
    }

    return true
}

type RareFunctionsDropdownProps = {
    rt: Root
    setShowDetachedPlayer?: (value: boolean) => void
    openCopyPassageModal?: (passageToCopy: Passage) => void
}

async function downloadPassageVideo(rt: Root, passage: Passage, setProgressMessage: (message: string) => void) {
    const { currentVideos } = rt
    const { maxVideoSizeMB, compressedVideoResolution, compressedVideoQuality } = rt.project

    if (!(await VideoCompressor.checkIfServerRunning())) {
        setProgressMessage('')
        return
    }

    const compressor = new VideoCompressor(
        compressedVideoQuality,
        compressedVideoResolution,
        maxVideoSizeMB,
        setProgressMessage
    )
    return compressor.concatenateVideos(currentVideos, passage, -1, -1)
}

async function downloadPassageRecording(rt: Root, passage: Passage) {
    const setProgressMessage = passage.setCompressionProgressMessage.bind(passage)

    try {
        setProgressMessage(t('Initializing...'))
        const blob = await downloadPassageVideo(rt, passage, setProgressMessage)
        setProgressMessage('')
        return { blob }
    } catch (error) {
        setProgressMessage('')
        systemError(error)
        return { blob: undefined }
    }
}

async function uploadFile(file: File, _id: string, type: string, setProgressMessage: (message: string) => void) {
    const FILE_CHUNK_SIZE = 6_000_000
    const count = Math.ceil(file.size / FILE_CHUNK_SIZE)

    // Create a multi part upload
    const { uploadId, urls } = await createmulti(_id, type, count, true)
    const pieces = urls[0].split('/')
    const baseUrl = pieces.slice(0, 3).join('/')
    log('uploadFile/createmulti', fmt({ _id, type, count, uploadId, urls, baseUrl }))

    // delete axios.defaults.headers.put['Content-Type']

    const parts = []

    let errorCount = 0

    for (let index = 0; index < urls.length; ) {
        const start = index * FILE_CHUNK_SIZE
        const end = (index + 1) * FILE_CHUNK_SIZE
        const blob = index < urls.length ? file.slice(start, end) : file.slice(start)

        const options = {
            method: 'PUT',
            body: blob
        }

        try {
            log(`uploadFile/uploading[${index}]...`, fmt({ errorCount, url: urls[index] }))
            setProgressMessage(`Uploading ... ${index + 1}/${urls.length} ${'**********'.slice(0, errorCount)}`)

            const response = await fetch(urls[index], options)

            // Headers include extra double quotes at start and end.
            // Slice these off.
            const ETag = response.headers.get('ETag')?.slice(1, -1)
            log('uploadFile/response', fmt({ index, ETag }))
            if (!ETag) throw Error('uploadFile/No ETag returned')

            ++index
            errorCount = 0
            parts.push({
                ETag,
                PartNumber: index
            })
        } catch (error) {
            log('###uploadFile error', error)
            errorCount += 1
            if (errorCount > 10) {
                throw Error('Upload failed. Too many errors.')
            }
            continue
        }
    }

    const stage = baseUrl.includes('-prd') ? 'prd' : 'dev'
    const viewerUrl = `https://sltt-hosting-${stage}.s3.amazonaws.com/viewer.html?${_id}`

    // Complete the multipart upload operation
    try {
        log('uploadFile/uploading DONE', fmt({ parts }))
        const shortUrl = await completemulti(_id, uploadId, parts, viewerUrl)
        log('uploadFile/completemulti DONE')
        return shortUrl
    } catch (error) {
        throw Error('Could not complete upload.')
    }
}

async function createLinkToSelection(rt: Root, passage: Passage) {
    const { selectionStartTime, selectionEndTime } = rt.timeline.getSelectionTimes()
    const { currentVideos } = rt
    if (!passage) {
        return
    }
    if (selectionStartTime < 0 || selectionEndTime < 0) return

    const setProgressMessage = passage.setCompressionProgressMessage.bind(passage)

    const { maxVideoSizeMB, compressedVideoResolution, compressedVideoQuality } = rt.project
    try {
        setProgressMessage(t('Initializing...'))
        if (!(await VideoCompressor.checkIfServerRunning())) {
            setProgressMessage('')
            return
        }

        const compressor = new VideoCompressor(
            compressedVideoQuality,
            compressedVideoResolution,
            maxVideoSizeMB,
            setProgressMessage
        )
        const concatenatedData = await compressor.concatenateVideos(
            currentVideos,
            passage,
            selectionStartTime,
            selectionEndTime,
            'mp4'
        )

        const now = Date.now()

        const id = `${rt.project.name}/public/${now}.mp4`
        const shortUrl = await uploadFile(concatenatedData, id, 'video/mp4', setProgressMessage)

        await navigator.clipboard.writeText(shortUrl)
        displayInfo(`Link (${shortUrl}) copied to clipboard. Anyone can view this link.`)
    } catch (err) {
        const error = err as Error
        log(error)
        displayError(error.message)
        setProgressMessage('')
        return
    }

    setProgressMessage('')
}

async function createLinkToVideo(rt: Root) {
    const { passageVideo, currentTime, project } = rt
    if (!passageVideo) {
        return
    }
    const text = createLink({ project, itemId: passageVideo._id, time: currentTime })
    try {
        await navigator.clipboard.writeText(text)
        displayInfo(t('Link copied to clipboard. Only your team can view this link.'))
    } catch (error) {
        displayError(error, 'Failed to copy!')
    }
}

const copyRecordingToNewDraft = async (passage: Passage, passageVideo: PassageVideo, rt: Root) => {
    try {
        passage.setCompressionProgressMessage(t('Starting compression...'))
        const saveAsAudio = rt.currentVideos.viewableVideos.every((vv) => vv.video.isAudioOnly())
        const { blob: concatenatedBlob } = saveAsAudio
            ? await getPassageAudio(passage, passageVideo, AudioEncodeType.opus)
            : await downloadPassageRecording(rt, passage)

        const fileName = saveAsAudio ? 'concatenatedBlob.ogg' : 'concatenatedBlob.mp4'
        if (concatenatedBlob) {
            await uploadRecording({
                files: [new File([concatenatedBlob], fileName, { type: concatenatedBlob.type })],
                rt,
                passage,
                copyVerseReferences: false,
                t
            })
        }
    } catch (err) {
        displayError(t('recordingUnsuccessfulCopy'))
    } finally {
        passage.setCompressionProgressMessage('')
    }
}

const copyAudioRecordingToPassageResource = async (passage: Passage, passageVideo: PassageVideo, rt: Root) => {
    try {
        passage.setCompressionProgressMessage(t('Starting compression...'))
        const { blob: concatenatedBlob } = await getPassageAudio(passage, passageVideo, AudioEncodeType.opus)
        if (concatenatedBlob) {
            const document = passage.createPassageDocument()
            await document.uploadAudioFile(
                new File([concatenatedBlob], 'concatenatedBlob.ogg', { type: concatenatedBlob.type }),
                rt.name
            )
        }
    } catch (err) {
        displayError(t('passageResourceUnsuccessfulCreation'))
    } finally {
        passage.setCompressionProgressMessage('')
    }
}

const RareFunctionsDropdown: FC<RareFunctionsDropdownProps> = ({ rt, setShowDetachedPlayer }) => {
    const [blob, setBlob] = useState<Blob>()
    const [exportModalOpen, setExportModalOpen] = useState(false)

    const { portion, passage, passageVideo, canPlayThrough, useMobileLayout, dateFormatter } = rt

    const compressingVideo = passage?.videoBeingCompressed || false
    const compressorAvailable = !useMobileLayout && canPlayThrough && !compressingVideo

    useEffect(() => {
        let blobHref = ''

        if (blob && passage) {
            blobHref = URL.createObjectURL(blob)
            const fileExtension = blob.type.startsWith('audio/')
                ? blob.type.endsWith('opus')
                    ? '.opus'
                    : '.wav'
                : '.mp4'
            let fileName = `${passage.name}-${dateFormatter.format(new Date(Date.now()))}`
            fileName = `${safeFileName(fileName)}${fileExtension}`
            downloadWithFilename(blobHref, fileName)
        }

        return () => {
            if (blobHref) {
                URL.revokeObjectURL(blobHref)
            }
        }
    }, [blob, dateFormatter, passage])

    return (
        <Dropdown className="video-toolbar-hamburger-menu" id="video-toolbar-hamburger-menu" pullRight>
            <Dropdown.Toggle className="video-toolbar-hamburger-menu-toggle" noCaret>
                <BarsIcon className="main-video-see-more-icon" tooltip={t('See more options')} />
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {exportModalOpen && portion && (
                    <ExportModal rt={rt} exportFromType="passageVideo" portion={portion} setOpen={setExportModalOpen} />
                )}
                <MenuItem
                    className="video-toolbar-hamburger-menu-item"
                    onSelect={() => setExportModalOpen(true)}
                    disabled={!passageVideo || compressingVideo}
                >
                    <>
                        <DownloadIcon className="sl-download-full-video-icon fa-fw" tooltip="" />
                        {t('Export')}
                    </>
                </MenuItem>
                {!rt.currentVideos.viewableVideos.some((vv) => vv.video.isAudioOnly()) && (
                    <MenuItem
                        className="video-toolbar-hamburger-menu-item"
                        onSelect={() =>
                            passage &&
                            downloadPassageRecording(rt, passage).then((recording) => setBlob(recording.blob))
                        }
                        disabled={!passage || !passageVideo || !compressorAvailable}
                    >
                        <>
                            <DownloadIcon className="sl-download-full-video-icon fa-fw" tooltip="" />
                            {t('Export video')}
                        </>
                    </MenuItem>
                )}
                {!rt.useMobileLayout && rt.iAmTranslator && (
                    <MenuItem
                        className="video-toolbar-hamburger-menu-item"
                        onSelect={() => passage && passageVideo && copyRecordingToNewDraft(passage, passageVideo, rt)}
                        disabled={!passage || !passageVideo}
                    >
                        <>
                            <CopyIcon className="main-video-clipboard-icon fa-fw" tooltip="" />
                            {t('recordingCreateConcatenatedDraft')}
                        </>
                    </MenuItem>
                )}
                {!rt.useMobileLayout &&
                    rt.iAmInterpreter &&
                    rt.currentVideos.viewableVideos.every((vv) => vv.video.isAudioOnly()) && (
                        <MenuItem
                            className="video-toolbar-hamburger-menu-item"
                            onSelect={() =>
                                passage &&
                                passageVideo &&
                                copyAudioRecordingToPassageResource(passage, passageVideo, rt)
                            }
                            disabled={!passage || !passageVideo}
                        >
                            <>
                                <FolderIcon className="main-video-clipboard-icon fa-fw" tooltip="" />
                                {t('recordingCopyDraftToPassageResource')}
                            </>
                        </MenuItem>
                    )}
                {setShowDetachedPlayer && (
                    <MenuItem
                        className="video-toolbar-hamburger-menu-item"
                        onSelect={() => setShowDetachedPlayer?.(true)}
                        disabled={compressingVideo}
                    >
                        <>
                            <DesktopIcon className="detach-icon fa-fw" tooltip="" />
                            {t('recordingDetachPlayer')}
                        </>
                    </MenuItem>
                )}
                <MenuItem
                    className="video-toolbar-hamburger-menu-item"
                    onSelect={() => createLinkToVideo(rt)}
                    disabled={!passageVideo}
                >
                    <>
                        <ClipboardIcon className="main-video-clipboard-icon fa-fw" tooltip="" />
                        {t('recordingCopyLinkForRecording')}
                    </>
                </MenuItem>
                {!isAVTT && passage && (
                    <MenuItem
                        disabled={!rt.timeline.selectionPresent() || !compressorAvailable}
                        className="video-toolbar-hamburger-menu-item"
                        onSelect={() => createLinkToSelection(rt, passage)}
                    >
                        <>
                            <ClipboardIcon className="main-video-clipboard-icon fa-fw" tooltip="" />
                            {t('recordingCopyLinkForSelection')}
                        </>
                    </MenuItem>
                )}
            </Dropdown.Menu>
        </Dropdown>
    )
}

interface IVideoToolbar {
    rt: Root
    playAllVideos: () => void
    playCurrentVideo: () => void
    pausePlayback: () => void
    pauseRecording: () => void
    record: () => void
    stopRecording: () => void
    setShowDetachedPlayer?: (value: boolean) => void
    recordingState: AVTTRecordingState
    resumeRecording: () => void
    openBiblicalTermMarkerEditor: () => void
}

class VideoToolbar extends React.Component<IVideoToolbar> {
    onSelectVideo = async (video: PassageVideo) => {
        if (video.removed) {
            await this.undeleteVideo(video)
        } else {
            await this.makeSelection(video)
        }
    }

    goToNote = async (note: PassageNote) => {
        const { rt } = this.props
        const { passage } = rt
        if (!passage) {
            return
        }
        let video = passage.findVideo(note._id) || null
        video = video?.baseVideo(passage) || video
        await rt.setPassage(passage)
        await rt.setPassageVideo(video)
        rt.setNote(note)
    }

    createPassageSegment = () => {
        const _createPassageSegment = async () => {
            const { rt } = this.props
            const { passage, passageVideo, passageSegment } = rt
            if (!passage || !passageVideo || !passageSegment || passageSegment.videoPatchHistory.length > 0) return
            const adjustedTime = passageVideo.timeToPosition(passage, rt.currentTime)
            if (adjustedTime !== undefined) {
                await passageVideo.addSegment(adjustedTime)
                rt.resetCurrentTime(rt.currentTime) // will force current segment to be set
            } else {
                throw Error(`${t('System Error')}: PassageVideo, Passage, or PassageSegment should not be null`)
            }
        }

        _createPassageSegment().catch(displayError)
    }

    deleteVideo = async () => {
        const { rt } = this.props
        const { passage, passageVideo } = rt
        if (!passage || !passageVideo) {
            displayError(`${t('System Error')}: Status change failed`)
            return
        }

        this.confirmDeletion(() => {
            if (!passageVideo) {
                displayError(`${t('System Error')}: Status change failed`)
                return
            }

            passage
                .removeVideo(passageVideo._id)
                .then(() => {
                    const i = passage.videosNotDeleted.length
                    rt.setPassageVideo(i > 0 ? passage.videosNotDeleted[i - 1] : null)
                })
                .catch(displayError)
        })
    }

    confirmDeletion(doDeletion: () => void) {
        confirmAlert({
            title: t('recordingDeleteQuestion'),
            message: t('recordingDeleteWarning'),
            confirmLabel: t('recordingDelete'),
            cancelLabel: t('recordingKeep'),
            onConfirm: doDeletion
        })
    }

    async undeleteVideo(passageVideo: PassageVideo) {
        const { rt } = this.props
        const { passage, iAmAdmin } = rt
        if (!passage || !iAmAdmin) return

        try {
            await passage.undeleteVideo(passageVideo)
            await this.makeSelection(passageVideo)
        } catch (error) {
            displayError(error)
        }
    }

    async makeSelection(passageVideo: PassageVideo) {
        const { rt } = this.props
        const { passage } = rt
        if (!passage) return

        try {
            await rt.setPassageVideo(passageVideo)
        } catch (error) {
            displayError(error)
        }
    }

    render() {
        const {
            rt,
            playAllVideos,
            playCurrentVideo,
            record,
            stopRecording,
            setShowDetachedPlayer,
            recordingState,
            resumeRecording,
            pausePlayback,
            pauseRecording,
            openBiblicalTermMarkerEditor
        } = this.props
        const {
            passage,
            passageVideo,
            passageSegment,
            playing,
            recording,
            hardNotificationCutoff,
            iAmInterpreter,
            iAmTranslator,
            iAmConsultant,
            canViewConsultantOnlyFeatures,
            editingSegment,
            useMobileLayout,
            dateFormatter,
            canPlayThrough,
            project
        } = rt
        const { plans } = project

        const selectionPresent = rt.timeline.selectionPresent()

        const playShown = !playing && !recording
        const recordShown = !playing && recordingState !== 'RECORDING'
        const pauseShown = !recordShown

        const compressingVideo = passage?.videoBeingCompressed || false
        const enabled = !editingSegment && !compressingVideo

        const stopShown = recording
        const stopEnabled = enabled && (recordingState === 'RECORDING' || recordingState === 'PAUSED')
        const isNotPlayingNorRecording = enabled && !recording && !playing

        const playEnabled = canPlayThrough && !recording && enabled

        const recordEnabled =
            (passage && isNotPlayingNorRecording && iAmTranslator) || ['INITIALIZED', 'PAUSED'].includes(recordingState)
        const createNoteEnabled = !!passageVideo && isNotPlayingNorRecording && iAmInterpreter

        const createSegmentEnabled =
            isNotPlayingNorRecording && !!passageSegment && passageSegment.videoPatchHistory.length === 0
        const verseReferenceEnabled = isNotPlayingNorRecording && iAmConsultant
        const cutButtonEnabled = isNotPlayingNorRecording && allowAudioDeletion(rt)
        const createBiblicalTermMarkerEnabled = isNotPlayingNorRecording && rt.iAmTranslator

        const dropdownVideos = passage
            ? (rt.iAmAdmin ? passage.videos : passage.videosNotDeleted).filter((v) => !v.isPatch)
            : []

        const cutoff = hardNotificationCutoff()
        const activeVideos = dropdownVideos.filter((v) => !v.removed)
        let unresolvedNote: PassageNote | null = null
        if (passage && activeVideos.length) {
            unresolvedNote = activeVideos[activeVideos.length - 1].mostRecentUnresolvedNote(
                passage,
                cutoff,
                canViewConsultantOnlyFeatures
            )
        }

        const displayDeleteVideo = !!passageVideo
        const unresolvedTextNotificationDisplayed = !!passage && unresolvedNote && !!passageVideo
        const unresolvedTextNotificationEnabled = unresolvedTextNotificationDisplayed && enabled
        const displayPassageVideoSelector = !!passage && dropdownVideos.length > 0
        const changeMainVideoEnabled = displayPassageVideoSelector && enabled

        // If I try to move the following to a .css file it stops working.
        // I have no idea why.
        const passageSegmentIconStyle = {
            cursor: 'pointer',
            verticalAlign: 'middle'
        }

        return (
            <div className="video-toolbar">
                <div className="video-toolbar-left">
                    {playShown && (
                        <PlayButton
                            enabled={playEnabled}
                            selectionPresent={selectionPresent}
                            tooltip={selectionPresent ? t('Play selection.') : t('Play.')}
                            className="main-video-play-button"
                            onClick={async (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
                                if (e.shiftKey) {
                                    playAllVideos()
                                } else {
                                    playCurrentVideo()
                                }
                            }}
                        />
                    )}

                    {pauseShown && playing && (
                        <PauseButton
                            className="main-video-pause-button"
                            enabled
                            tooltip={t('Pause.')}
                            onClick={() => pausePlayback()}
                        />
                    )}

                    {pauseShown && recording && (
                        <PauseButton
                            className="main-video-pause-button"
                            enabled
                            tooltip={t('Pause.')}
                            onClick={() => pauseRecording()}
                        />
                    )}

                    {recordShown && !recording && (
                        <SlttHelp
                            id="passages.html"
                            tooltip={t('Record passage. Click square Stop icon to end.')}
                            place="bottom"
                        >
                            <RecordButton
                                tooltip=""
                                selectionPresent={false}
                                enabled={recordEnabled}
                                className="sl-record-button sl-record-video-button"
                                onClick={() => record()}
                            />
                        </SlttHelp>
                    )}

                    {recordShown && recording && (
                        <RecordButton
                            tooltip={t('Continue recording')}
                            selectionPresent={false}
                            enabled={recordEnabled}
                            className="sl-record-button sl-record-video-button"
                            onClick={() => resumeRecording()}
                        />
                    )}

                    {stopShown && (
                        <StopButton
                            className="main-video-stop-button"
                            enabled={stopEnabled}
                            tooltip={t('Stop and save recording.')}
                            onClick={stopRecording}
                        />
                    )}

                    {passage && (
                        <BaseRecordingFilePicker
                            enabled={recordEnabled && !recording}
                            rt={rt}
                            passage={passage}
                            className="main-upload-file-button"
                        />
                    )}

                    {!useMobileLayout && (
                        <>
                            <CreateNoteButton
                                enabled={createNoteEnabled}
                                onClick={() => {
                                    if (passage && passageVideo) {
                                        const notePosition = passageVideo.timeToPosition(passage, rt.currentTime)
                                        const video = passageVideo.timeToVideo(passage, rt.currentTime)
                                        log('createNote', rt.currentTime, notePosition, video._id)
                                        const note = video.createNote.bind(video)(notePosition)
                                        note.time = rt.currentTime
                                        rt.setNote(note)
                                    }
                                }}
                            />
                            <PassageSegmentsIcon
                                className="sl-create-passage-segment"
                                style={passageSegmentIconStyle}
                                tooltip={t('recordingCreateNewSegment')}
                                place="bottom"
                                id="segments.html#segmentscreate"
                                enabled={createSegmentEnabled}
                                onClick={this.createPassageSegment}
                            />
                            <SlttHelp id="biblicalTerms.html" tooltip={t('createBiblicalTermMarker')} place="bottom">
                                <KeyTermButton
                                    className="video-toolbar-button key-term-marker-button"
                                    onClick={openBiblicalTermMarkerEditor}
                                    enabled={createBiblicalTermMarkerEnabled}
                                />
                            </SlttHelp>
                            <PassageVideoReferenceIcon
                                className="sl-create-passage-segment"
                                style={passageSegmentIconStyle}
                                onClick={() => createVerseReference(rt)}
                                tooltip={t('Create new verse reference')}
                                enabled={verseReferenceEnabled}
                            />
                            {passageVideo?.isAudioOnly() && (
                                <CutButton
                                    onClick={() => deleteSectionOfAudio(rt)}
                                    className="video-toolbar-button scissors-icon"
                                    tooltip={t('Delete section')}
                                    enabled={cutButtonEnabled}
                                />
                            )}
                        </>
                    )}
                </div>

                <div className="video-toolbar-right">
                    {displayDeleteVideo && !useMobileLayout && (
                        <TrashButton
                            enabled={enabled}
                            buttonClassName=""
                            className="delete-video-button"
                            onClick={this.deleteVideo}
                            tooltip={t('recordingDelete')}
                        />
                    )}
                    {displayPassageVideoSelector && (
                        <PassageVideoSelector
                            enabled={changeMainVideoEnabled}
                            videos={dropdownVideos}
                            currentVisibleVideo={passageVideo || dropdownVideos[dropdownVideos.length - 1]}
                            onSelect={this.onSelectVideo}
                            dateFormatter={dateFormatter}
                            passageVideoNotification={rt}
                            plan={plans.length ? plans[0] : undefined}
                        />
                    )}
                    {unresolvedTextNotificationDisplayed && !useMobileLayout && (
                        <UnresolvedTextNotification
                            className="video-toolbar-notification sl-fa-button"
                            tooltip={t('Unresolved notes exist')}
                            onClick={async () => {
                                if (unresolvedTextNotificationEnabled && unresolvedNote) {
                                    this.goToNote(unresolvedNote)
                                }
                            }}
                        />
                    )}
                    <RareFunctionsDropdown {...{ rt, setShowDetachedPlayer }} />
                </div>
            </div>
        )
    }
}

export default observer(VideoToolbar)

import React, { FC } from 'react'
import { observer } from 'mobx-react'
import { LineChart, Line, XAxis, YAxis } from 'recharts'
import { useTranslation } from 'react-i18next'

import { expectedCompletionDate, getGraphData } from './ProgressUtils'

import { FilteredPortionPassages } from '../StatusEditor'

import { RootConsumer } from '../../app/RootContext'
import ProjectDataFilter from '../../utils/ProjectDataFilter'

import { Passage } from '../../../models3/Passage'
import { Portion } from '../../../models3/Portion'
import { Project } from '../../../models3/Project'
import { Root } from '../../../models3/Root'

import './ProgressGraph.css'

interface IProgressGraphHeader {
    project: Project
    currentValue: string
    setCurrentPortion: (value: Portion) => void
    setCurrentPassage: (portion: Portion, passage: Passage) => void
    setShowAll: () => void
    setShowCurrentPortion: () => void
    setShowCurrentPassage: () => void
}

const ProgressGraphHeader: FC<IProgressGraphHeader> = ({
    setCurrentPortion,
    setCurrentPassage,
    setShowCurrentPortion,
    setShowCurrentPassage,
    setShowAll,
    currentValue,
    project
}) => {
    const { t } = useTranslation()

    const { displayName } = project

    return (
        <RootConsumer>
            {() => (
                <div className="progress-graph-header">
                    <h4 className="progress-graph-project-name">
                        <b>{displayName}</b>
                    </h4>
                    <div className="progress-graph-header-item">
                        {t('Report of')}
                        <ProjectDataFilter
                            portions={project.portions}
                            currentValue={currentValue}
                            setShowAll={setShowAll}
                            setShowCurrentPassage={setShowCurrentPassage}
                            setShowCurrentPortion={setShowCurrentPortion}
                            setPortion={setCurrentPortion}
                            setPassage={setCurrentPassage}
                        />
                    </div>
                </div>
            )}
        </RootConsumer>
    )
}

interface IProgressGraphFooter {
    rt: Root
    data: { name: string; date: Date; percent: number }[]
}

const ProgressGraphFooter: FC<IProgressGraphFooter> = ({ rt, data }) => {
    const { t } = useTranslation()

    const entries = data.map((entry) => {
        const { date, percent } = entry
        return {
            date,
            percent
        }
    })
    const sortedEntries = [...entries].sort((a, b) => a.date.getTime() - b.date.getTime())
    if (sortedEntries[sortedEntries.length - 1].percent === 100) {
        return <div>{t('Already completed')}</div>
    }
    const { dateFormatter } = rt
    const completionDate = expectedCompletionDate(sortedEntries, new Date())
    let formattedCompletionDate = ''
    if (completionDate !== undefined) {
        formattedCompletionDate = dateFormatter.format(completionDate)
    }
    return (
        <div>
            {completionDate !== undefined
                ? t('Projected completion date: {{date}}', { date: formattedCompletionDate })
                : t('Projected completion date: N/A')}
        </div>
    )
}

interface IProgressGraph {
    data: { name: string; date: Date; percent: number }[]
}

export const ProgressGraph: FC<IProgressGraph> = ({ data }) => {
    const entries = data.map((entry) => {
        const { name, percent } = entry
        return {
            name,
            percent
        }
    })
    return (
        <div>
            <LineChart width={400} height={400} data={entries}>
                <Line type="linear" dataKey="percent" stroke="#8884d8" isAnimationActive={false} />
                <XAxis dataKey="name" />
                <YAxis domain={[0, 100]} />
            </LineChart>
        </div>
    )
}

interface IProgressGraphPage {
    rt: Root
    currentSelectedOption: string
    portionPassages: FilteredPortionPassages[]
    setCurrentPortion: (value: Portion) => void
    setCurrentPassage: (portion: Portion, passage: Passage) => void
    setShowAll: () => void
    setShowCurrentPortion: () => void
    setShowCurrentPassage: () => void
}

export const ProgressGraphPage: FC<IProgressGraphPage> = observer(
    ({
        rt,
        currentSelectedOption,
        portionPassages,
        setShowAll,
        setShowCurrentPassage,
        setShowCurrentPortion,
        setCurrentPassage,
        setCurrentPortion
    }) => {
        const { t } = useTranslation()

        const { project, uiLanguage } = rt
        const passages = portionPassages.flatMap((pp) => pp.passages)
        const plan = project.plans[0]
        const data = plan ? getGraphData(passages, plan, uiLanguage) : []

        return (
            <div>
                <ProgressGraphHeader
                    project={project}
                    currentValue={currentSelectedOption}
                    setShowAll={setShowAll}
                    setShowCurrentPassage={setShowCurrentPassage}
                    setShowCurrentPortion={setShowCurrentPortion}
                    setCurrentPassage={setCurrentPassage}
                    setCurrentPortion={setCurrentPortion}
                />
                <div className="progress-graph-separator" />
                {data.length === 0 && t('No data available')}
                {data.length > 0 && (
                    <>
                        <div className="progress-graph-table">
                            <ProgressGraph data={data} />
                        </div>
                        <div className="progress-graph-separator" />
                        <ProgressGraphFooter {...{ rt, data }} />
                    </>
                )}
            </div>
        )
    }
)

import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { useAppRoot } from '../app/RootContext'
import MembersByRoleView from '../projectSettings/members/MembersByRoleView'
import { CancelButton, OKButton } from './Buttons'
import { Switch } from './Switch'
import { systemError } from './Errors'
import { LoadingIcon } from './Icons'
import './ProjectModal.css'

interface ProjectDeleteResultProps {
    projectName: string
    setOpenModal: (projectName: string) => void
}

const ProjectDeleteResult = ({ projectName, setOpenModal }: ProjectDeleteResultProps) => {
    const { t } = useTranslation()
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState('')
    const appRoot = useAppRoot()

    useEffect(() => {
        const deleteProject = async () => {
            try {
                setLoading(true)
                const projectRoot = appRoot.rtsMap.get(projectName)
                if (projectRoot) {
                    await appRoot.deleteProject(projectName, projectRoot.group)
                } else {
                    setError(t('Project to delete was not found!'))
                }
            } catch (e) {
                console.log(e)
                systemError(e)
                setError(t('Could not delete.'))
            }
            setLoading(false)
        }
        deleteProject()
    }, [appRoot, projectName, t])

    if (loading) {
        return (
            <Modal.Body>
                <div className="project-modal-loading-message-parent">
                    <LoadingIcon className="" />
                    <span className="project-modal-loading-message">{t('Deleting project...')}</span>
                </div>
            </Modal.Body>
        )
    }

    if (error) {
        return <Modal.Body>{error}</Modal.Body>
    }

    return (
        <>
            <Modal.Body>{t('Successfully deleted project.')}</Modal.Body>
            <Modal.Footer>
                <div className="project-modal-footer-buttons">
                    <OKButton
                        enabled
                        onClick={() => {
                            setOpenModal('')
                            appRoot.reinitializeProjects()
                        }}
                        buttonClassName=""
                        className="ok-button"
                        tooltip={t('OK')}
                    />
                </div>
            </Modal.Footer>
        </>
    )
}

interface ProjectDeleteModalProps {
    projectName: string
    setOpenModal: (projectName: string) => void
}

export const ProjectDeleteModal = ({ projectName, setOpenModal }: ProjectDeleteModalProps) => {
    const { t } = useTranslation()
    const [loading, setLoading] = useState(true)
    const [showResultPage, setShowResultPage] = useState(false)
    const [isOk, setIsOk] = useState(false)
    const appRoot = useAppRoot()

    const projectRoot = appRoot.rtsMap.get(projectName)

    useEffect(() => {
        let mounted = true

        async function initializeRoots() {
            if (!projectRoot) {
                return
            }

            setLoading(true)

            await projectRoot.initialize()

            if (mounted) {
                setLoading(false)
                setIsOk(!projectRoot.project.videod())
            }
        }
        initializeRoots()

        return () => {
            mounted = false
        }
    }, [projectRoot])

    const showModalBodyAndFooter = () => {
        if (!projectRoot) {
            return (
                <Modal.Body>
                    <div className="project-modal-loading-message-parent">
                        <span className="project-modal-warning-message">{t('Cannot find project to delete!')}</span>
                    </div>
                </Modal.Body>
            )
        }

        if (loading) {
            return (
                <Modal.Body>
                    <div className="project-modal-loading-message-parent">
                        <LoadingIcon className="" />
                        <span className="project-modal-loading-message">{t('Loading project...')}</span>
                    </div>
                </Modal.Body>
            )
        }

        if (showResultPage) {
            return <ProjectDeleteResult projectName={projectRoot.name} setOpenModal={setOpenModal} />
        }

        return (
            <>
                <Modal.Body>
                    <div className="project-modal-section">
                        <h4>{t('Members')}</h4>
                        <div>
                            <MembersByRoleView project={projectRoot.project} />
                        </div>
                        <h4>{t('Plan')}</h4>
                        <div>
                            <ol>
                                {projectRoot.project.plans.map((plan) =>
                                    plan.viewableStages.map((stage) => <li key={stage._id}>{stage.name}</li>)
                                )}
                            </ol>
                        </div>
                        <h4>{t('Portions')}</h4>
                        <div>
                            <ol>
                                {projectRoot.project.portions.map((portion) => (
                                    <li key={portion._id}>
                                        {portion.name} {portion.videod() && <i className="fas fa-fw fa-video" />}
                                    </li>
                                ))}
                            </ol>
                        </div>
                    </div>
                    <div className="project-modal-warning-message">
                        {projectRoot.project.videod() && (
                            <Switch
                                value={isOk}
                                setValue={(value) => setIsOk(value)}
                                tooltip=""
                                className="project-modal-switch"
                            >
                                <label>{t('Are you sure? This project has recordings!')}</label>
                            </Switch>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="project-modal-footer-buttons">
                        <OKButton
                            enabled={isOk}
                            onClick={() => setShowResultPage(true)}
                            buttonClassName=""
                            className="ok-button"
                            tooltip={t('Delete project')}
                        />
                        <CancelButton
                            enabled
                            onClick={() => setOpenModal('')}
                            className="cancel-button"
                            tooltip={t('Cancel')}
                        />
                    </div>
                </Modal.Footer>
            </>
        )
    }

    return (
        <Modal show onHide={() => setOpenModal('')} backdrop="static">
            <Modal.Header closeButton>
                <h3>
                    {t('Delete Project: {{projectName}}', {
                        projectName: projectRoot?.project?.getFormattedDisplayName() ?? ''
                    })}
                </h3>
            </Modal.Header>
            {showModalBodyAndFooter()}
        </Modal>
    )
}

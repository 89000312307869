// Play video based on events signaled on Root.
// rt.passageVideo is the video that is played.
// Handles stepping through passages on "play all passages" event

import React, { Component } from 'react'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import 'react-input-slider/dist/input-slider.css'

import VideoPlayer from './VideoPlayer'

import { Root } from '../../models3/Root'
import { PassageVideo } from '../../models3/PassageVideo'

import SegmentLabelsPosition from '../segments/SegmentLabelsPosition'
import { displayError } from '../utils/Errors'
import { fmt } from '../utils/Fmt'

import './Video.css'

/**
 * RootVideoPlayer - communicate to/from root to player
 *    VideoPlayer - handle changes at segment/patch boundaries
 *       CoreVideoPlayer - On request select/play main video or patch. Manage time poller.
 */

// Sticking an - in VideoPlayer in order to allow using chrome developer
// tools log filtering to distinguish between VideoPlayer and RootVideoPlayer
// messages.
// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:RootVideo-Player')

interface IRootVideoPlayer {
    rt: Root
    showSegmentLabels?: boolean
    setContainerHeight: (height: number) => void
    initialTime?: number
}

@observer
export default class RootVideoPlayer extends Component<IRootVideoPlayer> {
    playAllInProgress = false
    // @observable passageVideo: PassageVideo | null = null

    videoPlayer: VideoPlayer | null = null

    resetTime = 0 // If resetTimeRequested, reset time to this point when play ends

    resetTimeRequested = false

    rootVideoPlayerRef = React.createRef<HTMLDivElement>()

    @observable videoWidth = 0

    constructor(props: IRootVideoPlayer) {
        super(props)
        const { rt } = this.props

        this.play = this.play.bind(this)
        this.playAll = this.playAll.bind(this)
        this.stopAndDoNotReset = this.stopAndDoNotReset.bind(this)
        this.stopAndReset = this.stopAndReset.bind(this)
        this.setCurrentTime = this.setCurrentTime.bind(this)

        this.onEnded = this.onEnded.bind(this)
        this.abandonPlayAllInProgress = this.abandonPlayAllInProgress.bind(this)
        this.onCanPlayThrough = this.onCanPlayThrough.bind(this)
        this.onSegmentChanged = this.onSegmentChanged.bind(this)
        this.onPlayingStatus = this.onPlayingStatus.bind(this)
        this.setVideoWidth = this.setVideoWidth.bind(this)
        this.setVideoHeight = this.setVideoHeight.bind(this)
        this.videoPlayerStopped = this.videoPlayerStopped.bind(this)
        this.getVideoPlayer = this.getVideoPlayer.bind(this)

        this.addListeners(rt)

        this.setPassageVideo()
    }

    componentDidMount() {
        const { rt } = this.props

        this.setVideoHeight()
        this.addListeners(rt)
    }

    componentDidUpdate(prevProps: IRootVideoPlayer) {
        const { rt } = this.props

        this.setVideoHeight()
        // componentDidUpdate gets called when we switch projects giving us a chance to re-add the listeners
        // if they were removed by componentWillUnmount above.
        if (prevProps.rt.name !== rt.name) {
            this.removeListeners(prevProps.rt)
            this.addListeners(rt)
        }
    }

    componentWillUnmount() {
        const { rt } = this.props

        /**
         * When we switch projects sometimes this gets called.
         * componentDidUpdate will re-add them if necessary.
         */
        this.removeListeners(rt)
    }

    onCanPlayThrough(video: PassageVideo, duration: number) {
        const { rt } = this.props
        const { duration: videoDuration } = video
        // log('onCanPlayThrough', fmt({video, duration, videoDuration}))

        if (duration === Infinity) {
            log('### Infinite duration reported by player, should not happen')
            return
        }

        if (videoDuration && videoDuration !== Infinity && videoDuration > 0.1) return

        /**
         * The video was originally recorded using a technique (e.g. webcam) that did
         * not include a duration. The video player has returned to us a duration
         * after loading this video. Update the video duration including the duration
         * of its segment.
         */
        video
            .setDuration(duration)
            .then(() => {
                // force VideoPositionBar to redraw with correct duration
                rt.setDuration(duration)
            })
            .catch(displayError)
    }

    onPlayingStatus(playing: boolean) {
        const { rt } = this.props
        if (playing === rt.playing) return

        const { resetTime, resetTimeRequested } = this
        rt.playing = playing
        log('onPlayingStatus', fmt({ playing, resetTimeRequested, resetTime }))

        // If user requested to reset time at end of play, do so
        if (!playing && resetTimeRequested) {
            setTimeout(() => rt.resetCurrentTime(resetTime), 800)
            this.resetTimeRequested = false
        }
    }

    onSegmentChanged(segmentIndex: number) {
        const { rt } = this.props
        const { passage, passageVideo } = rt

        if (!passageVideo || !passage) {
            log('onSegmentChange !passageVideo || !passage')
            return
        }

        log('onSegmentChanged', fmt({ segmentIndex }))
        const segment = passageVideo.segments[segmentIndex]
        if (segment._id === passageVideo?._id) return

        rt.setPassageSegment(segment)
    }

    onEnded() {
        const { rt } = this.props
        const { passage, portion, passageVideo } = rt
        log(`onEnded playAll=${this.playAllInProgress}, ${passageVideo && passageVideo._id}`)

        if (!this.playAllInProgress) return

        // move to next videoPassage when doing "play all passages"

        if (!portion || !passage) {
            this.abandonPlayAllInProgress('no portion or no passage')
            return
        }

        const { passages } = portion
        const playablePassages = passages.filter((p) => p.videosNotDeleted.length > 0)
        const passageIndex = playablePassages.findIndex((p) => p._id === passage._id)
        log(`onEnded playAll passageIndex=${passageIndex}`)
        if (passageIndex < 0) {
            this.abandonPlayAllInProgress('could not find passage')
            return
        }

        if (passageIndex >= playablePassages.length - 1) {
            this.abandonPlayAllInProgress('onEnded playAll stop')
            return
        }

        const nextPassage = playablePassages[passageIndex + 1]
        log('nextPassage', nextPassage._id, this.playAllInProgress)

        rt.setPassage(nextPassage).catch(displayError)

        // let nonPatchVideos = nextPassage.videosNotDeleted.filter(v => !v.isPatch)
        // let video = nonPatchVideos.slice(-1)[0]
        // if (!video) {
        //     this.abandonPlayAllInProgress('video had no non-patches')
        //     return
        // }

        // log('onEnded playAll play next', video._id)
        // this.passageVideo = video // trigger display/play of next passage
    }

    // Invoked externally based on event
    // Sets the currently active video as well as the current time in that video
    setCurrentTime(newTime: number) {
        const vp = this.getVideoPlayer()
        log('setCurrentTime2', fmt({ newTime, vp: vp && '*present*' }))

        if (vp) {
            vp.setCurrentTime(newTime)
        }
    }

    // When root.passageVideo changes setup to display the newly
    // chosen value.
    setPassageVideo = () => {
        const { rt } = this.props
        const { passageVideo, currentVideos } = rt
        if (!passageVideo) return

        const { playAllInProgress } = this

        log('setPassageVideo', fmt({ playAllInProgress, passageVideo }))

        const vp = this.getVideoPlayer()
        if (vp) {
            vp.stop()
        }

        currentVideos.waitUntilDownloaded().then(() => {
            log('passageVideoHasChanged downloaded')
            if (this.playAllInProgress) this.play()
        })
    }

    setVideoHeight() {
        const { current } = this.rootVideoPlayerRef
        const { setContainerHeight } = this.props
        if (current) {
            const { width } = current.getBoundingClientRect()
            let height = width * 0.5625 // 16:9 aspect ratio
            height = Math.min(height, window.innerHeight - 75)
            setContainerHeight(height)
        }
    }

    setVideoWidth(width: number) {
        this.videoWidth = width
    }

    getVideoPlayer() {
        const { videoPlayer } = this
        if (!videoPlayer) log('### no videoPlayer present')
        return videoPlayer
    }

    removeListeners(rt: Root) {
        if (!rt.rootVideoPlayerListenersAdded) return
        log('removeListeners', rt.project.name)

        rt.removeListener('play', this.play)
        rt.removeListener('pause', this.stopAndDoNotReset)
        rt.removeListener('playAll', this.playAll)
        rt.removeListener('stop', this.stopAndReset)
        rt.removeListener('setCurrentTime', this.setCurrentTime)
        rt.removeListener('setPassageVideo', this.setPassageVideo)
        rt.removeListener('onEnded', this.onEnded)

        rt.rootVideoPlayerListenersAdded = false
    }

    videoPlayerStopped(hitEndingTime?: boolean) {
        const { rt } = this.props
        const { resetTimeRequested, resetTime } = this
        log('video-PlayerStopped', fmt({ hitEndingTime, resetTimeRequested, resetTime }))

        if (resetTimeRequested) {
            rt.pause()
            rt.resetCurrentTime(resetTime)
            this.resetTimeRequested = false
            return
        }

        rt.pause()
    }

    playAll() {
        log('playAll')
        if (!this.getVideoPlayer()) return

        this.playAllInProgress = true
        this.play()
    }

    // startTime = undefined, means play from current position.
    // endTime = undefined, means play through until end.
    // resetTime = undefined, means when you reach endTime stop there
    //             otherwise go to resetTime.
    // If a selection is present in the timeline and the caller has
    // not specified a startTime, play the selection.

    play(startTime?: number, endTime?: number, resetTime?: number) {
        const { rt } = this.props
        const { currentTime, timeline } = rt

        const { selectionStartTime, selectionEndTime } = timeline.getSelectionTimes()

        log(
            'play',
            fmt({
                startTime,
                endTime,
                resetTime,
                currentTime,
                selectionStartTime,
                selectionEndTime
            })
        )

        if (startTime === undefined && rt.timeline.selectionPresent()) {
            startTime = selectionStartTime
            endTime = selectionEndTime
            resetTime = startTime
        }

        startTime = startTime ?? currentTime
        this.resetTimeRequested = resetTime !== undefined
        this.resetTime = resetTime ?? 0

        const vp = this.getVideoPlayer()
        if (vp) {
            vp.play(startTime, endTime)
                .then(() => this.addViewedBy())
                .catch(displayError)
        }
    }

    async addViewedBy() {
        const { rt } = this.props
        const { username, passageVideo } = rt

        if (passageVideo) {
            await passageVideo.addViewedBy(username)
        }
    }

    stopAndDoNotReset() {
        log('stopAndDoNotReset')

        this.resetTimeRequested = false
        const vp = this.getVideoPlayer()
        vp?.stop()
    }

    stopAndReset() {
        log('stop')
        this.resetTimeRequested = true
        const vp = this.getVideoPlayer()
        vp?.stop()
    }

    addListeners(rt: Root) {
        if (rt.rootVideoPlayerListenersAdded) return
        log('addListeners', rt.project.name)

        rt.addListener('play', this.play)
        rt.addListener('pause', this.stopAndDoNotReset)
        rt.addListener('playAll', this.playAll)
        rt.addListener('stop', this.stopAndReset)
        rt.addListener('setCurrentTime', this.setCurrentTime)
        rt.addListener('setPassageVideo', this.setPassageVideo)
        rt.addListener('onEnded', this.onEnded)

        rt.rootVideoPlayerListenersAdded = true
    }

    abandonPlayAllInProgress(msg: string) {
        log('abandonPlayAllInProgress')

        this.stopAndDoNotReset()
        this.playAllInProgress = false
        log(msg)
    }

    render() {
        const { rt, showSegmentLabels, initialTime } = this.props
        const { playAllInProgress, videoWidth, setVideoWidth } = this
        const {
            passage,
            currentVideos,
            passageVideo,
            editingSegmentLabels,
            segmentLabelsDraft,
            setSegmentLabelsDraft,
            playbackRate,
            passageSegment,
            videoPlaybackKeydownEnabled
        } = rt

        if (!passageVideo || !passage) {
            return null
        }

        // log('render', fmt({passageVideo}))

        const segment = passageSegment?.actualSegment(passage)

        return (
            <div className="root-video-player">
                <div className="video-player-container" ref={this.rootVideoPlayerRef}>
                    <VideoPlayer
                        ref={(videoPlayer) => (this.videoPlayer = videoPlayer)}
                        passage={passage}
                        video={passageVideo}
                        playbackRate={playbackRate}
                        vvc={currentVideos}
                        onEnded={() => rt.emit('onEnded')}
                        disablePlay={!videoPlaybackKeydownEnabled}
                        onTick={(currentTime) => rt.setCurrentTime(currentTime)}
                        onPlayingStatus={this.onPlayingStatus}
                        onSegmentChange={this.onSegmentChanged}
                        onCanPlayThrough={this.onCanPlayThrough}
                        autoPlay={playAllInProgress}
                        play={rt.play}
                        stop={this.videoPlayerStopped}
                        setVideoWidth={setVideoWidth}
                        initialTime={initialTime}
                    >
                        {showSegmentLabels && segment && (
                            <SegmentLabelsPosition
                                {...{
                                    segmentLabelsDraft,
                                    editingSegmentLabels,
                                    setSegmentLabelsDraft,
                                    segment,
                                    videoWidth
                                }}
                            />
                        )}
                    </VideoPlayer>
                </div>
            </div>
        )
    }
}

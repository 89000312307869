import { t } from 'i18next'
import { VideoCache } from '../../models3/VideoCache'
import { displayError, displayInfo } from '../utils/Errors'
import { Root } from '../../models3/Root'
import { getVideoDuration } from '../../models3/VideoDuration'
import { EditingSegmentPosition } from '../translation/TranslationRightPane'

export function canCreatePatchFromNoteItem(rt: Root) {
    const { passage, clonedNoteItem: item } = rt

    if (!passage || !item || !item.url) return false
    return item._id.startsWith(passage._id)
}

export async function createPatchFromNoteItem(
    rt: Root,
    setEditingSegmentPosition: (value: EditingSegmentPosition) => void
) {
    const { passage, passageVideo, passageSegment, iAmTranslator, clonedNoteItem: item } = rt
    if (!passage || !passageVideo || !passageSegment || !iAmTranslator || !rt.timeline.selection) return

    if (!item || !item.url || !item._id.startsWith(passage._id)) {
        displayInfo(t('noteClickRecordingMessage'))
        return
    }

    const patchableSelectionPresent = rt.patchableSelectionPresent()

    const video = passage.createVideo(rt.name)
    video.url = item.url

    let { duration } = item
    if (duration === 0) {
        const blob = await VideoCache.getVideoBlob(item.url)
        if (!blob) {
            displayError('Could not find video')
            return
        }
        duration = await getVideoDuration(blob)
    }
    video.duration = duration
    video.log(passage, 'patch')

    passageVideo.log(passage, 'before patch')
    let segment = passageSegment
    if (patchableSelectionPresent) {
        const { segment: _segment } = await passageVideo.createSelectionSegment(passage, rt.timeline)
        segment = _segment
        rt.setPassageSegment(segment)
    }

    await passage.addPatchVideo(passageVideo, video, segment, segment.actualSegment(passage))
    passageVideo.log(passage, 'after patch')

    /**
     * This is necessary to ensure that all the videos in the passage including
     * the new patch are present in viewable video collection
     */
    rt.setPassageVideo(passageVideo)

    setEditingSegmentPosition(EditingSegmentPosition.Both)
}

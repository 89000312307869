import { observable, computed } from 'mobx'
import _ from 'underscore'

import { DBObject } from './DBObject'
import { OralPassageResource } from './OralPassageResource'
import { PassageDocument } from './PassageDocument'
import { PassageNote } from './PassageNote'
import { PassagePDF } from './PassagePDF'
import { PassageSegment } from './PassageSegment'
import { PassageVideo } from './PassageVideo'
import { Project } from './Project'
import { getVideoDuration } from './VideoDuration'
import { remove } from './Utils'

import { RefRange } from '../scrRefs/RefRange'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:Models')

export type PassageContentType =
    | 'Introduction'
    | 'Translation'
    | 'Other'
    | 'Nonpublishable'
    | 'Introduction+Translation'
    | 'Introduction+Translation+Other'

export type PassageResource = PassageDocument | OralPassageResource | PassagePDF

export class Passage extends DBObject {
    @observable name = ''

    @observable difficulty = 1.0

    @observable rank = ''

    @observable assignee = ''

    @observable documents: PassageDocument[] = []

    @observable oralResources: OralPassageResource[] = []

    @observable pdfs: PassagePDF[] = []

    @observable videos: PassageVideo[] = []

    @observable _rev = 0

    @observable compressionProgressMessage = '' // not persisted

    @observable copiedFromId = '' // passage was copied from another passage. e.g. <projectName>/<_id>

    @computed get trackedProjectName() {
        if (this.copiedFromId.indexOf('/') >= 0) {
            return this.copiedFromId.slice(0, this.copiedFromId.indexOf('/'))
        }
        return ''
    }

    @computed get trackedPassageId() {
        return this.copiedFromId.slice(this.copiedFromId.indexOf('/') + 1)
    }

    @observable contentType: PassageContentType = 'Translation'

    @observable references: RefRange[] = []

    @computed get videoBeingCompressed() {
        return this.compressionProgressMessage.trim() !== ''
    }

    /**
     * The task (aka status) for this passage is the status of the most recent (undeleted) video
     * that has a status.
     */
    @computed get task() {
        const vnds = this.videosNotDeleted
        const i = _.findLastIndex(vnds, (v) => Boolean(v.status))
        return i < 0 ? '' : vnds[i].status
    }

    setCompressionProgressMessage(message: string) {
        this.compressionProgressMessage = message
    }

    toDocument() {
        const { name, rank, difficulty, copiedFromId, assignee, contentType, references } = this
        const serializedReferences = JSON.stringify(references)
        return this._toDocument({
            name,
            rank,
            difficulty,
            copiedFromId,
            assignee,
            contentType,
            references: serializedReferences
        })
    }

    toSnapshot() {
        const snapshot = this.toDocument()
        snapshot.videos = this.videos.map((video) => video.toSnapshot())

        return snapshot
    }

    async setAssignee(assignee: string) {
        if (this.assignee === assignee) {
            return
        }
        const doc = this._toDocument({ assignee, model: 7 })
        await this.db.put(doc)
    }

    async setReferences(references: RefRange[]) {
        const serializedReferences = JSON.stringify(references)
        if (JSON.stringify(this.references) === serializedReferences) {
            log('setReferences no change')
            return
        }

        log('setReferences', serializedReferences)
        const doc = this._toDocument({ references: serializedReferences })
        await this.db.put(doc)
    }

    // All unresolved notes for this passage ordered by increasing position
    get notes() {
        const notes: PassageNote[] = []

        this.videos.forEach((video) => {
            video.notes.filter((note) => !note.resolved).forEach((note) => notes.push(note))
        })

        return notes.sort((a, b) => a.position - b.position)
    }

    async setName(name: string) {
        if (name === this.name) return

        const doc = this._toDocument({ name })
        await this.db.put(doc)
    }

    async setRank(rankNumber: number) {
        const doc = this._toDocument({ rank: DBObject.numberToRank(rankNumber) })
        await this.db.put(doc)
    }

    createPassageDocument() {
        const newId = this.db.getNewId(this.documents, new Date(Date.now()), 'pasDoc_')
        const passageDoc = new PassageDocument(`${this._id}/${newId}`, this.db)
        let rank = 100
        if (this.documents.length > 0) {
            rank = this.documents.slice(-1)[0].rankAsNumber + 100
        }
        passageDoc.rank = DBObject.numberToRank(rank)
        passageDoc.title = ''
        return passageDoc
    }

    createOralPassageResource() {
        const newId = this.db.getNewId(this.oralResources, new Date(Date.now()), 'orPasRsc_')
        const oralResource = new OralPassageResource(`${this._id}/${newId}`, this.db)
        let rank = 100
        if (this.oralResources.length > 0) {
            rank = this.oralResources.slice(-1)[0].rankAsNumber + 100
        }
        oralResource.rank = DBObject.numberToRank(rank)
        oralResource.title = ''
        return oralResource
    }

    createOralPassageResourceFromExisting(rsc: OralPassageResource) {
        if (rsc.removed) {
            return
        }
        const newResource = this.createOralPassageResource()
        const copy = rsc.copy()
        copy._id = newResource._id
        return copy
    }

    createPassagePDF() {
        const newId = this.db.getNewId(this.pdfs, new Date(Date.now()), 'pasPDF_')
        const pdf = new PassagePDF(`${this._id}/${newId}`, this.db)
        let rank = 100
        if (this.pdfs.length > 0) {
            rank = this.pdfs.slice(-1)[0].rankAsNumber + 100
        }
        pdf.rank = DBObject.numberToRank(rank)
        pdf.url = ''
        return pdf
    }

    createPassagePDFFromExisting(pdf: PassagePDF) {
        if (pdf.removed) {
            return
        }
        const newPDF = this.createPassagePDF()
        const copy = pdf.copy()
        copy._id = newPDF._id
        return copy
    }

    createPassageDocumentFromExisting(passageDocument: PassageDocument) {
        if (passageDocument.removed) {
            return
        }

        const newDocument = this.createPassageDocument()
        const copy = passageDocument.copy()
        copy._id = newDocument._id
        return copy
    }

    async addOralPassageDocument(resource: OralPassageResource) {
        await this.db.put(resource.toDocument())
        const _resource = this.oralResources.find((r) => r._id === resource._id)
        if (!_resource) {
            throw new Error('Could not find oral passage resource we just added')
        }
        return _resource
    }

    async addAndUploadOralPassageDocument(audioRecording: File, projectName: string, resource: OralPassageResource) {
        if (!Project.copyFileToVideoCache) {
            throw new Error('Project.copyFileToVideoCache not set')
        }

        const baseUrl = `${projectName}/${resource._id}`
        const url = await Project.copyFileToVideoCache(audioRecording, baseUrl)
        resource.url = url
        return this.addOralPassageDocument(resource)
    }

    async removeOralPassageResource(_id: string) {
        await remove(this.oralResources, _id)
    }

    async addPassagePDF(pdf: PassagePDF) {
        await this.db.put(pdf.toDocument())
        const _pdf = this.pdfs.find((p) => p._id === pdf._id)
        if (!_pdf) {
            throw new Error('Could not find passage pdf we just added')
        }
        return _pdf
    }

    async addAndUploadPassagePDF(file: File, projectName: string, pdf: PassagePDF) {
        if (!Project.copyFileToVideoCache) {
            throw new Error('Project.copyFileToVideoCache not set')
        }

        const baseUrl = `${projectName}/${pdf._id}`
        const url = await Project.copyFileToVideoCache(file, baseUrl)
        pdf.url = url
        return this.addPassagePDF(pdf)
    }

    async removePassagePDF(_id: string) {
        await remove(this.pdfs, _id)
    }

    async addPassageDocument(passageDocument: PassageDocument) {
        await this.db.put(passageDocument.toDocument())
        const _document = this.documents.find((d) => d._id === passageDocument._id)
        if (!_document) {
            throw new Error('Could not find passage document we just added')
        }
        return _document
    }

    async removePassageDocument(_id: string) {
        await remove(this.documents, _id)
    }

    createVideo(projectName: string, creationDate?: Date) {
        const date = creationDate ?? new Date(Date.now())
        const dateId = this.db.getNewId(this.videos, date)
        const itemId = `${this._id}/${dateId}`
        const video = new PassageVideo(itemId, this.db, creationDate)

        video.url = `${projectName}/${itemId}`

        return video
    }

    createVideoFromExisting(passageVideo: PassageVideo, projectName: string) {
        // we don't check if the video has been removed. Unlike other objects, removed passage
        // videos stay around in the model.
        const video = this.createVideo(projectName, new Date(passageVideo.creationDate))
        const copy = passageVideo.copy()
        copy._id = video._id
        copy.segments = []
        copy.notes = []
        copy.glosses = []
        copy.highlights = []
        return copy
    }

    copy() {
        let copy = new Passage(this._id, this.db)
        copy = Object.assign(copy, this)
        copy.documents = this.documents.map((doc) => doc.copy())
        copy.oralResources = this.oralResources.map((rsc) => rsc.copy())
        copy.pdfs = this.pdfs.map((pdf) => pdf.copy())
        copy.references = this.references.map((ref) => ref.copy())
        copy.videos = this.videos.map((video) => video.copy())
        return copy
    }

    async addVideoWithDefaultSegment(video: PassageVideo, copyVerseReferences = true) {
        const _video = await this.addVideo(video)
        await _video.addSegment(0)
        const updatedVideo = this.videos.find((v) => _video._id === v._id)
        if (!updatedVideo) {
            throw Error('Could not find video we just added segment to')
        }
        if (this.videosNotDeleted.length > 1 && copyVerseReferences) {
            const mostRecentDraft = this.videosNotDeleted.slice(-2)[0]
            await this.copyOverReferences(mostRecentDraft, updatedVideo)
        }
        return updatedVideo
    }

    // Copy over references from oldVideo to newVideo. If any references exist beyond the
    // length of newVideo, copy over the 1st one and give it a position of newVideo.duration.
    // Do not copy over other references that exist beyond the end of the video.
    private async copyOverReferences(oldVideo: PassageVideo, newVideo: PassageVideo) {
        const visibleReferences = oldVideo.getVisibleReferenceMarkers(this)
        const sorted = _.sortBy(visibleReferences, 'time')
        for (const el of sorted) {
            const { references, time } = el
            const newRefs = references.map((ref) => new RefRange(ref.startRef, ref.endRef))
            if (time > newVideo.duration) {
                await newVideo.addReference(newRefs, newVideo.duration)
                break
            } else {
                await newVideo.addReference(newRefs, time)
            }
        }
    }

    async addVideo(video: PassageVideo) {
        await this.db.put(video.toDocument())
        const _video = this.findVideo(video._id)
        if (!_video) {
            throw Error('could not find video we just added!')
        }
        return _video
    }

    async addPatchVideo(
        video: PassageVideo,
        patch: PassageVideo,
        segment: PassageSegment,
        onTopSegment: PassageSegment
    ) {
        if (this.findVideo(patch._id)) throw Error('Patch already added')

        // Add patch to list of videos for this passage.
        // Persist it to DB.
        patch.isPatch = true
        const savedPatch = await this.addVideo(patch)

        const { labels, references, cc, documents, audioClips, transcriptions } = onTopSegment
        const { segment: newSegment } = await savedPatch.addSegment(0, labels, references, cc)

        const startTime = onTopSegment.time
        const endTime = onTopSegment.time + onTopSegment.duration
        const visibleTermMarkers = video
            .getVisibleBiblicalTermMarkers(this)
            .filter((marker) => marker.time >= startTime && marker.time <= endTime)

        const visibleVerseReferenceMarkers = video
            .getVisibleReferenceMarkers(this)
            .filter((marker) => marker.time >= startTime && marker.time <= endTime)

        // copy all the data to the patch and the segment on the patch
        await Promise.all([
            newSegment.copyPassageSegmentDocuments(documents),
            newSegment.copyAudioClips(audioClips),
            newSegment.copyTranscriptions(transcriptions),
            savedPatch.copyMarkers(onTopSegment, visibleTermMarkers),
            savedPatch.copyMarkers(onTopSegment, visibleVerseReferenceMarkers)
        ])

        await segment.addVideoPatchToHistory(savedPatch)
        await video.updateVersion() // force display of video to redraw

        video.log(this, 'addPatchVideo DONE')

        const newVideo = this.videos.find((v) => v._id === patch._id)
        if (!newVideo || newVideo.segments.length !== 1) {
            throw new Error('Error creating patch video')
        }

        return newVideo
    }

    async deletePatchVideo(existingVideo: PassageVideo, patch: PassageVideo, segment: PassageSegment) {
        const exists = this.videos.find((v) => v._id === patch._id)
        if (!exists || !patch.isPatch || !segment.videoPatchHistory.includes(patch._id)) {
            return
        }
        const { notes, glosses } = patch
        for (const note of notes) {
            for (const item of note.items) {
                await note.removeItem(item._id)
            }
            await patch.removeNote(note._id)
        }
        for (const gloss of glosses) {
            await patch.removeGloss(gloss._id)
        }
        await segment.removeVideoPatchFromHistory(patch)
        await this.removeVideo(patch._id)
        await existingVideo.updateVersion()
    }

    async removeVideo(_id: string) {
        await remove(this.videos, _id)
    }

    async undeleteVideo(video: PassageVideo) {
        if (video.removed) {
            const doc = video._toDocument({})
            doc.removed = false
            await this.db.put(doc)
        }
    }

    async uploadFile(file: File, creationDate: Date, projectName: string): Promise<PassageVideo> {
        const dateId = this.db.getNewId(this.videos, creationDate)
        const itemId = `${this._id}/${dateId}`

        const video = new PassageVideo(itemId, this.db, creationDate)

        const baseUrl = `${projectName}/${itemId}`

        if (!Project.copyFileToVideoCache) throw Error('Project.copyFileToVideoCache not set')

        video.mimeType = file.type
        video.url = await Project.copyFileToVideoCache(file, baseUrl, video.creationDate)
        video.duration = await getVideoDuration(file)

        return video
    }

    videod() {
        return this.videos.length > 0
    }

    get videosNotDeleted() {
        // Videos with _rev === 0 are still being updated by DBAcceptor and should be
        // ignored until that process is completed.
        return this.videos.filter((video) => !video.removed && !video.isPatch && video._rev > 0)
    }

    getDefaultVideo(_id: string) {
        const { videosNotDeleted } = this
        let video = _.findWhere(videosNotDeleted, { _id })

        if (!video) {
            if (this.videosNotDeleted.length > 0) {
                video = this.videosNotDeleted.slice(-1)[0]
            }
        }

        return video || null // ensure we return null instead of undefined
    }

    async setDifficulty(difficulty: number) {
        difficulty = difficulty || 0

        if (difficulty < 0 || this.difficulty === difficulty) {
            return
        }

        const doc = this._toDocument({})
        doc.difficulty = difficulty
        await this.db.put(doc)
    }

    async setContentType(contentType: PassageContentType) {
        if (contentType === this.contentType) {
            return
        }

        const doc = this._toDocument({})
        doc.contentType = contentType
        await this.db.put(doc)
    }

    // Find video in this passage.
    // Returns undefined if not found.
    // _id can be for a video or any of its subobject (e.g. PassageNote)
    findVideo(_id: string) {
        const video = this.videos.find((v) => _id.startsWith(v._id))
        if (!video) {
            // log(`###findVideo failed passage=${this._id}, video=${_id}`)
        }

        return video
    }

    findSegment(_id: string) {
        const video = this.findVideo(_id)
        const segment = video?.segments.find((s) => _id.startsWith(s._id))

        return segment
    }

    // Find note in this passage.
    // Returns undefined if not found.
    // _id can be for a note or any of its subobject (e.g. PassageNoteItem)
    findNote(_id: string) {
        const video = this.findVideo(_id)
        const note = video?.notes.find((n) => _id.startsWith(n._id))
        if (!note) {
            // log(`###findNote failed passage=${this._id}, note=${_id}`)
        }

        return note
    }

    firstUnviewedNote(username: string, cutoff: Date, includeConsultantOnlyNotes: boolean) {
        const videos = this.videosNotDeleted
        const mostRecentVideo = videos[videos.length - 1]
        return mostRecentVideo?.firstUnviewedNoteAfterDate(this, username, cutoff, includeConsultantOnlyNotes) || null
    }

    firstUnresolvedNoteOnLatestVideo(cutoff: Date, includeConsultantOnlyNotes: boolean) {
        const videos = this.videosNotDeleted
        const mostRecentVideo = videos[videos.length - 1]
        return mostRecentVideo?.mostRecentUnresolvedNote(this, cutoff, includeConsultantOnlyNotes) || null
    }

    getUnresolvedNote(cutoff: Date, includeConsultantOnlyNotes: boolean) {
        const newestToOldestVideos = [...this.videosNotDeleted].reverse()
        for (const video of newestToOldestVideos) {
            const note = video.mostRecentUnresolvedNote(this, cutoff, includeConsultantOnlyNotes)
            if (note) {
                return note
            }
        }
        return null
    }

    firstUnviewedVideo(username: string, cutoff: Date) {
        const videos = this.videosNotDeleted
        const mostRecentVideo = videos[videos.length - 1]
        return mostRecentVideo?.isUnviewedAfterDate(username, cutoff) ? mostRecentVideo : null
    }
}

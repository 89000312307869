// SegmentToolbar allows controlling playing and recording

import React, { Component, FunctionComponent, useContext } from 'react'
import { DropdownButton, MenuItem, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { observer } from 'mobx-react'
import { t } from 'i18next'

import { Root } from '../../models3/Root'
import {
    PlayButton,
    PauseButton,
    RecordButton,
    StopButton,
    SlttHelp,
    SegmentLabelButton,
    EyeButton
} from '../utils/Buttons'
import '../utils/Buttons.css'
import { PatchVideoDeleter } from '../video/PatchVideoDeleter'
import { PassageSegment, PassageSegmentApproval } from '../../models3/PassageSegment'
import { PassageVideo } from '../../models3/PassageVideo'
import { Member } from '../../models3/Member'

import { OldSegmentDropdown } from './OldSegmentDropdown'
import { CheckCircle, CheckIcon, ThinCircleIcon } from '../utils/Icons'
import { RootContext } from '../app/RootContext'
import VideoSegmentPosition from './VideoSegmentPosition'
import { EditingSegmentPosition } from '../translation/TranslationRightPane'
import SegmentDeleter from './SegmentDeleter'
import { isAVTT } from '../app/slttAvtt'
import { canCreatePatchFromNoteItem, createPatchFromNoteItem } from '../notes/PatchFromNote'
import { displayError } from '../utils/Errors'
import { AVTTRecordingState } from '../video/VideoRecorder'

// function enable(cns, enabled) { return enabled ? cns : cns + ' sl-button-disabled' }

// NOT IMPLEMENTED YET
// function PlayPreviousDraftSegmentButton({ onClick, enabled, tooltip }) {
//     let d = `M 0,0
//              L 20,15
//              L 0,30`

//     return (
//         <span className={enable('sl-play-previous-draft-segment-button', enabled)}
//             onClick={() => onClick && onClick()}
//             data-toggle="tooltip"
//             title={tooltip} >
//             <svg className="sl-play-previous-draft-segment-svg" width="35" height="50" xmlns="http://www.w3.org/2000/svg">
//                 <g>
//                     <path stroke="#337ab7"
//                         d={d}
//                         strokeWidth="1.5" fill="#337ab7" />
//                     <text stroke="#337ab7" textAnchor="start" fontFamily="Helvetica, Arial, sans-serif"
//                         fontSize="16" y="10" x="15" strokeWidth="0" fill="#337ab7">-1</text>
//                 </g>
//             </svg >
//         </span>
//     )
// }

// function SelectPreviousDraftSegmentButton({ onClick, enabled, tooltip }) {
//     return (
//         <span className={enable('sl-fa-button sl-select-previous-draft-segment-button fa-caret-down', enabled)}
//             onClick={() => onClick && onClick()}
//             data-toggle="tooltip"
//             title={tooltip} >
//         </span>
//     )
// }

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:SegmentToolbar')

interface ISegmentApproval {
    segment: PassageSegment
    enabled: boolean
}

const SegmentApproval: FunctionComponent<ISegmentApproval> = observer(({ segment, enabled }) => {
    const rt = useContext(RootContext)
    if (!rt) return null

    const { iAmAdmin, iAmInterpreter, username, dateFormatter, project } = rt
    const { approved, approvalDate, approvedBy } = segment
    const isApproved = approved !== PassageSegmentApproval.State0

    const options = [
        <span key="dash" className="dash-icon sl-fa-button">
            &mdash;
        </span>,
        <CheckIcon key="check" className="segment-approval-status-icon" tooltip="" />,
        <ThinCircleIcon key="circle" className="segment-approval-status-icon" tooltip="" />,
        <CheckCircle key="checkCircle" className="segment-approval-status-icon" tooltip="" />
    ]

    const currentOption = options[approved]
    const allowedToEdit = iAmAdmin || iAmInterpreter

    let member = project.members.find((mem) => mem.email === approvedBy)
    if (!member) {
        member = new Member(username)
    }

    // example: "Segment approved by email@gmail.com on 5-24-21 10:00"
    const approvedMessage = t('Segment approved by {{userEmail}} on {{date}}', {
        userEmail: member.email,
        date: dateFormatter.format(new Date(approvalDate))
    })

    const tooltip = <Tooltip id="segment-approval-tooltip">{isApproved ? approvedMessage : t('Approved?')}</Tooltip>

    return (
        <form className={`${allowedToEdit ? '' : 'segment-approval-not-allowed'} flex-centered-items`}>
            <span className="segment-approval-current-status">
                <OverlayTrigger overlay={tooltip} placement="bottom">
                    <div>{currentOption}</div>
                </OverlayTrigger>
                {allowedToEdit && (
                    <DropdownButton
                        bsStyle="default"
                        title=""
                        id="segment-approval-dropdown"
                        className="sl-dropdown segment-approval-dropdown"
                        disabled={!enabled}
                    >
                        {options.map((icon, i) => (
                            <MenuItem
                                key={icon.key}
                                value={i}
                                onClick={() => {
                                    if (allowedToEdit) {
                                        segment.setApproved(i, username)
                                    }
                                }}
                            >
                                <div className="segment-approval-option">{icon}</div>
                            </MenuItem>
                        ))}
                    </DropdownButton>
                )}
            </span>
        </form>
    )
})
interface ISegmentToolbar {
    rt: Root
    segment: PassageSegment
    oldVersionOptions: PassageVideo[]
    chooseVersionToPlay: (id: string) => void
    play: () => void
    pause: () => void
    record: () => void
    stopRecording: () => void
    editingSegmentPosition: EditingSegmentPosition
    setEditingSegmentPosition: (value: EditingSegmentPosition) => void
    startEditingLabels: () => void
    baseSegment: PassageSegment
    className: string
    recordingState: AVTTRecordingState
}

class SegmentToolbar extends Component<ISegmentToolbar> {
    startEditingLabels = () => {
        log(`setEditingLabels true`)
        const { startEditingLabels } = this.props
        startEditingLabels()
    }

    render() {
        const { startEditingLabels } = this
        const {
            rt,
            record,
            stopRecording,
            chooseVersionToPlay,
            oldVersionOptions,
            play,
            pause,
            segment,
            editingSegmentPosition,
            setEditingSegmentPosition,
            className,
            recordingState
        } = this.props

        const { playing, passage, passageVideo, passageSegment, recording, editingSegment, iAmInterpreter } = rt

        const selectionPresent = rt.timeline.selectionPresent()
        const patchableSelectionPresent = rt.patchableSelectionPresent()

        const enabled = !!(passageVideo && passageSegment && !editingSegment)
        const pauseShown = playing
        const playEnabled = enabled && !recording

        const recordShown = !playing && !recording
        let recordEnabled = enabled && rt.iAmTranslator
        let recordTooltip = t('Record patch for this segment.')
        if (selectionPresent) {
            recordEnabled = enabled && rt.iAmTranslator && patchableSelectionPresent
            recordTooltip = t('Record patch for the selection.')
            if (!patchableSelectionPresent) {
                recordTooltip += ` ${t('Patch selections cannot cross segments boundaries.')}`
            }
        }

        if (canCreatePatchFromNoteItem(rt)) {
            recordTooltip += ` ${t('recordingClickToUseNoteAsPatch')}`
        }

        const stopShown = recording
        const stopEnabled = enabled && (recordingState === 'RECORDING' || recordingState === 'PAUSED')
        const oldSegmentDropdownShown = passageSegment && passageSegment.videoPatchHistory.length > 0

        const labelsEnabled = enabled && iAmInterpreter

        const playButtonClass = `sl-segment-toolbar-button ${!oldSegmentDropdownShown && 'sl-play-segment-button'}`

        const actualSegment = passage && passageSegment?.actualSegment(passage)

        return (
            <div className={`segment-toolbar ${className}`}>
                <div className="segment-toolbar-first-group">
                    {!pauseShown && (
                        <PlayButton
                            tooltip={t('Play')}
                            className={playButtonClass}
                            enabled={playEnabled}
                            selectionPresent={false}
                            onClick={play}
                        />
                    )}

                    {pauseShown && (
                        <PauseButton
                            className="sl-pause-segment-button"
                            enabled
                            tooltip={t('Pause.')}
                            onClick={pause}
                        />
                    )}

                    {oldSegmentDropdownShown && (
                        <SlttHelp
                            id="patches.html#patchplay"
                            tooltip={t('Play a previous version this segment.')}
                            place="top"
                        >
                            <OldSegmentDropdown
                                rt={rt}
                                options={oldVersionOptions}
                                enabled={!!enabled}
                                onSelect={chooseVersionToPlay}
                            />
                        </SlttHelp>
                    )}

                    {recordShown && (
                        <SlttHelp id="patches.html" tooltip={recordTooltip} place="top">
                            <RecordButton
                                tooltip=""
                                className="sl-record-patch-button"
                                enabled={recordEnabled}
                                selectionPresent={selectionPresent}
                                onClick={(shiftKey: boolean) => {
                                    if (shiftKey) {
                                        createPatchFromNoteItem(rt, setEditingSegmentPosition).catch(displayError)
                                        return
                                    }

                                    record()
                                }}
                            />
                        </SlttHelp>
                    )}

                    {stopShown && (
                        <StopButton
                            className="segment-stop-button"
                            enabled={stopEnabled}
                            onClick={stopRecording}
                            tooltip={t('Stop recording.')}
                        />
                    )}

                    {!recording &&
                        passage &&
                        passageSegment &&
                        passageVideo &&
                        passageSegment.videoPatchHistory.length > 0 && (
                            <PatchVideoDeleter
                                {...{
                                    enabled: !!enabled,
                                    deleter: { segment: passageSegment, rt, passage, passageVideo }
                                }}
                            />
                        )}
                    {passageVideo && !passageVideo.isAudioOnly() && (
                        <VideoSegmentPosition
                            {...{ enabled: !!enabled, editingSegmentPosition, setEditingSegmentPosition }}
                        />
                    )}
                </div>

                <div className="segment-toolbar-middle-group">
                    <SegmentApproval {...{ enabled: !!enabled, segment }} />
                    {!isAVTT && (
                        <SegmentLabelButton
                            className="segment-labels-button"
                            tooltip={t('Edit labels for this segment.')}
                            enabled={labelsEnabled}
                            onClick={startEditingLabels}
                        />
                    )}
                </div>

                <div className="segment-toolbar-last-group">
                    {!isAVTT && passageSegment && actualSegment && (
                        <EyeButton
                            className="ignore-segment-button"
                            enabled
                            tooltip={t('recordingHideSegment')}
                            value={actualSegment.ignoreWhenPlayingVideo}
                            setValue={actualSegment.setIgnoreWhenPlayingVideo}
                        />
                    )}
                    {passageSegment && <SegmentDeleter segment={passageSegment} rt={rt} />}
                    {/* <PlayPreviousDraftSegmentButton enabled={true} /> */}
                </div>
            </div>
        )
    }
}

export default observer(SegmentToolbar)

import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'
import { t } from 'i18next'

import { Root } from '../../models3/Root'
import {
    ImageReferencesIcon,
    BiblicalSignsIcon,
    CompareDraftIcon,
    DbsPlayIcon,
    PassageDocumentIcon,
    PublishedBiblesIcon,
    StarIcon,
    PassageResourcesIcon
} from '../utils/Icons'
import EnhancedResourceViewer from '../enhancedResources/EnhancedResourceViewer'
import { Images } from '../images/Images'
import ErrorBoundary from '../utils/Errors'
import CompareDraftVideoMain from '../video/CompareDraftVideoMain'
import DbsPlayer from '../dbs/DbsPlayer'
import PassageResources from '../passages/PassageDocuments'
import { enableKeyTerms, isAVTT } from '../app/slttAvtt'
import PassageBiblicalTermsViewer from '../enhancedResources/PassageBiblicalTermsViewer'
import { SlttHelp } from '../utils/Buttons'

export enum EditingSegmentPosition {
    None,
    Starting,
    Ending,
    Both
}

const TranslationRightPane = observer(({ rt }: { rt: Root }) => {
    const [tabIndex, setTabIndex] = useState(parseInt(rt.getDefault('TRANSLATIONRIGHTPANEINDEX') || '0'))

    const { portion, passage, passageVideo } = rt
    if (!portion || !passage) return null

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { tourSelector /* so that children re-render */ } = rt

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { _rev } = passage

    const showCompareDraftVideoMain = passageVideo !== null
    const showPassageResources = passage !== null

    const _setTabIndex = (index: number) => {
        rt.setDefault('TRANSLATIONRIGHTPANEINDEX', index.toString())
        setTabIndex(index)
    }

    return (
        <Tabs
            selectedIndex={tabIndex}
            onSelect={(index) => {
                if (rt.editingImage || rt.editingSegment) return
                _setTabIndex(index)
            }}
        >
            <TabList>
                <Tab key="passage-docs">
                    {isAVTT && (
                        <PassageResourcesIcon className="right-pane-tab-icon" tooltip={t('Passage resources')} />
                    )}
                    {!isAVTT && (
                        <PassageDocumentIcon className="right-pane-tab-icon" tooltip={t('Passage documents')} />
                    )}
                </Tab>

                <Tab key="image-references">
                    <ImageReferencesIcon className="right-pane-tab-icon" tooltip={t('Images')} />
                </Tab>
                {!isAVTT && (
                    <Tab key="draft-compare">
                        <CompareDraftIcon className="right-pane-tab-icon" tooltip={t('recordingCompareToDrafts')} />
                    </Tab>
                )}
                {!isAVTT && (
                    <Tab key="dbs-references">
                        <DbsPlayIcon
                            className="right-pane-tab-icon sl-dbs-play-icon"
                            tooltip={t('recordingShowPublished')}
                        />
                    </Tab>
                )}
                <Tab key="signs">
                    {isAVTT && <PublishedBiblesIcon className="right-pane-tab-icon" tooltip={t('Published Bibles')} />}
                    {!isAVTT && <BiblicalSignsIcon className="right-pane-tab-icon" tooltip={t('Biblical Signs')} />}
                </Tab>
                {enableKeyTerms && (
                    <Tab key="passage-key-terms">
                        <SlttHelp
                            id="biblicalTerms.html#passage-biblical-terms"
                            tooltip={t('passageBiblicalTermsHeader')}
                            place="bottom"
                        >
                            <StarIcon className="right-pane-tab-icon passage-key-terms-icon" />
                        </SlttHelp>
                    </Tab>
                )}
            </TabList>

            <TabPanel key="passage-docs">
                <ErrorBoundary>{showPassageResources && <PassageResources {...{ rt, passage }} />}</ErrorBoundary>
            </TabPanel>

            <TabPanel key="image-references">
                <ErrorBoundary>
                    <Images rt={rt} />
                </ErrorBoundary>
            </TabPanel>

            {!isAVTT && (
                <TabPanel key="draft-compare">
                    <ErrorBoundary>{showCompareDraftVideoMain && <CompareDraftVideoMain {...{ rt }} />}</ErrorBoundary>
                </TabPanel>
            )}

            {!isAVTT && (
                <TabPanel key="dbs-references">
                    <ErrorBoundary>
                        <DbsPlayer rt={rt} />
                    </ErrorBoundary>
                </TabPanel>
            )}

            <TabPanel key="signs">
                <ErrorBoundary>
                    <EnhancedResourceViewer rt={rt} />
                </ErrorBoundary>
            </TabPanel>

            {enableKeyTerms && (
                <TabPanel key="passage-key-terms">
                    <ErrorBoundary>
                        <PassageBiblicalTermsViewer
                            rt={rt}
                            passage={rt.passage}
                            passageVideo={rt.passageVideo}
                            project={rt.project}
                            portion={rt.portion}
                        />
                    </ErrorBoundary>
                </TabPanel>
            )}
        </Tabs>
    )
})

export default TranslationRightPane

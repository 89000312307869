import { FC, useState } from 'react'
import { observer } from 'mobx-react'
import { SortableContainer } from 'react-sortable-hoc'
import { useTranslation } from 'react-i18next'

import PortionListItem from './PortionView'
import PortionAdder from './PortionAdder'

import { ExportPortionsModal } from '../export/ExportPortionsModal'

import { CopyButton, DownloadButton } from '../utils/Buttons'
import { CopyProjectDataModal } from '../utils/CopyProjectDataModal'
import { systemError, CustomErrorBoundary } from '../utils/Errors'
import LoadingMessage from '../utils/InitializationMessage'

import { Portion } from '../../models3/Portion'
import { Root } from '../../models3/Root'

import './Portion.css'

interface ISortableContainer {
    rt: Root
    portions: Portion[]
}

interface IPortionsEditor {
    rt: Root
}

export const PortionsEditor: FC<IPortionsEditor> = observer(({ rt }) => {
    const { t } = useTranslation()
    const { project, initialized, loadingMessage, useMobileLayout, iAmAdmin } = rt
    const [openCopyPortionsModal, setOpenCopyPortionsModal] = useState(false)
    const [openExportModal, setOpenExportModal] = useState(false)

    const { portions } = project

    /* eslint-disable-next-line @typescript-eslint/no-unused-expressions */
    portions.length // necessary to force re-render, don't know why

    if (!initialized) return <LoadingMessage {...{ loadingMessage }} />

    const onSortEnd = (result: any) => {
        const { oldIndex } = result
        const { newIndex } = result

        project.movePortion(project.portions[oldIndex]._id, newIndex).catch(systemError)
    }

    const SortableList = SortableContainer((props: ISortableContainer) => {
        return (
            <div>
                {props.portions.map((portion, index) => (
                    <CustomErrorBoundary
                        key={portion._id}
                        fallbackUI={
                            <div
                                className="portion-portion portion-error"
                                title={t('Something went wrong while displaying this portion')}
                            >
                                <b>{`${portion.name}???`}</b>
                            </div>
                        }
                    >
                        <PortionListItem
                            disabled={!props.rt.iAmInterpreter}
                            index={index}
                            rt={props.rt}
                            portion={portion}
                        />
                    </CustomErrorBoundary>
                ))}
            </div>
        )
    })

    return (
        <div className="portions-editor">
            <h3>
                {t('Portions')} {`(${project.displayName})`}
            </h3>

            {iAmAdmin && project.plans.length && project.plans[0].viewableStages.length > 0 && (
                <div className="portions-menu">
                    <CopyButton
                        onClick={() => setOpenCopyPortionsModal(true)}
                        buttonClassName=""
                        className="portions-menu-item"
                        tooltip={t('Copy portions')}
                        enabled
                    />
                    {openCopyPortionsModal && (
                        <CopyProjectDataModal
                            setOpenModal={setOpenCopyPortionsModal}
                            source={project}
                            copyDataType="portions"
                        />
                    )}

                    <DownloadButton
                        className="portions-menu-item"
                        tooltip={t('Export')}
                        onClick={() => setOpenExportModal(true)}
                        enabled
                    />
                    {openExportModal && <ExportPortionsModal rt={rt} setOpen={setOpenExportModal} />}
                </div>
            )}

            <SortableList portions={project.portions} rt={rt} useDragHandle onSortEnd={onSortEnd} />
            {!useMobileLayout && <PortionAdder rt={rt} />}
        </div>
    )
})

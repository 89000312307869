// Play video based on events signaled on Root.
// rt.passageVideo is the video that is played.
// Handles stepping through passages on "play all passages" event

import React, { Component } from 'react'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import { EventEmitter } from 'events'

import './Video.css'

import VideoPlayer from './VideoPlayer'
import { ViewableVideoCollection } from './ViewableVideoCollection'

import SegmentLabelsPosition from '../segments/SegmentLabelsPosition'
import { displayError } from '../utils/Errors'

import { Passage } from '../../models3/Passage'
import { PassageSegment } from '../../models3/PassageSegment'
import { PassageSegmentLabel } from '../../models3/PassageSegmentLabel'
import { PassageVideo } from '../../models3/PassageVideo'
import { Timeline } from '../../models3/RootBase'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:DraftVideoPlayer')

const f = (x?: number) => x?.toFixed(2)

interface DraftVideoPlayerProps {
    draftVideoPlayer: IDraftVideoPlayer
    setContainerHeight: (height: number) => void
}

interface IDraftVideoPlayer extends EventEmitter {
    stopRoot: () => void
    passage: Passage | null
    passageSegment: PassageSegment | null
    passageVideo: PassageVideo | null
    currentVideos: ViewableVideoCollection
    setDuration: (duration: number) => void
    playing: boolean
    setPlaying: (playing: boolean) => void
    setCurrentTime: (currentTime: number) => void
    resetCurrentTime: (newTime: number) => void
    currentTime: number
    playbackRate: number
    setPlaybackRate: (rate: number) => void
    timeline: Timeline
    username: string
    videoPlaybackKeydownEnabled: boolean
}

@observer
export default class DraftVideoPlayer extends Component<DraftVideoPlayerProps> {
    videoPlayer: VideoPlayer | null = null

    resetTime = 0 // If resetTimeRequested, reset time to this point when play ends

    resetTimeRequested = false

    @observable videoWidth = 0

    intervalId?: NodeJS.Timeout

    constructor(props: DraftVideoPlayerProps) {
        super(props)
        const { draftVideoPlayer } = this.props

        this.play = this.play.bind(this)
        this.stopAndDoNotReset = this.stopAndDoNotReset.bind(this)
        this.stopAndReset = this.stopAndReset.bind(this)
        this.setCurrentTime = this.setCurrentTime.bind(this)
        this.onCanPlayThrough = this.onCanPlayThrough.bind(this)
        this.onPlayingStatus = this.onPlayingStatus.bind(this)
        this.setVideoHeight = this.setVideoHeight.bind(this)

        draftVideoPlayer.addListener('play', this.play)
        draftVideoPlayer.addListener('pause', this.stopAndDoNotReset)
        draftVideoPlayer.addListener('stop', this.stopAndReset)
        draftVideoPlayer.addListener('setCurrentTime', this.setCurrentTime)
        draftVideoPlayer.addListener('setPassageVideo', this.setPassageVideo)

        this.setPassageVideo()
    }

    componentDidMount() {
        this.intervalId = setInterval(this.setVideoHeight, 100) // temporary, use ResizeObserver if possible once Typescript adds type declarations for it
    }

    componentWillUnmount() {
        const { draftVideoPlayer } = this.props

        if (this.intervalId) {
            clearInterval(this.intervalId)
        }
        this.intervalId = undefined
        draftVideoPlayer.removeListener('play', this.play)
        draftVideoPlayer.removeListener('pause', this.stopAndDoNotReset)
        draftVideoPlayer.removeListener('stop', this.stopAndReset)
        draftVideoPlayer.removeListener('setCurrentTime', this.setCurrentTime)
        draftVideoPlayer.removeListener('setPassageVideo', this.setPassageVideo)
    }

    onCanPlayThrough(video: PassageVideo, duration: number) {
        const { draftVideoPlayer } = this.props

        log('onCanPlayThrough', duration, video.computedDuration, video._id)

        if (!isFinite(duration) || duration < 0.1) return

        // This is nasty code that tries to figure out if this video does not know
        // its duration. If not, it uses the duration computed by the video element
        // and sets the duration to that.
        if (video.duration > 0.1) return

        draftVideoPlayer.setDuration(duration) // ????
        video.setDuration(duration).catch(displayError)
    }

    onPlayingStatus(playing: boolean) {
        const { draftVideoPlayer } = this.props
        const { setPlaying, resetCurrentTime, stopRoot } = draftVideoPlayer
        if (playing === draftVideoPlayer.playing) return

        log('onPlayingStatus', playing)
        setPlaying(playing)

        // If user requested to reset time at end of play, do so
        const { resetTime, resetTimeRequested } = this
        if (!playing && resetTimeRequested) {
            log(
                `onPlayingStatus resetTimeRequested=${resetTimeRequested}, resetTime=${f(
                    resetTime
                )}, playing=${playing}`
            )
            resetCurrentTime(resetTime)
            this.resetTimeRequested = false
            stopRoot()
        }
    }

    getVideoPlayer() {
        const { videoPlayer } = this
        if (!videoPlayer) log('### no videoPlayer present')
        return videoPlayer
    }

    // startTime = undefined, means play from current position.
    // endTime = undefined, means play through until end.
    // resetTime = undefined, means when you reach endTime stop there
    //             otherwise go to resetTime.
    // If a selection is present in the timeline and the caller has
    // not specified a startTime, play the selection.

    setCurrentTime(newTime: number) {
        log('setCurrentTime', newTime)

        const vp = this.getVideoPlayer()
        vp?.setCurrentTime(newTime)
    }

    // Invoked externally based on event
    // Sets the currently active video as well as the current time in that video

    setVideoHeight() {
        const { setContainerHeight } = this.props
        const videoArea = document.querySelectorAll("[data-id='main-video']")
        for (const element of videoArea) {
            const _element = element as HTMLElement
            const htmlElementVisible = (el: HTMLElement) => el.offsetHeight > 0 && el.offsetWidth > 0
            const isVisible = htmlElementVisible(_element)
            if (isVisible) {
                setContainerHeight(_element.offsetHeight)
            }
        }
    }

    // When root.passageVideo changes setup to display the newly
    // chosen value.
    setPassageVideo = () => {
        const { draftVideoPlayer } = this.props
        const { passageVideo, currentVideos } = draftVideoPlayer
        if (!passageVideo) return

        log('setPassageVideo', passageVideo?._id)

        const vp = this.getVideoPlayer()
        vp?.stop()

        currentVideos.waitUntilDownloaded().then(() => {
            log('passageVideoHasChanged downloaded')
        })
    }

    stopAndDoNotReset() {
        log("[pause event] stop and don't reset")
        this.resetTimeRequested = false
        const vp = this.getVideoPlayer()
        vp?.stop()
    }

    stopAndReset() {
        log('[stop event] stop and reset')
        this.resetTimeRequested = true
        const vp = this.getVideoPlayer()
        vp?.stop()
    }

    async addViewedBy() {
        const { draftVideoPlayer } = this.props
        const { username, passageVideo } = draftVideoPlayer

        await passageVideo?.addViewedBy(username)
    }

    play(startTime?: number, endTime?: number, resetTime?: number) {
        const { draftVideoPlayer } = this.props
        const { currentTime, timeline } = draftVideoPlayer

        const { selectionStartTime, selectionEndTime } = timeline.getSelectionTimes()
        log(
            `play3draft ${f(startTime)}/${f(endTime)} [resetTime=${f(resetTime)}] [${f(selectionStartTime)}..${f(
                selectionEndTime
            )}]`
        )

        if (startTime === undefined && draftVideoPlayer.timeline.selectionPresent()) {
            startTime = selectionStartTime
            endTime = selectionEndTime
            resetTime = startTime
        }

        startTime = startTime ?? currentTime
        this.resetTimeRequested = resetTime !== undefined
        this.resetTime = resetTime ?? 0

        const vp = this.getVideoPlayer()
        vp?.play(startTime, endTime)
            .then(() => this.addViewedBy())
            .catch(displayError)
    }

    render() {
        const { draftVideoPlayer } = this.props
        const {
            passage,
            currentVideos,
            passageVideo,
            setCurrentTime,
            playbackRate,
            passageSegment: segment,
            videoPlaybackKeydownEnabled
        } = draftVideoPlayer

        if (!passageVideo || !passage) {
            return null
        }

        log(`render ${passageVideo._id}`)

        // Do not allow editing of segment labels
        const segmentLabelsDraft: PassageSegmentLabel[] = []
        const editingSegmentLabels = false
        const setSegmentLabelsDraft = () => {}
        const { videoWidth } = this

        return (
            <div className="draft-video-player">
                <div className="video-player-container">
                    <VideoPlayer
                        ref={(videoPlayer) => (this.videoPlayer = videoPlayer)}
                        passage={passage}
                        video={passageVideo}
                        playbackRate={playbackRate}
                        vvc={currentVideos}
                        onTick={(currentTime) => setCurrentTime(currentTime)}
                        onPlayingStatus={this.onPlayingStatus}
                        onCanPlayThrough={this.onCanPlayThrough}
                        play={this.play}
                        stop={this.stopAndDoNotReset}
                        setVideoWidth={(width) => (this.videoWidth = width)}
                        disablePlay={!videoPlaybackKeydownEnabled}
                    >
                        {segment && (
                            <SegmentLabelsPosition
                                {...{
                                    segmentLabelsDraft,
                                    editingSegmentLabels,
                                    setSegmentLabelsDraft,
                                    segment,
                                    videoWidth
                                }}
                            />
                        )}
                    </VideoPlayer>
                </div>
                <div className="video-player-controls" style={{ flexShrink: 0, flexBasis: '33px' }} />
            </div>
        )
    }
}

import { observer } from 'mobx-react'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { displayError } from '../utils/Errors'
import FilePicker from '../utils/FilePicker'

interface PassageResourceFilePickerProps {
    enabled: boolean
    className: string
    resourceType: 'audio' | 'pdf'
    uploadFile: (file: File) => void
}

export const PassageResourceFilePicker: FC<PassageResourceFilePickerProps> = observer(
    ({ enabled, className, resourceType, uploadFile }) => {
        const { t } = useTranslation()

        const accept = resourceType === 'audio' ? 'audio/*' : 'application/pdf'

        const upload = (files: FileList) => {
            if (files.length !== 1) {
                displayError(t('You must drop exactly one file.'))
                return
            }

            const file = files[0]
            if (
                (resourceType === 'audio' && !file.type.startsWith('audio/')) ||
                (resourceType === 'pdf' && file.type !== 'application/pdf')
            ) {
                displayError(t('Cannot upload this type of file.'))
                return
            }

            const maxFileSize = 50 * 1024 * 1024
            if (file.size > maxFileSize) {
                displayError(t('File must be smaller than 50MB.'))
                return
            }

            uploadFile(file)
        }

        return (
            <FilePicker
                className={className}
                enabled={enabled}
                accept={accept}
                setSelectedFiles={(files) => upload(files)}
            />
        )
    }
)

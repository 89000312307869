import { observable } from 'mobx'

import { DBObject } from './DBObject'

export class PassageSegmentResource extends DBObject {
    @observable text = ''

    toDocument() {
        const { text } = this
        return this._toDocument({ text, model: 13 })
    }

    copy() {
        let copy = new PassageSegmentResource(this._id, this.db)
        copy = Object.assign(copy, this)
        return copy
    }

    async setText(text: string) {
        if (this.text.trim() === text.trim()) {
            return
        }

        const doc = this._toDocument({ text, model: 13 })
        await this.db.put(doc)
    }
}

import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { PencilButton } from '../utils/Buttons'
import { systemError } from '../utils/Errors'
import { SegmentTextEditor } from '../utils/SegmentTextEditor'
import { SegmentWarningIcon } from '../utils/Icons'

import { PassageSegment } from '../../models3/PassageSegment'
import { Root } from '../../models3/Root'

import './Segments.css'

interface SegmentTranscriptionProps {
    segment: PassageSegment
    rt: Root
    className: string
    readOnly?: boolean
    showMessage?: boolean
}

export const SegmentTranscription = observer(
    ({ segment, rt, className, readOnly, showMessage }: SegmentTranscriptionProps) => {
        const [editing, setEditing] = useState(false)
        const { t } = useTranslation()
        const transcription = segment.transcriptions.length ? segment.transcriptions[0] : undefined
        const text = transcription ? transcription.text : ''
        const recordingDateString = rt?.passage?.findVideo(segment._id)?.creationDate ?? ''
        const recordingDate = recordingDateString ? new Date(recordingDateString) : undefined
        const lastUpdatedDate = transcription ? new Date(transcription.modDate) : undefined

        return (
            <div className={`segment-transcription ${className}`}>
                <div className="segment-dialog-heading">{t('segmentTranscriptionHeader')}</div>
                {showMessage && recordingDate && lastUpdatedDate && lastUpdatedDate < recordingDate && (
                    <>
                        <SegmentWarningIcon />
                        {t('olderThanSegment')}
                    </>
                )}
                <SegmentTextEditor
                    savedText={text}
                    save={(value) => {
                        const currentTranscription = segment.transcriptions.length
                            ? segment.transcriptions[0]
                            : segment.createTranscription()
                        currentTranscription.setText(value).catch(systemError)
                    }}
                    onClose={() => setEditing(false)}
                    editing={editing}
                    notEditingControls={
                        !readOnly && (
                            <PencilButton
                                enabled={rt.iAmInterpreter}
                                onClick={() => rt.iAmInterpreter && setEditing(true)}
                                className="right-pane-button default-blue-icon opaque-on-hover"
                                tooltip={t('Edit')}
                            />
                        )
                    }
                />
            </div>
        )
    }
)

import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { confirmAlert } from 'react-confirm-alert'

import { AddButton, PencilButton, TrashButton } from '../utils/Buttons'
import { SegmentTextEditor } from '../utils/SegmentTextEditor'

import { PassageSegment } from '../../models3/PassageSegment'
import { PassageSegmentResource } from '../../models3/PassageSegmentResource'
import { Root } from '../../models3/Root'

type SegmentResourceTextProps = {
    resource: PassageSegmentResource
    removeResource: () => void
    rt: Root
}

const SegmentResourceText = observer(({ resource, removeResource, rt }: SegmentResourceTextProps) => {
    const { t } = useTranslation()
    const [editing, setEditing] = useState(false)

    function confirmDelete() {
        confirmAlert({
            title: t('Delete segment reference text?'),
            message: t('Are you sure you want to delete this segment reference text?'),
            confirmLabel: t('Delete'),
            cancelLabel: t('Cancel'),
            onConfirm: () => removeResource()
        })
    }

    return (
        <SegmentTextEditor
            savedText={resource.text}
            save={(text) => resource.setText(text)}
            onClose={() => setEditing(false)}
            editing={editing}
            notEditingControls={
                <>
                    <PencilButton
                        enabled={rt.iAmInterpreter}
                        onClick={() => rt.iAmInterpreter && setEditing(true)}
                        className="right-pane-button default-blue-icon opaque-on-hover"
                        tooltip={t('Edit')}
                    />
                    <TrashButton
                        onClick={() => rt.iAmInterpreter && confirmDelete()}
                        tooltip={t('Delete')}
                        className="right-pane-button default-blue-icon opaque-on-hover"
                        buttonClassName=""
                        enabled={rt.iAmInterpreter}
                    />
                </>
            }
        />
    )
})

type SegmentResourcesViewerProps = {
    segment: PassageSegment
    rt: Root
    className: string
}

export default observer(({ segment, rt, className }: SegmentResourcesViewerProps) => {
    const { t } = useTranslation()
    const [openResource, setOpenResource] = useState<PassageSegmentResource>()

    useEffect(() => {
        setOpenResource(undefined)
    }, [segment])

    return (
        <div className={`segment-resource-text-area ${className}`}>
            <div className="segment-dialog-heading">{t('referenceTextsPanel')}</div>
            {segment.resources.map((sr) => (
                <SegmentResourceText
                    key={sr._id}
                    resource={sr}
                    removeResource={() => segment.removeSegmentResource(sr._id)}
                    rt={rt}
                />
            ))}
            {openResource && (
                <SegmentTextEditor
                    savedText=""
                    save={(value) => {
                        openResource.setText(value)
                        setOpenResource(undefined)
                    }}
                    onClose={() => setOpenResource(undefined)}
                    editing
                    notEditingControls=""
                />
            )}
            <AddButton
                className="right-pane-button default-blue-icon"
                buttonClassName=""
                enabled={rt.iAmInterpreter}
                onClick={() => rt.iAmInterpreter && setOpenResource(segment.createSegmentResource())}
                tooltip={t('Add segment reference text')}
            />
        </div>
    )
})

/* eslint-disable react/prefer-stateless-function */

import React, { Component } from 'react'
import './Passage.css'
import { RootConsumer } from '../app/RootContext'

class TourPassage extends Component {
    render() {
        const buttonCN = 'btn passage-button passage-selected passage-video-present'

        return (
            <RootConsumer>
                {() => (
                    <div className="tour-passage-box">
                        <span
                            className="passage-handle tour-passage-handle"
                            data-toggle="tooltip"
                            title="Drag to reorder."
                        >
                            <i className="fas fa-bars" />
                        </span>
                        <span>
                            <button type="button" className={buttonCN}>
                                The Lost Coin
                            </button>
                        </span>
                        <span className="passage-delete-button" data-toggle="tooltip" title="Delete passage.">
                            <i className="fas fa-fw fa-trash-alt" />
                        </span>
                    </div>
                )}
            </RootConsumer>
        )
    }
}

export default TourPassage

/* eslint-disable react/no-array-index-key */

import { observer } from 'mobx-react'
import React, { FC } from 'react'
import { DropdownButton, MenuItem } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { Member } from '../../models3/Member'
import { Passage } from '../../models3/Passage'
import { Project } from '../../models3/Project'

import { MembersIcon } from './Icons'
import './MemberDisplay.css'

type ImageSize = 'small' | 'large'
type MemberAvatarProps = {
    imageUrl: string
    variation: ImageSize
}

function getMemberAvatarClassName(imageSize: ImageSize) {
    let className = ''
    if (imageSize === 'large') {
        className = 'member-image-container-lg'
    } else if (imageSize === 'small') {
        className = 'member-image-container-sm'
    }
    return className
}

function getDefaultImageClassName(imageSize: ImageSize) {
    let defaultImageClassName = ''
    if (imageSize === 'large') {
        defaultImageClassName = 'default-member-image-lg'
    } else if (imageSize === 'small') {
        defaultImageClassName = 'default-member-image-sm'
    }
    return defaultImageClassName
}

const MemberAvatar: FC<MemberAvatarProps> = observer(({ imageUrl, variation }) => {
    const className = getMemberAvatarClassName(variation)
    const defaultImageClassName = getDefaultImageClassName(variation)

    if (imageUrl) {
        return <img className={className} src={imageUrl} />
    }

    return <MembersIcon className={`${className} ${defaultImageClassName}`} tooltip="" />
})

type MemberDisplayProps = {
    member: Member
    imageSize: ImageSize
    showFullName?: boolean
    onlyShowAvailableParts?: boolean
    onlyShowUsernameIfNoImage?: boolean
}

export const MemberDisplay: FC<MemberDisplayProps> = observer(
    ({ member, imageSize, showFullName, onlyShowAvailableParts, onlyShowUsernameIfNoImage }) => {
        const { imageUrl, email } = member
        const username = showFullName ? email : email.split('@')[0]

        if (onlyShowAvailableParts) {
            if (imageUrl) {
                return (
                    <span className="member-display-full inline-flex-centered">
                        <span className="member-image-container-extra-space">
                            <MemberAvatar imageUrl={imageUrl} variation={imageSize} />
                        </span>
                        {username}
                    </span>
                )
            }

            return <span>{username}</span>
        }

        if (onlyShowUsernameIfNoImage) {
            if (imageUrl) {
                return (
                    <span className="member-display inline-flex-centered">
                        <MemberAvatar imageUrl={imageUrl} variation={imageSize} />
                    </span>
                )
            }

            return <span>{username}</span>
        }

        return (
            <span className="member-display-full inline-flex-centered">
                <span className="member-image-container-extra-space">
                    <MemberAvatar imageUrl={imageUrl} variation={imageSize} />
                </span>
                {username}
            </span>
        )
    }
)

type MemberSelectorProps = {
    project: Project
    passage: Passage
    allowChange: boolean
}

export const MemberSelector: FC<MemberSelectorProps> = ({ project, passage, allowChange }) => {
    const { t } = useTranslation()

    const { members } = project
    const { assignee } = passage
    const currentMember = members.find((mem) => mem.email === assignee)

    return (
        <span className="member-selector-current-status">
            {currentMember ? (
                <MemberDisplay member={currentMember} imageSize="small" onlyShowUsernameIfNoImage />
            ) : (
                <div className="member-display">{t('Unassigned')}</div>
            )}
            <DropdownButton
                bsStyle="default"
                id="member-selector"
                title=""
                className="sl-dropdown member-selector-dropdown"
                disabled={!allowChange}
            >
                <MenuItem onSelect={() => passage.setAssignee('')}>
                    <div className="member-display">{t('Unassigned')}</div>
                </MenuItem>
                {members.map((member, i) => (
                    <MenuItem key={i} onSelect={() => passage.setAssignee(member.email)}>
                        <MemberDisplay member={member} imageSize="small" />
                    </MenuItem>
                ))}
            </DropdownButton>
        </span>
    )
}

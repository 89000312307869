/* eslint-disable no-restricted-syntax */
/* eslint-disable max-classes-per-file */

import { observable } from 'mobx'
import { Backend } from './backend'

export class WrittenLanguage {
    langName: string

    langId: number

    order: number

    constructor(doc: any) {
        this.langName = doc.lang_name
        this.langId = doc.lang_id
        this.order = doc.order
    }
}

export class Dialect {
    id: number

    abbr: string

    focal: boolean

    name: string

    constructor(doc: any) {
        this.id = doc.id
        this.abbr = doc.abbr
        this.focal = doc.focal
        this.name = doc.name
    }
}

export class GrammarCategory {
    id: number

    name: string

    constructor(doc: any) {
        this.id = doc.id
        this.name = doc.name
    }
}

export class SentenceText {
    lang_name: string

    lang_id: number

    order: number

    text: string

    constructor(doc: any) {
        this.lang_name = doc.lang_name
        this.lang_id = doc.lang_id
        this.order = doc.order
        this.text = doc.text
    }
}

export class Sentence {
    id: number

    path: string

    hash: string

    sentence_texts: SentenceText[]

    constructor(doc: any) {
        this.id = doc.id
        this.path = doc.path
        this.hash = doc.hash
        this.sentence_texts = (doc.sentence_texts || []).map((_item: any) => new SentenceText(_item))
    }
}

export class ExtraText {
    lang_name: string

    lang_id: number

    order: number

    text: string

    constructor(doc: any) {
        this.lang_name = doc.lang_name
        this.lang_id = doc.lang_id
        this.order = doc.order
        this.text = doc.text
    }
}

export class ExtraMediaFile {
    id: number

    path: string

    hash: string

    constructor(doc: any) {
        this.id = doc.id
        this.path = doc.path
        this.hash = doc.hash
    }
}

export class GlossText {
    sense: Sense

    lang_name: string

    lang_id: number

    order: number

    text: string

    constructor(sense: Sense, doc: any) {
        this.sense = sense
        this.lang_name = doc.lang_name || 'English'
        this.lang_id = doc.lang_id || 0
        this.order = doc.order || 0
        this.text = doc.text || ''
    }

    // In order to write an object to an external database we need to
    // create a sanitized clone with no loops.
    sanitize() {
        const clone: any = { ...this }
        clone.sense = undefined // break look caused by parent link
        return clone
    }
}

export class Sense {
    sign: Sign

    id: number

    grammar_category_id: number

    gram_cat_name: string

    dialect_ids: []

    dialects: Dialect[]

    gloss_texts: GlossText[]

    sentences: Sentence[]

    // URL for gloss for sign (in SL of this project)
    // NOT CURRENTLY USED by SooSL, but used by SLTT
    gloss_path: string

    constructor(sign: Sign, doc: any) {
        function keepDialect(element: Dialect) {
            return doc.dialect_ids.includes(element.id)
        }
        function keepGramCat(element: GrammarCategory) {
            return doc.grammar_category_id === element.id
        }

        this.sign = sign
        this.id = doc.id
        this.grammar_category_id = doc.grammar_category_id

        this.gram_cat_name = ''
        if (this.grammar_category_id) {
            this.gram_cat_name = sign.gram_cats.filter(keepGramCat)[0].name
        }

        this.dialect_ids = doc.dialect_ids
        this.dialects = sign.dialects.filter(keepDialect)
        this.gloss_texts = (doc.gloss_texts || []).map((_item: any) => new GlossText(this, _item))
        this.sentences = (doc.sentences || []).map((_item: any) => new Sentence(_item))

        this.gloss_path = doc.gloss_path || ''
    }

    // Search for a gloss with one of the given language names.
    // Return it or '' if none found.
    getGlossText(lang_names: string[]) {
        for (const lang_name of lang_names) {
            const gt = this.gloss_texts.find((text) => text.lang_name === lang_name)
            if (gt) return gt.text
        }

        return ''
    }

    // Add a gloss with the specified lang_name.
    // If there already is a gloss with this lang_name, replace it.
    setGlossText(text: string, lang_name: string) {
        const gts = this.gloss_texts.filter((gt) => gt.lang_name !== lang_name)
        gts.push(new GlossText(this, { text, lang_name }))
        this.gloss_texts = gts
    }

    // In order to write an object to an external database we need to
    // create a sanitized clone with no loops.
    sanitize() {
        const clone: any = { ...this }
        clone.sign = undefined // remove loop to parent

        clone.gloss_texts = this.gloss_texts.map((gt) => gt.sanitize())
        return clone
    }
}

// Sign
//     senses
//        gloss_texts

export class Sign {
    id: number

    @observable path: string // full url for sign video

    hash: string

    component_codes: string[]

    senses: Sense[]

    extra_texts: ExtraText[]

    extra_media_files: ExtraMediaFile[]

    dialects: Dialect[]

    gram_cats: GrammarCategory[]

    constructor(doc: any, dialects: Dialect[], gram_cats: GrammarCategory[]) {
        this.id = doc.id
        this.path = doc.path || ''
        this.hash = doc.hash || ''
        this.component_codes = doc.component_codes || []
        this.dialects = dialects
        this.gram_cats = gram_cats

        this.senses = (doc.senses || []).map((sense: any) => new Sense(this, sense))
        this.extra_texts = (doc.extra_texts || []).map((_item: any) => new ExtraText(_item))
        this.extra_media_files = (doc.extra_media_files || []).map((_item: any) => new ExtraMediaFile(_item))
    }

    // In order to write an object to an external database we need to
    // create a sanitized clone with no loops.
    sanitize() {
        const clone: any = { ...this }
        clone.dialects = undefined // remove loop to parent
        clone.gram_cats = undefined // remove loop to parent

        clone.senses = this.senses.map((sense) => sense.sanitize())
        return clone
    }

    // Upload a file and set video path for sign to uploaded file url

    async uploadFile(projectName: string, fileName: string, file: File) {
        const contentType = 'video/mp4'

        const url = await Backend.getUploadUrl(projectName, fileName, contentType)
        await Backend.uploadFile(url, file, contentType)

        const path = url.split('?')[0]
        return path
    }

    async uploadBlob(projectName: string, fileName: string, blob: Blob) {
        const contentType = 'video/webm'

        const url = await Backend.getUploadUrl(projectName, fileName, contentType)
        console.log(`setPathFromBlob url=${url}`)

        await Backend.uploadBlob(url, blob, contentType)
        console.log(`setPathFromBlob putBlob DONE`)

        const path = url.split('?')[0]
        return path
    }

    async put(projectName: string) {
        await Backend.putSign(projectName, this.sanitize())
    }
}

export class Project {
    soosl_version: string

    project_name: string

    project_description: string

    writtenLanguages: WrittenLanguage[]

    dialects: Dialect[]

    grammar_categories: GrammarCategory[]

    @observable signs: Sign[]

    constructor(doc: any) {
        this.soosl_version = doc.soosl_version
        this.project_name = doc.project_name
        this.project_description = doc.project_description
        this.writtenLanguages = (doc.written_languages || []).map((lang: any) => new WrittenLanguage(lang))
        this.dialects = (doc.dialects || []).map((dialect: any) => new Dialect(dialect))
        this.grammar_categories = (doc.grammar_categories || []).map(
            (grammar_category: any) => new GrammarCategory(grammar_category)
        )
        this.signs = (doc.signs || []).map((sign: any) => new Sign(sign, this.dialects, this.grammar_categories))
    }

    async addSign(sign: Sign) {
        await Backend.putSign(this.project_name, sign.sanitize())
        this.signs.push(sign)
    }

    async deleteSign(id: number) {
        console.log('deleteSign', this.project_name, id)

        await Backend.deleteSign(this.project_name, id)
        const idx = this.signs.findIndex((s) => s.id === id)
        if (idx >= 0) {
            this.signs.splice(idx, 1)
        }
    }

    createSignFromGloss(gloss: string, lang_name?: string) {
        const dialects = this.dialects || []
        const gram_cats = this.grammar_categories || []

        const sign = new Sign({}, dialects, gram_cats)

        sign.id = new Date().getTime()

        const sense = new Sense(sign, { dialect_ids: [1] })
        sign.senses.push(sense)

        const glossText = new GlossText(sense, {
            lang_name: lang_name || 'English',
            lang_id: 1,
            order: 0,
            text: gloss
        })

        sense.gloss_texts.push(glossText)

        return sign
    }
}

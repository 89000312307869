import React, { Component } from 'react'
import { observer } from 'mobx-react'
import InputSlider from 'react-input-slider'

import VideoPlayer from './VideoPlayer'
import { ViewableVideoCollection } from './ViewableVideoCollection'

import { Passage } from '../../models3/Passage'
import { PassageVideo } from '../../models3/PassageVideo'

import { fmt } from '../utils/Fmt'

import './Video.css'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:RangeVideoPlayer')

/**
 * Play a video within startTime/endTime range.
 * This module should never access root, all events are communicated to parent.
 * Also contains a rate control for the video.
 *
 * RangeVideoPlayer
 *    VideoPlayer - handle changes at segment/patch boundaries
 *       CoreVideoPlayer - On request select/play main video or patch. Manage time poller.
 */

interface IRangeVideoPlayer {
    passage: Passage
    video: PassageVideo
    currentVideos: ViewableVideoCollection
    startTime: number
    endTime: number
    playbackRate: number
    setPlaybackRate: (rate: number) => void
    className?: string
    onTick?: (currentTime: number) => void
    onEnded?: () => void
    onPlayingStatus?: (isPlaying: boolean) => void
    enableSpeedSlider?: boolean
}

@observer
class RangeVideoPlayer extends Component<IRangeVideoPlayer> {
    videoPlayer: VideoPlayer | null = null

    constructor(props: IRangeVideoPlayer) {
        super(props)

        const { video, startTime, endTime } = props
        log('constructor', fmt({ video, startTime, endTime }))

        this.playRange = this.playRange.bind(this)
        this.stop = this.stop.bind(this)
        this.onRateChange = this.onRateChange.bind(this)
    }

    onRateChange = (pos: any /* {x:, y: } */) => {
        console.log(`rateChange ${pos.y}`)
        const { setPlaybackRate } = this.props
        const playbackRate = 2.0 - pos.y
        setPlaybackRate(playbackRate)
    }

    public playRange = async (startTime?: number, endTime?: number) => {
        console.clear()
        log('playRange', startTime, endTime)

        const { videoPlayer } = this
        if (videoPlayer) {
            await videoPlayer.play(startTime, endTime)
        }
    }

    public stop = () => {
        log('stop')
        const { videoPlayer } = this
        const { onEnded } = this.props

        if (videoPlayer) videoPlayer.stop()
        if (onEnded) setTimeout(() => onEnded(), 800)
    }

    // Returns true if video control was sufficiently setup to set time
    // eslint-disable-next-line react/no-unused-class-component-methods
    public setCurrentTime = (newTime: number) => {
        log('setCurrentTime', fmt({ newTime }))

        const { videoPlayer } = this
        if (!videoPlayer) return false

        return videoPlayer.setCurrentTime(newTime)
    }

    render() {
        const {
            passage,
            video,
            currentVideos,
            onTick,
            onEnded,
            onPlayingStatus,
            className,
            playbackRate,
            enableSpeedSlider
        } = this.props

        if (!video || !passage) return null

        log(`render ${video._id}`)
        const tooltip = `Speed = ${playbackRate.toFixed(1)}`

        return (
            <div className={className}>
                <div className="video-player-container">
                    <VideoPlayer
                        ref={(videoPlayer) => (this.videoPlayer = videoPlayer)}
                        passage={passage}
                        video={video}
                        playbackRate={playbackRate}
                        vvc={currentVideos}
                        onTick={(currentTime: number) => {
                            if (onTick) onTick(currentTime)
                        }}
                        onEnded={onEnded}
                        onPlayingStatus={(isPlaying: boolean) => onPlayingStatus && onPlayingStatus(isPlaying)}
                        play={this.playRange}
                        stop={this.stop}
                    />
                </div>
                {enableSpeedSlider && (
                    <div className="video-player-controls">
                        <div className="u-slider u-slider-y video-player-slider">
                            <InputSlider
                                className="slider video-rate-input-slider"
                                slidertooltip={tooltip}
                                axis="y"
                                y={2.0 - playbackRate}
                                ymax={2}
                                ystep={0.1}
                                onChange={this.onRateChange}
                            />
                        </div>
                    </div>
                )}
            </div>
        )
    }
}

export default RangeVideoPlayer

import { Component, FunctionComponent, useEffect, useRef, useState } from 'react'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import { t } from 'i18next'

import { createLink } from '../utils/Helpers'
import { INoteRoot } from './NoteDialog'
import { NoteItem } from './NoteItem'
import { NoteTextEditor } from './NoteTextEditor'

import MediaThumbnail from '../utils/MediaThumbnail'
import { WarningIcon } from '../utils/Icons'
import {
    CancelButtonWithLabel,
    PauseButtonWithLabel,
    RecordButtonWithLabel,
    StopButtonWithLabel,
    ClipboardButton,
    PencilButton,
    SlttHelp,
    RecordAudioNoteCommentButton
} from '../utils/Buttons'
import TextInput from '../utils/TextInput'
import { displayError, displayInfo, systemError } from '../utils/Errors'

import VideoRecorder, { AudioRecorderComponent, AVTTRecordingState } from '../video/VideoRecorder'
import { RecordingDoneParams, VideoUploader } from '../video/VideoUploader'

import NoteLocationEditor from '../video/NoteLocationEditor'

import { IDateFormatter } from '../../models3/DateUtilities'
import { Passage } from '../../models3/Passage'
import { PassageNote } from '../../models3/PassageNote'
import { PassageNoteItem } from '../../models3/PassageNoteItem'
import { PassageVideo } from '../../models3/PassageVideo'

import { NoteMarkerControl } from './NoteMarkerDropdown'
import { LiveWaveformVisualizerWrapper } from '../video/WaveformVisualizer'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:NoteMain')

const intest = localStorage.getItem('intest') === 'true'

interface INoteAddItemButtons {
    addText: () => void
    addVideo: () => void
    addAudioRecording: () => void
    iAmInterpreter: boolean
    note: PassageNote
}

const NoteAddItemButtons: FunctionComponent<INoteAddItemButtons> = ({
    iAmInterpreter,
    note,
    addText,
    addVideo,
    addAudioRecording
}) => {
    if (!iAmInterpreter || note?.resolved) return null

    return (
        <div className="note-add-buttons">
            <div
                className=" note-record-button-video et-right fas fa-3x fa-video text-danger"
                data-id="create-video-note-item"
                onClick={addVideo}
            />
            <RecordAudioNoteCommentButton
                onClick={addAudioRecording}
                className="note-audio-record-icon"
                buttonClassName="note-audio-record-button"
                enabled
            />
            <div
                className="note-record-button-write et-right fa fa-3x fa-edit"
                data-id="create-text-note-item"
                onClick={addText}
            />
        </div>
    )
}

interface INoteMessage {
    passage: Passage | null
    video: PassageVideo
    note: PassageNote
    dateFormatter: IDateFormatter
}

const NoteMessage: FunctionComponent<INoteMessage> = ({ passage, video, note, dateFormatter }) => {
    if (!passage || (note.onTop && !note.inIgnoredSegment)) {
        return null
    }

    const noteVideo = note.toVideo(passage)
    if (!noteVideo) {
        return null
    }

    let patchedOverMessage = ''
    if (!note.onTop) {
        if (noteVideo._id === video?._id) {
            patchedOverMessage = t('noteApplyMessage')
        } else {
            const videoDate = new Date(noteVideo.creationDate)
            const visibleVideoDate = dateFormatter.format(videoDate)
            patchedOverMessage = t('noteApplyToPatchMessage', { visibleVideoDate })
        }
    }

    return (
        <div className="note-messages">
            <div className="note-messages-icons">
                <WarningIcon className="note-messages-warning-icon" tooltip="" />
            </div>
            <div className="note-messages-list">
                <div>{patchedOverMessage}</div>
                {note.inIgnoredSegment && <div>{t('Note is in a segment that is being ignored.')}</div>}
            </div>
        </div>
    )
}

interface INoteCitation {
    noteRoot: INoteRoot
    allowEditing: boolean
    setEditing: (value: boolean) => void
    position: number
}

// Show the user the citation from the main video that this note applies to.
// Allow the user to adjust the citation.

const NoteCitation: FunctionComponent<INoteCitation> = ({ noteRoot, allowEditing, setEditing, position }) => {
    const [adjusting, setAdjusting] = useState(false)

    const tooltip = t('notePlayRecording')
    const id = 'notes.html#notecitation'

    const { passage, passageVideo, note } = noteRoot

    if (!passageVideo || !passage) return null

    const citationVideo = passage.findVideo(note._id)
    if (!citationVideo) return null

    function _setAdjusting(value: boolean) {
        setAdjusting(value)
        setEditing(value)
    }

    const baseVideo = citationVideo?.baseVideo(passage) || citationVideo
    if (!adjusting)
        return (
            <div className="media-thumbnail note-citation" data-tip data-for={id}>
                <SlttHelp id={id} tooltip={tooltip} place="top">
                    <MediaThumbnail
                        url={citationVideo.url}
                        creator={baseVideo.creator}
                        isImage={false}
                        currentTime={position}
                        onClickVideo={() => allowEditing && _setAdjusting(true)}
                        onClickImage={() => {}}
                    />
                </SlttHelp>
            </div>
        )

    return <NoteLocationEditor {...{ noteRoot }} onDoneEditing={() => _setAdjusting(false)} />
}

interface INoteDescription {
    noteRoot: INoteRoot
    allowEditing: boolean
    setEditing: (value: boolean) => void
}

const NoteDescription: FunctionComponent<INoteDescription> = ({ noteRoot, setEditing, allowEditing }) => {
    const { iAmAdmin, note, username, useMobileLayout, createNoteIfNonexistent } = noteRoot
    const { description } = note

    const [editing, setLocalEditing] = useState(false)

    function _setEditing(value: boolean) {
        setLocalEditing(value)
        setEditing(value)
    }

    const placeholder = t('Note description...')
    const canEdit = !note.resolved && (iAmAdmin || note.creator === username)

    if (!canEdit) {
        if (description) return <div className="note-description">{description}</div>
        return null
    }

    if (!editing) {
        const tooltip = t('Write a short description for a note.')
        const id = 'notes.html#notedescription'

        return (
            <div className="note-description">
                {!description && <i>{placeholder}</i>}
                {description}
                {allowEditing && !useMobileLayout && (
                    <SlttHelp id={id} tooltip={tooltip} place="top">
                        <PencilButton
                            enabled
                            className="note-item-edit-button"
                            onClick={() => _setEditing(true)}
                            tooltip=""
                        />
                    </SlttHelp>
                )}
            </div>
        )
    }

    const save = async (value: string) => {
        try {
            await createNoteIfNonexistent()
            await note.setDescription(value)
            _setEditing(false)
        } catch (err) {
            systemError(err)
        }
    }

    return (
        <TextInput
            initialValue={description}
            message=""
            placeholder={placeholder}
            _onEnter={save}
            _onEscape={() => _setEditing(false)}
            allowEmptyValue
        />
    )
}

interface IVideoRecordingToolbar {
    start: () => void
    cancel: () => void
    pause: () => void
    resume: () => void
    stopAndSave: () => void
    recordingState: AVTTRecordingState
}

const VideoRecordingToolbar = ({
    start,
    stopAndSave,
    resume,
    pause,
    cancel,
    recordingState
}: IVideoRecordingToolbar) => {
    return (
        <div className="video-recording-toolbar">
            {['RECORDING'].includes(recordingState) && (
                <PauseButtonWithLabel
                    enabled
                    buttonClassName="note-video-button"
                    className="note-video-pause-recording-button"
                    onClick={pause}
                    tooltip={t('Pause recording')}
                />
            )}
            {!['RECORDING'].includes(recordingState) && (
                <RecordButtonWithLabel
                    enabled={['INITIALIZED', 'PAUSED'].includes(recordingState)}
                    onClick={recordingState === 'PAUSED' ? resume : start}
                    buttonClassName="note-video-button"
                    className="note-video-record-button"
                    tooltip={recordingState === 'INITIALIZED' ? t('Start recording') : t('Continue recording')}
                    selectionPresent={false}
                />
            )}
            <CancelButtonWithLabel
                enabled={['INITIALIZED', 'RECORDING', 'PAUSED'].includes(recordingState)}
                onClick={cancel}
                tooltip={t('Cancel recording')}
                buttonClassName="note-video-button"
                className="note-video-cancel-record-button"
            />
            <StopButtonWithLabel
                className="note-video-stop-button"
                enabled={['RECORDING', 'PAUSED'].includes(recordingState)}
                tooltip={t('Stop and save recording.')}
                onClick={stopAndSave}
            />
        </div>
    )
}

type NoteVideoRecorderProps = {
    url: string
    cancel: () => void
    save: (recordingDoneParams: Partial<RecordingDoneParams>) => Promise<void>
}

export const NoteVideoRecorder = ({ cancel, url, save }: NoteVideoRecorderProps) => {
    const [recordingState, setRecordingState] = useState<AVTTRecordingState>('NOT_INITIALIZED')
    const [videoUploader] = useState(() => new VideoUploader(url, save))
    const [mediaStream, setMediaStream] = useState<MediaStream>()
    const vr = useRef<VideoRecorder>(null)

    function keydown(e: KeyboardEvent) {
        // We are assuming that other components that listen for keyboard events are
        // either unmounted, or ignore any events that occur while the note dialog is open.
        e.stopPropagation()
        log(`keydown code=${e.code}`, e)

        if (e.code === 'Escape') {
            if (recordingState !== 'NOT_INITIALIZED' && recordingState !== 'STOPPED') {
                vr.current?.cancel()
                return cancel()
            }
        }

        if (e.code === 'Space') {
            if (recordingState === 'INITIALIZED') {
                return vr.current?.startRecordingAfterCountdown()
            }
            if (recordingState === 'RECORDING') {
                return vr.current?.pause()
            }
            if (recordingState === 'PAUSED') {
                return vr.current?.resume()
            }
        }

        if (e.code === 'Enter' && (recordingState === 'RECORDING' || recordingState === 'PAUSED')) {
            return vr.current?.stop()
        }
    }

    useEffect(() => {
        window.addEventListener('keydown', keydown)
        return () => {
            window.removeEventListener('keydown', keydown)
        }
    })

    return (
        <div className="note-video-recorder" style={{ maxHeight: window.innerHeight }}>
            <VideoRecordingToolbar
                start={() => vr.current?.startRecordingAfterCountdown()}
                stopAndSave={() => vr.current?.stop()}
                cancel={() => {
                    vr.current?.cancel()
                    cancel()
                }}
                pause={() => vr.current?.pause()}
                resume={() => vr.current?.resume()}
                recordingState={recordingState}
            />
            <VideoRecorder
                ref={vr}
                setRecordingState={setRecordingState}
                videoUploader={videoUploader}
                setMediaStream={setMediaStream}
                usePauseAndResume
            />
            <div className="note-audio-recorder-waveform-wrapper">
                <LiveWaveformVisualizerWrapper
                    mediaStream={mediaStream}
                    recordingState={recordingState}
                    isAudioOnly={false}
                    className="note-recording-audio-waveform-visualizer"
                />
            </div>
        </div>
    )
}

type NoteAudioRecorderProps = {
    url: string
    cancel: () => void
    save: (recordingDoneParams: Partial<RecordingDoneParams>) => Promise<void>
}

export const NoteAudioRecorder = ({ cancel, url, save }: NoteAudioRecorderProps) => {
    const [recordingState, setRecordingState] = useState<AVTTRecordingState>('NOT_INITIALIZED')
    const [videoUploader] = useState(() => new VideoUploader(url, save))
    const [mediaStream, setMediaStream] = useState<MediaStream>()
    const vr = useRef<AudioRecorderComponent>(null)

    function keydown(e: KeyboardEvent) {
        // We are assuming that other components that listen for keyboard events are
        // either unmounted, or ignore any events that occur while the note dialog is open.
        e.stopPropagation()
        log(`keydown code=${e.code}`, e)

        if (e.code === 'Escape') {
            vr.current?.cancel()
            return cancel()
        }

        if (e.code === 'Space') {
            if (recordingState === 'INITIALIZED') {
                return vr.current?.startRecording()
            }
            if (recordingState === 'RECORDING') {
                return vr.current?.pause()
            }
            if (recordingState === 'PAUSED') {
                return vr.current?.resume()
            }
        }

        if (
            e.code === 'Enter' &&
            recordingState !== 'NOT_INITIALIZED' &&
            recordingState !== 'INITIALIZED' &&
            recordingState !== 'STOPPED'
        ) {
            return vr.current?.stop()
        }
    }

    useEffect(() => {
        window.addEventListener('keydown', keydown)
        return () => {
            window.removeEventListener('keydown', keydown)
        }
    })

    return (
        <div className="note-audio-recorder" style={{ maxHeight: window.innerHeight }}>
            <VideoRecordingToolbar
                start={() => vr.current?.startRecording()}
                stopAndSave={() => vr.current?.stop()}
                cancel={() => {
                    vr.current?.cancel()
                    cancel()
                }}
                pause={() => vr.current?.pause()}
                resume={() => vr.current?.resume()}
                recordingState={recordingState}
            />
            <div className="avtt-audio-area">
                <AudioRecorderComponent
                    ref={vr}
                    setRecordingState={setRecordingState}
                    videoUploader={videoUploader}
                    setMediaStream={setMediaStream}
                    usePauseAndResume
                />
            </div>
            <div className="note-audio-recorder-waveform-wrapper">
                <LiveWaveformVisualizerWrapper
                    mediaStream={mediaStream}
                    recordingState={recordingState}
                    isAudioOnly
                    className="note-recording-audio-waveform-visualizer"
                />
            </div>
        </div>
    )
}

interface INoteMain {
    noteRoot: INoteRoot
    closeNoteDialog: () => void
    editing: boolean
    setEditing: (value: boolean) => void
    selectNoteType: (note: PassageNote, type: string) => void
    isSaved: boolean
}

@observer
export class NoteMain extends Component<INoteMain> {
    @observable recordingVideoItem?: PassageNoteItem

    @observable recordingAudioItem?: PassageNoteItem

    @observable editingTextItem?: PassageNoteItem

    @observable currentNote

    @observable firstTimeSaved = false

    constructor(props: INoteMain) {
        super(props)

        const { noteRoot } = this.props
        this.currentNote = noteRoot.note
        this.setEditingTextItem = this.setEditingTextItem.bind(this)
    }

    componentDidUpdate() {
        const { noteRoot, setEditing } = this.props

        if (this.currentNote._id !== noteRoot.note._id) {
            this.setEditingTextItem(undefined)
            setEditing(false)
            this.currentNote = noteRoot.note
        }
    }

    componentWillUnmount() {
        const { setEditing } = this.props
        setEditing(false)
    }

    setEditingTextItem(item?: PassageNoteItem) {
        const { setEditing } = this.props
        this.editingTextItem = item
        setEditing(!!item)
    }

    createLinkToNote = async () => {
        const { noteRoot } = this.props
        const { note, currentTime, project } = noteRoot
        const text = createLink({ project, itemId: note._id, time: currentTime })
        try {
            await navigator.clipboard.writeText(text)
            displayInfo(t('Link copied to clipboard. You can paste this into a note or an email.'))
        } catch (error) {
            displayError(error, t('Failed to copy!'))
        }
    }

    saveNoteItemChanges = async (text: string) => {
        const { noteRoot } = this.props
        const { editingTextItem, firstTimeSaved } = this

        const { note, passage } = noteRoot

        try {
            if (editingTextItem) {
                const exists = note.items.find((item) => item._id === editingTextItem?._id)
                if (exists && firstTimeSaved && text.trim() === '') {
                    await noteRoot.note.removeItem(editingTextItem._id)
                } else if (exists && !firstTimeSaved) {
                    await editingTextItem.updateText(text)
                } else if (!exists && text.trim() !== '') {
                    editingTextItem.text = text
                    await note.addItem(editingTextItem, passage)
                    this.firstTimeSaved = true
                }
            }
        } catch (err) {
            systemError(err)
        }
    }

    createNoteItem = async () => {
        const { noteRoot } = this.props
        const { note } = noteRoot
        const item = note.createItem()
        this.setEditingTextItem(item)
    }

    addVideo = () => {
        const { noteRoot, setEditing } = this.props
        const { note, name } = noteRoot
        this.recordingVideoItem = note.createItem()
        this.recordingVideoItem.url = `${name}/${this.recordingVideoItem._id}`
        setEditing(true)
    }

    addAudioRecording = () => {
        const { noteRoot, setEditing } = this.props
        const { note, name } = noteRoot
        this.recordingAudioItem = note.createItem()
        this.recordingAudioItem.url = `${name}/${this.recordingAudioItem._id}`
        setEditing(true)
    }

    addVideoDone = async ({ err, blobsCount, url = '' }: Partial<RecordingDoneParams>) => {
        if (err) {
            this.recordingVideoItem = undefined
            displayError(err)
            return
        }

        const { recordingVideoItem } = this
        const { noteRoot, setEditing } = this.props
        const { note, passage, createNoteIfNonexistent } = noteRoot

        if (!recordingVideoItem) {
            return
        }

        if (intest) {
            // force to test url which is always present
            recordingVideoItem.url = url
        }

        recordingVideoItem.url = `${recordingVideoItem.url}-${blobsCount}`

        try {
            await createNoteIfNonexistent()
            await note.addItem(recordingVideoItem, passage)
            this.recordingVideoItem = undefined
            setEditing(false)
        } catch (error) {
            systemError(err)
        }
    }

    addAudioRecordingDone = async ({ err, blobsCount, mimeType = '', url = '' }: Partial<RecordingDoneParams>) => {
        if (err) {
            this.recordingAudioItem = undefined
            displayError(err)
            return
        }

        const { recordingAudioItem } = this
        const { noteRoot, setEditing } = this.props
        const { note, passage, createNoteIfNonexistent } = noteRoot

        if (!recordingAudioItem) {
            return
        }

        if (intest) {
            // force to test url which is always present
            recordingAudioItem.url = url
            recordingAudioItem.fileType = mimeType
        }

        recordingAudioItem.url = `${recordingAudioItem.url}-${blobsCount}`
        recordingAudioItem.fileType = mimeType

        try {
            await createNoteIfNonexistent()
            await note.addItem(recordingAudioItem, passage)
            this.recordingAudioItem = undefined
            setEditing(false)
        } catch (error) {
            systemError(err)
        }
    }

    closeRecorder = () => {
        const { setEditing } = this.props

        this.recordingVideoItem = undefined
        this.recordingAudioItem = undefined
        setEditing(false)
    }

    render() {
        const { noteRoot, closeNoteDialog, editing, setEditing, selectNoteType, isSaved } = this.props
        const { note, passage, passageVideo, dateFormatter, canViewConsultantOnlyFeatures, iAmInterpreter, project } =
            noteRoot
        const { recordingVideoItem, recordingAudioItem, createLinkToNote, editingTextItem } = this

        if (!passage || !passageVideo || !note) {
            return null
        }

        if (recordingVideoItem) {
            return (
                <NoteVideoRecorder
                    cancel={this.closeRecorder}
                    url={recordingVideoItem.url}
                    save={this.addVideoDone.bind(this)}
                />
            )
        }

        if (recordingAudioItem) {
            return (
                <NoteAudioRecorder
                    cancel={this.closeRecorder}
                    url={recordingAudioItem.url}
                    save={this.addAudioRecordingDone.bind(this)}
                />
            )
        }

        if (editingTextItem) {
            return (
                <div>
                    <NoteTextEditor
                        initialText=""
                        onSave={async (text) => {
                            const { createNoteIfNonexistent } = noteRoot
                            try {
                                await createNoteIfNonexistent()
                                await this.saveNoteItemChanges.bind(this)(text)
                                this.firstTimeSaved = false
                                this.setEditingTextItem(undefined)
                            } catch (err) {
                                this.setEditingTextItem(undefined)
                                systemError(err)
                            }
                        }}
                        onCancel={() => {
                            this.firstTimeSaved = false
                            this.setEditingTextItem(undefined)
                        }}
                    />
                </div>
            )
        }

        const allowEditing = !editing

        return (
            <div className="note-main-body">
                <NoteMessage {...{ passage, note, video: passageVideo, dateFormatter }} />
                <div className="note-main-header">
                    {passageVideo.isAudioOnly() ? (
                        <NoteLocationEditor noteRoot={noteRoot} onDoneEditing={() => {}} />
                    ) : (
                        <NoteCitation
                            {...{
                                noteRoot,
                                setEditing,
                                allowEditing,
                                position: noteRoot.note.position
                            }}
                        />
                    )}
                    <div className="note-main-header-buttons">
                        <NoteMarkerControl
                            note={note}
                            project={project}
                            allowEditing={!note.resolved && iAmInterpreter}
                            selectNoteType={selectNoteType}
                        />
                        <div className="note-description-wrapper">
                            <NoteDescription {...{ noteRoot, setEditing, allowEditing }} />
                        </div>
                        <div className="clipboard-button-wrapper">
                            <ClipboardButton
                                buttonClassName=""
                                className="clipboard-button"
                                enabled={isSaved}
                                onClick={createLinkToNote}
                                tooltip={t('Create link.')}
                            />
                        </div>
                    </div>
                </div>
                {note.visibleItems(canViewConsultantOnlyFeatures).map((item) => (
                    <NoteItem key={item._id} {...{ noteRoot, item, closeNoteDialog, setEditing, allowEditing }} />
                ))}
                <div className="note-item">
                    <div className="note-item-main">
                        <NoteAddItemButtons
                            iAmInterpreter={iAmInterpreter}
                            note={note}
                            addText={this.createNoteItem.bind(this)}
                            addVideo={this.addVideo}
                            addAudioRecording={this.addAudioRecording.bind(this)}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

/* eslint-disable react/jsx-filename-extension */
import './i18n'

import { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { HashRouter } from 'react-router-dom'
import './index.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/css/bootstrap-theme.css'
import { createBrowserHistory } from 'history'

import { OnlineStatusProvider } from './components/app/OnlineStatusContext'
import ServiceWorkerWrapper from './components/app/ServiceWorkerWrapper'
import SLToolWithCognito from './components/app/SLToolWithCognito'
import { isDev } from './components/app/slttAvtt'

import ErrorBoundary from './components/utils/Errors'

// Convert old-style URLs to new style so things don't break
// https://s3.amazonaws.com/sltt-hosting-tst/index.html -> https://s3.amazonaws.com/sltt-hosting-tst/#/index.html
const history = createBrowserHistory()
const { hash, pathname, search } = window.location
if (!hash) {
    const secondSlashIndex = pathname.slice(1).indexOf('/') + 2
    const pathnameWithHash = pathname.slice(0, secondSlashIndex).concat('#/').concat(pathname.slice(secondSlashIndex))
    const withHash = pathnameWithHash.concat(search)
    history.replace(withHash)
}

export function redirectToHome() {
    const secondSlashIndex = pathname.slice(1).indexOf('/') + 2
    const pathnameWithHash = pathname.slice(0, secondSlashIndex).concat('#/index.html')
    history.replace(pathnameWithHash)
}

ReactDOM.render(
    <div className={`${isDev ? 'is-dev' : ''} app-container`}>
        <Suspense fallback="loading">
            <OnlineStatusProvider>
                <ServiceWorkerWrapper />
                <ErrorBoundary>
                    <HashRouter>
                        <SLToolWithCognito />
                    </HashRouter>
                </ErrorBoundary>
            </OnlineStatusProvider>
        </Suspense>
    </div>,
    document.getElementById('root')
)

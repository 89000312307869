/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable import/no-cycle */
/* eslint-disable max-classes-per-file */
import levelup, { LevelUp } from 'levelup'
import leveljs from 'level-js'
import { openDB } from 'idb'
import { t } from 'i18next'

import API from './API'
import { IDB, IDBObject, IDBObjectWithKey } from './IDB'
import { DBChangeNotifier } from './DBChangeNotifier'
import { DBAcceptor } from './DBAcceptor'
import { IDateCreator, SLTTDateCreator } from './DateUtilities'
import { canAccessInternet } from '../components/app/OnlineStatusContext'
import { isUnrecoverableHttpError, systemError } from '../components/utils/Errors'
import { MAX_RICHTEXT_SIZE } from '../components/utils/RichTextEditor'
import { isProjectRestoreInProgress } from '../components/utils/Helpers'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:Levelup')
// eslint-disable-next-line @typescript-eslint/no-var-requires
const dbg = require('debug')('slttdbg:Levelup')

function logDocs(title: string, docs: any[]) {
    if (docs.length === 0) return
    log(`sync docs ${title} [${docs[0].seq}..${docs.slice(-1)[0].seq}]`)
}

const intest = localStorage.getItem('intest') === 'true'

// Create global variable on window so that test can verify puts
const _window = window as any
let putDocs: any[] = []
_window._putDocs_ = putDocs

// Starting key for docs not yet synced to central DB
const MIN_LOCAL_SEQ = 1000000000
const MAX_LOCAL_SEQ = 9999999999

// Express and Lambda can handle payloads up to 256kb
const MAX_TO_SYNC_SIZE = 250000

// Lambda has a max payload of 6 MB. So our backend is set to send no more than 5 pages of a DynamoDb query.
// Each page will be minimum of 400k and maximum of 1 MB. So we should retry sync when this is exceeded.
const MIN_SYNC_RETRY_SIZE = 5 * 400 * 1024

// Our backend sync can handle about 150 new docs before 30 sec timeout (504)
const MAX_TO_SYNC_DOCS = 100

function delay(duration: number) {
    return new Promise<void>(function (resolve) {
        setTimeout(() => resolve(), duration)
    })
}

function isLocalKey(key: number) {
    return key >= MIN_LOCAL_SEQ
}

export const UNSYNCED_DATA_EXISTS_TAG = '-unsynced-data-exists'
const LAST_SUCCESSFUL_SYNC_TAG = '-last-successful-sync'

const recordUnsyncedChanges = (projectName: string) => {
    localStorage.setItem(`${projectName}${UNSYNCED_DATA_EXISTS_TAG}`, 'true')
}

const recordNoUnsyncedChanges = (projectName: string) => {
    localStorage.removeItem(`${projectName}${UNSYNCED_DATA_EXISTS_TAG}`)
}

const recordLastSuccessfulSync = (projectName: string) => {
    localStorage.setItem(`${projectName}${LAST_SUCCESSFUL_SYNC_TAG}`, Date.now().toString())
}

export const getLastSuccessfulSyncTimeMs = (projectName: string) => {
    return Number(localStorage.getItem(`${projectName}${LAST_SUCCESSFUL_SYNC_TAG}`))
}

// This is what we store in level-js-PROJECT-db
export type DBEntry = {
    key: number // see discussion of local and global keys below
    doc: any // IDBobject to be passed to DBAcceptor
}

interface IAccept {
    label?: string
    seq?: number
}

interface IChange {
    doc: any
    timer: NodeJS.Timeout | null
}

const DEBOUNCE_TIME_MS = 1000

// Batch all db changes that update the same field(s) of the same object and debounce them.
class DBChangeDeferrer {
    private changes: IChange[] = []

    constructor(private db: _LevelupDB) {
        this.submitChange = this.submitChange.bind(this)
        this.commitChange = this.commitChange.bind(this)
    }

    submitChange(doc: any) {
        // Two changes are equivalent if they have the same _id and all the field
        // names are the same
        function sameItem(doc2: any) {
            if (doc._id !== doc2._id) return false

            // A request to remove should we replace any other request.
            // Otherwise the item flashes back into existence when the original commit happens
            // and then re-disappears when the removal commit happens.
            if (doc.removed) return true

            const existingDocKeys = Object.keys(doc2).sort()
            const docKeys = Object.keys(doc).sort()
            return JSON.stringify(existingDocKeys) === JSON.stringify(docKeys)
        }

        const existingIndex = this.changes.findIndex((item) => sameItem(item.doc))

        if (existingIndex > -1) {
            log('submitChange replacement', JSON.stringify(doc))
            const existing = this.changes[existingIndex]
            if (existing.timer) {
                clearTimeout(existing.timer)
            }
            existing.timer = setTimeout(() => this.commitChange(doc), DEBOUNCE_TIME_MS)
            existing.doc = doc
        } else {
            log('submitChange', JSON.stringify(doc))
            const timer = setTimeout(() => this.commitChange(doc), DEBOUNCE_TIME_MS)
            this.changes.push({ doc, timer })
        }
    }

    private async commitChange(doc: any) {
        log('commitChange', JSON.stringify(doc))
        await this.db.put(doc)

        const index = this.changes.findIndex((c) => c.doc === doc)
        if (index > -1) {
            this.changes.splice(index, 1)
        } else {
            log('### commitChange doc not in changes!')
        }
    }
}

export class _LevelupDB implements IDB {
    public db: LevelUp<leveljs>

    acceptor?: DBAcceptor

    changeDeferrer: DBChangeDeferrer

    notifier?: DBChangeNotifier

    // All documents in the LevelUp DB have a numeric keys.
    // Key values 0..MIN_LOCAL_SEQ represent documents that exist in DynamoDB.
    //
    // Key values localSeqBase+1..localSeqLast represent documents that have been created
    // locally but not yet synced to DynamoDB.
    // These documents are deleted once they have been synced to DynamoDB.
    // Sync will return to us from DynamoDB a permanent copy of each of these documents
    // to store locally [provided conflict resolution does not cause a specific
    // update to be ignored]

    // Largest local key present in indexedDB.
    // MIN_LOCAL_SEQ if none present.
    // Local keys start at MIN_LOCAL_SEQ+1.
    // WARNING: This value may be updated in the middle of a sync operation.
    localSeqLast = MIN_LOCAL_SEQ

    // Largest local key that has been sent to sync API.
    // When localSeqLast > localSeqBase there are local docs waiting to be synced to DynamoDB.
    localSeqBase = MIN_LOCAL_SEQ

    remoteSeq = -1

    updaterId = 0

    numPendingSyncs = 0

    constructor(public name: string, public username: string) {
        log(`constructor ${name}`)
        this.db = levelup(leveljs(`${name}-db`))
        this.name = name
        this.changeDeferrer = new DBChangeDeferrer(this)
        this.doSync = this.doSync.bind(this)
    }

    getRemoteSeq() {
        return this.remoteSeq
    }

    async initialize(acceptor: DBAcceptor, progress: (message: string) => void): Promise<number> {
        this.acceptor = acceptor
        const dbRecordCount = await this.acceptLocalDBRecords(progress)

        await this.doSync(progress)
        if (!intest) {
            const onChange = async (maxseq: number) => {
                if (maxseq > this.remoteSeq) {
                    await this.doSync()
                }
            }
            this.notifier = new DBChangeNotifier(this.name, onChange)
            this.notifier.requestNotifications()
        }

        return dbRecordCount
    }

    // Called once on initialize to accept all records in DB.
    // Side effect: sets localSeqBase, localSeqlast, remoteSeq
    private async acceptLocalDBRecords(progress: (message: string) => void): Promise<number> {
        const notifyLocalProgress = (percent: number) => {
            progress(`${t('Initializing...')} ${percent.toFixed(1)}%`)
        }
        const percent = 0
        notifyLocalProgress(percent)
        const entries = await this.readDBRecords()

        let isFirstLocalKey = true
        for (let i = 0; i < entries.length; ++i) {
            const entry = entries[i]
            if (entry.key <= 0 || !entry.doc || !entry.doc._id) {
                log('###BAD ENTRY', entry)
            }

            const { key } = entry

            if (isLocalKey(key)) {
                if (isFirstLocalKey) {
                    this.localSeqBase = key - 1
                    isFirstLocalKey = false
                }
                this.localSeqLast = key
            } else {
                this.remoteSeq = key
            }

            if (i % 500 === 0) {
                await delay(1) // let ui redraw to show progress
                notifyLocalProgress((100 * i) / entries.length)
            }

            this.accept(entry.doc, { seq: key })
        }

        return entries.length
    }

    // This is remarkably faster than get() when reading all records.
    async readDBRecords(): Promise<DBEntry[]> {
        return _LevelupDB.readDBRecords(this.name)
    }

    static async readDBRecords(name: string): Promise<DBEntry[]> {
        const dbname = `level-js-${name}-db`
        const storeName = `${name}-db`

        const idb = await openDB(dbname, 1)

        const keys = await idb.getAllKeys(storeName)
        const values = await idb.getAll(storeName)

        const entries: DBEntry[] = []

        for (let i = 0; i < keys.length; ++i) {
            const key = keys[i] as number
            const doc = JSON.parse(values[i])
            entries.push({ key, doc })
        }

        idb.close()

        return entries
    }

    accept(doc: any, arg?: IAccept) {
        const label = arg?.label ?? ''

        if (label) {
            dbg(`accept[${label}]`, doc._id)
        }
        const seq: number = arg?.seq ?? -1

        try {
            this.acceptor?.accept(doc, label, seq)
        } catch (error) {
            //! !! send .errors to remote log
            console.error('_LevelupDB accept', error)
        }
    }

    // Synchronize items in local DB with central DB
    async doSync(progress?: (message: string) => void) {
        // Since canAccessInternet() can be expensive if excessively used, just check navigator.onLine here.
        // This can give a false positive, but network error is handled below
        if (!navigator.onLine || !API.loggedIn()) {
            return
        }

        // If we run multiple simultaneous sync we will get duplicate db records.
        // Just wait until we are done with this sync.
        if (this.numPendingSyncs > 0) {
            return
        }

        this.numPendingSyncs++
        await this.sync(progress)
        this.numPendingSyncs--
    }

    private async sync(progress?: (message: string) => void) {
        const notifyRemoteProgress = () => {
            // Since we can't tell the user how many objects we are syncing, I don't think
            // counting helps. Just show them the spinning icon.
            progress?.(t('Syncing'))
        }
        notifyRemoteProgress()

        // Loop until all local items sent to remote and all remote items fetched
        let maxDocs = MAX_TO_SYNC_DOCS // try as many as our backend can handle, unless an error is encountered
        while (true) {
            try {
                // Make local copies because other processes can changes these while sync is underway
                const { localSeqBase, localSeqLast } = this

                // Send the local docs to DynamoDB via sync.
                try {
                    const newItems = await this.syncDocs(localSeqBase, localSeqLast, maxDocs)

                    await this.deleteLocalDocs(localSeqBase, localSeqLast, maxDocs)

                    // Record the fact that we have successfully sent local records to sync api
                    // Can't use this.localSeqLast because new docs may have come
                    // in since we synced.
                    this.localSeqBase = Math.min(maxDocs + localSeqBase, localSeqLast)
                    await this.acceptRemoteItems(newItems)

                    notifyRemoteProgress()

                    // If a put happened while we are in the middle of doing our sync, then
                    // it will increment this.localSeqLast. We can use this to determine if
                    // there are new local items that still need synced to the remote.
                    if (this.localSeqLast > this.localSeqBase && !intest) {
                        continue /* repeat sync loop */
                    }

                    // If we receive a lot of data in one request, the service we requested data from may have
                    // limited how much data we received and there may be more we have not seen yet.
                    if (newItems.length && !intest) {
                        const payloadSize = JSON.stringify(newItems).length
                        if (payloadSize >= MIN_SYNC_RETRY_SIZE) {
                            log(
                                `Received payload of ${payloadSize} bytes, which is near max Lambda/DynamoDb payload. So try to get more...`
                            )
                            continue /* repeat sync loop */
                        }
                    }

                    localStorage.setItem('lastSuccessfulSync', Date.now().toString())
                    recordNoUnsyncedChanges(this.name)
                    recordLastSuccessfulSync(this.name)
                    break // nothing left to sync
                } catch (err) {
                    if (!(await canAccessInternet('sync error'))) {
                        // TODO: This handles the case when internet connection is lost during sync.
                        // This should cause the sync to stop, and then resume when connected again.
                        break
                    }

                    const error = err as Error
                    if (isUnrecoverableHttpError(error.message)) {
                        // Since we cannot recover from this error, cause the offending doc to be skipped
                        if (maxDocs === MAX_TO_SYNC_DOCS) {
                            log('Unrecoverable error encountered, so try again one doc at a time', error)
                            maxDocs = 1
                        } else {
                            log('Unrecoverable error encountered, so delete the local doc and resume', error)
                            await this.deleteLocalDocs(localSeqBase, localSeqLast, maxDocs)
                            maxDocs = MAX_TO_SYNC_DOCS
                        }
                    } else {
                        // Recoverable error encountered (e.g. fetch took too long), so try to sync again
                        throw error
                    }
                }
            } catch (error) {
                systemError(error, false) // log error but do not nag user
            }
        }
    }

    unsyncedChangesExist = async () => {
        const { localSeqBase, localSeqLast } = this
        const docs = await this.get(localSeqBase, localSeqLast)
        return docs.length > 0
    }

    private async deleteLocalDocs(localSeqBase: number, localSeqLast: number, maxDocs: number) {
        if (isProjectRestoreInProgress()) {
            return
        }
        let deletedCount = 0
        for (let i = localSeqBase + 1; i <= localSeqLast && deletedCount < maxDocs; ++i) {
            dbg(`deleteLocalDocs[${i}]`)
            await this.db.del(i)
            deletedCount++
        }
    }

    private async acceptRemoteItems(items: any[]) {
        if (items.length === 0 || isProjectRestoreInProgress()) return
        const lastSeq = items.slice(-1)[0].seq
        log(`acceptRemoteItems[${items.length}] lastSeq=${lastSeq}`)

        if (intest) {
            putDocs = putDocs.concat(items)
            items.forEach((item) => this.accept(item.doc))
            return
        }

        // Writing all the new items to IndexDB in a batch operation is much
        // faster than writing them individually.
        const ops: any[] = items.map((item) => ({
            type: 'put',
            key: item.seq,
            value: JSON.stringify(item.doc)
        }))
        await this.db.batch(ops)
        this.remoteSeq = lastSeq

        items.forEach((item) => this.accept(item.doc, { seq: item.seq }))
    }

    /**
     * It should not be possible to create a big doc, but is so truncate it so
     * that it does not crash the sync process.
     */
    limitDocSize(doc: any) {
        const safeFieldsToReset = ['src', 'text']
        safeFieldsToReset.forEach((fieldName) => {
            if (doc[fieldName] && doc[fieldName].length > MAX_RICHTEXT_SIZE) {
                doc[fieldName] = t('richTextTooLargeError')
                doc.error = 'richTextTooLargeError'
            }
        })

        return JSON.stringify(doc).length
    }

    getDocsSlice(docs: any[]) {
        let i = 0
        let totalLength = 0
        for (; i < docs.length && totalLength < MAX_TO_SYNC_SIZE; ++i) {
            totalLength += this.limitDocSize(docs[i])
        }

        return docs.slice(0, i)
    }

    // Sync docs with remote server.
    // Do it in modest size chunks so as to not cause a timeout error.
    async syncDocs(localSeqBase: number, localSeqLast: number, maxDocs: number) {
        const docs = (await this.get(localSeqBase, localSeqLast, maxDocs)).map((doc) => doc.doc)
        let newItems: any[] = []

        while (true) {
            const _docs = this.getDocsSlice(docs)
            // log('syncDocs', _docs.length, docs.length)

            // Send the local docs to DynamoDB via sync.
            // newItems are all the items from the remote db with ids
            // than the last remote item successfully accepted.
            logDocs('to backend', _docs)
            const _newItems = await API.sync(this.name, _docs, this.remoteSeq)
            newItems = newItems.concat(_newItems)

            docs.splice(0, _docs.length)
            if (docs.length === 0) break
        }

        return newItems
    }

    // Get documents from local db
    get(seqStart?: number, seqEnd?: number, maxDocs?: number, reverse = false): Promise<IDBObjectWithKey[]> {
        if (!seqStart) seqStart = 0
        if (!seqEnd) seqEnd = MAX_LOCAL_SEQ

        const decoder = new TextDecoder('utf-8')
        const docs: IDBObjectWithKey[] = []

        return new Promise((resolve, reject) => {
            if (seqStart === seqEnd) resolve(docs) // nothing to get

            const limits = { gt: seqStart, lte: seqEnd, limit: maxDocs, reverse }
            this.db
                .createReadStream(limits)
                .on('data', function (data) {
                    try {
                        const key = decoder.decode(data.key)
                        const json = decoder.decode(data.value)
                        const doc = JSON.parse(json)
                        docs.push({ key: Number(key), doc })
                    } catch (error) {
                        console.error('_LevelupDB get', error)
                    }
                })
                .on('error', function (err) {
                    console.error('_LevelupDB get', err)
                    reject(err)
                })
                .on('end', function () {
                    if (docs.length) {
                        log(`get [${seqStart}-${seqEnd}] docs=${docs.length}`)
                    }
                    resolve(docs)
                })
        })
    }

    async put(doc: IDBObject) {
        // If testing in progress, remember doc but don't update server database
        if (intest || isProjectRestoreInProgress()) {
            putDocs.push(doc)
            this.accept(doc)
            return
        }

        recordUnsyncedChanges(this.name)

        this.localSeqLast += 1
        await this.db.put(this.localSeqLast, JSON.stringify(doc))
        dbg(`put ${this.localSeqLast}`, JSON.stringify(doc, null, 4))

        const seq = this.localSeqLast
        this.accept(doc, { label: `put ${seq}`, seq })

        this.doSync().catch(systemError)
    }

    submitChange(doc: IDBObject) {
        if (intest) {
            putDocs.push(doc)
            this.accept(doc)
            return
        }

        const seq = this.localSeqLast + 1
        this.accept(doc, { label: `put ${seq}`, seq })
        this.changeDeferrer.submitChange(doc)
    }

    static lastId = ''

    static dateToId(date: Date, tag = '') {
        // When running tests use a constant time stamp
        if (intest) {
            return `${tag}190101_020304`
        }

        let _id = date.toISOString().slice(2, -5)
        _id = _id.replace('T', '_')
        _id = _id.replace(/-/g, '')
        _id = _id.replace(/:/g, '')

        const { lastId } = _LevelupDB
        if (lastId >= _id) {
            const parts = lastId.split('_')
            const newTime = (parseInt(parts[1]) + 1).toString()
            _id = `${parts[0]}_${newTime.padStart(6, '0')}`
        }

        _LevelupDB.lastId = _id

        return tag + _id
    }

    getNewId(existing: any[], date: Date, tag = '') {
        const newId = _LevelupDB.dateToId(date, tag)

        // If we accidentally insert a bogus id into the list of existing ids
        // it can corrupt all future ids if it the alphabetic largetst.
        // Ignore invalid ids when creating new ids.
        function isValidId(id: string) {
            if (!id) return false
            const _parts = id.split('_')
            return _parts.length === 2 && !isNaN(parseInt(_parts[1], 10))
        }
        existing = existing.filter((e) => isValidId(e._id))

        if (existing.length === 0) {
            return newId
        }

        existing = existing.map((e) => e._id.split('/').slice(-1)[0])
        const maxId = existing.reduce((max: string, next: string) => (next > max ? next : max))

        if (newId > maxId) {
            return newId
        }

        const parts = maxId.split('_')
        const new2id = `${parts[0]}_${(parseInt(parts[1], 10) + 1).toString().padStart(6, '0')}`

        return new2id
    }

    // async get(startKey = '', endKey = '') {
    //     if (startKey && !endKey) {
    //         endKey = startKey   // only get exact matches
    //     }

    //     let options = {
    //         startkey: startKey,
    //         endkey: endKey,
    //         include_docs: true,
    //         update_seq: true,
    //     }

    //     let response = await this.db.allDocs(options)
    //     this.update_seq = (response as any).update_seq
    //     // response = {rows: [{doc: {...} }]}
    //     return response.rows.map((row: any) => row.doc)
    // }

    async delete(doc: any) {
        doc.removed = true
        await this.put(doc)
    }

    // async getOne(_id: string) {
    //     return this.db.get(_id)
    // }

    getDate(date?: Date) {
        return _LevelupDB.getDate(date)
    }

    // Returns a UMT date with format 2020/10/03 19:01:14.093Z
    // WARNING: we use this date to determine in the back end what change is the latest.
    // Changing the format of this will likely cause data loss due to items being judged outdated
    // and being discarded.
    static getDate(date?: Date) {
        const dateCreator = _LevelupDB.getDateCreator()
        return dateCreator.getDate(date)
    }

    static getDateCreator(): IDateCreator {
        return new SLTTDateCreator()
    }

    cancel() {}

    slice() {
        throw Error('Only supported in _MemoryDB for unit testing')
        return []
    }

    reset() {
        throw Error('Only supported in _MemoryDB for unit testing')
    }

    async deleteDB(): Promise<void> {
        if (isProjectRestoreInProgress()) {
            return
        }
        await this.db.close()
        return new Promise((resolve, reject) => {
            const deleteRequest = indexedDB.deleteDatabase(`level-js-${this.name}-db`)

            deleteRequest.onerror = () => {
                reject('Error deleting db')
            }

            deleteRequest.onsuccess = () => {
                resolve()
            }

            deleteRequest.onblocked = () => {
                reject('Delete db request blocked')
            }
        })
    }

    async rebuild(items: DBEntry[], allow = false) {
        if (!allow && isProjectRestoreInProgress()) {
            return
        }

        const currentDocs = await this.readDBRecords()

        // Delete docs database so that we can completely rebuild the database
        const ops: any[] = [
            ...currentDocs.map((item) => ({
                type: 'del',
                key: item.key
            })),
            ...items.map((item) => ({
                type: 'put',
                key: item.key,
                value: JSON.stringify(item.doc)
            }))
        ]
        await this.db.batch(ops)
    }
}

// Show a horizontal timeline with a vertical bar indicating current position in video.
// Allow user to set video position by clicking or dragging.

import React, { Component } from 'react'
import { observer } from 'mobx-react'

import { IVideoPosition } from './VideoPositionBar'

// make adjustment display optional

interface IThinVideoPositionBar {
    videoPosition: IVideoPosition
}

class ThinVideoPositionBar extends Component<IThinVideoPositionBar> {
    private width = 640

    private height = 10

    private canvas: any

    componentDidMount() {
        this.updateCanvas()
    }

    componentDidUpdate() {
        this.updateCanvas()
    }

    setCurrentTime(x: number) {
        const { videoPosition } = this.props

        const { duration, resetCurrentTime, passage } = videoPosition
        const { width } = this

        if (!passage?.videoBeingCompressed) {
            const ct = (x * duration) / width
            // console.log('setCurrentTime', x, rt.duration, w, ct)
            resetCurrentTime(ct)
        }
    }

    displayXToModelX(e: React.MouseEvent) {
        const boundingRect = e.currentTarget.getBoundingClientRect()
        const x = e.clientX - boundingRect.left
        return x * (this.canvas.width / this.canvas.offsetWidth)
    }

    // If I call this "mousedown" it never gets called! why?
    _mousedown(e: any) {
        const { videoPosition } = this.props
        if (!videoPosition.passage?.videoBeingCompressed) {
            const x = this.displayXToModelX(e)
            console.log('mousedown', x)
            this.setCurrentTime(x)
        }
    }

    updateCanvas() {
        const { canvas, width, height } = this
        if (!canvas) return

        const { videoPosition } = this.props
        const { currentTime, duration, passage, passageVideo, timeline } = videoPosition
        if (!passage || !passageVideo) return

        const m = height / 2

        const ctx = canvas.getContext('2d')
        ctx.clearRect(0, 0, width, height)

        ctx.lineWidth = 0.5
        ctx.strokeStyle = 'black'
        ctx.beginPath()
        ctx.moveTo(0, m)
        ctx.lineTo(width, m)
        ctx.stroke()

        let startPosition = (timeline.start / duration) * width
        startPosition = Math.max(5, startPosition)

        ctx.lineWidth = 2
        ctx.strokeStyle = 'purple'
        ctx.beginPath()
        ctx.moveTo(startPosition - 15, m - 15)
        ctx.lineTo(startPosition, m)
        // ctx.lineTo(startPosition - 5, m + 5)
        ctx.stroke()

        const endPosition = (timeline.end / duration) * width

        ctx.lineWidth = 2
        ctx.strokeStyle = 'purple'
        ctx.beginPath()
        ctx.moveTo(endPosition + 15, m - 15)
        ctx.lineTo(endPosition, m)
        // ctx.lineTo(endPosition + 5, m + 5)
        ctx.stroke()

        // Draw current position cursor
        let position = (currentTime / duration) * width
        if (position < 1) position = 1 // if we draw cursor at origin we can only see half of it

        ctx.lineWidth = 2
        ctx.strokeStyle = 'black'
        ctx.beginPath()
        ctx.moveTo(position, m - 5)
        ctx.lineTo(position, m + 5)
        ctx.stroke()
    }

    render() {
        const { videoPosition } = this.props
        const { width, height } = this
        // Necessary to force re-render when current time changes to show video position cursor
        // eslint-disable-next-line
        let { currentTime, passage, passageVideo, timeline } = videoPosition

        // Don't need to show the thin timeline if we are not zoomed
        if (timeline.zoomLevel <= 1) return null

        // eslint-disable-next-line
        passageVideo && passageVideo._rev // for render on any passage video changes
        // eslint-disable-next-line
        passage && passage._rev // for render on any passage changes

        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        passage?.videoBeingCompressed
        // log('*** render', passageVideo && passageVideo._rev)

        // let pvs = (passageVideo && passageVideo.segments) || []
        // log('positions', pvs.map(pv => pv.position))

        // Check to see if segment time have changed and if so reset them
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        passage && passageVideo && passageVideo.setSegmentTimes(passage)

        return (
            <div data-id="thin-video-positionbar">
                <canvas
                    className="thin-video-positionbar"
                    width={width}
                    height={height}
                    ref={(c) => {
                        this.canvas = c
                    }}
                    onMouseDown={this._mousedown.bind(this)}
                />
            </div>
        )
    }
}

export default observer(ThinVideoPositionBar)

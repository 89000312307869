import { observer } from 'mobx-react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { OKButton, CancelButton } from './Buttons'

interface TextEditorProps {
    savedText: string
    onClose: () => void
    save: (value: string) => void
}

const TextEditor = observer(({ savedText, onClose, save }: TextEditorProps) => {
    const { t } = useTranslation()
    const [value, setValue] = useState(savedText)

    return (
        <>
            <textarea
                autoFocus
                placeholder={t('Type Esc to cancel.')}
                onKeyDown={(e) => {
                    e.stopPropagation()

                    if (e.key === 'Escape') {
                        onClose()
                    }
                }}
                value={value}
                onChange={(e) => setValue(e.target.value)}
                className="segment-text-editor__editor"
                dir="auto"
            />
            <OKButton
                enabled
                onClick={() => {
                    save(value)
                    onClose()
                }}
                buttonClassName=""
                className="right-pane-button ok-icon"
                tooltip={t('Save')}
            />
            <CancelButton enabled onClick={() => onClose()} className="right-pane-button" tooltip={t('Cancel')} />
        </>
    )
})

type SegmentTextEditorProps = {
    savedText: string
    onClose: () => void
    save: (text: string) => void
    editing: boolean
    notEditingControls: React.ReactFragment
}

export const SegmentTextEditor = observer(
    ({ savedText, onClose, save, editing, notEditingControls }: SegmentTextEditorProps) => {
        return (
            <div className="segment-text-editor">
                {editing && <TextEditor savedText={savedText} onClose={onClose} save={save} />}
                {!editing && <textarea value={savedText} className="segment-text-editor__editor" readOnly dir="auto" />}
                {!editing && notEditingControls}
            </div>
        )
    }
)

/* eslint-disable react/no-array-index-key */

import { FC } from 'react'
import { Dropdown, MenuItem } from 'react-bootstrap'

import './Note.css'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { CircleNoteMarker, SlttHelp, SquareNoteMarker, TriangleNoteMarker } from '../utils/Buttons'
import { PassageNote } from '../../models3/PassageNote'
import { Project } from '../../models3/Project'

interface INoteMarkerDropdown {
    options: JSX.Element[]
    currentOption: JSX.Element
    onSelect: (option: number) => void
}

const NoteMarkerDropdown = (props: INoteMarkerDropdown) => {
    const { t } = useTranslation()
    const { options, currentOption, onSelect } = props

    const tooltip = t('Set shape and color of note marker.')
    const id = 'notes.html#notecolor'

    return (
        <div className="note-marker-dropdown" data-tip data-for={id} data-place="right">
            <SlttHelp id={id} tooltip={tooltip} place="right">
                <Dropdown id="note-marker-dropdown">
                    <Dropdown.Toggle className="note-marker-dropdown-item">{currentOption}</Dropdown.Toggle>
                    <Dropdown.Menu>
                        {options.map((type, i) => {
                            return (
                                <MenuItem
                                    key={i}
                                    className="note-marker-dropdown-item"
                                    onClick={() => onSelect(i)}
                                    value={i}
                                >
                                    {type}
                                </MenuItem>
                            )
                        })}
                    </Dropdown.Menu>
                </Dropdown>
            </SlttHelp>
        </div>
    )
}

interface INoteMarkerControl {
    note: PassageNote
    project: Project
    allowEditing: boolean
    selectNoteType: (note: PassageNote, type: string) => void
}

export const NoteMarkerControl: FC<INoteMarkerControl> = observer(({ note, project, allowEditing, selectNoteType }) => {
    const { type } = note
    const { noteColors } = project

    const selectType = (passageNote: PassageNote, option: number) => {
        selectNoteType(passageNote, option.toString())
    }

    const markerShapes: JSX.Element[] = [
        <CircleNoteMarker className="note-bar-marker" enabled />,
        <SquareNoteMarker className="note-bar-marker" enabled />,
        <TriangleNoteMarker className="note-bar-marker" enabled />
    ]

    const noteMarkers: JSX.Element[] = markerShapes.flatMap((shape) =>
        noteColors.map((color) => <span style={{ color }}>{shape}</span>)
    )

    const noteMarkersMap: { [type: string]: JSX.Element } = {
        '0': noteMarkers[0],
        '1': noteMarkers[1],
        '2': noteMarkers[2],
        '3': noteMarkers[3],
        '4': noteMarkers[4],
        '5': noteMarkers[5],
        '6': noteMarkers[6],
        '7': noteMarkers[7],
        '8': noteMarkers[8]
    }

    if (allowEditing) {
        return (
            <NoteMarkerDropdown
                options={noteMarkers}
                currentOption={noteMarkersMap[type]}
                onSelect={(option) => selectType(note, option)}
            />
        )
    }

    return <span className="note-marker-placeholder">{noteMarkersMap[type]}</span>
})

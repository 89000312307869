/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { FC, useRef, useState, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Modal from 'react-bootstrap/lib/Modal'
import {
    Slider,
    SliderItem,
    GetHandleProps,
    Handles,
    GetTrackProps,
    Tracks,
    SliderModeValue
} from 'react-compound-slider'
import { observer } from 'mobx-react'

import VideoPositionViewer from './VideoPositionViewer'

import { Passage } from '../../models3/Passage'
import { MIN_SEGMENT_LENGTH, PassageSegment } from '../../models3/PassageSegment'
import { PassageVideo } from '../../models3/PassageVideo'
import { createVisiblePassage } from '../../models3/VisiblePassageVideo'
import {
    OkEditSegmentButton,
    CancelEditSegmentButton,
    PlayButton,
    PaneCloseButton,
    SegmentPositionAdjustTimeButtons
} from '../utils/Buttons'
import { displayError } from '../utils/Errors'
import RangeVideoPlayer from '../video/RangeVideoPlayer'
import { RootContext } from '../app/RootContext'
import { EditingSegmentPosition } from '../translation/TranslationRightPane'
import { fmt } from '../utils/Fmt'
import { isAVTT } from '../app/slttAvtt'
import { AudioWaveformCreator, WaveformVisualizer } from '../video/WaveformVisualizer'
import { SimpleWaveformVisualizer } from '../enhancedResources/ProjectReferenceVideoPlayer'
import { PositionSetter, VideoTimeline } from '../video/VideoTimeline'
import VideoTimelinePlayButtons from '../video/VideoTimelinePlayButtons'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:SegmentPositionDialog')

function fx(position: number) {
    return position.toFixed(2)
}

interface ITrack {
    source: SliderItem
    target: SliderItem
    getTrackProps: GetTrackProps
}

const Track: FC<ITrack> = ({ source, target, getTrackProps }) => {
    const { onMouseDown, onTouchStart } = getTrackProps()
    return (
        <div
            className="slider-track clickable"
            style={{
                left: `${source.percent}%`,
                width: `${target.percent - source.percent}%`
            }}
            onMouseDown={onMouseDown}
            onTouchStart={onTouchStart}
        />
    )
}

/**
 * Track of position and endPosition of segment.
 * Enforce
 *    minPosition <= position <= endPosition-MIN_SEGMENT_LENGTH
 *    endPosition <= maxPosition
 */
class SegmentPositions {
    // min/max positions allowed for this segment
    minPosition = 0

    maxPosition: number

    // current start and end positions of segment
    position: number

    endPosition: number

    constructor(public passage: Passage, public video: PassageVideo, public segmentIndex: number) {
        const segment = video.segments[segmentIndex]

        if (segment.isPatched) {
            const actualSegment = segment.actualSegment(passage)
            this.maxPosition = this.actualVideo.duration
            this.position = actualSegment.position
            this.endPosition = actualSegment.endPosition
        } else {
            if (segmentIndex > 0) {
                this.minPosition = video.segments[segmentIndex - 1].endPosition
            }

            if (segmentIndex < video.segments.length - 1) {
                const nextSegment = video.segments[segmentIndex + 1]
                this.maxPosition = nextSegment.position
            } else {
                this.maxPosition = video.duration
            }

            this.position = segment.position
            this.endPosition = segment.endPosition
        }

        log(
            `constructor ${fx(this.minPosition)} <= ${fx(this.position)}-${fx(this.endPosition)} <= ${fx(
                this.maxPosition
            )}`
        )
        this.checkValidity()
    }

    checkValidity(throwOnError?: boolean) {
        const { minPosition, position, endPosition, maxPosition } = this

        const _valid =
            minPosition >= 0 &&
            position >= minPosition &&
            endPosition >= position + MIN_SEGMENT_LENGTH &&
            maxPosition >= endPosition

        if (!_valid) {
            log('### invalid positions', fmt({ minPosition, position, endPosition, maxPosition }))
            if (throwOnError) {
                throw new Error('Invalid segment positions')
            }
        }
    }

    get actualVideo() {
        const segment = this.video.segments[this.segmentIndex]
        return segment.actualVideo(this.passage)!
    }

    /**
     *
     * @param value: Desired value, will be forced to lie in minPosition..maxPosition
     * @param setStartingPosition: True = set position, false = set endPosition.
     */
    setPosition(value: number, setStartingPosition: boolean) {
        const { minPosition, maxPosition, position, endPosition } = this

        if (setStartingPosition) {
            this.position = Math.min(value, endPosition - MIN_SEGMENT_LENGTH)
        } else {
            // Setting ending position (but not too close to minPosition)
            this.endPosition = Math.max(value, position + MIN_SEGMENT_LENGTH)
        }

        this.position = Math.max(this.position, minPosition)
        this.endPosition = Math.min(this.endPosition, maxPosition)

        const newValue = setStartingPosition ? this.position : this.endPosition
        log(
            'setPosition',
            fmt({ value, newValue, setStartingPosition, minPosition, position, endPosition, maxPosition })
        )

        return newValue
    }

    async save() {
        try {
            log('save')

            this.checkValidity(true)

            const { video, segmentIndex, passage, actualVideo, position, endPosition } = this

            const segment = video.segments[segmentIndex]
            const actualSegment = segment.actualSegment(passage)

            log(
                `save [si=${segmentIndex} ${fx(actualSegment.position)}..${fx(actualSegment.endPosition)}] ${fx(
                    position
                )}..${fx(endPosition)}`
            )

            await actualSegment.setPositions(position, endPosition, actualVideo)
        } catch (error) {
            displayError(error)
        }
    }
}

function logSaveSegment(label: string, passage: Passage, video: PassageVideo, segmentIndex: number) {
    if (segmentIndex < 0 || segmentIndex >= video.segments.length) return
    const segment = video.segments[segmentIndex]
    log(`!!!save ${label} segment positions`, JSON.stringify(segment.dbg(passage), null, 4))
}

interface IPlayPreview {
    positions: SegmentPositions
    prePositions: SegmentPositions | null
    postPositions: SegmentPositions | null
    setPlaying: (playing: boolean) => void
}

export const PlayPreview: FC<IPlayPreview> = ({ positions, prePositions, postPositions, setPlaying }) => {
    const { t } = useTranslation()
    const [isPlaying, setIsPlaying] = useState(false)
    const [currentTime, setCurrentTime] = useState(0)
    const [setters, setSetters] = useState<PositionSetter[]>([])
    const rvp = useRef<RangeVideoPlayer>(null)
    const [visiblePassage, setVisiblePassage] = useState<Passage>(() => {
        const { passage } = positions
        return createVisiblePassage(passage, '9999/12/31')
    })

    // Patched segment
    const video = visiblePassage.videos.find((v) => v._id === positions.video._id)
    const segment = video!.segments[positions.segmentIndex].actualSegment(visiblePassage)
    const segmentDuration = segment.endPosition - segment.position

    const startTime = Math.max(0, segment.time - 2)
    const endTime = Math.min(segment.time + segmentDuration + 2, video!.computedDuration)

    useEffect(() => {
        setCurrentTime(startTime)

        setSetters([
            new PositionSetter(
                '3',
                () => startTime,
                () => endTime,
                (value) => setCurrentTime(value)
            )
        ])
    }, [endTime, startTime])

    useEffect(() => {
        const newPassage = createVisiblePassage(positions.passage, '9999/12/31')
        setVisiblePassage(newPassage)
    }, [positions.passage])

    // Make a copy of the video object using latest patches
    // console.clear()

    const rt = useContext(RootContext)
    if (!rt || !video) {
        return null
    }

    const { currentVideos, playbackRate, setPlaybackRate } = rt
    const className = 'video-patch-preview'

    function pause() {
        if (rvp.current) {
            rvp.current.stop()
        }
    }

    async function playAll() {
        setIsPlaying(true)
        if (!rvp.current) {
            return
        }

        await rvp.current.playRange(currentTime, endTime)
    }

    function onTick(time: number) {
        const newSetters = setters.slice()
        newSetters[0].setValue(time)
        setSetters(newSetters)
    }

    function setSegmentPositions(_positions: SegmentPositions | null) {
        if (_positions === null) return
        log('setSegmentPositions', fx(_positions.position), fx(_positions.endPosition))
        const _segment = video?.segments[_positions.segmentIndex].actualSegment(visiblePassage)
        if (_segment) {
            _segment.position = _positions.position
            _segment.endPosition = _positions.endPosition
        }
    }

    // Use adjusted positions
    setSegmentPositions(positions)
    setSegmentPositions(prePositions)
    setSegmentPositions(postPositions)
    video.setSegmentTimes(visiblePassage, true)

    return (
        <div className="project-reference-video-player-content">
            <div className="note-location-editor-video">
                <RangeVideoPlayer
                    {...{
                        className,
                        passage: visiblePassage,
                        video,
                        playbackRate,
                        setPlaybackRate,
                        currentVideos,
                        startTime,
                        endTime,
                        onPlayingStatus: setIsPlaying,
                        onTick,
                        enableSpeedSlider: true
                    }}
                    ref={rvp}
                />
                <div className="video-timeline-area">
                    <VideoTimelinePlayButtons isPlaying={isPlaying} playAll={playAll} pause={pause} />
                    <div className="video-timeline">
                        {isAVTT && visiblePassage && (
                            <SimpleWaveformVisualizer
                                video={video}
                                passage={visiblePassage}
                                startTime={startTime}
                                endTime={endTime}
                            />
                        )}
                        <VideoTimeline
                            setters={setters}
                            domainStartPosition={startTime}
                            domainEndPosition={endTime}
                            adjustTime={(time) => {
                                const newSetters = setters.slice()
                                newSetters[0].setValue(time)
                                setSetters(newSetters)
                            }}
                            enabled
                            allowAdjustingPositions
                        />
                    </div>
                </div>
            </div>
            <div className="note-location-editor-close">
                <PaneCloseButton
                    onClick={() => setPlaying(false)}
                    enabled
                    tooltip={t('Close pane')}
                    className="segment-position-preview-close"
                />
            </div>
        </div>
    )
}

type SegmentAudioWaveformVisualizerProps = {
    segment: PassageSegment
    passage: Passage
}

const SegmentAudioWaveformVisualizer = observer(({ segment, passage }: SegmentAudioWaveformVisualizerProps) => {
    const [waveformData, setWaveformData] = useState<number[]>([])

    useEffect(() => {
        let mounted = true

        async function getTheData() {
            const samplesOnScreen = 100
            const waveformCreator = new AudioWaveformCreator()
            const slices = await segment.getPlayableSlicesForOnTopSegment(passage)
            const waveform = await waveformCreator.getNormalizedPCMValues(slices, samplesOnScreen)
            if (mounted) {
                setWaveformData(waveform.normalizedPCMValues)
            }
        }
        getTheData()

        return () => {
            mounted = false
        }
    }, [passage, segment])

    return <WaveformVisualizer waveformData={waveformData} className="segment-audio-waveform-visualizer" />
})

interface IHandle {
    handle: SliderItem
    getHandleProps: GetHandleProps
}

const Handle: FC<IHandle> = ({ handle, getHandleProps }) => {
    const { onKeyDown, onMouseDown, onTouchStart } = getHandleProps(handle.id)

    return (
        <div
            className="slider-handle clickable"
            style={{ left: `${handle.percent}%` }}
            onKeyDown={onKeyDown}
            onMouseDown={onMouseDown}
            onTouchStart={onTouchStart}
        />
    )
}
interface ISegmentPositionTimeline {
    domainStart: number
    domainEnd: number
    position: number
    setPosition: (value: number) => number
}

const SegmentPositionTimeline: FC<ISegmentPositionTimeline> = ({ domainStart, domainEnd, position, setPosition }) => {
    function mode(curr: SliderModeValue[], next: SliderModeValue[]) {
        const value = next[0].val
        next[0].val = setPosition(value)
        return next
    }

    const domain = [domainStart, domainEnd]
    return (
        <Slider className="segment-position-timeline" domain={domain} step={0.1} mode={mode} values={[position]}>
            <div className="segment-position-timeline-rail" />
            <Handles>
                {({ handles, getHandleProps }) => (
                    <div>
                        {handles.map((handle) => (
                            <Handle key={handle.id} handle={handle} getHandleProps={getHandleProps} />
                        ))}
                    </div>
                )}
            </Handles>
            <Tracks>
                {({ tracks, getTrackProps }) => (
                    <div>
                        {tracks.map(({ id, source, target }) => (
                            <Track key={id} source={source} target={target} getTrackProps={getTrackProps} />
                        ))}
                    </div>
                )}
            </Tracks>
        </Slider>
    )
}

interface IVideoSegmentPositionEditor {
    className: string
    heading: string
    positions: SegmentPositions
    editStartingPosition: boolean
}

const VideoSegmentPositionEditor: FC<IVideoSegmentPositionEditor> = ({
    className,
    heading,
    positions,
    editStartingPosition
}) => {
    const [position, setPosition] = useState(editStartingPosition ? positions.position : positions.endPosition)

    const { minPosition, maxPosition, passage } = positions

    const _setPosition = (_position: number) => {
        const value = positions.setPosition(_position, editStartingPosition)
        setPosition(value)
        return value
    }

    const { video, segmentIndex } = positions
    let segment = null
    if (segmentIndex < video.segments.length) {
        segment = video.segments[segmentIndex]
    }
    const actualSegment = segment?.actualSegment(positions.passage)

    if (video.isAudioOnly()) {
        return (
            <div className={className}>
                <div className="video-segment-editor-heading">{heading}</div>
                {isAVTT && actualSegment && (
                    <div className="segment-audio-waveform-visualizer-wrapper">
                        <SegmentAudioWaveformVisualizer segment={actualSegment} passage={passage} />
                    </div>
                )}
                <div className="segment-position-timeline-area">
                    <SegmentPositionTimeline
                        domainStart={minPosition}
                        domainEnd={maxPosition}
                        position={position}
                        setPosition={_setPosition}
                    />
                </div>
            </div>
        )
    }

    return (
        <div className={className}>
            <div className="video-segment-editor-heading">{heading}</div>
            <div className="">
                <VideoPositionViewer position={position} video={positions.actualVideo!} />
            </div>
            <div className="segment-dialog-adjustment-buttons">
                <SegmentPositionAdjustTimeButtons
                    previousSecondEnabled={position - 1.0 >= minPosition}
                    previousFrameEnabled={position - 2.0 / 30.0 >= minPosition}
                    nextFrameEnabled={position + 2.0 / 30.0 <= maxPosition}
                    nextSecondEnabled={position + 1.0 <= maxPosition}
                    adjustCurrentTime={(delta: number) => {
                        log('!!!', fx(position), fx(delta))
                        _setPosition(position + delta)
                    }}
                />
            </div>
            {isAVTT && actualSegment && (
                <div className="segment-audio-waveform-visualizer-wrapper">
                    <SegmentAudioWaveformVisualizer segment={actualSegment} passage={passage} />
                </div>
            )}
            <div className="segment-position-timeline-area">
                <SegmentPositionTimeline
                    domainStart={minPosition}
                    domainEnd={maxPosition}
                    position={position}
                    setPosition={_setPosition}
                />
            </div>
        </div>
    )
}
interface IPositionDialogBody {
    positions: SegmentPositions
    editStartingPosition: boolean
}

const PositionDialogBody: FC<IPositionDialogBody> = ({ positions, editStartingPosition }) => {
    return (
        <div className="patch-dialog-body">
            <VideoSegmentPositionEditor
                className="video-segment-editor2"
                heading=""
                {...{ positions }}
                editStartingPosition={editStartingPosition}
            />
        </div>
    )
}

interface IAdjustingBothDialogBody {
    positions: SegmentPositions
    prePositions: SegmentPositions | null
    postPositions: SegmentPositions | null
}

export const AdjustingBothDialogBody: FC<IAdjustingBothDialogBody> = ({ positions, prePositions, postPositions }) => {
    const { t } = useTranslation()
    const [playing, setPlaying] = useState(false)

    return (
        <div>
            <div>
                {!playing && (
                    <PlayButton
                        className="video-play-patch-preview"
                        enabled={!playing}
                        selectionPresent={false}
                        onClick={() => {
                            setPlaying(true)
                        }}
                        tooltip={t('Preview adjustments.')}
                    />
                )}
                {playing && <PlayPreview {...{ positions, prePositions, postPositions, setPlaying }} />}
            </div>
            <div className="patch-dialog-body">
                {prePositions && (
                    <VideoSegmentPositionEditor
                        className="video-segment-editor1"
                        heading={t('Segment Before')}
                        positions={prePositions}
                        editStartingPosition={false}
                    />
                )}
                <VideoSegmentPositionEditor
                    className="video-segment-editor2"
                    heading={t('Current Segment Start')}
                    {...{ positions }}
                    editStartingPosition
                />
                <VideoSegmentPositionEditor
                    className="video-segment-editor3"
                    heading={t('Current Segment End')}
                    {...{ positions }}
                    editStartingPosition={false}
                />
                {postPositions && (
                    <VideoSegmentPositionEditor
                        className="video-segment-editor1"
                        heading={t('Segment After')}
                        positions={postPositions!}
                        editStartingPosition
                    />
                )}
            </div>
        </div>
    )
}
interface ISegmentPositionDialog {
    passage: Passage
    video: PassageVideo
    segmentIndex: number
    editingSegmentPosition: EditingSegmentPosition
    close: (canceled: boolean, time: number, duration: number) => void
}

export const SegmentPositionDialog: FC<ISegmentPositionDialog> = ({
    passage,
    video,
    segmentIndex,
    editingSegmentPosition: esp,
    close
}) => {
    const { t } = useTranslation()

    // setEditingSegmentPosition: (value: number) => void,
    const adjustingBoth = esp === EditingSegmentPosition.Both

    const [positions] = useState<SegmentPositions>(new SegmentPositions(passage, video, segmentIndex))

    const [prePositions] = useState<SegmentPositions | null>(
        adjustingBoth && segmentIndex !== 0 ? new SegmentPositions(passage, video, segmentIndex - 1) : null
    )

    const [postPositions] = useState<SegmentPositions | null>(
        adjustingBoth && segmentIndex < video.segments.length - 1
            ? new SegmentPositions(passage, video, segmentIndex + 1)
            : null
    )

    function save() {
        async function _save() {
            await positions.save()
            if (prePositions) {
                await prePositions.save()
            }
            if (postPositions) {
                await postPositions.save()
            }

            // recompute times based on new positions
            video.setSegmentTimes(passage, true)
            const segment = video.segments[segmentIndex].actualSegment(passage)

            logSaveSegment('previous', passage, video, segmentIndex - 1)
            logSaveSegment('current', passage, video, segmentIndex)
            logSaveSegment('next', passage, video, segmentIndex + 1)

            close(false, segment.time, video.computedDuration)
        }

        _save().catch(displayError)
    }

    let heading = ''

    switch (esp) {
        case EditingSegmentPosition.Starting:
            heading = t('Segment Start')
            break
        case EditingSegmentPosition.Ending:
            heading = t('Segment End')
            break
        case EditingSegmentPosition.Both:
            heading = t('Adjust Segment Start and End')
            break
        default:
            break
    }

    const dialogClassName = adjustingBoth ? 'modal-90w' : 'modal-20w'

    return (
        <Modal style={{ top: '1%' }} dialogClassName={dialogClassName} show onHide={save} backdrop="static">
            <Modal.Header closeButton>
                <h3>{heading}</h3>
            </Modal.Header>
            <Modal.Body>
                <div className="patch-dialog-modal-body">
                    {adjustingBoth && <AdjustingBothDialogBody {...{ positions, prePositions, postPositions }} />}
                    {esp === 1 && <PositionDialogBody {...{ positions, editStartingPosition: true }} />}
                    {esp === 2 && <PositionDialogBody {...{ positions, editStartingPosition: false }} />}
                    <div>
                        <OkEditSegmentButton enabled onClick={save} />
                        <CancelEditSegmentButton enabled onClick={() => close(true, 0, 0)} />
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

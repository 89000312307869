import { observer } from 'mobx-react'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { isAVTT } from '../../app/slttAvtt'
import { Project } from '../../../models3/Project'
import { GenericIcon } from '../../utils/Icons'
import { Switch } from '../../utils/Switch'

type VideoCompressionPreferencesProps = {
    project: Project
    iconClassName: string
}

export const VideoCompressionPreferences: FC<VideoCompressionPreferencesProps> = observer(
    ({ project, iconClassName }) => {
        const { t } = useTranslation()

        const {
            compressedVideoResolution,
            compressedVideoQuality,
            recordAudioOnly,
            maxVideoSizeMB,
            setCompressedVideoQuality,
            setCompressedVideoResolution,
            setMaxVideoSizeMB
        } = project

        return (
            <>
                {isAVTT && (
                    <div className="project-preferences-switch">
                        <Switch
                            value={!recordAudioOnly}
                            setValue={(value) => project.setRecordAudioOnly(!value)}
                            tooltip=""
                        >
                            <GenericIcon
                                className={iconClassName}
                                iconName="fa-video"
                                tooltip={t('projectSettingsRecordVideo')}
                            />
                        </Switch>
                    </div>
                )}
                {!recordAudioOnly && (
                    <div>
                        <h4>{t('projectSettingsCompression')}</h4>
                        <div className="flex-centered team-preference-option">
                            <div className="team-preference-option-label">{t('Resolution')}</div>
                            <select
                                value={compressedVideoResolution}
                                onChange={(e) => setCompressedVideoResolution(parseInt(e.target.value))}
                            >
                                <option value="480">480</option>
                                <option value="720">720</option>
                            </select>
                        </div>
                        <div className="flex-centered team-preference-option">
                            <div className="team-preference-option-label">{t('projectSettingsRecordingQuality')}</div>
                            <select
                                value={compressedVideoQuality}
                                onChange={(e) => setCompressedVideoQuality(parseInt(e.target.value))}
                            >
                                <option value="18">18 (Very high)</option>
                                <option value="19">19</option>
                                <option value="20">20</option>
                                <option value="21">21</option>
                                <option value="22">22</option>
                                <option value="23">23</option>
                                <option value="24">24</option>
                                <option value="25">25</option>
                                <option value="26">26</option>
                                <option value="27">27</option>
                                <option value="28">28 (Very low)</option>
                            </select>
                        </div>
                        <div className="flex-centered team-preference-option">
                            <div className="team-preference-option-label">
                                {t('projectSettingsMaxCompressedRecording')}
                            </div>
                            <select
                                value={maxVideoSizeMB}
                                onChange={(e) => setMaxVideoSizeMB(parseInt(e.target.value))}
                            >
                                <option value="100">100</option>
                                <option value="250">250</option>
                            </select>
                        </div>
                    </div>
                )}
            </>
        )
    }
)

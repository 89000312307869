/* eslint-disable react/prefer-stateless-function */

// Allow user to select which portion in the current project they wish to work with

import React, { Component } from 'react'
import { observer } from 'mobx-react'

import { RootConsumer } from '../app/RootContext'

import { Portion } from '../../models3/Portion'

interface IPortionSelector {
    onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void
    portions: Portion[]
    selectedValue: string
}

class PortionSelector extends Component<IPortionSelector> {
    render() {
        const { selectedValue, portions, onChange } = this.props

        return (
            <RootConsumer>
                {() => (
                    <select className="custom-select portion-selector" value={selectedValue} onChange={onChange}>
                        {portions.map(({ _id, name }) => (
                            <option key={_id} value={_id}>
                                {' '}
                                {name}{' '}
                            </option>
                        ))}
                    </select>
                )}
            </RootConsumer>
        )
    }
}

export default observer(PortionSelector)

/* eslint-disable no-underscore-dangle */
import { observable, computed } from 'mobx'
import { changeLanguage } from 'i18next'

import API from './API'
import { DateFormatterFactory, daysAgo, IDateFormatter } from './DateUtilities'
import { Passage } from './Passage'
import { PassageGloss } from './PassageGloss'
import { PassageNote } from './PassageNote'
import { PassageSegment } from './PassageSegment'
import { PassageSegmentLabel } from './PassageSegmentLabel'
import { PassageVideo } from './PassageVideo'
import { Portion } from './Portion'
import { Project } from './Project'
import { ProjectReferences } from './ProjectReferences'
import { RootBase } from './RootBase'
import { normalizeUsername } from './Utils'

import { redirectToHome } from '..'

import { defaultLabels } from '../components/segments/SegmentLabelsPosition'
import { tourSteps, setTourRt } from '../components/translation/TranslationEditorTour'
import { displayError } from '../components/utils/Errors'
import { fmt } from '../components/utils/Fmt'

import { RefRange } from '../scrRefs/RefRange'
import { refRangesToDisplay } from '../scrRefs/Utils'

import { Project as SooSLProject } from '../soosl/Project'
import { Backend } from '../soosl/backend'
import { RecordingDoneParams } from '../components/video/VideoUploader'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:Root')

const intest = localStorage.getItem('intest') === 'true'

export interface RootProps {
    project: Project
    username: string
    id_token: string
    iAmRoot: boolean
    group?: string
    groupProjects?: string[]
    selectPage: (selection: string) => void
}
export class Root extends RootBase {
    @computed get name() {
        return this.project.name
    }

    @computed get displayName() {
        return this.project.displayName
    }

    project: Project

    initializing = false

    @observable initialized = false

    @observable uiLanguage = 'en'

    @observable uiLanguageChanging = false

    @computed get dateFormatter(): IDateFormatter {
        return new DateFormatterFactory().getDateFormatter(this.project.dateFormat, this.uiLanguage)
    }

    @observable portion: Portion | null = null

    @observable passageGloss: PassageGloss | null = null

    @observable passageSegment: PassageSegment | null = null

    @observable playbackRate = 1.0

    @observable statusShowAll = true

    @observable statusShowCurrentPortion = false

    @observable statusShowCurrentPassage = false

    username = ''

    id_token = ''

    @observable iAmRoot = false

    @observable iAmAdmin = false

    @observable iAmTranslator = false

    @observable iAmConsultant = false // anyone who can make notes

    @observable iAmInterpreter = false

    @observable iAmApprover = false // must have consultant role

    @observable recording = false

    @observable passageVideoUrl = ''

    @observable passageVideoBlob: Blob | null = null

    @observable segmentLabelsDraft: PassageSegmentLabel[] = [] // Only use if editingSegmentLabels is true

    @observable editingSegmentLabels = false

    @observable editingSegment = false

    @observable editingImage = false

    @observable editingPassageDocument = false

    @observable termModalOpen = false

    group?: string

    groupProjects?: string[]

    @computed get videoPlaybackKeydownEnabled() {
        return (
            !this.editingSegment &&
            !this.editingPassageDocument &&
            !this.editingSegmentLabels &&
            !this.note &&
            !this.termModalOpen
        )
    }

    setEditingPassageDocument(value: boolean) {
        this.editingPassageDocument = value
    }

    setEditingSegment(value: boolean) {
        if (value) {
            this.pause()
        }
        this.editingSegment = value
    }

    @observable softNotificationCutoff = '2000/01/01' // Do not generate notifications for items created before this date

    @observable notificationMaxDays = 30

    hardNotificationCutoff = () => {
        const { softNotificationCutoff, notificationMaxDays } = this
        const notificationCutoffDate = new Date(softNotificationCutoff)
        return new Date(Math.max(daysAgo(notificationMaxDays), notificationCutoffDate.getTime()))
    }

    sooslProject: SooSLProject | null = null

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    selectPage: (selection: string) => void

    @observable glossScale = 0.0 // 0..100, 0 is narrow width glosses is GlossBar

    projectReferences = new ProjectReferences()

    @observable loadingMessage = ''

    @observable useMobileLayout = false

    @observable useNarrowWidthLayout = false

    previousGlossVideoId = ''

    previousGlossPosition = 0

    // ------------ UI Tour related -------------
    @observable tourOpen = false

    @observable tourStepNumber = 0

    @observable tourSelector = ''

    @observable videoTourSignedUrl?: string // Set when showing video in TourVideoPlayer

    constructor({ project, username, id_token, iAmRoot, group, groupProjects, selectPage }: RootProps) {
        super()
        this.project = project
        this.username = normalizeUsername(username)
        this.id_token = id_token
        this.iAmRoot = iAmRoot
        this.group = group
        this.groupProjects = groupProjects
        this.selectPage = selectPage

        this.setCurrentTime = this.setCurrentTime.bind(this)
        this.setTimelineZoom = this.setTimelineZoom.bind(this)
        this.play = this.play.bind(this)
        this.setGlossScale = this.setGlossScale.bind(this)
        this.hardNotificationCutoff = this.hardNotificationCutoff.bind(this)
        this.setSegmentLabelsDraft = this.setSegmentLabelsDraft.bind(this)
        this.addListener = this.addListener.bind(this)
        this.removeListener = this.removeListener.bind(this)
        this.setPlaybackRate = this.setPlaybackRate.bind(this)
        this.displayableReferences = this.displayableReferences.bind(this)
        this.navigateToLinkLocation = this.navigateToLinkLocation.bind(this)
        this.setEditingSegment = this.setEditingSegment.bind(this)
        this.setEditingPassageDocument = this.setEditingPassageDocument.bind(this)
        this.pause = this.pause.bind(this)

        if (this.iAmRoot) {
            log(`role = root`)
            this.iAmAdmin = true
            this.iAmTranslator = true
            this.iAmConsultant = true
            this.iAmInterpreter = true
            this.iAmApprover = true
        }
    }

    dbg(details?: string) {
        const portion = this.portion && `${this.portion.name} | ${this.portion._id}`
        const passage = this.passage && `${this.passage.name} | ${this.passage._id}`
        const currentVideos = this.currentVideos.dbg()
        const passageGloss = this.passageGloss && this.passageGloss.dbg()
        const passageSegment = this.passageSegment && this.passageSegment.dbg(this.passage, details)

        const {
            statusShowAll,
            statusShowCurrentPassage,
            statusShowCurrentPortion,
            username,
            recording,
            playing,
            currentTime,
            duration,
            canPlayThrough
        } = this

        const note = (this.note && this.note.dbg(details?.includes('n'))) || 'none selected'
        const verseReference = (this.verseReference && this.verseReference.dbg()) || 'none selected'

        return {
            portion,
            passage,
            currentVideos,
            statusShowAll,
            statusShowCurrentPassage,
            statusShowCurrentPortion,
            username,
            recording,
            playing,
            currentTime,
            duration,
            canPlayThrough,
            passageGloss,
            passageSegment,
            note,
            verseReference
        }
    }

    async initialize() {
        const { initialized, initializing, name, project } = this
        log(`[${name}] --- start 7 model.initialize`)

        if (initialized || initializing) {
            log(`[${name}] model.initialize duplicate initialize ignored`)
            return
        }

        log(`[${this.name}] model.initialize A`)

        this.initializing = true

        await project.initialize((message: string) => {
            this.loadingMessage = message
        })

        log(`[${this.name}] model.initialize B`)

        this.setRole()
        await this.restoreDefaults()

        log(`[${this.name}] model.initialize C`)
        await this.navigateToLinkLocation()
        this.initialized = true
    }

    async navigateToLinkLocation() {
        const { origin, pathname, hash } = window.location
        const { project } = this
        const nonHashURL = origin.concat(pathname).concat(hash.slice(2))
        const url = new URL(nonHashURL)
        const searchParams = new URLSearchParams(url.search)
        const _id = searchParams.get('id')
        const time = searchParams.get('time')
        const linkProject = searchParams.get('project')

        if (!_id || !time || (linkProject && project.name !== linkProject)) {
            return
        }

        const portion = project.findPortion(_id)
        if (!portion) {
            displayError('Portion does not exist.')
            redirectToHome()
            return
        }

        const passage = project.findPassage(_id)
        if (!passage) {
            displayError('Passage does not exist.')
            redirectToHome()
            return
        }

        const pv = passage.findVideo(_id)
        if (!pv) {
            displayError('Passage video does not exist.')
            redirectToHome()
            return
        }

        await this.setPortion(portion)
        await this.setPassage(passage)
        // Root.setPassageVideo function assumes the video you pass is a base video
        await this.setPassageVideo(pv.baseVideo(passage) || pv)

        this.setNote(passage.findNote(_id))
        this.setCurrentTime(Number(time) || 0)
    }

    setRole() {
        if (!this.project) throw Error('Project not set')
        if (!this.project.members) throw Error('Members not set')

        if (this.iAmRoot) {
            this.iAmAdmin = true
            this.iAmTranslator = true
            this.iAmConsultant = true
            this.iAmInterpreter = true
            this.iAmApprover = true
            return
        }

        this.iAmAdmin = false
        this.iAmTranslator = false
        this.iAmConsultant = false
        this.iAmInterpreter = false
        this.iAmApprover = false

        const member = this.project.members.find((projectMember) => projectMember.email === this.username)
        if (!member) {
            log(`###setRole member [${this.username}] not found`)
            return
        }

        const { role } = member
        log(`setRole ${this.username} = ${role}, root=${this.iAmRoot}`)

        if (['admin', 'consultant'].includes(role)) this.iAmApprover = true

        if (['admin'].includes(role)) this.iAmAdmin = true

        if (['admin', 'translator'].includes(role)) this.iAmTranslator = true

        if (['admin', 'translator', 'consultant'].includes(role)) this.iAmConsultant = true

        if (['admin', 'translator', 'consultant', 'interpreter'].includes(role)) this.iAmInterpreter = true
    }

    async getSooslProject(): Promise<SooSLProject> {
        if (!this.sooslProject) {
            const json = await Backend.getProject(this.project.name)
            this.sooslProject = new SooSLProject(json)
        }

        return this.sooslProject
    }

    getDefault(tag: string) {
        // check if we are not running in a browser, this should only happen during unit tests
        if (typeof localStorage === 'undefined') return ''

        const key = `defaults.${this.name}.${tag}`
        log(`getDefault ${key}=${localStorage.getItem(key)}`)
        return localStorage.getItem(key)
    }

    setDefault(tag: string, value: string | null) {
        // check if we are not running in a browser, this should only happen during unit tests
        if (typeof localStorage === 'undefined') return

        const key = `defaults.${this.name}.${tag}`
        value = value || ''
        log(`setDefault ${key}=${value}`)
        localStorage.setItem(key, value)
    }

    // Retrieve the previous user settings from local storage.
    // Attempt to set project to previously selected portion and passage.
    async restoreDefaults() {
        log(`[${this.name}] restoreDefaults`)

        const playbackRateString = localStorage.getItem('videoPlaybackRate')
        log(`initial videoPlaybackRate ${playbackRateString}`)
        const playbackRate = playbackRateString ? parseFloat(playbackRateString) : 1.0
        this.playbackRate = playbackRate

        this.glossScale = parseFloat(this.getDefault('gloss-scale') || '100.0')

        this.timeline.zoomLevel = parseFloat(this.getDefault('timelineZoom') || '1')

        const isStatusShowingAll = this.getDefault('statusShowAll')
        if (!isStatusShowingAll) {
            this.setDefault('statusShowAll', 'true')
            this.statusShowAll = true
        } else {
            this.statusShowAll = isStatusShowingAll === 'true'
        }

        const isStatusShowingCurrentPortion = this.getDefault('statusShowCurrentPortion')
        if (!isStatusShowingCurrentPortion) {
            this.setDefault('statusShowCurrentPortion', 'false')
            this.statusShowCurrentPortion = false
        } else {
            this.statusShowCurrentPortion = isStatusShowingCurrentPortion === 'true'
        }

        const isStatusShowingCurrentPassage = this.getDefault('statusShowCurrentPassage')
        if (!isStatusShowingCurrentPassage) {
            this.setDefault('statusShowCurrentPassage', 'false')
            this.statusShowCurrentPassage = false
        } else {
            this.statusShowCurrentPassage = isStatusShowingCurrentPassage === 'true'
        }

        const uiLanguage = this.getDefault('uiLanguage')
        if (!uiLanguage) {
            this.setDefault('uiLanguage', 'en')
        }
        this.uiLanguage = uiLanguage || 'en'

        const { project } = this
        const portion = project.getDefaultPortion(this.getDefault('portion'))
        if (!portion) return

        this.portion = portion
        this.setDefault('portion', portion._id)

        const passage = portion.getDefaultPassage(this.getDefault('passage'))
        if (!passage) return

        this.passage = passage
        this.setDefault('passage', passage._id)

        const passageVideo = passage.getDefaultVideo(this.getDefault('passagevideo') ?? '')
        if (passageVideo) {
            await this.setPassageVideo(passageVideo)
        } else {
            await this.setPassage(passage)
        }
    }

    resetStatusFilter() {
        this.setDefault('statusShowAll', 'false')
        this.setDefault('statusShowCurrentPortion', 'false')
        this.setDefault('statusShowCurrentPassage', 'false')
        this.statusShowAll = false
        this.statusShowCurrentPortion = false
        this.statusShowCurrentPassage = false
    }

    getLastProjectNotificationSeen() {
        const rank = this.getDefault('projectnotification')
        return rank ?? '0'
    }

    setLastProjectNotificationSeen(rank: string) {
        this.setDefault('projectnotification', rank)
    }

    setNotificationCutoff(value: string) {
        const cutoffMilliseconds = Date.parse(value)
        if (!isNaN(cutoffMilliseconds)) {
            this.setDefault('notificationCutoff', value)
            this.softNotificationCutoff = value
        }
    }

    setStatusShowAll(value: boolean) {
        this.resetStatusFilter()
        this.statusShowAll = value
        this.setDefault('statusShowAll', value ? 'true' : 'false')
    }

    setStatusShowCurrentPortion(value: boolean) {
        this.resetStatusFilter()
        this.statusShowCurrentPortion = value
        this.setDefault('statusShowCurrentPortion', value ? 'true' : 'false')
    }

    setStatusShowCurrentPassage(value: boolean) {
        this.resetStatusFilter()
        this.statusShowCurrentPassage = value
        this.setDefault('statusShowCurrentPassage', value ? 'true' : 'false')
    }

    setGlossScale(scale: number) {
        super.setGlossScale(scale)
        this.setDefault('gloss-scale', scale.toString())
    }

    // /**
    // * If passage has not been selected yet and this is a passage for the currently
    // * selected portion, use this passage
    // */
    // async acceptPassage(passage: Passage) {
    //     if (this.passage) return
    //     if (!this.portion) return
    //     if (!passage._id.startsWith(this.portion._id)) return

    //     log('acceptPassage')
    //     await this.setPassage(passage)
    // }

    // /**
    // * If this video is the latest video for the passage, make it the selected video
    // */
    // async acceptPassageVideo(video: PassageVideo) {
    //     if (!this.portion) return
    //     if (!this.passage) return
    //     if (!video._id.startsWith(this.passage._id)) return
    //     if (this.passage.videosNotDeleted.slice(-1)[0]._id !== video._id) return

    //     log('acceptPassageVideo')
    //     await this.setPassageVideo(video)
    // }

    async setPortion(portion: Portion | null) {
        log(`[${this.name}] setPortion: ${portion && portion.name}`)

        this.setDefault('portion', portion && portion._id)
        this.portion = null
        await this.setPassage(null)
        if (!portion) return

        this.portion = portion

        await this.setDefaultPassage()
    }

    async setDefaultPassage() {
        const passage = this.portion?.getDefaultPassage('') ?? null
        await this.setPassage(passage)

        this.setDefaultPassageVideo()
    }

    async setPassage(passage: Passage | null) {
        log(`[${this.name}] setPassage: ${passage && passage.name}`)
        this.setDefault('passage', passage && passage._id)

        if (!passage) {
            this.passage = null
            this.setEditingSegment(false)
            await this.setPassageVideo(null)
            return
        }

        // Set passageVideo to null because otherwise we can have a situation
        // where passage and passageVideo temporarily refer to different passages.
        await this.setPassageVideo(null)

        this.passage = passage
        await this.setDefaultPassageVideo()

        // Force portion to appear to change to ensure that the new passage
        // item is seen by observers. Better way to do this???
        const { portion } = this
        this.portion = null
        this.portion = portion
    }

    // async preloadNoteVideos(passageVideo: PassageVideo | null) {
    //     if (!passageVideo) return

    //     let notes = passageVideo.notes.filter(note => !note.resolved)
    //     for (let note of notes) {
    //         for (let item of note.items) {
    //             if (item.url) {
    //                 log('preloading', item.url)
    //                 try {
    //                     await VideoCache.requestVideo(item.url)
    //                 } catch (error) {
    //                     log(`preloadNoteVideos error`, error)
    //                 }
    //             }
    //         }
    //     }
    // }

    async setDefaultPassageVideo() {
        if (!this.passage) {
            this.passageVideo = null
            this.passageSegment = null
            this.passageVideoUrl = ''
            return
        }

        const passageVideo = this.passage.getDefaultVideo('')
        await this.setPassageVideo(passageVideo)
    }

    async setPassageVideo(passageVideo: PassageVideo | null) {
        const { portion, passage, name } = this
        await super._setPassageVideo(name, passage, passageVideo)

        // Set references, sa they can be derived from portion, passage, and segment video
        this.setDbsRefs(portion)
    }

    setPassageSegment(passageSegment: PassageSegment) {
        const { passage, passageVideo } = this
        if (!passage || !passageVideo) return
        if (this.passageSegment === passageSegment) return
        log('setPassageSegment', fmt({ passageSegment, time: passageSegment.time }))
        this.passageSegment = passageSegment
    }

    resetCurrentTime(newTime: number, duration?: number) {
        super.resetCurrentTime(newTime, duration)
        const { portion } = this
        this.setDbsRefs(portion)
    }

    setCurrentTime(currentTime: number) {
        super.setCurrentTime(currentTime)
        const { passage, passageVideo, portion } = this

        if (!portion) {
            return
        }
        this.setDbsRefs(portion)

        // If the video was just created, it may not have any segments yet.
        // If so, we cannot select a segment yet.
        if (!passage || !passageVideo || passageVideo.segments.length === 0) {
            return
        }

        try {
            const segment = passageVideo.timeToSegment(passage, currentTime)
            this.setPassageSegment(segment)
        } catch (error) {
            log('###setCurrentTime', error)
        }
    }

    setPlaybackRate(rate: number) {
        rate = Math.max(0.1, Math.min(rate, 2.0))
        this.playbackRate = rate
        localStorage.setItem('videoPlaybackRate', rate.toString())
        log(`change videoPlaybackRate ${rate}`)
    }

    openTour() {
        setTourRt(this)
        this.tourStepNumber = 0
        // this.tourSelector = this.getCurrentTourStep().selector
        this.tourOpen = true
    }

    closeTour() {
        this.tourSelector = ''
        this.tourOpen = false
    }

    getCurrentTourStep(stepNumber: number) {
        const step = tourSteps[stepNumber]
        this.tourSelector = step.selector
        log(`=== ${step.selector}`)
    }

    async setVideoTourSignedUrl(url: string) {
        this.videoTourSignedUrl = await API.getUrl(this.name, url)
    }

    createNote() {
        const { passageVideo } = this
        if (!passageVideo) {
            throw new Error('note not created because of missing passageVideo')
        }

        const creationDate = passageVideo.db.getNewId(passageVideo.notes, new Date(Date.now()))
        const _id = `${passageVideo._id}/${creationDate}`

        this.setNote(new PassageNote(_id, passageVideo.db))
        if (!this.note) {
            throw new Error('note not created')
        }

        let ct = this.currentTime
        if (intest) ct = Math.round(ct * 1000) / 1000 // stop jitter from failing test
        this.note.position = ct
        this.note.startPosition = ct - 10
        this.note.endPosition = ct + 10
    }

    /**
     * Play video in main window (RootVideoPlayer)
     * @param startTime - when present start playing video at this time, otherwise at time = 0
     * @param endPosition - when present stop playing video at this time, otherwise play to end
     * @param resetTime - when present, reset video time to this playing
     */
    play(startTime?: number, endPosition?: number, resetTime?: number) {
        if (this.editingSegment) return
        super.play(startTime, endPosition, resetTime)
    }

    playAll() {
        if (this.editingSegment) return
        this.emit('playAll')
    }

    /**
     * This is called by VideoMain to trigger the start of recording.
     * It is also called by SegmentsEditor to start recording a patch.
     */
    record(
        videoBeingRecorded: PassageVideo,
        onRecordingDone: ({ err, blobsCount, url, duration, mimeType }: Partial<RecordingDoneParams>) => void,
        recordAudioOnly: boolean
    ) {
        if (this.recording) {
            log(`record request ignored, already recording`)
            return // ignore if already recording
        }
        if (!this.portion || !this.passage || !this.iAmTranslator) return
        log(`record`)
        this.emit('record', videoBeingRecorded, onRecordingDone, recordAudioOnly)
    }

    async createBiblicalTermMarker(time: number) {
        const { passage, passageVideo } = this
        if (!passage || !passageVideo) return

        // If this segment has a patch add the marker to the patch, otherwise add to base video
        const segment = passageVideo.timeToSegment(passage, time)
        const video = segment.patchVideo(passage) || passageVideo
        const _segment = segment.actualSegment(passage)
        const position = _segment.timeToPosition(time)

        const marker = video.createBiblicalTermMarker()
        marker.position = position
        if (
            marker.canChangePositionToTime({
                time,
                computedDuration: passageVideo.computedDuration,
                markers: passageVideo.getVisibleBiblicalTermMarkers(passage)
            })
        ) {
            return marker
        }
    }

    setLocale(locale: string) {
        changeLanguage(locale)

        this.uiLanguage = locale
        this.setDefault('uiLanguage', locale)
        this.uiLanguageChanging = true
        setTimeout(() => {
            this.uiLanguageChanging = false
        }, 2000)
    }

    getDuration(video: PassageVideo) {
        const { passage } = this
        let viewTime = 0
        if (passage) {
            video.segments.forEach((s) => {
                viewTime += s.actualSegment(passage).duration
            })
        }
        return viewTime
    }

    getProjectReferences() {
        return this.projectReferences
    }

    startEditingSegmentLabels(initialLabels: PassageSegmentLabel[]) {
        this.segmentLabelsDraft = initialLabels
        this.editingSegmentLabels = true
    }

    async saveSegmentLabelsDraftChanges() {
        const { passageSegment, passage } = this
        if (passageSegment && passage) {
            const segment = passageSegment.actualSegment(passage)
            if (segment) {
                const { segmentLabelsDraft } = this

                log('Save segment labels to database')
                const filteredLabels = segmentLabelsDraft.map((label, i) => (label.text ? label : defaultLabels[i]))
                await segment.setLabels(filteredLabels)

                this.passageSegment = passageSegment
                this.editingSegmentLabels = false
            }
        }

        this.segmentLabelsDraft = []
        this.editingSegmentLabels = false
    }

    setSegmentLabelsDraft(labels: PassageSegmentLabel[]) {
        this.segmentLabelsDraft = labels
    }

    async resetSegmentLabelsDraftChanges() {
        log(`discard segment labels changes`)
        this.segmentLabelsDraft = []
        this.editingSegmentLabels = false
    }

    @computed get canViewConsultantOnlyFeatures() {
        return this.iAmRoot || this.iAmApprover
    }

    // Convert references to a display able string based on book names defined for the project
    displayableReferences(references: RefRange[] | undefined | null) {
        if (!references) return ''
        return refRangesToDisplay(references, this.project)
    }

    // // Parse a Reference string, Luke 7.11-12, into RefRanges.
    // // Uses book names from project.
    // // THROWS if cannot parse
    // parseReferences(references: string): RefRange[] {
    //     let { bookNames } = this.project
    //     let bookNamesList = Object.values(bookNames)
    //     let refRanges = RefRange.parsePersistedRefRanges(references, bookNamesList)
    //     return refRanges
    // }

    // Parse a Reference string, Luke 7.11-12, into RefRanges.
    // Uses book names from project, ui language, or English.
    // THROWS if cannot parse.
    parseReferences(references: string): RefRange[] {
        const { bookNames } = this.project
        const bookNamesList = Object.values(bookNames)
        const refRanges = RefRange.parseReferences(references, this.uiLanguage, bookNamesList)
        return refRanges
    }

    // Setup as Bob the interpreter.
    // This is useful when testing.
    // From browser console: window.appRoot.rt.setInterpreter()

    setInterpreter() {
        this.username = 'bob@gmail.com'

        this.iAmRoot = false
        this.iAmAdmin = false
        this.iAmTranslator = false
        this.iAmConsultant = false
        this.iAmInterpreter = true
        this.iAmApprover = false
    }

    setConsultant() {
        this.username = 'bob@gmail.com'

        this.iAmRoot = false
        this.iAmAdmin = false
        this.iAmTranslator = false
        this.iAmConsultant = false
        this.iAmInterpreter = false
        this.iAmApprover = true
    }

    setTranslator() {
        this.username = 'bob@gmail.com'

        this.iAmRoot = false
        this.iAmAdmin = false
        this.iAmTranslator = true
        this.iAmConsultant = false
        this.iAmInterpreter = true
        this.iAmApprover = false
    }

    setObserver() {
        this.username = 'bob@gmail.com'

        this.iAmRoot = false
        this.iAmAdmin = false
        this.iAmTranslator = false
        this.iAmConsultant = false
        this.iAmInterpreter = false
        this.iAmApprover = false
    }

    setTimelineZoom(zoom: number) {
        super.setTimelineZoom(zoom)
        this.setDefault('timelineZoom', zoom.toFixed(0))
    }

    static screenCaptureInProgress() {
        return localStorage.getItem('screencapture') === 'true'
    }
}

import { observer } from 'mobx-react'
import { MutableRefObject, useEffect, useRef, useState } from 'react'
import ReactDOMServer from 'react-dom/server'
import WaveSurfer from 'wavesurfer.js'
import MarkersPlugin from 'wavesurfer.js/src/plugin/markers'
import RegionsPlugin from 'wavesurfer.js/src/plugin/regions'
import TimelinePlugin from 'wavesurfer.js/src/plugin/timeline'

import { CircleNoteMarker, SquareNoteMarker, TriangleNoteMarker, UnresolvedNoteMarker } from '../utils/Buttons'
import { CheckCircle, CheckIcon, StarIcon, ThinCircleIcon } from '../utils/Icons'

import { BiblicalTermMarker } from '../../models3/BiblicalTermMarker'
import { Passage } from '../../models3/Passage'
import { PassageNote } from '../../models3/PassageNote'
import { PassageSegment, PassageSegmentApproval } from '../../models3/PassageSegment'
import { PassageVideo } from '../../models3/PassageVideo'
import { ProjectTerm } from '../../models3/ProjectTerm'
import { getVisiblePassageOrPortionRefRanges } from '../../models3/ProjectReferences'
import { ReferenceMarker } from '../../models3/ReferenceMarker'
import { Root } from '../../models3/Root'
import { TimelineSelection } from '../../models3/RootBase'

import './WaveSurferAudioPlayerCore.css'

const MARKER_SIZE_PX = 15

const MARKER_LABEL_CLASS_NAME = 'marker-label'

const enum RegionType {
    segment = 'segment',
    selection = 'selection'
}

const clamp = (num: number, min: number, max: number) => Math.min(Math.max(num, min), max)

const getMarkerId = (wavesurferMarker: any) => {
    let markerId = ''
    const markerLabelElement: any = Array.from(wavesurferMarker.el.children).find(
        (el: any) => el.className === MARKER_LABEL_CLASS_NAME
    )
    if (markerLabelElement) {
        const markerElement: any = Array.from(markerLabelElement.children).find(
            (el: any) => el.dataset.markerId !== undefined
        )
        if (markerElement) {
            markerId = markerElement.dataset.markerId
        }
    }
    return markerId
}

const findMarker = ({
    markerId,
    passage,
    passageVideo
}: {
    markerId: string
    passage: Passage
    passageVideo: PassageVideo
}) => {
    return (
        passageVideo.getVisibleReferenceMarkers(passage).find((v) => v._id === markerId) ??
        passageVideo.getVisibleBiblicalTermMarkers(passage).find((v) => v._id === markerId)
    )
}

const findNoteMarker = ({
    markerId,
    passage,
    passageVideo,
    showConsultantOnlyNotes
}: {
    markerId: string
    passage: Passage
    passageVideo: PassageVideo
    showConsultantOnlyNotes: boolean
}) => {
    return passageVideo.getVisibleNotes(passage, showConsultantOnlyNotes).find((v) => v._id === markerId)
}

interface SpanWithWidth extends HTMLSpanElement {
    width: number
}

const createNoteMarker = ({
    note,
    enabled,
    mostRecentNoteIdViewed,
    noteColors
}: {
    note: PassageNote
    enabled: boolean
    mostRecentNoteIdViewed: string
    noteColors: string[]
}) => {
    const { onTop, inIgnoredSegment, consultantOnly, resolved, type } = note
    const typeIndex = parseInt(type)

    let color = ''
    if (!enabled) {
        color = 'grey'
    } else if (!onTop || inIgnoredSegment) {
        color = 'black'
    } else if (consultantOnly) {
        color = 'purple'
    } else if (resolved) {
        //
    } else {
        color = noteColors[typeIndex % 3]
    }

    const className = 'note-icon'
    const tooltip = note.description
    const style = { color }
    const shapes = [
        <CircleNoteMarker className={className} style={style} enabled tooltip={tooltip} />,
        <SquareNoteMarker className={className} style={style} enabled tooltip={tooltip} />,
        <TriangleNoteMarker className={className} style={style} enabled tooltip={tooltip} />
    ]
    let markerShape = resolved ? (
        <UnresolvedNoteMarker className={className} style={style} tooltip={tooltip} />
    ) : (
        shapes[Math.floor(typeIndex / 3)]
    )

    if (mostRecentNoteIdViewed === note._id) {
        markerShape = <span className="wavesurfer-recent-note">{markerShape}</span>
    }

    const markerElement = document.createElement('span') as SpanWithWidth
    markerElement.innerHTML = ReactDOMServer.renderToStaticMarkup(markerShape)
    markerElement.width = MARKER_SIZE_PX // markers plugin expects this for custom marker elements
    markerElement.dataset.markerId = note._id
    return markerElement
}

export const createSegmentApprovalMarker = (segment: PassageSegment) => {
    let markerShape: JSX.Element | undefined
    const className = 'approval-icon'
    if (segment.approved === PassageSegmentApproval.State1) {
        markerShape = <CheckIcon className={className} />
    } else if (segment.approved === PassageSegmentApproval.State2) {
        markerShape = <ThinCircleIcon className={className} />
    } else if (segment.approved === PassageSegmentApproval.State3) {
        markerShape = <CheckCircle className={className} />
    }
    let markerElement
    if (markerShape) {
        markerElement = document.createElement('span') as SpanWithWidth
        markerElement.width = MARKER_SIZE_PX // markers plugin expects this for custom marker elements
        markerElement.innerHTML = ReactDOMServer.renderToStaticMarkup(markerShape)
        return markerElement
    }
    return markerElement
}

export const createBiblicalTermMarker = ({
    marker,
    terms,
    passage
}: {
    marker: BiblicalTermMarker
    terms: ProjectTerm[]
    passage: Passage
}) => {
    const markerElement = document.createElement('span') as SpanWithWidth
    let glossText = ''
    for (const term of terms) {
        for (const gloss of term.glosses) {
            if (gloss._id === marker.targetGlossId) {
                glossText = gloss.text
                break
            }
        }
        if (glossText !== '') {
            break
        }
    }

    const markerClassNames = `key-term-marker-icon${marker.isOlderThanContainingPassageVideo(passage) ? ' old' : ''}`

    markerElement.innerHTML = ReactDOMServer.renderToStaticMarkup(<StarIcon className={markerClassNames} />)

    markerElement.setAttribute('title', glossText)
    markerElement.width = MARKER_SIZE_PX // markers plugin expects this for custom marker elements
    markerElement.dataset.markerId = marker._id
    return markerElement
}

export const createVerseReferenceMarker = ({ marker, rt }: { marker: ReferenceMarker; rt: Root }) => {
    const markerElement = document.createElement('span') as SpanWithWidth
    const references = rt.displayableReferences(marker.references)
    const stroke = rt.passage && marker.isOlderThanContainingPassageVideo(rt.passage) ? 'black' : 'grey'

    markerElement.setAttribute('title', references)
    markerElement.innerHTML = ReactDOMServer.renderToStaticMarkup(
        <svg viewBox="0 0 24 24" className="verse-icon">
            <g>
                <line x1="4" y1="4" x2="12" y2="20" strokeWidth="5" stroke={stroke} />
                <line x1="12" y1="20" x2="20" y2="4" strokeWidth="5" stroke={stroke} />
            </g>
        </svg>
    )
    markerElement.width = MARKER_SIZE_PX // markers plugin expects this for custom marker elements
    markerElement.dataset.markerId = marker._id
    return markerElement
}

const placeMarker = ({
    marker,
    rt,
    wavesurferRef
}: {
    marker: ReferenceMarker | BiblicalTermMarker
    rt: Root
    wavesurferRef: MutableRefObject<WaveSurfer | null>
}) => {
    let markerElement
    if (marker instanceof ReferenceMarker) {
        markerElement = createVerseReferenceMarker({ marker, rt })
    } else {
        if (!rt.passage) {
            return
        }

        const refRanges = getVisiblePassageOrPortionRefRanges({ passage: rt.passage, portion: rt.portion })
        const terms = rt.project.getKeyTermsThatOccurInVerses(refRanges)
        markerElement = createBiblicalTermMarker({ marker, terms, passage: rt.passage })
    }

    wavesurferRef.current?.markers.add({
        time: marker.time,
        position: 'top',
        draggable: rt.iAmTranslator,
        markerElement
    })
}

interface WaveSurferAudioPlayerCoreProps {
    url: string
    passage: Passage
    passageVideo: PassageVideo
    rt: Root
    playbackRate: number
    minPxPerSecond: number
    setZoomOutEnabled: (value: boolean) => void
    setMinPxPerSecond: (value: number) => void
    setBiblicalTermMarker: (marker: BiblicalTermMarker) => void
}

export const WaveSurferAudioPlayerCore = observer(
    ({
        url,
        rt,
        playbackRate,
        passage,
        passageVideo,
        minPxPerSecond,
        setBiblicalTermMarker,
        setZoomOutEnabled,
        setMinPxPerSecond
    }: WaveSurferAudioPlayerCoreProps) => {
        const [ready, setReady] = useState(false)
        const [draggingSelection, setDraggingSelection] = useState(false)
        const wavesurferRef = useRef<WaveSurfer | null>(null)

        // These will force the component to rerender when anything on passage changes.
        // This is necessary to get the regions and markers to update when any field on
        // the underlying data is changed.
        const { mostRecentNoteIdViewed } = rt
        const { selection } = rt.timeline
        const { _rev } = passage

        const removeRegions = (regionType: RegionType) => {
            if (wavesurferRef.current) {
                Object.values(wavesurferRef.current.regions.list)
                    .filter((region) => region.attributes.type === regionType)
                    .forEach((region) => region.remove())
            }
        }

        // handle initial load and set up wavesurfer
        useEffect(() => {
            if (url) {
                const waveSurfer = WaveSurfer.create({
                    container: '#waveform',
                    waveColor: '#337ab7',
                    progressColor: '#337ab7',
                    minPxPerSec: minPxPerSecond,
                    scrollParent: true,
                    plugins: [
                        TimelinePlugin.create({
                            container: '#timeline',
                            deferInit: true
                        }),
                        RegionsPlugin.create({
                            dragSelection: true
                        }),
                        MarkersPlugin.create({
                            // There is a bug in wavesurfer where if you don't provide
                            // any markers initially, you can't drag any markers. The fix
                            // is to provide a placeholder marker, which you can then
                            // delete right away.
                            // https://github.com/katspaugh/wavesurfer.js/issues/2417#issuecomment-1053659377
                            markers: [{ draggable: true, time: 0 }]
                        })
                    ]
                })

                wavesurferRef.current = waveSurfer

                wavesurferRef.current.load(url)

                wavesurferRef.current.on('ready', () => {
                    wavesurferRef.current?.initPlugin('timeline')

                    const duration = wavesurferRef.current?.getDuration()
                    const timeline = wavesurferRef.current?.timeline
                    if (duration && timeline) {
                        // set scrolling
                        const { clientLeft, clientWidth } = timeline.wrapper
                        const totalWidth = timeline.canvases.reduce(
                            (size, canvas) => size + canvas.getBoundingClientRect().width,
                            0
                        )
                        const scrollWidth = clientWidth - clientLeft
                        setZoomOutEnabled(scrollWidth < totalWidth)
                        setMinPxPerSecond(Math.round(totalWidth / duration))
                    }

                    setReady(true)
                })

                wavesurferRef.current.on('seek', (seekTime) => {
                    if (wavesurferRef.current) {
                        // seek time is from 0..1
                        rt.resetCurrentTime(seekTime * wavesurferRef.current.getDuration())
                    }
                })

                wavesurferRef.current.on('region-click', () => {
                    rt.timeline.selection = undefined
                })

                wavesurferRef.current.on('region-created', (region) => {
                    if (region.attributes.type !== RegionType.segment) {
                        region.attributes.type = RegionType.selection
                    }
                })

                wavesurferRef.current.on('region-updated', (region) => {
                    if (region.attributes.type === RegionType.selection) {
                        if (wavesurferRef.current) {
                            const seekTo = region.end / wavesurferRef.current.getDuration()
                            if (!isNaN(seekTo) && seekTo !== Infinity) {
                                wavesurferRef.current.seekTo(seekTo)
                            }
                        }
                        setDraggingSelection(true)
                        rt.timeline.selection = new TimelineSelection(region.start, region.end)
                    }
                })

                wavesurferRef.current.on('region-update-end', (region) => {
                    if (region.attributes.type === RegionType.selection) {
                        setDraggingSelection(false)
                    }
                })

                wavesurferRef.current.on('marker-drag', () => {
                    wavesurferRef.current?.regions.disableDragSelection()
                })

                wavesurferRef.current.on('marker-drop', (wavesurferMarker) => {
                    wavesurferRef.current?.regions.enableDragSelection({})

                    const markerId = getMarkerId(wavesurferMarker)
                    if (!markerId) {
                        return
                    }

                    const marker = findMarker({ markerId, passage, passageVideo })
                    if (!marker) {
                        return
                    }

                    const time = wavesurferMarker.time
                    const { computedDuration } = passageVideo
                    const index = wavesurferRef.current?.markers.markers.indexOf(wavesurferMarker) ?? -1

                    let markers
                    if (marker instanceof ReferenceMarker) {
                        markers = passageVideo.getVisibleReferenceMarkers(passage)
                    } else if (marker instanceof BiblicalTermMarker) {
                        markers = passageVideo.getVisibleBiblicalTermMarkers(passage)
                    } else {
                        return
                    }

                    if (marker.canChangePositionToTime({ time, computedDuration, markers })) {
                        passageVideo.saveMarkerPosition(passage, time, marker)
                    } else if (index >= 0) {
                        // Move marker back to where it was
                        wavesurferRef.current?.markers.remove(index)
                        placeMarker({ marker, rt, wavesurferRef })
                    }
                })

                wavesurferRef.current.on('marker-click', (wavesurferMarker) => {
                    const markerId = getMarkerId(wavesurferMarker)
                    if (!markerId) {
                        return
                    }

                    const marker = findMarker({ markerId, passage, passageVideo })
                    if (marker instanceof ReferenceMarker) {
                        rt.verseReference = marker
                    } else if (marker instanceof BiblicalTermMarker) {
                        setBiblicalTermMarker(marker)
                    } else {
                        const note = findNoteMarker({
                            markerId,
                            passage,
                            passageVideo,
                            showConsultantOnlyNotes: rt.canViewConsultantOnlyFeatures
                        })
                        if (note) {
                            rt.setNote(note)
                        }
                    }
                })
            }

            return () => {
                setReady(false)
                wavesurferRef.current?.unAll()
                wavesurferRef.current?.destroy()
                wavesurferRef.current = null
            }
        }, [
            minPxPerSecond,
            passage,
            passageVideo,
            rt,
            setBiblicalTermMarker,
            setMinPxPerSecond,
            setZoomOutEnabled,
            url
        ])

        // handle when playing or dragging
        useEffect(() => {
            if (!ready || !wavesurferRef.current || wavesurferRef.current.isPlaying() || draggingSelection) {
                return
            }
            const duration = wavesurferRef.current.getDuration()
            if (!duration) {
                return
            }
            // Disable seeking so that we don't update rt.currentTime. Sometimes
            // there is a rounding error when seeking, which causes rt.currentTime
            // to be changed.
            wavesurferRef.current.setDisabledEventEmissions(['seek'])

            const timeline = wavesurferRef.current.timeline
            const { clientLeft, clientWidth, scrollLeft } = timeline.wrapper
            const totalWidth = timeline.canvases.reduce(
                (size, canvas) => size + canvas.getBoundingClientRect().width,
                0
            )
            const scrollRight = scrollLeft + (clientWidth - clientLeft)

            // Seek time must be between 0 and 1
            const seekTo = clamp(rt.currentTime / duration, 0, 1)
            const cursorOutOfView = seekTo * totalWidth < scrollLeft || seekTo * totalWidth > scrollRight
            if (rt.playing || cursorOutOfView) {
                wavesurferRef.current.seekAndCenter(seekTo)
            } else {
                wavesurferRef.current.seekTo(seekTo)
            }

            // Object.values(wavesurferRef.current.regions.list).forEach((region) =>
            //     region.update({ color: 'transparent' })
            // )
            // wavesurferRef.current.regions.getCurrentRegion()?.update({ color: 'rgba(68,85,90,0.1)' })

            wavesurferRef.current.setDisabledEventEmissions([])
        }, [rt.currentTime, ready, rt.playing, draggingSelection])

        // handle playback rate change
        useEffect(() => {
            wavesurferRef.current?.setPlaybackRate(playbackRate)
        }, [playbackRate])

        // handle selection
        useEffect(() => {
            if (!wavesurferRef.current || !ready) {
                return
            }

            removeRegions(RegionType.selection)

            if (!selection) {
                return
            }

            const { start, end } = selection
            wavesurferRef.current?.regions.add({
                start,
                end,
                drag: false,
                resize: false,
                attributes: { type: RegionType.selection }
            })
        }, [selection, ready])

        // handle segment changes and marker drops
        useEffect(() => {
            if (!wavesurferRef.current || !ready) {
                return
            }

            // This should remove any elements we have created, so we don't have to do it manually.
            removeRegions(RegionType.segment)
            wavesurferRef.current.markers.clear()

            // put down segment lines and segment approval markers first since they do not need to be clicked
            passageVideo.segments.forEach((segment) => {
                const actualSegment = segment.actualSegment(passage)
                const start = actualSegment.positionToTime(actualSegment.position)
                const end = actualSegment.positionToTime(actualSegment.endPosition)

                wavesurferRef.current?.regions.add({
                    start,
                    end,
                    drag: false,
                    resize: false,
                    color: 'transparent',
                    attributes: {
                        type: RegionType.segment,
                        patched: segment.isPatched ? 'true' : 'false',
                        segment: segment._id
                    }
                })

                const markerElement = createSegmentApprovalMarker(actualSegment)
                if (markerElement) {
                    wavesurferRef.current?.markers.add({
                        time: actualSegment.time,
                        position: 'top',
                        markerElement
                    })
                }
            })

            /// do not put other markers for mobile layout
            if (rt.useMobileLayout) {
                return
            }

            // put down  markers
            passageVideo.getVisibleReferenceMarkers(passage).forEach((marker) => {
                placeMarker({ marker, rt, wavesurferRef })
            })

            // put down biblical term markers
            passageVideo.getVisibleBiblicalTermMarkers(passage).forEach((marker) => {
                placeMarker({ marker, rt, wavesurferRef })
            })

            // put down note markers
            const enabled = !passage.videoBeingCompressed
            const noteColors = rt.project.noteColors
            passageVideo.getVisibleNotes(passage, rt.canViewConsultantOnlyFeatures).forEach((note) => {
                const markerElement = createNoteMarker({ note, enabled, mostRecentNoteIdViewed, noteColors })
                wavesurferRef.current?.markers.add({
                    time: note.time,
                    position: 'bottom',
                    markerElement
                })
            })
        }, [passage, passageVideo, ready, rt, _rev, mostRecentNoteIdViewed]) // _rev and mostRecentNoteIdViewed need to here to detect any underlying changes

        return (
            <>
                <div id="timeline" />
                <div id="waveform" />
            </>
        )
    }
)

/* eslint-disable react/prefer-stateless-function */

import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { DraggableLocation } from 'react-beautiful-dnd'

import { DragAndDropData } from './DragAndDropData'
import StatusBoard from './StatusBoard'
import { FilteredPortionPassages } from './StatusEditor'
import './StatusBoardEditor.css'

import { ClearNotifications } from '../notifications/Notifications'
import ProjectDataFilter from '../utils/ProjectDataFilter'

import { Passage } from '../../models3/Passage'
import { Portion } from '../../models3/Portion'
import { Root } from '../../models3/Root'

interface IStatusEditor {
    rt: Root
    currentSelectedOption: string
    portionPassages: FilteredPortionPassages[]
    setCurrentPortion: (value: Portion) => void
    setCurrentPassage: (portion: Portion, passage: Passage) => void
    setShowAll: () => void
    setShowCurrentPortion: () => void
    setShowCurrentPassage: () => void
}

const findPassage = (data: DragAndDropData, columnId: string, draggableId: string) => {
    const column = data.findColumn(columnId)
    if (column !== undefined) {
        const passage = column.items.map((e) => e.passage).find((e) => e._id === draggableId)

        if (passage !== undefined) {
            return passage
        }
    }
}

const updateStatus = (
    data: DragAndDropData,
    source: DraggableLocation,
    destination: DraggableLocation,
    draggableId: string
) => {
    const startId = source.droppableId
    const finishId = destination.droppableId

    if (startId !== finishId) {
        const passage = findPassage(data, startId, draggableId)
        if (passage !== undefined) {
            const newColumn = data.findColumn(finishId)
            if (newColumn !== undefined) {
                const videos = passage.videosNotDeleted
                if (videos.length > 0) {
                    videos[videos.length - 1].setStatus(newColumn.task.id)
                }
            }
        }
    }
}

class StatusBoardEditor extends Component<IStatusEditor> {
    render() {
        const {
            rt,
            currentSelectedOption,
            portionPassages,
            setShowAll,
            setShowCurrentPassage,
            setShowCurrentPortion,
            setCurrentPassage,
            setCurrentPortion
        } = this.props
        const { project } = rt
        const { plans, displayName } = project

        const plan = plans[0]
        if (!plan) {
            return null
        }

        // let message = <div>{t('Drop project plan file here to upload.')}</div>
        // let dropTargetView = <DropTargetViewLarge message={message} />

        const passages = portionPassages.flatMap((pp) => pp.passages)
        const showClearNotificationsButton = portionPassages.length === 1

        return (
            <div className="status-board-editor">
                <div className="status-board-editor-header">
                    <h4 className="status-board-editor-project-name">
                        <b>{displayName}</b>
                    </h4>
                    <div className="portion-selector-container">
                        <div>
                            <ProjectDataFilter
                                portions={project.portions}
                                currentValue={currentSelectedOption}
                                setShowAll={setShowAll}
                                setShowCurrentPassage={setShowCurrentPassage}
                                setShowCurrentPortion={setShowCurrentPortion}
                                setPortion={setCurrentPortion}
                                setPassage={setCurrentPassage}
                            />
                        </div>
                        {showClearNotificationsButton && <ClearNotifications rt={rt} passages={passages} />}
                    </div>
                </div>
                {/* <DropTarget upload={upload} dropTargetView={dropTargetView}> */}
                <div className="status-board">
                    <StatusBoard
                        project={project}
                        projectPlan={plan}
                        portionPassages={portionPassages}
                        showLongTaskNames={portionPassages.length > 1}
                        updateStatus={updateStatus}
                        rt={rt}
                    />
                </div>
                {/* </DropTarget> */}
            </div>
        )
    }
}

export default observer(StatusBoardEditor)

import React, { FC, useState } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { Root } from '../../../models3/Root'
import { EditableStageList } from './Stage'
import { CopyProjectDataModal } from '../../utils/CopyProjectDataModal'
import { CopyButton } from '../../utils/Buttons'

import './ProjectPlanEditor.css'

interface IProjectPlanEditor {
    rt: Root
}

export const ProjectPlanEditor: FC<IProjectPlanEditor> = observer(({ rt }) => {
    const { t } = useTranslation()

    const [openCopyPlanModal, setOpenCopyPlanModal] = useState(false)
    const { iAmAdmin, project } = rt

    return (
        <div className="project-plan-editor">
            <h4 className="project-plan-editor-project-name">
                <b>{project.displayName}</b>
            </h4>
            {iAmAdmin && project.plans.length && project.plans[0].viewableStages.length > 0 && (
                <div>
                    {openCopyPlanModal && (
                        <CopyProjectDataModal
                            setOpenModal={setOpenCopyPlanModal}
                            source={project}
                            copyDataType="plan"
                        />
                    )}
                    <CopyButton
                        onClick={() => setOpenCopyPlanModal(true)}
                        buttonClassName=""
                        className="project-plan-copy-button"
                        tooltip={t('Copy plan')}
                        enabled
                    />
                </div>
            )}
            <EditableStageList rt={rt} />
        </div>
    )
})

import React from 'react'
import './Buttons.css'
import { useTranslation } from 'react-i18next'
import ReactTooltip from 'react-tooltip'
import _1SecLeftButton from './1SecLeftButton.gif'
import PreviousButton from './PreviousButton.gif'
import NextButton from './NextButton.gif'
import _1SecRightButton from './1SecRightButton.gif'
import _PlayFromBeginningButton from './PlayFromBeginningButton.gif'
import { RootConsumer } from '../app/RootContext'
import {
    ReferencesIcon,
    ApproveIcon,
    DisapproveIcon,
    ArrowsIcon,
    PencilIcon,
    ConsultantIcon,
    EditDifficultyIcon,
    CircleIcon,
    ForwardIcon,
    DbsPlayIcon,
    EditIcon,
    ClipboardIcon,
    GoBackIcon,
    GoForwardIcon,
    CheckIcon,
    CogIcon,
    CloneIcon,
    StarIcon,
    CopyIcon,
    FileUploadIcon,
    DownloadIcon,
    HeadsetIcon
} from './Icons'

import { docsUrl } from '../app/slttAvtt'
import { ScissorsIcon } from './HeroIcons'

function enable(cns = '', enabled = false) {
    return enabled ? cns : `${cns} sl-button-disabled`
}
function enableImg(cns = '', enabled = false) {
    return enabled ? cns : `${cns} sl-image-button-disabled`
}

/**
 * In order to get the link to the help system to work when SlttHelpTooltip
 * was used in a <MenuItem> I had to change the <a> to call this function
 * on click. The normal click handler was disabled (somehow!?) by the
 * MenuItem.
 */
const openInNewTab = (url: string) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
}

interface FAButtonProps {
    className: string
    onClick: (e?: any) => void
    tooltip?: string
    enabled?: boolean
    buttonClassName?: string
    selectionPresent?: boolean
    tourSelector?: string
}

// className must include the icon selector, e.g. fa-arrow-left
export const FAButton = ({ tooltip, enabled, onClick, className }: FAButtonProps) => {
    return (
        <span>
            <span
                className={enable(`sl-fa-button fas ${className}`, enabled)}
                onClick={() => enabled && onClick()}
                data-toggle="tooltip"
                title={tooltip}
            />
        </span>
    )
}

interface SlttHelpTooltipProps {
    tooltip: string
    id: string
}

export const SlttHelpTooltip = ({ tooltip, id }: SlttHelpTooltipProps) => {
    return (
        <ReactTooltip id={id} backgroundColor="gray" effect="solid" delayShow={1500} delayHide={500} clickable>
            {tooltip}
            <a onClick={() => openInNewTab(`${docsUrl}/${id}`)} target="_blank">
                <span className="sl-help-icon fas fa-question-circle" />
            </a>
        </ReactTooltip>
    )
}

export const HelpLinkWithMessage = ({ message, id }: { message?: string; id: string }) => {
    const link = (
        <a onClick={() => openInNewTab(`${docsUrl}/details/${id}`)} target="_blank">
            <span className="help-link fas fa-question-circle" />
        </a>
    )

    return message === undefined ? (
        <span>{link}</span>
    ) : (
        <p>
            {message}
            {link}
        </p>
    )
}

export const RecordButton = ({ enabled, onClick, className, tooltip, selectionPresent }: FAButtonProps) => {
    let _className = className
    if (selectionPresent) _className += ' sl-record-selection-button'

    return (
        <RootConsumer>
            {() => {
                // let className = classNames("et-right", "fa", "fa-2x", "fa-circle", "fa-fw", "text-danger", "video-up1",
                return (
                    <span
                        className={enable(`${_className} sl-fa-button fas fa-circle fa-fw`, enabled)}
                        onClick={(e) => {
                            if (enabled && onClick) {
                                onClick(e.shiftKey)
                            }
                        }}
                        data-toggle="tooltip"
                        title={tooltip}
                    />
                )
            }}
        </RootConsumer>
    )
}

export const RecordButtonWithLabel = ({
    enabled,
    onClick,
    buttonClassName = '',
    className,
    tooltip,
    selectionPresent
}: FAButtonProps) => {
    let _className = className
    if (selectionPresent) _className += ' sl-record-selection-button'

    return (
        <button
            type="button"
            className={`${buttonClassName} center-align-flex wraparound-button`}
            onClick={(e) => enabled && onClick(e)}
        >
            <span
                className={enable(`${_className} sl-fa-button fas fa-circle`, enabled)}
                data-toggle="tooltip"
                title={tooltip}
            />
            {'<Space>'}
        </button>
    )
}

export const StopButton = ({ enabled, onClick, className, tooltip }: FAButtonProps) => {
    return (
        <span
            className={enable(`${className} sl-fa-button sl-stop-button clickable fas fa-fw fa-stop`, enabled)}
            onClick={() => enabled && onClick()}
            data-id="stop-recording-button"
            data-toggle="tooltip"
            title={tooltip}
        />
    )
}

export const StopButtonWithLabel = ({ enabled, onClick, className, tooltip }: FAButtonProps) => {
    return (
        <button type="button" className="center-align-flex wraparound-button" onClick={(e) => enabled && onClick(e)}>
            <span
                className={enable(`${className} sl-fa-button sl-stop-button fas fa-stop`, enabled)}
                data-toggle="tooltip"
                title={tooltip}
            />
            {'<Enter>'}
        </button>
    )
}

export const PlayButton = ({ enabled, onClick, className, tooltip, selectionPresent }: FAButtonProps) => {
    let _className = enable(`${className} sl-play-button sl-fa-button fas fa-fw fa-play`, enabled)
    if (selectionPresent) _className += ' sl-selection-present'

    return (
        <span
            className={_className}
            onClick={(event) => enabled && onClick(event)}
            data-id="play-button"
            data-toggle="tooltip"
            title={tooltip}
        />
    )
}

export const PlayFromBeginningButton = ({ onClick, className }: FAButtonProps) => {
    const { t } = useTranslation()
    const tooltip = t('Play from timeline cursor')

    return (
        <RootConsumer>
            {() => (
                <span>
                    <img
                        className={`${className} sl-play-button`}
                        src={_PlayFromBeginningButton}
                        onClick={() => onClick()}
                        alt={tooltip}
                        title={tooltip}
                    />
                </span>
            )}
        </RootConsumer>
    )
}

export const PauseButton = ({ enabled, onClick, className, tooltip }: FAButtonProps) => {
    return (
        <span
            className={enable(`${className} sl-pause-button sl-fa-button fas fa-fw fa-pause`, enabled)}
            onClick={() => enabled && onClick()}
            data-id="pause-button"
            data-toggle="tooltip"
            title={tooltip}
        />
    )
}

export const PauseButtonWithLabel = ({ enabled, onClick, buttonClassName = '', className, tooltip }: FAButtonProps) => {
    return (
        <button
            type="button"
            className={`${buttonClassName} center-align-flex wraparound-button`}
            onClick={(e) => enabled && onClick(e)}
        >
            <span
                className={enable(`${className} sl-pause-button sl-fa-button fas fa-pause`, enabled)}
                data-toggle="tooltip"
                title={tooltip}
            />
            {'<Space>'}
        </button>
    )
}

interface SlttHelpProps {
    tooltip?: string
    id: string
    children: React.ReactNode
    place: string
}

//    color: ${props => props.enabled ? 'red' : 'lightgrey'};

export const SlttHelp = ({ tooltip = '', id, children, place }: SlttHelpProps) => {
    const _id = `details/${id}`
    return (
        <span data-tip data-for={_id} data-place={place}>
            {children}
            <SlttHelpTooltip id={_id} tooltip={tooltip} />
        </span>
    )
}

export const CreateNoteButton = ({ enabled, onClick }: Omit<FAButtonProps, 'className'>) => {
    const { t } = useTranslation()

    const tooltip = t('Create note at current location.')
    const id = 'notes.html'

    return (
        <SlttHelp {...{ id, tooltip }} place="bottom">
            <span
                className={enable('sl-fa-button sl-create-note-button fas fa-comment', enabled)}
                onClick={() => enabled && onClick()}
                data-tip
                data-for={id}
            />
        </SlttHelp>
    )
}

export const ZoomInTimelineButton = ({ enabled, onClick }: Omit<FAButtonProps, 'className'>) => {
    const { t } = useTranslation()

    return (
        <span
            className={enable('sl-fa-button sl-zoom-in-timeline-button fas fa-plus', enabled)}
            onClick={() => enabled && onClick()}
            data-id="zoom-in-timeline-button"
            data-toggle="tooltip"
            title={t('Zoom in timeline.')}
        />
    )
}

export const ZoomOutTimelineButton = ({ enabled, onClick }: Omit<FAButtonProps, 'className'>) => {
    const { t } = useTranslation()

    return (
        <span
            className={enable('sl-fa-button sl-zoom-out-timeline-button fas fa-minus', enabled)}
            onClick={() => enabled && onClick()}
            data-id="zoom-out-timeline-button"
            data-toggle="tooltip"
            title={t('Zoom out timeline.')}
        />
    )
}

export const TrashButton = ({ onClick, tooltip, buttonClassName = '', className, enabled }: FAButtonProps) => {
    return (
        <button type="button" className={`wraparound-button ${buttonClassName}`} onClick={(e) => enabled && onClick(e)}>
            <span
                className={enable(`${className} sl-fa-button fas fa-trash-alt`, enabled)}
                data-id="trash-button"
                data-toggle="tooltip"
                title={tooltip}
            />
        </button>
    )
}

export const PlayTourStepButton = ({ enabled, onClick }: Omit<FAButtonProps, 'className'>) => {
    return (
        <RootConsumer>
            {() => {
                const tooltip = enabled
                    ? 'Click to play sign language explanation or drag and drop an .mp4 file to supply an explanation in your sign language.'
                    : 'Drag and drop an .mp4 file here to supply an explanation in your sign language.'

                return (
                    <span
                        className={enable('sl-fa-button sl-play-tour-step-button fas fa-sign-language', enabled)}
                        onClick={() => enabled && onClick()}
                        data-toggle="tooltip"
                        title={tooltip}
                    />
                )
            }}
        </RootConsumer>
    )
}

export const DeletePatchVideoButton = ({ onClick, enabled }: Omit<FAButtonProps, 'className'>) => {
    const { t } = useTranslation()

    return (
        <RootConsumer>
            {() => {
                const tooltip = t('recordingDeletePatch')
                return (
                    <span
                        className={enable(
                            'delete-patch-video-button clickable sl-fa-button fas fa-fw fa-trash',
                            enabled
                        )}
                        onClick={() => enabled && onClick()}
                        data-toggle="tooltip"
                        title={tooltip}
                    />
                )
            }}
        </RootConsumer>
    )
}

export const CreateSegmentButton = ({ enabled, onClick }: Omit<FAButtonProps, 'className'>) => {
    const { t } = useTranslation()

    return (
        <RootConsumer>
            {() => {
                const tooltip = t('recordingCreateSegmentAt')

                return (
                    <span>
                        <span
                            className={enable('sl-fa-button sl-create-segment-button fas fa-tag', enabled)}
                            onClick={() => enabled && onClick()}
                            data-toggle="tooltip"
                            title={tooltip}
                        />
                        {/* 
                        ??? should we support a dropdown menu to select a segment?
                        <Dropdown id="dropdown-select-segment">
                            <Dropdown.Toggle bsStyle="default" />
                            <Dropdown.Menu>
                                <MenuItem eventKey="1">Luke 15.11-24</MenuItem>
                                <MenuItem eventKey="2">Luke 15.25-31</MenuItem>
                            </Dropdown.Menu>
                        </Dropdown> */}
                    </span>
                )
            }}
        </RootConsumer>
    )
}

export const EditSegmentButton = ({ enabled, onClick, tooltip, tourSelector, className }: FAButtonProps) => {
    let className2 = `sl-fa-button sl-edit-segment-button fas fa-edit ${className}`

    className2 = enable(className2, enabled)
    if (tourSelector && tourSelector.startsWith('.sl-edit-segment')) {
        className2 += ' sl-edit-segment-button-tour'
    }

    return (
        <span>
            <span className={className2} onClick={() => enabled && onClick()} data-toggle="tooltip" title={tooltip} />
        </span>
    )
}

export const DictateSegmentButton = ({ enabled, onClick, tooltip, tourSelector }: Omit<FAButtonProps, 'className'>) => {
    const className = `sl-fa-button sl-edit-segment-button sl-dictate-segment-caption-button fas fa-microphone`

    let className2 = enable(className, enabled)
    if (tourSelector && tourSelector.startsWith('.sl-dictate-segment')) {
        className2 += ' sl-edit-segment-button-tour'
    }

    return (
        <span>
            <span className={className2} onClick={() => enabled && onClick()} data-toggle="tooltip" title={tooltip} />
        </span>
    )
}

export const OkEditSegmentButton = ({ enabled, onClick }: Omit<FAButtonProps, 'className'>) => {
    const { t } = useTranslation()

    return (
        <RootConsumer>
            {() => {
                const tooltip = t('Save edits.')

                return (
                    <span>
                        <span
                            className={enable('sl-fa-button sl-ok-edit-segment-labels-button fas fa-check', enabled)}
                            onClick={() => enabled && onClick()}
                            data-toggle="tooltip"
                            title={tooltip}
                        />
                    </span>
                )
            }}
        </RootConsumer>
    )
}

export const CancelEditSegmentButton = ({ enabled, onClick }: Omit<FAButtonProps, 'className'>) => {
    const { t } = useTranslation()

    return (
        <RootConsumer>
            {() => {
                const tooltip = t('Cancel edits')

                return (
                    <span>
                        <span
                            className={enable(
                                'sl-fa-button sl-cancel-edit-segment-labels-button far fa-times-circle',
                                enabled
                            )}
                            onClick={() => enabled && onClick()}
                            data-toggle="tooltip"
                            title={tooltip}
                        />
                    </span>
                )
            }}
        </RootConsumer>
    )
}

export const DeleteSegmentButton = ({ tooltip, enabled, onClick }: Omit<FAButtonProps, 'className'>) => {
    return (
        <span
            className={enable('sl-fa-button sl-delete-segment-button fas fa-trash-alt', enabled)}
            onClick={() => enabled && onClick()}
            data-toggle="tooltip"
            title={tooltip}
        />
    )
}

export const PreviousSegmentButton = ({ tooltip, enabled, onClick }: Omit<FAButtonProps, 'className'>) => {
    return (
        <span>
            <span
                className={enable('sl-fa-button sl-previous-segment-button fas fa-arrow-left', enabled)}
                onClick={() => enabled && onClick()}
                data-toggle="tooltip"
                title={tooltip}
            />
        </span>
    )
}

export const NextSegmentButton = ({ tooltip, enabled, onClick }: Omit<FAButtonProps, 'className'>) => {
    return (
        <span>
            <span
                className={enable('sl-fa-button sl-next-segment-button fas fa-arrow-right', enabled)}
                onClick={() => enabled && onClick()}
                data-toggle="tooltip"
                title={tooltip}
            />
        </span>
    )
}

export const SegmentLabelButton = ({ enabled, className, tooltip, onClick }: FAButtonProps) => {
    return (
        <span
            className={enable(`${className} sl-fa-button fas fa-tag`, enabled)}
            data-toggle="tooltip"
            title={tooltip}
            onClick={(e) => enabled && onClick(e)}
        />
    )
}

export const SegmentReferencesButton = ({ enabled, className, tooltip, onClick }: FAButtonProps) => {
    return (
        <button type="button" className="wraparound-button" onClick={(e) => enabled && onClick(e)}>
            <ReferencesIcon className={enable(className, enabled)} tooltip={tooltip} />
        </button>
    )
}

export const DeleteButton = ({ className, tooltip, enabled, onClick }: FAButtonProps) => {
    return (
        <button
            type="button"
            className="wraparound-button"
            onClick={(e) => enabled && onClick(e)}
            data-toggle="tooltip"
            title={tooltip}
        >
            <span className={enable(`sl-fa-button fas fa-trash-alt ${className}`, enabled)} />
        </button>
    )
}

interface TimeAdjustmentButtonProps {
    cmd: string
    enabled: boolean
    adjustCurrentTime: (adjustment: number) => void
    showShortcut: boolean
}

export const PreviousSecond = ({ cmd, enabled, adjustCurrentTime, showShortcut }: TimeAdjustmentButtonProps) => {
    const { t } = useTranslation()

    let title = ''
    if (showShortcut) {
        title = `${t('Go back 1 second.')} (${cmd}+\u2190)`
    } else {
        title = t('Go back 1 second.')
    }
    return (
        <span>
            <img
                className={enableImg('sl-adjust-current-time-button', enabled)}
                src={_1SecLeftButton}
                onClick={() => enabled && adjustCurrentTime(-1.0)}
                alt={title}
                title={title}
            />
        </span>
    )
}

export const PreviousFrame = ({ cmd, enabled, adjustCurrentTime, showShortcut }: TimeAdjustmentButtonProps) => {
    const { t } = useTranslation()

    let title = ''
    if (showShortcut) {
        title = `${t('Go back 1 frame.')} (${cmd}+\u2190)`
    } else {
        title = t('Go back 1 frame.')
    }
    return (
        <span>
            <img
                className={enableImg('sl-adjust-current-time-button', enabled)}
                src={PreviousButton}
                onClick={() => enabled && adjustCurrentTime(-2.0 / 30.0)}
                alt={title}
                title={title}
            />
        </span>
    )
}

export const NextFrame = ({ cmd, enabled, adjustCurrentTime, showShortcut }: TimeAdjustmentButtonProps) => {
    const { t } = useTranslation()

    let title = ''
    if (showShortcut) {
        title = `${t('Go forward 1 frame.')} (${cmd}+\u2192)`
    } else {
        title = t('Go forward 1 frame.')
    }
    return (
        <span>
            <img
                className={enableImg('sl-adjust-current-time-button', enabled)}
                src={NextButton}
                data-id="forward-one-frame"
                onClick={() => enabled && adjustCurrentTime(2.0 / 30.0)}
                alt={title}
                title={title}
            />
        </span>
    )
}

export const NextSecond = ({ cmd, enabled, adjustCurrentTime, showShortcut }: TimeAdjustmentButtonProps) => {
    const { t } = useTranslation()

    let title = ''
    if (showShortcut) {
        title = `${t('Go forward 1 second.')} (${cmd}+\u2192)`
    } else {
        title = t('Go forward 1 second.')
    }
    return (
        <span>
            <img
                className={enableImg('sl-adjust-current-time-button', enabled)}
                src={_1SecRightButton}
                onClick={() => enabled && adjustCurrentTime(1.0)}
                alt={title}
                title={title}
            />
        </span>
    )
}
interface AdjustCurrentTimeButtonsProps {
    enabled: boolean
    adjustCurrentTime: (delta: number) => void
    includeSeconds: boolean
}

export const AdjustCurrentTimeButtons = ({
    enabled,
    adjustCurrentTime,
    includeSeconds
}: AdjustCurrentTimeButtonsProps) => {
    const cmd = navigator.platform.startsWith('Mac') ? '\u2318' : 'Ctrl'

    return (
        <span className="sl-adjust-current-time-buttons">
            {includeSeconds && (
                <PreviousSecond cmd="Shift" enabled={enabled} adjustCurrentTime={adjustCurrentTime} showShortcut />
            )}
            <PreviousFrame cmd={cmd} enabled={enabled} adjustCurrentTime={adjustCurrentTime} showShortcut />
            <NextFrame cmd={cmd} enabled={enabled} adjustCurrentTime={adjustCurrentTime} showShortcut />
            {includeSeconds && (
                <NextSecond cmd="Shift" enabled={enabled} adjustCurrentTime={adjustCurrentTime} showShortcut />
            )}
        </span>
    )
}

interface GlossPositionAdjustTimeButtonsProps {
    previousFrameEnabled: boolean
    nextFrameEnabled: boolean
    adjustCurrentTime: (adjustment: number) => void
}

export const GlossPositionAdjustTimeButtons = ({
    previousFrameEnabled,
    nextFrameEnabled,
    adjustCurrentTime
}: GlossPositionAdjustTimeButtonsProps) => {
    const cmd = navigator.platform.startsWith('Mac') ? '\u2318' : 'Ctrl'

    return (
        <span className="sl-adjust-current-time-buttons">
            <PreviousFrame
                cmd={cmd}
                enabled={previousFrameEnabled}
                adjustCurrentTime={adjustCurrentTime}
                showShortcut
            />
            <NextFrame cmd={cmd} enabled={nextFrameEnabled} adjustCurrentTime={adjustCurrentTime} showShortcut />
        </span>
    )
}

interface SegmentPositionAdjustTimeButtonsProps {
    previousSecondEnabled: boolean
    previousFrameEnabled: boolean
    nextSecondEnabled: boolean
    nextFrameEnabled: boolean
    adjustCurrentTime: (adjustment: number) => void
}

export const SegmentPositionAdjustTimeButtons = ({
    previousSecondEnabled,
    previousFrameEnabled,
    nextSecondEnabled,
    nextFrameEnabled,
    adjustCurrentTime
}: SegmentPositionAdjustTimeButtonsProps) => {
    const cmd = navigator.platform.startsWith('Mac') ? '\u2318' : 'Ctrl'

    return (
        <RootConsumer>
            {() => (
                <span className="sl-adjust-current-time-buttons">
                    <PreviousSecond
                        cmd="Shift"
                        enabled={previousSecondEnabled}
                        adjustCurrentTime={adjustCurrentTime}
                        showShortcut
                    />
                    <PreviousFrame
                        cmd={cmd}
                        enabled={previousFrameEnabled}
                        adjustCurrentTime={adjustCurrentTime}
                        showShortcut
                    />
                    <NextFrame
                        cmd={cmd}
                        enabled={nextFrameEnabled}
                        adjustCurrentTime={adjustCurrentTime}
                        showShortcut
                    />
                    <NextSecond
                        cmd="Shift"
                        enabled={nextSecondEnabled}
                        adjustCurrentTime={adjustCurrentTime}
                        showShortcut
                    />
                </span>
            )}
        </RootConsumer>
    )
}

interface AdjustNoteMarkerButtonsProps {
    enabled: boolean
    adjustTime: (delta: number) => void
}

export const AdjustNoteMarkerButtons = ({ enabled, adjustTime }: AdjustNoteMarkerButtonsProps) => {
    const { t } = useTranslation()

    const backOneSecondTooltip = t('Go back 1 second.')
    const backOneFrameTooltip = t('Go back 1 frame')
    const forwardOneFrameTooltip = t('Go forward 1 frame')
    const forwardOneSecondTooltip = t('Go forward 1 second.')
    return (
        <RootConsumer>
            {() => (
                <span>
                    <img
                        className={enableImg('sl-adjust-current-time-button', enabled)}
                        src={_1SecLeftButton}
                        onClick={() => enabled && adjustTime(-1.0)}
                        alt={backOneSecondTooltip}
                        title={backOneSecondTooltip}
                    />
                    <img
                        className={enableImg('sl-adjust-current-time-button', enabled)}
                        src={PreviousButton}
                        onClick={() => enabled && adjustTime(-0.05)}
                        alt={backOneFrameTooltip}
                        title={backOneFrameTooltip}
                    />
                    <img
                        className={enableImg('sl-adjust-current-time-button', enabled)}
                        src={NextButton}
                        data-id="forward-one-frame"
                        onClick={() => enabled && adjustTime(0.05)}
                        alt={forwardOneFrameTooltip}
                        title={forwardOneFrameTooltip}
                    />
                    <img
                        className={enableImg('sl-adjust-current-time-button', enabled)}
                        src={_1SecRightButton}
                        onClick={() => enabled && adjustTime(1.0)}
                        alt={forwardOneSecondTooltip}
                        title={forwardOneSecondTooltip}
                    />
                </span>
            )}
        </RootConsumer>
    )
}

export const VideoSegmentStartingPointButton = ({ tooltip, className, enabled, onClick }: Partial<FAButtonProps>) => {
    return (
        <span
            className={enable(
                `${className} ${onClick && 'clickable'} segment-button sl-fa-button fas fa-step-backward`,
                enabled
            )}
            onClick={() => {
                if (enabled && onClick) {
                    onClick()
                }
            }}
            data-toggle="tooltip"
            title={tooltip}
        />
    )
}

export const VideoSegmentEndingPointButton = ({ tooltip, className, enabled, onClick }: Partial<FAButtonProps>) => {
    return (
        <span
            className={enable(
                `${className} ${onClick && 'clickable'} segment-button sl-fa-button fas fa-step-forward`,
                enabled
            )}
            onClick={() => enabled && onClick && onClick()}
            data-toggle="tooltip"
            title={tooltip}
        />
    )
}

export const DbsPlayButton = ({ onClick, className, tooltip, enabled }: FAButtonProps) => {
    return (
        <button type="button" className="wraparound-button" onClick={(e) => enabled && onClick(e)}>
            <DbsPlayIcon className={enable(className, enabled)} tooltip={tooltip} />
        </button>
    )
}

export const SooSLLinkButton = ({ onClick, enabled }: FAButtonProps) => {
    return (
        <RootConsumer>
            {() => {
                const tooltip = 'Link term to SooSL sign'

                return (
                    <span
                        className={enable('sl-fa-button sl-soosl-link-button fas fa-link', enabled)}
                        onClick={() => onClick()}
                        data-toggle="tooltip"
                        title={tooltip}
                    />
                )
            }}
        </RootConsumer>
    )
}

export const SooSLUnlinkButton = ({ onClick, enabled }: FAButtonProps) => {
    return (
        <RootConsumer>
            {() => {
                const tooltip = 'Remove link between term and SooSL sign.'

                return (
                    <span
                        className={enable('sl-fa-button sl-soosl-unlink-button fas fa-unlink', enabled)}
                        onClick={() => onClick()}
                        data-toggle="tooltip"
                        title={tooltip}
                    />
                )
            }}
        </RootConsumer>
    )
}

export const SooSLCancelLinkButton = ({ enabled, onClick }: FAButtonProps) => {
    return (
        <RootConsumer>
            {() => {
                const tooltip = 'Cancel adding SooSL link.'

                return (
                    <span>
                        <span
                            className={enable('sl-fa-button sl-soosl-cancel-link-button far fa-times-circle', enabled)}
                            onClick={() => enabled && onClick()}
                            data-toggle="tooltip"
                            title={tooltip}
                        />
                    </span>
                )
            }}
        </RootConsumer>
    )
}

export const PaneCloseButton = ({ enabled, onClick, tooltip, className }: FAButtonProps) => {
    return (
        <button
            type="button"
            className="wraparound-button"
            onClick={(e) => enabled && onClick(e)}
            data-toggle="tooltip"
            title={tooltip}
        >
            <span className={enable(`${className} sl-fa-button far fa-window-close`, enabled)} />
        </button>
    )
}

export const EditDifficultyButton = ({ enabled, onClick, className, tooltip }: FAButtonProps) => {
    return (
        <button type="button" className="wraparound-button" onClick={(e) => enabled && onClick(e)}>
            <EditDifficultyIcon className={className} tooltip={tooltip} />
        </button>
    )
}

export const LockButton = ({ enabled, onClick, className, tooltip }: FAButtonProps) => {
    return (
        <button type="button" className="wraparound-button" onClick={(e) => enabled && onClick(e)}>
            <span
                className={enable(`${className} sl-fa-button fas fa-lock`, enabled)}
                data-id="lock-button"
                data-toggle="tooltip"
                title={tooltip}
            />
        </button>
    )
}

export const UnlockButton = ({ enabled, onClick, className, tooltip }: FAButtonProps) => {
    return (
        <button type="button" className="wraparound-button" onClick={(e) => enabled && onClick(e)}>
            <span
                className={enable(`${className} sl-fa-button fas fa-lock-open`, enabled)}
                data-id="unlock-button"
                data-toggle="tooltip"
                title={tooltip}
            />
        </button>
    )
}

interface NoteMarkerProps extends Omit<FAButtonProps, 'onClick'> {
    style?: any
}

export const SquareNoteMarker = ({ className, enabled, style, tooltip }: NoteMarkerProps) => {
    return (
        <span
            className={enable(`${className} note-marker sl-fa-button fas fa-square`, enabled)}
            data-id="square-note-marker"
            style={style}
            title={tooltip}
        />
    )
}

export const CircleNoteMarker = ({ className, enabled, style, tooltip }: NoteMarkerProps) => {
    return (
        <span
            className={enable(`${className} note-marker sl-fa-button fas fa-circle`, enabled)}
            data-id="circle-note-marker"
            style={style}
            title={tooltip}
        />
    )
}

export const TriangleNoteMarker = ({ className, enabled, style, tooltip }: NoteMarkerProps) => {
    return (
        <span
            className={enable(`${className} note-marker sl-fa-button fas fa-play fa-rotate-270`, enabled)}
            data-id="triangle-note-marker"
            style={style}
            title={tooltip}
        />
    )
}

export const UnresolvedNoteMarker = ({ className, style, tooltip }: NoteMarkerProps) => {
    return (
        <span
            className={enable(`${className} sl-play-button sl-fa-button far fa-circle`, true)}
            data-id="unresolved-note-marker"
            style={style}
            title={tooltip}
        />
    )
}

interface ColorSquareProps {
    color: string
    width: string
    height: string
}

export const ColorSquare = ({ color, width, height }: ColorSquareProps) => {
    return (
        <span
            style={{
                display: 'inline-block',
                backgroundColor: color,
                border: '1px solid black',
                width,
                height,
                marginRight: '5px'
            }}
        />
    )
}

export const BarsIcon = ({ className, tooltip }: Omit<FAButtonProps, 'onClick'>) => {
    return <span className={`${className} fas sl-fa-button fa-bars`} data-toggle="tooltip" title={tooltip} />
}

export const ClearNotificationsButton = ({ className, onClick, tooltip }: FAButtonProps) => {
    return (
        <span
            className={`${className} clickable fas fa-fw sl-fa-button fa-unlink`}
            data-id="clear-visible-notifications"
            data-toggle="tooltip"
            title={tooltip}
            onClick={() => onClick()}
        />
    )
}

export const DownArrowButton = ({ className, onClick, tooltip }: FAButtonProps) => {
    return (
        <button
            type="button"
            className="wraparound-button"
            onClick={(e) => onClick(e)}
            data-toggle="tooltip"
            title={tooltip}
        >
            <span className={`${className} fas fa-fw fa-arrow-circle-down`} />
        </button>
    )
}

export const ReferencesButton = ({ className, tooltip, onClick }: FAButtonProps) => {
    return (
        <button type="button" className="wraparound-button" onClick={(e) => onClick(e)}>
            <ReferencesIcon className={className} tooltip={tooltip} />
        </button>
    )
}

export const OKButton = ({ enabled, onClick, buttonClassName = '', className, tooltip }: FAButtonProps) => {
    return (
        <button
            type="button"
            className={`${buttonClassName} wraparound-button`}
            onClick={(e) => enabled && onClick(e)}
            data-toggle="tooltip"
            title={tooltip}
        >
            <span
                className={enable(`${className} sl-fa-button fas fa-check`, enabled)}
                data-toggle="tooltip"
                title={tooltip}
            />
        </button>
    )
}

export const CancelButton = ({ enabled, onClick, className, tooltip }: FAButtonProps) => {
    return (
        <button
            type="button"
            className="wraparound-button"
            onClick={(e) => enabled && onClick(e)}
            data-toggle="tooltip"
            title={tooltip}
        >
            <span
                className={enable(`${className} sl-fa-button far fa-times-circle`, enabled)}
                data-toggle="tooltip"
                title={tooltip}
            />
        </button>
    )
}

export const CancelButtonWithLabel = ({
    enabled,
    onClick,
    buttonClassName = '',
    className,
    tooltip
}: FAButtonProps) => {
    return (
        <button
            type="button"
            className={`${buttonClassName} center-align-flex wraparound-button`}
            onClick={(e) => enabled && onClick(e)}
        >
            <span
                className={enable(`${className} sl-fa-button far fa-times-circle`, enabled)}
                data-toggle="tooltip"
                title={tooltip}
            />
            {'<Esc>'}
        </button>
    )
}

export const ApproveButton = ({ enabled, onClick, className, tooltip }: FAButtonProps) => {
    return (
        <button type="button" className="wraparound-button" onClick={(e) => enabled && onClick(e)}>
            <ApproveIcon className={enable(className, enabled)} tooltip={tooltip} />
        </button>
    )
}

export const PencilButton = ({ enabled, onClick, className, tooltip }: FAButtonProps) => {
    return (
        <button type="button" className="wraparound-button" onClick={(e) => enabled && onClick(e)}>
            <PencilIcon className={enable(className, enabled)} tooltip={tooltip} />
        </button>
    )
}

export const CloneButton = ({ enabled, onClick, className, tooltip }: FAButtonProps) => {
    return (
        <button type="button" className="wraparound-button" onClick={(e) => enabled && onClick(e)}>
            <CloneIcon className={enable(className, enabled)} tooltip={tooltip} />
        </button>
    )
}

export const GoBackButton = ({ enabled, onClick, className, tooltip }: FAButtonProps) => {
    return (
        <button type="button" className="wraparound-button" onClick={(e) => enabled && onClick(e)}>
            <GoBackIcon className={enable(className, enabled)} tooltip={tooltip} />
        </button>
    )
}

export const GoForwardButton = ({ enabled, onClick, className, tooltip }: FAButtonProps) => {
    return (
        <button type="button" className="wraparound-button" onClick={(e) => enabled && onClick(e)}>
            <GoForwardIcon className={enable(className, enabled)} tooltip={tooltip} />
        </button>
    )
}

export const EditButton = ({ enabled, onClick, className, tooltip }: FAButtonProps) => {
    return (
        <button type="button" className="wraparound-button" onClick={(e) => enabled && onClick(e)}>
            <EditIcon className={enable(className, enabled)} tooltip={tooltip} />
        </button>
    )
}

export const DisapproveButton = ({ enabled, onClick, className, tooltip }: FAButtonProps) => {
    return (
        <button type="button" className="wraparound-button" onClick={(e) => enabled && onClick(e)}>
            <DisapproveIcon className={enable(className, enabled)} tooltip={tooltip} />
        </button>
    )
}

interface ExpandButtonProps {
    className: string
    tooltip: string
    src: string
}

export const ExpandButton = ({ src, className, tooltip }: ExpandButtonProps) => {
    return (
        <a href={src} target="_blank" aria-label={tooltip} rel="noreferrer">
            <span className="sr-only">Opens in a new window</span>
            <ArrowsIcon aria-hidden="true" className={className} tooltip={tooltip} />
        </a>
    )
}

export const ConsultantButton = ({ enabled, onClick, className, tooltip }: FAButtonProps) => {
    return (
        <button type="button" className="wraparound-button" onClick={(e) => enabled && onClick(e)}>
            <ConsultantIcon className={className} tooltip={tooltip} />
        </button>
    )
}

interface CircleButtonProps extends FAButtonProps {
    style: any
}

export const CircleButton = ({ onClick, className, style, tooltip = '' }: CircleButtonProps) => {
    return (
        <button type="button" className="wraparound-button" onClick={(e) => onClick(e)}>
            <CircleIcon {...{ className, style, tooltip }} />
        </button>
    )
}

export const DoublePlayButton = ({ onClick, className, tooltip, enabled, selectionPresent }: FAButtonProps) => {
    let _className = 'wraparound-button'
    if (selectionPresent) _className += ' sl-selection-present'

    return (
        <button type="button" className={_className} onClick={(e) => enabled && onClick(e)}>
            <ForwardIcon className={enable(className, enabled)} tooltip={tooltip} />
        </button>
    )
}

export const UndoButton = ({ onClick, buttonClassName = '', className, tooltip, enabled }: FAButtonProps) => {
    return (
        <button type="button" className={`${buttonClassName} wraparound-button`} onClick={(e) => enabled && onClick(e)}>
            <span className={`${className} sl-fa-button fas fa-undo-alt`} data-toggle="tooltip" title={tooltip} />
        </button>
    )
}

export const AddButton = ({ className, enabled, onClick, tooltip }: FAButtonProps) => {
    return (
        <button type="button" className="wraparound-button" onClick={(e) => enabled && onClick(e)}>
            <span
                className={enable(`${className} sl-fa-button far fa-plus-square`, enabled)}
                data-toggle="tooltip"
                title={tooltip}
            />
        </button>
    )
}

export const ClipboardButton = ({ buttonClassName = '', className, enabled, onClick, tooltip }: FAButtonProps) => {
    return (
        <button type="button" className={`${buttonClassName} wraparound-button`} onClick={(e) => enabled && onClick(e)}>
            <ClipboardIcon className={enable(className, enabled)} tooltip={tooltip} />
        </button>
    )
}

export const SyncButton = ({ className, enabled, onClick, tooltip }: FAButtonProps) => {
    return (
        <button type="button" className="wraparound-button" onClick={(e) => enabled && onClick(e)}>
            <span
                className={enable(`${className} sl-fa-button fas fa-chevron-down`, enabled)}
                data-toggle="tooltip"
                title={tooltip}
            />
        </button>
    )
}

interface EyeButtonProps extends Omit<FAButtonProps, 'onClick'> {
    value: boolean
    setValue: (value: boolean) => void
}

export const EyeButton = ({ className, enabled, tooltip, value, setValue }: EyeButtonProps) => {
    if (value) {
        return (
            <button type="button" className="wraparound-button" onClick={() => enabled && setValue && setValue(false)}>
                <span
                    className={enable(`${className} sl-fa-button far fa-eye`, enabled)}
                    data-toggle="tooltip"
                    title={tooltip}
                />
            </button>
        )
    }

    return (
        <button type="button" className="wraparound-button" onClick={() => enabled && setValue && setValue(true)}>
            <span
                className={enable(`${className} sl-fa-button far fa-eye-slash`, enabled)}
                data-toggle="tooltip"
                title={tooltip}
            />
        </button>
    )
}

export const CheckButton = ({ onClick, className, tooltip, enabled }: FAButtonProps) => {
    return (
        <button type="button" className="wraparound-button" onClick={() => enabled && onClick()}>
            <CheckIcon className={enable(className, enabled)} tooltip={tooltip} />
        </button>
    )
}

export const SettingsButton = ({ onClick, className, tooltip, enabled }: FAButtonProps) => {
    return (
        <button type="button" className="wraparound-button" onClick={() => enabled && onClick()}>
            <CogIcon className={enable(className, enabled)} tooltip={tooltip} />
        </button>
    )
}

export const StarButton = ({ onClick, buttonClassName = '', className, tooltip, enabled }: FAButtonProps) => {
    return (
        <button type="button" className={`wraparound-button ${buttonClassName}`} onClick={() => enabled && onClick()}>
            <span className={enable(`${className} fa-fw fas fa-star`, enabled)} data-toggle="tooltip" title={tooltip} />
        </button>
    )
}

export const HollowStarButton = ({ onClick, buttonClassName = '', className, tooltip, enabled }: FAButtonProps) => {
    return (
        <button type="button" className={`wraparound-button ${buttonClassName}`} onClick={() => enabled && onClick()}>
            <span
                className={enable(`${className} sl-fa-button fa-fw far fa-star`, enabled)}
                data-toggle="tooltip"
                title={tooltip}
            />
        </button>
    )
}

export const KeyTermButton = ({ onClick, buttonClassName = '', className, tooltip, enabled }: FAButtonProps) => {
    return (
        <button type="button" className={`wraparound-button ${buttonClassName}`} onClick={(e) => enabled && onClick(e)}>
            <StarIcon className={enable(className, enabled)} tooltip={tooltip} />
        </button>
    )
}

export const UploadButton = ({ onClick, className, tooltip, enabled }: FAButtonProps) => {
    return (
        <button type="button" className="wraparound-button" onClick={(e) => enabled && onClick(e)}>
            <FileUploadIcon className={enable(className, enabled)} tooltip={tooltip} />
        </button>
    )
}

export const CopyButton = ({ onClick, buttonClassName = '', className, tooltip, enabled }: FAButtonProps) => {
    return (
        <button type="button" className={`wraparound-button ${buttonClassName}`} onClick={(e) => enabled && onClick(e)}>
            <CopyIcon className={enable(className, enabled)} tooltip={tooltip} />
        </button>
    )
}

export const CutButton = ({ onClick, className, tooltip, enabled }: FAButtonProps) => {
    return (
        <button type="button" className="wraparound-button" onClick={() => enabled && onClick()}>
            <ScissorsIcon className={enable(className, enabled)} tooltip={tooltip} />
        </button>
    )
}

export const ImageButton = ({ onClick, buttonClassName = '', className, tooltip, enabled }: FAButtonProps) => {
    return (
        <button type="button" className={`wraparound-button ${buttonClassName}`} onClick={() => enabled && onClick()}>
            <span
                className={enable(`${className} sl-fa-button fa-fw far fa-image`, enabled)}
                data-toggle="tooltip"
                title={tooltip}
            />
        </button>
    )
}

export const DownloadButton = ({ onClick, className, tooltip, enabled }: FAButtonProps) => {
    return (
        <button type="button" className="wraparound-button" onClick={() => enabled && onClick()}>
            <DownloadIcon className={enable(className, enabled)} tooltip={tooltip} />
        </button>
    )
}

export const RecordAudioNoteCommentButton = ({
    onClick,
    buttonClassName = '',
    className,
    tooltip,
    enabled
}: FAButtonProps) => {
    return (
        <button type="button" className={`${buttonClassName} wraparound-button`} onClick={() => enabled && onClick()}>
            <HeadsetIcon className={enable(className, enabled)} tooltip={tooltip} />
        </button>
    )
}

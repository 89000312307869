import React, { FC, useState } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import Modal from 'react-bootstrap/lib/Modal'

import { CancelEditSegmentButton, OkEditSegmentButton } from '../utils/Buttons'
import { GenericIcon, ReferencesIcon, TitleIcon, CogIcon } from '../utils/Icons'
import { ReferenceInput } from '../utils/ReferenceInput'
import { displayError } from '../utils/Errors'

import { Passage, PassageContentType } from '../../models3/Passage'
import { Root } from '../../models3/Root'

// change progress to use passage.references
// Permissions: difficulty (admin/consultant)
// add camera icon for passage name

interface IPassageNameInput {
    rt: Root
    passage: Passage
    passageName: string
    setPassageName: (name: string) => void
    nameError: string
    setNameError: (name: string) => void
    onEnter?: () => void
}

const PassageNameInput: FC<IPassageNameInput> = ({
    rt,
    passage,
    passageName,
    setPassageName,
    nameError,
    setNameError,
    onEnter
}) => {
    const { t } = useTranslation()

    function validateName(name: string) {
        if (passage.name === name) return ''

        if (!rt.portion) {
            return t('portionNotFound')
        }

        return rt.portion.checkNewPassageName(name)
    }

    function onChange(e: React.ChangeEvent<HTMLInputElement>) {
        const name = e.target.value
        setPassageName(name)
        setNameError(validateName(name))
    }

    return (
        <div className="passage-name-input">
            <div>
                <input
                    autoFocus
                    value={passageName}
                    onKeyDown={(e) => e.keyCode === 13 && onEnter && onEnter()}
                    onChange={onChange}
                />
                {nameError && <span className="passage-error"> {nameError}</span>}
            </div>
        </div>
    )
}

interface IPassageContentTypeInput {
    contentType: PassageContentType
    setContentType: (contentType: PassageContentType) => void
}

const PassageContentTypeInput: FC<IPassageContentTypeInput> = ({ contentType, setContentType }) => {
    const { t } = useTranslation()

    return (
        <select value={contentType} onChange={(e) => setContentType(e.target.value as PassageContentType)}>
            <option value="Introduction"> {t('Introduction')} </option>
            <option value="Translation"> {t('Translation')} </option>
            <option value="Other"> {t('Other')} </option>
            <option value="Nonpublishable"> {t('Nonpublishable')} </option>
            <option value="Introduction+Translation"> {t('Introduction + Translation')} </option>
            <option value="Introduction+Translation+Other"> {t('Introduction + Translation + Other')} </option>
        </select>
    )
}

interface IDifficultyInput {
    difficulty: number
    setDifficulty: (difficulty: number) => void
}

const DifficultyInput: FC<IDifficultyInput> = ({ difficulty, setDifficulty }) => {
    const { t } = useTranslation()
    const [value, setValue] = useState(difficulty.toString())

    return (
        <div>
            <CogIcon className="passage-difficulty-icon" tooltip={t('Difficulty for passage.')} />
            <input
                className="difficulty-input"
                required
                type="number"
                value={value}
                min={0}
                step={0.1}
                onChange={(e) => {
                    setDifficulty(parseFloat(e.target.value))
                    setValue(e.target.value)
                }}
            />
        </div>
    )
}

interface IPassageEditor {
    rt: Root
    passage: Passage
    setEditing: (editing: boolean) => void
}

const PassageEditor: FC<IPassageEditor> = ({ rt, passage, setEditing }) => {
    const { t } = useTranslation()
    const [passageName, setPassageName] = useState(passage.name)
    const [nameError, setNameError] = useState('')
    const [references, setReferences] = useState(passage.references)
    const [referencesError, setReferencesError] = useState(false)
    const [difficulty, setDifficulty] = useState(passage.difficulty)
    const [contentType, setContentType] = useState(passage.contentType)

    function save() {
        if (passage.name !== passageName && !nameError) {
            passage.setName(passageName).catch(displayError)
        }
        if (!referencesError) {
            passage
                .setReferences(references)
                .then(() => rt.setDbsRefs(rt.portion))
                .catch(displayError)
        }
        if (!isNaN(difficulty)) {
            passage.setDifficulty(difficulty).catch(displayError)
        }
        passage.setContentType(contentType).catch(displayError)
        setEditing(false)
    }

    return (
        <Modal style={{ top: '1%' }} show onHide={() => setEditing(false)} backdrop="static">
            <Modal.Header closeButton> {t('Passage')} </Modal.Header>
            <Modal.Body>
                <div className="passage-edit-row">
                    <TitleIcon className="passage-edit-icon" tooltip={t('Title')} />
                    <PassageNameInput
                        {...{ rt, passage, passageName, setPassageName, nameError, setNameError, onEnter: save }}
                    />
                </div>

                <div className="passage-edit-row">
                    <ReferencesIcon className="passage-edit-icon" tooltip={t('References for passage.')} />
                    <ReferenceInput
                        refInput={rt}
                        onEnter={save}
                        refs={references}
                        setRefs={setReferences}
                        errored={referencesError}
                        setErrored={setReferencesError}
                        includeGotoReferenceButton={false}
                    />
                </div>

                <div className="passage-edit-row">
                    <GenericIcon
                        className="passage-edit-icon fa-fw"
                        iconName="fa-certificate"
                        tooltip={t('Project type')}
                    />
                    <PassageContentTypeInput {...{ contentType, setContentType }} />
                </div>

                <div>
                    <DifficultyInput {...{ difficulty, setDifficulty }} />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <OkEditSegmentButton enabled={!nameError && !referencesError} onClick={save} />
                <CancelEditSegmentButton enabled onClick={() => setEditing(false)} />
            </Modal.Footer>
        </Modal>
    )
}

export default observer(PassageEditor)

import React, { FC, useState, useEffect } from 'react'
import { Alert } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import * as serviceWorker from '../../serviceWorker'

// Customize build process like this?
// https://karannagupta.com/using-custom-workbox-service-workers-with-create-react-app/
// Eventual this: https://github.com/facebook/create-react-app/issues/5673

// NOTE: In order to use service worker currently you must
//     yarn build      -- build production version of code,
//                     -- autocreates loader based on template
//     serve -s public
const ServiceWorkerWrapper: FC = () => {
    const { t } = useTranslation()
    const [updateAvailable, setUpdateAvailable] = useState(false)
    const [worker, setWorker] = useState<ServiceWorker | null>(null)

    const updateToLatest = () => {
        worker?.postMessage({ type: 'SKIP_WAITING' })
        setUpdateAvailable(false)
        window.location.reload()
    }

    const onUpdateAvailable = (registration: ServiceWorkerRegistration) => {
        setUpdateAvailable(true)
        setWorker(registration.waiting)
    }

    useEffect(() => {
        // Use service worker
        serviceWorker.register({ onUpdate: onUpdateAvailable })

        /* --- OR --- */

        // Do NOT use service worker
        // serviceWorker.unregister()
    }, [])

    if (updateAvailable) {
        return (
            <Alert>
                {t('An update is available.')}
                <button type="button" onClick={updateToLatest}>
                    {t('Update')}
                </button>
            </Alert>
        )
    }

    return null
}

export default ServiceWorkerWrapper

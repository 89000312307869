// Allow user to select which version of the video for the package

import { FC } from 'react'
import { observer } from 'mobx-react'
import _ from 'underscore'
import { MenuItem, Dropdown } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import PassageStatus from './PassageStatus'

import { UnresolvedTextNotification } from '../notifications/Notifications'
import { IDateFormatter } from '../../models3/DateUtilities'
import { TrashIcon } from '../utils/Icons'

import { Passage } from '../../models3/Passage'
import { PassageVideo } from '../../models3/PassageVideo'
import { ProjectPlan } from '../../models3/ProjectPlan'

interface IPassageVideoSelector {
    enabled: boolean
    videos: PassageVideo[]
    currentVisibleVideo: PassageVideo
    onSelect: (video: PassageVideo) => void
    dateFormatter: IDateFormatter
    passageVideoNotification: IPassageVideoNotification
    plan?: ProjectPlan
}

interface IPassageVideoNotification {
    passage: Passage | null
    hardNotificationCutoff: () => Date
    canViewConsultantOnlyFeatures: boolean
}

const PassageVideoSelector: FC<IPassageVideoSelector> = ({
    videos,
    currentVisibleVideo,
    enabled,
    onSelect,
    passageVideoNotification: passageVideoDisplayFormat,
    dateFormatter,
    plan
}) => {
    const { t } = useTranslation()
    const { passage, hardNotificationCutoff, canViewConsultantOnlyFeatures } = passageVideoDisplayFormat

    if (!passage) {
        return null
    }

    const removedVideos = videos.filter((v) => v.removed)
    const activeVideos = _.sortBy(
        videos.filter((v) => !v.removed),
        'rank'
    ).reverse()

    const cutoff = hardNotificationCutoff()
    const creationDate = currentVisibleVideo.displayedCreationDate(dateFormatter)

    const uploadIndicator = (video: PassageVideo) => (video.uploaded ? '' : '*')

    return (
        <div className="passage-video-selector">
            <Dropdown id="dropdown-custom-1" disabled={!enabled} pullRight>
                <Dropdown.Toggle className="passage-video-selector-2">
                    {currentVisibleVideo.removed && (
                        <TrashIcon className="deleted-video-item-trash" tooltip={t('recordingDeleted')} />
                    )}
                    {`${creationDate} ${uploadIndicator(currentVisibleVideo)}`} &nbsp;
                    {plan && <PassageStatus passageVideo={currentVisibleVideo} plan={plan} />}
                </Dropdown.Toggle>
                <Dropdown.Menu className="scrollable-dropdown">
                    {activeVideos.map((pv) => {
                        const unresolvedNote = pv.mostRecentUnresolvedNote(
                            passage,
                            cutoff,
                            canViewConsultantOnlyFeatures
                        )
                        return (
                            <MenuItem
                                className="passage-video-selector-2"
                                key={pv._id}
                                onClick={() => onSelect(pv)}
                                value={pv.creationDate}
                            >
                                {pv.displayedCreationDate(dateFormatter)}
                                {uploadIndicator(pv)} &nbsp;
                                {plan && <PassageStatus passageVideo={pv} plan={plan} />}
                                {unresolvedNote && (
                                    <UnresolvedTextNotification
                                        className="portion-button"
                                        tooltip={t('Unresolved notes exist')}
                                    />
                                )}
                            </MenuItem>
                        )
                    })}
                    {removedVideos.map((pv) => {
                        const unresolvedNote = pv.mostRecentUnresolvedNote(
                            passage,
                            cutoff,
                            canViewConsultantOnlyFeatures
                        )
                        return (
                            <MenuItem
                                className="passage-video-selector-2"
                                key={pv._id}
                                onClick={() => onSelect(pv)}
                                value={pv.creationDate}
                            >
                                <div className="deleted-video-item">
                                    <TrashIcon
                                        className="deleted-video-item-trash"
                                        tooltip={t('recordingDeletedUndelete')}
                                    />
                                    {pv.displayedCreationDate(dateFormatter)}
                                    {uploadIndicator(pv)} &nbsp;
                                    {plan && <PassageStatus passageVideo={pv} plan={plan} />}
                                    {unresolvedNote && (
                                        <UnresolvedTextNotification
                                            className="portion-button"
                                            tooltip={t('Unresolved notes exist')}
                                        />
                                    )}
                                </div>
                            </MenuItem>
                        )
                    })}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
}

export default observer(PassageVideoSelector)

import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { observable } from 'mobx'
import { t } from 'i18next'

import {
    DeleteButton,
    PlayButton,
    GlossPositionAdjustTimeButtons,
    PreviousSegmentButton,
    NextSegmentButton
} from '../utils/Buttons'
import { SearchBox } from '../utils/SearchBox'
import { displayError } from '../utils/Errors'

import { Passage } from '../../models3/Passage'
import { PassageGloss, IDrawablePassageGloss } from '../../models3/PassageGloss'
import { PassageSegment } from '../../models3/PassageSegment'
import { PassageVideo } from '../../models3/PassageVideo'

// Edit name (rename) existing Gloss
// It must be a valid Gloss name and not create a duplicate Gloss name

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:GlossEditor')

const getDrawable = (p: IGlossEditor) => p.drawables[p.glossIndex]

interface IGlossEditor {
    passage: Passage | null
    passageVideo: PassageVideo | null
    drawableGloss: IDrawablePassageGloss | null
    resetCurrentTime: (newTime: number) => void
    stop: () => void
    play: (startTime?: number, endPosition?: number, resetTime?: number) => void
    drawables: IDrawablePassageGloss[]
    glossIndex: number // glossIndex of gloss in drawables
    setGlossIndex: (glossIndex: number) => void
    onDelete: () => Promise<void>
    onDone: () => void
}

class GlossEditor extends Component<IGlossEditor> {
    @observable text = ''

    position = 0 // position in the view

    minPosition = 0

    maxPosition = 0

    glossEditorRef = React.createRef<HTMLDivElement>()

    constructor(props: IGlossEditor) {
        super(props)
        this.initializeGloss()

        this.onPlay = this.onPlay.bind(this)
        this.saveChanges = this.saveChanges.bind(this)
        this.clickOutside = this.clickOutside.bind(this)
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.clickOutside)
        document.addEventListener('touchstart', this.clickOutside)
    }

    componentDidUpdate(prevProps: IGlossEditor) {
        const { gloss } = getDrawable(prevProps)

        // If we are switching to a new gloss, save changes (if any) in this gloss
        if (gloss._id !== getDrawable(this.props).gloss._id) {
            this.saveChanges(gloss)
            this.initializeGloss()
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.clickOutside)
        document.removeEventListener('touchstart', this.clickOutside)
    }

    onPlay(margin: number) {
        const { play } = this.props
        const { time, duration } = getDrawable(this.props)
        log(`onPlay time=${time.toFixed(1)}, duration=${duration.toFixed(1)}`)

        const start = Math.max(0, time - margin)
        play(start, time + duration + margin, time)
    }

    gotoPreviousGlossEnabled = () => {
        const { glossIndex } = this.props
        return glossIndex > 0
    }

    gotoPreviousGloss = () => {
        if (!this.gotoPreviousGlossEnabled()) return
        const { glossIndex, setGlossIndex } = this.props
        setGlossIndex(glossIndex - 1)
    }

    gotoNextGlossEnabled = () => {
        const { glossIndex, drawables } = this.props
        return glossIndex < drawables.length - 1
    }

    gotoNextGloss = () => {
        if (!this.gotoNextGlossEnabled()) return
        const { glossIndex, setGlossIndex } = this.props
        setGlossIndex(glossIndex + 1)
    }

    // If _index is a valid array glossIndex in drawables return the corresponding gloss, video, segment
    glossInfo(_index: number) {
        const { drawables, passage } = this.props

        let gloss = null
        let video: PassageVideo | null = null
        let segment: PassageSegment | null = null

        if (_index >= 0 && _index < drawables.length) {
            gloss = drawables[_index].gloss
            video = (passage && gloss.toVideo(passage)) ?? null
            segment = video?.positionToSegment(gloss.position)?.segment ?? null
        }

        return { gloss, video, segment }
    }

    initializeGloss() {
        const { glossIndex, drawables } = this.props

        const dr = drawables[glossIndex]
        this.text = dr.gloss.text
        this.position = dr.gloss.position

        const MIN_GLOSS_WIDTH = 0.5 // seconds
        const MAX_GLOSS_WIDTH = 2.0 // seconds

        const { segment } = this.glossInfo(glossIndex)
        const { segment: prevSegment } = this.glossInfo(glossIndex - 1)
        const { segment: nextSegment, gloss: nextGloss } = this.glossInfo(glossIndex + 1)

        if (!prevSegment || prevSegment?._id !== segment?._id) {
            // No previous gloss or previous gloss in a different segment
            // Min position is the start of this segment
            this.minPosition = segment?.position ?? 0
        } else {
            // Previous gloss is in the same segment as this gloss.
            // Ensure that previous gloss maintains min width
            this.minPosition = prevSegment.position + MIN_GLOSS_WIDTH
        }

        if (!nextSegment || segment?._id !== nextSegment?._id) {
            // No next gloss or next gloss in a different segment
            // Max position is the end of this segment
            this.maxPosition = segment?.endPosition ?? MAX_GLOSS_WIDTH
        } else {
            // Next gloss is in the same segment as this gloss.
            // Ensure that the gloss ends before start of next gloss
            this.maxPosition = nextGloss?.position ?? MAX_GLOSS_WIDTH
        }

        // this.onPlay()
    }

    clickOutside(event: any) {
        const { drawableGloss, onDone, stop } = this.props
        const { glossEditorRef, saveChanges } = this
        if (!glossEditorRef.current || glossEditorRef.current.contains(event.target)) {
            return
        }

        if (drawableGloss) {
            saveChanges(drawableGloss.gloss)
        }
        stop()
        onDone()
    }

    saveChanges(gloss: PassageGloss) {
        const { text, position } = this

        if (text !== gloss.text || position !== gloss.position) {
            gloss.set(text, position).catch(displayError)
        }
    }

    adjustPosition(adjustment: number) {
        log(`adjustPosition ${adjustment}`)

        const { position, minPosition, maxPosition } = this
        const { glossIndex, resetCurrentTime } = this.props
        const { segment } = this.glossInfo(glossIndex)
        if (!segment) return

        const newPosition = position + adjustment
        if (newPosition < minPosition || newPosition > maxPosition) return

        this.position = newPosition

        resetCurrentTime(segment.positionToTime(newPosition))
    }

    render() {
        const { text, props, position, minPosition, maxPosition, glossEditorRef } = this
        const { onDelete, passage, passageVideo, drawableGloss, stop, onDone } = props
        if (!passage || !passageVideo || !drawableGloss) {
            return null
        }

        const { gloss } = getDrawable(this.props)

        // log(`render glossIndex=${props.glossIndex}`)

        const delta = 0.05

        // { t('Type gloss. <Enter> = save.') } <br />
        // { t('<Esc> = cancel. <Tab> = next sign.') }

        return (
            <div className="gloss-editor" ref={glossEditorRef}>
                <div className="gloss-pre-commands">
                    <PlayButton
                        tooltip={t(`Play sign ( ] ), play longer ( } ).`)}
                        className="gloss-play-button"
                        enabled
                        selectionPresent={false}
                        onClick={() => this.onPlay(0.25)}
                    />
                    <GlossPositionAdjustTimeButtons
                        previousFrameEnabled={position - delta >= minPosition}
                        nextFrameEnabled={position + delta <= maxPosition}
                        adjustCurrentTime={(adjustment: number) => this.adjustPosition.bind(this)(adjustment)}
                    />
                    <DeleteButton className="gloss-delete" tooltip={t('Delete gloss')} enabled onClick={onDelete} />
                </div>
                <div className="gloss-editor-input">
                    <PreviousSegmentButton
                        enabled={this.gotoPreviousGlossEnabled()}
                        onClick={() => this.gotoPreviousGloss()}
                        tooltip={t('Go to previous gloss (Shift+Tab).')}
                    />
                    <SearchBox
                        searchParameter={text}
                        options={[]} // We aren't providing suggestions right now
                        onEnter={() => {
                            stop()
                            this.saveChanges(gloss)
                            onDone()
                        }}
                        onEscape={() => {
                            stop()
                            onDone()
                        }}
                        searchParameterChanged={(newReferences: string) => {
                            this.text = newReferences
                        }}
                        tooltip=""
                        keyboardShortcuts={[
                            {
                                key: ']',
                                metaKey: false,
                                shiftKey: false,
                                handler: (e) => {
                                    e.preventDefault()
                                    this.onPlay(0.25)
                                }
                            },
                            {
                                key: '}',
                                metaKey: false,
                                shiftKey: true,
                                handler: (e) => {
                                    e.preventDefault()
                                    this.onPlay(1.5)
                                }
                            },
                            {
                                key: 'ArrowLeft',
                                metaKey: true,
                                shiftKey: false,
                                handler: () => {
                                    this.adjustPosition(-0.05)
                                }
                            },
                            {
                                key: 'ArrowRight',
                                metaKey: true,
                                shiftKey: false,
                                handler: () => {
                                    this.adjustPosition(0.05)
                                }
                            },
                            {
                                key: 'Tab',
                                metaKey: false,
                                shiftKey: false,
                                handler: (e) => {
                                    e.preventDefault()
                                    this.gotoNextGloss()
                                }
                            },
                            {
                                key: 'Tab',
                                metaKey: false,
                                shiftKey: true,
                                handler: (e) => {
                                    e.preventDefault()
                                    this.gotoPreviousGloss()
                                }
                            }
                        ]}
                        disableTab
                        autoFocus
                    />
                    <NextSegmentButton
                        enabled={this.gotoNextGlossEnabled()}
                        onClick={() => this.gotoNextGloss()}
                        tooltip={t('Go to next gloss (Tab).')}
                    />
                </div>
                {/* <div className="gloss-post-commands">
                </div> */}
            </div>
        )
    }
}

export default observer(GlossEditor)

import { observable } from 'mobx'

import { DBObject } from './DBObject'
import { IDB } from './IDB'
import { Passage } from './Passage'
import { PassageVideo } from './PassageVideo'

// Wrapper around gloss to allow drawing it in GlossBar
export interface IDrawablePassageGloss {
    x: number
    time: number
    duration: number
    width: number // width of gloss in pixels
    gloss: PassageGloss
}

export class PassageGloss extends DBObject {
    position = 0 // time offset in video to start of gloss

    // based on the position of the following gloss
    text = ''

    @observable _rev = 0

    // These values are not store in DB
    time = 0 // time for this gloss in the timeline

    segmentIndex = 0 // which segment is this on in the base (non-patched) video?

    constructor(_id: string, db: IDB) {
        super(_id, db)
        this.duration = this.duration.bind(this)
    }

    toDocument() {
        const { position, text } = this
        return this._toDocument({ position, text })
    }

    toSnapshot() {
        const snapshot = this.toDocument()

        return snapshot
    }

    dbg() {
        const { text, position } = this
        return { text, position }
    }

    copy() {
        let copy = new PassageGloss(this._id, this.db)
        copy = Object.assign(copy, this)
        return copy
    }

    async set(text: string, position: number) {
        const doc = this.toDocument()

        doc.text = text
        doc.position = position
        doc.rank = DBObject.numberToRank(position)

        await this.db.put(doc)
    }

    // Find the PassageVideo containing this gloss
    toVideo(passage: Passage) {
        return passage.videos.find((v) => v.glosses.find((g) => g._id === this._id))
    }

    toSegment(passage: Passage) {
        const video = this.toVideo(passage)
        if (!video) return null

        const { segment } = video.positionToSegment(this.position)

        return segment
    }

    static async addTestData(pv: PassageVideo) {
        if (pv.glosses.length) return

        await pv.addGloss(0, 'ONE')
        await pv.addGloss(1, 'TWO')
        await pv.addGloss(2, 'THREE')
        await pv.addGloss(3, 'FOUR')
        await pv.addGloss(4, 'FIVE')
        await pv.addGloss(5, 'SIX')
        await pv.addGloss(6, 'SEVEN')
        await pv.addGloss(7, 'EIGHT')
    }

    /** The length of the gloss
     * @param passage The containing passage
     * @param passageVideo The base video. The gloss might be on the video, or on a patch of the video.
     */
    duration(passage: Passage, passageVideo: PassageVideo) {
        const visibleGlosses = passageVideo.getVisibleGlosses(passage)
        const index = visibleGlosses.findIndex((gloss) => gloss._id === this._id)

        // if this is the last gloss, we want it to have some length
        let endingTime = this.time + 10
        if (index + 1 < visibleGlosses.length) {
            const nextGloss = visibleGlosses[index + 1]
            endingTime = nextGloss.time
        }
        return endingTime - this.time
    }
}

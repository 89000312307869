/* eslint-disable react/prefer-stateless-function */

import React, { Component } from 'react'
import { RootConsumer } from '../app/RootContext'

// An non-interactive dummy version of PassageAdder for display during reacttour

class TourPassageAdder extends Component {
    render() {
        return (
            <RootConsumer>
                {() => (
                    <span className="passage-adder" data-toggle="tooltip" title="Add new passage.">
                        <i className="far fa-fw fa-plus-square" />
                    </span>
                )}
            </RootConsumer>
        )
    }
}

export default TourPassageAdder

import { Component, useEffect, useState } from 'react'
import Tour from 'reactour'
import SplitPane from 'react-split-pane'
import { observable } from 'mobx'
import { observer } from 'mobx-react'

import TourVideoPlayer from './TourVideoPlayer'
import { tourSteps } from './TranslationEditorTour'
import TranslationRightPane from './TranslationRightPane'

import NoteDialog, { INoteRoot } from '../notes/NoteDialog'
import PassageList from '../passages/PassageList'

import { Root } from '../../models3/Root'

import ErrorBoundary from '../utils/Errors'
import LoadingMessage from '../utils/InitializationMessage'

import { DetachedVideoPlayer } from '../video/DetachedVideoPlayer'
import VideoMain from '../video/VideoMain'
import { AVTTRecordingState } from '../video/VideoRecorder'

import './Resizer.css'
import './Translation.css'
import '../notes/Note.css'
import '../video/Video.css'

type AVTTTranslationRightPanelProps = {
    rt: Root
    setShowDetachedPlayer: (value: boolean) => void
    recordingState: AVTTRecordingState
    setRecordingState: (state: AVTTRecordingState) => void
}

const AVTTTranslationRightPanelSplit = observer(
    ({ rt, setShowDetachedPlayer, recordingState, setRecordingState }: AVTTTranslationRightPanelProps) => {
        const [rightPaneDefaultSize, setRightPaneDefaultSize] = useState(0)
        const [rightPanelMaxSize, setRightPanelMaxSize] = useState(0)

        // We cannot set default size when calling useState, because we need access to the DOM.
        useEffect(() => {
            const rightPanel = document.querySelector("[data-id='translation-right-panel']")
            if (rightPanel) {
                const _element = rightPanel as HTMLElement
                const isVisible = _element.offsetWidth > 0 && _element.offsetHeight > 0
                if (isVisible) {
                    // Make pane 2 twice as wide as pane 3
                    setRightPaneDefaultSize(_element.getBoundingClientRect().width * 0.666666)
                }
            }
        }, [])

        useEffect(() => {
            function _setRightPanelMaxSize() {
                const rightPanel = document.querySelector("[data-id='translation-right-panel']")
                if (rightPanel) {
                    const _element = rightPanel as HTMLElement
                    const isVisible = _element.offsetWidth > 0 && _element.offsetHeight > 0
                    if (isVisible) {
                        // Allow some room for pane 3
                        const BUFFER_WIDTH = 300
                        const distanceToRightScreenEdge =
                            document.documentElement.clientWidth - _element.getBoundingClientRect().x
                        setRightPanelMaxSize(distanceToRightScreenEdge - BUFFER_WIDTH)
                    }
                }
            }
            const interval = setInterval(() => _setRightPanelMaxSize(), 100)
            return () => {
                clearInterval(interval)
            }
        })

        return (
            <SplitPane
                split="vertical"
                minSize={300}
                defaultSize={Math.min(rightPaneDefaultSize, rightPanelMaxSize)}
                maxSize={rightPanelMaxSize}
                style={{ position: 'relative', height: 'auto', overflow: 'visible' }}
            >
                <VideoMain
                    rt={rt}
                    setShowDetachedPlayer={setShowDetachedPlayer}
                    recordingState={recordingState}
                    setRecordingState={setRecordingState}
                />
                <div className="translation-right-pane">
                    <TranslationRightPane rt={rt} />
                </div>
            </SplitPane>
        )
    }
)
interface ITranslationEditor {
    rt: Root
}

class TranslationEditor extends Component<ITranslationEditor> {
    topDiv: HTMLDivElement | null = null

    listenersAdded = false

    @observable showDetachedPlayer = false

    @observable recordingState: AVTTRecordingState = 'NOT_INITIALIZED'

    constructor(props: ITranslationEditor) {
        super(props)
        this.setShowDetachedPlayer = this.setShowDetachedPlayer.bind(this)
    }

    componentDidMount() {
        if (!this.topDiv || this.listenersAdded) return

        this.topDiv.addEventListener('dragover', this.preventDrop, false)
        this.topDiv.addEventListener('drop', this.preventDrop, false)
        this.listenersAdded = true
    }

    setShowDetachedPlayer(value: boolean) {
        this.showDetachedPlayer = value
    }

    preventDrop(e: DragEvent) {
        if (!e) return

        e.preventDefault()

        if (e.dataTransfer) {
            e.dataTransfer.effectAllowed = 'none'
            e.dataTransfer.dropEffect = 'none'
        }
    }

    render() {
        const { rt } = this.props
        const { note, tourOpen, initialized, loadingMessage, useMobileLayout, useNarrowWidthLayout } = rt
        const { showDetachedPlayer, setShowDetachedPlayer, recordingState } = this

        if (!initialized) return <LoadingMessage {...{ loadingMessage }} />

        // Is this the right thing to do? All we want is to ensure note is never
        // undefined in the note dialog
        const noteRoot = rt as INoteRoot
        const closeNoteDialog = () => rt.setNote(undefined)

        const detachedPlayer = rt

        return (
            <div
                ref={(c) => {
                    this.topDiv = c
                }}
            >
                {note && <NoteDialog {...{ noteRoot, closeNoteDialog }} />}
                {showDetachedPlayer && <DetachedVideoPlayer {...{ detachedPlayer, setShowDetachedPlayer }} />}
                <div className="translation-top-container">
                    <SplitPane
                        split={useNarrowWidthLayout ? 'horizontal' : 'vertical'}
                        minSize={useNarrowWidthLayout ? 'auto' : 100}
                        size={useNarrowWidthLayout ? 'auto' : 200}
                        maxSize={useNarrowWidthLayout ? 'auto' : 400}
                        allowResize={!useNarrowWidthLayout}
                        style={{ position: 'relative', height: 'auto', overflow: 'visible' }}
                    >
                        <div className="translation-left-pane">
                            <ErrorBoundary>
                                <PassageList rt={rt} />
                            </ErrorBoundary>
                        </div>
                        <ErrorBoundary>
                            <div className="translation-right-panel" data-id="translation-right-panel">
                                {useMobileLayout ? (
                                    <VideoMain
                                        rt={rt}
                                        recordingState={recordingState}
                                        setRecordingState={(state) => (this.recordingState = state)}
                                    />
                                ) : (
                                    <AVTTTranslationRightPanelSplit
                                        rt={rt}
                                        setShowDetachedPlayer={setShowDetachedPlayer}
                                        recordingState={recordingState}
                                        setRecordingState={(state) => (this.recordingState = state)}
                                    />
                                )}
                            </div>
                        </ErrorBoundary>
                    </SplitPane>
                </div>
                <Tour
                    steps={tourSteps}
                    getCurrentStep={rt.getCurrentTourStep.bind(rt)}
                    maskClassName="tour-mask"
                    isOpen={tourOpen}
                    showNavigation={false}
                    onRequestClose={() => rt.closeTour()}
                />
                <TourVideoPlayer rt={rt} />
            </div>
        )
    }
}

export default observer(TranslationEditor)

import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { useAppRoot } from '../app/RootContext'
import { Root } from '../../models3/Root'
import MembersByRoleView from '../projectSettings/members/MembersByRoleView'

import { CancelButton, OKButton } from './Buttons'
import { CopyPassageOptions } from './CopyProjectDataModal'

import { systemError } from './Errors'
import { isValidIdCharacters, isValidIdLength } from './Helpers'
import { LoadingIcon } from './Icons'
import { Switch } from './Switch'
import TextInput from './TextInput'

import './ProjectModal.css'

interface ProjectAddResultProps {
    projectName: string
    isGroup: boolean
    groupRoot?: Root
    copyAdminsOnly: boolean
    copyPortions: boolean
    copyPlan: boolean
    latestDraftOnly: boolean
    includeResources: boolean
    setOpenModal: (value: boolean) => void
}

const ProjectAddResult = ({
    projectName,
    isGroup,
    groupRoot,
    copyAdminsOnly,
    copyPortions,
    copyPlan,
    latestDraftOnly,
    includeResources,
    setOpenModal
}: ProjectAddResultProps) => {
    const { t } = useTranslation()

    const [loading, setLoading] = useState(true)
    const [error, setError] = useState('')
    const [newProjectName, setNewProjectName] = useState('')
    const appRoot = useAppRoot()

    useEffect(() => {
        const addProject = async () => {
            try {
                setLoading(true)
                const newName = await appRoot.createProject(projectName, isGroup, groupRoot?.name)
                setNewProjectName(newName)
                await appRoot.reinitializeProjects()

                // Additional group project work
                if (groupRoot) {
                    const newProjectRoot = appRoot.rtsMap.get(newName)
                    if (groupRoot && newProjectRoot) {
                        await newProjectRoot.initialize()

                        await Promise.all(
                            (groupRoot.project.members ?? [])
                                .filter((member) => (copyAdminsOnly ? member.role === 'admin' : true))
                                .map((member) => newProjectRoot.project.addMember(member.email, member.role))
                        )

                        await Promise.all([
                            copyPlan
                                ? newProjectRoot.project.setViewableStagesFromExisting(groupRoot.project)
                                : Promise.resolve(),
                            copyPortions
                                ? newProjectRoot.project.setPortionsFromExisting({
                                      sourceProject: groupRoot.project,
                                      includeResources,
                                      latestDraftOnly
                                  })
                                : Promise.resolve()
                        ])
                    }
                }
            } catch (e) {
                console.log(e)
                systemError(e)
                setError(t('Could not add project.'))
                setLoading(false)
            }
        }
        addProject()
    }, [
        appRoot,
        copyAdminsOnly,
        copyPlan,
        copyPortions,
        groupRoot,
        includeResources,
        isGroup,
        latestDraftOnly,
        projectName,
        t
    ])

    if (loading) {
        return (
            <Modal.Body>
                <div className="project-modal-loading-message-parent">
                    <LoadingIcon className="" />
                    <span className="project-modal-loading-message">{t('Adding project...')}</span>
                </div>
            </Modal.Body>
        )
    }

    if (error) {
        return <Modal.Body>{error}</Modal.Body>
    }

    return (
        <>
            <Modal.Body>{t('Successfully added project: ') + newProjectName}</Modal.Body>
            <Modal.Footer>
                <div className="project-modal-footer-buttons">
                    <OKButton
                        enabled
                        onClick={() => setOpenModal(false)}
                        buttonClassName=""
                        className="ok-button"
                        tooltip={t('OK')}
                    />
                </div>
            </Modal.Footer>
        </>
    )
}

interface ProjectAddModalProps {
    groupRoot?: Root
    setOpenModal: (value: boolean) => void
}

export const ProjectAddModal = ({ groupRoot, setOpenModal }: ProjectAddModalProps) => {
    const { t } = useTranslation()
    const [projectName, setProjectName] = useState('')
    const [isGroup, setIsGroup] = useState(false)
    const [copyAdminsOnly, setCopyAdminsOnly] = useState(true)
    const [copyPlan, setCopyPlan] = useState(true)
    const [copyPortions, setCopyPortions] = useState(true)
    const [latestDraftOnly, setLatestDraftOnly] = useState(true)
    const [includeResources, setIncludeResources] = useState(false)
    const [showResultPage, setShowResultPage] = useState(false)
    const appRoot = useAppRoot()

    const rootNamesLowerCase = appRoot.rts.map((rt) => rt.name.toLowerCase())

    const validateProjectName = (name: string) => {
        if (!isValidIdCharacters(name)) {
            return t('Invalid characters')
        }

        if (!isValidIdLength(name)) {
            return t('Too short or long')
        }

        const projectNameWithGroup = groupRoot ? `${groupRoot.name}:${name}` : name
        if (rootNamesLowerCase.includes(projectNameWithGroup.toLowerCase())) {
            return t('Duplicate name')
        }

        return ''
    }

    const projectNameChanged = (newProjectName: string) => {
        const newName = newProjectName.trim()
        setProjectName(newName)
        return validateProjectName(newName)
    }

    return (
        <Modal show onHide={() => setOpenModal(false)} backdrop="static">
            <Modal.Header closeButton>
                <h3>
                    {!groupRoot && t('Add project')}
                    {groupRoot &&
                        t('Add project to group {{groupName}}', {
                            groupName: groupRoot.project.getFormattedDisplayName()
                        })}
                </h3>
            </Modal.Header>

            {showResultPage && (
                <ProjectAddResult
                    projectName={projectName}
                    isGroup={isGroup}
                    groupRoot={groupRoot}
                    copyAdminsOnly={copyAdminsOnly}
                    copyPortions={copyPortions}
                    copyPlan={copyPlan}
                    latestDraftOnly={latestDraftOnly}
                    includeResources={includeResources}
                    setOpenModal={setOpenModal}
                />
            )}

            {!showResultPage && (
                <>
                    <Modal.Body>
                        <div className="project-modal-section">
                            <h4> {t('Project Name')}</h4>
                            <div className="project-name-box">
                                <TextInput
                                    message={
                                        groupRoot
                                            ? t('Project name will be appended with the group name.')
                                            : t('Project name is set.')
                                    }
                                    initialValue=""
                                    validate={projectNameChanged}
                                    _onEnter={() => ''}
                                />
                            </div>
                            {!groupRoot && (
                                <div>
                                    <h4>{t('Group')}</h4>
                                    <Switch value={isGroup} setValue={(value) => setIsGroup(value)} />
                                </div>
                            )}
                            {groupRoot && (
                                <>
                                    <h4>{t('Copy group admins only?')}</h4>
                                    <div>
                                        <Switch
                                            value={copyAdminsOnly}
                                            setValue={(value) => setCopyAdminsOnly(value)}
                                            tooltip=""
                                        />
                                        <MembersByRoleView
                                            project={groupRoot.project}
                                            includeRoles={copyAdminsOnly ? ['admin'] : undefined}
                                        />
                                    </div>
                                    <h4>{t('Copy group portions?')}</h4>
                                    <div>
                                        <Switch
                                            value={copyPortions}
                                            setValue={(value) => setCopyPortions(value)}
                                            tooltip=""
                                        />
                                        {copyPortions && (
                                            <CopyPassageOptions
                                                latestDraftOnly={latestDraftOnly}
                                                includeResources={includeResources}
                                                setLatestDraftOnly={setLatestDraftOnly}
                                                setIncludeResources={setIncludeResources}
                                            />
                                        )}
                                    </div>
                                    <h4> {t('Copy group plan?')}</h4>
                                    <div>
                                        <Switch value={copyPlan} setValue={(value) => setCopyPlan(value)} />
                                        <ol>
                                            {copyPlan &&
                                                groupRoot.project.plans.map((plan) =>
                                                    plan.viewableStages.map((stage) => (
                                                        <li key={stage._id}>{stage.name}</li>
                                                    ))
                                                )}
                                        </ol>
                                    </div>
                                </>
                            )}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="project-modal-footer-buttons">
                            <OKButton
                                enabled={!validateProjectName(projectName)}
                                onClick={() => setShowResultPage(true)}
                                buttonClassName=""
                                className="ok-button"
                                tooltip={t('Add project')}
                            />
                            <CancelButton
                                enabled
                                onClick={() => setOpenModal(false)}
                                className="cancel-button"
                                tooltip={t('Cancel')}
                            />
                        </div>
                    </Modal.Footer>
                </>
            )}
        </Modal>
    )
}

import React, { useEffect } from 'react'
import { observer } from 'mobx-react'

import { VideoCache } from '../../models3/VideoCache'
import { systemError } from './Errors'
import { DownloadingMessage, VideoDownloadingMessage } from '../video/VideoMessage'

interface IVideoDownloading {
    videoUrl: string
    creator: string
    onEnded: (blob: Blob) => void
    fontSizePt: number
    className?: string
}

class VideoDownloading extends React.Component<IVideoDownloading> {
    timer: any

    constructor(props: IVideoDownloading) {
        super(props)
        this.clearMyTimer.bind(this)
        this.fetchStatus.bind(this)
    }

    componentDidMount() {
        this.fetchStatus()
    }

    componentWillUnmount() {
        this.clearMyTimer()
    }

    clearMyTimer() {
        if (this.timer) {
            clearTimeout(this.timer)
            this.timer = null
        }
    }

    fetchStatus() {
        const { videoUrl, onEnded } = this.props

        VideoCache.queryVideoDownload(videoUrl)
            .then((response) => {
                if (response.blob) {
                    onEnded(response.blob)
                } else {
                    this.timer = setTimeout(() => this.fetchStatus(), 2000)
                }
            })
            .catch((error: any) => {
                this.clearMyTimer()
                systemError(error)
            })
    }

    render() {
        const { className, videoUrl, creator, fontSizePt: fontSize } = this.props
        return (
            <div className={className || ''}>
                <VideoDownloadingMessage urls={[videoUrl]} creator={creator} fontSizePt={fontSize} />
            </div>
        )
    }
}

type BlobDownloadingProps = {
    url: string
    onEnded: (blob: Blob) => void
}

export const BlobDownloading = observer(({ url, onEnded }: BlobDownloadingProps) => {
    useEffect(() => {
        let timer: NodeJS.Timeout | undefined
        async function fetchStatus() {
            try {
                const query = await VideoCache.queryVideoDownload(url)
                if (query.blob) {
                    onEnded(query.blob)
                } else {
                    timer = setTimeout(() => fetchStatus(), 2000)
                }
            } catch (error) {
                if (timer) {
                    clearTimeout(timer)
                    timer = undefined
                }
                systemError(error)
            }
        }

        if (url.trim() !== '') {
            fetchStatus()
        }

        return () => {
            if (timer) {
                clearTimeout(timer)
                timer = undefined
            }
        }
    }, [onEnded, url])

    return <DownloadingMessage {...{ url }} />
})

export default observer(VideoDownloading)

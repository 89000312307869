import React, { Component } from 'react'
import { observer /* inject */ } from 'mobx-react'
import { confirmAlert } from 'react-confirm-alert'
import { t } from 'i18next'

import { displayError } from '../utils/Errors'
import { DeleteSegmentButton } from '../utils/Buttons'

import { PassageSegment } from '../../models3/PassageSegment'
import { Root } from '../../models3/Root'

interface ISegmentDeleter {
    rt: Root
    segment: PassageSegment
}

class SegmentDeleter extends Component<ISegmentDeleter> {
    confirmDeletion(doDeletion: () => void) {
        // No need to confirm, if no video has been recorded yet
        confirmAlert({
            title: t('DELETE this segment?'),
            message: t('recordingSegmentDeleteMessage'),
            confirmLabel: t('Delete!!!'),
            cancelLabel: t('Keep.'),
            onConfirm: doDeletion
        })
    }

    delete() {
        const { rt, segment } = this.props
        const { passageVideo, passage } = rt
        if (!passageVideo || !passage) return

        const newTime = segment.time

        this.confirmDeletion(() => {
            if (!passageVideo) return
            passageVideo
                .removeSegment(segment._id)
                .then(() => rt.resetCurrentTime(newTime))
                .catch((err) => {
                    displayError(err)
                })
        })
    }

    render() {
        const { segment, rt } = this.props
        const { iAmInterpreter, passageVideo, editingSegment } = rt
        if (!segment) return null

        if (!segment || !passageVideo) return null

        const segments = (passageVideo && passageVideo.segments) || []
        if (segments.length === 0) return

        const index = segments.findIndex((s) => s._id === segment._id)
        const previousIndex = index - 1
        let previousSegmentIsPatch = false
        if (previousIndex >= 0) {
            previousSegmentIsPatch = segments[previousIndex].videoPatchHistory.length > 0
        }

        const enabled =
            !editingSegment &&
            iAmInterpreter &&
            segment._id !== segments[0]._id &&
            segment.videoPatchHistory.length === 0 &&
            !previousSegmentIsPatch
        let tooltip = t('Delete this segment.')

        if (segment._id === segments[0]._id) {
            tooltip = t('You cannot delete the first segment.')
        }

        return <DeleteSegmentButton enabled={enabled} tooltip={tooltip} onClick={this.delete.bind(this)} />
    }
}

export default observer(SegmentDeleter)

/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from 'react'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import { Droppable } from 'react-beautiful-dnd'
import { t } from 'i18next'

import PassageCard from './PassageCard'
import './TaskColumn.css'
import { ColumnData } from './DragAndDropData'
import { Root } from '../../models3/Root'
import DifficultyView from './DifficultyView'

import { EditDifficultyButton } from '../utils/Buttons'

interface ITaskColumn extends ColumnData {
    rt: Root
    updateDifficulty: (difficulty: number) => void
}

class TaskColumn extends Component<ITaskColumn> {
    @observable editingDifficulty = false

    render() {
        const { task, id, items, rt, updateDifficulty } = this.props
        const { useMobileLayout } = rt
        const { difficulty } = task
        const { editingDifficulty } = this

        const name = task.displayedName
        return (
            <div className="column-container">
                <div className="task-column-header">
                    <h4>
                        <b title={task.details}>{name}</b>
                    </h4>
                    {!useMobileLayout && task.name !== 'Not started' && task.name !== 'Finished' && (
                        <>
                            <div className="edit-difficulty-button-area">
                                {!editingDifficulty &&
                                    (rt.iAmAdmin ? (
                                        <EditDifficultyButton
                                            enabled
                                            onClick={() => (this.editingDifficulty = true)}
                                            className="edit-difficulty-button clickable"
                                            tooltip={`${t('Current difficulty:')} ${difficulty}.\n${t(
                                                'Click to change.'
                                            )}`}
                                        />
                                    ) : (
                                        <EditDifficultyButton
                                            enabled
                                            onClick={() => {}}
                                            className="edit-difficulty-button"
                                            tooltip={`${t('Current difficulty:')} ${difficulty}.\n`}
                                        />
                                    ))}
                            </div>
                            {editingDifficulty && (
                                <DifficultyView
                                    stopEditing={() => {
                                        this.editingDifficulty = false
                                    }}
                                    difficulty={difficulty}
                                    setDifficulty={updateDifficulty}
                                />
                            )}
                        </>
                    )}
                </div>
                <Droppable droppableId={id}>
                    {(provided) => (
                        <div className="task-list" ref={provided.innerRef} {...provided.droppableProps}>
                            {items.map((item, index) => (
                                <PassageCard
                                    key={item.passage._id}
                                    passageData={item}
                                    index={index}
                                    rt={rt}
                                    updateDifficulty={item.passage.setDifficulty.bind(item.passage)}
                                />
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </div>
        )
    }
}

export default observer(TaskColumn)

import { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Passage } from '../../models3/Passage'
import { PassageVideo } from '../../models3/PassageVideo'
import { Portion } from '../../models3/Portion'
import { Root } from '../../models3/Root'
import { VideoCache } from '../../models3/VideoCache'
import { VideoCacheRecord } from '../../models3/VideoCacheRecord'
import { LoadingIcon } from './Icons'

export interface MissingRecording {
    portion: Portion
    passage: Passage
    missingSegments: number[]
}

const recordingCached = (url: string, blobIds: string[]) => {
    const videoCacheRecord = VideoCache.getVideoCacheRecord(url)
    const baseUrl = `${VideoCacheRecord.baseUrl(videoCacheRecord._id)}-`
    const count = blobIds.filter((result) => result.startsWith(baseUrl)).length
    return count === videoCacheRecord.totalBlobs
}

const getMissingSegments = ({
    passage,
    passageVideo,
    blobIds
}: {
    passage: Passage
    passageVideo: PassageVideo
    blobIds: string[]
}) => {
    const patchVideos = [...passageVideo.patchVideos(passage)].filter((patch) => patch.onTop)
    const missingSegments = []

    if (!recordingCached(passageVideo.url, blobIds)) {
        for (const [index, segment] of passageVideo.segments.entries()) {
            if (!segment.isPatched) {
                missingSegments.push(index)
            }
        }
    }

    for (const { patchVideo, segmentIndex } of patchVideos) {
        if (!recordingCached(patchVideo.url, blobIds)) {
            missingSegments.push(segmentIndex)
        }
    }
    return missingSegments.sort((a, b) => a - b) // .sort() sorts alphabetically, so we have to write our own sort
}

const getCachedBlobIds = async () => {
    const cachedBlobKeys = await VideoCache.getAllVideoBlobKeys()
    return cachedBlobKeys.map((result) => result.toString())
}

const getMissingPassageRecording = ({
    passage,
    portion,
    blobIds,
    passageVideo
}: {
    passage: Passage
    portion: Portion
    blobIds: string[]
    passageVideo?: PassageVideo
}) => {
    if (!passageVideo) {
        const latestRecording = passage.getDefaultVideo('')
        if (!latestRecording) {
            return []
        }
        const missingSegments = getMissingSegments({ passage, passageVideo: latestRecording, blobIds })
        return missingSegments.length ? [{ portion, passage, missingSegments }] : []
    }
    const missingSegments = getMissingSegments({ passage, passageVideo, blobIds })
    return missingSegments.length ? [{ portion, passage, missingSegments }] : []
}

const getMissingPortionRecording = (portion: Portion, blobIds: string[]) => {
    return portion.passages.map((passage) => getMissingPassageRecording({ passage, portion, blobIds })).flat()
}

const getMissingRecordingsInPortions = async (portions: Portion[]) => {
    const blobIds = await getCachedBlobIds()
    return portions.map((portion) => getMissingPortionRecording(portion, blobIds)).flat()
}

const requestPassageDownload = (passage: Passage) => {
    const latestRecording = passage.getDefaultVideo('')
    if (!latestRecording) {
        return
    }
    const patchVideos = [...latestRecording.patchVideos(passage)].filter((patch) => patch.onTop)
    const urls = [latestRecording.url, ...patchVideos.map((pv) => pv.patchVideo.url)]
    urls.forEach((url) => VideoCache.implicitVideoDownload(url))
}

const requestPortionDownload = (portion: Portion) => {
    portion.passages.forEach(requestPassageDownload)
}

const requestAllPortionsDownload = (portions: Portion[]) => {
    portions.forEach(requestPortionDownload)
}

interface MissingRecordingsTableCoreProps {
    missingRecordings: MissingRecording[]
}

const MissingRecordingsTableCore = ({ missingRecordings }: MissingRecordingsTableCoreProps) => {
    const { t } = useTranslation()
    if (!missingRecordings.length) {
        return <div>{t('missingRecordingsNone')}</div>
    }

    return (
        <Table>
            <thead>
                <tr>
                    <th>{t('Portion')}</th>
                    <th>{t('Passage')}</th>
                    <th>{t('missingRecordingsColumn')}</th>
                </tr>
            </thead>
            <tbody>
                {missingRecordings.map((recording) => {
                    return (
                        <tr key={recording.passage._id}>
                            <td>{recording.portion.name}</td>
                            <td>{recording.passage.name}</td>
                            <td>{recording.missingSegments.map((segment) => segment + 1).join()}</td>
                        </tr>
                    )
                })}
            </tbody>
        </Table>
    )
}

interface MissingRecordingsTableProps {
    rt: Root
    missingRecordings: MissingRecording[]
    loading: boolean
    header?: JSX.Element
}

const MissingRecordingsTable = ({ rt, missingRecordings, loading, header }: MissingRecordingsTableProps) => {
    const { t } = useTranslation()

    useEffect(() => {
        requestAllPortionsDownload(rt.project.portions)
    }, [rt.project.portions])

    return (
        <div>
            {header ?? <h4>{t('missingRecordingsHeader')}</h4>}
            {loading && <LoadingIcon className="" />}
            {!loading && <MissingRecordingsTableCore missingRecordings={missingRecordings} />}
        </div>
    )
}

interface AllMissingRecordingsTableProps {
    rt: Root
    header?: JSX.Element
}

const CHECK_FOR_MISSING_RECORDINGS_TIMEOUT_MS = 5000

export const AllMissingRecordingsTable = ({ rt, header }: AllMissingRecordingsTableProps) => {
    const [loading, setLoading] = useState(true)
    const [missingRecordings, setMissingRecordings] = useState<MissingRecording[]>([])

    useEffect(() => {
        const getMissingRecordings = async () => {
            setLoading(true)
            const recordings = await getMissingRecordingsInPortions(rt.project.portions)
            setMissingRecordings(recordings)
            setLoading(false)
            setTimeout(() => setLoading(true), CHECK_FOR_MISSING_RECORDINGS_TIMEOUT_MS)
        }
        if (loading) {
            getMissingRecordings()
        }
    }, [rt, loading])

    return <MissingRecordingsTable rt={rt} missingRecordings={missingRecordings} loading={loading} header={header} />
}

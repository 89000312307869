/* eslint-disable react/no-array-index-key */

import { observer } from 'mobx-react'
import { FC, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { Project } from '../../../models3/Project'
import { CancelButton, CircleButton, OKButton } from '../../utils/Buttons'
import ColorPicker from '../../utils/ColorPicker'
import { displayError } from '../../utils/Errors'
import { GenericIcon } from '../../utils/Icons'

interface INoteMarkerColorEditor {
    color: string
    setEditing: (value: boolean) => void
    setColor: (color: string) => void
}

const NoteMarkerColorEditor: FC<INoteMarkerColorEditor> = ({ color, setEditing, setColor }) => {
    const { t } = useTranslation()
    const [draftColor, setDraftColor] = useState(color)

    function save() {
        setColor(draftColor)
        setEditing(false)
    }

    return (
        <Modal show onHide={save} backdrop="static">
            <Modal.Header>
                <h3>{t('Edit note marker color')}</h3>
            </Modal.Header>
            <Modal.Body>
                <ColorPicker color={draftColor} setColor={setDraftColor} />
            </Modal.Body>
            <Modal.Footer>
                <div className="note-marker-color-modal-buttons">
                    <OKButton
                        enabled
                        onClick={save}
                        buttonClassName=""
                        className="ok-button"
                        tooltip={t('Save changes')}
                    />
                    <CancelButton
                        enabled
                        onClick={() => setEditing(false)}
                        className="cancel-button"
                        tooltip={t('Cancel editing')}
                    />
                </div>
            </Modal.Footer>
        </Modal>
    )
}

interface INoteMarkerColor {
    editing: boolean
    setEditing: (value: boolean) => void
    color: string
    setColor: (color: string) => void
}

const NoteMarkerColor: FC<INoteMarkerColor> = ({ editing, setEditing, color, setColor }) => {
    const { t } = useTranslation()

    const circleButtonStyle = {
        color,
        outline: editing ? '1px solid black' : '',
        borderRadius: '4px'
    }
    return (
        <div className="note-marker-color">
            <CircleButton
                style={circleButtonStyle}
                className="note-marker-color-icon"
                tooltip={t('Edit note marker color')}
                onClick={() => setEditing(true)}
            />
            {editing && <NoteMarkerColorEditor {...{ color, setEditing, setColor }} />}
        </div>
    )
}

interface NoteMarkerColorsPreferencesProps {
    project: Project
    iconClassName: string
}

export const NoteMarkerColorsPreferences: FC<NoteMarkerColorsPreferencesProps> = observer(
    ({ project, iconClassName }) => {
        const { t } = useTranslation()
        const { noteColors } = project
        const [editingIndex, setEditingIndex] = useState(-1)
        async function saveColor(color: string, index: number) {
            const newColors = noteColors.slice()
            newColors[index] = color
            try {
                await project.setNoteColors(newColors)
            } catch (error) {
                displayError(error)
            }
        }

        return (
            <div className="note-marker-color-list">
                <GenericIcon className={iconClassName} iconName="fa-comment" tooltip={t('Note marker colors')} />
                {noteColors.map((color, i) => (
                    <NoteMarkerColor
                        key={i}
                        editing={editingIndex === i}
                        setEditing={(editing) => setEditingIndex(editing ? i : -1)}
                        color={color}
                        setColor={(newColor) => saveColor(newColor, i)}
                    />
                ))}
            </div>
        )
    }
)

import JSZip from 'jszip'
import { Passage } from '../../models3/Passage'
import { PassageVideo } from '../../models3/PassageVideo'
import { Portion } from '../../models3/Portion'
import { Project } from '../../models3/Project'
import { AudioEncodeType, getPassageAudio, getPortionAudio } from '../utils/DownloadPassage'

export interface ExportItem {
    recording?: {
        path: string
        data?: Blob
        isMissingParts: boolean
    }
    transcription?: {
        path: string
        data: string
    }
}

interface InputParams {
    recording: {
        path: string
        audioEncodeType: AudioEncodeType
        secondsOfSilence?: number
    }
    transcription: {
        path: string
        project: Project
    }
}

export const getExportItem = async ({
    passage,
    passageVideo,
    portion,
    inputParams
}: {
    passage?: Passage
    passageVideo?: PassageVideo
    portion?: Portion
    inputParams: Partial<InputParams>
}): Promise<ExportItem> => {
    const exportItem: ExportItem = {}
    if (inputParams.recording) {
        let audio
        if (portion) {
            audio = await getPortionAudio(
                portion,
                inputParams.recording.secondsOfSilence ?? 0,
                inputParams.recording.audioEncodeType
            )
        } else if (passage && passageVideo) {
            audio = await getPassageAudio(passage, passageVideo, inputParams.recording.audioEncodeType)
        }
        if (audio) {
            const { blob, isMissingParts } = audio
            exportItem.recording = { path: inputParams.recording.path, data: blob, isMissingParts }
        }
    }

    if (inputParams.transcription) {
        let transcription
        if (portion) {
            transcription = portion.getTranscription(inputParams.transcription.project)
        } else if (passage && passageVideo) {
            transcription = passageVideo.getTranscription(passage, inputParams.transcription.project)
        }
        if (transcription) {
            exportItem.transcription = { path: inputParams.transcription.path, data: transcription }
        }
    }

    return exportItem
}

export const generateZip = async (files: ExportItem[]) => {
    if (!files.some((item) => item.recording?.data) && !files.some((item) => item.transcription)) {
        return
    }
    const zip = new JSZip()
    files.forEach((item) => {
        if (item.recording?.data) {
            zip.file(item.recording.path, item.recording.data)
        }
        if (item.transcription) {
            zip.file(item.transcription.path, item.transcription.data)
        }
    })
    const zipFile = await zip.generateAsync({ type: 'blob' })
    return new Blob([zipFile])
}

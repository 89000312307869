// Accept dropped Note files and upload them

import { Component } from 'react'
import { observer } from 'mobx-react'
import { t } from 'i18next'

import { INoteRoot } from './NoteDialog'

import { isAllowedImage } from '../images/AllowedImages'
import DropTarget from '../utils/DropTarget'
import { displayError, systemError } from '../utils/Errors'
import { JSON_MIME_TYPE } from '../utils/Helpers'

import { Passage } from '../../models3/Passage'
import { PassageVideo } from '../../models3/PassageVideo'
import { Project } from '../../models3/Project'

import './Note.css'

interface INoteDropTarget {
    noteRoot: INoteRoot
    canUpload: () => Promise<boolean>
    dropTargetView: JSX.Element
}

class NoteDropTarget extends Component<INoteDropTarget> {
    async getText(file: File): Promise<string> {
        const reader = new FileReader()
        return new Promise((resolve) => {
            reader.onload = (e: any) => resolve(e.target.result)
            reader.readAsText(file)
        })
    }

    upload = async (files: FileList) => {
        const { canUpload, noteRoot } = this.props

        const canContinue = await canUpload()
        if (canContinue) {
            if (!noteRoot.iAmInterpreter) {
                displayError(t('Observers cannot upload Notes to project.'))
                return
            }

            if (files.length !== 1) {
                displayError(t('You must drop exactly one file.'))
                return
            }
            const file = files[0]

            if (file.type === JSON_MIME_TYPE) {
                this.uploadJsonFile(file).catch((err: any) => {
                    displayError(err)
                })
                return
            }

            const video = document.createElement('video')
            if (isAllowedImage(file.type) || video.canPlayType(file.type)) {
                try {
                    await this.uploadFile(file)
                } catch (err) {
                    systemError(err)
                }
            } else {
                displayError(t('Cannot upload this type of file.'))
            }
        }
    }

    async uploadFile(file: File) {
        const { noteRoot } = this.props
        const { name, note, passage, setNote, username } = noteRoot

        const item = note.createItem()
        await item.addViewedBy(username)

        const baseUrl = `${name}/${item._id}`
        const obj = 'Project.copyFileToVideoCache'
        if (!Project.copyFileToVideoCache) throw Error(`${obj} not set`)
        item.url = await Project.copyFileToVideoCache(file, baseUrl, item.creationDate)
        item.fileType = file.type

        const _note = await note.addItem(item, passage)
        setNote(_note)
    }

    // should be moved to ProjectModes PassageVideo class
    async importNote(passage: Passage, video: PassageVideo, noteData: any) {
        const note = video.createNote(noteData.position)
        note.startPosition = noteData.startPosition
        note.endPosition = noteData.endPosition

        await video.addNote(note)

        const _note = video.notes.find((n) => note._id === n._id)
        if (!_note) {
            displayError('could not find newly created note')
            return
        }

        const item = _note.createItem()
        item.text = noteData.text
        await note.addItem(item, passage)
    }

    // should be moved to ProjectModes PassageVideo class
    async importSegment(video: PassageVideo, segData: any) {
        const { position } = segData

        let _seg = video.segments[0]

        if (position > 0.1) {
            await video.addSegment(position, [])
            const _seg2 = video.segments.find((s) => s.position === segData.position)
            if (!_seg2) {
                displayError('could not find newly created segment')
                return
            }

            _seg = _seg2
        }

        if (segData.references) {
            await _seg.setReferences(segData.references)
        }

        if (segData.glosses) {
            for (const gloss of segData.glosses) {
                await _seg.setGloss(gloss.identity, gloss.gloss)
            }
        }
    }

    /*
        The user can drop a file in this format onto the Note Dialog in order to
        create notes or segments corresponding to info that has been extracted
        from Elan files (Nathan has code to do this)
        
        {
            "creator": "mpenner@biblesocieties.org",
            "notes": [
                { "position": 5, "startPosition": 4.95, "endPosition": 7, "text": "The sign here ..." }
            ],
            "segments": [
                { 
                    "position": 3, 
                    "references": "Leviticus 10:11",
                    "glosses": [
                        {"identity": "milesnlwork@gmail.com", "gloss": "A fine kettle of fish..."}
                    ]
                }
            ],
            "glosses": [
                {"position": 3.2, "text": "mother"}
            ]
        }
    */

    //! will always attach imports to base video even if it has been patched over

    async uploadJsonFile(file: File) {
        const { noteRoot } = this.props
        const { passage, passageVideo } = noteRoot
        if (!passage || !passageVideo) {
            return
        }

        const jsonText = await this.getText(file)
        const json: any = JSON.parse(jsonText)

        if (json.notes) {
            for (const note of json.notes) {
                await this.importNote(passage, passageVideo, note)
            }
        }

        if (json.segments) {
            for (const seg of json.segments) {
                await this.importSegment(passageVideo, seg)
            }
        }

        if (json.glosses) {
            for (const gloss of json.glosses) {
                await passageVideo.addGloss(gloss.position, gloss.gloss)
            }
        }
    }

    render() {
        const { children, dropTargetView } = this.props

        const { upload } = this

        return (
            <DropTarget upload={upload} dropTargetView={dropTargetView}>
                {children}
            </DropTarget>
        )
    }
}

export default observer(NoteDropTarget)

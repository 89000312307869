import React, { FC, useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react'
import NewWindow from 'react-new-window'
import { EventEmitter } from 'events'

import VideoPlayer from './VideoPlayer'
import { ViewableVideoCollection } from './ViewableVideoCollection'

import { Passage } from '../../models3/Passage'
import { PassageVideo } from '../../models3/PassageVideo'
import { Portion } from '../../models3/Portion'
import { Timeline } from '../../models3/RootBase'

import { displayError } from '../utils/Errors'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:DetachedVideoPlayer')

const f = (x?: number) => x?.toFixed(2)

type DetachedVideoPlayerProps = {
    detachedPlayer: IDetachedVideoPlayer
    setShowDetachedPlayer: (value: boolean) => void
}

interface IDetachedVideoPlayer extends EventEmitter {
    passage: Passage | null
    passageVideo: PassageVideo | null
    currentVideos: ViewableVideoCollection
    portion: Portion | null
    currentTime: number
    playbackRate: number
    timeline: Timeline
}

export const DetachedVideoPlayer: FC<DetachedVideoPlayerProps> = observer(
    ({ detachedPlayer, setShowDetachedPlayer }) => {
        const videoPlayerRef = useRef<VideoPlayer>(null)
        const [playAllInProgress, setPlayAllInProgress] = useState(false)

        const {
            passage,
            passageVideo,
            currentVideos,
            portion,
            currentTime,
            timeline,
            addListener,
            removeListener,
            playbackRate
        } = detachedPlayer

        function getVideoPlayer() {
            if (!videoPlayerRef) log('### no videoPlayer present')
            return videoPlayerRef
        }

        function play(startTime?: number, endTime?: number, resetTime?: number) {
            const { selectionStartTime, selectionEndTime } = timeline.getSelectionTimes()
            log(
                `play3 ${f(startTime)}/${f(endTime)} [resetTime=${f(resetTime)}] [${f(selectionStartTime)}..${f(
                    selectionEndTime
                )}]`
            )

            if (startTime === undefined && timeline.selectionPresent()) {
                startTime = selectionStartTime
                endTime = selectionEndTime
                resetTime = startTime
            }

            startTime = startTime ?? currentTime

            const vp = getVideoPlayer()
            vp.current?.play(startTime, endTime).catch(displayError)
        }

        function stop() {
            const vp = getVideoPlayer()
            vp.current?.stop()
        }

        function stopAndReset() {
            log('stop')
            stop()
        }

        function stopAndDoNotReset() {
            log('pause')
            stop()
        }

        function playAll() {
            log('playAll')
            if (!getVideoPlayer().current) return

            play()
            setPlayAllInProgress(true)
        }

        function setCurrentTime(newTime: number) {
            log('setCurrentTime', newTime)
            const vp = getVideoPlayer()
            vp.current?.setCurrentTime(newTime)
        }

        function setPassageVideo() {
            if (!passageVideo) return

            log('setPassageVideo', playAllInProgress, passageVideo._id)
            stop()

            currentVideos.waitUntilDownloaded().then(() => {
                log('passageVideoHasChanged downloaded')
                if (playAllInProgress) {
                    play()
                }
            })
        }

        function abandonPlayAllInProgress(msg: string) {
            log('abandonPlayAllInProgress')
            stopAndDoNotReset()
            setPlayAllInProgress(false)
            log(msg)
        }

        function onEnded() {
            log(`onEnded playAll=${playAllInProgress}, ${passageVideo?._id}`)

            if (!playAllInProgress) return

            if (!portion || !passage) {
                abandonPlayAllInProgress('no portion or no passage')
                return
            }

            const { passages } = portion
            const playablePassages = passages.filter((p) => p.videosNotDeleted.length > 0)
            const passageIndex = playablePassages.findIndex((p) => p._id === passage._id)
            log(`onEnded playAll passageIndex=${passageIndex}`)
            if (passageIndex < 0) {
                abandonPlayAllInProgress('could not find passage')
                return
            }

            if (passageIndex >= playablePassages.length - 1) {
                abandonPlayAllInProgress('onEnded playAll stop')
            }
        }

        useEffect(() => {
            addListener('play', play)
            addListener('pause', stopAndDoNotReset)
            addListener('stop', stopAndReset)
            addListener('playAll', playAll)
            addListener('setCurrentTime', setCurrentTime)
            addListener('setPassageVideo', setPassageVideo)
            addListener('onEnded', onEnded)

            return () => {
                removeListener('play', play)
                removeListener('pause', stopAndDoNotReset)
                removeListener('stop', stopAndReset)
                removeListener('playAll', playAll)
                removeListener('setCurrentTime', setCurrentTime)
                removeListener('setPassageVideo', setPassageVideo)
                removeListener('onEnded', onEnded)
            }
        })

        return (
            <NewWindow features={{ width: 640, height: 480 }} onUnload={() => setShowDetachedPlayer(false)}>
                <div className="detached-video-player-area">
                    {passage && passageVideo && (
                        <VideoPlayer
                            ref={videoPlayerRef}
                            passage={passage}
                            video={passageVideo}
                            playbackRate={playbackRate}
                            vvc={currentVideos}
                            play={() => {}}
                            stop={() => {}}
                            autoPlay={playAllInProgress}
                            disableOnClick
                            muted
                        />
                    )}
                </div>
            </NewWindow>
        )
    }
)

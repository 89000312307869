import React, { FC } from 'react'
import _ from 'underscore'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import MemberView from './MemberView'
import MemberAdder from './MemberAdder'

import { Member } from '../../../models3/Member'
import { Root } from '../../../models3/Root'

interface IMembersEditor {
    rt: Root
}

const MembersEditor: FC<IMembersEditor> = ({ rt }) => {
    const { t } = useTranslation()

    const { project } = rt
    const { members } = project

    const isSoleAdmin = (member: Member) => {
        if (member.role !== 'admin') return false

        return _.where(project.members, { role: 'admin' }).length === 1
    }

    return (
        <div>
            <div className="container-fluid">
                <div className="row">
                    <h3>{t('Project Members')}</h3>
                </div>
                <div className="row">
                    <div className="col-md-5">
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th>{t('User')}</th>
                                    <th>{t('Role')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {members.map((member) => {
                                    return (
                                        <MemberView
                                            key={member.email}
                                            member={member}
                                            rt={rt}
                                            isSoleAdmin={isSoleAdmin(member)}
                                        />
                                    )
                                })}
                                <MemberAdder rt={rt} />
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default observer(MembersEditor)

import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { observable } from 'mobx'
import { Route, RouteComponentProps, withRouter } from 'react-router-dom'
import { ToastContainer, ToastPosition } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'
import { t } from 'i18next'
import { isMobileOnly } from 'react-device-detect'

import DatabaseEditor from './DatabaseEditor'
import ProjectsEditor from './ProjectsEditor'
import { NavigationBar } from './NavigationBar'
import { isAVTT, websiteShortName } from './slttAvtt'
import TitleComponent from './Title'
import { RootProvider, RootConsumer, AppRootProvider } from './RootContext'

import ProjectMessages from '../notifications/ProjectMessages'
import ProjectSettings from '../projectSettings/ProjectSettings'
import { PortionsEditor } from '../portions/PortionsEditor'
import StatusEditor from '../status/StatusEditor'
import TranslationEditor from '../translation/TranslationEditor'

import LoadingMessage from '../utils/InitializationMessage'
import ErrorBoundary, { displayError, systemError } from '../utils/Errors'
import { OfflineMessage } from '../utils/OfflineMessage'

import { AppRoot, AuthType } from '../../models3/AppRoot'
import { MarbleLemmas } from '../../scrRefs/Lemmas'

import './SLTool.css'

// SLTool - top level component for app
// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:SLTool')

let iPadWarningIssued = false

const _window = window as any

function isChrome() {
    const _w: any = window

    const isChromium = _w.chrome
    const winNav = _w.navigator
    const vendorName = winNav.vendor
    const isOpera = typeof _w.opr !== 'undefined'
    const isIEedge = winNav.userAgent.indexOf('Edge') > -1
    const isIOSChrome = winNav.userAgent.match('CriOS')

    if (isIOSChrome) {
        return true
    }
    if (
        isChromium !== null &&
        typeof isChromium !== 'undefined' &&
        vendorName === 'Google Inc.' &&
        isOpera === false &&
        isIEedge === false
    ) {
        return true
    }

    return false
}

function issueIPadWarning() {
    if (iPadWarningIssued) return false
    iPadWarningIssued = true
    return navigator.userAgent.match(/Mac/) && navigator.maxTouchPoints && navigator.maxTouchPoints > 2
}

interface SLToolProps extends RouteComponentProps {
    authType: AuthType
    username?: string
    idToken?: string
}

class SLTool extends Component<SLToolProps> {
    @observable selected = 'translation'

    narrowScreenListener?: MediaQueryList

    appRoot = new AppRoot(this.selectPage.bind(this))

    @observable resourcesFetchDone = false

    constructor(props: any) {
        super(props)

        _window.appRoot = this.appRoot
        _window._ = this.appRoot // less to type
        // VideoCacheUploader.uploadDone = this.appRoot.uploadDone.bind(this.appRoot)

        this.appRoot.useMobileLayout = isMobileOnly

        if (props.authType === 'cognito' && props.username && props.idToken) {
            this.appRoot.setUser(props.username, props.idToken, props.authType)
        }

        this.selected = localStorage.getItem('pageSelected') || 'translation'

        this.selectProject = this.selectProject.bind(this)

        this.fetchStaticData().catch((err) => {
            this.resourcesFetchDone = true
            log(`fetchStaticData ERROR=${err}`)
        })
    }

    async componentDidMount() {
        const { appRoot } = this
        const { history } = this.props
        log('componentDidMount')

        this.narrowScreenListener = window.matchMedia('(max-width: 900px)')
        this.setNarrowWidth(this.narrowScreenListener.matches)
        this.narrowScreenListener.addEventListener('change', (e) => {
            this.setNarrowWidth(e.matches)
        })

        let videoCacheLimitGB = 10
        if (appRoot.useMobileLayout) {
            videoCacheLimitGB = 2
        }

        localStorage.setItem('videoCacheLimitGB', videoCacheLimitGB.toString())

        appRoot
            .initializeProjects()
            .then((rt) => {
                // If the user has not created any portions for this project yet
                // force them to the portion creation page.
                const portionsCount = rt?.project.portions.length ?? -1
                if (portionsCount === 0) {
                    log('!!!force /portions')
                    history.push(`/portions`)
                }
            })
            .catch(displayError)
    }

    componentWillUnmount() {
        this.narrowScreenListener?.removeEventListener('change', (e) => {
            this.setNarrowWidth(e.matches)
        })
    }

    setNarrowWidth(value: boolean) {
        const { appRoot } = this
        appRoot.useNarrowWidthLayout = value
        appRoot.rts.forEach((root) => (root.useNarrowWidthLayout = value))
    }
    // !! Feels like this is a strange place to be fetching data relating to resources.
    // Where should it be really? appRoot?

    async fetchStaticData() {
        await MarbleLemmas.fetch()

        log('fetchStaticData done')
        this.resourcesFetchDone = true
    }

    selectPage(selection: string) {
        this.selected = selection
        localStorage.setItem('pageSelected', selection)
    }

    selectProject(projectName: string) {
        this.appRoot.setCurrentProject(projectName).catch(systemError)
    }

    render() {
        const { selected, appRoot, selectProject, resourcesFetchDone } = this
        const { rts, rt, iAmRoot, projectsInitialized } = appRoot

        if (!isChrome()) return <div className="not-chrome">{t('You must use Chrome browser for this website.')}</div>

        if (issueIPadWarning()) displayError(t('SLTT does not work on iPad.'))

        log('render', rt)

        const selectPage = this.selectPage.bind(this)

        /* Debugging routes.
         * It may be useful to put the following in react-router/es/matchPath.js>matchPath
         *
         *    console.log('!!!matchPath', pathname, path)
         */

        // I think Router matches against window.location.pathname
        if (rt && rt.uiLanguageChanging) {
            return <div>Language changing...</div>
        }

        return (
            <AppRootProvider initialValue={appRoot}>
                <RootProvider initialValue={rt}>
                    <RootConsumer>
                        {() => (
                            <div>
                                <div>
                                    <TitleComponent title={websiteShortName} />
                                    <OfflineMessage />
                                    <NavigationBar
                                        selected={selected}
                                        selectPage={selectPage}
                                        openTour={rt && rt.openTour.bind(rt)}
                                        selectProject={selectProject}
                                    />

                                    {appRoot.id_token && !resourcesFetchDone && projectsInitialized && (
                                        <LoadingMessage loadingMessage={t('Loading resources ...')} />
                                    )}

                                    {appRoot.id_token && !projectsInitialized && (
                                        <LoadingMessage loadingMessage={t('Initializing projects ...')} />
                                    )}

                                    {appRoot.id_token && projectsInitialized && resourcesFetchDone && (
                                        <>
                                            {rts.length === 0 && (
                                                <div>
                                                    <p>{t('You are not currently a member of any project.')}</p>
                                                    <p>
                                                        {t(
                                                            'If you are a member of an existing project, ask the project administrator to add you.'
                                                        )}
                                                    </p>
                                                </div>
                                            )}
                                            {rt && (
                                                <Route
                                                    exact
                                                    path="/(index.html)?"
                                                    render={() => (
                                                        <ErrorBoundary>
                                                            <TranslationEditor {...{ rt }} />
                                                        </ErrorBoundary>
                                                    )}
                                                />
                                            )}

                                            {rt && (
                                                <Route
                                                    path="/portions"
                                                    render={() => (
                                                        <ErrorBoundary>
                                                            <PortionsEditor rt={rt} />
                                                        </ErrorBoundary>
                                                    )}
                                                />
                                            )}

                                            {rt && (
                                                <Route
                                                    path="/project-settings"
                                                    render={() => (
                                                        <ErrorBoundary>
                                                            <ProjectSettings rt={rt} />
                                                        </ErrorBoundary>
                                                    )}
                                                />
                                            )}

                                            {!isAVTT && rt && (
                                                <Route
                                                    path="/messages"
                                                    render={() => (
                                                        <ErrorBoundary>
                                                            <ProjectMessages rt={rt} appRoot={appRoot} />
                                                        </ErrorBoundary>
                                                    )}
                                                />
                                            )}

                                            {rt && (
                                                <Route
                                                    path="/status"
                                                    render={() => (
                                                        <ErrorBoundary>
                                                            <StatusEditor rt={rt} />
                                                        </ErrorBoundary>
                                                    )}
                                                />
                                            )}

                                            {rt && rt.iAmRoot && (
                                                <Route
                                                    path="/database"
                                                    render={() => (
                                                        <ErrorBoundary>
                                                            <DatabaseEditor rt={rt} />
                                                        </ErrorBoundary>
                                                    )}
                                                />
                                            )}
                                            {iAmRoot && (
                                                <Route
                                                    path="/projects"
                                                    render={() => (
                                                        <ErrorBoundary>
                                                            <ProjectsEditor />
                                                        </ErrorBoundary>
                                                    )}
                                                />
                                            )}
                                        </>
                                    )}
                                </div>
                                <ToastContainer
                                    position={ToastPosition.TOP_LEFT}
                                    autoClose={5000}
                                    hideProgressBar
                                    newestOnTop={false}
                                    closeOnClick
                                    pauseOnHover
                                />
                            </div>
                        )}
                    </RootConsumer>
                </RootProvider>
            </AppRootProvider>
        )
    }
}

export default withRouter(observer(SLTool))

import { useEffect, useRef, useState } from 'react'
import WaveSurfer from 'wavesurfer.js'
import MarkersPlugin, { MarkerParams } from 'wavesurfer.js/src/plugin/markers'
import TimelinePlugin from 'wavesurfer.js/src/plugin/timeline'

import { PlayButton, PauseButton } from '../utils/Buttons'

import './WaveSurferAudioPlayerLite.css'

interface WaveSurferAudioPlayerLiteProps {
    url: string
    markerParams: MarkerParams[]
    startTime?: number
}

export const WaveSurferAudioPlayerLite = ({ url, markerParams, startTime }: WaveSurferAudioPlayerLiteProps) => {
    const wavesurferRef = useRef<WaveSurfer | null>(null)
    const [playing, setPlaying] = useState(false)

    // create new WaveSurfer instance
    // On component mount and when url changes
    useEffect(() => {
        setPlaying(false)

        wavesurferRef.current = WaveSurfer.create({
            container: '.wavesurfer-lite #waveform-lite',
            waveColor: '#337ab7',
            progressColor: '#337ab7',
            scrollParent: true,
            responsive: true,
            height: 100,
            // If true, normalize by the maximum peak instead of 1.0.
            normalize: true,
            // Use the PeakCache to improve rendering speed of large waveforms.
            partialRender: true,
            plugins: [
                TimelinePlugin.create({ container: '.wavesurfer-lite #timeline-lite' }),
                MarkersPlugin.create({ markers: markerParams })
            ]
        })

        wavesurferRef.current.load(url)

        wavesurferRef.current.on('ready', () => {
            const totalTime = wavesurferRef.current?.getDuration()
            if (startTime && totalTime) {
                wavesurferRef.current?.seekAndCenter(startTime / totalTime)
            }
        })

        return () => wavesurferRef.current?.destroy()
    }, [url, markerParams, startTime])

    const handlePlayPause = () => {
        setPlaying(!playing)
        wavesurferRef.current?.playPause()
    }

    return (
        <div className="wavesurfer-lite">
            <div className="controls">
                {playing && <PauseButton className="sl-pause-button" enabled onClick={handlePlayPause} />}
                {!playing && <PlayButton className="sl-play-button" enabled onClick={handlePlayPause} />}
            </div>
            <div className="wavesurfer">
                <div id="timeline-lite" />
                <div id="waveform-lite" />
            </div>
        </div>
    )
}

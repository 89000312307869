import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import './DropTarget.css'
import { UploadIcon } from './Icons'

interface IDropTargetView {
    message: JSX.Element
}

export const DropTargetViewLarge: FC<IDropTargetView> = ({ message }) => {
    const { t } = useTranslation()

    return (
        <div className="drop-target-area">
            <div className="drop-target-center-area">
                <UploadIcon className="drop-target-icon fa-fw" tooltip={t('Upload')} />
                {message}
            </div>
        </div>
    )
}

export const DropTargetViewSmall: FC<IDropTargetView> = () => {
    const { t } = useTranslation()

    return (
        <div className="drop-target-area">
            <div className="drop-target-center-area">
                <UploadIcon className="drop-target-icon-small fa-fw" tooltip={t('Upload')} />
            </div>
        </div>
    )
}

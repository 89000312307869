import _ from 'underscore'

import { Passage } from '../models3/Passage'
import { PassageVideo } from '../models3/PassageVideo'
import { ReferenceMarker } from '../models3/ReferenceMarker'
import { PassageSegment } from '../models3/PassageSegment'
import { Root } from '../models3/Root'

import { displayError, displayInfo } from '../components/utils/Errors'

// allow drag drop of video to main video area, if no passage selected, create one

// add unit test?

// https://developer.mozilla.org/en-US/docs/Web/API/Document/documentElement
// An Element, Comment, Text are kinds of Node.
// document.documentElement is the root element.

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:finalcutpro')

interface IFCPLabel {
    text: string
    startTime: number // start time in clip in seconds
    endTime: number // ending time in clip in seconds
    prototype: string // 'v' verse number, '1'..'4' segment label
}

// Verse Referebce label
class FCPReferenceLabel implements IFCPLabel {
    public text: string

    public startTime: number

    public endTime: number

    prototype = 'v'

    constructor(rt: Root, reference: ReferenceMarker, endTime: number) {
        this.text = rt.displayableReferences(reference.references)
        this.startTime = reference.time
        this.endTime = endTime
    }
}

class FCPSegmentLabel implements IFCPLabel {
    public text: string

    public startTime: number

    public endTime: number

    public prototype: string

    constructor(segment: PassageSegment, labelIndex: number) {
        this.text = segment.labels[labelIndex].text
        this.startTime = segment.time
        this.endTime = segment.time + segment.endPosition - segment.position

        // PassageSegmentLabels are stored bottom left, bottom right, top left, top right
        const labelId = ['1', '2', '3', '4']
        this.prototype = labelId[labelIndex]
    }
}

async function getText(file: File): Promise<string> {
    const reader = new FileReader()
    return new Promise((resolve) => {
        reader.onload = (e: any) => resolve(e.target.result)
        reader.readAsText(file)
    })
}

function downloadText(text: string, fileName: string) {
    const blob = new Blob([text], { type: 'text/plain' })
    const blobUrl = URL.createObjectURL(blob)
    const link = document.createElement('a')

    link.href = blobUrl
    link.download = `${fileName.split('.')[0]} #2.fcpxml`

    document.body.appendChild(link)

    link.dispatchEvent(
        new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window
        })
    )

    document.body.removeChild(link)
}

// Search for unique element by tag name
function elementByName(root: Element, name: string) {
    const elements = [...root.getElementsByTagName(name)]
    if (elements.length !== 1) {
        throw Error()
    }

    return elements[0]
}

// Return text of first text-style element
function titleText(element: Element) {
    const tss = [...element.getElementsByTagName('text-style')]
    return tss[0]?.textContent?.trim() || ''
}

function removeElement(element: Element) {
    const parent = element.parentElement
    parent?.removeChild(element)
}

// Remove the text-style-def from this title since we only one to insert it into
// the output document once.
function removeTextStyleDef(title: Element) {
    const defs = [...title.getElementsByTagName('text-style-def')]
    defs.forEach((def) => removeElement(def))
}

function escapeHTML(text: string) {
    text = text.replace(/"/g, '&quot;')
    text = text.replace(/</g, '&lt;')
    text = text.replace(/>/g, '&gt;')
    text = text.replace(/&/g, '&amp;')
    return text
}

// Return quantized time in ticks in FCP format.
// e.g. "21021/24000s"
function fcpTime(time: number, ticksQuantization: number, ticksPerSecond: number) {
    let ticks = time * ticksPerSecond
    ticks = Math.round(ticks / ticksQuantization) * ticksQuantization
    return `${ticks}/${ticksPerSecond}s`
}

/**
 * Insert a label into fcpxml document
 */
function insertLabel(
    clip: Element, // clip to append title to
    label: IFCPLabel, // content to be inserted
    prototypes: Map<string, Element>, // title elements used as prototypes
    ticksQuantization: number,
    ticksPerSecond: number,
    lane: number
) {
    const { text, startTime, endTime, prototype } = label

    if (!text.trim()) return // label has not text to insert

    const title = prototypes.get(prototype)
    if (!title) {
        log(`insertLabel: no title prototype found[${prototype}]`)
        return
    }

    const title2 = title.cloneNode(true) as Element // make a copy of prototype title

    // We only want to insert the style definition once so we remove it after we
    // have used it the first time.
    removeTextStyleDef(title)

    title2.setAttribute('name', escapeHTML(`${prototype} - ${text}`))
    title2.setAttribute('lane', lane.toFixed())
    title2.setAttribute('offset', fcpTime(startTime, ticksQuantization, ticksPerSecond))
    title2.setAttribute('duration', fcpTime(endTime - startTime, ticksQuantization, ticksPerSecond))

    // insert new text
    const textStyleElement = [...title2.getElementsByTagName('text-style')][0]
    if (!textStyleElement) {
        throw Error(`could not find text-style`)
    }

    textStyleElement.innerHTML = escapeHTML(text)

    clip.appendChild(title2)
    clip.appendChild(new Text('\n'))
}

/**
 * Time offsets and durations values in fcpxml are very picky.
 * Take the format attribute of the sequence element.
 * Find the format element in the resource element that has the same id.
 * Extract the frameDuration attribute value, e.g. "1001/24000s".
 * In this case all times must be expressed in ticks of 1/24000s.
 * However times must be a multiple of the first number, e.g. 1001.
 * I think this is equivalent of saying that times must lie on frame boundaries.
 * For example the 11th frame is at time "11011/24000s".
 * FCP will complain when importing xml files if times do not meet these requirements.
 * Simon Cozens did the research on this.
 *
 * Return ticksQuantization (numerator) and ticksPerSecond (denominator) numbers.
 */
function getFrameDuration(doc: Document) {
    //  <project
    //    <sequence format="r1"
    //  <resources
    //    <format id="r1" frameDuration="1001/24000s"

    const project = elementByName(doc.documentElement, 'project')
    const sequence = elementByName(project, 'sequence')
    const formatId = sequence.getAttribute('format')
    if (!formatId) {
        throw Error(`No formatId found for project`)
    }

    const resources = elementByName(doc.documentElement, 'resources')
    const formats = [...resources.getElementsByTagName('format')]

    const format = _.find(formats, (f) => f.getAttribute('id') === formatId)
    if (!format) {
        throw Error(`No format for id=${format}`)
    }

    const parts = format.getAttribute('frameDuration')?.split('/')
    if (!parts) {
        throw Error(`Invalid frameDuration for id=${format}`)
    }

    return {
        ticksQuantization: parseInt(parts[0]),
        ticksPerSecond: parseInt(parts[1].slice(0, -1))
    }
}

// Find video clip in xml document.
function getClip(doc: Document) {
    const project = elementByName(doc.documentElement, 'project')
    const clips = [...project.getElementsByTagName('asset-clip')]
    if (clips.length === 0) {
        throw Error('No video clip present')
    }
    if (clips.length > 1) {
        throw Error('More than one video clip present')
    }

    return clips[0]
}

/**
 * Remove all the titles in the document.
 * Store them in a map based on text.
 * 'v' - verse numbers
 * '1' - first label listed for segment in SLTT
 * '2, '3', '4'
 */
function getPrototypes(doc: Document) {
    const titles = new Map<string, Element>()

    for (const title of [...doc.getElementsByTagName('title')]) {
        titles.set(titleText(title), title)
        removeElement(title) // remove prototype titles
    }

    return titles
}

function addMessage(messages: string[], labels: IFCPLabel[], prototypes: Map<string, Element>) {
    if (labels.length === 0) return

    const { prototype } = labels[0]
    const count = labels.length
    const labelType = prototype === 'v' ? 'verses' : `Label ${prototype}'s`

    if (prototypes.get(prototype)) {
        messages.push(`${count} ${labelType} inserted.`)
    } else {
        messages.push(`No title with text "${prototype}" found; ${labelType}'s NOT inserted.`)
    }
}

function addComment(doc: Document, messages: string[], rt: Root, passage: Passage, passageVideo: PassageVideo) {
    const _video = JSON.stringify(passageVideo.dbg(passage, 's'), null, 4)
    const comment = new Comment([...messages, _video].join('\n'))
    doc.documentElement.appendChild(comment)
}

// Transform the fcpxml document by inserting titles in the clip corresponding to each
// verse reference and segment label.
export async function transformDoc(rt: Root, passage: Passage, passageVideo: PassageVideo, doc: Document) {
    const clip = getClip(doc)
    const { ticksQuantization, ticksPerSecond } = getFrameDuration(doc)
    const prototypes = getPrototypes(doc)
    const messages: string[] = []

    let lane = 1
    const references = passageVideo.getVisibleReferenceMarkers(passage)
    const labels = references.map(
        (reference, i) =>
            new FCPReferenceLabel(
                rt,
                reference,
                i + 1 < references.length ? references[i + 1].time : passageVideo.duration
            )
    )
    addMessage(messages, labels, prototypes)

    labels.forEach((label) => insertLabel(clip, label, prototypes, ticksQuantization, ticksPerSecond, lane))
    if (labels.length) {
        ++lane // If any verse references, move to next lane
    }

    const segments = passageVideo.visibleSegments(passage)
    for (let i = 0; i < 4; ++i) {
        const labels2 = segments
            .filter((segment) => segment.labels[i]?.text)
            .map((segment) => new FCPSegmentLabel(segment, i))
        addMessage(messages, labels2, prototypes)

        labels2.forEach((label) => insertLabel(clip, label, prototypes, ticksQuantization, ticksPerSecond, lane))
        if (labels2.length) {
            ++lane // If any segment label inserted in this lane, move to next lane
        }
    }

    // update project name
    const project = elementByName(doc.documentElement, 'project')
    project.setAttribute('name', `${project.getAttribute('name')} #2`)

    addComment(doc, messages, rt, passage, passageVideo)

    return messages
}

/*
 * Take an exported fcpxml file and insert verse numbers and headings.
 * Download resulting file for reimportation to fcp.
 */
export async function downloadFcpxml(rt: Root, passage: Passage, passageVideo: PassageVideo, file: File) {
    log('downloadFcpxml', file.name)

    try {
        const xmlText = await getText(file)

        const domParser = new DOMParser()
        const doc = domParser.parseFromString(xmlText, 'text/xml')

        const messages = await transformDoc(rt, passage, passageVideo, doc)

        const serializer = new XMLSerializer()
        const xmlOutput = serializer.serializeToString(doc)

        downloadText(xmlOutput, file.name)

        if (messages.length) displayInfo(messages.join('\n'))
    } catch (error) {
        displayError(error)
    }
}

/* eslint-disable import/no-cycle */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */

import { EAFItem, readEAFFile } from './ELANReader'

import { Passage } from '../models3/Passage'
import { PassageVideo } from '../models3/PassageVideo'
import { Root } from '../models3/Root'

import { fmt } from '../components/utils/Fmt'

/**
 * Routines to convert ELAN .eaf files into glosses, segments, and notes.
 */

// eslint-disable-next-line @typescript-eslint/no-var-requires
// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:ELANCreator')

// If items are short combine them with previous item because we cannot display
// tiny time duration things correctly.
function combineShortItems(items: EAFItem[]) {
    const initialLength = items.length

    for (let j = items.length - 1; j > 0; j -= 1) {
        if (items[j].position - items[j - 1].position < 0.15) {
            // eslint-disable-next-line no-param-reassign
            items[j - 1].text += ` ${items[j].text}`
            items.splice(j, 1)

            j -= 1
        }
    }

    log(`combineShortItems ${initialLength} => ${items.length}`)
}

async function importGlosses(items: EAFItem[], passage: Passage, passageVideo: PassageVideo, creator: string) {
    log('importGlosses', fmt({ creator, length: items.length }))

    combineShortItems(items)

    for (const item of items) {
        const { position, text } = item
        log('gloss', fmt({ position, text }))
        // if (position > 300) return

        await passageVideo.addGloss(position, text)
    }
}

async function importSegment(passage: Passage, video: PassageVideo, creator: string, item: EAFItem) {
    const { text, position } = item
    log('segment', fmt({ position, text }))
    // if (position > 300) return

    const { segment } = await video.addSegment(position, [])

    await segment.setGloss(creator, text)
}

async function importSegments(items: EAFItem[], passage: Passage, passageVideo: PassageVideo, creator: string) {
    log('importSegments', fmt({ creator, length: items.length }))

    for (const item of items) {
        await importSegment(passage, passageVideo, creator, item)
    }
}

function getImporter(id: string) {
    const creator = localStorage.getItem('eafcreator') || 'mwinedt@biblesocieties.org'

    id = id.toLowerCase()

    if (id.startsWith('gloss')) return { creator, importer: importGlosses }
    if (id.startsWith('segment')) return { creator, importer: importSegments }

    return { creator, importer: null }
}

export async function importEAFFile(rt: Root, passage: Passage, passageVideo: PassageVideo, file: File) {
    log('importEAFFile')

    const tiers = await readEAFFile(file)

    for (const tier of tiers) {
        const { creator, importer } = getImporter(tier.id)
        if (importer) {
            log(`tier [${tier.id}] - import`)
            await importer(tier.items, passage, passageVideo, creator)
        } else {
            log(`tier [${tier.id}] - skipped`)
        }
    }
}

/* eslint-disable react/prefer-stateless-function */

import React, { Component } from 'react'
import { observer /* inject */ } from 'mobx-react'
import { t } from 'i18next'

import { PreviousSegmentButton, NextSegmentButton } from '../utils/Buttons'

import { PassageSegment } from '../../models3/PassageSegment'
import { Root } from '../../models3/Root'

interface ISegmentSelector {
    rt: Root
    segment: PassageSegment
    editing: boolean
}

class SegmentSelector extends Component<ISegmentSelector> {
    render() {
        const { segment, rt, editing } = this.props
        const { passageVideo, passage } = rt

        if (!segment || !passage || !passageVideo) return null

        const segments = passageVideo.visibleSegments(passage)
        if (!segments.length) return null

        // segments.forEach(seg => console.log(`${seg.position.toFixed(2)}`))

        const idx = segments.findIndex((s) => s._id === segment._id)
        if (idx === -1) return null

        const gotoSegment = function (seg: PassageSegment) {
            rt.resetCurrentTime(seg.time)
        }

        let enableLeft = false
        let enableRight = false
        // Don't allow changing the currently selected segment if we are in the middle
        // of an edit
        if (!editing) {
            enableLeft = idx > 0
            enableRight = idx < segments.length - 1
        }

        const x = idx + 1
        const y = segments.length

        // example: (segment number) 1 of 5
        const segmentPositionMessage = t('{{x}} of {{y}}', { x, y })
        return (
            <div className="segment-selector">
                <PreviousSegmentButton
                    enabled={enableLeft}
                    tooltip={t('Go to previous segment.')}
                    onClick={() => gotoSegment(segments[idx - 1])}
                />
                <span className="segment-selector-text">{segmentPositionMessage}</span>
                <NextSegmentButton
                    enabled={enableRight}
                    tooltip={t('Go to next segment.')}
                    onClick={() => gotoSegment(segments[idx + 1])}
                />
            </div>
        )
    }
}

export default observer(SegmentSelector)

// Show a horizontal timeline with a vertical bar indicating current position in video.
// Allow user to set video position by clicking or dragging.

import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { observable } from 'mobx'
import _ from 'underscore'

import { Passage } from '../../models3/Passage'
import { IDrawablePassageGloss } from '../../models3/PassageGloss'
import { PassageSegmentApproval, PassageSegment } from '../../models3/PassageSegment'
import { PassageVideo } from '../../models3/PassageVideo'
import { ReferenceMarker } from '../../models3/ReferenceMarker'
import { Portion } from '../../models3/Portion'
import { Project } from '../../models3/Project'
import { RefRange } from '../../scrRefs/RefRange'
import { Timeline, TimelineSelection } from '../../models3/RootBase'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:VideoPositionBar')

interface IVideoPositionBar {
    videoPosition: IVideoPosition
}

export interface IVideoPosition {
    currentTime: number
    iAmConsultant: boolean
    resetCurrentTime: (time: number) => void
    duration: number
    timeline: Timeline
    passage: Passage | null
    passageVideo: PassageVideo | null
    drawableGloss: IDrawablePassageGloss | null
    useMobileLayout: boolean
    setDbsRefs: (portion: Portion) => void
    dbsRefs: RefRange[]
    parseReferences: (references: string) => RefRange[]
    project: Project
    portion: Portion | null
    passageSegment: PassageSegment | null
    displayableReferences: (references: RefRange[] | undefined | null) => string
    setDefault: (tag: string, value: string | null) => void
    getDefault: (tag: string) => string | null
    editingSegment: boolean
    verseReference?: ReferenceMarker
}

interface IPoint {
    x: number
    y: number
}

interface IDrawableVerseReference {
    path: Path2D
    boundingBox: Path2D
    reference: ReferenceMarker
}

interface IDrawableSegment {
    startX: number
    segment: PassageSegment
    actualSegment: PassageSegment
    boundingBox: Path2D
    path: Path2D
}

const VIDEO_POSITION_BAR_WIDTH = 640
const VIDEO_POSITION_BAR_HEIGHT = 35
const TIMELINE_START_Y = 10
const TIMELINE_MIDDLE_Y = (VIDEO_POSITION_BAR_HEIGHT - TIMELINE_START_Y) / 2 + TIMELINE_START_Y

class VideoPositionBarDrawer {
    constructor(private canvas: React.RefObject<HTMLCanvasElement>, private videoPosition: IVideoPosition) {
        _.bindAll(
            this,
            'draw',
            'xPosition',
            'drawSelection',
            'drawGloss',
            'drawTimelineBoundary',
            'drawCurrentPositionCursor',
            'drawVerseBoundaries',
            'drawSegments'
        )
    }

    draw(mousePosition: IPoint, verseBoundaries: IDrawableVerseReference[], drawableSegments: IDrawableSegment[]) {
        const ctx = this.canvas.current?.getContext('2d')
        if (!ctx) {
            return
        }

        ctx.clearRect(0, 0, VIDEO_POSITION_BAR_WIDTH, VIDEO_POSITION_BAR_HEIGHT)

        this.drawSelection()
        this.drawSegments(mousePosition, drawableSegments)
        this.drawGloss()
        this.drawTimelineBoundary()
        this.drawCurrentPositionCursor()
        this.drawVerseBoundaries(mousePosition, verseBoundaries)
    }

    private xPosition(time: number) {
        const { videoPosition } = this
        const { timeline } = videoPosition
        return ((time - timeline.start) / timeline.duration) * VIDEO_POSITION_BAR_WIDTH
    }

    private drawSelection() {
        const { videoPosition } = this
        const { xPosition } = this
        const { timeline } = videoPosition
        const { selection } = timeline
        const ctx = this.canvas.current?.getContext('2d')
        if (!ctx) {
            return
        }

        if (selection && selection.start >= 0 && selection.start <= timeline.end && selection.end >= timeline.start) {
            const selectionStartPosition = xPosition(Math.max(selection.start, timeline.start))
            const selectionEndPosition = xPosition(Math.min(selection.end, timeline.end))
            const selectionWidth = selectionEndPosition - selectionStartPosition
            ctx.beginPath()
            ctx.fillStyle = 'lightgray'
            ctx.fillRect(selectionStartPosition, TIMELINE_START_Y, selectionWidth, VIDEO_POSITION_BAR_HEIGHT)
        }
    }

    private drawGloss() {
        const { videoPosition } = this
        const { xPosition } = this
        const { drawableGloss } = videoPosition

        const ctx = this.canvas.current?.getContext('2d')
        if (!ctx) {
            return
        }

        if (drawableGloss) {
            const { time, duration } = drawableGloss
            let xg1 = xPosition(time)
            let xg2 = xPosition(time + duration)

            if (xg1 <= VIDEO_POSITION_BAR_WIDTH && xg2 >= 0) {
                xg1 = Math.max(0, xg1)
                xg2 = Math.max(xg1 + 12, xg2)
                xg2 = Math.min(VIDEO_POSITION_BAR_WIDTH, xg2)

                ctx.strokeStyle = 'purple'
                ctx.lineWidth = 5
                ctx.beginPath()
                ctx.moveTo(xg1, TIMELINE_MIDDLE_Y - 5)
                ctx.lineTo(xg2, TIMELINE_MIDDLE_Y - 5)
                ctx.stroke()
            }
        }
    }

    private drawTimelineBoundary() {
        const { videoPosition } = this
        const { duration, timeline } = videoPosition

        const ctx = this.canvas.current?.getContext('2d')
        if (!ctx) {
            return
        }

        if (timeline.start > 0.1) {
            ctx.strokeStyle = 'black'
            ctx.lineWidth = 3
            ctx.setLineDash([3, 3, 3])
            ctx.beginPath()
            ctx.clearRect(0, TIMELINE_MIDDLE_Y - 1, 20, 3) // x,y,width,height
            ctx.moveTo(0, TIMELINE_MIDDLE_Y)
            ctx.lineTo(20, TIMELINE_MIDDLE_Y)
            ctx.stroke()
        }

        if (timeline.end < duration - 0.1) {
            ctx.strokeStyle = 'black'
            ctx.lineWidth = 3
            ctx.setLineDash([3, 3, 3])
            ctx.beginPath()
            ctx.clearRect(VIDEO_POSITION_BAR_WIDTH - 20, TIMELINE_MIDDLE_Y - 1, 20, 3) // x,y,width,height
            ctx.moveTo(VIDEO_POSITION_BAR_WIDTH - 20, TIMELINE_MIDDLE_Y)
            ctx.lineTo(VIDEO_POSITION_BAR_WIDTH, TIMELINE_MIDDLE_Y)
            ctx.stroke()
        }

        ctx.setLineDash([])
    }

    private drawCurrentPositionCursor() {
        const { videoPosition } = this
        const { xPosition } = this
        const { currentTime } = videoPosition

        const ctx = this.canvas.current?.getContext('2d')
        if (!ctx) {
            return
        }

        const currentTimeX = Math.max(xPosition(currentTime), 1)
        ctx.lineWidth = 2
        ctx.strokeStyle = 'black'
        ctx.beginPath()
        ctx.moveTo(currentTimeX, TIMELINE_MIDDLE_Y - 6)
        ctx.lineTo(currentTimeX, TIMELINE_MIDDLE_Y + 6)
        ctx.stroke()
    }

    private drawVerseBoundaries(mousePosition: IPoint, verseBoundaries: IDrawableVerseReference[]) {
        const { canvas, videoPosition } = this
        const ctx = canvas.current?.getContext('2d')
        if (!ctx) {
            return
        }

        const { displayableReferences, editingSegment, passage } = videoPosition
        const disabled = !!passage?.videoBeingCompressed || editingSegment
        const { x, y } = mousePosition

        for (const boundary of verseBoundaries) {
            const { path, boundingBox, reference } = boundary
            if (ctx.isPointInPath(boundingBox, x, y)) {
                ctx.strokeStyle = disabled ? 'grey' : 'red'
                const text = displayableReferences(reference.references)
                if (text) {
                    ctx.font = '14px sans serif'
                    const measureText = ctx.measureText(text)
                    ctx.fillStyle = 'white'
                    const rectWidth = measureText.width + 7
                    const rectHeight = measureText.actualBoundingBoxAscent + 7
                    let rectX = x + 3
                    let rectY = y - 3 - measureText.actualBoundingBoxAscent
                    if (VIDEO_POSITION_BAR_WIDTH - rectX < rectWidth) {
                        rectX = VIDEO_POSITION_BAR_WIDTH - rectWidth
                    }
                    if (rectY < 0) {
                        rectY = 0
                    }
                    ctx.fillRect(rectX, rectY, rectWidth, rectHeight)
                    ctx.fillStyle = 'black'
                    ctx.fillText(text, rectX + 4, rectY + 4 + measureText.actualBoundingBoxAscent)
                }
            } else {
                ctx.strokeStyle = passage && reference.isOlderThanContainingPassageVideo(passage) ? 'black' : 'grey'
            }
            ctx.stroke(path)
        }
    }

    private drawSegments(mousePosition: IPoint, drawableSegments: IDrawableSegment[]) {
        const { videoPosition } = this
        const { passageVideo, useMobileLayout, editingSegment, passage } = videoPosition

        const disabled = !!passage?.videoBeingCompressed || editingSegment

        const ctx = this.canvas.current?.getContext('2d')
        if (!ctx) {
            return
        }

        const { x, y } = mousePosition

        drawableSegments.forEach((entry, i) => {
            const { startX, segment, actualSegment, path, boundingBox } = entry

            // Draw next part of timeline
            const nextPosition =
                i !== drawableSegments.length - 1 ? drawableSegments[i + 1].startX : VIDEO_POSITION_BAR_WIDTH
            ctx.lineWidth = segment.videoPatchHistory.length > 0 ? 3 : 2
            if (disabled) {
                ctx.strokeStyle = 'grey'
            } else if (segment.videoPatchHistory.length > 0) {
                ctx.strokeStyle = '#da8383'
            } else {
                ctx.strokeStyle = 'black'
            }

            if (actualSegment.ignoreWhenPlayingVideo) {
                ctx.setLineDash([10, 10])
            }
            ctx.beginPath()
            ctx.moveTo(startX, TIMELINE_MIDDLE_Y)
            ctx.lineTo(nextPosition, TIMELINE_MIDDLE_Y)
            ctx.stroke()
            ctx.setLineDash([])

            // Draw segment boundary
            ctx.clearRect(startX - 4, TIMELINE_MIDDLE_Y - 2, 8, 4)
            const isAllowedToDrag = passageVideo && segment.isAllowedToDrag(passageVideo) && !disabled
            ctx.lineWidth = 4
            if (ctx.isPointInPath(boundingBox, x, y) && isAllowedToDrag) {
                ctx.strokeStyle = 'red'
            } else {
                ctx.strokeStyle = 'lightblue'
            }
            ctx.stroke(path)

            function drawCheck() {
                if (!ctx) {
                    return
                }
                ctx.strokeStyle = 'gray'
                ctx.lineWidth = 2
                ctx.beginPath()
                ctx.moveTo(startX + 2, TIMELINE_MIDDLE_Y - 12)
                ctx.lineTo(startX + 4, TIMELINE_MIDDLE_Y - 10)
                ctx.lineTo(startX + 6, TIMELINE_MIDDLE_Y - 14)
                ctx.stroke()
            }

            function drawCircle() {
                if (!ctx) {
                    return
                }
                ctx.strokeStyle = 'gray'
                ctx.lineWidth = 2
                const radius = 4
                ctx.beginPath()
                ctx.arc(startX + radius, radius + 7, radius, 0, 2 * Math.PI, false)
                ctx.stroke()
            }

            if (!useMobileLayout) {
                if (actualSegment.approved === PassageSegmentApproval.State1) {
                    drawCheck()
                } else if (actualSegment.approved === PassageSegmentApproval.State2) {
                    drawCircle()
                } else if (actualSegment.approved === PassageSegmentApproval.State3) {
                    drawCircle()
                    drawCheck()
                }
            }
        })
    }
}

const MIN_DRAG_DISTANCE_PIXELS = 10

export class CanvasDragAndClickDetector {
    @observable mousePosition: IPoint = { x: -1, y: -1 }

    @observable dragStart: IPoint = { x: -1, y: -1 }

    maxDistanceDraggedFromStart = 0

    @observable mousedown = false

    constructor(
        private canvasRef: React.RefObject<HTMLCanvasElement>,
        private onClick: (x: number, y: number) => void,
        private onDrag: (startX: number, startY: number, currentX: number, currentY: number) => void,
        private onDragEnd: (startX: number, startY: number, endX: number, endY: number) => void
    ) {
        this.onDrag = _.throttle(this.onDrag, 100)
        this.displayXToModelX = this.displayXToModelX.bind(this)
        this.displayYToModelY = this.displayYToModelY.bind(this)
        this._mousedown = this._mousedown.bind(this)
        this.mouseup = this.mouseup.bind(this)
        this.mousemove = this.mousemove.bind(this)
        this.mouseleave = this.mouseleave.bind(this)
        const { current } = canvasRef
        if (current) {
            current.addEventListener('mousedown', this._mousedown)
            current.addEventListener('mouseup', this.mouseup)
            current.addEventListener('mousemove', this.mousemove)
            current.addEventListener('mouseleave', this.mouseleave)
        }
    }

    dispose() {
        const { current } = this.canvasRef
        if (current) {
            current.removeEventListener('mousedown', this._mousedown)
            current.removeEventListener('mouseup', this.mouseup)
            current.removeEventListener('mousemove', this.mousemove)
            current.removeEventListener('mouseleave', this.mouseleave)
        }
    }

    displayYToModelY(e: React.MouseEvent) {
        const boundingRect = e.currentTarget.getBoundingClientRect()
        const y = e.clientY - boundingRect.top
        const { current } = this.canvasRef
        const canvasHeight = current?.height || 0
        const canvasOffsetHeight = current?.offsetHeight || 1 // prevent divide by 0
        return y * (canvasHeight / canvasOffsetHeight)
    }

    displayXToModelX(e: React.MouseEvent) {
        const boundingRect = e.currentTarget.getBoundingClientRect()
        const x = e.clientX - boundingRect.left
        const { current } = this.canvasRef
        const canvasWidth = current?.width || 0
        const canvasOffsetWidth = current?.offsetWidth || 1 // prevent divide by 0
        return x * (canvasWidth / canvasOffsetWidth)
    }

    mouseup() {
        const { maxDistanceDraggedFromStart } = this
        const { x, y } = this.mousePosition
        const { x: dragStartX, y: dragStartY } = this.dragStart
        const didDrag = dragStartY > -1 && dragStartX > -1 && maxDistanceDraggedFromStart >= MIN_DRAG_DISTANCE_PIXELS
        if (didDrag) {
            this.onDragEnd(dragStartX, dragStartY, x, y)
        } else {
            this.onClick(x, y)
        }
        this.mousedown = false
        this.dragStart = { x: -1, y: -1 }
        this.maxDistanceDraggedFromStart = 0
    }

    mousemove(e: any) {
        const { mousedown } = this
        const x = this.displayXToModelX(e)
        const y = this.displayYToModelY(e)
        this.mousePosition = { x, y }
        const { x: dragStartX, y: dragStartY } = this.dragStart
        if (mousedown) {
            this.maxDistanceDraggedFromStart = Math.max(this.maxDistanceDraggedFromStart, Math.abs(x - dragStartX))
            const isDragging =
                Math.abs(x - dragStartX) >= MIN_DRAG_DISTANCE_PIXELS ||
                this.maxDistanceDraggedFromStart >= MIN_DRAG_DISTANCE_PIXELS
            if (isDragging) {
                this.onDrag(dragStartX, dragStartY, x, y)
            }
        }
    }

    _mousedown() {
        const { x, y } = this.mousePosition
        this.dragStart = { x, y }
        this.mousedown = true
    }

    mouseleave() {
        this.mousePosition = { x: -1, y: -1 }
        this.dragStart = { x: -1, y: -1 }
        this.mousedown = false
        this.maxDistanceDraggedFromStart = 0
    }
}

class VideoPositionBar extends Component<IVideoPositionBar> {
    private canvas: React.RefObject<HTMLCanvasElement>

    @observable mousePosition: IPoint = { x: -1, y: -1 }

    @observable verseBoundaries: IDrawableVerseReference[] = []

    @observable drawableSegments: IDrawableSegment[] = []

    @observable segment?: PassageSegment

    @observable reference?: ReferenceMarker

    draggingSegmentOrReference = false

    mouseHandler?: CanvasDragAndClickDetector

    constructor(props: IVideoPositionBar) {
        super(props)
        this.canvas = React.createRef()
        this.getVerseReferenceByPoint = this.getVerseReferenceByPoint.bind(this)
        this.getSegmentByPoint = this.getSegmentByPoint.bind(this)
        this.onClick = this.onClick.bind(this)
        this.onDrag = this.onDrag.bind(this)
        this.onDragEnd = this.onDragEnd.bind(this)
        this.xPosition = this.xPosition.bind(this)
        this.gatherVerseBoundaries = this.gatherVerseBoundaries.bind(this)
        this.gatherDrawableSegments = this.gatherDrawableSegments.bind(this)
        this.setSegment = this.setSegment.bind(this)
        this.displayXToModelX = this.displayXToModelX.bind(this)
        this.displayYToModelY = this.displayYToModelY.bind(this)
        this.mousemove = this.mousemove.bind(this)
        this.mouseleave = this.mouseleave.bind(this)
        this.xToTime = this.xToTime.bind(this)
        this.setCurrentTime = this.setCurrentTime.bind(this)
        this.saveSegmentPosition = this.saveSegmentPosition.bind(this)
    }

    async componentDidMount() {
        const { videoPosition } = this.props

        this.mouseHandler = new CanvasDragAndClickDetector(this.canvas, this.onClick, this.onDrag, this.onDragEnd)
        const videoPositionBarDrawer = new VideoPositionBarDrawer(this.canvas, videoPosition)
        this.drawableSegments = this.gatherDrawableSegments()
        this.verseBoundaries = this.gatherVerseBoundaries()
        videoPositionBarDrawer.draw(this.mousePosition, this.verseBoundaries, this.drawableSegments)
    }

    async componentDidUpdate() {
        const { videoPosition } = this.props

        const videoPositionBarDrawer = new VideoPositionBarDrawer(this.canvas, videoPosition)
        this.drawableSegments = this.gatherDrawableSegments()
        this.verseBoundaries = this.gatherVerseBoundaries()
        videoPositionBarDrawer.draw(this.mousePosition, this.verseBoundaries, this.drawableSegments)
    }

    componentWillUnmount() {
        if (this.mouseHandler) {
            this.mouseHandler.dispose()
        }
    }

    async onClick(x: number, y: number) {
        const { verseBoundaries, canvas } = this
        const { videoPosition } = this.props
        const { iAmConsultant, editingSegment, passage } = videoPosition

        const disabled = !!passage?.videoBeingCompressed || editingSegment

        if (disabled) {
            return
        }

        this.setCurrentTime(x)
        videoPosition.timeline.selection = undefined

        const ctx = canvas.current?.getContext('2d')
        if (!ctx) {
            return
        }

        let ref: ReferenceMarker | undefined
        for (const boundary of verseBoundaries) {
            const { boundingBox, reference } = boundary
            if (ctx.isPointInPath(boundingBox, x, y)) {
                ref = reference
            }
        }

        const allowedToEditReference = iAmConsultant
        if (allowedToEditReference && !videoPosition.verseReference) {
            log('onClick', JSON.stringify(ref?.dbg(), null, 4))
            videoPosition.verseReference = ref
        }
    }

    onDrag(startX: number, startY: number, currentX: number) {
        const { videoPosition } = this.props
        const { passageVideo, iAmConsultant, editingSegment, passage } = videoPosition
        const { draggingSegmentOrReference, setSegment, reference, segment } = this

        const disabled = !!passage?.videoBeingCompressed || editingSegment

        if (disabled) {
            return
        }

        const ref = this.getVerseReferenceByPoint(startX, startY)
        const seg = this.getSegmentByPoint(startX, startY)

        const allowedToEditReference = iAmConsultant
        const allowedToEditSegment = iAmConsultant

        if (allowedToEditReference) {
            if (reference) {
                const newTime = this.xToTime(currentX)
                this.saveReference(newTime)
            } else if (ref) {
                this.reference = ref
            }
        }

        if (allowedToEditSegment) {
            if (segment) {
                const newTime = this.xToTime(currentX)
                this.saveSegmentPosition(newTime)
            } else if (seg && passageVideo && seg.isAllowedToDrag(passageVideo)) {
                setSegment(seg)
            }
        }

        if (ref || seg) {
            videoPosition.timeline.selection = undefined
            this.draggingSegmentOrReference = true
        } else if (!draggingSegmentOrReference && startY >= TIMELINE_START_Y) {
            const startTime = this.xToTime(startX)
            const ct = this.xToTime(currentX)
            if (ct < startTime) {
                videoPosition.timeline.selection = new TimelineSelection(ct, startTime)
            } else {
                videoPosition.timeline.selection = new TimelineSelection(startTime, ct)
            }
        }

        this.setCurrentTime(currentX)
    }

    async onDragEnd() {
        const { videoPosition } = this.props
        const { timeline, editingSegment, passage } = videoPosition
        const { selection } = timeline

        const disabled = !!passage?.videoBeingCompressed || editingSegment

        if (disabled) {
            return
        }

        // Make sure startTime < endTime if the user has dragged left
        if (selection && selection.start >= 0 && selection.end >= 0 && selection.start > selection.end) {
            videoPosition.timeline.selection = new TimelineSelection(selection.end, selection.start)
        }

        this.draggingSegmentOrReference = false
        this.setSegment(undefined)
        this.setReference(undefined)
    }

    setCurrentTime(x: number) {
        const { videoPosition } = this.props
        const { resetCurrentTime } = videoPosition
        resetCurrentTime(this.xToTime(x))
    }

    setSegment(segment?: PassageSegment) {
        this.segment = segment
    }

    setReference(reference?: ReferenceMarker) {
        this.reference = reference
        // I dont think we need to do anything with dbsRefs because they should be set based on the position in the timeline
    }

    getSegmentByPoint(x: number, y: number) {
        const { canvas, drawableSegments } = this
        let seg: PassageSegment | undefined
        const ctx = canvas.current?.getContext('2d')
        if (ctx) {
            for (const ds of drawableSegments) {
                const { segment, boundingBox } = ds
                if (ctx.isPointInPath(boundingBox, x, y)) {
                    seg = segment
                }
            }
        }
        return seg
    }

    getVerseReferenceByPoint(x: number, y: number) {
        const { canvas, verseBoundaries } = this
        let ref: ReferenceMarker | undefined
        const ctx = canvas.current?.getContext('2d')
        if (ctx) {
            for (const boundary of verseBoundaries) {
                const { boundingBox, reference } = boundary
                if (ctx.isPointInPath(boundingBox, x, y)) {
                    ref = reference
                }
            }
        }
        return ref
    }

    async saveReference(time: number) {
        const { reference } = this
        const { videoPosition } = this.props
        const { passageVideo, passage, resetCurrentTime } = videoPosition

        if (
            !passageVideo ||
            !passage ||
            !reference ||
            !reference.canChangePositionToTime({
                time,
                computedDuration: passageVideo.computedDuration,
                markers: passageVideo.getVisibleReferenceMarkers(passage)
            })
        ) {
            return
        }

        resetCurrentTime(time)
        const _reference = await passageVideo.saveReferencePosition(passage, time, reference)

        this.setReference(_reference)
    }

    async saveSegmentPosition(segmentTime: number) {
        const { segment } = this
        const { videoPosition } = this.props
        const { passage, passageVideo, resetCurrentTime } = videoPosition

        if (!passage || !passageVideo || !segment || !segment.canChangePositionToTime(segmentTime, passageVideo)) {
            return
        }

        resetCurrentTime(segmentTime)
        await passageVideo.saveSurroundingSegmentPositions(passage, segmentTime, segment)
    }

    gatherVerseBoundaries() {
        const { videoPosition } = this.props
        const { xPosition } = this
        const { passage, passageVideo } = videoPosition

        const ctx = this.canvas.current?.getContext('2d')
        if (!ctx || !passage) {
            return []
        }

        const verseBoundaries: IDrawableVerseReference[] = []
        const visibleReferences = passageVideo?.getVisibleReferenceMarkers(passage) || []
        const sorted = _.sortBy(visibleReferences, 'time')
        for (const reference of sorted) {
            const { time } = reference
            const drawPosition = xPosition(time)
            const boundingBox = new Path2D()
            ctx.lineWidth = 2
            boundingBox.rect(drawPosition - 8, 0, 16, TIMELINE_START_Y)

            ctx.beginPath()
            ctx.lineWidth = 2
            const path = new Path2D()
            path.moveTo(drawPosition - 3, 1)
            path.lineTo(drawPosition, 7)
            path.lineTo(drawPosition + 3, 1)
            verseBoundaries.push({ reference, path, boundingBox })
        }
        return verseBoundaries
    }

    gatherDrawableSegments() {
        const { videoPosition } = this.props
        const { xPosition } = this
        const { passage, passageVideo, timeline } = videoPosition

        const ctx = this.canvas.current?.getContext('2d')
        if (!ctx || !passage) {
            return []
        }

        const segments = (passageVideo && passageVideo.segments) || []
        let drawableSegments: IDrawableSegment[] = []
        for (let i = 0; i < segments.length; ++i) {
            const segment = segments[i]
            const actualSegment = segment.actualSegment(passage)
            const { time } = actualSegment

            if (time + actualSegment.duration < timeline.start) continue
            if (time > timeline.end) continue

            const startX = Math.max(xPosition(time), 0)

            const boundingBox = new Path2D()
            ctx.strokeStyle = 'black'
            ctx.lineWidth = 2
            boundingBox.rect(startX - 4, TIMELINE_START_Y + 1, 8, VIDEO_POSITION_BAR_HEIGHT - TIMELINE_START_Y - 1)

            ctx.beginPath()
            const path = new Path2D()
            ctx.beginPath()
            path.moveTo(startX, TIMELINE_MIDDLE_Y - 10)
            path.lineTo(startX, TIMELINE_MIDDLE_Y + 10)

            const nextEntry = { startX, segment, actualSegment, boundingBox, path }
            drawableSegments.push(nextEntry)
        }

        drawableSegments = _.sortBy(drawableSegments, 'startX')
        return drawableSegments
    }

    displayYToModelY(e: React.MouseEvent) {
        const boundingRect = e.currentTarget.getBoundingClientRect()
        const y = e.clientY - boundingRect.top
        const { current } = this.canvas
        const canvasHeight = current?.height || 0
        const canvasOffsetHeight = current?.offsetHeight || 1 // prevent divide by 0
        return y * (canvasHeight / canvasOffsetHeight)
    }

    displayXToModelX(e: React.MouseEvent) {
        const boundingRect = e.currentTarget.getBoundingClientRect()
        const x = e.clientX - boundingRect.left
        const { current } = this.canvas
        const canvasWidth = current?.width || 0
        const canvasOffsetWidth = current?.offsetWidth || 1 // prevent divide by 0
        return x * (canvasWidth / canvasOffsetWidth)
    }

    mousemove(e: any) {
        const x = this.displayXToModelX(e)
        const y = this.displayYToModelY(e)
        this.mousePosition = { x, y }
    }

    mouseleave() {
        this.mousePosition = { x: -1, y: -1 }
    }

    xPosition(time: number) {
        const { videoPosition } = this.props
        const { timeline } = videoPosition
        return ((time - timeline.start) / timeline.duration) * VIDEO_POSITION_BAR_WIDTH
    }

    xToTime(x: number) {
        const { videoPosition: videoPositionDisplay } = this.props
        const { timeline } = videoPositionDisplay
        const frac = x / VIDEO_POSITION_BAR_WIDTH
        return timeline.start + frac * timeline.duration
    }

    render() {
        const { videoPosition } = this.props
        const { mousePosition } = this

        // WARNING! There are a lot of unused variables in this line.
        // However, extracting them here forces the VideoPositionBar to re-render when
        // one of the changes so be very careful about removing these.
        const {
            passage,
            passageVideo,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            drawableGloss,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            useMobileLayout,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            currentTime,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            duration,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            timeline,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            iAmConsultant,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            editingSegment,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            resetCurrentTime
        } = videoPosition

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { start, end, zoomLevel, selection } = timeline

        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        passage?.videoBeingCompressed

        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        passageVideo?.references

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { x, y } = mousePosition

        // eslint-disable-next-line
        passageVideo && passageVideo._rev // for render on any passage video changes
        // eslint-disable-next-line
        passage && passage._rev // for render on any passage changes
        // log('*** render', passageVideo && passageVideo._rev)

        // let pvs = (passageVideo && passageVideo.segments) || []
        // log('positions', pvs.map(pv => pv.position))

        // Check to see if segment time have changed and if so reset them
        if (passage && passageVideo) {
            passageVideo.setSegmentTimes(passage)
        }

        return (
            <div data-id="video-positionbar">
                <canvas
                    className="video-positionbar"
                    width={VIDEO_POSITION_BAR_WIDTH}
                    height={VIDEO_POSITION_BAR_HEIGHT}
                    ref={this.canvas}
                    onMouseLeave={this.mouseleave.bind(this)}
                    onMouseMove={this.mousemove.bind(this)}
                />
            </div>
        )
    }
}

export default observer(VideoPositionBar)

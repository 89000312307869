import { useState, useEffect } from 'react'
import { Dropdown, FormControl, MenuItem, Modal } from 'react-bootstrap'
import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'

import { Switch } from './Switch'
import { CancelButton, OKButton } from './Buttons'
import { systemError } from './Errors'
import { LoadingIcon } from './Icons'

import { useAppRoot } from '../app/RootContext'

import { MemberRole, memberExistsWithRole } from '../../models3/Member'
import { Passage } from '../../models3/Passage'
import { PassageCopyVisitor } from '../../models3/PassageCopyVisitor'
import { Portion } from '../../models3/Portion'
import { Project } from '../../models3/Project'

import './ProjectModal.css'

const permissionError = (role: MemberRole, t: TFunction) => {
    return `${t(
        'You must have the following role in the source and target projects to complete this operation:'
    )} ${role}`
}

type CopyProjectDataType = 'plan' | 'portions' | 'portion' | 'passage'

interface CopyPassageOptionsProps {
    latestDraftOnly: boolean
    includeResources: boolean
    setLatestDraftOnly: (value: boolean) => void
    setIncludeResources: (value: boolean) => void
}
export const CopyPassageOptions = ({
    latestDraftOnly,
    includeResources,
    setLatestDraftOnly,
    setIncludeResources
}: CopyPassageOptionsProps) => {
    const { t } = useTranslation()

    return (
        <div>
            <h4>{t('Copy latest draft only?')}</h4>
            <div>
                <Switch value={latestDraftOnly} setValue={(value) => setLatestDraftOnly(value)} />
            </div>

            <h4>{t('Copy passage resources?')}</h4>
            <div>
                <Switch value={includeResources} setValue={(value) => setIncludeResources(value)} />
            </div>
        </div>
    )
}
interface CopyProjectDataResultProps {
    setOpenModal: (value: boolean) => void
    destinationProjects: Project[]
    loading: boolean
    error: string
}

const CopyProjectDataResult = (props: CopyProjectDataResultProps) => {
    const { t } = useTranslation()

    const { setOpenModal, destinationProjects, loading, error } = props

    if (loading) {
        return (
            <Modal.Body>
                <div className="project-modal-loading-message-parent">
                    <LoadingIcon className="" />
                    <span className="project-modal-loading-message">{t('Copying...')}</span>
                </div>
            </Modal.Body>
        )
    }

    if (error) {
        return <Modal.Body>{error}</Modal.Body>
    }

    const successMessage =
        destinationProjects.length > 1 ? t('Successfully copied to projects: ') : t('Successfully copied to project: ')

    return (
        <>
            <Modal.Body>
                {successMessage + destinationProjects.map((project) => project.getFormattedDisplayName()).join(', ')}
            </Modal.Body>
            <Modal.Footer>
                <div className="project-modal-footer-buttons">
                    <OKButton
                        enabled
                        onClick={() => setOpenModal(false)}
                        buttonClassName=""
                        className="ok-button"
                        tooltip={t('OK')}
                    />
                </div>
            </Modal.Footer>
        </>
    )
}

interface CopyProjectDataModalProps {
    setOpenModal: (value: boolean) => void
    source: Project
    copyDataType: CopyProjectDataType
    copyData?: Portion | Passage
}

export const CopyProjectDataModal = (props: CopyProjectDataModalProps) => {
    const { t } = useTranslation()

    const { setOpenModal, source, copyDataType, copyData } = props
    const appRoot = useAppRoot()
    const [destinations, setDestinationProjects] = useState<Project[] | undefined>()
    const [selectedProjects, setSelectedProjects] = useState<Project[]>([])
    const [portionsToCopy, setPortionsToCopy] = useState<Portion[]>([])
    const [selectedPortion, setSelectedPortion] = useState<Portion | undefined>()
    const [latestDraftOnly, setLatestDraftOnly] = useState(true)
    const [includeResources, setIncludeResources] = useState(false)
    const [showCopyPage, setShowCopyPage] = useState(false)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState('')

    const excludeProject = copyDataType === 'plan' || copyDataType === 'portions' ? source.name : undefined

    const requiredRole: MemberRole = copyDataType === 'plan' || copyDataType === 'portions' ? 'admin' : 'translator'

    const warning =
        copyDataType === 'plan'
            ? t('This will overwrite the existing data attached to the destination project.')
            : t('This will not overwrite the existing data attached to the destination project, but will add it.')

    const header = () => {
        switch (copyDataType) {
            case 'passage':
                return t('Copy passage: ') + (copyData as Portion).name
            case 'plan':
                return t('Copy plan')
            case 'portion':
                return t('Copy portion: ') + (copyData as Portion).name
            case 'portions':
                return t('Copy portions')
            default:
                return ''
        }
    }

    const copy = async () => {
        try {
            setLoading(true)
            if (
                memberExistsWithRole({ members: source.members, email: appRoot.username, requiredRole }) &&
                selectedProjects.every((project) =>
                    memberExistsWithRole({ members: project.members, email: appRoot.username, requiredRole })
                )
            ) {
                if (copyDataType === 'plan') {
                    await Promise.all(selectedProjects.map((project) => project.setViewableStagesFromExisting(source)))
                } else if (copyDataType === 'passage' && selectedPortion) {
                    await Promise.all(
                        selectedProjects.map((project) => {
                            const visitor = new PassageCopyVisitor(project, source, selectedPortion, includeResources)
                            return visitor.visitPassage(copyData as Passage, latestDraftOnly)
                        })
                    )
                } else if (copyDataType === 'portion') {
                    await Promise.all(
                        selectedProjects.map((sourceProject) =>
                            sourceProject.setPortionFromExisting({
                                sourceProject: source,
                                portion: copyData as Portion,
                                includeResources,
                                latestDraftOnly
                            })
                        )
                    )
                } else if (copyDataType === 'portions') {
                    await Promise.all(
                        selectedProjects.flatMap((sourceProject) =>
                            sourceProject.setPortionsFromExisting({
                                sourceProject,
                                portions: portionsToCopy,
                                includeResources,
                                latestDraftOnly
                            })
                        )
                    )
                }
            } else {
                setError(permissionError(requiredRole, t))
            }
        } catch (e) {
            console.log(e)
            systemError(e)
            setError(t('Could not copy.'))
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        const initializeRoots = async () => {
            setDestinationProjects(undefined)
            await Promise.all(appRoot.rts.map((rt) => rt.initialize()))

            // NOTE: rts projects do not contain latest rt.iAm permissions, so check members directly
            const sortedProjects = appRoot.getProjectsByRole({ requiredRole, excludeProject })
            setDestinationProjects(sortedProjects)
        }
        initializeRoots()
    }, [appRoot, excludeProject, requiredRole])

    const renderBodyAndFooter = () => {
        if (!destinations) {
            return (
                <Modal.Body>
                    <LoadingIcon className="" />
                </Modal.Body>
            )
        }

        if (showCopyPage && selectedProjects.length) {
            return (
                <CopyProjectDataResult
                    destinationProjects={selectedProjects}
                    setOpenModal={setOpenModal}
                    loading={loading}
                    error={error}
                />
            )
        }

        const hasProjects = destinations.length >= 1

        const renderPortions = (project: Project) => {
            if (project.portions.length === 0) {
                return <div>{t('There are no portions in the selected project.')}</div>
            }

            return (
                <div>
                    <h4>{t('Destination portion')}</h4>
                    <Dropdown id="copy-passage-modal-portion-dropdown">
                        <Dropdown.Toggle className="sl-dropdown">
                            {selectedPortion?.name ?? t('Choose one')}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {project.portions.map((portion) => (
                                <MenuItem key={portion._id} onSelect={() => setSelectedPortion(portion)}>
                                    {portion.name}
                                </MenuItem>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            )
        }

        const _setPortionsToCopy = (portionIds: string[]) => {
            const portions = source.portions.filter((portion) => portionIds.includes(portion._id))
            setPortionsToCopy(portions)
        }

        const _setSelectedProjects = (projectNames: string[]) => {
            const projects = appRoot.rts.filter((rt) => projectNames.includes(rt.name)).map((rt) => rt.project)
            setSelectedProjects(projects)
        }

        const renderPortionsToCopy = () => {
            return (
                <div>
                    <h4>{t('Portions to copy')}</h4>
                    {portionsToCopy.map((portion) => portion.name).join(', ')}
                    <FormControl
                        componentClass="select"
                        multiple
                        onChange={(e: any) => {
                            _setPortionsToCopy(Array.from(e.target.selectedOptions).map((opt: any) => opt.value))
                        }}
                    >
                        {source.portions.map((portion) => (
                            <option key={portion._id} value={portion._id}>
                                {portion.name}
                            </option>
                        ))}
                    </FormControl>
                </div>
            )
        }

        return (
            <>
                <Modal.Body>
                    <div className="project-modal-section">
                        {hasProjects && (
                            <>
                                <div>
                                    {copyDataType === 'portions' ? (
                                        <>
                                            <h4>{t('Destination projects')}</h4>
                                            <FormControl
                                                componentClass="select"
                                                multiple
                                                onChange={(e: any) =>
                                                    _setSelectedProjects(
                                                        Array.from(e.target.selectedOptions).map(
                                                            (opt: any) => opt.value
                                                        )
                                                    )
                                                }
                                            >
                                                {destinations.map((project) => (
                                                    <option key={project.name} value={project.name}>
                                                        {project.getFormattedDisplayName()}
                                                    </option>
                                                ))}
                                            </FormControl>
                                        </>
                                    ) : (
                                        <>
                                            <h4>{t('Destination project')}</h4>
                                            <Dropdown id="project-modal-project-dropdown">
                                                <Dropdown.Toggle className="sl-dropdown">
                                                    {selectedProjects.length
                                                        ? selectedProjects[0].getFormattedDisplayName()
                                                        : t('Choose one')}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    {destinations.map((project) => (
                                                        <MenuItem
                                                            key={project.name}
                                                            onSelect={() => {
                                                                setSelectedProjects([project])
                                                                setSelectedPortion(undefined)
                                                            }}
                                                        >
                                                            {project.getFormattedDisplayName()}
                                                        </MenuItem>
                                                    ))}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </>
                                    )}
                                </div>
                                {copyDataType === 'passage' &&
                                    selectedProjects.length > 0 &&
                                    renderPortions(selectedProjects[0])}
                                {copyDataType === 'portions' && renderPortionsToCopy()}
                                {(copyDataType === 'passage' ||
                                    copyDataType === 'portion' ||
                                    copyDataType === 'portions') && (
                                    <CopyPassageOptions
                                        latestDraftOnly={latestDraftOnly}
                                        includeResources={includeResources}
                                        setLatestDraftOnly={setLatestDraftOnly}
                                        setIncludeResources={setIncludeResources}
                                    />
                                )}
                            </>
                        )}
                        {!hasProjects && permissionError(requiredRole, t)}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="project-modal-footer-buttons">
                        {hasProjects && (
                            <OKButton
                                enabled={
                                    copyDataType === 'portions'
                                        ? selectedProjects.length > 0 && portionsToCopy.length > 0
                                        : copyDataType === 'passage'
                                        ? selectedProjects.length > 0 && !!selectedPortion
                                        : selectedProjects.length > 0
                                }
                                onClick={() => {
                                    if (selectedProjects.length) {
                                        setShowCopyPage(true)
                                        copy()
                                    }
                                }}
                                buttonClassName=""
                                className="ok-button"
                                tooltip={t('Copy')}
                            />
                        )}
                        <CancelButton
                            enabled
                            onClick={() => setOpenModal(false)}
                            className="cancel-button"
                            tooltip={t('Cancel')}
                        />
                    </div>
                </Modal.Footer>
            </>
        )
    }

    return (
        <Modal show onHide={() => setOpenModal(false)} backdrop="static">
            <Modal.Header closeButton>
                <h3>{header()}</h3>
                <div>{warning}</div>
            </Modal.Header>
            {renderBodyAndFooter()}
        </Modal>
    )
}

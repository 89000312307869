import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { KeyTermButton } from '../utils/Buttons'
import { getTermSenseName } from '../../scrRefs/Lemmas'
import ProjectTermCollection from '../../scrRefs/ProjectTerms'

import { PassageGloss } from '../../models3/PassageGloss'
import { Project } from '../../models3/Project'

import './Gloss.css'

// set width based on time width/scale
// show right border
// allow edit text
// allow delete
// allow play
// allow next gloss

function getTermInfos(gloss: PassageGloss, termCollection: ProjectTermCollection) {
    const terms = termCollection.getProjectTermsByGloss(gloss.text)
    let termsArray = Array.from(terms)
    termsArray = termsArray.filter((term) => term.isKeyTerm)
    const termInfos: { id: string; name: string }[] = []
    for (const term of termsArray) {
        const lemma = term.getMarbleLemma()
        if (lemma) {
            const lexMeaning = lemma.meanings.find((meaning) => meaning.lexicalLink === term.lexicalLink)
            let name = ''
            if (lexMeaning) {
                name = getTermSenseName(lemma, lexMeaning)
            }
            termInfos.push({ id: term.lexicalLink, name })
        }
    }
    return termInfos
}

interface IGlossView {
    gloss: PassageGloss
    width: number
    last: boolean // last gloss displayed in GlossBar
    focused: boolean // this gloss is open in GlossEditor
    onClick: () => void
    project: Project
    setTermId: (termId: string) => void
}

const GlossView = observer(({ gloss, width, onClick, last, focused, project, setTermId }: IGlossView) => {
    const { t } = useTranslation()
    const termInfos = getTermInfos(gloss, project.termCollection)

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { _rev } = gloss

    let className = last ? 'gloss-box gloss-box-last' : 'gloss-box'
    if (focused) className += ' gloss-box-focused'
    // log(`render |text=${gloss.text}| [duration=${duration.toFixed(2)}]`)

    return (
        <div className={className} data-id={`gloss-${gloss.text}`} style={{ width }} onClick={onClick}>
            {gloss.text}
            {termInfos.map((info) => (
                <KeyTermButton
                    key={info.id}
                    className="gloss-key-term-icon fa-fw"
                    buttonClassName="gloss-key-term-button"
                    onClick={(e: any) => {
                        // Make sure no one else handles this event
                        e.stopPropagation()
                        setTermId(info.id)
                    }}
                    enabled
                    tooltip={`${t('viewBiblicalTerm')} (${info.name})`}
                />
            ))}
        </div>
    )
})

export default GlossView

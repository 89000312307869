import { DropdownButton, MenuItem } from 'react-bootstrap'

import { PassageVideo } from '../../models3/PassageVideo'
import { Root } from '../../models3/Root'

import '../translation/Translation.css'
import './Segments.css'

interface OldSegmentDropdownProps {
    rt: Root
    options: PassageVideo[]
    onSelect: (id: string) => void
    enabled: boolean
}

export const OldSegmentDropdown = ({ rt, options, onSelect, enabled }: OldSegmentDropdownProps) => {
    const { dateFormatter } = rt

    return (
        <DropdownButton
            bsStyle="default"
            title=""
            id="old-segment-dropdown"
            className="sl-dropdown old-segment-dropdown"
            disabled={!enabled}
        >
            {options.map((item) => (
                <MenuItem key={item._id} eventKey={item._id} onClick={() => onSelect(item._id)}>
                    {dateFormatter.format(new Date(item.creationDate))}
                </MenuItem>
            ))}
        </DropdownButton>
    )
}

import React, { Component } from 'react'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import { t } from 'i18next'

import TextInput from '../utils/TextInput'
import { displayError } from '../utils/Errors'
import { Root } from '../../models3/Root'

interface IPortionAdder {
    rt: Root
}

class PortionAdder extends Component<IPortionAdder> {
    @observable adding = false

    onCancel() {
        this.adding = false
    }

    onEnter(newPortionName: string) {
        const { rt } = this.props
        const { project } = rt

        if (this.validate(newPortionName)) {
            this.adding = false
            return
        }

        project
            .addPortion(newPortionName)
            .then((portion) => {
                rt.setPortion(portion)
                this.adding = false
            })
            .catch((err: any) => {
                displayError(err)
                this.adding = false
            })
    }

    validate(newPortionName: string) {
        const { rt } = this.props

        newPortionName = newPortionName.trim()

        if (newPortionName === '') {
            return t('Empty name')
        }

        if (rt.project.portions.some((portion) => portion.name === newPortionName)) {
            return t('Duplicate name')
        }

        return ''
    }

    render() {
        const { rt } = this.props
        const { project, iAmTranslator } = rt
        const { portions } = project

        if (portions.length === 0 && !iAmTranslator) {
            return <p>{t('No portions present yet for this project')}</p>
        }

        if (!iAmTranslator) return null

        if (!this.adding)
            return (
                <div>
                    {portions.length === 0 && (
                        <span>
                            {t('Projects are divided into portions.')}
                            <br />
                            <br />
                            {t(`For example, a portion might be called 'Mark 1'.`)}
                            <br />
                            <br />
                            {t('Click the plus sign below to add a portion to this project.')}
                            <br />
                            <br />
                        </span>
                    )}
                    <span
                        onClick={() => {
                            this.adding = true
                        }}
                        data-toggle="tooltip"
                        title={t('Add portion.')}
                        className="far fa-fw fa-plus-square portion-add-button"
                    />
                </div>
            )

        return (
            <div className="passage-box">
                <TextInput
                    message={t('Type Enter to add new portion or Esc to cancel.')}
                    initialValue=""
                    validate={this.validate.bind(this)}
                    _onEscape={this.onCancel.bind(this)}
                    _onEnter={this.onEnter.bind(this)}
                />
            </div>
        )
    }
}

export default observer(PortionAdder)

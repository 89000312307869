import { observer } from 'mobx-react'
import { FC, useCallback, useEffect, useState } from 'react'
import { confirmAlert } from 'react-confirm-alert'
import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'

import { PassageResourceFilePicker } from './PassageResourceFilePicker'
import './Passage.css'
import { useAppRoot } from '../app/RootContext'
import { isAVTT, sat } from '../app/slttAvtt'

import {
    AddButton,
    CancelButton,
    GoBackButton,
    GoForwardButton,
    LockButton,
    OKButton,
    PencilButton,
    TrashButton,
    UnlockButton
} from '../utils/Buttons'
import DropTarget from '../utils/DropTarget'
import { DropTargetViewLarge } from '../utils/DropTargetView'
import { displayError, systemError } from '../utils/Errors'
import { fmt } from '../utils/Fmt'
import { LoadingIcon } from '../utils/Icons'
import { EditableRichText } from '../utils/RichTextEditor'
import TextInput from '../utils/TextInput'
import { BlobDownloading } from '../utils/VideoDownloading'

import { OralPassageResource } from '../../models3/OralPassageResource'
import { Passage, PassageResource } from '../../models3/Passage'
import { PassageDocument } from '../../models3/PassageDocument'
import { PassagePDF } from '../../models3/PassagePDF'
import { Root } from '../../models3/Root'
import { VideoCache } from '../../models3/VideoCache'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:PassageDocuments')

type PassageResourceWithGroup = {
    group?: string
    resource: PassageResource
}

function resourceTitle(resource: PassageResource, index: number) {
    if (resource.title) return resource.title

    return `#${index + 1}`
}

function deleteResource(onConfirm: () => Promise<void>, onCancel: () => void, t: TFunction) {
    confirmAlert({
        title: sat(t('Delete document?'), t('Delete resource')),
        message: sat(
            t('Are you sure you want to delete this document?'),
            t('Are you sure you want to delete this resource?')
        ),
        confirmLabel: t('Delete'),
        cancelLabel: t('Cancel'),
        onConfirm,
        onCancel
    })
}

const Link = observer(({ url, linkText }: PDFLinkProps) => {
    const [blob, setBlob] = useState<Blob>()
    const [blobSrc, setBlobSrc] = useState('')

    useEffect(() => {
        let src = ''
        if (blob) {
            src = window.URL.createObjectURL(blob)
            setBlobSrc(src)
        }

        return () => {
            if (src.trim() !== '') {
                window.URL.revokeObjectURL(src)
            }
        }
    }, [blob])

    useEffect(() => {
        setBlob(undefined)
        setBlobSrc('')
    }, [url])

    if (url.trim() !== '' && blobSrc.trim() === '') {
        return (
            <div className="audio-player-wrapper">
                <BlobDownloading {...{ url, onEnded: setBlob }} />
            </div>
        )
    }

    return (
        <a href={blobSrc} target="_blank" rel="noreferrer">
            {linkText}
        </a>
    )
})

type AudioPlayerProps = {
    url: string
}

const AudioPlayer = observer(({ url }: AudioPlayerProps) => {
    const [audioBlob, setAudioBlob] = useState<Blob>()
    const [audioBlobSrc, setAudioBlobSrc] = useState('')

    useEffect(() => {
        let src = ''
        if (audioBlob) {
            src = window.URL.createObjectURL(audioBlob)
            setAudioBlobSrc(src)
        }

        return () => {
            if (src.trim() !== '') {
                window.URL.revokeObjectURL(src)
            }
        }
    }, [audioBlob])

    useEffect(() => {
        setAudioBlob(undefined)
        setAudioBlobSrc('')
    }, [url])

    if (url.trim() !== '' && audioBlobSrc.trim() === '') {
        return (
            <div className="audio-player-wrapper">
                <BlobDownloading {...{ url, onEnded: setAudioBlob }} />
            </div>
        )
    }

    return (
        <div className="audio-player-wrapper">
            <audio src={audioBlobSrc} className="hundred-percent-width" controls />
        </div>
    )
})

type PassageDocumentEditorProps = {
    document: PassageDocument
    passage: Passage
    closeNewEditor: () => void
    rt: Root
    setEditing: (editing: boolean) => void
    readOnly: boolean
}

// If this is a long document retrieve its text from S3
const PassageDocumentEditor: FC<PassageDocumentEditorProps> = observer(
    ({ document, passage, closeNewEditor, rt, setEditing: _setEditing, readOnly }) => {
        const { t } = useTranslation()
        const { username, iAmAdmin, iAmInterpreter } = rt

        const editingNewDocument = !passage.documents.find((doc) => doc._id === document._id)

        const [editing, setEditing] = useState(false)
        const [editingTitle, setEditingTitle] = useState(false)
        const [savedText, setSavedText] = useState(editingNewDocument ? '' : '...')
        const [uploadingAudio, setUploadingAudio] = useState(false)
        const [uploadingPDF, setUploadingPDF] = useState(false)

        const { title } = document
        log('PassageDocumentEditor render', fmt({ title, documentTitle: document.title }))

        // 0 means most recent version of document, 1 next most recent, ...
        const [historyIndex, setHistoryIndex] = useState(0)

        // On initial load, get most recent text for document
        useEffect(() => {
            async function _loadText() {
                // We don't want to load an updated text when editing because the user
                // would lose their edits.
                if (editing) return

                log('PassageDocumentEditor _loadText')
                // getText deals with the fact that large documents are stored in S3 since
                // they are too big to store in DynamoDB.
                setSavedText(await document.getText(0, VideoCache))

                // When a new document edit arrives, make it the one in view
                // by resetting history location.
                // The user needs to realize that there is new document.
                setHistoryIndex(0)
            }

            _loadText()
        }, [document, editing])

        useEffect(() => {
            _setEditing(editing)
        }, [_setEditing, editing])

        function deleteDocument() {
            async function onConfirm() {
                await passage.removePassageDocument(document._id)
                closeNewEditor()
            }

            function onCancel() {}

            deleteResource(onConfirm, onCancel, t)
        }

        const canModifyEditingStatus = !readOnly && (iAmAdmin || document.creator === username)
        const editButtonsEnabled = !readOnly && (document.editable || canModifyEditingStatus)

        async function _setHistoryIndex(index: number) {
            setHistoryIndex(index)
            const text = await document.getText(index, VideoCache)
            setSavedText(text)
        }

        const uploadAudioFile = useCallback(
            async (file: File) => {
                try {
                    setUploadingAudio(true)
                    await document.uploadAudioFile(file, rt.project.name)
                    setUploadingAudio(false)
                } catch (error) {
                    systemError(error)
                } finally {
                    closeNewEditor()
                }
            },
            [closeNewEditor, document, rt.project.name]
        )

        const uploadPDFFile = useCallback(
            async (file: File) => {
                setUploadingPDF(true)
                await document.uploadPDFFile(file, rt.project.name)
                setUploadingPDF(false)
                closeNewEditor()
            },
            [closeNewEditor, document, rt.project.name]
        )

        return (
            <>
                {iAmInterpreter && (
                    <>
                        <div className="passage-document-toolbar">
                            <div className="passage-document-toolbar-push-right">
                                <TrashButton
                                    onClick={deleteDocument}
                                    enabled={editButtonsEnabled}
                                    buttonClassName="button-right-align"
                                    className="passage-document-button"
                                    tooltip={t('Delete')}
                                />
                                {document.editable && canModifyEditingStatus && (
                                    <UnlockButton
                                        enabled
                                        onClick={() => document.setEditable(false)}
                                        className="passage-document-button"
                                        tooltip={t('Allow only admin or document creator to edit')}
                                    />
                                )}
                                {!document.editable && canModifyEditingStatus && (
                                    <LockButton
                                        enabled
                                        onClick={() => document.setEditable(true)}
                                        className="passage-document-button"
                                        tooltip={t('Allow anyone to edit')}
                                    />
                                )}
                            </div>
                        </div>
                        <div className="passage-resource-separator" />
                    </>
                )}
                <div className="document-title-header">
                    <h4>{t('Title')}</h4>
                    <div className="margin-left-5px">
                        <PencilButton
                            enabled={!editingTitle && editButtonsEnabled}
                            onClick={() => setEditingTitle(true)}
                            className="passage-document-button passage-document-edit-button"
                            tooltip={t('Edit')}
                        />
                    </div>
                </div>
                {!editingTitle && (
                    <div className="passage-document-title">
                        <input type="text" value={title} readOnly />
                    </div>
                )}
                {editingTitle && (
                    <div className="passage-document-title">
                        <TextInput
                            message=""
                            initialValue={title}
                            _onEnter={async (value) => {
                                await document.setTitle(value, false)
                                setEditingTitle(false)
                                if (editingNewDocument && value !== '') {
                                    closeNewEditor()
                                }
                            }}
                            _onEscape={() => {
                                setEditingTitle(false)
                            }}
                            allowEmptyValue
                        />
                    </div>
                )}
                <div className="document-title-header">
                    <h4>{t('Audio')}</h4>
                    <div className="margin-left-5px">
                        <PassageResourceFilePicker
                            enabled={editButtonsEnabled}
                            className="passage-document-button passage-resource-import-button fa-fw"
                            resourceType="audio"
                            uploadFile={uploadAudioFile}
                        />
                    </div>
                </div>
                {uploadingAudio && <LoadingIcon className="" />}
                {!uploadingAudio && document.audioUrl.trim() !== '' && <AudioPlayer url={document.audioUrl} />}
                <div className="document-title-header">
                    <h4>{t('PDF')}</h4>
                    <div className="margin-left-5px">
                        <PassageResourceFilePicker
                            enabled={editButtonsEnabled}
                            className="passage-document-button passage-resource-import-button fa-fw"
                            resourceType="pdf"
                            uploadFile={uploadPDFFile}
                        />
                    </div>
                </div>
                {uploadingPDF && <LoadingIcon className="" />}
                {!uploadingPDF && document.pdfUrl.trim() !== '' && <Link url={document.pdfUrl} linkText="Open PDF" />}
                <div className="document-title-header">
                    <h4>{t('Text')}</h4>
                    <div className="passage-document-toolbar">
                        <div className="passage-document-toolbar-group-left">
                            <div className="margin-left-5px">
                                <PencilButton
                                    enabled={!editing && editButtonsEnabled}
                                    onClick={() => setEditing(true)}
                                    className="passage-document-button passage-document-edit-button passage-document-title-edit-button"
                                    tooltip={t('Edit')}
                                />
                            </div>
                        </div>
                        <div className="passage-document-toolbar-group-right">
                            <GoBackButton
                                enabled={!editing && historyIndex < document.textHistory.length - 1}
                                onClick={() => _setHistoryIndex(historyIndex + 1).catch(displayError)}
                                className="passage-document-button"
                                tooltip={t('View earlier document.')}
                            />
                            <GoForwardButton
                                enabled={!editing && historyIndex > 0}
                                onClick={() => _setHistoryIndex(historyIndex - 1).catch(displayError)}
                                className="passage-document-button"
                                tooltip={t('View later document')}
                            />
                        </div>
                    </div>
                </div>
                <EditableRichText
                    sizeLimit={0}
                    savedText={savedText}
                    save={(_text?: string) => {
                        async function _save() {
                            log('PassageDocumentEditor save', fmt({ text: _text?.slice(50) }))

                            if (_text !== undefined) {
                                await document.setText(_text, rt.project.name)
                                setSavedText(_text)
                            }

                            setHistoryIndex(0)
                            setEditing(false)

                            if (editingNewDocument && _text !== '') {
                                closeNewEditor()
                            }
                        }
                        _save().catch(displayError)
                    }}
                    cancel={() => {
                        log(`PassageDocumentEditor cancel, editingNewDocument=${editingNewDocument}`)
                        setEditing(false)
                    }}
                    editorOpen={editing}
                    setEditorOpen={setEditing}
                    prefix="passage-document"
                />
            </>
        )
    }
)

type PassagePDFDropTargetProps = {
    rt: Root
    passage: Passage
    resource: PassagePDF
    closeEditor: () => void
}

const PassagePDFDropTarget: FC<PassagePDFDropTargetProps> = observer(
    ({ rt, passage, resource, closeEditor, children }) => {
        const { t } = useTranslation()

        async function uploadFile(file: File) {
            let title = file.name
            const parts = file.name.split('.')
            if (parts.length > 0) {
                // everything after a period in a filename is the extension
                title = parts[0]
            }
            resource.title = title
            await passage.addAndUploadPassagePDF(file, rt.project.name, resource)
            closeEditor()
        }

        async function upload(files: FileList) {
            const { iAmInterpreter } = rt

            if (!iAmInterpreter) {
                displayError(t('You must be at least an interpreter.'))
                return
            }

            if (files.length !== 1) {
                displayError(t('You must drop exactly one file.'))
                return
            }

            const file = files[0]
            const allowedFileTypes = ['application/pdf']
            if (!allowedFileTypes.includes(file.type)) {
                displayError(t('Cannot upload this type of file.'))
                return
            }

            const maxFileSize = 10 * 1024 * 1024
            if (file.size > maxFileSize) {
                displayError(t('File must be smaller than 10MB.'))
                return
            }

            await uploadFile(file)
        }

        const message = <div>{t('Drop a PDF file here.')}</div>
        const dropTargetView = <DropTargetViewLarge message={message} />

        return <DropTarget {...{ upload, dropTargetView }}>{children}</DropTarget>
    }
)

type PDFEditorProps = {
    pdf: PassagePDF
    setEditing: (value: boolean) => void
}

const PDFEditor = observer(({ pdf, setEditing }: PDFEditorProps) => {
    const { t } = useTranslation()
    const [title, setTitle] = useState(pdf.getTitle())
    const [description, setDescription] = useState(pdf.description)

    const linkText = `${pdf.getTitle().split('.').join('-')}.pdf`

    return (
        <>
            <div className="passage-pdf-toolbar">
                <div className="two-column-first-group">
                    <OKButton
                        enabled
                        onClick={async () => {
                            await pdf.setTitle(title, false)
                            await pdf.setDescription(description)
                            setEditing(false)
                        }}
                        buttonClassName=""
                        className="small-passage-document-button ok-icon"
                        tooltip={t('Save')}
                    />
                    <CancelButton
                        enabled
                        onClick={() => setEditing(false)}
                        className="small-passage-document-button"
                        tooltip={t('Cancel')}
                    />
                </div>
                <div className="two-column-last-group" />
            </div>
            <div className="passage-pdf-title">
                <input type="text" value={title} placeholder={t('Title')} onChange={(e) => setTitle(e.target.value)} />
            </div>
            <Link url={pdf.url} linkText={linkText} />
            <div className="margin-bottom-10px" />
            <textarea
                autoFocus
                value={description}
                placeholder={t('Description...')}
                className="passage-pdf-description"
                onChange={(e) => {
                    if (e.target.value.length <= PassagePDF.MAX_DESCRIPTION_CHARACTERS) {
                        setDescription(e.target.value)
                    }
                }}
            />
            <div>
                {description.length}/{PassagePDF.MAX_DESCRIPTION_CHARACTERS}
            </div>
        </>
    )
})

type PDFViewerProps = {
    pdf: PassagePDF
    passage: Passage
    setEditing: (value: boolean) => void
    closeEditor: () => void
}

const PDFViewer = observer(({ pdf, setEditing, passage, closeEditor }: PDFViewerProps) => {
    const { t } = useTranslation()

    function deleteDocument() {
        async function onConfirm() {
            await passage.removePassagePDF(pdf._id)
            closeEditor()
        }

        function onCancel() {}

        deleteResource(onConfirm, onCancel, t)
    }

    const linkText = `${pdf.getTitle().split('.').join('-')}.pdf`

    return (
        <>
            <div className="passage-pdf-toolbar">
                <div className="two-column-first-group">
                    <PencilButton
                        enabled
                        onClick={() => setEditing(true)}
                        className="small-passage-document-button blue-button"
                        tooltip={t('Edit')}
                    />
                </div>
                <div className="two-column-last-group">
                    <TrashButton
                        onClick={deleteDocument}
                        enabled
                        buttonClassName="button-right-align"
                        className="small-passage-document-button"
                        tooltip={t('Delete')}
                    />
                </div>
            </div>
            <Link url={pdf.url} linkText={linkText} />
            <div className="margin-bottom-10px" />
            <textarea
                value={pdf.description}
                className="passage-pdf-description"
                placeholder={t('Description...')}
                readOnly
            />
        </>
    )
})

type PDFScreenProps = {
    rt: Root
    resource: PassagePDF
    passage: Passage
    closeEditor: () => void
    setEditing: (editing: boolean) => void
}

const PDFScreen = observer(({ rt, resource, passage, closeEditor, setEditing: _setEditing }: PDFScreenProps) => {
    const { t } = useTranslation()
    const [editing, setEditing] = useState(false)

    useEffect(() => {
        _setEditing(editing)
    }, [_setEditing, editing])

    const editingNewResource = !passage.pdfs.find((pdf) => pdf._id === resource._id)
    if (editingNewResource) {
        return (
            <PassagePDFDropTarget passage={passage} resource={resource} rt={rt} closeEditor={closeEditor}>
                <div className="passage-pdf-tab">
                    <p>{t('Drop a PDF file here.')}</p>
                    <CancelButton
                        enabled
                        onClick={() => closeEditor()}
                        className="small-passage-document-button"
                        tooltip={t('Cancel')}
                    />
                </div>
            </PassagePDFDropTarget>
        )
    }

    if (editing) {
        return (
            <div className="passage-pdf-tab flex-column">
                <PDFEditor pdf={resource} setEditing={setEditing} />
            </div>
        )
    }

    return (
        <div className="passage-pdf-tab flex-column">
            <PDFViewer pdf={resource} passage={passage} setEditing={setEditing} closeEditor={closeEditor} />
        </div>
    )
})

type PDFLinkProps = {
    url: string
    linkText: string
}

type OralResourceDropTargetProps = {
    rt: Root
    passage: Passage
    resource: OralPassageResource
    closeEditor: () => void
}

const OralResourceDropTarget: FC<OralResourceDropTargetProps> = observer(
    ({ rt, passage, resource, closeEditor, children }) => {
        const { t } = useTranslation()

        async function uploadFile(file: File) {
            let title = file.name
            const parts = file.name.split('.')
            if (parts.length > 0) {
                // everything after a period in a filename is the extension
                title = parts[0]
            }
            resource.title = title
            await passage.addAndUploadOralPassageDocument(file, rt.project.name, resource)
            closeEditor()
        }

        async function upload(files: FileList) {
            const { iAmInterpreter } = rt

            if (!iAmInterpreter) {
                displayError(t('You must be at least an interpreter.'))
                return
            }

            if (files.length !== 1) {
                displayError(t('You must drop exactly one file.'))
                return
            }

            const file = files[0]
            const allowedFileTypes = ['audio/mpeg']
            if (!allowedFileTypes.includes(file.type)) {
                displayError(t('Cannot upload this type of file.'))
                return
            }

            const maxFileSize = 50 * 1024 * 1024
            if (file.size > maxFileSize) {
                displayError(t('File must be smaller than 50MB.'))
                return
            }

            await uploadFile(file)
        }

        const message = <div>{t('Drop an mp3 file here.')}</div>
        const dropTargetView = <DropTargetViewLarge message={message} />
        return <DropTarget {...{ upload, dropTargetView }}>{children}</DropTarget>
    }
)

type OralResourceEditorProps = {
    resource: OralPassageResource
    setEditing: (value: boolean) => void
}

const OralResourceEditor = observer(({ setEditing, resource }: OralResourceEditorProps) => {
    const { t } = useTranslation()
    const [title, setTitle] = useState(resource.title ?? '')
    const [description, setDescription] = useState(resource.description)

    return (
        <>
            <div className="oral-passage-document-toolbar">
                <div className="two-column-first-group">
                    <OKButton
                        enabled
                        onClick={async () => {
                            await resource.setTitle(title, false)
                            await resource.setDescription(description)
                            setEditing(false)
                        }}
                        buttonClassName=""
                        className="small-passage-document-button ok-icon"
                        tooltip={t('Save')}
                    />
                    <CancelButton
                        enabled
                        onClick={() => setEditing(false)}
                        className="small-passage-document-button"
                        tooltip={t('Cancel')}
                    />
                </div>
                <div className="two-column-last-group" />
            </div>
            <div className="oral-passage-document-title">
                <input type="text" value={title} placeholder={t('Title')} onChange={(e) => setTitle(e.target.value)} />
            </div>
            <AudioPlayer url={resource.url} />
            <textarea
                autoFocus
                value={description}
                placeholder={t('Description...')}
                className="oral-passage-document-description"
                onChange={(e) => {
                    if (e.target.value.length <= OralPassageResource.MAX_DESCRIPTION_CHARACTERS) {
                        setDescription(e.target.value)
                    }
                }}
            />
            <div>
                {description.length}/{OralPassageResource.MAX_DESCRIPTION_CHARACTERS}
            </div>
        </>
    )
})

type OralResourceViewerProps = {
    resource: OralPassageResource
    passage: Passage
    setEditing: (value: boolean) => void
    closeEditor: () => void
}

const OralResourceViewer = observer(({ setEditing, passage, resource, closeEditor }: OralResourceViewerProps) => {
    const { t } = useTranslation()

    function deleteDocument() {
        async function onConfirm() {
            await passage.removeOralPassageResource(resource._id)
            closeEditor()
        }

        function onCancel() {}

        deleteResource(onConfirm, onCancel, t)
    }

    return (
        <>
            <div className="oral-passage-document-toolbar">
                <div className="two-column-first-group">
                    <PencilButton
                        enabled
                        onClick={() => setEditing(true)}
                        className="small-passage-document-button blue-button"
                        tooltip={t('Edit')}
                    />
                </div>
                <div className="two-column-last-group">
                    <TrashButton
                        onClick={deleteDocument}
                        enabled
                        buttonClassName="button-right-align"
                        className="small-passage-document-button"
                        tooltip={t('Delete')}
                    />
                </div>
            </div>
            <AudioPlayer url={resource.url} />
            <textarea
                value={resource.description}
                className="oral-passage-document-description"
                placeholder={t('Description...')}
                readOnly
            />
        </>
    )
})

type OralResourceScreenProps = {
    rt: Root
    resource: OralPassageResource
    passage: Passage
    closeEditor: () => void
    setEditing: (editing: boolean) => void
}

const OralResourceScreen = observer(
    ({ rt, resource, passage, closeEditor, setEditing: _setEditing }: OralResourceScreenProps) => {
        const { t } = useTranslation()
        const [editing, setEditing] = useState(false)

        useEffect(() => {
            _setEditing(editing)
        }, [_setEditing, editing])

        const editingNewResource = !passage.oralResources.find((doc) => doc._id === resource._id)
        if (editingNewResource) {
            return (
                <OralResourceDropTarget passage={passage} resource={resource} rt={rt} closeEditor={closeEditor}>
                    <div className="oral-passage-document-tab">
                        <p>{t('Drop an mp3 file here.')}</p>
                        <CancelButton
                            enabled
                            onClick={() => closeEditor()}
                            className="small-passage-document-button"
                            tooltip={t('Cancel')}
                        />
                    </div>
                </OralResourceDropTarget>
            )
        }

        if (editing) {
            return (
                <div className="oral-passage-document-tab flex-column">
                    <OralResourceEditor resource={resource} setEditing={setEditing} />
                </div>
            )
        }

        return (
            <div className="oral-passage-document-tab flex-column">
                <OralResourceViewer
                    resource={resource}
                    passage={passage}
                    setEditing={setEditing}
                    closeEditor={closeEditor}
                />
            </div>
        )
    }
)

type ResourceViewerProps = {
    resource: PassageResource
    passage: Passage
    rt: Root
    setNewResource: (resource: PassageResource | null) => void
    setEditing: (editing: boolean) => void
    readOnly: boolean
}

const ResourceViewer = observer(
    ({ resource, passage, rt, setNewResource, setEditing, readOnly }: ResourceViewerProps) => {
        if (resource instanceof PassageDocument) {
            return (
                <PassageDocumentEditor
                    document={resource}
                    passage={passage}
                    closeNewEditor={() => {
                        log('closeEditor')
                        setNewResource(null)
                    }}
                    rt={rt}
                    setEditing={setEditing}
                    readOnly={readOnly}
                />
            )
        }

        if (resource instanceof OralPassageResource) {
            return (
                <OralResourceScreen
                    resource={resource}
                    passage={passage}
                    closeEditor={() => {
                        log('closeEditor')
                        setNewResource(null)
                    }}
                    rt={rt}
                    setEditing={setEditing}
                />
            )
        }

        if (resource instanceof PassagePDF) {
            return (
                <PDFScreen
                    rt={rt}
                    resource={resource}
                    passage={passage}
                    closeEditor={() => {
                        log('closeEditor')
                        setNewResource(null)
                    }}
                    setEditing={setEditing}
                />
            )
        }

        return null
    }
)

type PassageResourcesProps = {
    rt: Root
    passage: Passage
}

const PassageResources: FC<PassageResourcesProps> = observer(({ rt, passage }) => {
    const { t } = useTranslation()
    const [newResource, setNewResource] = useState<PassageResource | null>(null)

    const appRoot = useAppRoot()
    function getResources(): PassageResourceWithGroup[] {
        let groupPassageResources: PassageResourceWithGroup[] = []
        if (rt.group) {
            const { group } = rt
            const groupRoot = appRoot.rtsMap.get(group)
            if (groupRoot) {
                groupPassageResources = groupRoot.project
                    .getResources(passage.references)
                    .map((resource) => ({ group, resource }))
            }
        }

        const passageResources = [
            ...passage.documents,
            ...passage.oralResources,
            ...passage.pdfs,
            ...(newResource ? [newResource] : [])
        ].map((resource) => ({ resource }))
        return [...passageResources, ...groupPassageResources]
    }

    const resources = getResources()

    const localResources = resources
        .filter((rsc) => !rsc.group)
        .sort((a, b) => a.resource.title.toLowerCase().localeCompare(b.resource.title.toLowerCase()))
    const groupResources = resources
        .filter((rsc) => rsc.group)
        .sort((a, b) => a.resource.title.toLowerCase().localeCompare(b.resource.title.toLowerCase()))

    const [selectedResourceId, setSelectedResourceId] = useState(() => {
        if (localResources.length) {
            return localResources[0].resource._id
        }
        if (groupResources.length) {
            return groupResources[0].resource._id
        }
        return ''
    })

    // temporary. remove after hook rt.editingPassageDocument back up.
    const [editing, setEditing] = useState(false)

    function addDocument() {
        if (rt.editingPassageDocument || editing) return // should not happen

        const document = passage.createPassageDocument()
        document.text = ''
        setSelectedResourceId(document._id)
        setNewResource(document)
    }

    let resource = resources.find((rsc) => rsc.resource._id === selectedResourceId)
    if (!resource && localResources.length) {
        resource = localResources[0]
    } else if (!resource && groupResources.length) {
        resource = groupResources[0]
    }

    const selectOptions = (options: PassageResource[]) => {
        return options.map((opt, i) => (
            <option key={opt._id} value={opt._id}>
                {resourceTitle(opt, i)}
            </option>
        ))
    }

    return (
        <div>
            <div className="passage-resource-viewer-top">
                {resources.length > 0 && (
                    <select
                        className="passage-resource-selector"
                        value={selectedResourceId}
                        onChange={(e) => setSelectedResourceId(e.target.value)}
                        disabled={rt.editingPassageDocument || editing}
                    >
                        {selectOptions(localResources.map((rsc) => rsc.resource))}
                        {groupResources.length && (
                            <optgroup label={rt.group} key={rt.group}>
                                {selectOptions(groupResources.map((rsc) => rsc.resource))}
                            </optgroup>
                        )}
                    </select>
                )}
                {rt.iAmInterpreter && isAVTT && (
                    <AddButton
                        className="passage-document-add-button small-passage-document-button"
                        buttonClassName=""
                        enabled={!rt.editingPassageDocument && !editing}
                        onClick={addDocument}
                        tooltip={t('Add resource')}
                    />
                )}
                {rt.iAmInterpreter && !isAVTT && (
                    <AddButton
                        className="passage-document-add-button small-passage-document-button"
                        buttonClassName=""
                        enabled={!rt.editingPassageDocument && !editing}
                        onClick={addDocument}
                        tooltip={t('Add passage document')}
                    />
                )}
                {resources.length === 0 && !rt.iAmInterpreter && (
                    <div>
                        {sat(
                            t(
                                'No documents have been created yet. You must be at least an interpreter to create documents.'
                            ),
                            t(
                                'No resources have been created yet. You must be at least an interpreter to create resources.'
                            )
                        )}
                    </div>
                )}
            </div>
            {resource && <div className="passage-resource-separator" />}
            {resource && (
                <ResourceViewer
                    resource={resource.resource}
                    key={resource.resource._id}
                    passage={passage}
                    rt={rt}
                    setNewResource={setNewResource}
                    setEditing={setEditing}
                    readOnly={!!resource.group}
                />
            )}
        </div>
    )
})

export default PassageResources

import React from 'react'
import { observer } from 'mobx-react'

import { Root } from '../../models3/Root'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const debug = require('debug')('sltt:PlayTourVideo')

interface ITourVideoPlayer {
    rt: Root
}

class TourVideoPlayer extends React.Component<ITourVideoPlayer> {
    onEnded = () => {
        const { rt } = this.props
        rt.videoTourSignedUrl = ''
    }

    render() {
        const { rt } = this.props
        const { videoTourSignedUrl } = rt
        if (!videoTourSignedUrl) return null // nothing to play

        debug(`render`)

        const style = {
            position: 'absolute' as const, // I have no idea why cast is necessary to make typescript happy
            left: 10,
            top: 10,
            width: 1000,
            height: 750,
            zOrder: 100
        }

        return (
            <div style={style}>
                <video controls src={videoTourSignedUrl} onEnded={this.onEnded} autoPlay />
            </div>
        )
    }
}

export default observer(TourVideoPlayer)

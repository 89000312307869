import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { confirmAlert } from 'react-confirm-alert'
import { OkEditSegmentButton, CancelEditSegmentButton, DeleteButton } from '../utils/Buttons'
import { ImageMetadata } from '../../resources/ImageMetadata'
import { ApproveIcon, CopyrightIcon, ReferencesIcon, TitleIcon } from '../utils/Icons'
import { Switch } from '../utils/Switch'
import { displayError, displayInfo } from '../utils/Errors'
import { ImagesRoot } from './ImagesRoot'
import { ReferenceInput } from '../utils/ReferenceInput'
import { ProjectImages } from '../../resources/ProjectImages'

// setup image-metadata-input-invalid
// add save() to image

interface IMetadataEditor {
    title: string
    value: string
    setValue: (value: string) => void
    validate?: (value: string) => boolean
}

const MetadataEditor: FC<IMetadataEditor> = ({ title, value, setValue, validate }) => {
    const valid = validate ? validate(value) : true
    let className = 'image-metadata-input'
    if (!valid) {
        className += ' image-metadata-input-invalid'
    }

    return (
        <input
            type="text"
            className={className}
            onChange={(element) => setValue(element.target.value)}
            value={value}
            placeholder={title}
        />
    )
}

interface IImageMetadataEditor {
    irt: ImagesRoot
    close: (saved: boolean) => void
    image: ImageMetadata
    showDeleteButton: boolean
}

const ImageMetadataEditor: FC<IImageMetadataEditor> = ({ irt, close, image, showDeleteButton }) => {
    const { t } = useTranslation()
    const [refRanges, setRefRanges] = useState(image.references)
    const [title, setTitle] = useState(image.getDefinition('en').title)
    const [copyright, setCopyright] = useState(image.copyright)
    const [shared, setShared] = useState(image.shared)
    const [errored, setErrored] = useState(false)

    const { iAmRoot } = irt.rt

    const tReferences = t('References')
    const tTitle = t('Title')
    const tCopyright = t('Copyright')

    return (
        <div className="image-metadata-editor">
            <div className="image-metadata-editor-box">
                <div className="image-metadata-editor-box-references">
                    <ReferencesIcon className="images-metadata-icon" tooltip={tReferences} />
                    <ReferenceInput
                        refInput={irt.rt}
                        refs={refRanges}
                        setRefs={setRefRanges}
                        errored={errored}
                        setErrored={setErrored}
                        referenceRequired
                        includeGotoReferenceButton={false}
                    />
                </div>
                <div>
                    <TitleIcon className="images-metadata-icon" tooltip={tTitle} />
                    <MetadataEditor title={tTitle} value={title} setValue={setTitle} />
                </div>
                <div>
                    <CopyrightIcon className="images-metadata-icon" tooltip={tCopyright} />
                    <MetadataEditor title={tCopyright} value={copyright} setValue={setCopyright} />
                </div>
                <Switch
                    className="image-approval"
                    value={shared}
                    setValue={(value) => {
                        if (value && !copyright.trim()) {
                            displayInfo(t('You must enter Copyright information before sharing image'))
                            return
                        }
                        setShared(value)
                    }}
                    enabled={iAmRoot}
                    tooltip={t('Approve sharing this image?')}
                >
                    <ApproveIcon tooltip="" className="segment-pane-icon" />
                </Switch>
            </div>
            <div className="image-metadata-editor-buttons">
                <OkEditSegmentButton
                    enabled={!errored && refRanges.length > 0}
                    onClick={() => {
                        image.update(refRanges, title, copyright, shared)
                        close(true)
                    }}
                />
                <CancelEditSegmentButton enabled onClick={() => close(false)} />
                {showDeleteButton && (
                    <DeleteButton
                        className="image-delete-button"
                        tooltip={t('Delete image')}
                        enabled
                        onClick={() =>
                            confirmAlert({
                                title: t('Delete image?'),
                                message: t('Are you sure you want to permanently delete this image?'),
                                confirmLabel: t('Delete'),
                                cancelLabel: t('Cancel'),
                                onConfirm: async () => {
                                    try {
                                        await ProjectImages.deleteProjectImage(image)
                                        close(false) // must be false or we will recreate image
                                    } catch (error) {
                                        displayError(error)
                                    }
                                }
                            })
                        }
                    />
                )}
            </div>
        </div>
    )
}

export default ImageMetadataEditor

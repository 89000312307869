/* eslint-disable import/no-cycle */
import { FC } from 'react'
import { Link } from 'react-router-dom'
import { confirmAlert } from 'react-confirm-alert'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import { t } from 'i18next'
import { Auth } from 'aws-amplify'

import { useAppRoot } from './RootContext'
import { LocaleSelector } from './LocaleSelector'
import { ProjectSelector } from './ProjectSelector'
import { avttVersion, isAVTT, sat, websiteShortName } from './slttAvtt'

import { localeLanguages } from '../utils/Languages'
import { SlttHelp } from '../utils/Buttons'
import { HeadsetIcon, HelpLink } from '../utils/Icons'

import { ProjectMessage } from '../../models3/ProjectMessage'
import { Root } from '../../models3/Root'

const ProjectTitle = observer(({ username, version }: { username: string; version: string }) => {
    return (
        <div className="navbar-header-title navbar-header navbar-brand">
            {`${websiteShortName} (${version}) / ${username}`}
        </div>
    )
})

const unreadProjectMessageCount = (rt: Root) => {
    const unreadMessageCount = (message: ProjectMessage) => (message.creator !== rt.username && !message.viewed ? 1 : 0)

    let count = 0
    for (const message of rt.project.messages) {
        count += unreadMessageCount(message)
        for (const response of message.responses) {
            count += unreadMessageCount(response)
        }
    }

    return count
}

interface IMessageIcon {
    messageCount: number
    onClick: () => void
    selected: boolean
}

const MessageIcon: FC<IMessageIcon> = ({ messageCount, onClick, selected }) => {
    const tooltip = messageCount ? t('unreadMessages', { messageCount }) : t('Create and view project messages.')

    return (
        <>
            <i
                className={classNames('fa-comment-dots', 'fas', 'fa-2x', 'edit-project-settings-button', {
                    'fa-border': selected
                })}
                onClick={onClick}
                data-toggle="tooltip"
                title={tooltip}
            />
            {messageCount === 0 ? '' : <span className="p-n-unread-messages-flag">{messageCount}</span>}
        </>
    )
}

interface INavigationBar {
    selectPage: (page: string) => void
    selected: string
    openTour: (() => void) | null
    selectProject: (projectName: string) => void
}

export const NavigationBar: FC<INavigationBar> = observer(({ selectPage, selected, selectProject }) => {
    const appRoot = useAppRoot()

    const { iAmRoot, username, useMobileLayout, rt, auth_type } = appRoot

    const hasPortions = rt ? Boolean(rt.project.portions.length) : false
    const messageCount = rt ? unreadProjectMessageCount(rt) : 0

    const handleSignOut = () =>
        confirmAlert({
            title: t('Sign out'),
            message: t(
                'Are you sure you want to sign out? You will not be able to sign in unless you are connected to the internet.'
            ),
            confirmLabel: t('Sign out'),
            cancelLabel: t('Cancel'),
            onConfirm: async () => {
                if (auth_type === 'cognito') {
                    await Auth.signOut()
                }
                appRoot.signOut()
            }
        })

    return (
        <nav className="navbar navbar-default app-navbar-bottom-margin">
            <div className="navbar-left">
                {!useMobileLayout && <ProjectTitle username={username} version={avttVersion} />}
                {rt && <ProjectSelector currentRoot={rt} selectProject={selectProject} />}
            </div>
            <div className="navbar-header-icons navbar-header">
                {rt && !useMobileLayout && (
                    <>
                        <Link to="/index.html" className="app-selector-icon video-camera-button">
                            {isAVTT ? (
                                <HeadsetIcon
                                    className={classNames('edit-passages-button', 'main-headset-icon', {
                                        'app-selector-disabled': !hasPortions
                                    })}
                                    tooltip={t('recordingPlayRecord')}
                                />
                            ) : (
                                <i
                                    className={classNames('fa-video', 'fa', 'fa-2x', 'edit-passages-button', {
                                        'fa-border': selected === 'translation',
                                        'app-selector-disabled': !hasPortions
                                    })}
                                    onClick={() => {
                                        selectPage('/')
                                    }}
                                    data-toggle="tooltip"
                                    title={t('recordingPlayRecord')}
                                />
                            )}
                        </Link>
                        <SlttHelp id="status.html" tooltip={t('Edit project status.')} place="bottom">
                            <Link to="/status" className="app-selector-icon">
                                <i
                                    className={classNames('fa-list-ol', 'fa', 'fa-2x', 'edit-status-button', {
                                        'fa-border': selected === 'status',
                                        'app-selector-disabled': !hasPortions
                                    })}
                                    onClick={() => {
                                        selectPage('/status')
                                    }}
                                />
                            </Link>
                        </SlttHelp>

                        <SlttHelp id="portions.html" tooltip={t('Edit portions.')} place="bottom">
                            <Link to="/portions" className="app-selector-icon edit-portions-button">
                                <i
                                    className={classNames('fa-chart-pie', 'fas', 'fa-2x', {
                                        'fa-border': selected === 'portions'
                                    })}
                                    onClick={() => {
                                        selectPage('/portions')
                                    }}
                                />
                            </Link>
                        </SlttHelp>

                        <SlttHelp id="settings.html" tooltip={t('Edit project settings.')} place="bottom">
                            <Link to="/project-settings" className="app-selector-icon">
                                <i
                                    className={classNames('fa-cog', 'fas', 'fa-2x', 'edit-project-settings-button', {
                                        'fa-border': selected === 'project-settings'
                                    })}
                                    onClick={() => {
                                        selectPage('/project-settings')
                                    }}
                                />
                            </Link>
                        </SlttHelp>

                        {!isAVTT && (
                            <SlttHelp
                                id="messages.html"
                                tooltip={sat(t('SLTT and Project Messages.'), t('AVTT and Project Messages.'))}
                                place="bottom"
                            >
                                <Link to="/messages" className="app-selector-icon">
                                    <MessageIcon
                                        {...{
                                            messageCount,
                                            selected: selected === 'messages',
                                            onClick: () => {
                                                selectPage('/messages')
                                            }
                                        }}
                                    />
                                </Link>
                            </SlttHelp>
                        )}

                        {iAmRoot && !Root.screenCaptureInProgress() && (
                            <Link to="/database" className="app-selector-icon">
                                <i
                                    className={classNames('fa-database', 'fas', 'fa-2x', {
                                        'fa-border': selected === 'database'
                                    })}
                                    onClick={() => {
                                        selectPage('/database')
                                    }}
                                    data-toggle="tooltip"
                                    title="Database Records."
                                />
                            </Link>
                        )}

                        {iAmRoot && !Root.screenCaptureInProgress() && (
                            <Link to="/projects" className="app-selector-icon">
                                <i
                                    className={classNames('fa-server', 'fas', 'fa-2x', {
                                        'fa-border': selected === 'projects'
                                    })}
                                    onClick={() => {
                                        selectPage('/projects')
                                    }}
                                    data-toggle="tooltip"
                                    title="Projects on server."
                                />
                            </Link>
                        )}
                    </>
                )}
                <HelpLink className="app-selector-icon fa-2x" tooltip={t('Get help')} />
                <LocaleSelector
                    options={localeLanguages}
                    currentValue={rt ? rt.uiLanguage : 'en'}
                    chooseOption={(option) => (rt ? rt.setLocale(option) : {})}
                />
                <div className="app-selector-icon">
                    <button type="button" className="btn btn-primary" onClick={handleSignOut}>
                        <span className="fas fa-sign-out-alt fa-lg" /> {t('Sign out')}
                    </button>
                </div>
            </div>
        </nav>
    )
})

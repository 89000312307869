import { Component } from 'react'
import { observable } from 'mobx'
import { observer } from 'mobx-react'

import VideoDownloading from '../utils/VideoDownloading'

import { PassageVideo } from '../../models3/PassageVideo'

interface IVideoPositionViewer {
    video: PassageVideo
    position: number
}

class VideoPositionViewer extends Component<IVideoPositionViewer> {
    @observable src = ''

    render() {
        const { video, position } = this.props

        const { url, creator } = video
        const { src } = this

        if (!src) {
            return (
                <VideoDownloading
                    className="video-position-viewer"
                    videoUrl={url}
                    creator={creator}
                    fontSizePt={18}
                    onEnded={(blob) => {
                        if (!src) {
                            this.src = window.URL.createObjectURL(blob)
                        }
                    }}
                />
            )
        }

        return (
            <div className="video-position-viewer">
                <video
                    className="video-position-viewer-video"
                    src={src}
                    ref={(vc) => {
                        if (vc) {
                            vc.currentTime = position
                        }
                    }}
                    autoPlay={false}
                />
            </div>
        )
    }
}

export default observer(VideoPositionViewer)

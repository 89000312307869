// Information to show uses for all the tour location for the main window.

import { Root } from '../../models3/Root'

let _rt: Root | null = null

export const setTourRt = function (rt: Root) {
    _rt = rt
}

export const getTourRt = function () {
    return _rt
}

export const tourSteps: any[] = [
    // Commenting out tourSteps for now because I don't think the tour give enough information
    // to use most features and I don't want to ask localizers to translate if we are
    // not going to use this
]

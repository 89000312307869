import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { t } from 'i18next'

import { VideoSegmentStartingPointButton } from '../utils/Buttons'
import { RootContext } from '../app/RootContext'
import { Root } from '../../models3/Root'
import { SegmentPositionDialog } from './SegmentPositionDialog'
import { EditingSegmentPosition } from '../translation/TranslationRightPane'

interface IVideoSegmentPosition {
    editingSegmentPosition: EditingSegmentPosition
    setEditingSegmentPosition: (value: EditingSegmentPosition) => void
    enabled: boolean
}

class VideoSegmentPosition extends Component<IVideoSegmentPosition> {
    // eslint-disable-next-line react/static-property-placement
    static contextType = RootContext

    render() {
        const { editingSegmentPosition, setEditingSegmentPosition, enabled } = this.props

        const rt: Root | null = this.context
        if (!rt) return null
        const { passage, passageVideo, passageSegment } = rt
        if (!passage || !passageVideo || !passageSegment) return null

        const segmentIndex = passageVideo.segments.findIndex((seg) => seg._id === passageSegment._id)
        if (segmentIndex < 0) return null

        return (
            <div>
                {editingSegmentPosition !== EditingSegmentPosition.None && (
                    <SegmentPositionDialog
                        {...{
                            passage,
                            video: passageVideo,
                            segmentIndex,
                            editingSegmentPosition,
                            close: (canceled, time, duration) => {
                                if (!canceled) {
                                    rt.resetCurrentTime(time, duration)
                                }
                                setEditingSegmentPosition(EditingSegmentPosition.None)
                            }
                        }}
                    />
                )}
                <VideoSegmentStartingPointButton
                    className="segment-adjust-start-position-button"
                    enabled={enabled && rt.iAmInterpreter}
                    onClick={() => {
                        setEditingSegmentPosition(EditingSegmentPosition.Both)
                    }}
                    tooltip={t('recordingEditSegmentPosition')}
                />
            </div>
        )
    }
}

export default observer(VideoSegmentPosition)

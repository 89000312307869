/* eslint-disable import/no-cycle */
/*
    Show all database keys for current project.
    Click on a key to see corresponding doc.
    Filter by entering a key prefix in the text box.
    Optionally delete all keys matching a prefix, BE CAREFUL! (but it won't let you delete w/o a filter value)
 */

import { useCallback, useEffect, useState } from 'react'
import { observer } from 'mobx-react'

import { displayError, systemError } from '../utils/Errors'
import LoadingMessage from '../utils/InitializationMessage'

import { _LevelupDB } from '../../models3/_LevelupDB'
import { Root } from '../../models3/Root'

import './SLTool.css'
import '../utils/Buttons.css'
import { useDebouncedValue } from '../utils/Hooks'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:DatabaseEditor')

interface IRowView {
    setDetails: (details: string) => void
    setCurrentRow: (row: Record<string, unknown>) => void
    currentRow: Record<string, unknown>
    deleteOne: (row: Record<string, unknown>) => Promise<void>
}

const RowView = (props: IRowView) => {
    const { setDetails, setCurrentRow, currentRow } = props
    const className = currentRow.removed ? 'db-row-removed' : ''

    return (
        <p className="database-row">
            <span
                className={className}
                onClick={() => {
                    setCurrentRow(currentRow)
                    setDetails(JSON.stringify(currentRow, null, 2))
                }}
            >
                {currentRow.key}: {currentRow._id}
            </span>
            &nbsp;
            {/* {!currentRow.removed && (
                <span
                    className="fas fa-trash-alt"
                    style={{
                        fontSize: '170%',
                        paddingLeft: '10px'
                    }}
                    onClick={() => {
                        deleteOne(currentRow).catch(displayError)
                    }}
                />
            )} */}
        </p>
    )
}

// interface IVcrView {
//     setDetails: (details: string) => void,
//     vcr: VideoCacheRecord,
// }

// function VcrView(props: IVcrView) {
//     let { setDetails, vcr } = props

//     return(
//         <p>
//             <span
//                 onClick={() => setDetails(JSON.stringify(vcr, null, 2))}>
//                 {vcr._id} {vcr.downloaded ? '*' : ''}{vcr.uploaded ? '^' : ''}
//             </span>
//         </p>
//     )
// }

// !! Include portion/passage name in primary displayed info
// allow filter by name
// make item name visually clickable

interface IDatabaseEditor {
    rt: Root
}

const DatabaseEditor = ({ rt }: IDatabaseEditor) => {
    const [allRows, setAllRows] = useState<Record<string, unknown>[]>([])
    const [details, setDetails] = useState('')
    const [currentRow, setCurrentRow] = useState<Record<string, unknown>>()
    const [textFilter, setTextFilter] = useState('')
    const debouncedTextFilter = useDebouncedValue(textFilter, 1000)
    const [minimumKeyString, setMinimumKeyString] = useState('')
    const debouncedMinimumKeyString = useDebouncedValue(minimumKeyString, 1000)
    const [docsToRead, setDocsToRead] = useState(100)
    const debouncedDocsToRead = useDebouncedValue(docsToRead, 1000)

    const debouncedMinimumKeyNumber = Number(debouncedMinimumKeyString)
    const isMinimumKeyNumberValid = !isNaN(debouncedMinimumKeyNumber) && isFinite(debouncedMinimumKeyNumber)
    const viewLatestDocuments = debouncedMinimumKeyString === '' || !isMinimumKeyNumberValid

    const load = useCallback(
        async (db: _LevelupDB) => {
            log('loading...')
            const dbRecords = !viewLatestDocuments
                ? await db.get(
                      debouncedMinimumKeyNumber - 1,
                      debouncedMinimumKeyNumber + debouncedDocsToRead - 1,
                      undefined,
                      true
                  )
                : await db.get(undefined, undefined, debouncedDocsToRead, true)
            const rows = dbRecords.map(({ key, doc }) => ({ key, ...doc }))

            log(`db rows read = ${rows.length}`)
            setAllRows(rows)
        },
        [viewLatestDocuments, debouncedMinimumKeyNumber, debouncedDocsToRead]
    )

    useEffect(() => {
        log('rt changed', rt.name)

        // Default to viewing currently selected passage
        rt.initialize()
            .then(() => {
                setDetails('')
                return load(rt.project.db as _LevelupDB)
            })
            .catch(systemError)
    }, [rt, load])

    async function deleteOne(row: Record<string, unknown>) {
        log('deleteOne', JSON.stringify(row, null, 4))
        const db = rt.project.db as _LevelupDB
        const { _id, creator, creationDate } = row

        await db.delete({ _id, creator, creationDate, modDate: db.getDate() })
        load(db).catch(displayError)
    }

    // function deleteItemsMatchingFilter() {
    //     const { db } = rt.project

    //     // Don't allow deleting everything!!!
    //     if (!filter || filter.length === 0) return

    //     db.delete(filter, `${filter}\uffff`)
    //         .then(() => {
    //             load().catch(displayError)

    //             setFilter('')
    //         })
    //         .catch(displayError)
    //     }
    // }

    // function acceptRow(row: Record<string, unknown>) {
    //     log('acceptRow', JSON.stringify(row, null, 4))
    //     const db = rt.project.db as _LevelupDB
    //     db.acceptor?.accept(row)
    // }

    const { initialized, loadingMessage } = rt
    if (!initialized) {
        return <LoadingMessage {...{ loadingMessage }} />
    }

    const filteredRows = allRows.filter(
        (row) => !debouncedTextFilter || JSON.stringify(row).includes(debouncedTextFilter)
    )

    return (
        <div>
            <div className="database-query-area">
                <div className="database-search-boxes">
                    <div>
                        <div>Number of docs to show</div>
                        <input
                            type="number"
                            value={docsToRead}
                            onChange={(e) => setDocsToRead(Number(e.target.value))}
                        />
                    </div>
                    <div>
                        <div>Starting key</div>
                        <input value={minimumKeyString} onChange={(e) => setMinimumKeyString(e.target.value)} />
                    </div>
                    <div>
                        <div>Filter by</div>
                        <input autoFocus value={textFilter} onChange={(e) => setTextFilter(e.target.value)} />{' '}
                    </div>
                </div>
                <div>
                    {!viewLatestDocuments ? (
                        <span>
                            Searching through docs with keys from{' '}
                            <b>
                                {debouncedMinimumKeyNumber} to {debouncedMinimumKeyNumber + debouncedDocsToRead - 1}
                            </b>
                            .
                        </span>
                    ) : (
                        <span>
                            Searching through the most recent <b>{debouncedDocsToRead}</b> documents.
                        </span>
                    )}
                    {filteredRows.length ? (
                        <span>
                            {' '}
                            The largest key shown in this search is <b>{filteredRows[0].key}</b>.
                        </span>
                    ) : (
                        <span> There are no search results that match your query.</span>
                    )}
                </div>
            </div>
            {/* Disabled for now. Will need better confirmation to users and code to handle it 
            <button
                type="button"
                className="delete-db-keys btn btn-primary btn-warning"
                onClick={deleteItemsMatchingFilter}
            >
                Delete!!!
            </button>
            */}
            <div className="app-database">
                <div className="app-database-list">
                    {filteredRows.map((row) => (
                        <RowView
                            key={`${row.key}`}
                            currentRow={row}
                            setDetails={setDetails}
                            setCurrentRow={setCurrentRow}
                            deleteOne={deleteOne}
                        />
                    ))}
                    {/* <div>VideoCacheRecords</div>
                    {this.vcrs.map(vcr => (
                        <VcrView key={vcr._id}
                            vcr={vcr}
                            setDetails={(details: string) => this.details = details} />
                    ))} */}
                </div>
                {currentRow && (
                    <div className="app-database-details">
                        <pre> {details} </pre>
                        {/* <button
                            type="button"
                            className="delete-db-keys btn btn-primary btn-warning"
                            onClick={() => acceptRow(currentRow)}
                        >
                            Accept
                        </button> */}
                    </div>
                )}
            </div>
        </div>
    )
}

export default observer(DatabaseEditor)

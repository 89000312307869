import { observable } from 'mobx'
import { RefRange } from '../../scrRefs/RefRange'
import { ImageMetadata } from '../../resources/ImageMetadata'
import ImageCollection from './ImageCollection'
import { Root } from '../../models3/Root'
import { s } from '../utils/Fmt'
import { ProjectImages } from '../../resources/ProjectImages'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:ImagesRoot')

/**
 * Common data shared by all controls used in the right hand side images pane
 */

export class ImagesRoot {
    @observable searchRefs: RefRange[] = []

    // This is the images matching searchRefs. Project and MARBLE.
    @observable images: ImageMetadata[] = []

    @observable showImageTitles = false
    // When true show image titles in thumbnail

    imageCollection = new ImageCollection()

    rt: Root

    constructor(rt: Root) {
        this.rt = rt
        this.showImageTitles = localStorage.getItem('showImageTitles') === 'true'
    }

    async refresh() {
        await this.setSearchRefs(this.searchRefs)
    }

    setSearchRefs = async (searchRefs: RefRange[]) => {
        log('ImagesRoot setSearchRefs', JSON.stringify(searchRefs))
        this.searchRefs = searchRefs

        try {
            if (searchRefs.length === 2 && searchRefs[0].startRef === '001001' && searchRefs[1].startRef === '066001') {
                // This is a back door that shows all the project images accessible to
                // this project if the reference 'Genesis 1; Revelations 1' is entered.
                // [I could not figure out any other straightforward way to trigger this]
                const imgs = await ProjectImages.getProjectImages(this.rt.name, '')
                log(
                    'setSearchRefs project images',
                    s(
                        imgs.map((img) => ({
                            project: img.project,
                            references: JSON.stringify(img.references),
                            shared: img.shared,
                            id: img.id
                        }))
                    )
                )
                this.images = imgs.filter((img) => img.shared || img.project === this.rt.project.name)
            } else {
                this.images = await this.imageCollection.fetchImages(this.rt.project.name, searchRefs)
            }
            log('ImagesRoot setSearchRefs found=', this.images.length)
        } catch (error) {
            this.images = []
            console.log('### cannot fetch images', error)
        }
    }

    adjustSearchRefs = (references: RefRange[]) => {
        const { searchRefs } = this

        if (searchRefs.some((ref) => ref.overlaps(references))) return

        this.setSearchRefs(searchRefs.concat(references))
    }
}

import { useState, useEffect } from 'react'
import { VideoCache } from '../../models3/VideoCache'

export const useGetProgress = (urls: string[]) => {
    const [numberUploaded, setNumberUploaded] = useState(0)
    const [numberDownloaded, setNumberDownloaded] = useState(0)
    const [totalBlobs, setTotalBlobs] = useState(0)
    const [progressQueried, setProgressQueried] = useState(false)

    useEffect(() => {
        function getProgressLoop() {
            const { uploaded, downloaded, total } = VideoCache.queryProgressMultiple(urls)
            setNumberUploaded(uploaded)
            setNumberDownloaded(downloaded)
            setTotalBlobs(total)
            setProgressQueried(true)
        }

        const intervalId = setInterval(getProgressLoop, 1000)
        return () => {
            clearInterval(intervalId)
        }
    }, [urls])

    return { numberUploaded, numberDownloaded, totalBlobs, progressQueried }
}

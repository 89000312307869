import { FC, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import Vimeo from '@u-wave/react-vimeo'
import { Dropdown, MenuItem } from 'react-bootstrap'
import useResizeObserver from 'use-resize-observer'

import { ReferenceInput } from '../utils/ReferenceInput'

import { Root } from '../../models3/Root'
import { Project } from '../../models3/Project'

import { getSLBiblePassages, SLBiblePassage } from '../../resources/albums'
import { RefRange } from '../../scrRefs/RefRange'
import { refRangesToDisplay } from '../../scrRefs/Utils'

import './DbsPlayer.css'

// eslint-disable-next-line
// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:DbsPlayer')

function defaultPassage(currentPassage: SLBiblePassage | undefined, passages: SLBiblePassage[]) {
    // If previously selected project and it's in new list, use first item from this previous project
    // If previously selected project not in list, use first item from American project
    // If previously selected project not in list and American not in list, select first item in list
    // If no previous project, use first from American
    // If no previous project and American not in list, select first item in list
    const americanPassages = passages.filter((p) => p.languageName === 'American')
    const currentProjectPassages = passages.filter((p) => p.languageName === currentPassage?.languageName)
    const nextPassage = currentProjectPassages[0] || americanPassages[0] || passages[0]
    return nextPassage
}

function displayedVideoName(passage: SLBiblePassage, project: Project) {
    let { name } = passage
    if (name.length > 80) {
        name = `${name.slice(0, 80)}...`
    }
    if (name) {
        name = `(${name})`
    }

    return `${passage.languageName} - ${refRangesToDisplay(passage.refs, project)} ${name}`
}

type VideoSelectorProps = {
    project: Project
    passages: SLBiblePassage[]
    passage: SLBiblePassage
    onClick: (passage: SLBiblePassage) => void
}

const VideoSelector: FC<VideoSelectorProps> = observer(({ project, passages, passage, onClick }) => {
    return (
        <Dropdown id="dbs-video-selector" pullRight>
            <Dropdown.Toggle className="sl-dropdown dbs-video-dropdown">
                {displayedVideoName(passage, project)}
            </Dropdown.Toggle>
            <Dropdown.Menu className="scrollable-dropdown">
                {passages.map((p) => (
                    <MenuItem key={`${p.url}`} onSelect={() => onClick(p)}>
                        {displayedVideoName(p, project)}
                    </MenuItem>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    )
})

type ResourcePlayerToolbarProps = {
    rt: Root
    setDbsRefs: (refs: RefRange[]) => void
    defaultReferenceId: string
    passages: SLBiblePassage[]
    passage?: SLBiblePassage
    onClick: (passage: SLBiblePassage) => void
}

const ResourcePlayerToolbar: FC<ResourcePlayerToolbarProps> = observer(
    ({ rt, setDbsRefs, defaultReferenceId, passages, passage, onClick }) => {
        const [errored, setErrored] = useState(false)
        return (
            <div className="video-toolbar dbs-player-toolbar">
                <div className="dbs-reference-search">
                    <ReferenceInput
                        refInput={rt}
                        refs={[]}
                        setRefs={setDbsRefs}
                        errored={errored}
                        setErrored={setErrored}
                        allowBookNameOnly
                        includeGotoReferenceButton
                        defaultReferenceId={defaultReferenceId}
                    />
                </div>
                <div className="dbs-video-selector">
                    {passage && <VideoSelector {...{ project: rt.project, passages, passage, onClick }} />}
                </div>
            </div>
        )
    }
)

const VimeoPlayer: FC<IDbsVideoMain> = ({ passage, width }) => {
    function ss(u: string) {
        return u.split('/').slice(-1)[0]
    }

    const [url, setUrl] = useState(passage.url)
    log(`VimeoPlayer ${ss(passage.url)} / ${ss(url)}`)

    /**
     * There is a bug in <Vimeo> that causes it to fail to load private videos
     * when passage.url changes to a new video.
     * To work around this we remove the Vimeo control for 100ms
     * and then create a new control with the new url.
     */
    if (url && passage.url !== url) {
        setTimeout(() => {
            setUrl(passage.url)
        }, 100)
        return <div />
    }

    const width2 = 20 * Math.round(width / 20)

    return (
        <Vimeo
            className="dbs-vimeo"
            video={url}
            showTitle={false}
            showByline={false}
            showPortrait={false}
            width={width2 - 50}
            height={((width2 - 50) * 480) / 640}
        />
    )
}
interface IDbsVideoMain {
    passage: SLBiblePassage
    width: number
}

const DbsVideoMain: FC<IDbsVideoMain> = observer(({ passage, width }) => {
    const { t } = useTranslation()
    const isVimeo = passage.url.includes('vimeo')

    let hostedBy = ''
    if (passage.hostedBy !== 'United Bible Societies') {
        hostedBy = `/ ${t('Hosted by: ')}${passage.hostedBy}`
    }

    return (
        <div className="dbs-video-main">
            <div className="dbs-video-container">
                {isVimeo ? (
                    <VimeoPlayer {...{ passage, width }} />
                ) : (
                    <video className="dbs-player-video" controls src={passage.url} />
                )}
            </div>
            <p>
                {' '}
                {passage.copyright} {hostedBy}{' '}
            </p>
        </div>
    )
})

interface IDbsPlayer {
    rt: Root
}

const DbsPlayer: FC<IDbsPlayer> = observer(({ rt }) => {
    const { t } = useTranslation()
    const [dbsRefs, setDbsRefs] = useState<RefRange[]>([])
    const [passages, setPassages] = useState<SLBiblePassage[]>([])
    const [passage, setPassage] = useState<SLBiblePassage | undefined>(undefined)

    const defaultReferenceId = 'DBSReference'

    const { ref, width = 640 } = useResizeObserver<HTMLDivElement>()

    // Whenever dbsRefs changes, fetch a list of associated passages and select a default passage
    // If we don't have any refs yet get them from localStorage default or rt.dbsRefs
    useEffect(() => {
        async function setDbsRefsPassages(refRanges: RefRange[]) {
            const biblePassages = await getSLBiblePassages(refRanges)
            setPassages(biblePassages)
            setPassage(defaultPassage(passage, biblePassages))
        }

        if (dbsRefs.length) {
            setDbsRefsPassages(dbsRefs)
            return
        }

        // No references passed try to get a default reference from localStorage
        const defaultReference = rt.getDefault(defaultReferenceId) ?? ''
        log('defaultReference', defaultReference)

        if (defaultReference) {
            const _refs = JSON.parse(defaultReference).map((r: any) => new RefRange(r.startRef, r.endRef)) as RefRange[]
            log('parseDefault', _refs)
            if (_refs.length) {
                setDbsRefs(_refs)
                return
            }
        }

        // Set refs to current rt.dbsRefs
        if (rt.dbsRefs.length) {
            setDbsRefs(rt.dbsRefs)
        }
    }, [dbsRefs, passage, rt])

    function onClick(biblePassage: SLBiblePassage) {
        log('onClick', JSON.stringify(biblePassage, null, 4))
        setPassage(biblePassage)
    }

    return (
        <div className="dbs-player-top-container" ref={ref}>
            <ResourcePlayerToolbar {...{ rt, setDbsRefs, defaultReferenceId, passages, passage, onClick }} />
            <div className="dbs-player-video-area">
                {passage ? <DbsVideoMain passage={passage} width={width} /> : <div>{t('recordingNoneSelected')}</div>}
            </div>
        </div>
    )
})

export default DbsPlayer

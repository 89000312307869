import { useState } from 'react'
import { Modal, Radio } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'

import { Passage } from '../../models3/Passage'
import { Portion } from '../../models3/Portion'
import { Root } from '../../models3/Root'

import { CancelButton, HelpLinkWithMessage, OKButton } from '../utils/Buttons'
import { DEFAULT_AUDIO_ENCODE_TYPE, AudioEncodeType } from '../utils/DownloadPassage'
import { exportToFile, safeFileName } from '../utils/Helpers'
import { ExportItem, generateZip, getExportItem } from './Export'
import { HeadsetIcon, LoadingIcon, WarningIcon } from '../utils/Icons'
import { ScrollIcon } from '../utils/SVGRepoIcons'
import { Switch } from '../utils/Switch'

import './ExportModal.css'

export const AudioEncodingSwitch = ({
    setAudioEncodeType,
    defaultAudioEncodeType = DEFAULT_AUDIO_ENCODE_TYPE
}: {
    setAudioEncodeType: (type: AudioEncodeType) => void
    defaultAudioEncodeType?: AudioEncodeType
}) => {
    const { t } = useTranslation()
    const [toggle, setToggle] = useState(defaultAudioEncodeType !== 'wav')
    const [radio, setRadio] = useState(defaultAudioEncodeType)

    return (
        <>
            <h4>{t('Compress recording?')}</h4>
            <Switch
                value={toggle}
                setValue={(newValue) => {
                    setAudioEncodeType(newValue ? radio : AudioEncodeType.wav)
                    setToggle(newValue)
                }}
                tooltip={t('Compress the recording before exporting it?')}
            />
            {toggle &&
                Object.keys(AudioEncodeType)
                    .filter((type) => type !== 'wav')
                    .map((type) => (
                        <Radio
                            key={type}
                            name="audio-encoding-radio-group"
                            onChange={() => {
                                setAudioEncodeType(type as AudioEncodeType)
                                setRadio(type as AudioEncodeType)
                            }}
                            checked={radio === type}
                        >
                            {type}
                        </Radio>
                    ))}
        </>
    )
}

export const CombineToggle = ({ toggle, setToggle }: { toggle: boolean; setToggle: (value: boolean) => void }) => {
    const { t } = useTranslation()
    return (
        <>
            <h4>{t('Combine passages?')}</h4>
            <Switch value={toggle} setValue={setToggle} tooltip={t('Combine passages before exporting?')} />
        </>
    )
}

interface SecondsOfSilenceProps {
    secondsOfSilence: number
    setSecondsOfSilence: (value: number) => void
}

export const SecondsOfSilence = ({ secondsOfSilence, setSecondsOfSilence }: SecondsOfSilenceProps) => {
    const { t } = useTranslation()
    const MIN_SPACER_SECONDS = 0
    const MAX_SPACER_SECONDS = 10

    return (
        <>
            <h4>{t('Seconds of silence between passages')}</h4>
            <input
                className="difficulty-input"
                required
                type="number"
                value={secondsOfSilence}
                step={0.1}
                onChange={(e) => {
                    // the min and max attributes of the input field don't always work
                    const silence = Math.max(MIN_SPACER_SECONDS, Math.min(MAX_SPACER_SECONDS, Number(e.target.value)))
                    setSecondsOfSilence(silence)
                }}
            />
        </>
    )
}

interface TranscriptionViewerProps {
    transcription: string
}

const TranscriptionViewer = ({ transcription }: TranscriptionViewerProps) => {
    return <textarea value={transcription} className="transcription-export-text-editor" readOnly dir="auto" />
}

interface RecordedPassagesListProps {
    passages: Passage[]
}

const RecordedPassagesList = ({ passages }: RecordedPassagesListProps) => {
    const { t } = useTranslation()
    return (
        <>
            <h4>{t('Passages recorded')}</h4>
            <ol>
                {passages.map((passage) => (
                    <li key={passage._id}>{passage.name}</li>
                ))}
            </ol>
        </>
    )
}

type ExportFromType = 'passageVideo' | 'portion'
interface ProgressModalBodyAndFooterProps {
    closeModal: () => void
    error: string
    loading: boolean
    loadingMessage?: string
    successBody: JSX.Element
}

export const ProgressModalBodyAndFooter = ({
    closeModal,
    error,
    loading,
    loadingMessage,
    successBody
}: ProgressModalBodyAndFooterProps) => {
    const { t } = useTranslation()

    if (error) {
        return (
            <>
                <Modal.Body>
                    <div>{error}</div>
                </Modal.Body>
                <Modal.Footer>
                    <OKButton enabled onClick={closeModal} buttonClassName="" className="ok-button" tooltip={t('OK')} />
                </Modal.Footer>
            </>
        )
    }

    if (loading) {
        return (
            <Modal.Body>
                <div className="export-loading-message-parent">
                    <LoadingIcon className="" />
                    <span className="export-loading-message">
                        {loadingMessage ?? t('Preparing file. This may take several minutes...')}
                    </span>
                </div>
            </Modal.Body>
        )
    }

    return (
        <>
            <Modal.Body>{successBody}</Modal.Body>
            <Modal.Footer>
                <OKButton enabled onClick={closeModal} buttonClassName="" className="ok-button" tooltip={t('OK')} />
            </Modal.Footer>
        </>
    )
}

interface ExportResultsPageWrapperProps {
    closeModal: () => void
    error: string
    loading: boolean
    isFileToDownload: boolean
    isMissingRecordings: boolean
}

export const ExportResultsPage = ({
    closeModal,
    error,
    loading,
    isFileToDownload,
    isMissingRecordings
}: ExportResultsPageWrapperProps) => {
    const { t } = useTranslation()
    return (
        <ProgressModalBodyAndFooter
            closeModal={closeModal}
            error={error}
            loading={loading}
            successBody={
                <div>
                    {isMissingRecordings && (
                        <div className="export-modal-warning">
                            <WarningIcon className="export-results-warning-icon" />
                            <div>{navigator.onLine ? t('exportRecordingsMissing') : t('exportOfflineWarning')}</div>
                        </div>
                    )}
                    <div>{isFileToDownload ? t('fileDownloaded') : t(`nothingDownloaded`)}</div>
                </div>
            }
        />
    )
}

interface ExportModalFooterProps {
    enabledOK?: boolean
    enabledCancel?: boolean
    onOK: (e?: any) => void
    onCancel: (e?: any) => void
}

export const ExportModalFooter = ({
    enabledOK = true,
    enabledCancel = true,
    onOK,
    onCancel
}: ExportModalFooterProps) => {
    const { t } = useTranslation()

    return (
        <Modal.Footer>
            <div className="export-modal-footer-buttons">
                <OKButton
                    enabled={enabledOK}
                    onClick={onOK}
                    buttonClassName=""
                    className="ok-button"
                    tooltip={t('OK')}
                />
                <CancelButton
                    enabled={enabledCancel}
                    onClick={onCancel}
                    className="cancel-button"
                    tooltip={t('Cancel')}
                />
            </div>
        </Modal.Footer>
    )
}

const safePortionName = (portion: Portion) => safeFileName(portion.name)

interface ExportModalProps {
    rt: Root
    exportFromType: ExportFromType
    portion: Portion
    setOpen: (value: boolean) => void
}

export const ExportModal = ({ rt, exportFromType, portion, setOpen }: ExportModalProps) => {
    const { t } = useTranslation()
    const [secondsOfSilence, setSecondsOfSilence] = useState(1.0)
    const [audioEncodeType, setAudioEncodeType] = useState(DEFAULT_AUDIO_ENCODE_TYPE)
    const [combinePassages, setCombinePassages] = useState(false)
    const [showResultsPage, setShowResultsPage] = useState(false)
    const [tabIndex, setTabIndex] = useState(0)
    const [error, setError] = useState('')
    const [downloading, setDownloading] = useState(true)
    const [isFileToDownload, setIsFileToDownload] = useState(true)
    const [isMissingRecordings, setIsMissingRecordings] = useState(false)

    const passagesToDownload = portion.passages.filter((passage) => passage.videosNotDeleted.length > 0)
    const isSomethingToDownload = passagesToDownload.length > 0

    const { project, passage, passageVideo } = rt

    let headerLabel
    let transcription = ''
    if (exportFromType === 'passageVideo') {
        if (!passage || !passageVideo) {
            return null
        }
        headerLabel = t('Export Passage: {{passageName}}', { passageName: passage.name })
        transcription = passageVideo.getTranscription(passage, project)
    } else {
        headerLabel = t('Export Portion: {{portionName}}', { portionName: portion.name })
        transcription = portion.getTranscription(project)
    }

    const audioSuffix = `-audio.${audioEncodeType}`

    const exportTheData = async () => {
        const exportType = tabIndex === 0 ? 'recordings' : 'transcription'
        const fileName = `AVTT-${portion.name}${exportFromType === 'passageVideo' ? `-${passage?.name}` : ''}`

        let fileExtension = ''
        try {
            if (exportType === 'recordings') {
                let blob: Blob | undefined
                if (exportFromType === 'passageVideo') {
                    if (!passage || !passageVideo) {
                        return
                    }
                    fileExtension = `.${audioEncodeType}`
                    const passageAudio = await getExportItem({
                        passage,
                        passageVideo,
                        inputParams: { recording: { path: '', audioEncodeType } }
                    })
                    if (passageAudio.recording) {
                        blob = passageAudio.recording.data
                        setIsMissingRecordings(passageAudio.recording.isMissingParts)
                    }
                } else if (combinePassages) {
                    fileExtension = `.${audioEncodeType}`
                    const portionAudio = await getExportItem({
                        portion,
                        inputParams: { recording: { path: '', secondsOfSilence, audioEncodeType } }
                    })
                    if (portionAudio.recording) {
                        blob = portionAudio.recording.data
                        setIsMissingRecordings(portionAudio.recording.isMissingParts)
                    }
                } else {
                    const dataPromises: Promise<ExportItem>[] = []
                    portion.passages.forEach((pass) => {
                        const passageVideos = pass.videosNotDeleted
                        if (!passageVideos.length) {
                            return
                        }

                        const latestAudio = passageVideos.slice(-1)[0]
                        const passageName = `${safePortionName(portion)}/${safeFileName(pass.name)}`
                        dataPromises.push(
                            getExportItem({
                                passage: pass,
                                passageVideo: latestAudio,
                                inputParams: { recording: { path: `${passageName}${audioSuffix}`, audioEncodeType } }
                            })
                        )
                    })

                    fileExtension = '.zip'
                    const data = await Promise.all(dataPromises)
                    setIsMissingRecordings(data.some((item) => item.recording?.isMissingParts))
                    const zipFile = await generateZip(data)
                    if (zipFile) {
                        blob = zipFile
                    }
                }

                if (!blob) {
                    setIsFileToDownload(false)
                    setDownloading(false)
                    return
                }

                exportToFile(blob, fileName, fileExtension)
            } else if (exportType === 'transcription') {
                setIsMissingRecordings(false)
                exportToFile(transcription, `${fileName}-transcription`, '.txt')
            }
            setDownloading(false)
        } catch (err) {
            setError(String(err))
        }
    }

    return (
        <Modal show onHide={() => setOpen(false)} backdrop="static">
            <Modal.Header closeButton>
                <h3>
                    {headerLabel}{' '}
                    <HelpLinkWithMessage
                        id={exportFromType === 'passageVideo' ? 'passages.html#export' : 'portions.html#export'}
                    />
                </h3>
            </Modal.Header>
            {showResultsPage && (
                <ExportResultsPage
                    closeModal={() => setOpen(false)}
                    error={error}
                    loading={downloading}
                    isFileToDownload={isFileToDownload}
                    isMissingRecordings={isMissingRecordings}
                />
            )}
            {!showResultsPage && (
                <>
                    <Modal.Body>
                        <Tabs selectedIndex={tabIndex} onSelect={(index: number) => setTabIndex(index)}>
                            <TabList>
                                <Tab>
                                    <HeadsetIcon className="passage-transcription-icon" tooltip={t('recordingPanel')} />
                                </Tab>
                                <Tab>
                                    <ScrollIcon
                                        className="passage-transcription-icon"
                                        tooltip={t('transcriptionPanel')}
                                    />
                                </Tab>
                            </TabList>
                            <TabPanel>
                                <h4 className="export-modal-subheading">{t('Export Recording')}</h4>
                                {exportFromType === 'passageVideo' && (
                                    <AudioEncodingSwitch setAudioEncodeType={setAudioEncodeType} />
                                )}
                                {exportFromType === 'portion' && (
                                    <>
                                        {!isSomethingToDownload && (
                                            <p>{t('No passages have been recorded in this portion.')}</p>
                                        )}
                                        {isSomethingToDownload && (
                                            <>
                                                <RecordedPassagesList passages={passagesToDownload} />
                                                <CombineToggle
                                                    toggle={combinePassages}
                                                    setToggle={setCombinePassages}
                                                />
                                                {combinePassages && (
                                                    <SecondsOfSilence
                                                        secondsOfSilence={secondsOfSilence}
                                                        setSecondsOfSilence={setSecondsOfSilence}
                                                    />
                                                )}
                                                <AudioEncodingSwitch setAudioEncodeType={setAudioEncodeType} />
                                            </>
                                        )}
                                    </>
                                )}
                            </TabPanel>
                            <TabPanel>
                                <h4 className="export-modal-subheading">{t('Export Transcription')}</h4>
                                {exportFromType === 'passageVideo' && (
                                    <TranscriptionViewer transcription={transcription} />
                                )}
                                {exportFromType === 'portion' && (
                                    <>
                                        {!isSomethingToDownload && (
                                            <p>{t('No passages have been recorded in this portion.')}</p>
                                        )}
                                        {isSomethingToDownload && (
                                            <>
                                                <RecordedPassagesList passages={passagesToDownload} />
                                                <TranscriptionViewer transcription={transcription} />
                                            </>
                                        )}
                                    </>
                                )}
                            </TabPanel>
                        </Tabs>
                    </Modal.Body>
                    <ExportModalFooter
                        onOK={() => {
                            if (isSomethingToDownload) {
                                setShowResultsPage(true)
                                exportTheData()
                            } else {
                                setOpen(false)
                            }
                        }}
                        onCancel={() => setOpen(false)}
                    />
                </>
            )}
        </Modal>
    )
}

import React, { Component } from 'react'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import { t } from 'i18next'
import { displayError } from '../utils/Errors'

import { Root } from '../../models3/Root'
import TextInput from '../utils/TextInput'

interface IPassageAdder {
    rt: Root
}

class PassageAdder extends Component<IPassageAdder> {
    @observable adding = false // entering name of passage to add

    constructor(props: IPassageAdder) {
        super(props)

        const { rt } = this.props
        this.adding = rt.portion?.passages.length === 0
    }

    // Start showing input box for name of passage to add
    onAdding() {
        this.adding = true
    }

    // Cancel adding a passage
    onCancel() {
        this.adding = false
    }

    onEnter(newPassageName: string) {
        if (!this.validate(newPassageName)) {
            this.addPassage(newPassageName).catch(displayError)
        }
        this.adding = false
    }

    async addPassage(newPassageName: string) {
        const { rt } = this.props
        const { portion } = rt
        if (!portion) {
            return
        }

        await portion.addPassage(newPassageName)
        const newPassage = portion.passages.slice(-1)[0] ?? null
        await rt.setPassage(newPassage)
    }

    validate(newPassageName: string) {
        const { rt } = this.props
        const { portion } = rt

        if (!portion) {
            return t('portionNotFound')
        }

        return portion.checkNewPassageName(newPassageName)
    }

    render() {
        const { rt } = this.props
        const { iAmTranslator } = rt

        if (!iAmTranslator) return null

        if (!this.adding)
            return (
                <div
                    className="passage-adder"
                    data-toggle="tooltip"
                    title={t('Add new passage.')}
                    onClick={this.onAdding.bind(this)}
                >
                    <i className="far fa-fw fa-plus-square" />
                </div>
            )

        return (
            <div className="passage-box">
                <TextInput
                    message={t('Type Enter to add new passage or Esc to cancel.')}
                    placeholder={t('Passage Name')}
                    initialValue=""
                    validate={this.validate.bind(this)}
                    _onEscape={this.onCancel.bind(this)}
                    _onEnter={this.onEnter.bind(this)}
                />
            </div>
        )
    }
}

export default observer(PassageAdder)
